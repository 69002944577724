function loadApp() {
    try {
        if(typeof WebView != "undefined"){
            if(window.location.href.indexOf("buynowsuccess=true") > -1){
                WebView.setPreference("closeActivity","productsuccess-url="+window.location.href);
            } else if (window.location.href.indexOf("course_link") > -1) {
                WebView.setPreference("closeActivity","preview="+window.location.href);
            }
            WebView.finishActivity();
        }
        if (typeof loadTheme == "function") {
            loadTheme();
        }
        if (typeof loadTeamsTheme == "function") {
            loadTeamsTheme();
        }
        try {
            Platform.setDeviceOrientation("portrait");
            Platform.enableZoom(false);
        } catch (e) {
            console.log("error while setting zoom or setting device orientation.");
        }
        CONTROLLER.loadConfig(SERVICECONFIG, VIEWCONFIG);
        if (APP_TYPE == "android") {
            Platform.setDebugMode("true");
            var isAppUpdate = Platform.getPreference("appupdate");
            if (isAppUpdate != "" && isAppUpdate == "true") {
                Platform.setPreference("appupdate","");
                Platform.clearCache();
            }
            loadAndroidApp();
        } else if (APP_TYPE == "ios") {
            loadIOSApp();
        } else {
            loadWebApp();
        }
        setActiveGroup();
    } catch (e) {
        console.log(e.message);
    }
};

function setActiveGroup(){
    if(OBJECT_USER_DATA != ""){
        var mode = getQueryParameter('mode', LOCATION_URL);
        var type = getQueryParameter('type', LOCATION_URL);
        if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'adaptivelearning' && typeof type != 'null' && type == 'adaptive_course'){
            var adaptiveGroupId = "";
            if(typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON.length > 0){
                for(var i = 0; i < OBJECT_USER_GROUPS_JSON.length; i++){
                    if(OBJECT_USER_GROUPS_JSON[i].type == "adaptive" && OBJECT_USER_GROUPS_JSON[i].title != "Adaptive All Content"){
                        adaptiveGroupId = OBJECT_USER_GROUPS_JSON[i].nid;
                    }
                }
            }
            if(adaptiveGroupId == ""){
                var activegroupid = Platform.getPreference("activegroupid");
                if(!activegroupid){
                    CONTROLLER.setVariable("activegroupid", OBJECT_USER_DATA.groups[0]);
                }
                else{
                    CONTROLLER.setVariable("activegroupid", activegroupid);
                }
            }else{
                CONTROLLER.setVariable("activegroupid", adaptiveGroupId);
            }
        }else{
            var activegroupid = Platform.getPreference("activegroupid");
            if(!activegroupid){
                CONTROLLER.setVariable("activegroupid", OBJECT_USER_DATA.groups[0]);
            }
            else{
                CONTROLLER.setVariable("activegroupid", activegroupid);
            }
        }
    }
}

function loadAndroidApp() {
    try {
        if(Platform.getPreference("QuizResultsSyncUpdate") == "true"){
            startQuizResultSyncOfResultIdEmpty();
            Platform.setPreference("QuizResultsSyncUpdate","false");
        }
        if(history.length == 1){
            let fromRegistrationPage = Platform.getPreference("afterRegistration");
            // remove progress bar if found
            if(typeof fromRegistrationPage !== "undefined" && fromRegistrationPage === "true"){
                Platform.setPreference("afterRegistration", "false");
                
                Client.closeProgressBar();
            } 
            Client.showDialog();
        }
        LOCATION_URL = getLocationURL();
        var QPM = extractQueryParam();
        var userService = CONTROLLER.getService("User");
        generateSeverClientOffset();
        var settings = CONTROLLER.getService("Settings");
        var contentutils = CONTROLLER.getService("ContentUtils");
        var bookmark = getQueryParameter('mode');
        var adaptiveQuiz = getQueryParameter('mode');
        var adaptiveLearning = getQueryParameter('mode');
        var user = settings.getLastLoggedinUser("user");
        if (handleErrorPage()) {
            return;
        }

         //to be uncommented later once API is fixed
        var temp_val = $.parseJSON(Platform.getLocalConfig());
        if (typeof temp_val.enablesso != 'undefined') {
            ENABLE_SSO_LOGIN = temp_val.enablesso;
        }
        if(typeof ENABLE_SSO_LOGIN !="undefined"){
            Platform.setPreference('ssoLogin', ENABLE_SSO_LOGIN);
        }
        if (Platform.getBootstrapStatus() == 'ready' || Platform.getBootstrapStatus() == 'failed' || user == ""
            || $.isEmptyObject(user) || (user.loginRequiredOffline && user.loginRequiredOffline == true)
            || (user.loginRequiredOnline && user.loginRequiredOnline == true)) {
            if (!$.isEmptyObject(user)) {
                setUserProductType();
            }
            var senttimestamp = Platform.getPreference('senttimestamp');
            var emailid = getQueryParameter('email');
            if(emailid=="null"){
                emailid = getQueryParameter('username');
            }
            var timestamp = getQueryParameter('timestamp');
            if (emailid != 'null' && timestamp != 'null') {
                if (timestamp == senttimestamp) {
                    if (Platform.getNetworkState() == true) {
                        var status = userService.loginRedirect(emailid);
                        if (status == true) {
                            Platform.setPreference('samlLogin', "true");
                            Platform.setPreference('emailid', emailid);
                            setUserData(emailid);
                            CONTROLLER.loadView("Signin");
                        }
                        else {
                            CONTROLLER.loadView("SamlError", t("ERROR_MESSAGE", true));
                        }
                    }
                    else {
                        CONTROLLER.loadView("SamlError", t("ERROR_MESSAGE", true));
                    }
                }
                else {
                    CONTROLLER.loadView("SamlError", t("UNAUTHORIZED_USER", true));
                }
            } else {
                if (typeof SIGNINLANDINGPAGE_ENABLED != 'undefined' && SIGNINLANDINGPAGE_ENABLED == 'true') {
                    CONTROLLER.loadView("SigninLanding");
                } else if(emailid == "" || emailid == "null" ||  typeof emailid == "undefined"){
                    if(typeof ENABLE_SSO_LOGIN != 'undefined' && ENABLE_SSO_LOGIN == "true"){
                        $(".vs_progress_wrapper").addClass("hidden")
                        CONTROLLER.loadView("SigninRedirect","signinredirect");
                    }else{
                        // course selection view where user select course before login and will be used that to show the app flow
                        if(typeof COURSE_SELECTION_BEFORE_SIGNINVIEW !== "undefined" && COURSE_SELECTION_BEFORE_SIGNINVIEW === "true"){
                            CONTROLLER.loadView("CourseSelection");
                            return;
                        }

                        CONTROLLER.loadView("Signin");
                    } 
                } else {
                    var emailid = emailid;
                    var uid = uid;
                    var data = "{";
                    var temp = "";
                    temp = ' "username" : "' + emailid + '" ,';
                    temp = temp + ' "ignore_phone_number" : "' + 'true' + '" '; //doesUserExist use phone no as mandatory for uniqueness
                    data = data + temp + "}";
                    var userexist = userService.doesUserExist(data);
                    if (userexist == 409) {
                        var status = userService.loginRedirect(emailid);
                        if (status == true) {
                            setUserData(emailid);
                            CONTROLLER.loadView("SigninRedirect");
                        }
                        else {
                            CONTROLLER.loadView("SigninLanding", ERROR_MESSAGE);
                        }
                    }
                    else {
                        var status = userService.loginRedirect(emailid,"", uid);
                        if (status == true) {
                            Platform.setPreference('autoLogin', 'true');
                            Platform.setPreference('emailid', emailid);
                            setUserData(emailid);
                                CONTROLLER.loadView("Signin");
                        }else{
                            if(ENABLE_SSO_LOGIN == "true"){
                                CONTROLLER.loadView("SigninRedirect");
                            }else if(ENABLE_SSO_LOGIN == "false"){
                                CONTROLLER.loadView("Signin");
                            }
                        }
                    }
                }
                Client.removeDialog();
            }
        } else {
            var initializeuser = Platform.initializeUser(JSON.stringify(OBJECT_USER_DATA));
            if (initializeuser == true || initializeuser == false) {
                setUserProductType();
                if (APP_TYPE == "android") {
                    contentutils.filedownloadprogresstimer();
                }
                if (Platform.getNetworkState() == true && getQueryParameter("ftl") == "null") {
                    userService.loginRedirect(OBJECT_USER_DATA.email);
                    userService.loginRedirectAjax(OBJECT_USER_DATA.email);
                }
                else {
                    ASYNC_LOGIN_REQUIRED = "true";
                }
                var scholarship_group = Platform.getPreference('scholarship_group');
                scholarship_group = $.parseJSON(scholarship_group);
                if (typeof scholarship_group != 'undefined' && typeof scholarship_group === 'object' && scholarship_group != null){
                    CONTROLLER.loadView("PreAssessment");
                    Client.removeDialog();
                    return;
                } 
                else{
                    var pendingNotification = Platform.getPreference('pendingNotification');
                    if (typeof pendingNotification != "undefined" && pendingNotification != "") {
                        try {
                            handleAndroidNotificationClick(pendingNotification);
                        } catch (e) {
                            if(typeof LOAD_CHATBOT_CATEGORIES_LANDINGPAGE !== "undefined" && LOAD_CHATBOT_CATEGORIES_LANDINGPAGE === "true"){
                                var courseSelected = Platform.getPreference("courseSelected");
                            
                                if(typeof courseSelected !== "undefined" && courseSelected === ""){
                                    Platform.setPreference("courseSelected", "UG/PG");
                                }
                                
                                // load all subjects to show all the categories
                                CONTROLLER.setVariable('loaded_chatbot', 'true');
                                CONTROLLER.setVariable('categories_listing_mode', 'chat_bot');
                                CONTROLLER.loadView('AllSubjects');
                            }else{
                                CONTROLLER.loadView("HomePage", "true");
                            }
                            console.log("Notification error.");
                        }
                    } else if (Platform.getPreference('shareddata')) {
                        onLaunchAppByURL();
                    } else if (launchFromPortalURL()) {
                        Client.removeDialog();
                        Client.closeProgressBar();
                        return;
                    } else {
                        if(typeof bookmark != 'undefined' && bookmark != "null" && bookmark == "bookmark"){
                            Client.removeDialog();
                            Client.closeProgressBar();
                            CONTROLLER.setVariable("lastView", 'favorites');
                            return loadFavoriteView(LOCATION_URL);
                        }
                        if(typeof adaptiveQuiz != 'undefined' && adaptiveQuiz != 'null' && adaptiveQuiz == 'adaptivequiz'){
                            Client.removeDialog();
                            CONTROLLER.setVariable("lastView", 'AdaptiveQuiz');
                            return loadAdaptiveQuiz(LOCATION_URL);
                        }
                        if(typeof adaptiveLearning != 'undefined' && adaptiveLearning != 'null' && adaptiveLearning == 'adaptivelearning'){
                            Client.removeDialog();
                            return loadAdaptiveLearning(LOCATION_URL);
                        }
                        // load course view to display chatbot coourses
                        if(typeof LOAD_CHATBOT_CATEGORIES_LANDINGPAGE !== "undefined" && LOAD_CHATBOT_CATEGORIES_LANDINGPAGE === "true"){
                            var courseSelected = Platform.getPreference("courseSelected");
                            
                            if(typeof courseSelected !== "undefined" && courseSelected === ""){
                                Platform.setPreference("courseSelected", "UG/PG");
                            }
                           
                            Client.removeDialog();
                            // load all subjects to show all the categories
                            CONTROLLER.setVariable('loaded_chatbot', 'true');
                            CONTROLLER.setVariable('categories_listing_mode', 'chat_bot');
                            CONTROLLER.loadView('AllSubjects');
                        }else{
                            if(typeof REDIRECT_TO_BUYNOW_ON_REGISTRATION != "undefined" && REDIRECT_TO_BUYNOW_ON_REGISTRATION == true){
                                var fromreg = getQueryParameter('fromreg');
                                if (fromreg != 'null' && fromreg == "true") {
                                    CONTROLLER.loadView("HomePage");
                                    setTimeout(function () {
                                        platformView.prototype.handleBuyNowClick()
                                    }, 100);
                                    return ;
                                }
                            }
                            if(typeof HOME_PAGE_ENABLED != "undefined" &&  HOME_PAGE_ENABLED == "true"){
                                CONTROLLER.loadView("HomePage");
                            }else{
                                CONTROLLER.setVariable('categories_listing_mode',"AllSubjects")
                                CONTROLLER.loadView('AllSubjects');
                            }
                            
                        }
                    }
                }
            }
        }
        checkAppUpdate();
    } catch (e) {
        console.log(e.message);
    }
}

function loadIOSApp() {
    LOCATION_URL = getLocationURL();
    var QPM = extractQueryParam();
    var userService = CONTROLLER.getService("User");
    var bookmark = getQueryParameter('mode');
    generateSeverClientOffset();
    setTimeout(function () {
        try {
            var udata = Platform.getPreference("udata");
            if (typeof udata != 'undefined' && udata != '' && udata != 'null') {
                udata = $.parseJSON(udata);
                OBJECT_USER_ID = udata[0]['uid'];
                var loginVal = userService.loginUserSync();
                if (typeof loginVal != 'undefined' && loginVal != '' && loginVal == "false")
                    udata = '';
            }
            if (typeof udata != 'undefined' && udata != '' && udata != "false" && udata != 'null') {
                var loginRequired = Platform.getPreference("loginRequired");
                if (typeof loginRequired != 'undefined' && loginRequired != '' && loginRequired == "true") {
                    CONTROLLER.loadView("Signin");
                    Client.closeProgressBar();
                    Client.removeDialog();
                    return "";
                } else {
                    OBJECT_USER_DATA = udata[0];
                    OBJECT_USER_ID = udata[0]['uid'];
                    OBJECT_USER_DATA.groups = {};
                    if (setUserData(udata[0]['email'], udata[0]['uid']) == false) {
                        CONTROLLER.loadView("Signin");
                        Client.removeDialog();
                        Client.closeProgressBar();
                        return;
                    }
                    var signinView = new SigninView();
                    signinView.loginSuccess(function () {
                        CONTROLLER.setVariable("autoLogin", "true");
                        var pendingNotification = Platform.getPreference('pendingNotification');
                        if (typeof pendingNotification != "undefined" && pendingNotification != "") {
                            try {
                                handleAndroidNotificationClick(pendingNotification);
                            } catch (e) {
                                if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                                    CONTROLLER.loadView("HomePage");
                                }
                                else {
                                    CONTROLLER.loadView("AllSubjects");
                                }
                                console.log("Notification error.");
                            }
                        } else if (Platform.getPreference('shareddata')) {
                            onLaunchAppByURL();
                        } else if (launchFromPortalURL()) {
                            Client.removeDialog();
                            Client.closeProgressBar();
                            return;
                        } else {
                            if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                                if(typeof bookmark != 'undefined' && bookmark != "null" && bookmark == "bookmark"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    CONTROLLER.setVariable("lastView", 'favorites');
                                    return loadFavoriteView(LOCATION_URL);
                                }
                                CONTROLLER.loadView("HomePage");
                            }
                            else {
                                CONTROLLER.loadView("AllSubjects");
                            }
                        }
                    });
                }
            } else {
                CONTROLLER.loadView("Signin");
                Client.closeProgressBar();
                Client.removeDialog();
            }
        } catch (e) {
            console.log(e.message);
        }
    }, 2000);
}

function loadWebApp() {
    try {
            if (Platform.getNetworkState() == false) {
                Client.removeDialog();
                Client.closeProgressBar();
                confirm("Internet is required! <br/> Would you like to retry?", function () {
                    window.location.reload();
                });
                $("#content_wrapper").empty();
                return;
            }
            $('title').html(PROJECT_TITLE);
            generateSeverClientOffset();
            if (EXEC_MODE == "online") {
                $('#client_favicon').attr("href", "../images/favicon.276a3b3d.ico");
                LOCATION_URL = getLocationURL();
                var userService = CONTROLLER.getService("User");
                var mode = getQueryParameter('mode', window.location.href);
                if (LOCATION_URL && LOCATION_URL.indexOf("user/password") > -1) {
                    CONTROLLER.loadView("ForgotPassword");
                    return;
                }
                var activeLoggedInUser = userService.isUserLoggedin();
                if (activeLoggedInUser != 'false') {
                    if (setUserData("", activeLoggedInUser[0]['uid']) == false) {
                        Client.removeDialog();
                        Client.closeProgressBar();
                        return;
                    }
                    var signinView = new SigninView();
                    signinView.loginSuccess(function () {
                        var pendingNotification = Platform.getPreference('pendingNotification');
                        var scholarship_group = Platform.getPreference('scholarship_group');
                        if (typeof pendingNotification != "undefined" && pendingNotification != "") {
                            try {
                                handleAndroidNotificationClick(pendingNotification);
                            } catch (e) {
                                if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                                    CONTROLLER.loadView("HomePage");
                                }
                                else {
                                    CONTROLLER.loadView("AllSubjects");
                                }
                                console.log("Notification error.");
                            }
                        } else if (Platform.getPreference('shareddata')) {
                            onLaunchAppByURL();
                        } else if (launchFromPortalURL()) {
                            Client.removeDialog();
                            Client.closeProgressBar();
                            return;
                        } 
                        else if (typeof scholarship_group != 'undefined' && typeof scholarship_group === 'object' ){
                            CONTROLLER.loadView("PreAssessment"); 
                            Client.removeDialog();
                            return;
                        } 
                        else {
                            if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                                if(typeof mode != 'undefined' && mode != "null" && mode == "bookmark"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    CONTROLLER.setVariable("lastView", 'favorites');
                                    return loadFavoriteView(LOCATION_URL);
                                }else if(typeof mode != 'undefined' && mode != "null" && mode == "scormToolKit"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    CONTROLLER.setVariable("scormToolKitQuiz", true);
                                    CONTROLLER.setVariable("lastView", 'homepage');
                                    return loadScormToolKit(LOCATION_URL);
                                } else if (typeof mode != 'undefined' && mode != "null" && mode == "webinar") {
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    return loadWebinarView();
                                }else if(typeof mode != 'undefined' && mode != "null" && mode == "adaptivelearning"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    return loadAdaptiveLearning(LOCATION_URL);
                                }else if(typeof mode != 'undefined' && mode != "null" && mode == "embedQuiz"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    return callQuizView(getQueryParameter('linknid'));
                                }else if(typeof mode != 'undefined' && mode != "null" && (mode == "embedVideo" || mode == "embedDocument")){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    return callResourceView(getQueryParameter('nid'));
                                }else if(typeof mode != 'undefined' && mode != "null" && mode == "iframemode"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    return loadContentInIframe();
                                }

                                // for chatbot check the resourceUrl in preference and load it
                                if(typeof window.WebView != 'undefined'){
                                    var queryString = window.WebView.getPreference('resourceUrl');
                                }else{
                                    var queryString = getResourceParamsFromURL('resourceUrl', window.location.href);
                                }

                                if(typeof queryString != 'undefined' && queryString != null && queryString != "" && queryString != "null"){
                                    var chatbot_mode = getQueryParameter('mode', queryString, true);
                                    // if mode is chatbot then load the resources accordingly
                                    if(typeof chatbot_mode != "undefined" && chatbot_mode == "chatbot"){
                                        return loadChatBot(queryString);
                                    }
                                }

                                // check if the url has destination and redirect if it has one to the destination url
                                var chatbotDestination = getQueryParameter("destination");
                                if(typeof chatbotDestination !== "undefined" && chatbotDestination !== "null"  && chatbotDestination !== ""){
                                    var dashredirectUrl = chatbotDestination + "&nid=" + getQueryParameter("nid") + "&client=" + getQueryParameter("client");
                                    // replace the location with dash url
                                    window.location.replace(dashredirectUrl);
                                }
                                
                                CONTROLLER.loadView("HomePage");
                            }
                            else {
                                if(typeof REDIRECT_TO_BUYNOW_ON_REGISTRATION != "undefined" && REDIRECT_TO_BUYNOW_ON_REGISTRATION == true){
                                    var fromreg = getQueryParameter('fromreg');
                                    if (fromreg != 'null' && fromreg == "true") {
                                        setTimeout(function () {
                                            platformView.prototype.handleBuyNowClick()
                                        }, 100);
                                        return ;
                                    }else{
                                        CONTROLLER.loadView("AllSubjects");
                                    }
                                }
                                if(typeof mode != 'undefined' && mode != "null" && mode == "bookmark"){
                                    Client.removeDialog();
                                    Client.closeProgressBar();
                                    CONTROLLER.setVariable("lastView", 'favorites');
                                    return loadFavoriteView(LOCATION_URL);
                                }else{
                                    CONTROLLER.loadView("AllSubjects");
                                }
                            }
                        }
                    });
                    Client.removeDialog();
                    return;
                } else {
                    Platform.deleteDatabase();
                    setTimeout(function () {
                        Platform.setPreference("helpsliderviewed", "true");
                        if (typeof SIGNINLANDINGPAGE_ENABLED != 'undefined' && SIGNINLANDINGPAGE_ENABLED == 'true') {
                            CONTROLLER.loadView("SigninLanding");
                        } else {
                            // if the url had type as content then put it in localstorage for redirection after login
                            if(window.location.href.indexOf("type=content") > -1){
                                localStorage.setItem("redirectionContentUrl", window.location.href);
                            }

                            // for ejaypee mobile browser check the location href and if it's drkonar url redirect to drkonar signin page
                            if(typeof window.location.href !== "undefined" && window.location.href.indexOf("drkonar.ejaypee.com") > -1){
                                window.location.href = DR_KONAR_WEBSITE;
                            }

                            if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
                                var google_auth = getQueryParameter('googleauth');
                                var error = getQueryParameter('error');
                                if(!checkIsEmpty(google_auth) && google_auth == 'failed'){
                                    if(!checkIsEmpty(error) && error == "accessdenied"){
                                        alert(t("GOOGLE_LOGIN_UNSUCCESS", true), function(){
                                            window.location.replace(window.location.origin);
                                        });
                                    }else{
                                        alert(t("USER_NOT_REGISTERED", true), function(){
                                            window.location.replace(window.location.origin);
                                        });
                                    }
                                    Client.closeProgressBar();
                                }
                            }

                            CONTROLLER.loadView("Signin");
                        }
                    }, 200);
                }
            }
            Client.removeDialog();
            Client.closeProgressBar();
    } catch (e) {
        console.log(e.message);
    }
}

function setUserProductType() {
    if (EXEC_MODE == "online") {
        USER_PRODUCT_TYPE = "online";
    } else {
        USER_PRODUCT_TYPE = "hybrid";
    }
}

function setUserData(emailid, uid) {
    var userService = CONTROLLER.getService("User");
    var userdata = [];
    if (uid) {
        userdata = userService.getUserInfo("", "", "", uid);
    } else {
        userdata = userService.getUserInfo("", emailid);
    }
    var userinfo = {};
    userinfo['username'] = userdata[0].name;
    userinfo['email'] = userdata[0].email || userdata[0].mail;
    userinfo['uid'] = userdata[0].uid;
    userinfo['isloggedin'] = true;
    userinfo['loginRequiredOnline'] = false;
    userinfo['groups'] = "";
    OBJECT_USER_DATA = userinfo;
    OBJECT_USER_ID = OBJECT_USER_DATA.uid;
    OBJECT_USER_NAME = OBJECT_USER_DATA.username;
    Platform.setUserID(OBJECT_USER_ID);
    if (!IS_APP_SHELL) {
        var userjsonstring = JSON.stringify(userinfo);
        Platform.saveObject("currentuser", 1, "content", $.trim(userjsonstring));
        Platform.saveObject("user", userinfo.uid, "content", $.trim(userjsonstring));
    }

    // save user data in preference
    Platform.setPreference("userDetailsJson", JSON.stringify(userinfo));
}

function handleBackClick() {
    // handle on back for documents in chatbot page
    var prevView = CONTROLLER.getVariable("previousView");
    
    if(typeof prevView !== 'undefined' && prevView === "chatbot"){
        return false;
    }

    // for mobile browser check for mode
    var mode = getQueryParameter('mode');

    // on mobile browser when launched url with mode=embedQuiz or mode=embedVideo return false
    // if pdf or video is opened return false
    if(APP_TYPE == "web"){
        if(typeof mode !== "undefined" && (mode === "embedQuiz")
        && $("#ebookview-frame").length === 0 && $("#learningvideoplayer").length === 0){
            return false;
        } else if(typeof mode !== "undefined" && mode === "embedVideo"){
            return false;
        }
    }

    if($("#coc_iframe").length > 0){
        Platform.closeApplication();
        return;
    }
    if(typeof  CONTROLLER.activeViewName != 'undefined' &&  CONTROLLER.activeViewName == 'VideoPlayer'){
        if($(window).width() > $(window).height()){
            setVideoPlayerToPortrait();
            return false;
        }   
    }

    if ($('.ui-update-dialog').length == 1 && Platform.getPreference('force_update_app') != 'false') {
        return "";
    }
    if ($("#cc_set_time_input").hasClass('isOpen')) {
        $("#cc_set_time_input").trigger('close');
        return false;
    }
    if ($("#cc_set_date_input").hasClass('isOpen')) {
        $("#cc_set_date_input").trigger('close');
        return false;
    }
    if($('.instructions_inner_wrapper').length >= 1){
        setTimeout(function () {
            CONTROLLER.onBack();
        }, 200);
    }
    if ($("#solution_embed_wrapper").length != 1) {
        var closedialog = closeDialog();
        if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
            if ($('.player').length > 0) {
                if (!PlayerManager.isPlaying()) {
                    PlayerManager.playVideo();
                }
            }
            if (typeof QUIZ_TIMER != 'undefined') {
                window.clearInterval(QUIZ_TIMER);
            }
            $('.title-overlay-wrapper').removeClass('hidden');
            BreakManager.closeBreak();
        }

        if (closedialog == true && BREAK_MODE == 'false') {
            if (typeof clearcheck != 'undefined' && clearcheck != null) {
                window.clearInterval(clearcheck);
            }else{
                return "";
            }
        }

        if(closedialog == true){
            return "";
        }
        
        BREAK_MODE = 'false';
    }
    clearTimeout(titleTimer);
    clearInterval(FULLSCREENTIMER);
    clearInterval(FULLSCREENRESIZETIMER);
    if ($("#search_text").length > 0 && !$("#search_text").hasClass("hidden")) {
        if ($(".title_text").html() !== 'Search') {
            $("#search_text").removeClass("inFocus");
            $("#search_text").addClass("hidden");
            $("#search_icon").css({ 'right': '50px' });
            if(CONTROLLER.activeViewName == "Category"){
                $('#right-menu-icon').addClass("hide-right-menu");
                $('#search_icon').addClass("position-search-icon");
            }
            $("#notifications,#sync_wrapper,#right-menu-icon,.title_text").removeClass("hidden");
            if(CONTROLLER.getVariable("recentdownloadsview") == true){
                $("#search_icon").css({ 'right': '26px' });
                $("#right-menu-icon").addClass("hidden");
                $(".title_text").removeClass("hidden");
            }
            if (CONTROLLER.getVariable("tasksearchenabled") == true) {
                $("#search_icon").css({ 'right': '16px' });
                CONTROLLER.setVariable("tasksearch", "");
                CONTROLLER.setVariable("previousView", "HomePage")
                CONTROLLER.loadView("ListTask");
            }else if (CONTROLLER.activeViewName == "HomePage") {
                $("#menu, #home_page_title_wrapper").removeClass("hidden");
                $("#back").css("visibility", "hidden");
                $("#back").addClass("hidden");
            } else if (CONTROLLER.activeViewName == "TemplateList") {
                $("#search_icon").css({ 'right': '16px' });
                $("#notifications,#sync_wrapper,#right-menu-icon").addClass("hidden");
                if (CONTROLLER.getVariable("activesearchtext")) {
                    $("#search_text").blur();
                    setTimeout(function () {
                        CONTROLLER.onBack();
                    }, 200);
                }
            }
        } else if ($("#search_text").hasClass("inFocus")) {
            $("#search_text").removeClass("inFocus");
            $("#search_text").blur();
        } else {
            $("#search_text").blur();
            setTimeout(function () {
                CONTROLLER.onBack();
            }, 200);
        }
    } else {
        CONTROLLER.onBack();
        Client.removeDialog();
    }
    setTimeout(function(){
        if(typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY != null && QUESTION_OF_THE_DAY != ''){
            $("#quizContainer_wrap").css("height","auto");
            $("#quiz_wrapper").css("height","auto");
        }
    }, 50);
    return "";
}

function updateApplication() {
    try {
        var updateJSON = Platform.isUpdateAvailable();
        if (typeof updateJSON != 'undefined' && updateJSON != '') {
            updateJSON = JSON.parse(updateJSON);
            return updateJSON;
        }
        return "";
    }
    catch (e) {
        console.log("Update is not available" + e);
    }
}

function checkAppUpdate(update_message) {
    try {
        if (EXEC_MODE != "mobile") {
            return;
        }
        var update_message = updateApplication();
        if (typeof update_message == 'undefined' || update_message == "") {
            Platform.deletePreference('session_count');
            Platform.setPreference('force_update_app', 'false');
            Platform.deletePreference('dailog_title');
            Platform.deletePreference('dailog_description');
            Platform.deletePreference('message_cycle');
        }
        else {
            Platform.setPreference('force_update_app', update_message['force']);
            Platform.setPreference('update_app_dailog', 'false');
            Platform.setPreference('dailog_title', update_message['title']);
            Platform.setPreference('dailog_description', update_message['description']);
            Platform.setPreference('message_cycle', parseInt(update_message['message_cycle']));

            if (update_message['force'] == 'false') {
                if (Platform.getPreference('session_count') == 'nan' || Platform.getPreference('session_count') == '') {
                    Platform.setPreference('update_dialog', 'false');
                }

                if (Platform.getPreference('update_dialog') != "false") {
                    Platform.setPreference('session_count', parseInt(Platform.getPreference('session_count')) + 1);
                }

                if (Platform.getPreference('update_dialog') == 'false') {
                    Platform.setPreference('session_count', 0);
                }
            }
        }
    }
    catch (e) {
        console.log('update is not available' + e);
    }
}

function showBuyNowPopup() {
    LAST_SHOWN_DURATION = PlayerManager.getTime();
    PlayerManager.pauseVideo();
    POPUP_SHOWN_COUNT++;
    clearTimeout(titleTimer);
    var platformview = new platformView();
    var resourceView = new ResourceView();
    Platform.setStatusAndNavigationBar(true);
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width = 0;
    var height = 0;
    var scrollposition = $(window).scrollTop();

    if ($(window).width() < 380) {
        width = 250;
        height = 250;
    }
    else {
        width = 400;
        height = 250;
    }


    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            resourceView.toggleNavigationBar();
            PlayerManager.playVideo();
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed bn_res_popup',
        position: "center",
    });

    $("#alert_dialog").html("<div id='buynow_popup_image'></div><div id='buynow_button'></div>");

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".ui-dialog-titlebar ").css({
        "position": "relative",
        "z-index": "100000"
    });

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({
        'z-index': '99999999',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none'
    });
    $('.ui-dialog-titlebar .ui-button-text').html("X");
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('.alert_wrapper_dialog .ui-widget').css({
        "font-size": "22px",
        "right": 0,
        "position": "absolute"
    });
    $('.ui-dialog-titlebar').css("height", "0");
}

function calculateDialogHeight(difference) {
    var screen_height = parseInt($(window).height());
    var top = (screen_height - 226) / 2;
    var calculated_height = screen_height - (2 * (top));
    $('html body .ui-dialog').css('min-height', (calculated_height - difference) + 'px');
}

function setdialogstyle(left, top, fullscreen) {
    if (fullscreen) {
        var left = 0;
        var top = 0;
    } else if (!left && !top) {
        var screen_width = parseInt($(window).width());
        var screen_height = parseInt($(window).height());
        var left = (screen_width - 320) / 2;
        var top = (screen_height - 226) / 2;
    }
    $('html body .ui-widget-header').css('display', 'none');
    $('html body .ui-dialog-titlebar').css('display', 'none');
    $('html body .ui-dialog').css({
        'border': '1px solid #bbb', 'z-index': '100000', 'top': top,
        'background': '#ffffff', 'position': 'absolute', 'outline': 'none', '-webkit-tap-highlight-color': 'rgba(255, 255, 255, 0)',
        '-webkit-touch-callout': 'none',
        '-webkit-user-select': 'none',
        '-khtml-user-select': 'none',
        '-moz-user-select': 'moz-none',
        '-ms-user-select': 'none',
        'user-select': 'none',
        'outline': 'none'
    });
    if (fullscreen) {
        $('html body .ui-dialog').css({
            'height': $(window).height(),
            'width': $(window).width(),
            'left': 0
        });
    }
    $('html body .ui-widget-content').css({ '-webkit-border-radius': '0px', 'border-radius': '0px' });
    $('html, body').animate({
        scrollTop: 0
    }, 'slow');
}
function hideMenuIcons(menuarray) {
    var menuelements = getMenuElements(menuarray);
    $(menuelements).hide();
}

function showMenuIcons(menuarray) {
    var menuelements = getMenuElements(menuarray);
    $(menuelements).show();
}

function getMenuElements(menuarray) {
    var menuelements = "";
    for (var i = 0; i < menuarray.length; i++) {
        if (i != (menuarray.length - 1)) {
            menuelements = menuelements + menuarray[i] + ",";
        }
        else {
            menuelements = menuelements + menuarray[i];
        }
    }
    return menuelements;
}

function goHome() {
    try {
        hPlayer.dispose();

    } catch (ex) { }
    if (typeof GROUP_TITLE != "undefined") {
        pageRedirect(HOME_PAGE_URL + "classname=" + GROUP_TITLE);
    }
    else {
        pageRedirect(HOME_PAGE_URL);
    }
}

function getURL() {
    var uri = unescape(window.location.href);
    return uri;
}

function checkSDcardCreator() {
    var sdcardcreator = false;
    for (var role in OBJECT_USER_DATA.roles) {
        if (OBJECT_USER_DATA.roles[role].toLowerCase() == "sdcard creator") {
            sdcardcreator = true;
            break;
        }
    };
    return sdcardcreator;
}

function checkSdcardCompatibility(courseid, catid) {
    if (USER_PRODUCT_TYPE == "offline" && CONTENT_IN_MULTIPLE_SDCARDS == true) {
        var chaptersubject = "";
        var tocService = CONTROLLER.getService("Toc");
        var chaptersofsdcard = tocService.getChaptersOfSdCard();
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
        if (chaptersofsdcard != "") {
            if (typeof chaptersofsdcard[category.title] != "undefined") {
                for (j = 0; j < chaptersofsdcard[category.title].length; j++) {
                    if (chaptersofsdcard[category.title][j].nid == courseid) {
                        chaptersubject = true;
                    }
                }
            }
            else {
                chaptersubject = false;
            }

        }
        if (chaptersubject == false) {
            var productToc = tocService.getTocSdCardInfo(courseid);
            if (productToc == false) {
                return true;
            } else if (typeof productToc == "undefined" || productToc == "") {
                alert(t("IMPROPER_SDCARD_STATUS", true));
                return false;
            } else {
                alert("Please insert SD card " + productToc);
                return false;
            }
        }
    }
    return true;
}

var queryParams = null;

dust.helpers.extractparam = function (chunk, ctx, bodies, params) {
    try {
        if (queryParams == null)
            queryParams = extractQueryParam();

        var name = dust.helpers.tap(params.name, chunk, ctx);

        return chunk.write(queryParams[name]);
    }
    catch (e) {
        console.log("extractparam Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.getposter = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var data = name.split(",");
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        name = contentUtilsService.getPosterPath(data[0], data[1]);
        return chunk.write(name);
    }
    catch (e) {
        console.log("getPoster Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.getUserAnswerDetails = function (chunk, ctx, bodies, params) {
    try {
        var options = dust.helpers.tap(params.options, chunk, ctx);
        var userAnswer = dust.helpers.tap(params.user_answer, chunk, ctx);
        var type = dust.helpers.tap(params.type, chunk, ctx);
        var optionName = "";
        var optionData = "";
        var response = "";
        if (userAnswer != null && userAnswer.length != 0) {
            if (type == "multimatching") {
                for (var i = 0; i < options.length; i++) {
                    if (response.length > 0) {
                        response += "<br/>";
                    }
                    if(options[i].question){
                        response += "Q." + (i + 1) + ") <br/>";
                        if (userAnswer && userAnswer.length > 0) {
                            for (var m = 0; m < userAnswer.length; m++) {
                                var user_answered = userAnswer[m].split("|");
                                if (options[i].match_id == user_answered[0]) {
                                    for (var j = 0; j < options.length; j++) {
                                        if (options[j].match_id == user_answered[1]) {
                                            response += "A." + (j + 1) + ". " + options[j].answer + "\n";
                                        }
                                    }
                                }
                            }
                        } else {
                            response += "<b> - </b>";
                        }
                    }
                }
            } else if (type == "matrix") {
                for (var i = 0; i < options.length; i++) {
                    if (response.length > 0) {
                        response += "<br/>";
                    }
                    if (options[i].question) {
                        response += "Q." + (i + 1) + ") <br/>";
                        if (userAnswer && userAnswer.length > 0) {
                            for (var m = 0; m < userAnswer.length; m++) {
                                var user_answered = userAnswer[m].split("|");
                                if (options[i].match_id == user_answered[0]) {
                                    for (var j = 0; j < options.length; j++) {
                                        if (options[j].match_id == user_answered[1]) {
                                            response += "A." + (j + 1) + ". " + options[j].answer + "\n";
                                        }
                                    }
                                }
                            }
                        } else {
                            response += "<b> - </b>";
                        }
                    }
                }
            }else if(type == "matching" || type == 'sequence'){
                var qNo = 0;
                for (var i in userAnswer) {
                    qNo += 1;
                    response += "Q." + (qNo) + ") <br/>";
                    for (var j = 0; j < options.length; j++) {
                        if (userAnswer[i] == options[j].match_id) {
                            response += options[j].answer + "\n";
                        }
                    }
                }
            }
            else if (type == "multichoice") {
                for (var i = 0; i < options.length; i++) {
                    if (userAnswer.indexOf(",") > -1) {
                        userAnswer = userAnswer.split(",");
                    }
                    if (isArray(userAnswer)) {
                        optionName = "";
                        optionData = "";
                        for (var j = 0; j < userAnswer.length; j++) {
                            if (userAnswer[j] && options[i].id == userAnswer[j]) {
                                optionName = String.fromCharCode(65 + parseInt(i));
                                optionData = options[i].answer;
                                if (response.length > 0) {
                                    response += "<br/>";
                                }
                                response += "Option " + optionName + ".<br/>" + optionData;
                                break;
                            }
                        }
                    } else {
                        if (options[i].id == userAnswer) {
                            optionName = String.fromCharCode(65 + parseInt(i));
                            optionData = options[i].answer;
                            response = "Option " + optionName + ".<br/>" + optionData;
                            break;
                        }
                    }
                }
            }
        } else {
            response = "<b> - </b>";
        }
        var returnvalue = chunk.write(response);
        return returnvalue;
    }
    catch (e) {
        console.log("getUserAnswerDetails Error Message=" + e.message);
        return chunk.write("");
    }
};

dust.helpers.getMatchUserAnswer = function (chunk, ctx, bodies, params) {
    try {
        var match = dust.helpers.tap(params.match, chunk, ctx);
        var user_answer = dust.helpers.tap(params.user_answer, chunk, ctx);
        var id = user_answer;
        for (var i = 0; i < match.length; i++) {
            if (match[i].match_id == id) {
                var user_ans = match[i]['answer'];
                break;
            }

        }
        var returnvalue = chunk.write(user_ans);
        return returnvalue;

    }
    catch (e) {
        console.log("getMatchUserAnswer Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.getMatchUserAnswerStatus = function (chunk, ctx, bodies, params) {
    try {
        var user_ans = "wrong";
        var match = dust.helpers.tap(params.match, chunk, ctx);
        var user_answer = dust.helpers.tap(params.user_answer, chunk, ctx);
        var id = user_answer;
        if (match.match_id == user_answer) {
            user_ans = 'correct';
        }
        var returnvalue = chunk.write(user_ans);
        return returnvalue;
    }
    catch (e) {
        console.log("getMatchUserAnswerStatus Error Message=" + e.message());
        return chunk.write("");
    }
}

dust.helpers.getuserposter = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var data = name.split(",");
        var name = getUsersPosterPath(data[0], data[1]);
        var tempname = name.split("/").slice(3).join("/");
        if (!DataManager.isFileExist(tempname)) {
            name = getUserDefaultPoster(data[0], data[1]);
        }
        return chunk.write(name);
    }
    catch (e) {
        console.log("getuserposter Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.getuserdefaultposter = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var data = name.split(",");
        if (data[0] == "adduser") {
            name = addUserPoster();
        }
        else {
            name = getUserDefaultPoster();
        }
        return chunk.write(name);
    }
    catch (e) {
        console.log("getuserdefaultposter Error Message=" + e.message);
        return chunk.write("");
    }
}


dust.helpers.getresourceposter = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var thumbnail_id = dust.helpers.tap(params.thumbnail_id, chunk, ctx);
        var thumbnail_filename = dust.helpers.tap(params.thumbnail_filename, chunk, ctx);
        var filetype = dust.helpers.tap(params.filetype, chunk, ctx);
        name = DataManager.getResourcePosterPath(name, thumbnail_id, thumbnail_filename, filetype);
        var returnvalue = chunk.write(name);
        return returnvalue;
    }
    catch (e) {
        console.log("getResourcePoster Error Message=" + e.message);
        return chunk.write("");
    }
}

function openDOC(resourceid) {
    var url = DataManager.getResourcePath(resourceid);
    if (url[0] != "error") {
        Platform.openPdf(url[1]);
    }
    else {
        alert(url[1]); //  To display the error message.
    }
}

dust.helpers.extractURL = function (chunk, ctx, bodies, params) {
    try {
        var url = getLocationURL();
        var data = url.split("?");
        var name = dust.helpers.tap(params.name, chunk, ctx);
        url = LINKS_PAGE_URL + data[1] + "&resourceid=" + name;
        return chunk.write(url);
    }
    catch (e) {
        console.log("Extract URL  Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.extractDescription = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var newstr = name.length < 50 ? name : name.substring(0, 50) + '..';
        return chunk.write(newstr);
    }
    catch (e) {
        console.log("Extract description Error Message=" + e.message);
        return chunk.write("");
    }

}


dust.helpers.convertToMinutes = function (chunk, ctx, bodies, params) {
    try {
        var name = dust.helpers.tap(params.name, chunk, ctx);
        var hours = Math.floor(name / 3600);
        name -= hours * 3600;

        var minutes = Math.floor(name / 60);
        name -= minutes * 60;

        var seconds = parseInt(name % 60, 10);

        var hdisplay = (hours < 10 ? '0' + hours : hours);
        var mdisplay = (minutes < 10 ? '0' + minutes : minutes);
        var sdisplay = (seconds < 10 ? '0' + seconds : seconds);

        var time = (hdisplay > 0 ? hdisplay + ' hr ' : '') + (mdisplay > 0 ? mdisplay + ' min ' : '') + sdisplay + ' sec';
        return chunk.write(time);
    }
    catch (e) {
        console.log("convert to Error Message=" + e.message);
        return '0:0:0';
    }
}

dust.helpers.sortByRank = function (chunk, context, bodies, params) {
    params.items.sort(function (a, b) {
        if(a.rank == null && b.rank != null){
            return -1;
        }
        else if(a.rank != null && b.rank == null){
            return 1;
        }
        else{
            return ((parseInt(a.rank) < parseInt(b.rank)) ? -1 : ((parseInt(a.rank) > parseInt(b.rank)) ? 1 : 0));
        }
    });

    return chunk;
};

function onElementFocus(element, topheight) {
    $(element).focusin(function () {
        if (!$(element).hasClass("focussed")) {
            $(element).addClass("focussed");
            var screen_height = parseInt($(window).height());
            var top = (screen_height - topheight) / 2;
            $('.ui-dialog').animate({ top: top }, 500);
        }
    });
}

function closeDialog(common) {
    if ($(".ui-dialog-content").length > 0 && $(".ui-dialog-content").dialog("isOpen") === true || common) {
        $("#dialog_transprant_wrapper").toggle();
        $('.ui-dialog-content').remove();
        $('.ui-dialog-content').dialog("close");
        $('body').unbind('touchmove');
        return true;
    }
}
dust.helpers.mapSubjectImage = function (chunk, ctx, bodies, params) {
    try {
        var returnvalue = chunk.write(BUYNOW_SUBJECT_IMAGES_MAP[params['items'].toLowerCase()]);
        return returnvalue;
    }
    catch (e) {
        console.log("mapSubjectImage Error Message=" + e.message);
        return chunk.write("");
    }
}
dust.helpers.mapSubjectTitle = function (chunk, ctx, bodies, params) {
    try {
        var returnvalue = chunk.write(params['items'].toUpperCase());
        return returnvalue;
    }
    catch (e) {
        console.log("mapSubjectImage Error Message=" + e.message);
        return chunk.write("");
    }
}

dust.helpers.getKey = function (chunk, ctx, bodies, params) {
    try {
        var newitems = [];
        for (var k in params['items']) {
            if ($.isNumeric(k)) {
                newitems.push({ key: 'test', value: params['items'][k] });
            } else {
                newitems.push({ key: k, value: params['items'][k] });
            }
        }
        params["items"] = newitems;
        return chunk;
    }
    catch (e) {
        console.log("getKey Error Message=" + e.message);
        return chunk.write("");
    }
}

function makeAsyncTaskCall(message, content, url, method, showdialog, callback, className) {
    var data = "{";
    var temp = "";
    temp = temp + ' "message" : "' + message + '",';
    temp = temp + ' "data" : "' + content + '",';
    temp = temp + ' "url" : "' + url + '",';
    temp = temp + ' "method" : "' + method + '",';
    temp = temp + ' "showdialog" : "' + showdialog + '",';
    temp = temp + ' "callback" : "' + callback + '",';
    temp = temp + ' "classname" : "' + className + '"';
    data = data + temp + "}";
    Platform.makeAsyncTaskCall(data);
}
function handleAndroidNotificationClick(pendingNotification) {
    try {
        var mode = getQueryParameter('mode', LOCATION_URL);
        if (CONTROLLER.activeViewName == "Signin" || Platform.getObjectProperty("currentuser",1,"content") == '') {
            return;
        }
        if(typeof mode != 'undefined' && mode == 'adaptivelearning'){
            return;
        }
        if (typeof pendingNotification != "undefined" && pendingNotification != "") {
            QUIZ_IN_PROGRESS = false;
            Platform.setPreference("notification", "");
            Platform.setPreference("pendingNotification", "");
            var data = "", action_type = "", action_data = "", type = "";
            if (typeof pendingNotification == "string") {
                var notification = $.parseJSON(pendingNotification);
            } else {
                var notification = pendingNotification;
            }
            if ((typeof notification != 'undefined' && notification.c_type == "action") ||
             (typeof notification.object_type != "undefined" && notification.object_type == "course")) {
                action_type = notification["object_type"];
                action_data = notification["object_id"];
                if (typeof action_data != 'undefined') {
                    if (notification['c_type'] == "action" || action_data['download'] == "true") {
                        type = "action";
                    }
                }
                if ((typeof action_data != 'undefined' && action_data != "" && type == "action" )
                || (typeof notification.object_type != "undefined" && notification.object_type == "course")){
                    handleNotificationClick(type, action_data, action_type, pendingNotification);
                } else if(notification['c_type'] == "forum_topic" || notification['c_type'] == "forum"){
                    CONTROLLER.loadView("HomePage");
                    require(["https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML"],
                    function () {
                        MathJax.Hub.Config({extensions: ["mml2jax.js"], jax: ["input/MathML", "output/HTML-CSS"]});
                        CONTROLLER.setVariable('notificationType',notification['c_type']);
                        CONTROLLER.loadView("ForumTopics", notification.cid);
                    });
                } else {
                    CONTROLLER.loadView("Notifications", "announcements");
                }
            }else{
                if (typeof notification != 'undefined' && notification.c_type == "popup"){
                    CONTROLLER.loadView("HomePage");
                }else{
                    CONTROLLER.loadView("Notifications", "announcements");
                }
            }
            Client.removeDialog();
            Client.closeProgressBar();
        }
    } catch (e) {
        console.log("handleAndroidNotificationClick : " + e.message);
    }
}
function handleNotificationClick(type, action_data, action_type,pendingNotification) {
    if (type == 'action' && typeof action_data != 'undefined' && action_data != "") {
        switch (action_type) {
            case "video":
                callResourceView(action_data);
                break;
            case "quiz":
                callQuizView(action_data);
                break;
            case "question":
                callQuestionView(action_data);
                break;
            case "webinar" :
                callWebinarView(action_data);
                break;
            case "assignment" :
            case "task" :
                callActivityView(pendingNotification);
                break;
            case "course":
            case "category":
                callCoursePage(action_data);
                break;
            case "test_collection":
                loadTestSeriesTabViewFromNotification("active", action_data, "");
                break;
            default:
                CONTROLLER.loadView("Notifications");
                break;
        }
    } else {
        CONTROLLER.loadView("Notifications");
    }
}
function onTap(e) {
    if ($('video').length > 0 && $("#solution_embed_wrapper").length != 1) {
        var userAgent = checkUserAgent();
        if(IS_TEAMS_APP && typeof e !== 'undefined' && $(e.target.offsetParent).hasClass('vjs-big-play-button')){
            if ($(".vjs-playing").length > 0 && $(".vjs-paused").length == 0) {
                PlayerManager.pauseVideo();
                return;
            }
        }

        if(typeof e !== 'undefined' && e.target.id !== 'playback_rate_button' &&  $('#playback_rate_btns').length > 0 && $('#playback_rate_btns').hasClass('hidden') == false){
            $('#playback_rate_btns').addClass('hidden');
        }

        if(userAgent === 'desktop_browser' && typeof e !== 'undefined' && $(e.target.offsetParent).hasClass('vjs-big-play-button')){
            if ($(".vjs-playing").length > 0 && $(".vjs-paused").length == 0) {
                PlayerManager.pauseVideo();
                return;
            }
        }

        if ($("#notes_text_wrapper").hasClass("hidden")) {
            if (hPlayer.userActive() === false) {
                hPlayer.userActive(true);
            }
            var resourceView = new ResourceView();
            clearTimeout(titleTimer);
            $('.title-overlay-wrapper').slideDown();
            //Platform.setStatusAndNavigationBar(true);
            setTimeout(function () {
                resourceView.setVideoFullscreen();
            }, 1000);
            titleTimer = setTimeout(function () {
                setTimeout(function () {
                    resourceView.setVideoFullscreen();
                }, 1000);
                if($(".bookmark_dialog").length == 0){
                    $("html body .ui-dialog,.ui-widget-overlay,.break-content").css("z-index","9999999");
                    $(".break-content").css("z-index","200");
                }
                else{
                    onTap();
                }
                if($(window).width() > $(window).height()){
                    Platform.setStatusAndNavigationBar(false);
                }
                $('.title-overlay-wrapper').slideUp();
            }, 5000);
        }
    }
}
function getUrlVars(url, name) {
    var results = new RegExp('[\?&&amp;]' + name + '=([^&#]*)').exec(url);
    if (results == null) {
        return null;
    }
    else {
        return results[1] || 0;
    }
}
function callActivityView(notification) {
    this.task=CONTROLLER.getService("Task");
    CONTROLLER.setVariable('notificationType', "action");
    var params ={
        value : 0,
        notification : notification
    }
    if (typeof notification == 'string') {
        notification = JSON.parse(notification)
    }
    var previousView = CONTROLLER.getVariable("previousView");
    if (typeof previousView == 'undefined' || previousView == '') {
        CONTROLLER.setVariable("previousView",'HomePage');
    }
    console.log(typeof notification);
    CONTROLLER.loadView('MyActivities',params);
}
function callForum(url) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var settings = CONTROLLER.getService("Settings");
        this.currentuser = settings.getLastLoggedinUser();
        var userGroups = this.currentuser.groups;
        CONTROLLER.setVariable('notificationType', "action");
        var topicid = getUrlVars(url, "ftid");
        renderAskanExpertView(userGroups, topicid);

    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callForum" + e.message);
    }
}
renderAskanExpertView = function (userGroups, topicid) {
    CONTROLLER.setVariable("activecourseid", "");
    if (Platform.getNetworkState() == false) {
        alert(t("INTERNET_ERROR", true));
        Client.closeProgressBar();
        return;
    }
    else {
        loadMathJax(function () {
            CONTROLLER.setVariable("activecourseid", "");
            CONTROLLER.setVariable('toc_forum', false);
            CONTROLLER.setVariable("show_logs", false);
            CONTROLLER.loadView("ForumTopics", topicid);
        });
    }
}
function callTestCollection(url) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var settings = CONTROLLER.getService("Settings");
        var platformview = new platformView();
        this.currentuser = settings.getLastLoggedinUser();
        var userGroups = this.currentuser.groups;
        CONTROLLER.setVariable('notificationType', "action");
        var tcid = getUrlVars(url, "tcid");
        var quizService = CONTROLLER.getService("Quiz");

        setTimeout(function () {
            if (Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                Client.closeProgressBar();
                return false;
            }
            Client.showProgressBar(t("Loading") + "");
            var testCollection = quizService.getTestCollection(OBJECT_USER_GROUPS);
            if (testCollection == 401) {
                platformview.showUserSubscriptionError();
                Client.closeProgressBar();
                return false;
            }
            else if (testCollection == 403) {
                onAccessDenied();
                Client.closeProgressBar();
                return false;
            } else if (testCollection == ERROR_MESSAGE) {
                alert(t("ERROR_MESSAGE", true));
                Client.closeProgressBar();
                return false;
            }
            if (userGroups.length > 2) {
                CONTROLLER.setVariable("renderTestCollection", "true");
                CONTROLLER.loadView('ChooseClass');
            } else {
                CONTROLLER.setVariable("testCollectiondata", testCollection);
                CONTROLLER.loadView("TestCollection", tcid);
            }
            Client.closeProgressBar();
        }, 400);

    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callTestCollection" + e.message);
    }

}

function callEbookView(url) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var courseid = getUrlVars(url, "sid");
        var nid = getUrlVars(url, "doc_id");
        var chapterid = getUrlVars(url, "cid");
        var platformview = new platformView();
        CONTROLLER.setVariable("activecourseid", courseid);
        var contentutils = CONTROLLER.getService("ContentUtils");
        var bookObject = $.parseJSON(Platform.getObjectProperty(OBJECT_BOOK, nid, "content"));
        var catid = bookObject[OBJECT_CATEGORY][0];
        CONTROLLER.setVariable("activegroupid", bookObject['groupid']);
        CONTROLLER.setVariable("activecatid", catid);
        CONTROLLER.setVariable('notificationType', "action");
        setTimeout(function () {
            if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
                if (USER_PRODUCT_TYPE == "online" && Platform.getNetworkState() == false) {
                    alert(t("INTERNET_ERROR", true));
                    Client.closeProgressBar();
                    return;
                }
                var isResourcePlayable = platformview.isResourcePlayable(nid, courseid, OBJECT_BOOK, "", "", "");
                if (isResourcePlayable == false) {
                    Client.closeProgressBar();
                    return "";
                }
            }
            if (USER_PRODUCT_TYPE == "offline") {
                var data = contentutils.checkContentAvailableInSdcard(courseid, catid);
                if (data && data[0] == "error") {
                    if (data[1].indexOf('Please insert SD') >= 0) {
                        alert(data[1]);
                        Client.closeProgressBar();
                        return "";
                    }
                }
            }
            var isFileAvailable = contentutils.isFileAvailable(bookObject.nid, bookObject.youtube_id, courseid, "ebook", catid);
            if (isFileAvailable.filestatus == "Not Available" && USER_PRODUCT_TYPE == "offline") {
                alert("File is not available to view the content.");
                Client.closeProgressBar();
                return "";
            } else if (isFileAvailable.filestatus == "Not Available" && USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                Client.closeProgressBar();
            }
            else if (isFileAvailable.filestatus == "In Progress" && isFileAvailable.version != "old" && Platform.getNetworkState() == false) {
                alert(t("FILE_DOWNLOAD_IN_PROGRESS", true));
                Client.closeProgressBar();
            }
            else {
                CONTROLLER.loadView('BookItem', bookObject);
            }
            Client.closeProgressBar();
        }, 800);
    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callEbookView" + e.message);
    }
};
function callWebinarView(action_data) {
    this.settings = CONTROLLER.getService("Settings");
    this.webinar = CONTROLLER.getService("Webinar");
    this.user = CONTROLLER.getService("User");
    var THIS = this;
    webinarStatus = THIS.webinar.getWebinarStatus(action_data);
    if( webinarStatus.status == "started"){
        var pageNumber;
        var totItems = 0;
        if (pageNumber != 0) {
            pageNumber = pageNumber - 1;
        }
        if(typeof WEBINAR_LIMIT == "undefined"){
            WEBINAR_LIMIT = 10;
        }
        if (HIDE_GROUPS_FILTER == "true") {
            var groups = OBJECT_USER_GROUPS.length ? ('"' + OBJECT_USER_GROUPS.join('","') + '"') : "";
        } else {
            var groups = CONTROLLER.getVariable("activegroupid");
        }
        var webinar_json = THIS.webinar.getAllWebinars(pageNumber, WEBINAR_LIMIT, groups);
        if(webinar_json == 403){
            onAccessDenied();
            Client.closeProgressBar();
            return false;
        }
        if (webinar_json == ERROR_MESSAGE || webinar_json == INTERNET_ERROR) {
            alert(t(webinar_json,true));
            Client.closeProgressBar();
            return false;
        }
        meetingId = webinarStatus.meeting_id;
        var webinar_account = webinar_json[OBJECT_WEBINAR][0].webinar_account;
        var password = webinar_json[OBJECT_WEBINAR][0].password;
        if (!IS_APP_SHELL ||  APP_TYPE == "ios") {
            if(!IS_APP_SHELL) meetingId = action_data; // if webinar is launched in mobile browser sending webinar nid
            if(typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")){
                Client.launchWebinar(meetingId, password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID);
            }else if(WEBINAR_USER_DETAIL_TYPE == "firstname"){
                var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                Client.launchWebinar(meetingId, password, userProfile[0].first_name + "-" + OBJECT_USER_ID);
            }else if(WEBINAR_USER_DETAIL_TYPE == "firstname_and_username"){
                var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                Client.launchWebinar(meetingId, password, userProfile[0].first_name + "-" + OBJECT_USER_NAME + "-" + OBJECT_USER_ID);
            }else{
                Client.launchWebinar(action_data);
            }
        } else {
            if(typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")){
                THIS.webinar.joinWebinar(meetingId, password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID,OBJECT_USER_DATA.email,webinar_account);
            }
            else if(WEBINAR_USER_DETAIL_TYPE == "firstname"){
                var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                THIS.webinar.joinWebinar(meetingId, password, userProfile[0].first_name + "-" + OBJECT_USER_ID,OBJECT_USER_DATA.email,webinar_account);
            }
            else if(WEBINAR_USER_DETAIL_TYPE == "firstname_and_username"){
                var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                THIS.webinar.joinWebinar(meetingId, password,userProfile[0].first_name+"-"+OBJECT_USER_NAME+"-"+OBJECT_USER_ID,OBJECT_USER_DATA.email,webinar_account);
            }
        }
        THIS.webinar.updateWebinarAttendance(action_data);
        if (EXEC_MODE == "mobile") {
        try{
            setTimeout(function(){
                Platform.doVideoViewHistorySync();
            },2000);
        }
        catch(e){
            console.log(e.message + " Platform.doVideoViewHistorySync() not defined");
        }
    }
    }else{
        CONTROLLER.loadView("Webinar");
    }
}

function callQuizView(action_data) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        CONTROLLER.setVariable('notificationUrlType', "quiz");
        var contentutils = CONTROLLER.getService("ContentUtils");
        var id = action_data;
        var mode = getQueryParameter('mode');
        CONTROLLER.setVariable('notificationType', "action");
        if(typeof mode != 'undefined' && mode == "scormToolKit"){
            CONTROLLER.setVariable('notificationType', "");
        } 
        var platformview = new platformView();

        var quizService = CONTROLLER.getService("Quiz");
        var utilService = CONTROLLER.getService("ContentUtils");
        var taskService = CONTROLLER.getService("Task");
        var quiz = quizService.getQuiz(id);
        var catid = quiz[OBJECT_CATEGORY][0];

        if(typeof quiz != 'undefined' && quiz == 'error'){
            CONTROLLER.setVariable('notificationType', "");
        }

        catid = getCategoryNidFromTid(catid);

        CONTROLLER.setVariable("activecatid", catid);
        var quiz_courseid = "", quiz_catid = "";
        var task_data = taskService.getTaskDataFromId(id);
        var quiz_data = utilService.getQuizFromId(quiz.nid);
        if(quiz_data != ERROR_MESSAGE){
            quiz_data = $.parseJSON(quiz_data);
            quiz_catid = quiz_data[0]['courses'][0];
        }
        if(task_data != ERROR_MESSAGE){
            task_data = $.parseJSON(task_data);
            quiz_courseid = task_data['course_id'];
        }
        quiz_courseid = getCategoryNidFromTid(quiz_courseid);
        CONTROLLER.setVariable('task_course_id', quiz_catid);
        CONTROLLER.setVariable('task_cat_id', catid);
        if (quiz == INTERNET_ERROR) {
            alert(t("INTERNET_ERROR", true));
            Client.closeProgressBar();
            return false;
        }
        if (typeof quiz == "undefined" || quiz == "" || quiz == "error") {
            alert(t("ERROR_MESSAGE", true));
            Client.closeProgressBar();
            return false;
        } else {
            if (USER_PRODUCT_TYPE == "offline" && typeof action_data.courseid != 'undefined' && action_data.courseid != '') {
                var courseid = action_data.courseid;
                CONTROLLER.setVariable("activecourseid", courseid);
                var data = contentutils.checkContentAvailableInSdcard(courseid, catid);
                if (data && data[0] == "error") {
                    if (data[1].indexOf('Please insert SD') >= 0) {
                        alert(data[1]);
                        Client.closeProgressBar();
                        return false;
                    }
                }
            }
            var isResourcePlayable = platformview.isResourcePlayable(id, action_data.courseid, OBJECT_QUIZ, "", "", "");
            if (isResourcePlayable == false) {
                Client.closeProgressBar();
                return false;
            }
            var isQuizViewable = platformview.isQuizViewable(quiz, quiz.type);
            if (isQuizViewable == false) {
                Client.closeProgressBar();
                return false;
            }
            if (USER_PRODUCT_TYPE == "offline" && typeof action_data.courseid != 'undefined' && action_data.courseid != '') {
                var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_QUIZ, id, "content"));
                var isFileAvailable = contentutils.isFileAvailable(resource.nid, resource.youtube_id, CONTROLLER.getVariable("activecourseid"), OBJECT_QUIZ);
                if (isFileAvailable.filestatus == "Not Available") {
                    alert("File is not available to view the content.");
                    Client.closeProgressBar();
                    return false;
                }
            }
            var questions = quizService.getQuestionsForQuiz(id);
            if (questions != "") {
				// setting context to submit quiz and load test summary
                CONTROLLER.setVariable("contextView", "notificationQuiz");

                beginQuiz(this, id, quiz.type);
            }
            else {
                alert(t("PROBLEM_STARTING_QUIZ_MSG", true));
                Client.closeProgressBar();
                return false;
            }
            return true;
        }
    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callQuizView" + e.message);
    }
};
function beginQuiz(e, id, type) {
    var params = { "button": e, "nid": id };
    $('#chapters_list_wrapper').remove();
    $('#content_wrapper').attr('id', 'quiz_wrapper');
    loadMathJax(function () {
        if (type == 'practice' || type == 'favorite') {
            CONTROLLER.loadView('Practicequiz', params);
        } else {
            CONTROLLER.loadView('Certificatequiz', params);
        }
    });
};
function loadMathJax(callback) {
    if (typeof LOCAL_MATHJAX != "undefined" && LOCAL_MATHJAX == "true"
    || (typeof DISABLE_MATHJAX != 'undefined' && DISABLE_MATHJAX)) {
        callback();
    } else {
        if (Platform.getNetworkState() == true) {
            require(["https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.7/MathJax.js?config=TeX-MML-AM_CHTML"],
                function () {
                    MathJax.Hub.Config({
                        extensions: ["mml2jax.js"], jax: ["input/MathML", "output/HTML-CSS"]
                    });
                    callback();
                }
            );
        } else {
            callback();
        }
    }
};
function callCoursePage(action_data) {
    try {
        Client.showProgressBar(t("Loading") + "...");

        var courseid = typeof action_data.courseid != "undefined" ? action_data.courseid : "";
        var tid = typeof action_data.tid != "undefined" ? action_data.tid : "";
        var cat_id = typeof action_data.cat_id != "undefined" ? action_data.cat_id : "";

        var courseService = CONTROLLER.getService("Course");
        var categoryService = CONTROLLER.getService("Category");
        CONTROLLER.setVariable('notificationType', "action");
        CONTROLLER.setVariable('notificationUrlType', "course");

        if (cat_id) {
            var catid = cat_id;
        } else {
            var catid = getCategoryNidFromTid(tid);
        }
        CONTROLLER.setVariable("activecatid", catid);
        var contentutils = CONTROLLER.getService("ContentUtils");
        if (USER_PRODUCT_TYPE == "offline") {
            var data = contentutils.checkContentAvailableInSdcard(courseid, catid);
            if (data && data[0] == "error") {
                if (data[1].indexOf('Please insert SD') >= 0) {
                    alert(data[1]);
                    Client.closeProgressBar();
                    return "";
                }
            }
        }
        if (courseid) {
            var chapters = courseService.getChaptersForCourse(courseid);
            if ($.isEmptyObject(chapters) || chapters[OBJECT_CHAPTER].length == 0) {
                alert(t("There are no " + OBJECT_CHAPTER + "s under this " + OBJECT_COURSE));
                Client.closeProgressBar();
                return "";
            }
            var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
            course = $.parseJSON(course);
            var groupid = getContentGroup(course);
            CONTROLLER.setVariable("activegroupid", groupid);
            CONTROLLER.setVariable("activegroupdisplayname",'');
            CONTROLLER.loadView("Course", courseid);
            return "";
        } else if (catid) {
            var category = Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content");
            category = $.parseJSON(category);
            var courses = categoryService.getCoursesForCategory(catid, CONTROLLER.getVariable("activegroupid"));
            if ($.isEmptyObject(courses) || courses[OBJECT_COURSE].length == 0) {
                alert(t("There are no " + OBJECT_COURSE + "s under this " + OBJECT_CATEGORY));
                Client.closeProgressBar();
                return "";
            }
            if (typeof CATEGORY_COUNT !== 'undefined') {
                CONTROLLER.loadView("Category", catid);
            } else {
                CONTROLLER.loadView("HomePage");
                Client.closeProgressBar();
                return "";
            }
        }
    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callSubjectView" + e.message);
    }
}
function callQuizListView(url) {
    if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            Client.closeProgressBar();
            return;
        }
    }
    try {
        Client.showProgressBar(t("Loading") + "...");
        var courseid = getUrlVars(url, "chapter_id");
        if (courseid == null) {
            courseid = getUrlVars(url, "sid");
        }
        var catid = getUrlVars(url, "cat_id");
        if (courseid == null || catid == null) {
            alert(CATEGORY_OR_CHAPTER_INFO_ERROR);
            Client.closeProgressBar();
            return "";
        }
        var contentutils = CONTROLLER.getService("ContentUtils");
        CONTROLLER.setVariable('notificationType', "action");
        CONTROLLER.setVariable('notificationUrlType', "quiz");
        catid = getCategoryNidFromTid(catid);
        CONTROLLER.setVariable("activecatid", catid);
        if (USER_PRODUCT_TYPE == "offline") {
            var data = contentutils.checkContentAvailableInSdcard(courseid, catid);
            if (data && data[0] == "error") {
                if (data[1].indexOf('Please insert SD') >= 0) {
                    alert(data[1]);
                    Client.closeProgressBar();
                    return "";
                }
            }
        }
        if (courseid) {
            var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
            course = $.parseJSON(course);
            var groupid = getContentGroup(course);
            CONTROLLER.setVariable("activegroupid", groupid);
            CONTROLLER.loadView("Course", courseid);
        }
    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callQuizListView" + e.message);
    }
}
function getCategoryNidFromTid(tid, categories) {
    if (!categories) {
        categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
    }
    if (categories) {
        for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
            if (tid == categories[OBJECT_CATEGORY][i]['tid']) {
                var nid = categories[OBJECT_CATEGORY][i]['nid'];
                return nid;
            }
        }
    }
}
function getCategoryTidFromNid(nid, categories) {
    if (!categories) {
        categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
    }
    if (categories) {
        for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
            if (nid == categories[OBJECT_CATEGORY][i]['nid']) {
                var tid = categories[OBJECT_CATEGORY][i]['tid'];
                return tid;
            }
        }
    }
}
function callResourceView(action_data) {
    try {
        this.course = CONTROLLER.getService("Course");
        this.resource = CONTROLLER.getService("Resource");
        Client.showProgressBar(t("Loading") + "...");
        if (content == "" || typeof(content) == "undefined") {
            var content =this.resource.getContentById(action_data);
            var status = content['status'];
            if (status == 200) {
                var content_data = content['data'];
                var content_array = JSON.parse(content_data);
                if(isArray(content_array) == true){
                    content_data = JSON.stringify(content_array[0]);
                }
                Platform.saveObject(OBJECT_VIDEO, action_data, "content", content_data);
                content = $.parseJSON(content_data);
            }
        }     
        
        //if iframe mode and category_id and course_id are present then take from query parameters, else rest the actual flow continues.
        var mode = getQueryParameter('mode', window.location.href);
        var category_id = getQueryParameter("catid", window.location.href);
        var course_id = getQueryParameter("course_id", window.location.href);

        var platformview = new platformView();
        var contentutils = CONTROLLER.getService("ContentUtils");

        if(typeof mode != 'undefined' && mode == 'iframemode' && !checkIsEmpty(category_id) && !checkIsEmpty(course_id)){
            var resourceid = action_data;
            var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content"));
            if (resource.type == null) {
                alert(t("CONTENT_NOT_SUPPORTED", true));
                Client.closeProgressBar();
                return;
            }
            var chapterid = getQueryParameter("chapterid", window.location.href);
        
        	var params = { "catid": category_id, "courseid": course_id, "resourceid": resourceid}

            if(!checkIsEmpty(chapterid)) {
                params.chapterid = chapterid
            }

        }else{
            var courseid = isArray(content) ? content[0][OBJECT_CATEGORY][0]  : content[OBJECT_CATEGORY][0];
            var catid = courseid;
            var category = Platform.getObjectProperty(OBJECT_CATEGORY,courseid , "content");
            category = $.parseJSON(category);
            var resourceid = action_data;
            
            var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content"));
            if (resource.type == null) {
                alert(t("CONTENT_NOT_SUPPORTED", true));
                Client.closeProgressBar();
                return;
            }
            var chapters = this.course.getChaptersForCourse(courseid);

            var course = getCourseOfSearchElement(resource[OBJECT_CHAPTER][0], resource[OBJECT_CATEGORY]);
            var params = { "catid": catid,  "courseid": course.nid, "resourceid": resourceid };
        }

        if (resource.type == RESOURCE_TYPE_WEBINAR && platformView.checkWebinarStatus(resource, params)) {
            return false;
        }
        CONTROLLER.setVariable("activecatid", params.catid);
        CONTROLLER.setVariable("activecourseid", params.courseid);

        if(mode !== 'iframemode'){
            CONTROLLER.setVariable('notificationType', "action");
        }
        
        setTimeout(function () {
            if (USER_PRODUCT_TYPE == "offline") {
                var data = contentutils.checkContentAvailableInSdcard(courseid, params.catid);
                if (data && data[0] == "error") {
                    if (data[1].indexOf('Please insert SD') >= 0) {
                        alert(data[1]);
                        Client.closeProgressBar();
                        return "";
                    }
                }
            }
            var isResourcePlayable = platformview.isResourcePlayable(params.resourceid, params.courseid, OBJECT_VIDEO, params.catid, true, params);
            if (isResourcePlayable == false) {
                Client.closeProgressBar();
                return false;
            }
            else {
                CONTROLLER.loadView("Resource", params);
            }
            Client.closeProgressBar();
        }, 800);
    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callResourceView" + e.message);
    }
};
function getContentGroup(content) {
    for (var i = 0; i < OBJECT_USER_GROUPS.length; i++) {
        for (var j = 0; j < content[OBJECT_GROUP].length; j++) {
            if (OBJECT_USER_GROUPS[i] == content[OBJECT_GROUP][j]) {
                return OBJECT_USER_GROUPS[i];
            }
        }
    }
}
function callGame(url) {
    Client.showProgressBar(t("Loading") + "...");
    var id = getUrlVars(url, "quiz_id");
    var params = { quizid: id, gameMode: 'play' };
    CONTROLLER.loadView('Game', params);
    return;
}

function onSocialLoginFailed(text) {
    alert(text);
}

function onSocialLoginSuccess(emailid) {
    Client.showProgressBar(t("Loading") + "");
    if (Platform.getNetworkState() == true) {
        var settings = CONTROLLER.getService("Settings");
        var contentutils = CONTROLLER.getService("ContentUtils");
        var userService = CONTROLLER.getService("User");
        var user = settings.getLastLoggedinUser("user");
        Platform.setPreference('socialLogin', "true");
        Platform.setPreference('emailid', emailid);
        var data = "{";
        var temp = "";
        temp = ' "username" : "' + emailid + '" ,';
        temp = temp + ' "ignore_phone_number" : "' + 'true' + '" '; //doesUserExist use phone no as mandatory for uniqueness
        data = data + temp + "}";

        var userexist = userService.doesUserExist(data);
        if (userexist == 'true') {
            //username exists
            var status = userService.loginRedirect(emailid);
            if (status == true) {
                setUserData(emailid);
                CONTROLLER.loadView("Signin");
            } else {
                CONTROLLER.loadView("SigninLanding", t("ERROR_MESSAGE", true));
            }
        } else {
            Platform.setPreference('socialLogin', "false");
            CONTROLLER.loadView("SigninLanding", t("USER_NOT_REGISTERED", true));
        }
    } else {
        CONTROLLER.loadView("SigninLanding", t("INTERNET_ERROR", true));
    }
    Client.closeProgressBar();
}
function onLaunchAppByURL(param) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var sharedData = param ? param : Platform.getPreference('shareddata');
        if (sharedData && sharedData.indexOf("challenge_invite") > -1) {
            if (Platform.getBootstrapStatus() === "completed") {
                Platform.setPreference('shareddata', "");
                if (Platform.getNetworkState() == true) {
                    var invitedUid = getQueryParameter("id", sharedData);
                    var quizId = getQueryParameter("challengeId", sharedData);
                    if (invitedUid && quizId) {
                        var param = {
                            'invitedUid': invitedUid,
                            'quizId': quizId
                        };
                        CONTROLLER.loadView("ChallengeTab", param);
                        Client.removeDialog();
                        Client.closeProgressBar();
                        return true;
                    }
                } else {
                    alert(t("INTERNET_ERROR", true));
                }
                if (!CONTROLLER.activeViewName) {
                    CONTROLLER.loadView("HomePage");
                }
            }
        }
        Client.closeProgressBar();
        return false;
    } catch (e) {
        console.log("launchChallengeTab :" + e.message);
    }
}
function callQuestionView(action_data) {
    loadMathJax(function () {
        CONTROLLER.loadView("NotificationQuestion", action_data);
    });
}

function isMergeableObject(val) {
    var nonNullObject = val && typeof val === 'object';

    return nonNullObject
        && Object.prototype.toString.call(val) !== '[object RegExp]'
        && Object.prototype.toString.call(val) !== '[object Date]';
}

function emptyTarget(val) {
    return Array.isArray(val) ? [] : {};
}

function cloneIfNecessary(value, optionsArgument) {
    var clone = optionsArgument && optionsArgument.clone && optionsArgument.clone === true;
    return (clone && isMergeableObject(value)) ? deepmerge(emptyTarget(value), value, optionsArgument) : value;
}

function defaultArrayMerge(target, source, optionsArgument) {
    var destination = target.slice();
    source.forEach(function (e, i) {
        if (typeof destination[i] === 'undefined') {
            destination[i] = cloneIfNecessary(e, optionsArgument);
        } else if (isMergeableObject(e)) {
            destination[i] = deepmerge(target[i], e, optionsArgument);
        } else if (target.indexOf(e) === -1) {
            destination.push(cloneIfNecessary(e, optionsArgument));
        }
    })
    return destination;
}

function configArrayMerge(target, source, optionsArgument) {
    var filesArray;
    var destination = target.slice();

    if (Array.isArray(target)) {
        filesArray = target.map(function (currentValue) {
            var fileParts = currentValue.split("/");
            fileParts.shift();
            return fileParts.join("/");
        })
    }

    source.forEach(function (e, i) {
        if (typeof destination[i] === 'undefined') {
            destination[i] = cloneIfNecessary(e, optionsArgument);
        } else if (isMergeableObject(e)) {
            destination[i] = deepmerge(target[i], e, optionsArgument);
        } else if (target.indexOf(e) === -1) {
            var fileParts = e.split("/");
            fileParts.shift();
            var file = fileParts.join("/");
            if (filesArray) {
                var fileIndex = filesArray.indexOf(file);
                if (fileIndex > -1) {
                    destination[fileIndex] = cloneIfNecessary(e, optionsArgument);
                    return;
                }
            }
            destination.push(cloneIfNecessary(e, optionsArgument));
        }
    })
    return destination;
}

function mergeObject(target, source, optionsArgument) {
    var destination = {};
    if (isMergeableObject(target)) {
        Object.keys(target).forEach(function (key) {
            destination[key] = cloneIfNecessary(target[key], optionsArgument);
        });
    }
    Object.keys(source).forEach(function (key) {
        if (!isMergeableObject(source[key]) || !target[key]) {
            destination[key] = cloneIfNecessary(source[key], optionsArgument);
        } else {
            destination[key] = deepmerge(target[key], source[key], optionsArgument);
        }
    })
    return destination;
}

function deepmerge(target, source, optionsArgument) {
    var array = Array.isArray(source);
    var options = optionsArgument || { arrayMerge: defaultArrayMerge };
    var arrayMerge = options.arrayMerge || defaultArrayMerge;

    if (array) {
        return Array.isArray(target) ? arrayMerge(target, source, optionsArgument) : cloneIfNecessary(source, optionsArgument);
    } else {
        return mergeObject(target, source, optionsArgument);
    }
}

deepmerge.all = function deepmergeAll(array, optionsArgument) {
    if (!Array.isArray(array) || array.length < 2) {
        throw new Error('first argument should be an array with at least two elements');
    }

    // we are sure there are at least 2 values, so it is safe to have no initial value
    return array.reduce(function (prev, next) {
        return deepmerge(prev, next, optionsArgument);
    });
}

function launchFromPortalURL(uri) {
    try {
        if(typeof uri == "undefined"){
            uri = LOCATION_URL;
        }
        var queryParams = extractQueryParamAndDecode(uri);
        var portalURL = (queryParams && queryParams.redir_path) ? decodeURI(queryParams.redir_path) : false;
        if (IS_TEAMS_APP) {
            portalURL = uri;
        }
        var catId = "", catIDs = "", resourceType = "";
        var tcid = queryParams.tcid;
        if (queryParams && queryParams.course_link) {
            catId = decodeURI(queryParams.course_link);
        }
        if(queryParams && queryParams.buynowsuccess == "true"){
            var users = $.parseJSON(Platform.getObjectsOfType("user"));
            if (EXEC_MODE == "mobile") {
                if (users != null && typeof users['user'] != 'undefined' && users['user'].length > 0) {
                    Platform.getNewUserSubscription(OBJECT_USER_ID);
                }
            }
        }

        if (typeof ENABLE_LOCALIZATION != "undefined" && ENABLE_LOCALIZATION  == "true" && queryParams && queryParams.lang) {
            var paramLang = Object.entries(LANGUAGE_CODE).find(([key, value]) => value === queryParams.lang);
            var activeLang = LANG_META_OBJECTS.find(item => item.tid == paramLang[0]);
            Platform.setPreference("lang", activeLang.tid);
            Platform.setPreference("langname", activeLang.name);
        }

        if (queryParams && queryParams.cat_id) {
            catId = decodeURI(queryParams.cat_id);
        }

        // check for the type to see where to redirect to for mobile browser
        if(APP_TYPE === "web" && queryParams && queryParams.type) {
            resourceType = decodeURI(queryParams.type);
        }

        if(APP_TYPE === "web" && queryParams && queryParams.course_link) {
            Platform.getObjectsOfType(OBJECT_CATEGORY)
            catId = decodeURI(queryParams.course_link);
        }
        catIDs = catId.split(',');
        if(catIDs.length > 1){
            var grpid = Platform.getPreference("activegroupid");
            CONTROLLER.setVariable("activegroupid", grpid);
            if(APP_TYPE == "web"){
                if (window.location.href.includes('?') && window.location.pathname == '/show/all/courses') {
                    window.history.pushState({}, null, window.location.origin)
                    LOCATION_URL = window.location.href.split('?')[0];
                }
            }
            CONTROLLER.loadView("AllSubjects", catIDs);
        }else if (portalURL && portalURL.indexOf("user/profile/settings") > -1) {
            CONTROLLER.loadView("MyProfile");
        }  else if (APP_TYPE === "web" && typeof resourceType !== 'undefined' && resourceType === "content") {
            return callResourceView(queryParams.vid);
        } else if (catId) {
            if(APP_TYPE == "web"){
                if (window.location.href.includes('?') && window.location.pathname == '/course/details') {
                    window.history.pushState({}, null, window.location.origin)
                    LOCATION_URL = window.location.href.split('?')[0];
                }
            }
            return loadCategoryView(catId);
        } else if(typeof chapterId != "undefined" && chapterId != ""){
            loadChapterView(chapterId);
        } else if(typeof contentId != "undefined" && contentId != ""){
            var not_action_notification = true;
            callResourceView(contentId, not_action_notification);//passing parameter to indicate, not action notification
        } else if(typeof tcid != "undefined" && tcid != ""){
            loadTestSeries(uri);
        }
        else if ((portalURL && portalURL.indexOf("show/all/courses") > -1) || (portalURL && portalURL.indexOf("/courses") > -1)) {
            CONTROLLER.loadView("AllSubjects");
        } else if (portalURL && portalURL.indexOf("show/mywebinars") > -1) {
            CONTROLLER.loadView("MyWebinars");
        } else if (portalURL && portalURL.indexOf("user/password") > -1) {
            CONTROLLER.loadView("ForgotPassword");
        }  else if (portalURL && portalURL.indexOf("show/user/faq") > -1) {
            CONTROLLER.loadView("UserFaq");
        }else if (portalURL && portalURL.indexOf("show/buynow") > -1) {
            var platformview = new platformView();
            platformview.handleBuyNowClick();
        }else if(portalURL && portalURL.indexOf("/challenges") > -1){
            CONTROLLER.loadView("ChallengeTab");
        }else if((portalURL && portalURL.indexOf("show/forum") > -1) || (portalURL && portalURL.indexOf("/forum") > -1)){
            CONTROLLER.setVariable("from_url",true);
            var platformview = new platformView();
            platformview.renderForum();
        }
        else {
            return false;
        }
        return true;
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

function loadCategoryView(catId) {
    try {
        var category =  $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catId, "content"));
        if(category == null){
            var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
            categories = $.parseJSON(categories);
            var catIndex = findIndexByKeyValue(categories[OBJECT_CATEGORY], "tid", catId);
            if (catIndex == null) {
                alert("There are no " + OBJECT_COURSE + "s under this " + OBJECT_CATEGORY);
                Client.closeProgressBar();
                return false;
            }
            category = categories[OBJECT_CATEGORY][catIndex];
        }
        if (!isArray(category[OBJECT_GROUP])) {
            category[OBJECT_GROUP] = category[OBJECT_GROUP].split(",");
        }
        if (category) {
            var commonGroups = category[OBJECT_GROUP].filter(function (obj) { return OBJECT_USER_GROUPS.indexOf(obj) > -1; });
            var matchFound = false;
            for (var i = 0; i < commonGroups.length; i++) {
                matchFound = setTemporaryActiveGroup(OBJECT_USER_GROUPS_JSON, commonGroups[i]);
                if (matchFound) {
                    break;
                }
            }
            if (!matchFound) {
                for (var j = 0; j < category[OBJECT_GROUP].length; j++) {
                    var groupObj = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, category[OBJECT_GROUP][j], "content"));
                    if (groupObj) {
                        if (!isArray(groupObj)) {
                            groupObj = [groupObj];
                        }
                        matchFound = setTemporaryActiveGroup(groupObj, category[OBJECT_GROUP][j]);
                        if (matchFound) {
                            break;
                        }
                    }
                }
            }
            var categoryService = CONTROLLER.getService("Category");
            var courses = categoryService.getCoursesForCategory(category['nid'], CONTROLLER.getVariable("activegroupid"));
            if (!matchFound || $.isEmptyObject(courses) || courses[OBJECT_COURSE].length == 0) {
                alert("There are no " + OBJECT_COURSE + "s under this " + OBJECT_CATEGORY);
                Client.closeProgressBar();
                return false;
            }
            CONTROLLER.loadView("Category", category['nid']);
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

function loadCategoryViewByTid(catTid) {
    try {
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
        if (categories) {
            var catIndex = findIndexByKeyValue(categories[OBJECT_CATEGORY], "tid", catTid);
            if (catIndex == null) {
                alert("There are no " + OBJECT_COURSE + "s under this " + OBJECT_CATEGORY);
                Client.closeProgressBar();
                return false;
            }
            var category = categories[OBJECT_CATEGORY][catIndex];
            if (!isArray(category[OBJECT_GROUP])) {
                category[OBJECT_GROUP] = [category[OBJECT_GROUP]];
            }
            var commonGroups = category[OBJECT_GROUP].filter(function (obj) { return OBJECT_USER_GROUPS.indexOf(obj) > -1; });
            var matchFound = false;
            for (var i = 0; i < commonGroups.length; i++) {
                matchFound = setTemporaryActiveGroup(OBJECT_USER_GROUPS_JSON, commonGroups[i]);
                if (matchFound) {
                    break;
                }
            }
            if (!matchFound) {
                for (var j = 0; j < category[OBJECT_GROUP].length; j++) {
                    var groupObj = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, category[OBJECT_GROUP][j], "content"));
                    if (groupObj) {
                        if (!isArray(groupObj)) {
                            groupObj = [groupObj];
                        }
                        matchFound = setTemporaryActiveGroup(groupObj, category[OBJECT_GROUP][j]);
                        if (matchFound) {
                            break;
                        }
                    }
                }
            }
            var categoryService = CONTROLLER.getService("Category");
            var courses = categoryService.getCoursesForCategory(category['nid'], CONTROLLER.getVariable("activegroupid"));
            if (!matchFound || $.isEmptyObject(courses) || courses[OBJECT_COURSE].length == 0) {
                alert("There are no " + OBJECT_COURSE + "s under this " + OBJECT_CATEGORY);
                Client.closeProgressBar();
                return false;
            }
            CONTROLLER.loadView("Category", category['nid']);
            return true;
        } else {
            return false;
        }
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

function setTemporaryActiveGroup(groups, groupId) {
    try{
        if (typeof groups != "undefined" && groups.length > 0) {
            if (isUserTaggedToShownGroups(groups, groupId)) {
                CONTROLLER.setVariable("activegroupid", groupId);
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log(e.message);
        return false;
    }
}

function isUserTaggedToShownGroups(groups, groupId) {
    try {
        var grpIndex = findIndexByKeyValue(groups, "nid", groupId);
        if (grpIndex != null && groups[grpIndex].status != "0") {
            return true;
        }
        return false;
    } catch (e) {
        console.log(e.message);
        return false;
    }
}
function isTrainer() {
    try {
        var trainer = false;
        if (typeof OBJECT_USER_DATA.roles == "undefined" || OBJECT_USER_DATA.roles == "") {
            var userService = CONTROLLER.getService("User");
            var userRoles = userService.getUserRole();
            if(userRoles != INTERNET_ERROR && userRoles != ERROR_MESSAGE){
                OBJECT_USER_DATA['roles'] = userRoles['roles'];
            }
        }
        if (typeof OBJECT_USER_DATA.roles != "undefined") {
            $.each(OBJECT_USER_DATA.roles, function (k, v) {
                if (k == USER_ROLES["trainer"]) {
                    trainer = true;
                }
            });
        }
        return trainer;
    } catch (e) {
        console.log("function : isTrainer - " + e.message);
        return false;
    }
}
function calender_event_show(params) {

    var title = params.title;
    var description = params.description;
    var display_time = '';
    if (typeof params.start != 'undefined' && params.start != '') {
        var start = params.start;
    }
    if (typeof params.end != 'undefined' && params.end != '') {
        var end = params.end;
    }

    if (typeof end != 'undefined' && end != '') {
        var time = start + ' - ' + end;
    }

    else {
        var time = start;
    }

    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    var description = replaceURLWithHTMLLinks(description);
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok":{
                text:t("Ok"),
                click: function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });



    var text = '<div class="alert_event_dialog">' + '<div class="event_text">' + 'Event ' + '<span class="event_date"> ( ' + time + ' )</span>' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="event_dialog_title">' + title + '</div>\n\
                       <div id="update_link" class="event_dialog_description">' + description + '</div>\
                      </div>' ;

    $('#alert_dialog').html(text);

    $('.event_dialog_description a').click(function (e) {
        var url = this.href;
        if (url != undefined && url != "") {
            var pattern = /^((http|https|ftp):\/\/)/;
            if (!pattern.test(url)) { //if domain name not starts with "http://
                url = "http://" + url;
            }
            Platform.openBrowser(url);
        }
        e.stopPropagation();
        return false;
    });

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('ok_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '1%', 'margin-top': '1px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.ui-dialog').css({ 'padding': '0px', 'top': '55%', 'border-radius': '3px', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}


function edit_bookmark(params) {

    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Save": {
                text:t("Save"),
                click:function() {
                    $("html,body").css("overflow","");
                    $("#bookmark_title").blur();
                    params['action'] = 'update';
                    params['title'] = $('input[id="bookmark_title"]').val();
                    if(params['title'].trim() == ''){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Title is empty. Please enter the title.');
                    }else{
                        var api_result = BookmarksService.prototype.updateBookmark(params);
                    }
                    if(api_result == 'success'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        Client.showProgressBar("Refreshing bookmarks...");
                        CONTROLLER.loadView("Bookmarks");

                    }
                    else if(api_result == 'error'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Something went wrong please try again later');
                    }
                    return false;
                }
            },
            "Cancel": {
                text:t("Cancel"),
                click:function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_event_dialog">' + '<div class="event_text">' + 'Edit Title' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="edit_dialog_title">' + '<input type="text" value="" id="bookmark_title" placeholder="Enter the title"/>' + '</div>\n\
                      </div>' ;

    $('#alert_dialog').html(text);

    $('input[id="bookmark_title"]').val(params.title);

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#ffffff', 'position': 'absolute', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '6%', 'margin-top': '16px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.ui-dialog').css({ 'padding': '0px', 'top': '55%', 'border-radius': '3px !important', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}


function delete_bookmark(params) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": {
                text:t("Ok"),
                click:function() {
                    $("html,body").css("overflow","");
                    params['action'] = 'delete';
                    var api_result = BookmarksService.prototype.updateBookmark(params);

                    if(api_result == 'success'){
                        Client.showProgressBar("Refreshing bookmarks...");
                        CONTROLLER.loadView("Bookmarks");
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                    }
                    else if(api_result == 'error'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Something went wrong please try again later');
                    }
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            },
            "Cancel":{
                text:t("Cancel"),
                click: function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_event_dialog">' + '<div class="event_text">' + 'Delete Favourite ' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
           <div class="delete_dialog_title">'+ 'Are you sure you want to delete this favourite ?' + '</div>\n\
          </div>' ;

    $('#alert_dialog').html(text);

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '6%', 'margin-top': '16px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.ui-dialog').css({ 'padding': '0px', 'top': '55%', 'border-radius': '3px !important', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function save_bookmark(params) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var global_pin = 0;
    var width;
    var height;
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Save":{
                text:t('Save'),
                click: function() {
                    $("html,body").css("overflow","");
                    params['global_pin'] = global_pin;
                    params['title'] = $('input[id="bookmark_title"]').val();
                    params ['action'] = 'add';
                    if(params['title'].trim() == ''){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Title is empty. Please enter the title.');
                    }else{
                        var api_result = BookmarksService.prototype.updateBookmark(params);
                    }
                    if(api_result == 'success'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Favorite has been added');
                    }
                    else if(api_result == 'error'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Something went wrong please try again later');
                    }

                    if(!PlayerManager.isPlaying()){
                        PlayerManager.playVideo();
                    }

                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    $('#bookmarks_icon').attr('src' , '/platform/images/bookmark_inactive.4e7f9fb2.png');
                    return false;
                }
            },
            "Cancel": {
                text:t(Cancel),
                click:function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    if(!PlayerManager.isPlaying()){
                        PlayerManager.playVideo();
                    }
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    $('#bookmarks_icon').attr('src' ,'/platform/images/bookmark_inactive.4e7f9fb2.png');
                    return false;
                }
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="bookmark_dialog">' +'<div class="add_bookmark">'+ 'Add to Favorites' + '</div> '+'</div>'+
                '<div class="dialog">'+'<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="add_dialog_title">' + '<input type="text" value="" id="bookmark_title" placeholder="Enter the title"/>' + '</div>\n\
                       <div class="add_dialog_global hidden">' + '<input type="checkbox" name="bookmark" value="bookmark"/> Share with others ' + '</div>\n\
                      </div>' ;


    $('#alert_dialog').html(text);

    $('input[type="checkbox"]').click(function(){
        if($(this).prop("checked") == true){
            global_pin = 1;
        }
        else if($(this).prop("checked") == false){
            global_pin = 0;
        }
    });

    $('input[id="bookmark_title"]').val(params.content.title);

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    if(typeof ENABLE_BOOKMARK_SHARE != 'undefined' && ENABLE_BOOKMARK_SHARE){
        $('.add_dialog_global').removeClass('hidden');
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000','background': '#F4F5F9','position': 'absolute','outline':'none','border-radius':'0px'});
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'35%'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-bottom':'5%','margin-top':'4px'});
    $('.event_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'55%','border-radius': '3px !important','border-color':'#0080C6'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function removeCoachMark() {
    // removing tool tip reference , tour overlay and helper layer
    var tooltipReferenceClass = document.getElementsByClassName('tooltipReference');
    var tourOverlayClass = document.getElementsByClassName('tour_overlay');
    var helperLayerClass = document.getElementsByClassName('helper_layer');
    tooltipReferenceClass[0].parentNode.removeChild(tooltipReferenceClass[0]);
    tourOverlayClass[0].parentNode.removeChild(tourOverlayClass[0]);
    helperLayerClass[0].parentNode.removeChild(helperLayerClass[0]);
    // showing scroll on wrapper
    var _wrapper = document.getElementById('wrapper');
    _wrapper.classList.remove('overflow_class');
}

function get_carousel_images(callback) {
    try {
        var imagesData;
        var carousel_image_data;
        if(typeof APP_BANNER_JSON_URL != 'undefined'){
            var banners_url = APP_BANNER_JSON_URL;
            MobileUserService.prototype.getBannersInfo(banners_url, function (data) {
                if (data != ERROR_MESSAGE) {
                        if (typeof data["groups_banners"] != "undefined" 
                        &&  (typeof HIDE_GROUPS_FILTER == 'undefined' && (typeof HIDE_GROUPS_FILTER !== 'undefined' && HIDE_GROUPS_FILTER !== "true"))) {
                            imagesData = data["groups_banners"].groups;
                        } else if ((typeof data["groups_banners"] == "undefined" && typeof data.banners != "undefined") || 
                        ( (typeof HIDE_GROUPS_FILTER !== 'undefined' && HIDE_GROUPS_FILTER == "true"))) {
                            imagesData = data.banners;
                        }
                    if (HOMEPAGEDATA['online_images'] != "true"
                    || (typeof HOMEPAGEDATA["activegroupid"] == "undefined" || 
                    (typeof HOMEPAGEDATA["activegroupid"] != "undefined" && 
                    (HOMEPAGEDATA["activegroupid"] != CONTROLLER.getVariable("activegroupid") || 
                    HOMEPAGEDATA["activegroupid"] == CONTROLLER.getVariable("activegroupid"))))){
                        if(typeof data["groups_banners"] != "undefined"){
                            var group_info = Platform.getObjectProperty(OBJECT_GROUP, CONTROLLER.getVariable("activegroupid"), "content");
                            group_info = $.parseJSON(group_info);
                            var groupName = group_info['title'].toLowerCase().trim();
                            for (var j = 0; j < imagesData.length; j++) {
                                var currentImageGroupName = Object.keys(imagesData[j])[0];
                                if (currentImageGroupName.toLowerCase().trim() == groupName) {
                                    carousel_image_data = imagesData[Object.keys(imagesData)[j]][currentImageGroupName];
                                    HOMEPAGEDATA['online_images'] = "true";
                                    break;
                                } 
                            }
                            if(typeof carousel_image_data == "undefined" || carousel_image_data == ""){
                                if (typeof data["banners"] != "undefined") {
                                    carousel_image_data = data["banners"];
                                }
                            }
                            return callback(carousel_image_data);
                        }else{
                            carousel_image_data =  imagesData;
                            if(HOMEPAGEDATA['online_images'] != "true"){
                                HOMEPAGEDATA['online_images'] = "true";
                            }
                            return callback(carousel_image_data);
                        } 
                    }
                }
            });
        }
    }catch (e) {
        console.log('function get_carousel_images error');
    }
}

function get_group_specific_carousel_images(callback){
    try{
        var imagesData;
        var carousel_image_data;
        MobileUserService.prototype.getBannersInfo("", function (data) {
            var imagesdata = [];
            var active_group = CONTROLLER.getVariable("activegroupid");
            if(!$.isEmptyObject(data)){
                if(typeof data[active_group] !== 'undefined' && data[active_group].length > 0){
                    for (var i = 0; i < data[active_group].length; i++){
                        let obj = {};
                        obj.link = data[active_group][i].banner_redirect_link; 
                        obj.icon = data[active_group][i].banner_image_filepath;
                        imagesdata.push(obj);
                    }
                    carousel_image_data = imagesdata;
                    HOMEPAGEDATA['online_images'] = "true";

                    return callback(carousel_image_data);
                }else{
                    HOMEPAGEDATA['online_images'] = "true";
                    carousel_image_data = OFFLINE_BANNERS;
                    return callback(carousel_image_data)
                }
            }else{
                HOMEPAGEDATA['online_images'] = "true";
                carousel_image_data = OFFLINE_BANNERS;
                return callback(carousel_image_data)
            }
        });
    }catch(e){
        console.log('function get_group_specific_carousel_images error');
    }
}


function deviceRegister() {
    var maccAdd=Platform.getDeviceMacAddress();

    if (Platform.getNetworkState() == true) {
        var deviceinfo = Platform.makeHttpGetCall(OBJECT_SERVER_DOMAIN + REGISTER_DEVICE_API + OBJECT_USER_ID + '&deviceid=' + maccAdd + '&macaddress=' + maccAdd + '&devicetype=' + Platform.getDeviceType());
        try {
            if (!$.isEmptyObject(deviceinfo)) {
                deviceinfo = $.parseJSON(deviceinfo);
                var status = deviceinfo['status'];
                if (status == 200)
                {
                    Platform.setErrorURL("");
                    Platform.removeObject("device", maccAdd);
                    window.location.href = "./index.html";
                }
                else{
                    return "false";
                }
            }
        }
        catch (e)
        {
            var error = "url :" + OBJECT_SERVER_DOMAIN + '/api/chip/associate?chipid=' + cid + '&uid=' + OBJECT_USER_ID + '&chipname=' + sdCardName + '&deviceid=' + maccAdd
                    + "\n error_code :" + status
                    + "\n response_data :" + deviceinfo['data']
                    + "\n message :" + e.message;
            console.log(error);

        };
    }
}

function handleErrorPage() {
    try {
        var queryParams = extractQueryParamAndDecode();
        if (typeof queryParams.error == "undefined") {
            return false;
        }
        CONTROLLER.loadView("Error", queryParams.error);
        Client.removeDialog();
        Client.closeProgressBar();
        return true;
    } catch (e) {
        console.log(e.message);
    }
}
trackScreen = function(context_item ,analyticsJson ){
    try {
        if (typeof FirebaseAnalytics != "undefined") {
            var str = context_item.trim();
            str = str.replace(/[^A-Z0-9]+/ig, "_");
            str = str.substring(0,40);
            context_item = str;
            FirebaseAnalytics.trackScreen(context_item,JSON.stringify(analyticsJson));
        }
    }
    catch(e){
        console.log(e.message);
    }

}

function loadTeamsTheme() {
    if (IS_TEAMS_APP) {
        var styles = "platform/css/teams-theme.min.d6c6dab6.css";
        if (APP_TYPE == "web") {
            styles = "/platform/css/teams-theme.min.d6c6dab6.css";
        }
        var styleTag = document.createElement("link");
        styleTag.setAttribute("rel", "stylesheet");
        styleTag.setAttribute("href", styles);
        document.head.appendChild(styleTag);
    }
}

function loadFavoriteView(LOCATION_URL){
    try{
        var params_data = {};
        var catid = getQueryParameter('catid',LOCATION_URL);
        var chapterid = getQueryParameter('chapterid',LOCATION_URL);
        var courseid = getQueryParameter('courseid',LOCATION_URL);
        var resourceid = getQueryParameter('resourceid',LOCATION_URL);
        var duration  = getQueryParameter('duration',LOCATION_URL);
        var bookmarkid = getQueryParameter('bookmark_id',LOCATION_URL);
        var title = getQueryParameter('title',LOCATION_URL);
        var clipping_type = getQueryParameter('clipping_type',LOCATION_URL);
        var page = getQueryParameter('page',LOCATION_URL);
        var prev_view = getQueryParameter('prev_view',LOCATION_URL);
        var mode = getQueryParameter('mode',LOCATION_URL);
        var quiz_id = getQueryParameter('quizid', LOCATION_URL);
        var type = getQueryParameter('type', LOCATION_URL);
        var take_quiz = getQueryParameter('takeQuiz', LOCATION_URL);
        var qn_no = getQueryParameter('qn_no', LOCATION_URL);
        
        CONTROLLER.setVariable("activecatid", catid);

        // bookmark data
        params_data = {
            "catid": catid,
            "chapterid": chapterid,
            "courseid": courseid,
            "resourceid": resourceid,
            "bookmark": "true",
            "duration": duration,
            "mode": "bookmark",
            "prev_view": prev_view,
            "bookmark_id": bookmarkid,
            "title" : title ,
            "page" : page,
            "nid" : resourceid,
            "quiz_id" : quiz_id,
            "take_quiz" : take_quiz,
            "qn_no": qn_no
        };
        if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
            trackScreenEventDetails({'context' : 'favorite item', 'event_name' : 'TB_TAP_FAVORITE_ITEM'});
        }
        if(type == "flashcard"){
            params_data["type"] = type;
            loadFlashcardFavorites(params_data);
        }else if(type == "question"){
			params_data["type"] = type;
            loadQuizFavorites(quiz_id, resourceid, take_quiz);
        }else{
            setTimeout(function(){
                var isResourcePlayable = platformView.prototype.isResourcePlayable(params_data.nid, params_data.courseid, OBJECT_VIDEO, params_data.catid, true, params_data);
                if (isResourcePlayable == true) {
                    CONTROLLER.setVariable("previousView", 'bookmark');
                    Client.closeProgressBar();
                    CONTROLLER.loadView("Resource",params_data);
                }else{
                    Client.closeProgressBar();
                    CONTROLLER.loadView("HomePage");
                }
            }, 500);
        }
    }catch(e){
        console.log('error while extracting params'+ e);
    }
}

function loadQuizFavorites(nid, questionId, take_quiz=false){
    var params = {};
    var quiz_res_Obj = {};
    var question_res_Obj = {
        'nid': "",
        'is_correct': "",
        'is_skipped': "",
        'answer_timestamp': "",
        'answer': "",
        'user_answer': "",
        'points_awarded': "",
        'questiontype': "",
        'start_time_per_question': ""
    };
    var quizService = CONTROLLER.getService("Quiz");
    var quiz_object = quizService.getQuiz(nid, true);
    var questions = quizService.getQuestionsForQuiz(nid);
    if(typeof take_quiz != 'undefined' && take_quiz == "true"){
        if (questions != "") {
            beginQuiz(this, nid, quiz_object.type);
        }
    }else{
        var questionIndex = 0;
        for (var i in questions["question"]) {
            if (!isNaN(i)) {
                questions["question"][i]['question_result'] = question_res_Obj;
                if (questions["question"][i]['nid'] === questionId) {
                    questionIndex = i;
                }
            }
        }
        quiz_object['quiz_result'] = quiz_res_Obj;
        quiz_object['questions'] = questions["question"];
        params.report = quiz_object;
        params.mode = "all";
        params.replace_item = "#quiz_wrapper";
        params.replace = true;
        params.questionIndex = questionIndex;
        params.bookmarkQuizTitle = quiz_object["title"];
        params.isViewBookmarkMode = quiz_object["title"];
        $("#content_wrapper").empty();
        $("#content_wrapper").attr("id", "quiz_wrapper");
        CONTROLLER.loadView('Summary', params);
    }
}

function loadScormToolKit(LOCATION_URL){
    try{
        var params_data = {};
        var catid = getQueryParameter('catid',LOCATION_URL);
        var chapterid = getQueryParameter('chapterid',LOCATION_URL);
        var courseid = getQueryParameter('courseid',LOCATION_URL);
        var resourceid = getQueryParameter('resourceid',LOCATION_URL);
        var duration  = getQueryParameter('duration',LOCATION_URL);
        var bookmarkid = getQueryParameter('bookmark_id',LOCATION_URL);
        var title = getQueryParameter('title',LOCATION_URL);
        var clipping_type = getQueryParameter('clipping_type',LOCATION_URL);
        var page = getQueryParameter('page',LOCATION_URL);
        var prev_view = getQueryParameter('prev_view',LOCATION_URL);
        var mode = getQueryParameter('mode',LOCATION_URL);
        var quiz_id = getQueryParameter('quizid', LOCATION_URL);
        var type = getQueryParameter('type', LOCATION_URL);
        var take_quiz = getQueryParameter('takeQuiz', LOCATION_URL);

        CONTROLLER.setVariable("activecatid", catid);

        // scorm kit data
        params_data = {
            "catid": catid,
            "chapterid": chapterid,
            "courseid": courseid,
            "resourceid": resourceid,
            "bookmark": "true",
            "duration": duration,
            "mode": "bookmark",
            "prev_view": prev_view,
            "bookmark_id": bookmarkid,
            "title" : title ,
            "page" : page,
            "nid" : resourceid,
            "quiz_id" : quiz_id,
            "take_quiz" : take_quiz
        };

        if(type == "quiz"){
            callQuizView(params_data['nid']);
        }

    }catch(e){
        console.log('error while extracting params'+ e);
    }
}

function getRedirectPath(url){
    var redirectPath = url;
    if (APP_TYPE == "android") {
        var resourcepath = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED + "&useractive=true&path=" + encodeURIComponent(redirectPath);
        resourcepath = Platform.getAuthenticatedURL(resourcepath, 'GET', 'cloud');
    } else {
        var resourcepath = redirectPath;
    }

    return resourcepath;
}

function get_salezart_carousel_images(callback){
    try{
        if (typeof OBJECT_USER_GROUPS != 'undefined') {
            var imagesData;
            var carousel_image_data;
            var banners_url = APP_BANNER_JSON_URL;
            MobileUserService.prototype.getBannersInfo(banners_url,function (data){
                var active_group = CONTROLLER.getVariable("activegroupid");
                var group_info = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, active_group, "content"));
                if(group_info && group_info['parent'] != '352707'){
                    var active_group = group_info.parent;
                    CONTROLLER.setVariable("group_info", JSON.stringify(group_info));
                    var group_info = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, active_group, "content"));
                }
                if(group_info != null && group_info['parent'] == '352707'){
                    var groupName = group_info['title'].toLowerCase();
                    if (data != ERROR_MESSAGE) {
                        imagesData = data.groups;
                        if(typeof HOMEPAGEDATA['online_images'] == "undefined"){
                            HOMEPAGEDATA['online_images'] = [];
                            HOMEPAGEDATA['online_images_by_groups'] = {};
                        }
                        if(HOMEPAGEDATA['online_images'].indexOf(group_info["nid"]) == -1){
                            for(var j=0; j < imagesData.length; j++){
                                if(Object.keys(imagesData[j]) == groupName){
                                    HOMEPAGEDATA['carousel_images'] = [];
                                    carousel_image_data =  imagesData[Object.keys(imagesData)[j]][groupName];
                                    HOMEPAGEDATA['online_images'].push(group_info["nid"]);
                                    break;
                                };
                            }
                        }else if(HOMEPAGEDATA['online_images'].indexOf(group_info["nid"])> -1){
                            for(var j=0; j < imagesData.length; j++){
                                if(Object.keys(imagesData[j]) == groupName){
                                    HOMEPAGEDATA['carousel_images'] = [];
                                    carousel_image_data =  imagesData[Object.keys(imagesData)[j]][groupName];
                                    break;
                                };
                            }
                        }
                        return callback(carousel_image_data);
                    }
                }
                else{
                    return callback(null);
                }
            });
        }
    }
    catch(e){
        console.log('function get_salezart_carousel_images error');
    }
}

//Message shown when the maximum answer limit is reached for the particular section.
function SECTION_LIMIT_REACHED() {
    return "You have already attempted a maximum of " + this.limit + " out of " + this.all + " questions in this section. If you would like to attempt this question instead, please reset(Clear Response) another question in this section first.";
};

function trackScreenEventDetails() {
    try {
    }
    catch(e){
        console.log(e.message);
    }
};

function loadAdaptiveQuiz(LOCATION_URL){
    try{
        Client.showProgressBar(t("Loading") + "...");
        var quiz_id = getQueryParameter('quiz_id', LOCATION_URL);
        var test_coll_id = getQueryParameter('test_coll_id', LOCATION_URL);
        var type = getQueryParameter('type', LOCATION_URL);
        var content_type = getQueryParameter('content_type', LOCATION_URL);

        if(typeof quiz_id != 'undefined' && quiz_id != null && quiz_id != ''){
            var quizService = CONTROLLER.getService("Quiz");
            var quiz_object = quizService.getQuiz(quiz_id, true);
            if(quiz_object != 'error'){
                var questions = quizService.getQuestionsForQuiz(quiz_id);
                if (questions != "") {
                    beginQuiz(this, quiz_id, quiz_object.type);
                }else{
                    loadAdaptiveTestColl();
                }
            }else{
                loadAdaptiveTestColl();
            }
        }else{
            loadAdaptiveTestColl();
        }
    }catch(e){
        Client.closeProgressBar();
        var app_url = Platform.getAppLocalURL();
        window.location.replace(app_url+'&nosplash=true');
        console.log('function loadAdaptiveQuiz');
    }
}

function loadAdaptiveTestColl(){
    try{
        if (window.location.href.includes('?')) {
            window.history.pushState({}, null, window.location.href.split('?')[0]);
            LOCATION_URL = window.location.href.split('?')[0];
        }
        Client.closeProgressBar();
        CONTROLLER.loadView("TestCollection");
    }catch(e){
        console.log('function loadAdaptiveQuiz');
    }
}

function quizTemplateBack(){
    var mode = getQueryParameter('mode');
    if(typeof mode != 'undefined' && mode != "null" && mode == "scormToolKit"){
        CONTROLLER.loadView("HomePage");
    }
}

function onActivityWindowClose(){
    var activityPreference = Platform.getPreference("closeActivity");
    
    var courseSelected = Platform.getPreference("courseSelected");

    if(typeof activityPreference != "undefined" && activityPreference != "" && 
    (activityPreference == "flashcard_vvh" || activityPreference == 'roleplay_vvh' 
    || activityPreference == "scorm_vvh" || activityPreference == 'html_vvh')){
        if(activityPreference == 'roleplay_vvh'){
            CONTROLLER.animateViewLoad = false;
            var activecourseid = CONTROLLER.getVariable('activecourseid');
            var previousView = CONTROLLER.getVariable('previousView');
            var roleplay_vvh_completed = Platform.getPreference('roleplay_vvh_completed');
            if(typeof roleplay_vvh_completed == 'undefined' || roleplay_vvh_completed == null || roleplay_vvh_completed == '' || roleplay_vvh_completed != 'true'){
                var video_details = OBJECT_VIDEO_DETAILS;
                var currentTime = Math.floor(new Date().getTime() / 1000);
                var videoParams = {'catid':video_details.categoryid, 'categoryid':video_details.categoryid, 'chapterid':video_details.chapterid, 'courseid':video_details.courseid, 'resourceid':video_details.resourceid, 'uid':OBJECT_USER_ID};
                var videoViewHistory = Platform.getVideoViewHistory(JSON.stringify(videoParams));
                videoViewHistory = JSON.parse(videoViewHistory);
                if(typeof videoViewHistory.status != 'undefined' && videoViewHistory.status == "inprogress" && videoViewHistory.endtime == '0'){
                    video_details.starttime = videoViewHistory.starttime;
                    video_details.endtime = currentTime;
                    video_details.session = OBJECT_USER_ID + '-' + video_details.starttime;
                    video_details.status = 'inprogress';
                }else if(typeof videoViewHistory.status != 'undefined' && videoViewHistory.status == "completed" && videoViewHistory.endtime == '0'){
                    video_details.starttime = videoViewHistory.starttime;
                    video_details.endtime = currentTime;
                    video_details.session = OBJECT_USER_ID + '-' + video_details.starttime;
                    video_details.status = 'completed';
                }
                Platform.addOrUpdateVideoViewHistory(JSON.stringify(video_details));
            }else{
                Platform.setPreference('roleplay_vvh_completed', "");
            }
            if(typeof activecourseid != 'undefined' && activecourseid != '' && typeof previousView != 'undefined' && previousView == "AllSubjects"){
                CONTROLLER.loadView('Course', activecourseid);
            }
        }else{
            if (activityPreference == "scorm_vvh") {
                OBJECT_VIDEO_DETAILS = JSON.parse(Platform.getPreference("scorm_vvh_details"));
            }
            var resourceview  = new ResourceView()
            resourceview.updateVideoViewHistory()
        }
        Client.closeProgressBar();
        if($(".notifications_container").length > 0){
            $("#footer_wrapper").addClass("hidden");
        }
    }
    if(activityPreference.indexOf("productsuccess") > -1 
    && (activityPreference.indexOf("product_id") > -1 || activityPreference.indexOf("buynowsuccess") > -1 ) ){
        var buynowpath = activityPreference.split("productsuccess-url=")[1]
        onBuyNowSuccess(buynowpath);
    }
    else if(activityPreference.indexOf("preview") > -1 
    && ( activityPreference.indexOf("course_link") > -1 )){
        var course_path = activityPreference.split("preview=")[1];
        launchFromPortalURL(course_path);
    }
    Platform.setPreference("closeActivity","");
    Platform.setPreference("activityheadertitle", " ");

    // for professional course since we open chatbot directly we will close the app directly on click back 
    if(typeof courseSelected !== "undefined" && courseSelected === "Professional"){
        Platform.closeApplication();
    }
}

function onBuyNowSuccess(buynowpath) {
  try {
    Client.showProgressBar("Upgrading user");
    var user = CONTROLLER.getService("User");
    if (Platform.getNetworkState() == false) {
      Client.closeProgressBar();
      return;
    }
    DATABASE_DOWNLOADED = false;
    var newUserProducts = user.downloadUserProducts();
    if (newUserProducts == "error" || typeof newUserProducts === "undefined") {
      Client.closeProgressBar();
      return;
    } else if (newUserProducts == "access forbidden") {
      OBJECT_USER_DATA.loginRequiredOnline = true;
      Platform.saveObject(
        "currentuser",
        1,
        "content",
        $.trim(JSON.stringify(OBJECT_USER_DATA))
      );
      Platform.saveObject(
        "user",
        OBJECT_USER_DATA.uid,
        "content",
        $.trim(JSON.stringify(OBJECT_USER_DATA))
      );
      Platform.showMessage(USER_LOGOUT_ERROR);
      $("body").html("");
      $("body").append(
        '<div id="wrapper"><div id="header_wrapper"></div>\n\
                    <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>'
      );
      CONTROLLER.loadView("Signin");
      Client.closeProgressBar();
      return;
    } else {
      
      Platform.getNewUserSubscription(OBJECT_USER_ID);
      Platform.startGroupSync();
      Platform.clearCache();
      Client.closeProgressBar();
      var launch = launchFromPortalURL(buynowpath);
      if(!launch){
        window.location.href = window.location.href;
      }
      return;
    }
  } catch (e) {
    Client.closeProgressBar();
    console.log("error while setting zoom or setting device orientation.");
  }
}

function loadWebinarView() {
    try {
        if (Platform.getNetworkState() == false) {
            Client.removeDialog();
            Client.closeProgressBar();
            confirm("Internet is required! <br/> Would you like to retry?", function () {
                window.location.reload();
            });
            $("#content_wrapper").empty();
            return;
        } else {
            Client.closeProgressBar();
            CONTROLLER.loadView("Webinar");
        }

    } catch (e) {
        Client.closeProgressBar();
        console.log("Function loadWebinarView caused error.");
    }
}

function loadAdaptiveLearning(LOCATION_URL){
    try{
        Client.showProgressBar(t("Loading") + "...");
        var type = getQueryParameter('type', LOCATION_URL);
        if(typeof type != 'undefined' && type == 'adaptive_course'){
            var course_nid = getQueryParameter('course_id', LOCATION_URL);
            var course_tid = getQueryParameter('course_tid', LOCATION_URL);
            if(typeof course_nid != 'undefined'){
                var courseDetails = Platform.getObjectProperty(OBJECT_CATEGORY,course_nid,"content");
                var chapters = "";
                if(EXEC_MODE == 'mobile'){
                    var chaptersForCourse = Platform.getRelatedObjects(OBJECT_CATEGORY, course_nid, OBJECT_COURSE)
                    if(typeof chaptersForCourse != 'undefined' && chaptersForCourse != null && chaptersForCourse != ''){
                        chapters = JSON.parse(chaptersForCourse);
                    }
                }
                if(EXEC_MODE == 'online' && typeof courseDetails != 'undefined' && courseDetails != null && courseDetails != ''){
                    CONTROLLER.loadView("Category", course_nid);
                }
                else if(EXEC_MODE == 'mobile' && typeof courseDetails != 'undefined' && courseDetails != null && courseDetails != '' && chapters != '' && typeof chapters[OBJECT_COURSE] != 'undefined' &&  chapters[OBJECT_COURSE].length > 1){
                    var adaptiveGroupId = "";
                    if(typeof OBJECT_USER_GROUPS_JSON != 'undefined' && OBJECT_USER_GROUPS_JSON.length > 0){
                        for(var i = 0; i < OBJECT_USER_GROUPS_JSON.length; i++){
                            if(OBJECT_USER_GROUPS_JSON[i].type == "adaptive" && OBJECT_USER_GROUPS_JSON[i].title != "Adaptive All Content"){
                                adaptiveGroupId = OBJECT_USER_GROUPS_JSON[i].nid;
                            }
                        }
                    }
                    CONTROLLER.setVariable('activegroupid', adaptiveGroupId);
                    CONTROLLER.loadView("Category", course_nid);
                }else{
                    Platform.updateAdaptiveContent();
                    if (window.location.href.includes('?')) {
                        window.history.pushState({}, null, window.location.href.split('?')[0]);
                        LOCATION_URL = window.location.href.split('?')[0];
                    }
                    setTimeout(() => {
                        CONTROLLER.loadView("AdaptiveLearning");
                    }, 1500);
                }
            }
        }else if(typeof type != 'undefined' && type == 'adaptive_levelling_quiz'){
            var quiz_id = getQueryParameter('quiz_id', LOCATION_URL);
            var study_plan_id = getQueryParameter('study_plan_id', LOCATION_URL);
            var quizService = CONTROLLER.getService("Quiz");
            var quiz_object = quizService.getQuiz(quiz_id, true);
            if(quiz_object != 'error'){
                Platform.setPreference('quiz_'+ quiz_id, JSON.stringify(quiz_object));
                var platformview = new platformView();
                platformview.setDownloadQuizType(quiz_object);
                var questions = quizService.getQuestionsForQuiz(quiz_id);
                if (questions != "") {
                    beginQuiz(this, quiz_id, quiz_object.type);
                }else{
                    alert('Quiz generation failed. Please try later', true);
                    if (window.location.href.includes('?')) {
                        window.history.pushState({}, null, window.location.href.split('?')[0]);
                        LOCATION_URL = window.location.href.split('?')[0];
                    }
                    CONTROLLER.loadView("HomePage");
                }
            } else {
                alert(NO_QUESTIONS, true);
                if (window.location.href.includes('?')) {
                    window.history.pushState({}, null, window.location.href.split('?')[0]);
                    LOCATION_URL = window.location.href.split('?')[0];
                }
                CONTROLLER.loadView("HomePage");
            }
        }
    }catch(e){
        Client.closeProgressBar();
        console.log("Function loadAdaptiveLearning caused error.");
        var app_url = Platform.getAppLocalURL();
        window.location.replace(app_url+'&nosplash=true');
    }
}

function loadTestSeries(url){
    try {
        Client.showProgressBar(t("Loading") + "...");
        var settings = CONTROLLER.getService("Settings");
        var platformview = new platformView();
        this.currentuser = settings.getLastLoggedinUser();
        var userGroups = this.currentuser.groups;
        
        var tcid = getUrlVars(url, "tcid");
        var quizStatus = getUrlVars(url, "status");
        var quizService = CONTROLLER.getService("Quiz");

        setTimeout(function () {
            if (Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                Client.closeProgressBar();
                return false;
            }
            Client.showProgressBar(t("Loading") + "");
            var testCollection = quizService.getTestCollectionFromId(tcid);
            if (testCollection == 401) {
                platformview.showUserSubscriptionError();
                Client.closeProgressBar();
                return false;
            }
            else if (testCollection == 403) {
                onAccessDenied();
                Client.closeProgressBar();
                return false;
            } else if (testCollection == ERROR_MESSAGE) {
                alert(t("ERROR_MESSAGE", true));
                Client.closeProgressBar();
                return false;
            }

            if(typeof quizStatus != 'undefined' && quizStatus != ''){
                testCollection[0]['quizstatus'] = quizStatus;
            }

            CONTROLLER.setVariable("skip_testcollection", "true");
            CONTROLLER.loadView("TestSeriesSelection", testCollection[0]);
            Client.closeProgressBar();
        }, 400);

    } catch (e) {
        Client.closeProgressBar();
        console.log("function : callTestSeries" + e.message);
    }
}

function loadTestSeriesTabViewFromBanners(status, url, title){
    try{
        Client.showProgressBar(t("Loading") + "...");
        var tcid = getUrlVars(url, "tcid");
        CONTROLLER.setVariable("tcid", tcid);
        CONTROLLER.setVariable("quiz_title", true)
        var params = {};
        params['quiz_title'] = title;
        params['title'] = title;
        params['nid'] = tcid;
        
        // loading test series tab view from banners
        loadTestSeriesTabView(status, tcid, params);

    }catch(e){
        Client.closeProgressBar();
        console.log("function : loadTestSeriesTabViewFromBanners:" + e.message);
    } 
}

function loadTestSeriesTabViewFromNotification(status, tcid, title){
    try{
        Client.showProgressBar(t("Loading") + "...");;
        CONTROLLER.setVariable("tcid", tcid);
        CONTROLLER.setVariable("quiz_title", true)
        var params = {};

        if(typeof title != 'undefined' && title == ""){
            if (Platform.getNetworkState() == true) {
                var test_collection = MobileQuizService.prototype.getTestCollectionFromId(tcid);
                if(typeof test_collection != 'undefined' && test_collection.length > 0){
                    title = test_collection[test_collection.length-1].title
                }
            }else{
                alert(INTERNET_ERROR, function(){
                    CONTROLLER.setVariable("previousView","HomePage");
                    CONTROLLER.loadView('Notifications', 'announcements');
                });
                Client.closeProgressBar();
                return false;
            }
          
        }

        params['quiz_title'] = title;
        params['title'] = title;
        params['nid'] = tcid;
        
        // loading test series tab view from notification
        loadTestSeriesTabView(status, tcid, params);

    }catch(e){
        Client.closeProgressBar();
        console.log("function : loadTestSeriesTabViewFromNotification:" + e.message);
    } 
}

function loadTestSeriesTabView(status, tcid, params){
    try{
        // checking for subscription and adding it to test collection
        var test_collections = MobileQuizService.prototype.getTestCollection(OBJECT_USER_GROUPS.join(','));
        // filter testcollection id
        var subscription = ""; var price = "";
        for(var test_coll = 0; test_coll < test_collections.length; test_coll++){
            if(test_collections[test_coll].nid == tcid){
                subscription = test_collections[test_coll].subscription;
                price = test_collections[test_coll].pricing;
            }
        }
        params['subscription'] = subscription;
        params['price'] = price;

        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            Client.closeProgressBar();
            return false;
        }
        if(typeof status != 'undefined' && status != "" && status == 'active'){
            CONTROLLER.setVariable("quizType", "active");
            params['id'] = 'active';
            CONTROLLER.loadView("TestSeries", params);
        }
        else if(typeof status != 'undefined' && status != "" && status == 'attempted'){
            CONTROLLER.setVariable("quizType", "attempted");
            params['id'] = 'attempted';
            CONTROLLER.loadView("TestSeries", params);
        }
        else if(typeof status != 'undefined' && status != "" && status == 'missed'){
            CONTROLLER.setVariable("quizType", "missed");
            params['id'] = 'missed';
            CONTROLLER.loadView("TestSeries", params);
        }
        else if(typeof status != 'undefined' && status != "" && status == 'upcoming'){
            CONTROLLER.setVariable("quizType", "upcoming");
            params['id'] = 'upcoming';
            CONTROLLER.loadView("TestSeries", params);
        }
    }catch(e){
        Client.closeProgressBar();
        console.log("function : loadTestSeriesTabView:" + e.message);
    } 
}

function getRolePlayRecFileFromMobile(filepath){
    try{
        var user_id = OBJECT_USER_ID;
        var roleplay_data = JSON.parse(Platform.getPreference('roleplay_data'));
        var roleplay_nid = roleplay_data.roleplay_nid;
        var roleplay_video_id = roleplay_data.roleplay_video_id;
        var folderName = "roleplay_record_folder_"+ roleplay_nid;
        if(typeof filepath != 'undefined' && filepath != ''){
            var adjustedFilePath = "file:" + filepath.replace(/\s/g, '%20');
            var url =  OBJECT_SERVER_DOMAIN + "/api/user/fileupload?uid="+user_id+'&folder='+folderName;
            var upload_data = {"message" : "Uploading Video...", "data" : adjustedFilePath, "url" : url, "method" : "post", "showdialog" : "true", "callback" : "getUploadedVideoPath"};
            upload_data = JSON.stringify(upload_data);
            Platform.makeAsyncTaskCall(upload_data);
        }else{
            var postData = {"roleplay_nid":roleplay_nid, "roleplay_video_id":roleplay_video_id, "uid":user_id, "filepath":""};
            MobileResourceService.prototype.setUserRolePlayVideos(postData)
            Platform.setPreference("roleplay_upload_status", true);
        }
    }catch(e){
        console.log("function : getRolePlayRecFileFromMobile:" + e.message);
    }
};

function getUploadedVideoPath(data){
    try{
        if(typeof data != 'undefined' && typeof data.data != 'undefined' && data.status == 200){
            if (CONTROLLER.activeViewName == 'Certificatequiz' || CONTROLLER.activeViewName == 'Practicequiz'){
                if(!isArray(data.data)){
                    var uploadedPath = JSON.parse(data.data);
                }else{
                    var uploadedPath = data.data;
                }
                var filepath = uploadedPath[0].filepath
                if(filepath.indexOf('.mp3') > -1 || filepath.indexOf('.mp4') > -1 || filepath.indexOf('.mkv') > -1 || filepath.indexOf('.wav') > -1){  
                    if($('.uploaded_audio_video').length > 0){
                        $('.uploaded_audio_video').attr('src', uploadedPath[0].filepath);
                        if($('.uploaded_audio_video').hasClass('hidden')){
                            $('.uploaded_audio_video').removeClass('hidden');
                        }
                    }else if($(".long_answer_text_field").length > 0){
                        if(filepath.indexOf('.mp3') > -1 || filepath.indexOf('.wav') > -1){
                            $('#edit-tries').append('<br/><audio src="' + filepath + '" type="audio/mp3/wav" controls controlsList="nodownload noplaybackrate"></audio><br/><br/>');
                        }else if(filepath.indexOf('.mp4') > -1 || filepath.indexOf('.mkv') > -1){
                            $('#edit-tries').append('<br/><video src="' + filepath + '" type="video/mp4/mkv" controls controlsList="nodownload noplaybackrate"></video><br/><br/>');
                        }
                    }
                    if(EXEC_MODE == 'online'){
                        $(".ui-widget-overlay").remove()
                        $('.attach_content_wrapper').remove()
                    }
                }else{
                    $(".blockUI").remove()
                    $(".ui-widget-overlay").remove()
                    alert(FILE_NOT_SUPPORTED);
                    return;
                }
            }else{
                var user_id = OBJECT_USER_ID;
                var roleplay_data = JSON.parse(Platform.getPreference('roleplay_data'));
                var roleplay_nid = roleplay_data.roleplay_nid;
                var roleplay_video_id = roleplay_data.roleplay_video_id;
                var uploadedPath = JSON.parse(data.data);
                var postData = {"roleplay_nid":roleplay_nid, "roleplay_video_id":roleplay_video_id, "uid":user_id, "filepath":uploadedPath[0].filepath};
                MobileResourceService.prototype.setUserRolePlayVideos(postData);
                Platform.setPreference("roleplay_upload_status", true);
            }
            Client.closeProgressBar()
        }else if(typeof data != 'undefined' && typeof data.data != 'undefined' && data.status == 413){
            alert(t("UPLOAD_LIMIT_EXCEEDED", true));
        }else{
            alert(t("ERROR_MESSAGE", true))
        }
        Client.closeProgressBar()
    }catch(e){
        console.log("function : getUploadedVideoPath:" + e.message);
    }
};

function replaceParamsinTemplate(certified_text, reports, params)
{
    try{
        var user_attempts='';
        var max_attempts='';
        var resourceid=CONTROLLER.getVariable("activeresourceid");
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO,resourceid,"content"));
        var resourceService = CONTROLLER.getService("Resource");
        max_attempts = params.report.takes;
        user_attempts = params.report.attempts + 1;
        var quiz_start = getFormattedDateWithTime(params['report'].quiz_open); 
        var quiz_end = getFormattedDateWithTime(params['report'].quiz_close);
        var user_quiz_end = getFormattedDateWithTime(params['report'].quiz_result['time_end']);
        var activeContent_type = CONTROLLER.getVariable("content_type");
        if(typeof params['report'].external_id != 'undefined'){
            var quiz_external_id = params['report'].external_id;
        }
        if (typeof activeContent_type != 'undefined' && typeof OBJECT_TEST_COLLECTION != "undefined" && activeContent_type == OBJECT_TEST_COLLECTION) {
            max_attempts = TEST_COLLECTION_MAX_ATTEMPTS;
                user_attempts = TEST_COLLECTION_USER_ATTEMPTS + 1;
        }
        certified_text = certified_text.replace(/@user_attempt/g,!checkIsEmpty(user_attempts) ?user_attempts : "");
        certified_text = certified_text.replace(/@max_attempt/g, !checkIsEmpty(max_attempts) ?max_attempts : "");
        certified_text = certified_text.replace(/@percentage_score%/g, !checkIsEmpty(reports.perc) ? reports.perc + '%' : ""  + '%');
        certified_text = certified_text.replace(/@percentage_score/g, !checkIsEmpty(reports.perc) ? reports.perc : "");
        certified_text = certified_text.replace(/@pass_rate%/g, !checkIsEmpty(params.report['pass_rate']) ?  params.report['pass_rate'] + '%' : "" + '%');
        certified_text = certified_text.replace(/@pass_rate/g,  !checkIsEmpty(params.report['pass_rate']) ? params.report['pass_rate'] : "");
        certified_text = certified_text.replace(/@score/g, !checkIsEmpty(reports['user_correct']) ?  reports['user_correct'] : "");
        certified_text = certified_text.replace(/@max_score/g,  !checkIsEmpty(reports['total_score']) ?  reports['total_score'] : "");
        certified_text = certified_text.replace(/@total_questions/g,  !checkIsEmpty(reports['total']) ? reports['total'] : "");
        certified_text = certified_text.replace(/!certificate/g, "");
        certified_text = certified_text.replace(/@quiz_nid/g, !checkIsEmpty(params.report.nid) ? params.report.nid : "");
        certified_text = certified_text.replace(/{quiz\.title}/g,!checkIsEmpty(params['report'].title) ? params['report'].title : "");
        certified_text = certified_text.replace(/{quiz\.start_date}/g, !checkIsEmpty(quiz_start) ? quiz_start : "").replace(/{quizresult\.time_start}/g,!checkIsEmpty(quiz_start) ? quiz_start : "")
        certified_text = certified_text.replace(/{quiz\.end_time}/g,!checkIsEmpty(quiz_end) ? quiz_end : "");
        certified_text = certified_text.replace(/{quiz\.start_time}/g,!checkIsEmpty(quiz_start) ? quiz_start : "");
        certified_text = certified_text.replace(/{user_quiz\.time_end}/g,!checkIsEmpty(user_quiz_end) ? user_quiz_end : "").replace(/{quizresult\.time_end}/g,!checkIsEmpty(user_quiz_end) ?user_quiz_end : "")
        certified_text = certified_text.replace(/{quiz\.external_id}/g,!checkIsEmpty(quiz_external_id) ? quiz_external_id : "").replace(/{quiz\.field_quiz_external_id}/g,!checkIsEmpty(quiz_external_id) ?quiz_external_id : "");
        certified_text = certified_text.replace(/{quiz\.quiz_type}/g,!checkIsEmpty(params['report'].type) ? params['report'].type : "").replace( /{quiz\.field_quiz_type}/g,!checkIsEmpty(params['report'].type) ? params['report'].type : "");
        certified_text = certified_text.replace(/{quiz\.quiz_id}/g,!checkIsEmpty(params.report.nid) ? params.report.nid : "").replace(/{quiz\.nid}/g,!checkIsEmpty(params.report.nid) ? params.report.nid : "");
        certified_text = replaceUserAttributes(certified_text);
        if(certified_text.indexOf('!percentage_score_widget') >= 0 ){
            certified_text = certified_text.replace("!percentage_score_widget", "");
            this.hide_score_widget = false;
        }else{
            this.hide_score_widget = true;
        }
        var results = MobileQuizService.prototype.getQuizResult(params.report['nid']);
        if (results != '' && results != null && results['quiz_result'].length != 0) {
            var quiz_res = results['quiz_result'][results['quiz_result'].length - 1];
            certified_text = certified_text.replace(/@result_id/g, !checkIsEmpty(quiz_res['result_id']) ? quiz_res['result_id'] : "");
        } else {
            certified_text = certified_text.replace(/@result_id/g,!checkIsEmpty(params.report.quiz_result.result_id) ? params.report.quiz_result.result_id : "");
        }
        if(PROJECT_TITLE == "LearnWISE"){
             certified_text = certified_text.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
        }
        return certified_text;
    }
    catch(e){
        console.log("function :- replaceParamsinTemplate " + e.message);
    }
};

function replaceUserAttributes(certified_text){
    try {
        var userData;
        userData = $.parseJSON(Platform.getObjectProperty("userAttributes", OBJECT_USER_ID, "content"));
        if(Platform.getNetworkState() == true && (userData == '' || userData == null)){
            userData = MobileUserService.prototype.getUserAttributes();
        }
        for (const key in userData) {
            if (userData.hasOwnProperty(key)) {
              const value = userData[key];
              const placeholder = `{user.${key}}`;
              if(key == "groups"){
                certified_text = certified_text.replace(/{user\.groups}/g,!checkIsEmpty(value) ? value : "").replace(/{user\.og_register}/g,!checkIsEmpty(value) ? value : "");
              }else if(key == "field_cp_title_value"){
                certified_text = certified_text.replace(/{user\.field_cp_title_value}/g,!checkIsEmpty(value) ? value : "").replace(/{user\.field_cp_title}/g,!checkIsEmpty(value) ? value : "");
              }else if(key == "field_name_value"){
                certified_text = certified_text.replace(/{user\.field_name_value}/g,!checkIsEmpty(value) ? value : "").replace(/{user\.field_name}/g,!checkIsEmpty(value) ? value : "");
              }else{
                certified_text = certified_text.split(placeholder).join(!checkIsEmpty(value) ? value : "");
                certified_text = certified_text.split(placeholder.replace("_value","")).join(!checkIsEmpty(value) ? value : "");
              }
            }
          }
        return certified_text;
    } catch (e) {
        console.log("function :- replaceUserAttributes " + e.message);
    }
};

function getScoreCertifiedData (reports,params){
    try{
        var certified_text = "";
        if((parseFloat(reports.perc) >= parseFloat(params.report['pass_rate'])) && params.report['pass_rate']) {
            certified_text = params.report['pass_result_template'];
        }else{
            certified_text = params.report['fail_result_template'];
        }
        //added to show inprogress template after submitting quiz
        //stringify questions to check long_answer or short_answer to show inprogress template
        var stringfiedQuestions = JSON.stringify(params['report'].questions);
        if (typeof params['report'].quiz_result.eval_completed == 'undefined' && (stringfiedQuestions.indexOf("short_answer") > -1 || stringfiedQuestions.indexOf("long_answer") > -1)) {
            if(typeof params.report['inprogress_result_template'] != 'undefined' && (params.report['inprogress_result_template'] != '' ||  params.report['inprogress_result_template'] != null) ){
                return params.report['inprogress_result_template'];
            }
        }
        return certified_text;
    }catch(e){
        console.log("function :- getScoreCertifiedData " + e.message);
    }
};

// load video or pdf in chatbot website
function loadChatBot(LOCATION_URL){
    try{
        Client.showProgressBar(t("Loading") + "...");
        var params_data = {};
        var resourceid = getQueryParameter('nid',LOCATION_URL) || "";
        var quiz_id = getQueryParameter('nid',LOCATION_URL) || "";
        var duration  = getQueryParameter('offset',LOCATION_URL) || "";
        var type = getQueryParameter('type', LOCATION_URL) || "";
        var contentTitle = getQueryParameter('title', LOCATION_URL) || "";

        if(type == "link"){
            let externalUrl = LOCATION_URL.split("?")[0];
            Client.closeProgressBar();
            loadContentInWebview(externalUrl, true, 'portrait', true, contentTitle);
        }else if(type == "flashcard"){
            let externalUrl = LOCATION_URL.split("?")[0];
            MobileUserService.prototype.loginRedirect(OBJECT_USER_DATA.email);
            Client.closeProgressBar();
            loadContentInWebview(externalUrl, true, 'portrait', true, contentTitle);
        }else{
            // get content details from resource id
            var content = MobileResourceService.prototype.getContentById(resourceid);

            if(typeof content.status != 'undefined' && (content.status == 403 || content.status == 404 || content.status == 401)){
                Client.closeProgressBar();
                alert("Resource not found", function(){
                    
                });
                return;
            }

            content = JSON.parse(content['data']);

            // get all the content details
            var catid = content[0][OBJECT_CATEGORY][0];
            var chapterid = content[0][OBJECT_CHAPTER][0];
            var courseid = catid;
            var title =  content[0]['title'];
            var page = getQueryParameter('offset',LOCATION_URL);
            var prev_view = getQueryParameter('prev_view',LOCATION_URL);

            CONTROLLER.setVariable("activecatid", catid);

            // chatbot data
            params_data = {
                "catid": catid,
                "chapterid": chapterid,
                "courseid": courseid,
                "resourceid": resourceid,
                "bookmark": "true",
                "duration": duration,
                "mode": "bookmark",
                "prev_view": prev_view,
                "title" : title ,
                "page" : page,
                "nid" : resourceid,
            };
            
            
            // if it's a question load question
            // else load video or document
            if(type == "question"){
                params_data["type"] = type;
                Client.closeProgressBar();
                loadQuizFavorites(quiz_id, resourceid);
            }else{
                var isResourcePlayable = platformView.prototype.isResourcePlayable(params_data.nid, params_data.courseid, OBJECT_VIDEO, params_data.catid, true, params_data);
                if (isResourcePlayable == true) {
                    CONTROLLER.setVariable("previousView", 'chatbot');
                    Client.closeProgressBar();
                    CONTROLLER.setVariable('portrait_video', true);
                    QUIZ_IN_PROGRESS = true;
                    // disable portrait video
                    LOAD_VIDEO_PLAYER_VIEW = false;
                    CONTROLLER.loadView("Resource", params_data);
                }else{
                    Client.closeProgressBar();
                    alert(UNSUBSCRIBED_RESOURCE_MESSAGE);
                }
            }
        }
        
    }catch(e){
        console.log('error while extracting params in loadChatBot '+ e.message);
    }
}



function makeAjaxCall(urldata,content,asynctype) {
    var result = "";
    var params = "";
    var status = "";

    if(typeof methodtype == 'undefined'|| methodtype == ""){
        methodtype = 'post';
    }
    if(!content || methodtype == "get") {
        methodtype = "get";
        content = false;
    } else if(ignorecontent){
        content = content;
    } else {
        content = "content=" + content;
    }

    var xhr_field = {
        withCredentials: true
    };
    if (urldata.indexOf('.json') > -1) {
        xhr_field = false;
    }

    if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
        if (urldata.indexOf('api/login/redirect') > -1) {
            urldata = Platform.getAuthenticatedURL(urldata, 'GET', 'cloud');
        } else if (urldata.indexOf('api/login/user') > -1) {
            urldata = Platform.getAuthenticatedURL(urldata, 'POST', 'cloud');
        } else {
            var deviceId = Platform.getDeviceMacAddress();
            if(deviceId != "")
                urldata += "&deviceid=" + deviceId;
        }
    }
    if(typeof result == "undefined" || result == ""){
        var THIS = this;
        $.ajax({
            type: methodtype,
            url: urldata,
            dataType: 'text',
            data: content,
            timeout: 40000,
            xhrFields: xhr_field,
            crossDomain: true,
            contentType: "application/x-www-form-urlencoded",
            async: asynctype,
            success: function (data, textStatus, xhr) {
                if(typeof data == "object"){
                    data = JSON.stringify(JSON.stringify(data));
                }
                else{
                    data = JSON.stringify($.trim(data));
                }
                result = '{"status":'+xhr.status+',"data":'+data+'}';
            },
            error: function(xhr, status, error) {
                status = xhr.status;
                result = '{"status":'+status+'}';
                var error_message = error.message ? error.message : error;
                console.log("url = " + urldata + " Error = " + error_message);
                if(status == "500" || status == "502" || status == "503" || status == "504"){
                  Client.closeProgressBar();
                  alert(ERROR_MESSAGE);
                }else if(status == "403"){
                    for(var i in LOGIN_RESTRICTED_URLS){
                        if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                            onAccessDenied();
                        }
                    }
                }
            }
        });
    }

};

function getCourseOfSearchElement (chapterid, categories) {
    var categoryService = CONTROLLER.getService('Category');
    for (var i = 0; i < categories.length; i++) {
        for (var j = 0; j < OBJECT_USER_GROUPS.length; j++) {
            var courses = categoryService.getCoursesForCategory(categories[i], OBJECT_USER_GROUPS[j]);
            if (courses) {
                for (var k = 0; k < courses[OBJECT_COURSE].length; k++){
                    if (courses[OBJECT_COURSE][k][OBJECT_CHAPTER].indexOf(chapterid) > -1) {
                        return courses[OBJECT_COURSE][k];
                    }
                }
            }
        }
    }
    return "";
};

function QuizResultSyncOfResultIdEmpty() {
 
    var getAllResults = function () {
        try {
            var results = Platform.getObjectsOfType("quiz_result");
            if(results != ""){
                results = $.parseJSON(results);
                return results["quiz_result"];
            }  
            else{
                return '';
            }
 
        } catch (e) {
            console.log("QuizResultSync - getAllResults : " + e.message);
            return {};
        }
    };
 
    var submitResults = function (results) {
        try {
            var syncedResultsCount = 0;
            var resultset2 = $.parseJSON(JSON.stringify(results))
            for (var i in results) {
               
                //get all empty objectid objects and check for submitted results
                if(results[i]["is_evaluated"] == "1" && results[i]["result_id"] == ""){
                    for (var j in resultset2) {
                        if( (results[i]["nid"] == resultset2[j]["nid"]) && resultset2[j]["result_id"] != ""){
                            mergeJsons(resultset2[j], results[i])
                            results[i]["result_id"] = resultset2[j]["result_id"];
                            results[i]["gen_result_id"] = resultset2[j]["gen_result_id"];
                            results[i]["time_start"] = resultset2[j]["time_start"];
                            Platform.saveObject("quiz_result",results[i]["result_id"],"content",JSON.stringify(results[i]));
                            console.log("Synced results for " + results[i]["nid"]);
                            syncedResultsCount++;
                            break;
                        }
                    }
                }
            }

            for (var i in results) {
               
                //get all temp result objects and check for in progress results with subject/section navigation issue
                if(results[i]["is_evaluated"] == "" && !results[i]["result_id"].includes("temp")){
                    for (var j in resultset2) {
                        if( (results[i]["nid"] == resultset2[j]["nid"]) && resultset2[j]["result_id"].includes("temp")){
                            mergeJsonsUnion(resultset2[j], results[i]);
                            results[i]["time_left"] = resultset2[j]["time_left"];
                            Platform.saveObject("quiz_result",results[i]["result_id"],"content",JSON.stringify(results[i]));
                            Platform.removeObject("quiz_result",resultset2[j]["result_id"]);
                            console.log("Cleaned up inprogress results for " + results[i]["nid"]);
                            break;
                        }
                    }
                }
            }
            if(syncedResultsCount > 0){
                Platform.uploadQuiz();
                Platform.removeObject("quiz_result","");
            }
            else{
                console.log("No pending quiz results to sync");
            }
           
        } catch (e) {
            console.log("QuizResultSync - submitResults : " + e.message);
        }
    };
 
    this.startSync = function () {
        try {
            if(typeof Platform != "undefined"){
                var allResultsFromData = getAllResults();
                if(allResultsFromData == ""){
                    console.log("No pending results to sync");
                    return "";
                }
                submitResults(allResultsFromData);
            }
        } catch (e) {
            console.log("QuizResultSync - startSync : " + e.message);
            return;
        }
    };
}
function startQuizResultSyncOfResultIdEmpty() {
    var quizResultSync = new QuizResultSyncOfResultIdEmpty();
    quizResultSync.startSync();
}
function mergeJsons(json1, json2) {
    for (let key in json1) {
        if ( !isNaN(key) && (json1.hasOwnProperty(key) && !json2.hasOwnProperty(key))) {
        json2[key] = json1[key];
        }
    }
}

function mergeJsonsUnion(json1, json2) {
    for (let key in json1) {
      if ( !isNaN(key) ) {
        json2[key] = json1[key];
      }
    }
}

function loadWhatsApp(container = "#wrapper"){
    try{
        if (typeof ENABLE_WHATSAPP != 'undefined' && ENABLE_WHATSAPP == 'true' && typeof CLIENT_WHATSAPP_URL != 'undefined' && CLIENT_WHATSAPP_URL != '') {
            $(container).append("<div class='whatsapp_image'></div>");
            $('.whatsapp_image').click(function() {
                if(Platform.getNetworkState() == true){
                    let url = CLIENT_WHATSAPP_URL;
                    Platform.openBrowser(url);
                }
            });
        }
    }catch(e){
        console.log('function loadWhatsApp error');
    }
};

function removeMarkCompleteWrapper() {
    $('#thankyou_overlay,#videofeedback_thankyou_wrapper').remove();
    $("#video-right-menu-wrapper").addClass("hidden");
    return false;
}

function loadContentInIframe(){
    try{
        Client.showProgressBar("Loading...");
        var resourceid = getQueryParameter('nid',LOCATION_URL) ;
        if(typeof resourceid !== 'undefined' && resourceid !== null && resourceid !== null){
            CONTROLLER.animateViewLoad = false;
            callResourceView(resourceid);
        }
    }catch(e){
        console.log('error in loadContentInIframe '+ e.message);
    }
}
function removeMarkCompleteWrapper() {
    $('#thankyou_overlay,#videofeedback_thankyou_wrapper').remove();
    $("#video-right-menu-wrapper").addClass("hidden");
    return false;
}

function loadFlashcardFavorites(paramsData){
    try{
        var data = {'nid': paramsData.quiz_id, 'catid': paramsData.catid, "title":paramsData.title, 'resource':{}, 'qn_no': paramsData.qn_no};
        data['resource'].flashcard_nid = paramsData.quiz_id;
        data['resource'].title = paramsData.title;
        CONTROLLER.loadView("Flashcard",data);
    }catch(e){
        console.log('error in loadFlashcardFavorites '+ e.message);
    }
}

function loadContentInWebview(url, enablezoom, orientation, showbackbutton, title, removeOrientation=false, togglescreencapture=false, showprogressbar=false, showheader=true){
    var params = {
        "url": url,
        "enablezoom": enablezoom,
        "orientation": orientation,
        "showbackbutton": showbackbutton,
        "title": title,
        "togglescreencapture": togglescreencapture,
        "showprogressbar": showprogressbar,
        "showheader": showheader,
        "removeorientation": removeOrientation
    };

    params = JSON.stringify(params);

    Client.openUrlInWebview(params);
};

//Callback for the API  Client.googleSignIn();
function googleSignInCallback(emailid){
    try{
        Client.showProgressBar(t("Loading") + "...");
        if(typeof emailid != 'undefined' && emailid != null && emailid != "" && emailid != "Login Failed"){
            var userService = CONTROLLER.getService("User");
            var data = "{";
            var temp = "";
            temp = ' "username" : "' + emailid + '" ,';
            temp = temp + ' "ignore_phone_number" : "' + 'true' + '" '; //doesUserExist use phone no as mandatory for uniqueness
            data = data + temp + "}";

            var userexist = userService.doesUserExist(data);
            if (userexist == 'true') {
                //username exists
                var status = userService.loginRedirect(emailid);
                if (status == true) {
                    Platform.setPreference('socialLogin', "true");
                    Platform.setPreference("loginsource", "google");
                    var url = Platform.getAppLocalURL() + '&email=' + emailid;
                    window.location.href = url;
                }else{
                    Client.googleSignOut();
                    alert(t("ERROR_MESSAGE", true));
                    Client.closeProgressBar();
                    return false;
                }
            }else{
                Client.googleSignOut();
                alert(t("USER_NOT_REGISTERED", true))
                Client.closeProgressBar();
                return false;
            }
        }else{
            Client.closeProgressBar();
            return false;
        }
    }catch(e){
        Client.closeProgressBar();
        console.log('function googleSignInCallback error');
    }
}

function initializeChatBotInViews(parentCatid=false, parentCatObj=false){
    try{
        var active_catid = parentCatid || CONTROLLER.getVariable("activecatid");
        var active_cat_obj = parentCatObj || CONTROLLER.getVariable("activecatobj");
        var active_cat_name = active_cat_obj.display_name || active_cat_obj.title;
        var data = {
            "subscriptionId" : "",
            "containerId" :  "wrapper",
            "logo" : Platform.getAppLocalURL().split("/index.html")[0] + "/client/images/chatbot.png",
            "clientName" : CHATBOT_CLIENT_NAME,
            "domain" : CHATBOT_PAGE,
            "uid" : OBJECT_USER_ID,
            "username" : OBJECT_USER_NAME,
            "courseNid" : active_catid,
            "courseName" : active_cat_name,
            "open" : APP_TYPE === "web" ? "inline" : "new",
            "context" : {},
            "contextEvent" : {},
            "botName":CHATBOT_NAME,
            "medium": "mobile_browser"
        } 
        var chatbot = window.dash;
        if(typeof chatbot != 'undefined'){
            CONTROLLER.setVariable("chatbotInitialized", "true");
            chatbot.initializeChatBot(data);  
            chatbot.hideDashBot();
        }
    }catch(e){
        console.log(e.message + " function >> initializeChatBotInViews");
    }
}

function Controller() {

    this.serviceMap = [];

    this.viewsMap = [];

    this.serviceConfig = null;

    this.viewConfig = null;
    this.activeViewName = null;

    this.targetViewname = null;

    this.activeView = null;

    this.variables = [];

    this.animateViewLoad = true;

    this.hideProgressOnRenderComplete = true;

    this.onBackTriggered = false;

    this.onPreRender = function (view) {
        var loadedViews = this.getCache("loadedViews");
        if(loadedViews.indexOf(view) == -1){
            Client.showProgressBar("Loading...");
        }
    }

    this.onRenderComplete = function (view) {
        var containerSLideDirection = "right";
        var loadedViews = this.getCache("loadedViews");
        if(loadedViews == ""){
            loadedViews = [];
        }

        if(this.animateViewLoad){
            if(this.onBackTriggered == true){
                containerSLideDirection = "left";
                this.onBackTriggered = false;
            }
            if($("#content_wrapper").length > -1){
                slideContainer("#content_wrapper",containerSLideDirection);
            }
        }
        else{
            this.animateViewLoad = true;
        }
        if(loadedViews.indexOf(view) == -1){
            loadedViews.push(view);
            this.setCache("loadedViews",loadedViews)
        }
        if(this.hideProgressOnRenderComplete){
            Client.closeProgressBar();
        }
        else{
            this.hideProgressOnRenderComplete = true;
        }
        
    }

    this.getService = function (name) {
        var service = this.serviceMap[name];
        if (service) {
            return service;
        }
        else {
            var className = this.serviceConfig[name].name;
            service = new window[className]();
            this.serviceMap[name] = service;
            return service;
        }
    };

    this.loadConfig = function (serviceConfig, viewConfig) {
        this.serviceConfig = serviceConfig;
        this.viewConfig = viewConfig;

        if (typeof CLIENT_SERVICECONFIG != "undefined" && typeof CLIENT_VIEWCONFIG != "undefined")
            this.loadClientConfig(CLIENT_SERVICECONFIG, CLIENT_VIEWCONFIG);
    };

    this.loadClientConfig = function (serviceConfig, viewConfig) {
        this.serviceConfig = deepmerge(this.serviceConfig, serviceConfig, { arrayMerge: configArrayMerge });
        this.viewConfig = deepmerge(this.viewConfig, viewConfig, { arrayMerge: configArrayMerge });
    };

    this.getCache = function(key){
        var cache = Platform.getCachedetails(key);
        if(cache != ""){
            //try to parse the cache, if it is not a valid json, return as it is
            try{
                cache = $.parseJSON(cache);
            }
            catch(e){
                console.log("function : getCache" + "\n message:" + e.message);
            }
            
        }
        return cache;
    }

    this.setCache = function(key,value,expiry){
        try {
            var data = { 'cacheid':key,'cachedata':JSON.stringify(value)}
            Platform.addorUpdateCache(JSON.stringify(data));
        } catch (e) {
            console.log("function : setCache" + "\n message:" + e.message);
        }
    }

    this.deleteCache = function(key){
        Platform.deleteCacheDetails(key);
    }


    this.setCache("loadedViews",[]);

    this.loadView = function (viewname, params="") {
        try {
            var files = this.viewConfig[viewname].files;

            var controller = this;

            if(typeof REQUIRE_CACHE != 'undefined' && REQUIRE_CACHE.indexOf(viewname) > -1){
                return this.loadRequireFiles(viewname, params, true);
            }

            require(files,
                function () {
                    controller.loadRequireFiles(viewname, params, false);
                },
                function (err) {
                    console.log(ERROR_MESSAGE + err);
                    Client.closeProgressBar();
                    alert(t(ERROR_MESSAGE));
                }
            );
        }
        catch (e) {
            console.log("Exception thrown while loading the view " + e.message);
        }
    }

    this.loadChildView = function (viewname, callback) {
        try {
            var files = this.viewConfig[viewname].files;

            //Get dependancy files for each service required by the view
            //and add to the files list

            // if (IS_APP_SHELL) {
            //     var services = this.viewConfig[viewname].services;

            //     for (i = 0; i < services.length; i++) {
            //         files = files.concat(this.serviceConfig[services[i]].files);
            //     }
            // }
            require(files,
                function () {
                    var viewClass = viewname + "View";
                    var view = new window[viewClass]();
                    callback(view);
                },
                function (err) {
                    console.log(ERROR_MESSAGE + err);
                    Client.closeProgressBar();
                    alert(ERROR_MESSAGE);
                }
            );
        }
        catch (e) {
            console.log("Exception thrown while loading the view " + e.message);
        }
    }

    this.getView = function (viewname) {
        var viewInstance = this.viewsMap[viewname];
        if (viewInstance) {
            return viewInstance;
        }
        else {
            viewInstance = new window[viewname + "View"]();
            viewInstance.prototype = viewInstance;
            viewInstance.prototype.constructor = viewInstance.constructor;
            this.viewsMap[viewname] = viewInstance;
            return viewInstance;
        }
    }

    this.onBack = function () {
        if (this.activeView.onBack() == false) {
            //default processing

        }
    }

    this.getVariable = function (name) {
        return this.variables[name];
    }

    this.setVariable = function (name, value) {
        if(name == "activegroupid"){
            var mode = getQueryParameter('mode', LOCATION_URL);
            if(mode != 'adaptivelearning'){
                Platform.setPreference("activegroupid",value);
            }
        }
        this.variables[name] = value;
    }

    var onHistoryPopState = function (event) {
        if (!IS_APP_SHELL) {
            if (event.state === null) {
                event.preventDefault();
                return false;
            }
            handleBackClick();
        }
    }

    this.loadRequireFiles = function(viewname, params,fromCache){
        var controller = this;
        var mode = getQueryParameter('mode', LOCATION_URL);
        var viewClass = viewname + "View";
        var view = new window[viewClass]();
        controller.targetViewname = viewname;

        if(typeof REQUIRE_CACHE == "undefined"){
            REQUIRE_CACHE = [];
        }

        REQUIRE_CACHE.push(viewname);

        if(fromCache){
            controller.activeViewName = viewname;
            controller.activeView = view;
        }

        controller.onPreRender(viewname+"-"+params);
        view.render(params);
        controller.onRenderComplete(viewname+"-"+params);
        if (!IS_APP_SHELL && getQueryParameter("bookmark", LOCATION_URL) == 'null') {
            if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'adaptivelearning' && typeof quiz_type != 'undefined' && quiz_type != null && quiz_type != 'null' && quiz_type == 'adaptive_levelling_quiz'){
                console.log('adaptivelearning');
            }else if(typeof mode != 'undefined' && mode != null && mode != 'null' && mode == 'iframemode'){
                console.log('iframemode');
            }else{
                window.history.pushState({ "activeViewName": viewname }, window.location.href);
            }
        }
        if(!fromCache){
            controller.activeViewName = viewname;
            controller.activeView = view;
        }
    }

    window.addEventListener('popstate', onHistoryPopState);
}

var CONTROLLER = new Controller();



function View(){

}


View.prototype.render = function() {
    alert("This method needs to be overridden with your own implementation");
};
    

View.prototype.renderTemplate=function(templatename, jsondatat, wrapper, replace) {
    try{
        dust.render(templatename, jsondatat, function(err, res) {
            if (replace == true) {
                $(wrapper).html(res);
            } else {
                $(wrapper).append(res);

            }
        });
    }catch(e){
        console.log("function : renderTemplate " + e.message);
        Client.closeProgressBar();
    }
}
    
    

View.prototype.onBack=function()
{
   alert("You need override this method");
}
function getURL(){
    var uri = unescape(window.location.href);
    return uri;
}

function getPortFromURL(url) {
    var regex = /^(http|https):\/\/[^:\/]+(?::(\d+))?/;
    var match = url.match(regex);
    if (match === null) {
      return null;
    } else {
      return match[2] ? match[2] : {http: "80", https: "443"}[match[1]];
    }
  }

function t(text,ismsg) {
    try{
        if (LANG == 'en' || LANG == '' || typeof LANG == 'undefined' ) {
            if(ismsg) {
                return window[text];
            }
            return text;
        }

        if (typeof LANGUAGE != "undefined") {
            try{
                var clientLang = eval(LANG.toUpperCase()+"_CLIENT")
            }
            catch(e){
                clientLang = "";
            }
            
            if(LANGUAGE[LANG].hasOwnProperty(text)){
                return LANGUAGE[LANG][text];
            }
            else if(typeof clientLang != "undefined" && clientLang != "" && clientLang.hasOwnProperty(text)){
                return clientLang[text];
            }
            else{
                if(ismsg) {
                    return window[text];
                }
                return text;
            }
        }
        return text;
    }
    catch(e){
        console.log("function : t " + e.message);
    }
}

dust.helpers.t = function (chunk, context, bodies, params) {
    var data = t(dust.helpers.tap(params.text, chunk, context));
    return chunk.write(data);
};

function getParameterByName(name) {
    var match = RegExp('[?&]' + name + '=([^&]*)').exec(window.location.search);
    return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
}

function stringStartsWith (string, prefix) {
    return string.slice(0, prefix.length) == prefix;
}

String.prototype.capitalizeFirstLetter = function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
}


function answer_key_dialog(alert_text , callback) {
    var type =alert_text.localeQuestion.questiontype;
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width = 0;
    var height = 0;
    var scrollposition = $(window).scrollTop();

    if ($(window).width() < 380) {
        width = 250;
        height = 120;
    }
    else {
        width = 300;
        height = 120;
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
    });

    if(type=="multichoice"){
        alert_text.renderTemplate('question_table.tl', alert_text.localeQuestion,"#alert_dialog",true)
    }
    else if(type=="short_answer"){
        alert_text.renderTemplate('short_answer_summary.tl', alert_text.localeQuestion,"#alert_dialog",true);
    }
    else if(type=="long_answer"){

        alert_text.renderTemplate('long_question.tl', alert_text.localeQuestion,"#alert_dialog",true);
    }else if(type=="truefalse"){
        alert_text.renderTemplate('truefalse_question.tl', alert_text.localeQuestion,"#alert_dialog",true);
    }else if(type=="multimatching"){
        alert_text.renderTemplate('question_table_match.tl', alert_text.localeQuestion,"#alert_dialog",true);
    }else if(type=="sequence"){
        alert_text.renderTemplate("put_in_sequence.tl", alert_text.localeQuestion,"#alert_dialog",true);
    }else if(type=="matching"){
        if(alert_text.localeQuestion.match_type == 'matrix'){
            alert_text.renderTemplate('matrix_summary.tl', alert_text.localeQuestion,"#alert_dialog",true);
        }else{
            alert_text.renderTemplate('match_summary.tl', alert_text.localeQuestion,"#alert_dialog",true);
        }
    }

    if(alert_text.localeQuestion.question_result.is_skipped != IS_SKIPPED_STATES['Grace']){
        $(".solution_content_wrapper").removeClass("hidden");
    }
    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "auto"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').append('<div id="index-close"></div>');
    $("#index-close").css({"position":"absolute","top":"10px","right":"5px", "border-radius":"5px", "height":"25px", "width":"25px"});
    
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer')
    $('.alert_wrapper_dialog').css({"height":$(window).height()+10,"width":$(window).width()+6,"top":"-1%","margin-left":"0px", "margin-top":"0px","left" :"-1%"});
    $('html body .ui-dialog').css({'z-index': '99999999',
    'background': '#ffffff','position': 'fixed','outline':'none'});
    $(".ui-dialog-titlebar, .ui-dialog-buttonset, #certificate_placeholder").addClass("hidden");
    if(typeof BREAK_MODE != "undefined" && BREAK_MODE =="true"){
        $(".ui-dialog-titlebar, .ui-dialog-buttonset").removeClass("hidden");
        $('#alert_dialog #index-close').remove()
    }
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $("#index-close").unbind("click").bind("click", function () {    
        $("#alert_dialog").dialog("close");
        $('#alert_dialog').remove();
        $("#transparent_wrapper").css({
            "display": "none"
        });
        $('.ui-widget-overlay').removeClass('custom-overlay');
        return false;
    });
    if(APP_TYPE != "web"){
        $("#questionTitle a,.question_title_expand a,#question_wrapper a,.solution_content_wrapper a,#section_text_wrapper a").not(".match_option_choose dd ul li a,.match_option_choose dt a, a:contains('Read More'), a:contains('Read Less')").unbind('click').bind("click", function (e) {
            var url = $(this).attr("href");
            if(typeof url != "undefined" && url != "" && !url.includes("javascript:void(0)") && url != "#"){
                if($.isFunction(Client.openUrlInWebview)){
                    loadContentInWebview(url, false, 'portrait', false, "");
                }else{
                    Platform.openBrowser(url);
                }
            }
        });
    }
}


String.prototype.capitalizeFirstLetterAllWords = function() {
    return this.toLowerCase().split(' ').map(
    function(s){
        return s.charAt(0).toUpperCase() + s.substring(1)
    }).join(' ');
}

function encodeHTMLEntities(rawStr){

    var encodedStr = rawStr.replace(/[\u00A0-\u9999<>\&]/gim, function(i) {
        return '&#'+i.charCodeAt(0)+';';
    });

    return encodedStr;
}


function getAndroidVersion(ua) {
    ua = (ua || navigator.userAgent).toLowerCase();
    var match = ua.match(/android\s([0-9\.]*)/);
    return match ? match[1] : false;
};

function format_time(date_obj) {
  // formats a javascript Date object into a 12h AM/PM time string
  var hour = date_obj.getHours();
  var minute = date_obj.getMinutes();
  var amPM = (hour > 11) ? "pm" : "am";
  if(hour > 12) {
    hour -= 12;
  } else if(hour == 0) {
    hour = "12";
  }
  if(hour < 10){
      hour = "0" + hour;
  }
  if(minute < 10) {
    minute = "0" + minute;
  }
  return hour + ":" + minute + amPM;
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
        var hash = "";
        if (uri.indexOf("#") !== -1) {
            hash = uri.replace(/.*#/, "#");
            uri = uri.replace(/#.*/, "");
        }
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";
        return uri + separator + key + "=" + value + hash;
    }
}

Date.prototype.getWeekDay = function() {
    var weekday = "";
    if(typeof LOCALE_WEEKS_TEXT != "undefined" && LOCALE_WEEKS_TEXT.hasOwnProperty(LANGUAGE_CODE[Platform.getPreference('lang')])){
        weekday = LOCALE_WEEKS_TEXT[LANGUAGE_CODE[Platform.getPreference('lang')]];
    }
    else{
        weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    }
    return weekday[this.getDay()];
}

Date.prototype.monthNames = [
    "January", "February", "March",
    "April", "May", "June",
    "July", "August", "September",
    "October", "November", "December"
];

Date.prototype.getMonthName = function() {
    return this.monthNames[this.getMonth()];
};
function nth(d) {
  if(d>3 && d<21) return 'th'; // thanks kennebec
  switch (d % 10) {
        case 1:  return "st";
        case 2:  return "nd";
        case 3:  return "rd";
        default: return "th";
    }
}

function getFormattedDate(timestamp) {
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = (new Date((parseInt(timestamp)) * 1000));
    var formatedDate = date.getDate().padLeft() + '-' + months[(date.getMonth())] + '-' + date.getFullYear();
    return formatedDate;
}


function getFormattedDateWithTime(timestamp){
    var date = (new Date((parseInt(timestamp)) * 1000));
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    var seconds = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
    time = hours + ":" + minutes + ":" + seconds + " " + am_pm;
    var formatedDate = date.toDateString() +  ' ' + time;
    return formatedDate;
}

function getFormattedDateWithTimeForEvents(timestamp){
    var months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var date = (new Date((parseInt(timestamp)) * 1000));
    var hours = date.getHours() > 12 ? date.getHours() - 12 : date.getHours();
    var am_pm = date.getHours() >= 12 ? "PM" : "AM";
    hours = hours < 10 ? "0" + hours : hours;
    var minutes = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
    time = hours + ":" + minutes +  " " + am_pm;
    var formatedDate = date.getDate().padLeft() + ' ' + months[(date.getMonth())] + ' ' + time;
    return formatedDate;
}

function getAndroidVersion(ua) {
    ua = (ua || navigator.userAgent).toLowerCase();
    var match = ua.match(/android\s([0-9\.]*)/);
    return match ? match[1] : false;
};

Number.prototype.padLeft = function(base,chr){
    var  len = (String(base || 10).length - String(this).length)+1;
    return len > 0? new Array(len).join(chr || '0')+this : this;
}

function onInternetAvailable(){
    if(Platform.getNetworkState() == true && typeof ASYNC_LOGIN_REQUIRED != "undefined" && ASYNC_LOGIN_REQUIRED == "true"){
        var userService = CONTROLLER.getService("User");
        userService.loginUserAsync();
        ASYNC_LOGIN_REQUIRED = "false";
    }
}

function sleep(milliseconds) {
  var start = new Date().getTime();
  for (var i = 0; i < 1e7; i++) {
    if ((new Date().getTime() - start) > milliseconds){
      break;
    }
  }
}

function getQueryParameter ( parameterName , url) {
    var queryString = "";

    if(typeof url == 'undefined' || url == "" || url == null){
        try{
            queryString = window.top.location.search.substring(1);
        } catch (e) {
            queryString = window.location.search.substring(1);
        }
    }
    else{
        if(typeof url !== 'undefined' && url.indexOf('?') > -1){

            queryString = url.split("?");

            if(queryString.length > 1){
                queryString = queryString[1];
            }else{
                queryString = "null";
            }
        }
    }

    if(EXEC_MODE == "online"){
        queryString = decodeURIComponent(queryString);
    }
    
    var parameterName = parameterName + "=";
    
    if ( queryString.length > 0 ) {
        var begin = queryString.indexOf ( parameterName );
        if ( begin != -1 ) {
            begin += parameterName.length;
            var end = queryString.indexOf ( "&" , begin );
            if ( end == -1 ) {
                end = queryString.length
            }
            return unescape ( queryString.substring ( begin, end ) );
        }
    }
    
    return "null";
}

function getResourceParamsFromURL( parameterName, url) {
    var urlParams = new URLSearchParams(url);
    var paramFound = urlParams.get(parameterName);
    return paramFound;
}

function replaceHTTPS(url){
    if(url.indexOf("https://") >= 0){
        url = url.replace("https://","http://");
    }
    return url;
}

function shuffleArray(o) {
    for(var j, x, i = o.length; i; j = Math.floor(Math.random() * i), x = o[--i], o[i] = o[j], o[j] = x);
    return o;
}

function getVideoDurationFromSeconds(resourceDuration){
    var sec_num = parseInt(resourceDuration, 10);
    var hours   = Math.floor(sec_num / 3600);
    var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds = sec_num - (hours * 3600) - (minutes * 60);

    if (hours   < 10) {hours   = "0"+hours;}
    if (minutes < 10) {minutes = "0"+minutes;}
    if (seconds < 10) {seconds = "0"+seconds;}

    resourceDuration =  hours + ":" + minutes+':'+seconds;

    return resourceDuration;
}

function hasId(data, id) { return data.some(function (el) { return el.nid === id; }); }

function hasResourceId(data, id) { return data.some(function (el) { return el.resourceid === id; }); }

function getObjectById(data, id, key) {
    for(i in data){
        if (data[i][key] === id) {
            return data[i];
        };
    }
}

function enableZoom(){
    Platform.enableZoom(true);
    $("#viewport").attr("content", "width=device-width, user-scalable=yes");
}

function disableZoom(){
    Platform.enableZoom(false);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
}

function getWindowWidth(param) {
        var windowWidth = 0;
        if (typeof (window.innerWidth) == 'number') {
            if (typeof param != 'undefined' && param == 'ebook') {
                windowWidth = $(window).width();
            } else {
                windowWidth = window.innerWidth;
            }
    }
        else {
                if (document.documentElement && document.documentElement.clientWidth) {
                        windowWidth = document.documentElement.clientWidth;
                }
                else {
                        if (document.body && document.body.clientWidth) {
                                windowWidth = document.body.clientWidth;
                        }
                }
        }
        return windowWidth;
}

function getWindowHeight(param) {
        var windowHeight = 0;
        if (typeof(window.innerHeight) == 'number') {
            if(typeof param!= 'undefined' && param == 'ebook'){
                windowHeight =  $(window).height();
            }else{
                windowHeight = window.innerHeight;
            }
        }
        else {
                if (document.documentElement && document.documentElement.clientHeight) {
                        windowHeight = document.documentElement.clientHeight;
                }
                else {
                        if (document.body && document.body.clientHeight) {
                                windowHeight = document.body.clientHeight;
                        }
                }
        }
        return windowHeight;
}

function time_ago(time){

switch (typeof time) {
    case 'number': break;
    case 'string': time = +new Date(time); break;
    case 'object': if (time.constructor === Date) time = time.getTime(); break;
    default: time = +new Date();
}

var time_formats = [
    [60, t('seconds'), 1], // 60
    [120, '1 '+t('minute ago'), '1 '+t('minute from now')], // 60*2
    [3600, t('minutes'), 60], // 60*60, 60
    [7200, '1 '+t('hour ago'), '1 '+t('hour from now')], // 60*60*2
    [86400, t('hours'), 3600], // 60*60*24, 60*60
    [172800, t('Yesterday'), t('Tomorrow')], // 60*60*24*2
    [604800, t('days'), 86400], // 60*60*24*7, 60*60*24
    [1209600, '1 '+t('week ago'), t('Next week')], // 60*60*24*7*4*2
    [2419200, t('weeks'), 604800], // 60*60*24*7*4, 60*60*24*7
    [4838400, '1 '+t('month ago'), t('Next month')], // 60*60*24*7*4*2
    [29030400, t('months'), 2419200], // 60*60*24*7*4*12, 60*60*24*7*4
    [58060800, '1 '+t('year ago'), t('Next year')], // 60*60*24*7*4*12*2
    [2903040000, t('years'), 29030400], // 60*60*24*7*4*12*100, 60*60*24*7*4*12
    [5806080000, '1 '+t('century ago'), t('Next century')], // 60*60*24*7*4*12*100*2
    [58060800000, t('centuries'), 2903040000] // 60*60*24*7*4*12*100*20, 60*60*24*7*4*12*100
];
var seconds = (+new Date() - time) / 1000,
    token = t('ago'), list_choice = 1;

if (seconds == 0) {
    return t('Just now')
}
if (seconds < 0) {
    seconds = Math.abs(seconds);
    token = t('from now');
    list_choice = 2;
}
var i = 0, format;
while (format = time_formats[i++])
    if (seconds < format[0]) {
        if (typeof format[2] == 'string')
            return format[list_choice];
        else
            return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
    }
return time;
}

jQuery.fn.center = function (absolute) {
    return this.each(function () {
            var t = jQuery(this);

            t.css({
                    position:	absolute ? 'absolute' : 'fixed',
                    left:		'50%',
                    top:		'50%',
                    zIndex:		'99'
            }).css({
                    marginLeft:	'-' + (t.outerWidth() / 2) + 'px',
                    marginTop:	'-' + (t.outerHeight() / 2) + 'px'
            });

            if (absolute) {
                    t.css({
                            marginTop:	parseInt(t.css('marginTop'), 10) + jQuery(window).scrollTop(),
                            marginLeft:	parseInt(t.css('marginLeft'), 10) + jQuery(window).scrollLeft()
                    });
            }
    });
}

function tribyte_dialog_show(alert_text, callback) {
    $('#alert_dialog').remove();
    var preView = CONTROLLER.getVariable("previousView");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    $('body').append('<div id="alert_dialog" ></div>');
    var width = 0;
    var height = 0;
    var scrollposition = $(window).scrollTop();

    if ($(window).width() < 380) {
        width = 250;
        height = 120;
    }
    else {
        width = 300;
        height = 120;
    }


    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": {
                text: t('Ok'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    var activeview = CONTROLLER.activeViewName;
                    if (activeview == 'TestSeries') {
                        return false;
                    } else {
                        if (notificationtype != "" && notificationtype == "action" && !notificationUrlType && (alert_text == INTERNET_ERROR || (typeof preView !== undefined && preView !== "HomePage"))) {
                            $("#wrapper").remove();
                            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
                        <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                            Client.showProgressBar(t("Loading") + "...");
                            Platform.setDeviceOrientation("portrait");
                            if ($(window).width() > $(window).height() && IS_APP_SHELL) {
                                setTimeout(function () {
                                    $(window).on("resize", function () {
                                        if (activeview == 'Notifications') {
                                            CONTROLLER.loadView("Notifications");
                                        } else {
                                            CONTROLLER.loadView("HomePage");
                                        }
                                        $(window).off("resize");
                                    });
                                }, 50);
                            }
                            else {
                                if (activeview == 'Notifications') {
                                    CONTROLLER.loadView("Notifications");
                                } else {
                                    CONTROLLER.loadView("HomePage");
                                }
                            }
                            $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
                            $(window).off("resize");
                        }
                        CONTROLLER.setVariable('notificationUrlType', '');
                        CONTROLLER.setVariable('notificationType', '');
                        if (typeof callback != 'undefined' && typeof callback == 'function') {
                            callback.apply();
                        }
                        return false;
                    }
                }
            }
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(alert_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);
    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "auto"
        });
    }

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'z-index': '99999999',
    'background': '#ffffff','position': 'fixed','outline':'none'});
    $(".ui-dialog-titlebar").hide();
    $(".ui-dialog-buttonset .ui-button").css("width", "100%");
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}


function sortByRank(json){
    try{
        return json.sort(function(a, b) {
            if(a.rank == null && b.rank != null){
                return -1;
            }
            else if(a.rank != null && b.rank == null){
                return 1;
            }
            else{
            return ((parseInt(a.rank) < parseInt(b.rank)) ? -1 : ((parseInt(a.rank) > parseInt(b.rank)) ? 1 : 0));
            }
        });
    }
    catch(e){
        console.log( "function : sortByRank"
            + "\n message:" + e.message);
    }
}
function sortByTimestamp(json) {
    try {
        var sorted = json.sort(function (x, y) {
            return parseInt(x.timestamp) - parseInt(y.timestamp);
        });
        return sorted;
    } catch (e) {
        console.log("function : sortByTimestamp"
                + "\n message:" + e.message);
    }
}

function sortByDuration(json) {
    try {
        var sorted = json.sort(function (x, y) {
            return parseInt(x.duration) - parseInt(y.duration);
        });
        return sorted;
    } catch (e) {
        console.log("function : sortByDuration"
                + "\n message:" + e.message);
    }
}
window.alert = function(msg, callback) {
    try{
        tribyte_dialog_show(msg, callback);
    }
    catch(e){
    }
}

window.confirm = function(msg,callback,newoktext,newcanceltext) {
    try{
        tribyte_confirm_dialog_show(msg,callback,newoktext,newcanceltext);
    }
    catch(e){

    }
}
function getUploadedFilePath() {
    if(APP_TYPE == "web"){
        Client.showProgressBar("Uploading...");
    }
    
    var fd = new FormData();
    var filedata;
    
    if(typeof $("#upload_file")[0] !== "undefined") {
        filedata = $("#upload_file")[0].files[0];
    }
    
    if(typeof filedata == 'undefined')
        filedata = UPLOADPATH;

    fd.append('file', filedata);
    var url = OBJECT_SERVER_DOMAIN + '/api/forumupload?uid=' + OBJECT_USER_ID + '&type=forum';
    $.ajax({
        url : url,
        data: fd,
        processData: false,
        contentType: false,
        type: 'POST',
        error: function(error) {
            alert('failed');
			if(APP_TYPE == "web"){
            	Client.closeProgressBar();
			}
        },
        success: function(data) {
            getserverImagePath($.parseJSON(data), filedata.name);
			if(APP_TYPE == "web"){
            	Client.closeProgressBar();
			}
        }
    });
}



function getUploadedVideoorAudioPath(filetype) {
    var fd = new FormData();
    var filedata = $("#upload_file")[0].files[0];
    if(typeof filedata == 'undefined'){
        $(".blockUI").remove()
        alert("Please select a file to upload")
        return;
    }else if(typeof filedata.type == "undefined" || filedata.type == ""){
        $(".blockUI").remove()
        alert("Unable to determine the selected file type.Please select the file from different folder")
        return;
    }else if(!filedata.type.includes(filetype)){
        $(".blockUI").remove()
        alert("Please upload a file of type " + filetype)
        return;
    }
    
    else{
        var fileSizeInBytes = filedata.size
        var fileSizeInMB = fileSizeInBytes / (1024 * 1024); 
        fileSizeInMB = fileSizeInMB.toFixed(2)
        if(typeof fileSizeInMB != "undefined" && fileSizeInMB > 20){
            $(".blockUI").remove()
            alert(t("UPLOAD_LIMIT_EXCEEDED", true));
            return;
        }
    }
    fd.append('file', filedata);
    $('[id^=attach_content_wrapper]').remove();
    var folderName = "QuestionAudioVideoUpload_"+ OBJECT_USER_ID;
    var url =  OBJECT_SERVER_DOMAIN + "/api/user/fileupload?uid="+OBJECT_USER_ID+'&folder='+folderName;
    $.ajax({
        url : url,
        data: fd,
        processData: false,
        contentType: false,
        type: 'POST',
        error: function(error) {
            $(".blockUI").remove()
            alert('failed');
        },
        success: function(data) {
            var uploadeddata = {}
            uploadeddata.data = $.parseJSON(data)
            uploadeddata.status = "200"
            getUploadedVideoPath(uploadeddata);
            $(".blockUI").remove()
        }
    });
}
function tribyte_confirm_dialog_show(alert_text, callback, buttonOK, buttonCancel ) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();

    var width;
    var height;

    if($(window).width() < 380){
        width = 250;
        height = 120;
    }
    else{
        width = 300;
        height = 120;
    }
    if(typeof buttonOK == "undefined"  ){
        buttonOK = t('OK');
    }
    if( typeof buttonCancel == "undefined"){
        buttonCancel = t('Cancel');
    }
    var buttonsJSON = {};
    buttonsJSON[buttonOK] = function () {
        $('#alert_dialog').dialog("close");
        $('#alert_dialog').remove();
        $("#transparent_wrapper").css({
                    "display": "none"
        });
        var quiz_inprogress = CONTROLLER.getVariable("quiz-inprogress");
        if(quiz_inprogress){
            delete_inprogress_attempt(true);
            return;
        }
        callback.apply();
        return false;
    };
    buttonsJSON[buttonCancel] = function () {
        $('#alert_dialog').dialog("close");
        $('#alert_dialog').remove();
        $("#transparent_wrapper").css({
                    "display": "none"
        });
        var quiz_inprogress = CONTROLLER.getVariable("quiz-inprogress");
        if(quiz_inprogress){
            delete_inprogress_attempt(false);
            return;
        }
        return false;
    };
    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            //window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: buttonsJSON,
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(alert_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
    'background': '#ffffff','position': 'fixed','outline':'none'});
    if(typeof buttonOK != "undefined" ){
        $('.ui-button-text:contains("'+buttonOK+'")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    }else{
    $('.ui-button-text:contains("Ok")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    }
    $(".ui-dialog-titlebar").hide();
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function extractQueryParam()
{
    uri = LOCATION_URL;
    try
    {
        if(typeof uri !="undefined" && uri != "")
        {
            var parts = uri.split("?");
            var params = {};
            if( parts.length> 1)
            {
                var paramsList = parts[1].split("&");
                for(var i =0; i < paramsList.length; i++)
                {
                    var p = paramsList[i].split("=");
                    if(p.length > 0)
                    {
                        params[p[0]] = p[1];
                    }

                }
                return params;
            }
        }
        return "";
    }
    catch(e)
    {
        console.log(e);
    }
}

function extractQueryParamAndDecode(uri)
{
    if(typeof uri == "undefined"){
        uri = LOCATION_URL;
    }
    try
    {
        if(typeof uri !="undefined" && uri != "")
        {
            var path = uri.split('?').slice(1).join('?')
            var params = {};
            if( path )
            {
                var paramsList = path.split("&");
                for(var i =0; i < paramsList.length; i++)
                {
                    var p = paramsList[i].split("=");
                    if(p.length > 0)
                    {
                        params[p[0]] = unescape(p[1]);
                    }

                }
                return params;
            }
        }
        return "";
    }
    catch(e)
    {
        console.log(e);
    }
}

function getLocationURL()
{
    var uri = unescape(window.location.href);
    return uri;
}

function hideMenuIcons(menuarray){
    var menuelements = getMenuElements(menuarray);
    $(menuelements).hide();
}

function showMenuIcons(menuarray){
    var menuelements = getMenuElements(menuarray);
    $(menuelements).show();
}

function getMenuElements(menuarray){
    var menuelements = "";
    for(var i=0;i<menuarray.length;i++){
        if(i != (menuarray.length - 1)){
            menuelements = menuelements + menuarray[i] + ",";
        }
        else{
            menuelements = menuelements + menuarray[i];
        }
    }
    return menuelements;
}

function onElementFocus(element,topheight){
    $(element).focusin(function() {
        if(!$(element).hasClass("focussed")){
            $(element).addClass("focussed");
                var screen_height = parseInt($(window).height());
                var top = (screen_height - topheight)/2;
                $('.ui-dialog').animate({top:top}, 500);
        }
    });
}

function sortJSONArray(json,prop){
    try{
        return json.sort(function(a, b) {
            return ( ( parseInt(a[prop]) < parseInt(b[prop])) ? -1 : ((parseInt(a[prop]) > parseInt(b[prop])) ? 1 : 0));
        });
    }
    catch(e){
        console.log( "function : sortJSONArray"
            + "\n message:" + e.message);
    }
}

function closeDialog(common){
    if($(".ui-dialog-content").length > 0 && $(".ui-dialog-content").dialog( "isOpen" ) === true || common){
        $("#dialog_transprant_wrapper").toggle();
        $('.ui-dialog-content').remove();
        $('.ui-dialog-content').dialog("close");
        $('body').unbind('touchmove');
        return true;
    }
}

function findIndexByKeyValue(obj, key, value)
{
    try{
       for (var i = 0; i < obj.length; i++) {
            if (obj[i][key] == value) {
                return i;
            }
        }
        return null;
    }
    catch(e){
       console.log( "function : findIndexByKeyValue"
            + "\n message:" + e.message);
    }
}

function isArray(what) {
    return Object.prototype.toString.call(what) === '[object Array]';
}

function jsonArrayHasId(data, id) {
    return data.some(function (el) {
        return el.nid === id;
    }); }

function replaceURLWithHTMLLinks(text) {
    var exp = /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?\/=~_|!:,.;]*)[-A-Z0-9+&@#\/%=~_|])/ig;
    return text.replace(exp, "<a href='$1' target='_blank'>$1</a>");
}

function getJSONByKey(data,key,val) {
  return data.filter(
      function(data){ return data[key] == val }
  );
}

function getAjxResponse(url,async,requestType,success_func,error_func,postData){
   try{
    if(!async)
        async = false;
        var ajax = "";

    if(!requestType)
        requestType = 'GET';
    if (!/^(f|ht)tps?:\/\//i.test(url)) {
      url = OBJECT_SERVER_DOMAIN + url;
   }
    if(requestType == 'GET'){
         ajax = Platform.makeHttpGetCall(url);
    }
    else{
         ajax = Platform.makeHttpPostCall(url);
    }
    ajax = $.parseJSON(ajax);
        var isJson = IsJsonString(ajax['data']);
        if (isJson == true) {
            var status = ajax['status'];
            if (status == 200) {
                var ajaxdata = ajax['data'];
                return ajaxdata;
            }
        }
        else {
            return "";
        }
    }
    catch (e) {
        console.log(e.message)
    }

}

function IsJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
function convertSecondsToHHMMSS(sec) {
    var format = [
        Math.floor(Math.floor(sec / 60) / 60) % 60, //HOURS
        Math.floor(sec / 60) % 60, //MINUTES
        sec % 60                  //SECONDS
    ];
    return $.map(format, function (v, i) {
        return ((v < 10) ? '0' : '') + v;
    }).join(':');
}
function getLoginRedirectUrl(redirectPath) {
    if( typeof LOGIN_REDIRECT_SET == 'undefined'){
        var resourcepath = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&auth="+AUTH_ENABLED+"&useractive=true&path="+ encodeURIComponent(redirectPath);
        resourcepath = Platform.getAuthenticatedURL(resourcepath, 'GET', 'cloud');
        LOGIN_REDIRECT_SET=true;
        return resourcepath;
    }else{
       return redirectPath;
    }

}
function initHelperCarousel()
{
    $('.helpslider').css({
        width: $(window).width(),
        height: $(window).height()
    });
    var carousel = $(".helpslider").royalSlider({
                        loop: false,
                        keyboardNavEnabled: false,
                        controlNavigation: 'bullets',
                        sliderDrag: false,
                        controlsInside: false,
                        arrowsNavAutoHide: false,
                        thumbsFitInViewport: false,
                        navigateByClick: false,
                        autoPlay: false,
                        transitionType:'move',
                        globalCaption: false,
                        imageScaleMode : "fit",
                        controlNavigationSpacing: 0,
                        imageScalePadding:0,
                        slidesSpacing:0
                      }).data('royalSlider');
        OBJECT_CAROUSEL = carousel;
}

function onAccessDenied() {
    try {
        Client.closeProgressBar();
        if (Platform.getNetworkState() == true) {
            if (typeof QUIZ_TIMER != 'undefined') {
                window.clearInterval(QUIZ_TIMER);
                QUIZ_TIMER = null;
            }
            CONTROLLER.setVariable("loadcategoryview", '');
            CONTROLLER.setVariable("loadtestcollectionforgivengroups", '');
            OBJECT_USER_DATA.loginRequiredOnline = true;
            Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.showMessage(t("USER_LOGOUT_ERROR",true));
            $('body').html('');
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
             //Making courseSelected preference empty so that on back from signinview redirects to courseview
            Platform.setPreference("courseSelected",'')
            if ($(window).width() > $(window).height() && IS_APP_SHELL) {
                Platform.setDeviceOrientation("portrait");
                $(window).on("resize", function() {
                    CONTROLLER.loadView("Signin");
                    $(window).off("resize");
                });
            }
            else {
                CONTROLLER.loadView("Signin");
            }
            return false;
        }
        else {
            return t("INTERNET_ERROR",true);
        }
    }
    catch (e) {
        console.log("function : onAccessDenied"
                + "\n message:" + e.message);

    }
}

function replaceBody() {
    $('#wrapper').remove();
    $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
    Client.showProgressBar(t("Loading") + "...");

}

window.addEventListener('message', function(event) {
    if (event.data.message == "logout") {
        onAccessDenied();
    }
}, false);

function showSolutionofTypeHtml(content) {
  if(typeof content != 'undefined' && content != ""){
    $('body').append('<div class="solution_type_html_wrapper"><div class="solution_content_inner_wrapper">'+content+'</div><div class="cont_wrapper"> <div class="continue">OK</div></div></div>');
        $('.solution_type_html_wrapper').dialog({
            resizable: false,
            modal: true,
            height: 500,
            width: 361,
            dialogClass: 'dlgfixed',
            position: "center"
        });

        $(".dlgfixed").center(false);
        $('.solution_type_html_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('.ui-dialog-buttonpane').css({
            "text-align": "center",
            margin: "15px"
        });
        $('.ui-button').css({'display':'none'});
        $('.inst_title').css({"font-size":"20px", 'font-family':'Roboto Bold'});
        $('.cont_wrapper').click(function() {
            $('.solution_type_html_wrapper').dialog("close");
            $('#quiz_inst_overlay, .solution_type_html_wrapper').remove();
        });
        $('.solution_type_html_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('html body .ui-dialog').css({'z-index': '99999999',
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none','padding':0});
        $(".ui-dialog-titlebar").hide();
        $('html body .ui-dialog').css({"height": $(window).height() + "px", "width": $(window).width() + "px", "display":"block",'z-index': '99999999',"overflow-x":" auto",
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'overflow-y': 'scroll',"left":0, "top":0, "margin-left" : "0", "margin-top" : "0"});

        $('.solution_content_inner_wrapper').css('height', $(window).height() - 71 + "px");
        $('.solution_type_html_wrapper').css({'height': $(window).height() + "px", "margin-top":"20px","padding":0});
        if ($(window).width() < 380) {
            $('html body .ui-dialog').css({"height": $(window).height() - 3 + "px"});
            $('.continue, .solution_content_inner_wrapper, .inst_title').css({'font-size': '15px'});
        }
        if (typeof MathJax != "undefined") {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, 'solution_type_html_wrapper']);
    }
    }
}

function setBodyStyle()
{
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var screenwidth = $(window).width();
    var screenheight = $(window).height();
    $("body,#wrapper").css({
        width: screenwidth + "px",
        height: screenheight + "px"
    });
    $('#quiz_wrapper').css({
        width: screenwidth + "px",
        height: screenheight - 100 + "px"
    });

    $("#content_wrapper").css({
        width: screenwidth + "px",
        height: (screenheight - 63) + "px"
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $("#footer_wrapper").css({
        width: $(window).width() + "px"
    });

    $('#header_wrapper').css('width', $(window).width());
    if (ENABLE_NEW_UI) {
        $("body,#wrapper,#quiz_wrapper").css('background', '#F4F5F9');
    } else {
        $("body,#wrapper,#quiz_wrapper").css('background', '#E5E5E5');
    }

    var catid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else{
        catid = CONTROLLER.getVariable("activecatid");
    }

    if (typeof SUBJECT_COLORS_MAP != "undefined" && SUBJECT_COLORS_MAP[catid]) {
        $('#header,.course_highlight,#course_compl_prog,.pie,.topic_seperator').css("background-color", SUBJECT_COLORS_MAP[catid]);
    }
    $(".title_text").css("width", $(window).width() - 170);

	$('.title-overlay-wrapper').removeClass("hidden");
   if($('#header_wrapper').css('position') == 'fixed'){
    if (ParentView != "DashBoardQuiz") {
        if (($(window).width() < 380)) {
            $('#quiz_wrapper').css({'margin-top': '50px'})
        }
        else {
            $('#quiz_wrapper').css({'margin-top': '55px'});
        }
    }
    else{
        if ($(window).width() < 380) {
            $('#quiz_wrapper').css({'margin-top': '12px'});
        }
        else {
            $('#quiz_wrapper').css({'margin-top': '12px'});
        }
    }
}
}
function backFromSolution() {
    try {
        hPlayer.dispose();
    } catch (ex) {
    }
    closeDialog();
    $('.flipQuestion_endquiz').css('display', 'none');
    if (typeof QUIZ_RESOURCE_PARAMS.resourceid != 'undefined' && QUIZ_RESOURCE_PARAMS.resourceid != '') {
        CONTROLLER.setVariable('activeresourceid', QUIZ_RESOURCE_PARAMS.resourceid);
    }
    clearTimeout(titleTimer);
    clearInterval(FULLSCREENTIMER);
    clearInterval(FULLSCREENRESIZETIMER);
    setTimeout(function(){
        QUIZ_IN_PROGRESS = false;
    },200);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    if (SOLUTION_LANDSCAPE == true) {
        Platform.setDeviceOrientation("landscape");
    } else {
        Platform.setDeviceOrientation("portrait");
    }
    if ($(window).width() > $(window).height() && IS_APP_SHELL) {
        $(window).on("resize", function () {
            setBodyStyle();
        });
    }
    else {
        setBodyStyle();
    }
    var height = $(window).height() - 125;
    $("#quizContainer_wrap").css({
        height: height + "px"
    })
    window.removeEventListener('orientationchange', doOnOrientationChange, false);

    // removing solution embed wrapper on back
    $("#solution_embed_wrapper").remove();

    return "";
}

function showSection(section) {
    $('#section_dialog').remove();
    $('body').append('<div id="section_dialog" ></div>');
    var  scrollposition = $(window).scrollTop();

    $('#section_dialog').dialog({
        resizable: false,
        modal: true,
        height: 500,
        width: 361,
        open: function() {
//            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": function() {
                $(this).dialog("close");
                $('#section_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;

            }
        },
        close: function() {
                $(this).dialog("close");
                $('#section_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="section_text">\n\ '+ section + '</div>';

    $('#section_dialog').html(text);

    $(".dlgfixed").center(false);

    $('#section_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'z-index': '99999999',
    'background': '#ffffff','position': 'fixed','outline':'none'});
$('html body .ui-dialog').css({"height": $(window).height() + "px", "width": $(window).width() + "px", "display":"block",'z-index': '99999999',"overflow-x":" auto",
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'overflow-y': 'scroll',"left":0, "top":0, "margin-left" : "0", "margin-top" : "0"});
    $(".ui-dialog-titlebar").hide();
    $(".ui-dialog-buttonset .ui-button").css("width","100%");
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('#section_dialog img').css({'max-width':'100%', 'max-height':'100%'});
    if ($(window).width() < 380) {
        $('html body .ui-dialog').css({"height": $(window).height() - 3 + "px"});
        $('.continue, .solution_content_inner_wrapper, .inst_title').css({'font-size': '15px'});
    }
    $('#section_dialog').css('height', $(window).height() - 51 + "px");
}
function getDateFormated(tm){
    var today = new Date(tm);
    var dd = today.getDate();
    var mm = today.getMonth() + 1; //January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    var today = dd + '-' + mm + '-' + yyyy;
    return today;
}
function getTime(tm){
    var dt = new Date(tm);
    var time = dt.getHours() + ":" + dt.getMinutes();
    return time;
}
function dateDiffInDays(timestamp1,timestamp2) {
var difference = timestamp1 - timestamp2;
var daysDifference = Math.floor(difference/1000/60/60/24);
return daysDifference;
}
function getMinutesBetweenDates(endTime, startTime) {
    var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var resultInMinutes = Math.round(difference / 60000);
    return resultInMinutes;
}

function doOnOrientationChange()
{
    setTimeout(function(){
        if($("#solution_embed_wrapper").length > 0){
            $('#playback_rate_btns').css({'margin-left':'-4px','font-size': '8px', 'right': '15px'});
            if($(window).height() > $(window).width()){
                $(".vjs-progress-control.vjs-control").css({ "width":"calc(100% - 5em)","left": "calc(1em + 10px)"});
                $('.video-js .vjs-control').css({'right': '2em'});
                $('.video-js .vjs-current-time').css({'left': '1.4em'});
                $('.video-js .vjs-time-divider').css({'left': '1.6em'});
                $('.video-js .vjs-duration').css({'left': '2em'});
            }else{
                $(".vjs-progress-control.vjs-control").css({ "width":"calc(100% - 5em)","left": "calc(1em + 15px)"});
                $('.video-js .vjs-control').css({'right': '2em'});

            }         
        }
        $("#content_wrapper,.ui-dialog,#solution_embed_wrapper,#ebookview-frame,#flash_iframe").css({
            height: (getWindowHeight()),
            width: (getWindowWidth())
        });
        $("body,#wrapper,#content_wrapper,.ui-dialog,#solution_embed_wrapper,#ebookview-frame,#flash_iframe").css({
            height: $(window).height(),
            width: $(window).width()
        });
    },200);
}

function generateSeverClientOffset(callback) {
    var quizService = CONTROLLER.getService("Quiz");
    quizService.getServerClientOffset(function (severClientOffset) {
        if (severClientOffset != INTERNET_ERROR && severClientOffset != ERROR_MESSAGE) {
            SERVER_CLIENT_OFFSET = severClientOffset;
        }
        if (typeof callback === 'function') {
            callback.apply();
        }
    });
    return;
}

function getSyncedServerTime() {
    var now = (new Date()).valueOf();
    if (typeof SERVER_CLIENT_OFFSET != 'undefined' && SERVER_CLIENT_OFFSET != 'null' && SERVER_CLIENT_OFFSET != '') {
        var syncedServerTime = (new Date(now + SERVER_CLIENT_OFFSET)).valueOf();
        return syncedServerTime;
    } else {
        return now;
    }
}

// the NTP algorithm
// t0 is the client's timestamp of the request packet transmission,
// t1 is the server's timestamp of the request packet reception,
// t2 is the server's timestamp of the response packet transmission and
// t3 is the client's timestamp of the response packet reception.
function ntp(t0, t1, t2, t3) {
    return {
        roundtripdelay: (t3 - t0) - (t2 - t1),
        offset: ((t1 - t0) + (t2 - t3)) / 2
    };
}

function onAppResume() {
    CONTROLLER.setVariable("onAppResume", "true");
}

// Reset Application
function resetApplication(){
    if (Platform.getNetworkState() == false) {
        alert(t("INTERNET_ERROR",true));
        return false;
    }
	var resetApplicationMsg = t("Clearing the data will delete all the downloaded Contents from this App. Click OK to continue.");
	if (EXEC_MODE == 'online') {
		resetApplicationMsg = t("Do you want to logout?");
	}
    var check = confirm(resetApplicationMsg, function() {
        resetAppCallBack();
    });
}

function resetAppCallBack() {
    try {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR",true));
            return false;
        }
        Client.showProgressBar(t("Loading") + "");
        setTimeout(function () {
            var user = CONTROLLER.getService("User")
              var ssoLogin = Platform.getPreference('ssoLogin');
            var result = user.logout();
            if (EXEC_MODE == 'online') {
                if (result == LOGOUT_FAILURE) {
                    alert(LOGOUT_FAILURE);
                    Client.closeProgressBar();
                }
                return;
            }
            if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
                var socialLogin = Platform.getPreference('socialLogin');
                var loginSource = Platform.getPreference("loginsource");
                if(typeof socialLogin != 'undefined' && socialLogin == 'true' && typeof loginSource != 'undefined' && loginSource == 'google'){
                    Client.googleSignOut();
                }
            }
            var dbDeleted = Platform.deleteDatabase();
            if (dbDeleted == true || dbDeleted == false) {
                var prevView = CONTROLLER.getVariable("previousView");
                if (typeof prevView != 'undefined' && prevView == 'Error') {
                    window.location.href = "../html/index.html";
                    Client.closeProgressBar();
                } else {
                    DATABASE_DOWNLOADED = false;
                    $('#wrapper').remove();
                    if(typeof ssoLogin != 'undefined' && ssoLogin != "false"){
                        window.location.href = CLIENT_LOGOUT_URL;
                        Client.closeProgressBar();
                    }else{
                        $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
                            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                        if (typeof SIGNINLANDINGPAGE_ENABLED != 'undefined' && SIGNINLANDINGPAGE_ENABLED == 'true') {
                            CONTROLLER.loadView("SigninLanding");
                        } else {
                            Client.showDialog();
                            // to fix issue during registration on logout 
                            window.location.href = Platform.getAppLocalURL();
                        }
                        Client.closeProgressBar();
                    }
                }
            }
        }, 200);
    } catch (e) {
        Client.closeProgressBar();
    }
}

function tribyte_update_dialog_show(title, description, callback) {

    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var forceUpdate = Platform.getPreference('force_update_app');
    var width;
    var height;
    var description = replaceURLWithHTMLLinks(description);
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }
    if(forceUpdate == "false"){
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "UPDATE NOW": function() {
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    Platform.setPreference('update_app_dailog','true');
                    Client.installApplication();
                    return false;
                },
                "LATER": function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }
    else if(forceUpdate == 'true'){
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "UPDATE NOW": function() {
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    Platform.setPreference('update_app_dailog','true');
                    Client.installApplication();
                    return false;
                },
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }



    var text = '<div class="alert_dialog_update">' +'<div class="update_text">'+'<img class="upgrade_img">' + 'Update'+ '</div> '+'<div class="left_triangle_update"></div>'+'</div>'+
                '<div class="dialog">'+'<div class="update_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="update_dialog_title">' + title + '</div>\n\
                       <div id="update_link" class="update_dialog_description">' + description + '</div>\
                      </div>' ;

    $('#alert_dialog').html(text);

    $('.update_dialog_description a').click(function(e){
        var url = this.href;
        if (url != undefined && url != "") {
            var pattern = /^((http|https|ftp):\/\/)/;
            if (!pattern.test(url)) { //if domain name not starts with "http://
                url = "http://" + url;
            }
            Platform.openBrowser(url);
        }
         e.stopPropagation();
         return false;
    });

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('.upgrade_img').attr('src','/platform/images/upgrade.1c954981.png');
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog').addClass('ui-update-dialog');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
    'background': '#ffffff','position': 'fixed','outline':'none','border-radius':'0px'});
    $('.ui-button-text:contains("Ok")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    if(forceUpdate == "false"){
        $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'46%','background-color':'#00aff0','color':'#fff','border-radius':'40px','height':'50px','margin-right':'6px'});
        $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-left':'20px','margin-bottom':'24%','margin-top':'11px'});
    }
    else if(forceUpdate == 'true'){
        $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'70%','background-color':'#00aff0','color':'#fff','border-radius':'40px','height':'50px','margin-right':'6px'});
        $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-left':'80px','margin-bottom':'24%','margin-top':'11px'});
    }

    $('.update_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'49%'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}



function viewSyllabusDialog(title,description){
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if($(window).width() < 380){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }
    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });
    var text = '<div class="show_syllabus_dialog">' + '<div class="syllabus_text">' + 'SYLLABUS' + '<img class="syllabus_close_img">' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="syllabus_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="syllabus_dialog_title">' + title + '</div>\n\
                       <div class="syllabus_dialog_description">' + description + '</div>\
                      </div>' ;

    $('#alert_dialog').html(text);

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }
    $(".dlgfixed").center(false);
    $('.syllabus_close_img').attr('src','/platform/images/cross_syllabus.png');
    $('.show_syllabus_dialog img').click(function (e) {
        $('#alert_dialog').remove();
        $("#transparent_wrapper").css({
            "display": "none"
        });
        $("html,body").css("overflow", "");
        return false;
    });
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-dialog').addClass('ui-update-dialog');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
    'background': '#ffffff','position': 'fixed','outline':'none','border-radius':'0px'});
    $('.ui-button-text:contains("Ok")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'46%','background-color':'#00aff0','color':'#fff','border-radius':'40px','height':'50px','margin-right':'6px'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-left':'20px','margin-bottom':'24%','margin-top':'11px'});
    $('.update_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'49%'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function  getNoSubjectInfoMessage(){
    return t("NO_COURSE_INFO",true);
}

function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|#|$)", "i");
    if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
        var hash = "";
        if (uri.indexOf("#") !== -1) {
            hash = uri.replace(/.*#/, "#");
            uri = uri.replace(/#.*/, "");
        }
        var separator = uri.indexOf("?") !== -1 ? "&" : "?";
        return uri + separator + key + "=" + value + hash;
    }
}
function fixURLsWithoutProtocol(obj) {
    var str = JSON.stringify(obj);
    var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
    str = replaceAll('https:'+ staticPath, staticPath, str);
    str = replaceAll('http:' + staticPath, staticPath, str);
    str = replaceAll(staticPath, STATIC_SERVER, str);
    return $.parseJSON(str);
}
function tribyte_update_dialog_show(title, description, callback) {

    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var forceUpdate = Platform.getPreference('force_update_app');
    var width;
    var height;
    var description = replaceURLWithHTMLLinks(description);
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }
    if(forceUpdate == "false"){
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "UPDATE NOW": function() {
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    Platform.setPreference('update_app_dailog','true');
                    Client.installApplication();
                    return false;
                },
                "LATER": function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    return false;
                }
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }
    else if(forceUpdate == 'true'){
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "UPDATE NOW": function() {
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    Platform.setPreference('update_app_dailog','true');
                    Client.installApplication();
                    return false;
                },
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }

    var text = '<div class="alert_dialog_update">' +'<div class="update_text">'+'<img class="upgrade_img">' + 'Update'+ '</div> '+'<div class="left_triangle_update"></div>'+'</div>'+
                '<div class="dialog">'+'<div class="update_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="update_dialog_title">' + title + '</div>\n\
                       <div id="update_link" class="update_dialog_description">' + description + '</div>\
                      </div>' ;

    $('#alert_dialog').html(text);

    $('.update_dialog_description a').click(function(e){
        var url = this.href;
        if (url != undefined && url != "") {
            var pattern = /^((http|https|ftp):\/\/)/;
            if (!pattern.test(url)) { //if domain name not starts with "http://
                url = "http://" + url;
            }
            Platform.openBrowser(url);
        }
         e.stopPropagation();
         return false;
    });

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('.upgrade_img').attr('src','/platform/images/upgrade.1c954981.png');
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.ui-dialog-buttonpane').addClass('update_dialog_button');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog').addClass('ui-update-dialog');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
    'background': '#f4f5f9','position': 'fixed','outline':'none','border-radius':'0px'});
    $('.ui-button-text:contains("Ok")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    if(forceUpdate == "false"){
        $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'46%','background-color':'#00aff0','color':'#fff','border-radius':'40px','height':'50px','margin-right':'6px'});
        $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-left':'20px','margin-bottom':'24%','margin-top':'11px'});
    }
    else if(forceUpdate == 'true'){
        $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'70%','background-color':'#00aff0','color':'#fff','border-radius':'40px','height':'50px','margin-right':'6px'});
        $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-left':'80px','margin-bottom':'24%','margin-top':'11px'});
    }

    $('.update_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'60%'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function loginUser(info){
    var userService = CONTROLLER.getService("User");
    var username = info.username;
    var email = info.email;
    var data = "{";
        var temp = "";
        temp = ' "username" : "' + username + '" ,';
        temp = temp + ' "phone" : "' + "" + '"';
        data = data + temp + "}";
    var userexist = userService.doesUserExist(data);
    if (userexist == 409 || userexist == 405) {
        window.location.href = "../html/index.html";
    }else{
        if(typeof DO_NOT_LOGIN_REGISTERED_NEW_USER !='undefined' && DO_NOT_LOGIN_REGISTERED_NEW_USER =='true'){
            var platformview = new platformView();
            CONTROLLER.setVariable('createusersuccess','true');
            CONTROLLER.loadView("Signin");
        }else{
            var status = userService.loginRedirect(username);
            if(status == true){
                setUserLoginData(email);
                CONTROLLER.setVariable('register_successful', 'true');
                CONTROLLER.loadView("Signin");
                Client.removeDialog();
                Client.closeProgressBar();
            }else{
                window.location.href = "../html/index.html";
            }
        }    
    }
}

function setUserLoginData(emailid) {
    var userService = CONTROLLER.getService("User");
    var userdata = userService.getUserInfo("", emailid);
    var userinfo = {};
    userinfo['username'] = userdata[0].name;
    userinfo['email'] = userdata[0].mail;
    userinfo['uid'] = userdata[0].uid;
    userinfo['isloggedin'] = true;
    userinfo['loginRequiredOnline'] = false;
    userinfo['groups'] = userdata[0].groups;
    userinfo['phone'] = userdata[0].mobile;
    OBJECT_USER_DATA = userinfo;
    OBJECT_USER_ID = OBJECT_USER_DATA.uid;
    OBJECT_USER_NAME = OBJECT_USER_DATA.username;
    Platform.setUserID(OBJECT_USER_ID);
	if(APP_TYPE == "ios") {
	    Platform.setPreference("udata", JSON.stringify(userdata));
	    Platform.setPreference("loginRequired", "false");
	}
}


function save_bookmark(params) {

    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var global_pin = 0;
    var width;
    var height;
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }

        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "Save": function() {
                    $("html,body").css("overflow","");
                    params['global_pin'] = global_pin;
                    params['title'] = $('input[id="bookmark_title"]').val();
                    params ['action'] = 'add';
                    if(params['title'].trim() == ''){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Title is empty. Please enter the title.');
                    }else{
                        var api_result = BookmarksService.prototype.updateBookmark(params);
                    }
                    if(api_result == 'success'){
                        if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
                            trackScreenEventDetails({'context' : 'favorite video', 'event_name' : 'TB_TAP_ADD_TO_FAV_VIDEO'});
                        }
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Favorite has been added');
                    }
                    else if(api_result == 'error'){
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        alert('Something went wrong please try again later');
                    }

                    if(!PlayerManager.isPlaying()){
                        PlayerManager.playVideo();
                    }

                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    $('#bookmarks_icon').attr('src' , '/platform/images/bookmark_inactive.4e7f9fb2.png');
                    return false;
                },
                "Cancel": function() {
                    $("html,body").css("overflow","");
                    $(this).dialog("close");
                    if(!PlayerManager.isPlaying()){
                        PlayerManager.playVideo();
                    }
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display":"none"
                    });
                    $('#bookmarks_icon').attr('src' ,'/platform/images/bookmark_inactive.4e7f9fb2.png');
                    return false;
                }
            },
            dialogClass: 'dlgfixed fav_dialog',
            position: "center",
        });



    var text = '<div class="bookmark_dialog">' +'<div class="add_bookmark">'+ 'Add to Favorites' + '</div> '+'</div>'+
        '<div class="dialog">'+'<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
               <div class="add_dialog_title">' + '<input type="text" value="" id="bookmark_title" placeholder="Enter the title"/>' + '</div>\n\
               <div class="add_dialog_global hidden">' + '<input type="checkbox" name="bookmark" value="bookmark"/> Share with others ' + '</div>\n\
              </div>' ;


    $('#alert_dialog').html(text);


    $('input[type="checkbox"]').click(function(){
        if($(this).prop("checked") == true){
            global_pin = 1;
        }
        else if($(this).prop("checked") == false){
            global_pin = 0;
        }
    });

    $('input[id="bookmark_title"]').val(params.content.title);

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });

        $('.alert_wrapper_dialog.ui-dialog').css({'border-radius': '5px'});
    }

    if(typeof ENABLE_BOOKMARK_SHARE != 'undefined' && ENABLE_BOOKMARK_SHARE){
        $('.add_dialog_global').removeClass('hidden');
    }

    $(".fav_dialog").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000','background': '#F4F5F9','position': 'absolute','outline':'none','border-radius':'0px'});
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'35%'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-bottom':'10%','margin-top':'4px'});
    $('.event_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.fav_dialog').css({'padding':'0px','top':'55%','border-radius': '3px !important','border-color':'#0080C6'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

function tribyte_dialog_popup_notification(notification, callback) {
    $('#alert_dialog').remove();
    var preView = CONTROLLER.getVariable("previousView");
    var alert_title = notification.title;
    var alert_description = notification.description;
    if(APP_TYPE == "ios" && alert_description.indexOf('src=') >= 0){
        var temporaryElement = document.createElement('div');
        temporaryElement.innerHTML = alert_description;
        Array.from(temporaryElement.querySelectorAll('img')).forEach((img) => {
            var curSrc = img.src.slice(5);
            var image_url = handleProtocolInRelativeURL(curSrc);
            var newcurSrc = "file:" + curSrc;
            img.src = img.src.replace(newcurSrc, image_url);
        });
        alert_description = temporaryElement.innerHTML;
    }   
    if (alert_description.replace(/<[^>]*>|[\n\r\s]/g, '').toLowerCase() === 'rateapp') {
        return false;
    }
    var platformview = new platformView();
    $('body').append('<div id="alert_dialog" ></div>');
    var width = 0;
    var height = 0;
    var  scrollposition = $(window).scrollTop();


    width = $(window).width() * 0.9;
    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
//            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "OK, GOT IT":{
                text:t('OK, GOT IT'),
                click: function () {
                    if(EXEC_MODE == "online" || APP_TYPE == "ios"){
                        Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid, "popup");
                        Platform.setPreference("notification_seen_"+notification.nid, 1);
                    }else{
                        Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid,1);
                    }
                    $('#alert_dialog').dialog('close');
                }
            }
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            platformview.checkNotifications();
            return false;
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog"><div class="notifications_div"><div class="image_popup"><div class="notification_popup img"></div></div>\n\
                       <div class="alert_dialog_title"><div class="notification_title">' +alert_title+ '</div>\
                       </div> <div class="notification_desc">'+alert_description+'</div>\n\
                      </div></div>';

    $('#alert_dialog').html(text);
    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"auto"
        });
    }

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'z-index': '99999999',
    'background': '#ffffff','position': 'fixed','outline':'none',"top": "85%"});
    $(".ui-dialog-titlebar").hide();
    $(".ui-dialog-buttonset button").attr("id","popup_button");
    $(".ui-dialog-buttonset").css({"background":"#fff", "display":"flex","justify-content":"center","padding": "10px 0 10px 0","border-radius":"0 0 7px 7px"});
    $('.alert_dialog').css({"min-height": "200px"});
    $('.alert_dialog').css({"margin-top":"30px", "position":"relative", "background":"transparent","margin-bottom":"0px"});
    $(".alert_wrapper_dialog").css({"background":"transparent","border":"none!important", "border-radius":"10px!important"});
    $(".alert_wrapper_dialog .ui-dialog-buttonpane").attr("id","popup_buttonpane");
    $(".alert_wrapper_dialog").attr("id","notifications_dialog");

    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });

    $(".notification_desc").unbind("click").bind("click", function(e){
        var targetElement = e.target;
        var url = $(targetElement).attr('href');
        if (e.target.parentNode.nodeName.toLowerCase() == "a") {
            targetElement = e.target.parentNode;
        } else {
            targetElement = e.target;
        }
        if (targetElement.nodeName.toLowerCase() == "a") {
            var url = $(targetElement).attr('href');
            if (url != undefined && url != "" && url != "#" && url.indexOf("forum/topic") == -1) {
                var pattern = /^((http|https|ftp):\/\/)/;
                if (!pattern.test(url)) { //if domain name not starts with "http://
                    url = "http://" + url;
                }
                Platform.openBrowser(url);
            }
            e.stopPropagation();
            if(EXEC_MODE == "online" || APP_TYPE == "ios"){
                Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid, "popup");
                Platform.setPreference("notification_seen_"+notification.nid, 1);
            }else{
                Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid,1);
            }
            $('#alert_dialog').dialog('close');
            return false;
        }
    });

    var dialog = $('#alert_dialog').closest('.ui-dialog');
    dialog.animate( {'top': '55%'}, 1000);
    $("#popup_button").unbind("click").bind("click",function(){
        if(EXEC_MODE == "online" || APP_TYPE == "ios"){
            Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid, "popup");
            Platform.setPreference("notification_seen_"+notification.nid, 1);
        }else{
            Platform.updateNotificationReadStatus(OBJECT_USER_ID,notification.nid,1);
        }
        $('#alert_dialog').dialog('close');
    });
}

function save_quiz_bookmark(params, callback) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var global_pin = 0;
    var width;
    var height;
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Save": function() {
                $("html,body").css("overflow","");
                params['global_pin'] = global_pin;
                params['quiz_title'] = $('input[id="quiz_bookmark_title"]').val();
                params['action'] = 'add';
                params['type'] = 'quiz';
                if(params['quiz_title'].trim() == ""){
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Title is empty. Please enter the title.');
                }else{
                    var api_result = BookmarksService.prototype.updateBookmark(params);
                }
                if(api_result == 'success'){
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    callback(api_result, params);
                    if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
                        trackScreenEventDetails({'context' : 'Favorites practice', 'event_name' : 'TB_TAP_ADD_TO_FAV_PRACTICE' });
                    }
                    alert('Quiz favorite has been added');
                }
                else if(api_result == 'error'){
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Something went wrong please try again later');
                }

                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;
            },
            "Cancel": function() {
                $("html,body").css("overflow","");
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_event_dialog">' +'<div class="add_favorite_title">'+ 'Add to Favorites' + '</div> '+'</div>'+
                '<div class="dialog">'+'<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="add_quiz_title">' + '<input type="text" value="" id="quiz_bookmark_title" placeholder="Enter the title"/>' + '</div>\n\
                       <div class="add_dialog_global hidden">' + '<input type="checkbox" name="bookmark" value="bookmark"/> Share with others ' + '</div>\n\
                      </div>' ;


    $('#alert_dialog').html(text);

    $('input[type="checkbox"]').click(function(){
        if($(this).prop("checked") == true){
            global_pin = 1;
        }
        else if($(this).prop("checked") == false){
            global_pin = 0;
        }
    });

    $('input[id="quiz_bookmark_title"]').val(params.quiz_title);

    var favourites = BookmarksService.prototype.getQuizFavorites(params);
    var favouritesTitles = [];
    for(var i=0;i<favourites.length;i++){
        favouritesTitles.push(favourites[i].quiz_title)
    }

    $( 'input[id="quiz_bookmark_title"]' ).autocomplete({
      source: favouritesTitles
    });

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    if(typeof ENABLE_BOOKMARK_SHARE != 'undefined' && ENABLE_BOOKMARK_SHARE){
        $('.add_dialog_global').removeClass('hidden');
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_quiz_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000','background': '#F4F5F9','position': 'absolute','outline':'none','border-radius':'0px'});
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'35%'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-bottom':'2%','margin-top':'15px'});
    $('.event_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'55%','border-radius': '3px !important','border-color':'#1997D4','padding-bottom': '15px','overflow':'visible'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('.ui-autocomplete').css(
        {
            "font-size":"1.5rem",
            "max-height": "150px",
            "overflow": "auto",
            
        }
    )
    if(!ENABLE_NEW_UI){
        $('html body .ui-dialog').css({'background': '#FFF'});
        $('.add_favorite_title').css({'color': '#FFF'});
        $('.add_quiz_title').css({'background-color': '#FFF'});
    }
}

function handleProtocolInRelativeURL(url){
    if (url.startsWith("//") && url.indexOf("http") == -1) {
        if(OBJECT_SERVER_DOMAIN.indexOf("https") > -1){
            url = 'https:' + url;
        }
        else{
            url = 'http:' + url;
        }
    }
    return url;
}

function delete_quiz_favorite(params, callback){
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": function() {
                $("html,body").css("overflow","");
                params['action'] = 'delete';
                params['type'] = 'quiz';
                var api_result = BookmarksService.prototype.updateBookmark(params);
                if(api_result == 'success'){
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    callback(api_result, params);
                    alert('Quiz favorite has been deleted');
                }
                else if(api_result == 'error'){
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Something went wrong please try again later');
                }
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;
            },
            "Cancel": function() {
                $("html,body").css("overflow","");
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_event_dialog">' +'<div class="delete_favorite_text">'+ 'Delete Favorite ' +'</div> '+'</div>'+
                            '<div class="dialog">'+'<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
            <div class="delete_dialog_title">'+'Are you sure you want to delete this quiz favorite ?'+ '</div>\n\
            </div>' ;

    $('#alert_dialog').html(text);

    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('delete_favorite_btn');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000','background': '#F4F5F9','position': 'fixed','outline':'none','border-radius':'0px'});
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'35%'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-bottom':'6%','margin-top':'16px'});
    $('.event_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'55%','border-radius': '3px !important','border-color':'#0080C6'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });

    if(!ENABLE_NEW_UI){
        $('html body .ui-dialog').css({'background': '#FFF'});
        $('.alert_wrapper_dialog .ui-dialog-buttonpane').css({'background':'#f4f5f9'});
        $('.delete_favorite_text').css({'color' : '#FFF'});
    }

}
//limit the number keyed in characters to max_chars(in the argument)
//element will be input characters
function limitInputChars(element, max_chars)
{
    if(element.value.length > max_chars) {
        element.value = element.value.substr(0, max_chars);
    }
}
//set the minimum and maximum lenght of characters input
//min will be minimum number of characters
//max will be the maximum number of characters
//value will be input characters
function setCharsLimit (value, min, max) 
{
    if(parseInt(value) < min || isNaN(parseInt(value))) 
        return 0; 
    else if(parseInt(value) > max) 
        return 100; 
    else return value;
}

function select_video_resolution_for_download(show_prompt_option, resource, courseid, activecatid ) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": function () {
                $("html,body").css("overflow", "");
                var radioValue = $("input[name='resolution']:checked").val();
                var rememberDownloadResolution = $('#remember_download_resolution').is(':checked');
                if ($.isNumeric(radioValue)) {
                    var prompt_resolution = Platform.getPreference("prompt_resolution");
                    DOWNLOAD_RESOURCE_FORMAT = radioValue;
                    Platform.setPreference("downlaod_resource_format", radioValue);
                    if(resource == "" || rememberDownloadResolution == true){
                        Platform.setPreference("prompt_resolution", "");
                    }
                    if(radioValue == "240"){
                        alert("You have selected " + radioValue + "px (Low resolution)");
                    }else if(radioValue == "480"){
                        alert("You have selected " + radioValue + "px (Medium resolution)");
                    }else if(radioValue == "720"){
                        alert("You have selected " + radioValue + "px (High resolution)");
                    }
                    if(typeof show_prompt_option != "undefined" && show_prompt_option == false){
                        var contentUtils = CONTROLLER.getService("ContentUtils");
                        contentUtils.fileDownload(resource, radioValue, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
                    }
                } else {
                    Platform.setPreference("prompt_resolution", "true");
                    alert("You can select the resolution while downloading the Video");
                }
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
                // return "";
            },
            "Cancel": function () {
                $("html,body").css("overflow", "");
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_resolution_dialog">' + '<div class="select_resolution_text">' + 'Select the resolution for Video Download' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
            <div class="resolution_dialog_title"></div>' ;
    $('#alert_dialog').html(text);

    if(resource == ""){
        var resource_online_format = RESOURCE_ONLINE_FORMAT.split(",");
    }else{
        var resource_online_format = resource['format-type'].split(",");
    }
    
    var radioHTML = "";
    $.each(resource_online_format, function( index, value ) {
        if(value == '240'){
        radioHTML += '<input type="radio" name="resolution" id="'+ value +'px" value="'+ value +'">\n\
        <label for="'+ value +'px">'+value +'px (Low resolution)</label><br>';
        } else if(value == '480'){
            radioHTML += '<input type="radio" name="resolution" id="'+ value +'px" value="'+ value +'">\n\
            <label for="'+ value +'px">'+value +'px (Medium resolution)</label><br>';
        }else if(value == '720'){
            radioHTML += '<input type="radio" name="resolution" id="'+ value +'px" value="'+ value +'">\n\
            <label for="'+ value +'px">'+value +'px (High resolution)</label><br>';
        }
    });
    if(typeof show_prompt_option != "undefined" && show_prompt_option == true){
        radioHTML += '<input type="radio" id="prompt_resolution" name="resolution" value="Prompt Resolution">\n\
        <label id="label_prompt_resolution" for="prompt_resolution">Prompt (Ask before download)</label>';
    } else {
        radioHTML += '<br><input type="checkbox" id="remember_download_resolution">\n\
        <label for="remember_download_resolution" style="margin-bottom:0px;font-weight:unset;"> Remember this for next download.</label>'
    }
    $(".resolution_dialog_title").html(radioHTML);

    var prompt_resolution = Platform.getPreference("prompt_resolution");

    if(prompt_resolution != "true" && typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
        $('input:radio[name="resolution"][value="' + DOWNLOAD_RESOURCE_FORMAT + '"]').prop('checked', true);
    }else if(prompt_resolution == "true"){
        $('input:radio[name="resolution"][value="Prompt Resolution"]').prop('checked', true);
    }else if(typeof show_prompt_option != "undefined" && show_prompt_option == true){
        $('input:radio[name="resolution"][value="Prompt Resolution"]').prop('checked', true);
    }
    if(resource != ""){
        if(resource_online_format.length == 1){
            $('input:radio[name="resolution"][value="' + resource_online_format + '"]').prop('checked', true);
        }else{
            $('input:radio[name="resolution"][value="' + RESOURCE_FORMAT + '"]').prop('checked', true);
        }
    }
    if(typeof show_prompt_option != "undefined" && show_prompt_option == false){
        $('input:radio[name="resolution"][value="Prompt Resolution"]').addClass("hidden");
        $('label[for="prompt_resolution"]').addClass("hidden");
    }
    if($('input:radio[name="resolution"][value="Prompt Resolution"]').hasClass("hidden") || 
        $('input:radio[name="resolution"][value="Prompt Resolution"]').length == 0){
        if(typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT != ""){
            $('input:radio[name="resolution"][value="' + DOWNLOAD_RESOURCE_FORMAT + '"]').prop('checked', true);
        }else{
            $('input:radio[name="resolution"][value="' + RESOURCE_FORMAT + '"]').prop('checked', true);
        }
    }

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('delete_favorite_btn');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#F4F5F9', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '6%', 'margin-top': '16px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.ui-dialog').css({ 'padding': '0px', 'top': '55%', 'border-radius': '3px !important', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });

    if (!ENABLE_NEW_UI) {
        $('html body .ui-dialog').css({ 'background': '#f4f5f9' });
        $('.alert_wrapper_dialog .ui-dialog-buttonpane').css({ 'background': '#f4f5f9' });
        $('.delete_favorite_text').css({ 'color': '#FFF' });
    }
}

function getQuizData(urldata){
    var xhr_field = {
        withCredentials: true
    };
    
    if (urldata.indexOf('.json') > -1) {
        xhr_field = false;
    }

    var xhr_field = {
        withCredentials: true
    };

    $.ajax({
        type: "GET",
        url: urldata,
        async: false,
        xhrFields: xhr_field,
        timeout: 40000,
        crossDomain: true,
        success: function (data) {
            // Run the code here that needs
            // to access the data returned
            var quiz_data = {};
            quiz_data['status'] = '200';
            quiz_data['data'] = JSON.stringify(data);
            quiz_data = JSON.stringify(quiz_data);
            result = quiz_data;
        },
        error: function(xhr, status, error) {
            status = xhr.status;
            var error_message = error.message ? error.message : error;
            console.log("url = " + urldata + " Error = " + error_message);

            if(status == "500" || status == "502" || status == "503" || status == "504"){
                Client.closeProgressBar();
                alert(ERROR_MESSAGE);
            } else if(status == "403"){
                for(var i in LOGIN_RESTRICTED_URLS){
                    if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                        onAccessDenied();
                    }
                }
            }
        }
    });

    if(typeof resulttype == "undefined")
        return result;

    if(resulttype === "boolean" && result === "true")
        return true;
    else if(resulttype === "boolean" && result === "false")
    return false

    return result;
}

function getSingleQuestionData(urldata){
    var xhr_field = {
        withCredentials: true
    };
    
    if (urldata.indexOf('.json') > -1) {
        xhr_field = false;
    }

    var xhr_field = {
        withCredentials: true
    };

    $.ajax({
        type: "GET",
        url: urldata,
        async: false,
        xhrFields: xhr_field,
        timeout: 40000,
        crossDomain: true,
        success: function (data) {
            // Run the code here that needs
            // to access the data returned
            var quiz_data = {};
            quiz_data['status'] = '200';
            quiz_data['data'] = data;
            quiz_data = JSON.stringify(quiz_data);
            result = quiz_data;
        },
        error: function(xhr, status, error) {
            status = xhr.status;
            var error_message = error.message ? error.message : error;
            console.log("url = " + urldata + " Error = " + error_message);

            if(status == "500" || status == "502" || status == "503" || status == "504"){
                Client.closeProgressBar();
                alert(ERROR_MESSAGE);
            } else if(status == "403"){
                for(var i in LOGIN_RESTRICTED_URLS){
                    if(urldata.indexOf(LOGIN_RESTRICTED_URLS[i]) > 0){
                        onAccessDenied();
                    }
                }
            }
        }
    });

    if(typeof resulttype == "undefined")
        return result;

    if(resulttype === "boolean" && result === "true")
        return true;
    else if(resulttype === "boolean" && result === "false")
    return false

    return result;
}

function tribyte_check_plans_show(alert_text,callback) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width = 0;
    var height = 0;
      var buy_now_title = typeof BUY_NOW_BUTTON_TITLE != "undefined" && BUY_NOW_BUTTON_TITLE != "" ? "Buy Now" : "Check Plans"
    if($(window).width() < 380){
        width = 250;
        height = 120;
    }
    else{
        width = 300;
        height = 120;
    }
    
    if(IS_IOS || (typeof SHOW_CHECK_PLANS !== "undefined" && SHOW_CHECK_PLANS === "false")){
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                'Ok':{
                    text:t("Ok"),
                    click: function () {
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        $("#transparent_wrapper").css({
                            "display": "none"
                        });
                        }
                    }
            },
            close: function () {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;
    
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }else{
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function() {
    //            window.scrollTo(0,scrollposition);
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                'Ok':{
                    text:t(buy_now_title),
                    click: function () {
                        $(this).dialog("close");
                        $('#alert_dialog').remove();
                        $("#transparent_wrapper").css({
                            "display": "none"
                        });
                        if (Platform.getNetworkState() == true) {
                            if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
                                trackScreenEventDetails({'context' : 'check subscription', 'event_name' : 'TB_TAP_SUBSCRIBE'});
                            }
                            platformView.prototype.unsetTaskQuiz();
                            $('.footer_home_image, .footer_course_image, .footer_forum_image, .footer_schedule_image, .footer_more_image').removeClass('active_tab');
                            $('.footer_all_course_image').addClass('active_tab');
                            platformView.prototype.handleBuyNowClick()
                        } else {
                            alert(INTERNET_ERROR);
                        }
                    }
                }
            },
            close: function () {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                return false;

            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    }

    var text = '<div class="alert_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(alert_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);
    if($(window).width() > $(window).height()){
        $('.alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"auto"
        });
    }

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'z-index': '99999999',
    'background': '#ffffff','position': 'fixed','outline':'none'});
    $(".ui-dialog-titlebar").hide();
    $(".ui-dialog-buttonset .ui-button").css("width","100%");
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });

}

function apm_generate_result (quiz_attempts_apm, inProgress){
    var generate_result = {};
    generate_result['nid'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['nid'];
    generate_result['vid'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['vid'];
    generate_result['uid'] = OBJECT_USER_ID;
    generate_result['time_start'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['time_start'];
    generate_result['time_end'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['time_end'];
    generate_result['score'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['score'];
    generate_result['is_invalid'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['is_invalid'];
    generate_result['is_evaluated'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['is_evaluated'];
    generate_result['time_left'] = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['time_left'];
    return generate_result;
}

function hasId(data, id) { return data.some(function (el) { return el.nid === id; }); }

function hasIdByKey(data, id, key) { return data.some(function (el) { return el[key] === id; }); }

function hasResourceId(data, id) { return data.some(function (el) { return el.resourceid === id; }); }

function getObjectById(data, id, key) {
    for(i in data){
        if (data[i][key] === id) {
            return data[i];
        };
    }
}

function enableZoom(){
    Platform.enableZoom(true);
    $("#viewport").attr("content", "width=device-width, user-scalable=yes");
}

function disableZoom(){
    Platform.enableZoom(false);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
}

/* video player changes */

function resizeVideoPlayer(){
    if($(window).width() < $(window).height()){
        CONTROLLER.setVariable("landscape_orientation_clicked",true)
        setVideoPlayerToLandscape();
    }else{
        CONTROLLER.setVariable("portrait_orientation_clicked",true)
        setVideoPlayerToPortrait();
    }
}

function setVideoPlayerToLandscape(){
    Platform.setDeviceOrientation("landscape");
    SHOW_BREAKS_IN_PORTRAIT_VIDEO = false;
    $(window).on("resize", function() {
        if(CONTROLLER.getVariable("landscape_orientation_clicked") == true){
            videoPlayerLandscapeView();
        }
    });
    if (EXEC_MODE == "online" && APP_TYPE != "ios") {
        CONTROLLER.onBack();
}
}

function videoPlayerLandscapeView(){
    CONTROLLER.setVariable("landscape_orientation_clicked",false)
    $('.title-overlay-wrapper').removeClass('hidden');
    $("#header_wrapper").css({"display": "none"});
    $('#header_wrapper').addClass('hidden');
    setTimeout(function(){
        var height =  $(window).height();
        var width = $(window).width();
        resizeVideoScreen();
        if (width > height) {
            $('#video_player_wrapper').removeClass('video_screen_format');
            $('.video-js, .video_player_wrapper').css({'height':height + 'px'});
        }
    },300);
    $('.video_player_tabs').addClass('hidden');
    $('#video_tab_content_wrapper').addClass('hidden');
    $('#videojs-overlay').removeClass('video_player_overlay_style');
    $('.vjs-control-bar').removeClass('removePlayerBarPosition');
    $('.videojs-skip-button').removeClass('videojs-skip-button-portrait');
    $('.video-js .vjs-control-bar').css({'font-size':'15px'});
    $('#bookmarks_button img, #orientation_button img').css({'height':'23px'});
    $('.vjs-loading-spinner').css({'width':'50px', 'height':'50px'});
    $('.vjs-menu .vjs-menu-content').css({'width':'100px'});
    $('.vjs-menu li').css({'height':'45px', 'line-height':'45px'});
    $('#water_mark').css({"font-size": "14px"});
    $('.videojs-skip-button').removeClass('skip_for_portrait');
    $('#playback_rate_btns').css({'margin-left':'-20px','font-size': '17px'});
    $('.title-overlay-inner').css("width", $(window).width() - 190);
    $(".title_text").css({"width" : $(window).width() - 190});
    $('#orientation_change').attr('src', '/platform/images/orientation_change_portait.08e2b436.png');
    $('.vjs-marker.lastSeenDuration').css({'top': '15px','height': '15px'});
    $('#content_wrapper').removeClass('content_wrapper_height');
    $('.vjs-progress-control.vjs-control').css({'width': '92%', 'left': '30px'});
    $('.video-js .vjs-time-control').css({'left': '30px'});
    $('.video-js .vjs-control').css({'right': '2em'});  
    $('#playback_rate_btns').css({'right': '2em'});
    $('#windows_wrapper').css({'width': '100%'});

    if(Platform.getNetworkState() == false && $("#rate_upvote_image").length == 0){
        if($('#subtitles_button').length > 0){
            $('#subtitles_button').css("margin-left","auto")
        }
        else{
            $("#bookmarks_icon").css("margin-left","auto")
        }
    }
    $("#conditional-break").removeClass("hidden")
    if(typeof IS_CONDITIONALBREAK_VISIBLE != 'undefined' && IS_CONDITIONALBREAK_VISIBLE){
        if(typeof BreakManager.breakToBeShown != 'undefined'){
            $('#clipping-break-wrapper-' + BreakManager.breakToBeShown.breakId+'').find("#clipping-break-content").removeClass("hidden");
            $('#close_break').click();
            closeDialog();
        }
    }
  
    $(".res-prev-icon").css({"right":"185px"});
    $(".res-next-icon").css({"right":"125px"});

    $('.video-js .vjs-time-control').css({'width': '75px'});
    $('.video-js .vjs-time-divider').css({'width': 'auto'});
    $('.video-js .vjs-current-time').css({'left': '17px'});
    $('.video-js .vjs-time-divider').css({'left': '3px'});
    $('.video-js .vjs-duration').css({'left': '-8px'});
    $(".vjs-progress-control.vjs-control").css({ "width":"calc(100% - 5em)","left": "calc(1em + 15px)"});
    if (('.markCompleteOuterWrapper').length > 0) {
        $(".markCompleteOuterWrapper").css("width","45%");
    }
    Platform.setStatusAndNavigationBar(false)
    $(window).off("resize", videoPlayerLandscapeView)
    if (EXEC_MODE == "online" && APP_TYPE != "ios") {
        $('#orientation_button').removeClass('hidden');
        $('#orientation_change').attr('src', '/platform/images/orientation_change.14e8d417.png');
    }

    // Hide chatbot button in videoplayer landscape view
    if($('#chatbot_container').length > 0){
        $('#chatbot_container').addClass('hidden');
    }
}

function setVideoPlayerToPortrait(){
    Platform.setDeviceOrientation("portrait");
    SHOW_BREAKS_IN_PORTRAIT_VIDEO = false;
    Platform.setStatusAndNavigationBar(true);
    $(window).on("resize", function() {
        if(CONTROLLER.getVariable("portrait_orientation_clicked") == true){
            videoPlayerPortraitView();
        }
    });
    if (EXEC_MODE == "online" && APP_TYPE != "ios") {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().then(() => {
                setTimeout(() => {
                    screen.orientation.lock('portrait')
                    .then(() => {
                        setTimeout(() => {
                            $('#orientation_change').attr('src', '/platform/images/orientation_change.14e8d417.png');
                            document.exitFullscreen();
                        }, 100);
                    })
                    .catch((error) => {
                        console.error('Failed to lock the screen orientation landscape:', error);
                    });
                }, 100);
            })
        } else if (document.fullscreenElement) {
            screen.orientation.lock('portrait')
            .then(() => {
                setTimeout(() => {
                    $('#orientation_change').attr('src', '/platform/images/orientation_change.14e8d417.png');
                    document.exitFullscreen().then(() => videoPlayerPortraitView());
                }, 100);
            })
            .catch((error) => {
                console.error('Failed to lock the screen orientation portrait:', error);
            });
        }
    }
}

function videoPlayerPortraitView(){
    CONTROLLER.setVariable("portrait_orientation_clicked",false)
    if ($('#video_player_list_wrapper').length > 0) {
        $('.title-overlay-wrapper').addClass('hidden');
    }
    $("#header_wrapper").css({"display": "block"});
    resizeVideoScreen();
    var height =  '100%';
    var video_title = $('.title-overlay-inner').text()
    if(typeof video_title != "undefined" && video_title != ""){
        $(".title_text").html(video_title)
    }
    $('#header_wrapper').removeClass('hidden');
    $('.video-js').css({'height':height});
    $('#video_player_wrapper').addClass('video_screen_format');
    $('.video_player_tabs').removeClass('hidden');
    $('#video_tab_content_wrapper').removeClass('hidden');
    $('#videojs-overlay').addClass('video_player_overlay_style');
    $('.vjs-control-bar').addClass('removePlayerBarPosition');
    $('.videojs-skip-button').addClass('videojs-skip-button-portrait');
    $('.video-js .vjs-control-bar').css({'font-size':'10px'});
    $('#bookmarks_button img').css({'height':'18px'});
    $('#orientation_button img').css({'height':'16px'});
    $('.vjs-loading-spinner').css({'width':'30px', 'height':'30px'});
    $('.vjs-menu .vjs-menu-content').css({'width':'60px'});
    $('.vjs-menu li').css({'height':'30px', 'line-height':'30px'});
    $('#water_mark').css({"font-size": "8px"});
    $('.videojs-skip-button').addClass('skip_for_portrait');
    $('#playback_rate_btns').css({'margin-left':'-4px','font-size': '8px'});
    $('#right-menu-wrapper').addClass('hidden');
    $('#right-menu-index-wrapper').addClass('hidden');
    $('.title-overlay-inner').css("width", $(window).width() - 150);
    $('#orientation_change').attr('src', '/platform/images/orientation_change.14e8d417.png');
    $("#conditional-break").addClass("hidden")
    if(typeof CONTROLLER.activeViewName != 'undefined' && (CONTROLLER.activeViewName == 'VideoPlayer' || CONTROLLER.activeViewName == 'McqBreak')){
        $(".title_text").css({"width" : "75vw"});
    }else if(typeof CONTROLLER.activeViewName != 'undefined' && CONTROLLER.activeViewName == 'Modules'){
        if ($(window).width() < 380) {
            $(".title_text").css({"width" : $(window).width() - 140});
            $('.title_text').addClass('title_text_style');
        }else{
            $(".title_text").css({"width" : $(window).width() - 160});
            $('.title_text').addClass('title_text_style');
        }
    }
    $('.vjs-marker.lastSeenDuration').css({'top': '10px','height': '10px'});
    $('#content_wrapper').removeClass('content_wrapper_height');
    $('.vjs-progress-control.vjs-control').css({'width': '100%', 'left': '0px'});
    $('.video-js .vjs-time-control').css({'left': '0px'});

    $('.video-js .vjs-control').css({'right': '2em'});
    $('#playback_rate_btns').css({'right': '2em'});
    
    if(typeof IS_CONDITIONALBREAK_VISIBLE != 'undefined' && IS_CONDITIONALBREAK_VISIBLE){
        if(typeof BreakManager.breakToBeShown != 'undefined'){
            $('#clipping-break-wrapper-' + BreakManager.breakToBeShown.breakId+'').find("#clipping-break-content").addClass("hidden");
            $('#close_break').click();
            closeDialog();
        }
    }
    
    if(Platform.getNetworkState() == false && $("#rate_upvote_image").length == 0){
        if($('#subtitles_button').length > 0){
            $('#subtitles_button').css("margin-left","auto")
        }
        else{
            $("#bookmarks_icon").css("margin-left","auto")
        }
    }
    $(".res-prev-icon").css({"right":"60px"});
    if (typeof SHOW_MARK_CONTENT != 'undefined' && SHOW_MARK_CONTENT) {
        $(".res-next-icon").css({"right":"45px"});
        $(".res-prev-icon").css({"right":"75px"});
        if (('.markCompleteOuterWrapper').length > 0) {
            $(".markCompleteOuterWrapper").css("width","85%")
        }
    }else{
        $(".res-next-icon").css({"right":"30px"});
    }

    /*if(CONTROLLER.getVariable("video_notes") == "true"){
        $('#v_p_tab_video_notes').click();
    }*/
    
    $('.video-js .vjs-time-control').css({'width': '50px'});
    $('.video-js .vjs-time-divider').css({'width': 'auto'});
    $('.video-js .vjs-current-time').css({'left': '1.4em'});
    $('.video-js .vjs-time-divider').css({'left': '0.6em'});
    $('.video-js .vjs-duration').css({'left': '0em'});
    $(".vjs-progress-control.vjs-control").css({ "width":"calc(100% - 3.5em)","left": "calc(1em + 10px)"});
    $(window).off("resize", videoPlayerPortraitView)
    var height = $(window).height();
    var video_tab_content_wrapper_height = height -  ( 48 + $('.resource_player').height() + 46 );
    $('#video_tab_content_wrapper').css({'height': video_tab_content_wrapper_height + 'px', 'overflow' : 'auto'});
    $('#v_p_tab_video_notes').click();

    // check for chatbot enabled and show the button
    var enable_chatbot = CONTROLLER.getVariable('enable_chat_button');
    if(typeof enable_chatbot !== 'undefined' && enable_chatbot === "True" && $('#chatbot_container').hasClass('hidden')){
        $('#chatbot_container').removeClass('hidden')
    }   
}

function resizeVideoScreen(){
    $("body,#wrapper,#content_wrapper").css({
        'width': $(window).width(),
        'height': $(window).height()
    });
    $('#header_wrapper').css({
        'width': $(window).width(),
    });
}

function fullScreenPDF(){
    CONTROLLER.setVariable('pdf_fullscreen', true);
    $('.pdf_view_btn').css({'top' : '30px'});
    $('.pdf_view_btn').removeClass('pdf_halfscreen');
    $('.pdf_view_btn').addClass('pdf_fullscreen');
    $('.v_tab_notes_view').addClass('show_full_pdf_view');
    $('.toolbar').removeClass('hidden');
}

function halfScreenPDF(){
    var pdf_top = $('.resource_player').height() + 46 + 30;
    CONTROLLER.setVariable('pdf_fullscreen', false);
    $('.pdf_view_btn').css({'top' : pdf_top + 'px'});
    $('.pdf_view_btn').removeClass('pdf_fullscreen');
    $('.pdf_view_btn').addClass('pdf_halfscreen');
    $('.v_tab_notes_view').removeClass('show_full_pdf_view');
    $('.toolbar').addClass('hidden');
    return "";
}

function resizeVideoPlayerByRotate(){
    var width = $(window).width();
    var height = $(window).height();
    if(width > height){
        videoPlayerLandscapeView();
    }else{
        videoPlayerPortraitView();
    }
}

function edit_video_notes(params, callback) {
    $('#notes_alert_dialog').remove();
    $('body').append('<div id="notes_alert_dialog" ></div>');
    var global_pin = 0;
    var width;
    var height;
    if($(window).width() < 480){
        width = 300;
        height = 'auto';
    }
    else{
        width = 340;
        height = 'auto';
    }

    $('#notes_alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function() {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Save": function() {
                $("html,body").css("overflow","");
                // saving edited notes
                var notes_content = [];

                params["note"] = $('input[id="video_notes_title"]').val();

                if (EXEC_MODE == "online") {
                    notes_content.push(params);
                    NOTES_OF_CATEGORY = [];
                    NOTES_OF_COURSE = [];
                    Platform.addOrUpdateNotes(JSON.stringify(notes_content), "delete");
                    delete NOTES_LIST[params.nid];
                } else {
                    Platform.addOrUpdateNotes(JSON.stringify(params));
                    callback("success");   
                }

                $("html,body").css("overflow","");
                $(this).dialog("close");
                $('#notes_alert_dialog').remove();

                $("#transparent_wrapper").css({
                    "display":"none"
                });
                return false;
            },
            "Cancel": function() {
                $("html,body").css("overflow","");
                $(this).dialog("close");
                $('#notes_alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });

                if (!PlayerManager.isPlaying()) {
                    PlayerManager.playVideo();
                }

                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="video_notes_edit_dialog">' +
        '<div class="dialog">'+'<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
               <div class="add_video_notes_dialog_title">' + '<input type="text" value="" id="video_notes_title" placeholder="Edit the notes"/>' + '</div>\n\
              </div>' ;


    $('#notes_alert_dialog').html(text);

    if($(window).width() > $(window).height()){
        $('.notes_alert_dialog').css({
            "max-height":height + 20,
            "overflow-y":"scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#notes_alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_quiz_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000','background': '#F4F5F9','position': 'absolute','outline':'none','border-radius':'0px'});
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({'width':'35%'});
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({'margin-bottom':'2%','margin-top':'15px'});
    $('.event_dialog_description a').css({'color':'#00aff0','text-decoration':'underline','font-weight':'500'});
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({'font-weight':'600'});
    $('.ui-dialog').css({'padding':'0px','top':'50%','border-radius': '3px !important','border-color':'#1997D4','padding-bottom': '15px','overflow':'visible'});
    $('.event_dialog').css({'margin-top':'10px'});
    $("#transparent_wrapper").css({
        "display":"block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $('.ui-autocomplete').css(
        {
            "font-size":"1.5rem",
            "max-height": "150px",
            "overflow": "auto",
            
        }
    )
    if(!ENABLE_NEW_UI){
        $('html body .ui-dialog').css({'background': '#FFF'});
        $('.add_favorite_title').css({'color': '#FFF'});
        $('.add_quiz_title').css({'background-color': '#FFF'});
    }
}

function checkIsEmpty(strData){
    if (typeof strData === 'undefined' || strData === '' || strData === null || strData == {} || strData == [] || strData == 'null') {
        return true;
    } else {
        if (typeof strData === 'string' && strData.trim().length <= 0) {
            return true;
        }
        return false;
    }
}
function supportsAudioType(type) {

    if (type == 'mp3' || type == 'wav') {
        return 'true';
    }
    var audio;
    var formats = {
        mp4: 'audio/mp4',
        ogg: 'audio/ogg',
        aac: 'audio/mp4; codecs="mp4a.40.2"',
        aif: 'audio/x-aiff',
        m4a: 'audio/mp4',
        oga: 'audio/ogg',
        webma: 'audio/webm',
        amr: 'audio/AMR',
        flac: 'audio/flac'
    };
    if (!audio) {
        audio = document.createElement('audio');
    }
    return (audio.canPlayType && audio.canPlayType(formats[type] || type));
}

function checkIsBookmarked(getQuizBookmarks,nid){
    try {
        for (var i = 0; i < getQuizBookmarks.length; i++) {
            if(getQuizBookmarks[i]["question_nid"] == nid){
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log("function : onAccessDenied"
                + "\n message:" + e.message);
    }
}

function checkQuizHasCourseId(){
    var course_id = CONTROLLER.getVariable('activecourseid');
    var task_course_id = CONTROLLER.getVariable('task_course_id');
    if (QUIZ_RESOURCE_PARAMS != "" && (QUIZ_RESOURCE_PARAMS.courseid != "" && QUIZ_RESOURCE_PARAMS.catid != "")) {
        return true;
    }else if(!checkIsEmpty(course_id)){
        return true;
    }else if(!checkIsEmpty(task_course_id)){
        return true;
    }
    return false;
}

redeemCouponDialog = function(){
    if(Platform.getNetworkState() == true){
        var THIS = this;
    let isApiCallMade = false; 
    let settingService = CONTROLLER.getService("Settings");
    var email = OBJECT_USER_DATA['email']; 
        $('#alert_dialog').remove();
        $('body').append('<div id="alert_dialog" ></div>');
        var width;
        var height;
        var description = REDEEM_COUPON_DESCRIPTION;
        
        // Set the default button text;
        var redeemButtonText = REDEEM_NOW;

        if ($(window).width() < 480) {
            width = 300;
            height = 'auto';
        } else {
            width = 340;
            height = 'auto';
        }

        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function () {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                [redeemButtonText]: function (e) {
                    if (Platform.getNetworkState() != true) {
                        $('.invalid_coupon_message').html(INTERNET_ERROR);
                        $('.invalid_coupon_message').css({'color':'red','display':'block'});
                    }else{
                        if(!isApiCallMade){
                            var coupon = $("#redeem_input").val();
                            if(coupon.trim().length > 0){
                                if(validateInput($("#redeem_input"))){
                                    Client.showProgressBar(t("Loading") + "...");
                                    var coupon = $("#redeem_input").val();
                                    setTimeout(function () {
                                        if(!isApiCallMade){
                                            isApiCallMade = true;
                                            var response = settingService.applyCoupon(OBJECT_USER_ID, coupon);
                                        }
                                        if(response == 406){
                                            $('.invalid_coupon_message').html(COUPON_EXPIRED_OR_INVALID);
                                            $('.invalid_coupon_message').css({'color':'red','display':'block'});
                                        }else if(response == 405){
                                            $('.invalid_coupon_message').html(COUPON_ALREADY_USED);
                                            $('.invalid_coupon_message').css({'color':'red','display':'block'});
                                        }
                                        else if(response !== 200){
                                            $('.invalid_coupon_message').css({'display':'block'});
                                        }else{
                                            $('#alert_dialog').remove();
                                            $("#transparent_wrapper").css({
                                                "display": "none"
                                            });
                                            if(EXEC_MODE != 'online'){
                                                Platform.updateSubscriptionFromServer();
                                                Platform.startGroupSync();
                                                Client.closeProgressBar()
                                                Platform.clearCache(false);
                                            }else{
                                                window.location.reload();
                                            }
                                            
                                            Platform.showMessage(COUPON_APPLIED);
                                        }
                                        Client.closeProgressBar()
                                    }, 500);
                                    
                                }else{
                                    $('.invalid_coupon_message').html(REDEEM_COUPON_INVALID_MESSAGE);
                                    $('.invalid_coupon_message').css({'color':'red','display':'block'});
                                }
                                e.preventDefault();
                                e.stopPropagation();
                            }else{
                                $('.invalid_coupon_message').html(EMPTY_COUPON_INPUT);
                                $('.invalid_coupon_message').css({'color':'red','display':'block'});
                            }
                            return false;
                        }
                    }
                    
                },
            },
            close: function() {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display":"none"
                });
                $('.ui-widget-overlay').removeClass('custom-overlay');
                $('#redeem_now_btn').removeClass('disable_redeem');
                return false;

            },
            dialogClass: 'dlgfixed',
            position: "center"
        });

        var text = '<div class="alert_dialog_redeem">\n\
                <div id="redeem_link" class="redeem_dialog_description">' + description + '</div>\n\
                \n\<input type="text" id="redeem_input" name="redeem_input" placeholder="Type Here"  required/>\n\
                <div class="invalid_coupon_message"></div>\n\
                </div>';
        
        $('#alert_dialog').html(text);
        // $('.ui-dialog-title').html('Redeem Coupon');
        $('.ui-dialog-title').html(REDEEM_DIALOG_HEADER);

        if ($(window).width() > $(window).height()) {
            $('.alert_dialog').css({
                "max-height": height + 20,
                "overflow-y": "scroll"
            });
        }
        $('input').keydown(function(){
            $('.invalid_coupon_message').html(REDEEM_COUPON_INVALID_MESSAGE);
            $('.invalid_coupon_message').css({'color':'red','display':'none'});
        });

        $(".dlgfixed").center(false);
        $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
        $('#alert_dialog').addClass('alert_dialog');
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('.ui-dialog').addClass('ui-update-dialog');
        $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
        $('#redeem_input').css({'width': '100%','margin-top':'15px','margin-bottom':'15px', 'font-size':'1.2em'});
        // $('#redeem_input::-webkit-input-placeholder').css({'color':'#D8D8D8'});
        // $('.alert_wrapper_dialog_title').css({'background': '#F4F5F9', 'color': '#000', 'width': '100%'});
        $('.alert_wrapper_dialog_title').css({'background': '#fff', 'color': '#000', 'width': '100%', 'border': 'none'});
        // $('.ui-widget-header').css({'border': '1px solid #F4F5F9'});
        $('.ui-dialog-title').css({'color':'#808080'});
        $('.ui-widget-header').css({'border': 'none'});
        $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
            'background': '#fff', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px'});
        $('.ui-button-text:contains("Ok")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
        $('.ui-dialog-buttonset .ui-button').attr("id", 'redeem_now_btn');
        $('#redeem_now_btn').css({'width': '100%','background-color': '#00315C!!important', 'color': '#fff!!important', 'height': '50px', 'margin-right': '6px'});
        $('.redeem_dialog_description a').css({'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500'});
        $('.redeem_dialog_description').css({'color': '#808080'});
        $('#redeem_input').css({'border': '1px solid #F4F5F9'});
        $('.ui-button-text').css({'font-weight': '600'});
        $('.ui-widget-content').css({'background':'#fff'});
        $('.ui-dialog').css({'top': '49%', 'border-radius': '7px'});
        $('.alert_dialog_redeem').css({'padding': '0px 10px', 'font-size':'16px','text-align':'left'});
        $("#transparent_wrapper").css({
            "display": "block",
            height: $(window).height() + "px",
            width: $(window).width() + "px"
        });
        $('#redeem_input').on('input', function () {
            // Reset the flag when the input value changes
            isApiCallMade = false;
            $('.invalid_coupon_message').css({'display': 'none'});
        });
    }else{
        alert(INTERNET_ERROR);
    }
}

function validateInput(inputField) {
    var inputValue = inputField.val().trim();
    var regex = /^[a-zA-Z0-9_ -]*$/; // Regular expression to match alphanumeric, hyphen, and underscore
    if (!regex.test(inputValue)) {
        return false;
    } else {
        return true;
    }
}

function slideContainer(container,direction="left",speed=150){
    if(ENABLE_CONTAINER_ANIMATION){
        $(container).hide().show("slide", { direction: direction }, "swing", speed);
    }
}

function checkIsStringified(str) {
    try {
        JSON.parse(str);
        return true;
    } catch (error) {
        return false;
    }
}

function isJSONString(str) {
    try {
        const parsedResult = JSON.parse(str);
        return typeof parsedResult === 'object';
    } catch (error) {
        return false;
    }
}

function checkUserAgent(){
    if (navigator.userAgent.includes('Android')) {
        return 'android';
    } else if (navigator.userAgent.includes('iPhone')) {
        return 'iphone';
    } else if (navigator.userAgent.includes('iPad')) {
        return 'ipad';
    } else {
        return 'desktop_browser';
    }
}

function videoPlayerResize() {
    var width = $(window).width();
    var height = $(window).height();
    if(CONTROLLER.getVariable("landscape_orientation_clicked") != true
        && CONTROLLER.getVariable("portrait_orientation_clicked") != true
        && CONTROLLER.getVariable("notes_edit_clicked") != true){
        if(width > height){
            videoPlayerLandscapeView();
        }else{
            videoPlayerPortraitView();
        }
    }
}

function isIOS() {
    return [
      'iPad Simulator',
      'iPhone Simulator',
      'iPod Simulator',
      'iPad',
      'iPhone',
      'iPod'
    ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

function cleanUpHTMLTags(html){
    var cleanText = html.replace(/(<([^>]+)>)/ig,"");
    return cleanText;
}

function updateFooterIcons() {
    FOOTER_DATA.forEach(function(footerItem) {
        if ($("." + footerItem.image_class).hasClass("active_tab")) {
            $('.' + footerItem.image_class + ' img').attr('src', footerItem.footer_active_image);
        } else {
            $('.' + footerItem.image_class + ' img').attr('src', footerItem.icon);
        }
    });
}
COURSE_DETAILS = {};
CATEGORY_DETAILS = [];
COURSES_FOR_CATEGORY = [];
CATEGORY_PROGRESS = {};
COURSE_PROGRESS = {};
CATEGORIES_FOR_GROUPS = [];
VIDEOS_WATCHED_COURSE = {};
VIDEOS_WATCHED_CHAPTER = {};
VIDEOS_WATCHED_CATEGORY = {};
VIDEO_VIEWED_COUNT = {};
SD_VIDEOS_WATCHED_COURSE = {};
COURSES_FOR_GROUP = {};
OBJECT_FROM_ID = {};
ALL_CATEGORIES = {};
ALL_CATEGORIES_JSON = {};
ALL_COURSES = {};
QUESTIONS_FOR_QUIZ = {};
OBJECT_USER_GROUPS = [];
OBJECT_USER_GROUPS_JSON = [];
OBJECT_USER_PROFILE = [];
COURSES_MAP = [];
NOTES_LIST = [];
USER_SUBSCRIPTIONS = "";
NOTES_OF_CATEGORY = [];
NOTES_OF_COURSE = [];
QUIZ_RESULT = {};
QUIZ_RESULT_BY_ID = {};
LAST_SHOWN_NOTIFICATION_TIME = 0;
NOTIFICATIONS_COUNT = "";
LOGIN_RESTRICTED_URLS = [];
COURSE_INDEX_MAP = {};
CATEGORY_RESOURCE_COUNT = {};
ENABLE_OFFLINE_FORUM = 'false';
IS_APP = "true";
TIMEOUT = 150;
ACCESS_DENIED = "Access Denied";
OBJECT_USER_ID = '';
OBJECT_USER_NAME = '';
OBJECT_USER_DATA = '';
OBJECT_USER_GROUPS = [];
OBJECT_USER_FIRST_NAME = '';

if (navigator.userAgent.indexOf('tribytehttpmobileclient/android') > -1) {
    APP_TYPE = "android";
    EXEC_MODE = "mobile";
    IS_APP_SHELL = true;
    AUTH_ENABLED = true;
    USE_ZOOM_SDK = true;
} else if (navigator.userAgent.indexOf('tribytehttpmobileclient/ios') > -1) {
    APP_TYPE = "ios";
    EXEC_MODE = "online";
    IS_APP_SHELL = true;
    AUTH_ENABLED = true;
    USE_ZOOM_SDK = true;
} else {
    APP_TYPE = "web";
    EXEC_MODE = "online";
    IS_APP_SHELL = false;
    AUTH_ENABLED = false;
    USE_ZOOM_SDK = false;
}

PROJECT_TITLE = "Learning";
CLIENT_NAME = "Learning";
CLIENT_OBJECT_CATEGORY = "course";
CLIENT_OBJECT_COURSE = "topic";
CLIENT_OBJECT_CHAPTER = "subtopic";
CLIENT_OBJECT_RESOURCE = "content";
OBJECT_CATEGORY = "course";
OBJECT_CATEGORY_TYPE = "category_properties";
OBJECT_CATEGORIES = "courses";
OBJECT_GROUP = "group";
OBJECT_GROUPS = "groups";
GROUP_TITLE = "";
OBJECT_COURSE = "topic";
OBJECT_CHAPTER = "subtopic";
OBJECT_BOOK = "book";
OBJECT_SUPPORT_DOCS = "supportdocs";
OBJECT_QUIZ = "assessment";
OBJECT_VIDEO = "content";
OBJECT_BATCH_SCHEDULE = "batch_schedule";
OBJECT_QUESTION = "question";
OBJECT_CHALLENGE = "challenge";
OBJECT_WEBINAR = 'webinar';
WEBINAR_TITLE = "Webinar";
OBJECT_CHAPTER_TITLE = "Sub Topic";
OBJECT_COURSE_TITLE = "Topic";
OBJECT_CATEGORY_TITLE = "Course";
LANDSCAPE_VIEWS = [
    "Resource",
    "NotesItem",
    "Assignment",
    "Signin",
    "UserFaq",
    "ForgotPassword",
    "Certificatequiz",
    "Practicequiz"
];
CHAPTER_TITLE = "Sub Topic";
OBJECT_VIDEO_WEBINAR = "webinar_record";
OBJECT_TEST_COLLECTION = 'test_collection';
RESOURCE_TYPE_WEBINAR = "Webinar";
RESOURCE_TYPE_TEST_COLLECTION = "Test Collection";
RESOURCE_TYPE_LINKED_OBJECT = "Linkedobject";
ENABLE_NEW_UI = false;
QUIZ_IN_PROGRESS = false;
COURSE_VIEWED = false;
HOMEPAGE_TYPE = "block"; // use "scroll" to get scrolling homepage
MENU_TYPE = "menu"; // use "menu" to get menu options
COURSE_VIEWED = false;
LEADER_BOARD_ENABLED = false;
HISTORY = [];
LOAD_COUNT = 0;
SHOW_HOMEPAGE_BANNERS = true;
CATEGORY_COUNT = 0;
HIDE_NO_DESCRIPTION_INFO = true;
CHECK_USER_PRODUCTS_LENGTH = false;

UPDATE_STATUS = "1";
DOWNLOAD_FILES_UNDER_COURSE_IN_PROGRESS = [];
DOWNLOAD_FILES_UNDER_CATEGORY_IN_PROGRESS = [];
ISAVAILABLE_HTML_TYPE = "html5conversion";
TESTSERIES_TITLE = "Test Series";
TESTCOLLECTION_TITLE = "Test Collection";
TITIE_TEST_SUMMARY = "Test Summary";
EXTERNAL_RESOURCE_ID = 'ExteralResourceId';
TEST_SUMMARY = {};
HOMEPAGEHEADERDATA = [];
TEST_SUMMARY["summary_data"] = [
    {"id": "concept_summary", "title": "Concept Summary", "icon": "/platform/images/score_card.e910e57a.png", "description": "", "onclick": ""},
    {"id": "test_score_card", "title": "Overview Report", "icon": "/platform/images/score_card.e910e57a.png", "description": "", "onclick": ""},
    {"id": "test_comparison_chart", "title": "Comparison chart", "icon": "/platform/images/comparison_chart.4eca86e8.png", "description": "", "onclick": ""},
    {"id": "test_key_answer", "title": "Answer Key", "icon": "/platform/images/question_list.ed03f67e.png", "description": "", "onclick": ""},
    {"id": "test_accuracy", "title": "Accuracy", "icon": "/platform/images/accuracy.ddefcce1.png", "description": "", "onclick": ""},
    {"id": "test_time_management", "title": "Time management", "icon": "/platform/images/time_management.dc9dbbb4.png", "description": "", "onclick": ""},
    {"id": "test_topic_wise", "title": "Tag Wise Report", "icon": "/platform/images/topic_wise.e3c3a6de.png", "description": "", "onclick": ""},
    {"id": "test_level_wise", "title": "Level Wise Report", "icon": "/platform/images/difficult_wise.a5027315.png", "description": "", "onclick": ""},
    //{"id": "test_subject_wise", "title": "Category Wise Report", "icon": "/platform/images/subject_wise.3831b3c6.png", "description": "", "onclick": ""},
    {"id": "test_questions_list", "title": "Question List", "icon": "/platform/images/subject_wise.3831b3c6.png", "description": "", "onclick": ""},
];
DOWNLOAD_STREAM = "mp4";
NOTES_LIST_CATEGORY = "";
SKIP_COURSE_VIEW = "false";
CHECK_COURSE_COUNT = "false";
OBJECT_CATEGORY = "category";
SUBJECT_INFO_UNAVAILABLE = "No Subject Info Available";
GET_VVH_BY_USER_GROUPS = "";
if(!IS_APP_SHELL) {
	HOMEPAGEHEADERDATA['data'] = [
	    {"text": "Change Group", "id":"change_group", "style": "#FAA25C", "icon": "/platform/images/change-class-icon.817d5795.png", "onclick":'CONTROLLER.loadView("ChooseGroup");'},
	    {"text": "Logout", "id":"right-menu-delete-db" ,"style": "#009999", "icon": "/platform/images/logout.34daa7c5.png" ,"onclick":"platformView.prototype.onLogoutClick()"},
	    {"text": "About Us", "id":"about-us", "style": "#fAAF3A", "icon_class": "about_us_icon","onclick":"CONTROLLER.loadView('InfoList')"}
	];
} else {
	HOMEPAGEHEADERDATA['data'] = [
	    {"text": "Change Group", "id":"change_group", "style": "#FAA25C", "icon": "/platform/images/change-class-icon.817d5795.png", "onclick":'CONTROLLER.loadView("ChooseGroup");'},
	    {"text": "Report Issue", "style": "#009999", "icon": "/platform/images/report-issue-icon.56c81df4.png","onclick": "HomePageView.prototype.reportIssue()"},
	    {"text": "Update Content", "id":"update_content","style": "#009999", "icon": "/platform/images/sync.f0106e31.png","onclick": "Platform.updateContent()"},
	    {"text": "Reset App", "id":"right-menu-delete-db" ,"style": "#009999", "icon": "/platform/images/logout.34daa7c5.png" ,"onclick":"resetApplication()"},
	    {"text": "About Us", "style": "#fAAF3A", "icon": "/client/images/about_us.3d5453b4.png","onclick":"CONTROLLER.loadView('InfoList')"}
	];
}
ENABLE_REGISTER = "true";
RES_CHANGE = "false";
VIDEO_SETTINGS = {};
MICROSOFT_OFFICE_PATH = "https://view.officeapps.live.com/op/embed.aspx?src=";
USER_ROLES = {
    "anonymous": 1,
    "authenticated": 2,
    "admin": 6,
    "trainer": 10,
    "student": 12,
    "site-admin": 15,
};
GAME_START_BUFFER_TIME = 10;
ACTIVE_RESOURCE_OBJECT ='';
LEADERBOARD_LIMIT = 5;
OBJECT_CHALLENGE = "challenge";
OBJECT_TASK = "task";
CHAPTERS_TAB_NAME = 'Modules';
FORUM_TAB_NAME = 'Forum';
ASKANEXPERT_QUESTION_LIST_TITLE="Ask an Expert";
ASKANEXPERT_TITLE = "Forum";
TRACK_USER_ACTIONS = "true";
ADMIN = "189";
WEBINAR_STARTED = "started";
WEBINAR_COMPLETED = "completed";
PORTAL_IS_SKIPPED_STATES = {
    '0': 'Answered',
    '1': 'Marked for Review with Answer',
    '2': 'Skipped',
    '3': 'Marked for Review without Answer',
    '4': 'Not Attempted', // Temporary - Used for better readability in app
    '5': 'Grace'
};
IS_SKIPPED_STATES = {
    'Grace' : '5'
}
MY_CHALLENGES = "My Challenges";
CHALLENGE_MODES = {
    "fixed": "game",
    "flexi": "practice_game"
};
CHALLENGE_TYPES = {
    "CAQ": "1", // Complete All Questions
    "FFF": "2", // Fastest Finger First
    "FAG": "3", // Faculty Assisted
};
RESOURCE_TYPE_WEBINAR = "Webinar";
RESOURCE_TYPE_LTI = "Lti";
CALENDER_TITLE = "Calendar";
OBJECT_VIDEO_WEBINAR = "webinar_record";
CONTENT_TAB_NAME = "Content";
EBOOK_TAB_NAME = "Ebook";
QUIZ_TAB_NAME = "Assessment";
DOCS_TAB_NAME = "Support Docs";
TASK_TAB_NAME = "Tasks";
SHOW_HOMEPAGE_LOGO = "false";
FOOTER_VIEWS = [
    "HomePage",
    "AllSubjects",
    "ForumTopics",
    "Calender",
    "Menu"
];
SHOW_SOLUTION_WITH_SHOW_ANSWER = false;
FEEDBACK_OPTIONS = {
    "videofeedbackoptions": {
        "0": {
            "title": "What do you want us to improve?",
            "feedback": {
                "1": "Video quality",
                "2": "Explanation",
                "3": "Language Issue",
                "4": "Lecture Sequence",
                "5": "Topics Covered"
            }
        },
        "1": {
            "title": "What do you like the most?",
            "feedback": {
                "1": "Video quality",
                "2": "Explanation",
                "3": "Language Used",
                "4": "Lecture Sequence",
                "5": "Topics Covered",
                "6": "Teaching Speed"
            }
        }
    },
    "ebookfeedbackoptions": {
        "0": {
            "title": "What do you want us to improve?",
            "feedback": {
                "1": "Factual Error",
                "2": "Other",
            }
        }
    }
};
SHOW_LOGS = false;
TASK_TAB_NAME = 'Tasks';
SUPPORTDOC_TAB_NAME = 'Recommended';
ASSIGNMENT_TAB_NAME = 'Assignment';
ASSESSMENT_TAB_NAME = 'Assessment';
EBOOK_TAB_NAME = 'eBook';
CONTENT_TAB_NAME = 'Content';OBJECT_VIDEO_WEBINAR = "webinar_record";OBJECT_VIDEO_WEBINAR = "webinar_record";
GALLERY_DOWNLOAD_TYPES = ['pptx', 'ppt', 'xls', 'xlsb', 'xlsm', 'xlsx', 'doc', 'docm', 'docx'];
WEBINAR_USER_DETAIL_TYPE = 'username';
ENABLE_LOCALIZATION = "false";
DEFAULT_LANG = "843";
ENABLE_NEW_UI = false;
LOCAL_MATHJAX = "false";
SHOW_SUBSCRIBED_COURSE_IN_FORUM = "false"
TEST_SERIES_TITLE = "Test Series";
LANGUAGE = {};
LANGUAGE_CODE = {
    '11693':"hi",
   "843":"en",
   "11694" : "be"
}
MY_WEBINAR_TITLE = "My Webinars";
RECORDED_WEBINAR_TITLE = "Recorded Webinars";
UPCOMING_WEBINAR_TITLE = "Upcoming Webinars";
UPDATE_DIALOG_MESSAGE_COUNT = 0;
FOOTER_DATA = [
    {"name" : "Home", "method_class" : "footer_home", "icon" : "/platform/images/home.25aab7dc.png", "image_class" : "footer_home_image"},
    {"name" : "Course", "method_class" : "footer_course", "icon": "/platform/images/course_new.985869ca.png", "image_class" : "footer_course_image"},
    {"name" : "Forum", "method_class" : "footer_forum", "icon" : "/platform/images/forum_new.fd6209f2.png", "image_class" : "footer_forum_image"},
    {"name" : "Calendar", "method_class" : "footer_schedule", "icon" : "/platform/images/schedule_new.525377c3.png", "image_class" : "footer_schedule_image"},
    {"name" : "more", "method_class" : "footer_more", "icon" : "/platform/images/more_new.c63e8e97.png", "image_class" : "footer_more_image"},
];
MENU_DATA = [];
MENU_DATA['data'] = [
    {"text": "Change Password", "style": "background-color:#4CAF50", "icon": "/platform/images/change_password.b15992f1.png","onclick":"CONTROLLER.loadView('ChangePassword')"},
    { "text": "Report Issue", "id": "report_issue", "style": "background-color:#35C1B8", "icon": "/platform/images/report-issue-icon.56c81df4.png", "onclick": "HomePageView.prototype.reportIssue()" },
    {"text": "Update Content", "id":"update_content","style": "background-color:#009999", "icon": "/platform/images/sync.f0106e31.png","onclick": "Platform.updateContent()"},
    {"text": "Reset App", "id":"right-menu-delete-db" ,"style": "background-color:#E07870", "icon": "/platform/images/logout.34daa7c5.png" ,"onclick":"resetApplication()"},
    {"text": "About Us", "style": "background-color:#fAAF3A", "icon": "/client/images/about_us.3d5453b4.png","onclick":"CONTROLLER.loadView('InfoList')"}
];
PLAYBACK_RATES = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
EXTERNAL_LOGOUT_URL = "";
GOOGLE_MEET_URL = "https://meet.google.com/";
IS_TEAMS_APP = false;
CATEGORY_DISPLAY_TYPE = "library";
CATEGORY_DISPLAY_TYPE_TITLE = "library"
USER_INFO = 'user_info';
MOBILE_VERIFICATION = false;
ALLOW_MINIMIZE_NUM = 3;
FORGOT_PASSWORD_PLACEHOLDER = "Email";
COURSE_TOPIC_DETAILS = {};
FORGOT_PASSWORD_PLACEHOLDER = "Email";
COURSE_TOPIC_DETAILS = {};
NAVIGATION_MODE_SECTION = 2;
NAVIGATION_MODE_SUBJECT = 1;
REPORT_VIEW_PAGER_PER_VIEW = 3;
$(function(){
    PORTRAIT_BODY_HEIGHT = $(window).height();
})
if(typeof APP_TYPE != "undefined" && APP_TYPE == "android"){
    WINDOW_PIXEL_WIDTH = Platform.getWindowWidth();
    WINDOW_PIXEL_HEIGHT = Platform.getWindowHeight() + 150;
}
USER_CERTIFICATE_URL = "/user/all/certificates";
SINGLE_QUESTION = false;
LOAD_QUIZ_ONLINE = false;
DISABLE_MATHJAX = true;
SYNCED_QUESTION_DATA = false;
REMOVE_CONTENT_TAB = false;
QUIZ_MODULE = 'true';
ENABLE_DOCUMENT_SHARE = true;
CERTIFICATES_TITLE = "My Certificates";
USER_CERTIFICATE_URL = "/user/all/certificates";
BUYNOW_TITLE = "Buy Now";
OPEN_BUYNOW_IN_WEBVIEW = "true";
HIDE_BOOKMARK_IN_PDF = false;
IS_IOS = false;
BLOCK_FREE_VIDEO = false;
CONTENT_LOCALIZATION_ONLY = "false";
LOAD_PORTAL_REGISTRATION = 'false';
CASE_SENSITIVE_EVALUATION_TYPE = "0";
NOT_CASE_SENSITIVE_EVALUATION_TYPE = "1";
MANUAL_EVALUATION_TYPE = "3";
DROPDOWN_EVALUATION_TYPE = "4";
AUTOMATIC_AND_LIST_EVALUATION_TYPE = "5";
RADIO_EVALUATION_TYPE = "6";
NUMERIC_EVALUATION_TYPE = "7";
TEXT_BOX_EVALUATION_TYPE = "8";
SHOW_SOLUTION_IN_TESTSERIES = false;
SHOW_NO_SUBJECTS_MESSAGE = true;
DISPLAY_QUIZ_NAME_IN_TESTSERIES_QUIZ = false;
ENABLE_ADAPTIVE_QUIZ = false;
DISPLAY_QUIZ_NAME_IN_TESTSERIES_QUIZ = false;
CONTENT_PROPERTIES = {};
CATEGORY_PROPERTIES = {};
CATEGORIES_WITH_FLASHCARD = {};
DISPLAY_QUIZ_NAME_IN_TESTSERIES_QUIZ = false;
ENABLE_REMEMBER_ME = "false";
FLASHCARDS_TITLE = "Flashcards";
ENABLE_QUESTION_OF_THE_DAY = false;
ENABLE_RECOMMENDED_VIDEOS = false;
LAST_VIEWED_COURSE = {};
FLASHCARDS_TITLE = "Flashcards";
CLIENT_CUSTOM_NOTIFICATION = false;
OBJECT_VIDEO_DETAILS = "";
OBJECT_NOTES_DETAILS = "";
GROUP_PROPERTIES = {};
CATEGORY_PROPERTIES = {};
COURSE_PROPERTIES = {};
CHAPTER_PROPERTIES = {};
CURRENT_USER_PROPERTIES = {};
CONTENT_PROPERTIES = {};
VIDEO_SETTINGS_PROPERTIES = {};
USER_PROFILE_PROPERTIES = {};
SHOW_USER_ANSWERS = true;
ENABLE_MEDIA_PLAYER = false;
ENABLE_APP_REVIEW = "false";
REQUIRE_CACHE = [];
SEND_GROUPS_TO_GET_SUBSCRIBED_CATEGORIES = false;
POSTER_PATHS = [];
SHOW_SOLUTION_IN_TESTSERIES = false;
RESOURCE_TYPE_FLASHCARD = "flashcard";
OBJECT_FLASHCARD = "flashcard";
GROUP_SPECIFIC_BANNERS = false;
BANNERSINFO = {};
BANNERS_INFO = "banners_json";
ENABLE_FEEDBACK = false;
QUESTION_LEVEL_FEEDBACK = false;
SEND_GROUPS_TO_GET_SUBSCRIBED_CATEGORIES = false;
COMMENTS_LIMIT = 20; //number of comments per page for comments pagination.
AUDIOPATH = '';
LANG='';
RESOURCE_TYPE_ROLEPLAY = 'roleplay';
ENABLE_COC_STATUS = "false";
DOWNLOADING_CONTENT_MESSAGE = "true";
DISPLAY_CHATBOT = "false";
IS_CONDITIONALBREAK_VISIBLE = false;
//Used to get the the course details from getcoursedetails (android api). 
//If there are more topics on the course make it false for optimization.
GET_FROM_COURSE_DETAILS_API = true;
QUESTION_OF_THE_DAY = false;
COURSE_SELECTION_BEFORE_SIGNINVIEW = "false";
ENABLE_USER_DELETE_IN_PROFILE = false;
TOTALCONTENTUNDERCOURSE = [];
RESOURCE_TYPE_DOCUMENT = "Document";
RESOURCE_TYPE_VIDEO = "Video";
ENABLE_SSO_LOGIN = "false";
SHOW_MARK_CONTENT = false;
CONTENT_COMPLETION_DURATION = '90';
ENABLE_SSO_LOGIN = 'false';
LOAD_VIDEO_PLAYER_VIEW = true;
VIDEO_PLAYER_TABS = [
    {"name" : "Index", "id" : "v_p_tab_index", "class" : "v_p_tab v_p_tab_index"},
    {"name" : "Notes", "id" : "v_p_tab_video_notes", "class" : "v_p_tab v_p_tab_video_notes"}
    // {"name" : "Notes", "id" : "v_p_tab_notes", "class" : "v_p_tab v_p_tab_notes"},
    // {"name" : "Assessments", "id" : "v_p_tab_assessment", "class" : "v_p_tab v_p_tab_assessment"},
    // {"name" : "Activity", "id" : "v_p_tab_activity", "class" : "v_p_tab v_p_tab_activity"},
    // {"name" : "Inline HTML", "id" : "v_p_tab_html", "class" : "v_p_tab v_p_tab_html"},
    // {"name" : "Webinar", "id" : "v_p_tab_webinar", "class" : "v_p_tab v_p_tab_webinar"}
]
HIDE_BREAKS = false;
SHOW_BREAKS_IN_PORTRAIT_VIDEO = false;
CATEGORY_PAGE_TITLE = "All Courses";
ENABLE_TESTSUMMARY_FROM_COURSE = 'true';
REPORT_PAGE_NO = 1;
REPORT_PAGE_VIEW_NO = 1;
ENABLE_CONTAINER_ANIMATION = true;
SHOW_CHECK_PLANS = "false";
DR_KONAR_WEBSITE = "https://drkonar.ejaypee.com/drkonarlogin";
DOWNLOADABLE_CONTENTS = "glossary,ilt,Video,Scorm 2004,Assessment,roleplay,Document,flashcard";
RESOURCE_TYPE_VIDEO = "Video";
SHOW_MARK_CONTENT = false;
CONTENT_COMPLETION_DURATION = '90';
ENABLE_SSO_LOGIN = 'false';
IGNORE_CONTENT_IN_POST_CALLS = true;
UPDATE_USERNAME_WHEN_MOB_UPDATE = true;
ADAPTIVE_USER_GOALS = [{"id":"1", "name":"Beginner"}, {"id":"2", "name":"Intermediate"}, {"id":"3", "name":"Master"}, {"id":"4", "name":"Specialist"}];
SHARE_PDF_WITH_WATERMARK = false;
HIDE_NOTES_FROM_COURSEVIEW_MENU = false;
RESOURCE_TYPE_EPUB = "epub";
FLASHCARDS_PAGE = "/apps/flashcard/flashcardlist";
LOAD_HOMEPAGE_BY_DEFAULT = "false";
ENABLE_COURSE_LEVEL_CERTIFICATE = false;
ENABLE_GOOGLE_SIGNIN = false;
COC_VERSION = "";
FORCE_SKIP_LIBRARY_VIEW = true;
RECENT_UPDATES_TITLE = "Recent Updates";
RECENTLY_ADDED_CONTENTS = {};
HIDE_SEARCH_ICON = false;
SCORM_ORIENTATION_LANDSCAPE = 'true';
SPINNERTIMER = "";
BUY_NOW_PAGE_NAME = "Buy Courses"
SHOW_PROFILE_TOP_ALIGNMENT = "false";
SKIP_QUIZ_FOOTER = false;
PROJECT_TITLE = "Salezart";
CLIENT_OBJECT_CATEGORY = "category";
CLIENT_OBJECT_COURSE = "course";
CLIENT_OBJECT_CHAPTER = "topic";
CLIENT_OBJECT_RESOURCE = "content";
CLIENT_OBJECT_VIDEO = "content";
CLIENT_OBJECT_LINKS = "content";
CLIENT_OBJECT_LINKS_FROM = "links";
CLIENT_OBJECT_VIDEO_FROM = "video";
CLIENT_OBJECT_FAQ = "faq";
RESOURCE_PATH = "";
RESOURCE_POSTER_PATH = "";
OBJECT_CLOUDFRONT_URL = Platform.getCloudFrontUrl();
TRANSCODE_PDF = true;
SOLUTION_EMBED = false;
SOLUTION_LANDSCAPE = false;
OBJECT_USER_ID = "";
OBJECT_USER_NAME = "";
GROUP_ID = "";
OBJECT_CATEGORY = "category";
OBJECT_CATEGORY_TYPE = "category_properties";
OBJECT_CATEGORIES = "categories";
OBJECT_GROUP = "group";
OBJECT_GROUPS = "groups";
GROUP_TITLE = "";
OBJECT_COURSE = "course";
OBJECT_CHAPTER = "topic";
OBJECT_BOOK = "book";
OBJECT_SUPPORT_DOCS = "supportdocs";
OBJECT_QUIZ = "assessment";
OBJECT_VIDEO = "content";
OBJECT_BATCH_SCHEDULE = "batch_schedule";
OBJECT_QUESTION = "question";
OBJECT_CHALLENGE = "challenge";
OBJECT_NOTES = "notes";
OBJECT_VIDEO_INDEX = "videoindex";
OBJECT_CHAPTER_TITLE = "Topic";
CHAPTER_TITLE = "Topic";
OBJECT_COURSE_TITLE = "Course";
OBJECT_CATEGORY_TITLE = "Category";
USE_LOCAL = true;
SHOW_TRIAL_POPUP = "true";
RESOURCE_FORMAT = 480;
if(parseFloat(getAndroidVersion()) <= 4.4){
    RESOURCE_ONLINE_FORMAT = "480";
}
else{
    RESOURCE_ONLINE_FORMAT = "240,480,720";
}
RES_CHANGE = "true";
CONTENT_SUBTITLE = "clipping_caption";
DOCUMENT_FORMAT = "native";
OBJECT_PRESET = "video_thumbnail_medium";
DOWNLOAD_EXPIRY = "";
POSTER_IMAGE_NAME = "medium";
DOWNLOAD_CONTENT_THROUGH_SYNC = "false";
DOWNLOAD_IF_NOT_PRESENT_LOCAL = "false";
DOWNLOAD_IF_NOT_PRESENT_LOCAL_WHILE_PLAYING = "false";
CLIENT_NAME = "Salezart";
BOOTSTRAP_IMAGES_SYNC = "false";
BOOTSTRAP_PAGE_URL = "../html/bootstrap.html";
DISABLE_PLAYER_CLICK = 0;
DELETE_DOC_THROUGH_SYNC = "true";
titleTimer = "";
VIDEO_AUTH_ENABLED = true;
ENCRYPTION_ENABLED = "false";
API_VERSION = 5.0;
CLOSE_DIALOG_ON_TAP = true;
COURSE_ID = "";
CHAPTER_ORDER_REQUIRED = true;
FILE_DOWNLOAD_SYNC = true;
CHAPTER_IMAGE_PRESET = "chapter_image_small";
FILE_DOWNLOAD_ASYNC = false;
RESOURCE_PRESET = "video_thumbnail_medium";
SUBJECTS_PAGE_TITLE = "My Categories";
NOTES_TITLE = "Notes";
PASSWORD_ENCRYPT = "true";
APP_MODE = "release";
SCROLL_POSITION = "";
DOWNLOAD_DATABASE = true;
PRACTICE="practice";
CERTIFICATE="certificate";
NETWORK_BOOTSTRAP = false;
ADD_MARGIN_PQ = false;
SUBSCRIBED_CHAPTERS = "";
DOWNLOAD_FILES_IN_PROGRESS = [];
DOWNLOADS_OBJECT_ID = "12345678";
COURSE_INDEX = ""
SD_REGISTRATION_TITLE="SDcard Registration";
OBJECT_SDCARD = "sdcard";
CONTENT_IN_MULTIPLE_SDCARDS = true;
OBJECT_VIDEO_DETAILS = "";
DATABASE_DOWNLOADED = false;
if (EXEC_MODE == "online") {
    USER_PRODUCT_TYPE = "online";
} else {
    USER_PRODUCT_TYPE = "hybrid";
}
CURRENT_PENDING_DATA = "";
DOCUMENT_TRANSCODE = true;
EMAIL_VERIFIED = true;
VIDEO_DEFAULT_FULLSCREEN = true;
FULLSCREENTIMER = "";
FULLSCREENRESIZETIMER = "";
YOUTUBE_PLAYER = false;
PLATFORM_VERSION = 4.0;
RESOURCE_TYPE_LINK = "Link";
RESOURCE_TYPE_QUIZ = "Flash";
RESOURCE_TYPE_HTML = "HTML5";
SWIPE_LEFT = "";
QUIZ_EMBED = false;
ABOUT_US_TITLE="About Us";
FORUM_ENABLE = "true";
OBJECT_SUPPORT_DOCS="supportdocs";
USE_PORTAL_SERVER_DOMAIN = true;
if (EXEC_MODE == "online") {
    /** Use this to check local server domain */
    var clientDomain = "https://online.salezart.com";
    if (window.location.origin.indexOf('localhost') > -1 || window.location.origin.indexOf('192.168.137.1') > -1) {
        OBJECT_SERVER_DOMAIN = clientDomain;
    } else {
        /** For ios builds - Domain needs to be specified */
        if (window.location.origin.indexOf('file://') > -1) {
            OBJECT_SERVER_DOMAIN = "https://online.salezart.com";
        } else {
            OBJECT_SERVER_DOMAIN = window.location.origin;
        }
	}

	PORTAL_SERVER_DOMAIN = OBJECT_SERVER_DOMAIN;
	STATIC_SERVER = 'https://static.' + OBJECT_SERVER_DOMAIN.split("://")[1];
	OLD_STATIC_SERVER = 'https://static.' + OBJECT_SERVER_DOMAIN.split("://")[1];
	OBJECT_VIDEOS_DOMAIN = "videos-learning1-tribytetech-com.s3.amazonaws.com";

	if (OBJECT_SERVER_DOMAIN.indexOf('https') > -1) {
	    IS_HTTPS_BUILD = 'true';
	} else {
	    IS_HTTPS_BUILD = 'false';
    }
} else {
	OBJECT_SERVER_DOMAIN = Platform.getPortalDomain();
	PORTAL_SERVER_DOMAIN = Platform.getPortalDomain();
	STATIC_SERVER = Platform.getStaticServerURL();
	OLD_STATIC_SERVER = 'http://static.online.salezart.com';
	OBJECT_VIDEOS_DOMAIN = "videos-salezart-com.s3.amazonaws.com";
}
if (OBJECT_SERVER_DOMAIN.indexOf("msteams") > -1) {
    IS_TEAMS_APP = true;
}
ASKANEXPERT_QUESTIONS = "forumquestions";
ASKANEXPERT_COMMENT_DATA = "commentdata"
QUESTIONS_WITH_COMMENTS = "forumquestionswithcomments";
ASKANEXPERT_QUESTION_LIST_TITLE="Forum";
FILTER_SUBJECTS="Filter Options";
POST_QUESTION="Post a question";
GROUPS_WITH_CATEGORIES = "groupswithcategories";
HIDE_GROUPS_FILTER = "false";
SUBSCRIBED_COURSE = "false";
EXPERT = "Expert";
REPLY_ENABLE_TO_ALL = "false";
APP_USER_GROUPS = [];
OBJECT_NOTES_CONTENT_LIST = "notes_text_list";
OBJECT_RESOURCE = "resouces";
LOGIN_REQUIRED_FOR_SYNC = false;
CATEGORY_PAGE_TITLE = "All Courses";
REPLY_ENABLE_TO_ALL = "true";
EVALUATION_MODE="";
CHECK_QUIZ_ATTEMPTS = "true";
CHECK_ON_PASS_RATE = 'true';
BLOGS_ENABLE = "false";
TASK_ENABLE = "false";
VIDEOVIEW_HISTORY_VERSION = '1.0';
SHOW_QUIZ = "true";
ALL_TASKS = "tasks";
BLOGS = "blogs";
TABS = "";
ASKANEXPERT_TITLE = "Forum";
BLOGS_WITH_COMMENTS = "blogswithcomments";
RESOURCE_TYPE_ASSESSMENT = "Assessment";
RESOURCE_TYPE_ASSIGNMENT = "Assignment";
SEQUENTIAL_LEARNING_ENABLED = true;
ALL_VIDEOS = {};
VIDEOS_LENGTH = [];
DASHBOARD_ENABLE = "true";
QUIZ_RESOURCE_PARAMS = "";
QUIZ_ENABLED='true';
CHECK_CATEGORY_COUNT = "false";
CHECK_SUBSCRIBED_COURSES = 'false';
CHECK_ON_PASS_RATE = "true";
SET_HEIGHT_FULL = true;
STREAM_TYPE = 'm3u8';
ALL_CHAPTERS = {};
LOCAL_APP_PATH = "app=true&app_path=" + Platform.getLocalServerPath() + "/"
if (OBJECT_SERVER_DOMAIN.indexOf("staging") > -1) {
    BUY_NOW_DOMAIN = "https://staging.online.salezart.com";
} else {
    BUY_NOW_DOMAIN = OBJECT_SERVER_DOMAIN;
}
BUY_NOW_URL = BUY_NOW_DOMAIN + "/user/product/list/?" + LOCAL_APP_PATH;
HIDE_BREAKS = false;
BREAK_MODE = "";
SHOW_QUIZ_BREAK_SUMMARY = 'false';
SAML_ACCESS_DENIED = 'false';
CHECK_VIDEO_STATUS = "true";
GET_COMPLETED_VIDEOS = true;
ASKAN_EXPERT_TAB_ENABLE = 'false';
SHOW_QUIZ_TAB="true";
DASHBOARD_TITLE = "My Progress";
OBJECT_SCORM = "Scorm Resource";
MY_ACTIVITIES_TITLE="My Activities";
MY_WEBINARS_TITLE = "My Webinars";
HOMEPAGEDATA = [];
HOMEPAGEDATA['top_content_mode']="carousel";
HOMEPAGEDATA['data'] = [
    { "text": "My Courses", "id": "homepage_course", "style": "background-color:#0C80C2", "icon": "/platform/images/allcourses.d86955cc.png", "onclick": "CONTROLLER.loadView('AllSubjects')" },
    {"text": "My Score", "style": "background-color:#009999", "icon": "/platform/images/dashboard.b188ebba.png", "onclick":"HomePageView.prototype.renderScoreCard()"},
    {"text": "Brand", "style": "background-color:#18dbd0", "icon": "/platform/images/mydownloads-icon.323a7a07.png", "view": "Downloads", "onclick": "HomePageView.prototype.renderDownloadsView(CATEGORY_TAGID1)"},
    {"text": "Collateral", "style": "background-color:#ff7866", "icon": "/platform/images/messagin_fw_icon.98082a5b.png","onclick": "HomePageView.prototype.renderDownloadsView(CATEGORY_TAGID2)"},
    {"text": "Webinar", "style": "background-color:#00bcd4", "icon": "/platform/images/webinar.da3c47cd.png","onclick":"HomePageView.prototype.renderWebinar()"},
    {"text": "My Challenges", "style": "background-color:#e8ba30 !important", "icon" : "/platform/images/games.a9f72158.png", "onclick" : "HomePageView.prototype.renderChallengeTab()"},
    {"text": "Blogs", "id":"blogs", "style":"background-color:#FA6", "icon": "/platform/images/blogs.cb84a2e8.png","onclick":"HomePageView.prototype.renderMyBlogs()"},
    {"text": "Help", "id": "Help", "style": "background-color:#2d9fcc", "icon": "/platform/images/help-icon-dashboard.2f450a23.png",  "onclick": "HomePageView.prototype.renderHelp()"},
];
HOMEPAGEDATA['carousel_images'] = [];
OFFLINE_BANNERS = [];
MENU_DATA['data'] = [
    { "text": "Report Issue", "id": "report_issue", "style": "background-color:#35C1B8", "icon": "/platform/images/report-issue-icon.56c81df4.png", "onclick": "HomePageView.prototype.reportIssue()" },
    { "text": "Update Content", "id": "menu_update_content", "style": "background-color:#009999", "icon": "/platform/images/sync.f0106e31.png", "onclick": "Platform.updateContent()" },
    {"text": "Clear Cache", "id":"right-menu-clear-cache", "style": "background-color:#00315C","icon": "/platform/images/clear_cache.72517908.png","onclick": "Platform.clearCache()"},
    {"text": "Reset App", "id":"right-menu-delete-db" ,"style": "background-color:#E07870", "icon": "/platform/images/logout.34daa7c5.png" ,"onclick":"resetApplication()"},
    { "text": "Upload Failed Submissions", id:"upload_failed_submit", "style": "background-color:#607D8B", "icon": "/platform/images/upload_image.bab40f4d.png", "onclick": "startQuizResultSync();$('.alert_dialog_title').css('word-break','break-word');" },
    {"text": "About Us", "style": "background-color:#fAAF3A", "icon": "/client/images/about_us.3d5453b4.png","onclick":"CONTROLLER.loadView('InfoList')"}
]
if (typeof APP_TYPE != "undefined" && APP_TYPE == "ios") {
    MENU_DATA['data'].push({"text": "Mail DB", "style": "background-color:#00315C", "icon": "/platform/images/mailDb_icon.png","onclick":"mailDB();"})
}
ENABLE_THEME_SWITCHER = false;
if (ENABLE_THEME_SWITCHER) {
    MENU_DATA['data'].splice(2, 0, { "text": "Change Theme", "style": "background-color:#00ACF1", "icon": "/client/images/change_theme.9ca1c4fa.png", "onclick": "changeTribyteTheme()" });
}
var theme = Platform.getPreference("ENABLE_NEW_UI");
HOMEPAGEHEADERDATA['data'] = MENU_DATA['data'];
if (theme == "false") {
    HOMEPAGEHEADERDATA['data'].unshift({
        "text": "Change Group", "id": "change_group", "style": "#FAA25C", "icon": "/platform/images/change-class-icon.817d5795.png", "onclick": 'CONTROLLER.loadView("ChooseGroup");'
    });
    HOMEPAGEDATA['data'].splice(2, 0, { "text": "Forum", "id": "homepage_forum", "style": "background-color:#5CBAFA", "icon": "/platform/images/forum.c8cf9b59.png", "onclick": "HomePageView.prototype.renderAskanExpertView()" });
    HOMEPAGEDATA['data'].splice(2, 0, { "text": "Calendar", "id": "homepage_calendar", "style": "background-color:#5827BF !important", "icon": "/platform/images/calender.2de4d761.png", "onclick": "HomePageView.prototype.renderCalender()" });
}
if (EXEC_MODE == "online") {
    var removeDataItems = ["homepage_peersync", "homepage_progress", "homepage_blogs"]
    HOMEPAGEDATA['data'] = HOMEPAGEDATA['data'].filter(function (item) {
        return removeDataItems.indexOf(item.id) == -1;
    });
    var removeMenuItems = ["report_issue", "menu_update_content"]
    MENU_DATA['data'] = MENU_DATA['data'].filter(function (item) {
        return removeMenuItems.indexOf(item.id) == -1;
    });
    HOMEPAGEHEADERDATA['data'] = HOMEPAGEHEADERDATA['data'].filter(function (item) {
        return removeMenuItems.indexOf(item.id) == -1;
    });
    for(var item in MENU_DATA['data']) {
        if (MENU_DATA['data'][item]['id'] === "right-menu-delete-db") {
            MENU_DATA['data'][item]['text'] = "Logout";
        }
    }
}
if (IS_TEAMS_APP) {
    var removeTeamsItems = ["homepage_course", "homepage_challenges"]
    HOMEPAGEDATA['data'] = HOMEPAGEDATA['data'].filter(function (item) {
        return removeTeamsItems.indexOf(item.id) == -1;
    });
}
HOME_PAGE_ENABLED="true";
DASHBOARD_TITLE ="My Progress";
MAINTAIN_CATEGORY_IMG_PRESET = "true";
NEW_COURSE_PAGE = "true";
SHOW_VIDEO_AT_COURSE_PAGE = "true";
CHAPTER_CONTENT_HEADER = "Required Readings :";
BLOGS = "blogs";
BLOGS_WITH_COMMENTS = "blogswithcomments";
BLOGS_ENABLE = "true";
ALL_ACTIVITIES='activities';
GENERAL_NOTIFICATIONS_TITLE = 'Notifications';
OBJECT_ASSIGNMENT='assignment';
OBJECT_TASK='task';
USER_BADGE="badge";
TITLE_BADGES="My Badges";
SCORECARD_TITLE="Score Card";
MY_PROFILE_TITLE="My Profile";
CLIENT_OBJECT_FAQ = "faq";
WEBINAR_TITLE = "Webinar";
OBJECT_WEBINAR = 'webinar';
SHOW_GROUP_TAB_IN_HOMEPAGE = "true";
SHOW_ONLY_CONTENT_GROUPS = "true";
QUIZZES_TITLE="Assessments";
CONTENTS_TITLE="Contents";
VIDEO_INDEX_ENABLED = 'true';
SIGNIN_LOGO_PRESENT = 'true';
SIGNIN_BG_CLR = "#FFF";
PLAYBACK_RATES = [0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];
ENABLE_QUIZ_CLEAR_RESPONSE = "true";
SIGNINLANDINGPAGE_ENABLED = "false";
SHOW_ASSIGNMENT_TAB  = 'true';
DOWNLOAD_STREAM = "m3u8";
CALENDER_TITLE = "Schedule";
TASK_ENABLED = "true";
BOOKMARKS_TITLE = "Bookmarks";
ENABLE_REGISTER = "true";
SHOW_UNSUBSCRIBED_RES_MESSAGE = "true";
ALLOW_CATEGORY_DOWNLOAD = "true";
GROUP_SPECIFIC_DATA = "true";
LEADERBOARD_LIMIT = 5;
ENABLE_PEER_CHALLENGE = true;
HTML_RESIZE_ORIENTATION = "true";
COURSE_VIEWED = false;
ENABLE_OFFLINE_FORUM = 'false';
HOMEPAGE_TYPE = "block"; // use "scroll" to get scrolling homepage
MENU_TYPE = "menu"; // use "menu" to get menu options
LEADER_BOARD_ENABLED = false;
HISTORY = [];
LOAD_COUNT = 0;
SHOW_HOMEPAGE_BANNERS = true;
REDIRECTTOPRODUCTLIST = "true";
SIGNIN_BG_CLR = "#FFF";
QUIZ_TAB_NAME = "Assessment";
HIDE_NO_DESCRIPTION_INFO = true;
QUIZ_IN_PROGRESS = false;
ENABLE_NEW_UI = true;
CHAPTERS_TAB_NAME = "";
SHOW_HOMEPAGE_LOGO = "false";
CHAPTER_TITLE = "Topic";
CHAPTERS_TAB_NAME = "Courses";
REPORT_PAGE_NO = 1;
QUIZ_QUESTIONS_PER_PAGE = 10;
REPORT_PAGE_VIEW_NO = 1;
REPORT_VIEW_CHANGED = false;
HIDE_COURSE_TITLE = true;
REPORT_VIEW_CHANGED = false;
ENABLE_TESTSUMMARY_FROM_COURSE = 'true';
DISABLE_VIDEO_STRETCH = "true";
SHOW_INFO_ICON_FOR_CATEGORY = false;
FEEDBACK_OPTIONS = {
    "videofeedbackoptions": {
        "0": {
            "title": "What do you want us to improve?",
            "feedback": {
                "1": "Error",
                "2": "Inadequate Content",
                "3": "Other",
            }
        }
    },
    "ebookfeedbackoptions": {
        "0": {
            "title": "What do you want us to improve?",
            "feedback": {
                "1": "Factual Error",
                "2": "Other",
            }
        }
    },
    "questionfeedbackoptions": {
        "0": {
            "title": "What do you want us to improve?",
            "feedback": {
                "1": "Incorrect Question",
                "2": "Incorrect Answer",
                "3": "Incorrect Explanation",
                "4": "Other",
            }
        }
    }
};
SHOW_LOGS = false;
LOGS_TITLE = 'Ask a Doubt';
ASK_A_DOUBT_MANDATORY_FIELDS = true;
if(OBJECT_SERVER_DOMAIN.indexOf("staging")> -1){
    APP_BANNER_JSON_URL = "https://s3-ap-southeast-1.amazonaws.com/images-staging-online-salezart-com/images.json";
    CATEGORY_TAGID1 = "11053";//Brand/Downloads->staging,
    CATEGORY_TAGID2 = "11052";//Messging FrameWork/Product Collateral ->staging
}
else{
    APP_BANNER_JSON_URL = "https://s3-ap-southeast-1.amazonaws.com/images-salezart-com/images.json";
    CATEGORY_TAGID1 = "11053";//tag name->download  tagid in prod "11053"
    CATEGORY_TAGID2 = "11052";//tag name->messaging framework  tagid in prod "11052"
}
LOAD_OFFLINE_BANNERS = false;
FOOTER_DATA = [
    {"name" : "Home", "method_class" : "footer_home", "icon" : "/platform/images/home.25aab7dc.png", "image_class" : "footer_home_image"},
    {"name" : "My Courses", "method_class" : "footer_course footer_study", "icon": "/platform/images/course_new.985869ca.png", "image_class" : "footer_course_image"},
    {"name" : "Forum", "method_class" : "footer_forum", "icon" : "/platform/images/forum_new.fd6209f2.png", "image_class" : "footer_forum_image"},
    {"name" : "Schedule", "method_class" : "footer_schedule", "icon" : "/platform/images/schedule_new.525377c3.png", "image_class" : "footer_schedule_image"},
    {"name" : "More", "method_class" : "footer_more", "icon" : "/platform/images/more_new.c63e8e97.png", "image_class" : "footer_more_image"},
]
ENABLE_FORGOT_PASSWORD = 'true';
LANG_META_OBJECTS = [
    { "name": "English", "tid": "843" }, { "name": "हिंदी", "tid": "11693" }, { "name": "বাংলা", "tid": "11694" }, { "name": "ಕನ್ನಡ", "tid": "12592" },{ "name": "తెలుగు", "tid": "12591" }, { "name": "தமிழ்", "tid": "11933" }, { "name": "മലയാളം", "tid": "12593" }
]
LANGUAGE_CODE = {
    [LANG_META_OBJECTS[0]["tid"]]:"en",
    [LANG_META_OBJECTS[1]["tid"]]:"hi",
    [LANG_META_OBJECTS[2]["tid"]]:"bn",
    [LANG_META_OBJECTS[3]["tid"]]:"ka",
    [LANG_META_OBJECTS[4]["tid"]]:"te",
    [LANG_META_OBJECTS[5]["tid"]]:"ta",
    [LANG_META_OBJECTS[6]["tid"]]:"ma"
}
LANG='';
HINDI="";
WELCOME_TEXT = "Welcome to "+PROJECT_TITLE;
ENABLE_LOCALIZATION = "true";
ENABLE_BOOKMARKS = false;
COACH_MARK_LIST = [];
COACH_MARK_LIST_ADDED = "false";
ENABLE_COACHMARKS = false;
ASSIGNMENT_TAB_NAME = "Activity";
RECORDED_WEBINAR_TITLE = "Recorded Webinars";
UPCOMING_WEBINAR_TITLE = "Upcoming Webinars";
TAGGED_CONTENT_LIMIT = 11;
SHOW_LIBRARY_COURSES = false;
BOOKMARKS_PAGE = OBJECT_SERVER_DOMAIN + "/apps/bookmarks/courses";
ENABLE_BOOKMARK_SHARE = false;
USERNAME_OR_EMAIL = "true";
SIGNIN_PLACEHOLDER = "Email";
WEBINAR_USER_DETAIL_TYPE = 'firstname_and_username';
VALIDATE_USERNAME = false;
IGNORE_ACTIVE_COURSE_PQ = "true";
HP_HELP_URL = PORTAL_SERVER_DOMAIN + "/video?vid=355294&dialog=true&active_group=";
DYNAMETER_UNIT="POINTS";
TITLE_BADGES="My Badges";
TITLE_SCORE="My Score";
ATTENDANCE_NID="330009";
USER_INFO = ["uid","groups","profile_image"];
SCORECARD_TITLE="My Score ";
SCORECARD_MYSCORE_TITLE="My Score"
CATEGORY_TAGID1_TAGNAME = "Brand";
CATEGORY_TAGID2_TAGNAME = "Product Collateral";
MICROSOFT_OFFICE_PATH = "https://view.officeapps.live.com/op/embed.aspx?src=";
REFRESH_COURSES = "Refresh courses";
COURSE_SUBSCRIPTION = "true";
DOWNLOADS_URL = OBJECT_SERVER_DOMAIN + "/userdownload?dialog=true";
SHOW_ASSIGNMENT_TAB = 'false';
ENABLE_TASK_AT_COURSE_LEVEL = "true";
LOCAL_MATHJAX = "true";    
if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios"){
    ENABLE_REGISTER = "false";
}
if (OBJECT_SERVER_DOMAIN.indexOf("staging") > -1) {
    CLIENT_DOMAIN = "https://staging.online.salezart.com";
} else {
    CLIENT_DOMAIN = OBJECT_SERVER_DOMAIN;
}
LOAD_PORTAL_REGISTRATION = "true";
HIDE_BOOKMARK_IN_PDF = true;
SHOW_UNSUBSCRIBED_CATEGORIES = "false";
if (OBJECT_SERVER_DOMAIN.indexOf("staging") > -1) {
    LOCALIZATION_LANGUAGES = [{"name":"English","tid":"843"},{"name":"हिंदी","tid":"11664"},{"name":"বাংলা","tid":"11665"},{"name":"தமிழ்","tid":"11717"}];
}else{
   LOCALIZATION_LANGUAGES =[ { "name": "English", "tid": "843" }, { "name": "हिंदी", "tid": "11693" }, { "name": "বাংলা", "tid": "11694" }, { "name": "ಕನ್ನಡ", "tid": "12592" },{ "name": "తెలుగు", "tid": "12591" }, { "name": "தமிழ்", "tid": "11933" }, { "name": "മലയാളം", "tid": "12593" }]
}
CONTENT_LOCALIZATION_ONLY = "true";
CALENDER_TITLE = "Calender";
if(typeof APP_TYPE != "undefined" && APP_TYPE == "android"){
    PORTAL_REGISTRATION_URL = CLIENT_DOMAIN + "/register/index.html?redir_path=" + encodeURIComponent(Platform.getAppLocalURL()) + "&app=true&ignorecontent=true";
    FOOTER_DATA.splice(2, 0, {"name" : "Buy Courses", "method_class" : "footer_all_course", "icon": "/platform/images/all_course.83d850cc.png", "image_class" : "footer_all_course_image"});
    HOMEPAGEDATA['data'].splice(1, 0,{"text": "My Progress", "style": "background-color:#2ECC71", "icon": "/platform/images/myprogress.84b64ec5.png", "onclick":"HomePageView.prototype.renderMyProgress()"});
}else if(typeof APP_TYPE != "undefined" && APP_TYPE == "web"){
    PORTAL_REGISTRATION_URL = CLIENT_DOMAIN + "/register/index.html?redir_path=" + encodeURIComponent(OBJECT_SERVER_DOMAIN) + "&ignorecontent=true";
}
if(typeof APP_TYPE != "undefined" && APP_TYPE == "ios"){
    HOMEPAGEDATA['data'].splice(1, 0,{"text": "My Progress", "style": "background-color:#2ECC71", "icon": "/platform/images/myprogress.84b64ec5.png", "onclick":"CONTROLLER.loadView('Dashboard')"});
}
SHOW_NO_SUBJECTS_MESSAGE = true;

ENABLE_DOWNLOAD_RESOLUTION_SWITCH = true;
if (ENABLE_DOWNLOAD_RESOLUTION_SWITCH && APP_TYPE != "ios" ) {
    MENU_DATA['data'].splice(3, 0, { "text": "Download Settings", "id": "download_settings", "style": "background-color:#607D8B", "icon": "/platform/images/video_white.5492e69d.png", "onclick": "select_video_resolution_for_download(true,'', '', '')" });
}
DOWNLOAD_RESOURCE_FORMAT = Platform.getPreference("downlaod_resource_format");
REMOVE_CONTENT_TAB = false;
DOCS_TAB_NAME = "Assets";
PROGRESS_DASHBOARD_PAGE = OBJECT_SERVER_DOMAIN + "/apps/studentdashboard/";
USER_SUBSCRIPTION_ERROR = "You do not have access to this part of the course";
ENABLE_USER_DELETE_IN_PROFILE = true;
IGNORE_CONTENT_IN_POST_CALLS = true;

/* Messages Specific to Client */

CLIENT_OBJECT_CATEGORY_MESSAGE = "There is no content available in the " + CLIENT_OBJECT_CATEGORY+"";
CLIENT_OBJECT_COURSE_MESSAGE  = "There is no content available in the " + CLIENT_OBJECT_COURSE+"";
CLIENT_OBJECT_CHAPTER_MESSAGE  = "There is no content available in the "+CLIENT_OBJECT_CHAPTER+"";
CLIENT_OBJECT_RESOURCE_MESSAGE  = "";
CLIENT_OBJECT_RESOURCE_MESSAGE  = "";
RESOURCEPATH_ONLINE = 'online';
RESOURCEPATH_FILE_DOES_NOT_EXIST_ERROR = 'File does not exist on the sd card';
REOURCEPATH_NO_CONNECTIVITY_ERROR = 'No connectivity. File download is in progress';
RESOURCEPATH_OFFLINE = 'offline';
ENTER_FIRSTNAME = "Please enter first name";
ENTER_USERNAME = "Please enter username.";
VALID_USERNAME = "Please provide a valid username.";
ENTER_ADDRESS = "Please enter address.";
ENTER_MOBILE_NO = "Please enter mobileno.";
ENTER_VALID_MOBILE_NO = "Please provide a valid mobileno.";
ENTER_ALL_FIELDS = "Please enter all the fields";
EMAIL_EMPTY = "Email field cannot be left empty";
SIGNIN_PLACEHOLDER_EMPTY = " field cannot be left empty";
MOB_NUM_EMPTY = "Mobile Number field cannot be left empty.";
VALID_MOB_NUM ="Please provide a valid mobile number.";
PASSWORD_EMPTY = "Password field cannot be left empty";
PASSWORD_DOESNOT_MATCH = "Passwords do not match.";
PASSWORD_LENGTH_ERROR = 'Password should be of minimum 6 characters';
VALID_EMAIL = 'Invalid Email Address';
USER_CREATION_FAILURE = "There is some problem in creating user.Please try later."
USER_LOGIN_FAILURE = "There is some problem while logging in. Please try later."
USER_LOGGED_IN_DIFF_DEVICE ="This device is not registered with you. You have already logged-in in another device. Please log-in from the same device to continue.";
USER_ALREADY_REGISTERED = "The following user already exists. Please signin to continue."
EDIT_USER_FAILURE = "There is some problem in editing the user.Please try later.";
NO_SIM_CARD_AVAILABLE_ERROR = "No sim card present in the phone";
USER_NOT_REGISTERED = 'User is not registered';
LOGIN_FAILURE = "The email and password do not match";
NO_USER_PRODUCTS = "The user has not brought any products";

FORGOT_PASSWORD_ERROR = "There is some problem while changing password. Please try again later";

IMAGE_UPLOAD_FAILED = "There is no network or some issue in upload.Please try later."
IMAGE_ROTATE_FAILED = "There is no network or some issue in rotate.Please try later."

NO_RESOURCE_ERROR = "The Object you are looking for is not present";

QUESTION_POSTED = "Your question has been posted";

GET_USER_GROUPS_FAILURE = "Error while getting user groups";

GET_USER_INFO_FAILURE = "Error while getting user info";

INTERNET_ERROR = "Internet is required.";

if(APP_TYPE == "ios") {
    EXPIRY_ERROR = "You do not have any active course";
} else {
    EXPIRY_ERROR = "Your package has expired. Please contact " + PROJECT_TITLE +" support for further assistance.";
}

INVALID_SYSTEM_DATE_ERROR = "Your tablet/phone date seems to be incorrect. Please adjust your date to use the application or contact " + PROJECT_TITLE +" support for further assistance.";

IMPROPER_SDCARD_STATUS="Please insert valid SdCard";

NO_INTERNET_AND_FILE_NOT_AVAILABLE = "File has not been downloaded";

FILE_NOT_AVAILABLE_AND_DOWNLOAD = "File has not been downloaded. Click on download button to start downloading.";

FILE_DOWNLOAD_IN_PROGRESS = INTERNET_ERROR;

FILE_NOT_AVAILABLE = "File has not been downloaded";

Download_InProgress = "File added for downloading";

ADD_TO_DOWNLOAD_MESSAGE = "added for downloading";

VIDEO_DOWNLOAD_INPROGRESS ="Video added for downloading";

Quiz_Download_InProgress = OBJECT_QUIZ + " added for downloading";

USER_EXIST_CHECK_ERROR = "There is some problem while registering the user. Please try again later.";

USER_LOGOUT_ERROR = "Please Signin to continue";

RESOURCE_ERROR = "There is some problem while performing this operation. \n\
        Please check your internet connection or contact " + PROJECT_TITLE +" support for further assistance.";

UPGRADE_USER_GROUPS_FAILURE = "There is some problem while upgrading the user. \n\
        Please try again later or contact " + PROJECT_TITLE +" support for further assistance.";

SEQUENTIAL_LEARNING_ERROR = "Learning material needs to be completed in a sequence. Please complete all the previous learning materials to access this.";

RECOMMENDED_COURSE_ERROR = "Prerequisite  courses are not completed. Please complete the prerequisites courses to get this enabled.";

ERROR_MESSAGE = "Something went wrong. Please check your internet connection and try again.";
LIMIT_OF_QUESTIONS = "Only two questions can be asked per day.";
USER_SUBSCRIPTION_ERROR = "You are not subscribed to any " + OBJECT_COURSE + "s, please contact " + PROJECT_TITLE +" support for further assistance.";
UNSUBSCRIBED_RESOURCE_MESSAGE = "You are not subscribed to this " + OBJECT_COURSE + ", please contact " + PROJECT_TITLE + " support for further assistance.";
UNSUBSCRIBED_ERROR_MESSAGE = "You don't have the subscription please contact " + PROJECT_TITLE + " support for further assistance.";
SYNC_MSG = OBJECT_COURSE_TITLE +' sync is in progress. Please wait for few minutes.';
CATEGORY_SYNC_MSG = OBJECT_CATEGORY_TITLE +' sync is in progress. Please wait for few minutes.';
if(ENABLE_NEW_UI || EXEC_MODE == "online") {
	NO_COURSE_MSG = "There are no active " + OBJECT_COURSE + "s";	NO_CATEGORY_MSG = "There are no active " + OBJECT_CATEGORIES + ".";
} else {
	NO_COURSE_MSG = "There are no active " + OBJECT_COURSE + "s. If you have subscribed to any " + OBJECT_COURSE + ", please tap on 'Refresh " + OBJECT_COURSE + "s' from the menu.";
	NO_CATEGORY_MSG = "There are no active " + OBJECT_CATEGORIES + ". If you have subscribed to any " + OBJECT_CATEGORY + ", please tap on 'Refresh " + OBJECT_CATEGORIES + "' from the menu.";
}
POST_ACCESS_ERROR = "You don't have access to Ask a Guide. To get regular access, please contact " + PROJECT_TITLE +" support for further assistance.";
QUESTION_POSTED = "Your question has been posted";
QUIZ_SKIP_ERROR = "All questions need to be answered to submit the " + OBJECT_QUIZ + ".";
SKIP_ERROR = "Please answer to continue";
NO_QUESTIONS = "There are no questions under this " + OBJECT_QUIZ + ".";
UNAUTHORIZED_USER = "Unauthorized user, please contact " + PROJECT_TITLE +" support for further assistance.";
QUIZ_ERROR_MESSAGE = "There is some problem while starting the " + OBJECT_QUIZ + ".";
FILE_NOT_AVALABLE_FOR_DOWNLOAD = "This " + OBJECT_VIDEO + " is not available for download.";
DEVICE_LIMIT_ERROR="This coupon code has been used already in another device. Please use a Valid code or contact your product support";
CONNECT_TO_WIFI_REGISTER_EERROR = 'Wifi setting of this device should be enabled at the time of '+CLIENT_NAME+' product registration, Please go to your phone android "Settings" and enable Wifi now';
CONNECT_TO_WIFI_SIGNIN_ERROR = 'Wifi setting of this device should be enabled at the time of '+CLIENT_NAME+' product signin, Please go to your phone android "Settings" and enable Wifi now';
INSTALLATION_ERROR = "Installation cannot be completed. Contact "+CLIENT_NAME+" support with your user details for further assistance";
NO_TESTS_AVAILABLE = "No tests available!";
NO_ACTIVITIES_MESSAGE='No activities available';
QUIZ_ATTEMPTS_EXCEEDED = 'You have crossed the number of attempts, you are not allowed to take the ' + OBJECT_QUIZ + ' now.';
QUIZ_FUTURE_DATE_ERROR_MESSAGE = "This " + OBJECT_QUIZ + " is scheduled for future date. It can be attempted from ";
QUIZ_EXPIRED_DATE_ERROR_MESSAGE = "This " + OBJECT_QUIZ + " you are attempting is closed. It was open till "
QUIZ_START_DATE_ERROR_MESSAGE = 'This ' + OBJECT_QUIZ + ' has not started. Please Wait or Contact the Administrator.';
QUIZ_END_DATE_ERROR_MESSAGE=  'The test has been ended. You cannot attempt the test now.';
Quiz_Download_InProgress = OBJECT_QUIZ + " added for downloading.";
UNAUTHORIZED_DEVICE_ERROR = 'The device is not registered with you. Please contact '+PROJECT_TITLE+' support for further assistance.';
DEVICE_REGISTRATION_FAILED = 'Device registration failed. Please contact '+PROJECT_TITLE+' support for further assistance.';
NETWORK_ERROR = "There seems to be an issue with your Mobile Data Settings. Kindly change your APN Network and try again.";
YOU_HAVE_NO_BADGES_EARNED="You have not earned any badge. Please refer Available Badges for more details.";
YOU_HAVE_NO_BOOKMARKS = "You have no Bookmarks";
QUIZ_THANKYOU_MESSAGE = 'Thanks for taking the ' + OBJECT_QUIZ + '!';
MOBILE_NO_ALREADY_EXIST_ERROR_MESSAGE = "This number already exist, please register with another number or login.";
PROBLEM_STARTING_QUIZ_MSG = 'There is some problem while starting the '+ OBJECT_QUIZ +'. Please contact '+ PROJECT_TITLE +' support for further assistance.';
TEST_COLLECTION_ATTEMPTS_EXCEEDED = 'You have crossed the number of attempts, you are not allowed to take the ' + OBJECT_QUIZ + ' now.';
TEST_COLLECTION_ATTEMPTS_CROSSED = 'You have crossed the number of attempts, you are not allowed to take the ' + OBJECT_QUIZ + ' now.';
TEST_COLLECTION_CERTIFIED = 'You have successfully completed this ' + OBJECT_QUIZ + ', you can not attempt it again';
TEST_COLLECTION_ERROR = "There is some problem while starting the " + OBJECT_QUIZ + ", Please try again later";
TEST_COLLECTION_EMPTY = 'There are no ' + OBJECT_QUIZ + 's added, Please try again later';
FIRST_CONTENT_MSG = "This is the first content of the " + OBJECT_CHAPTER;
FORGOT_PASSWORD_MESSAGE = "An email has been sent with further instructions to your registered email id. Please Signin to continue";
FORGOT_PASSWORD_BUTTON = "E-mail new password";
USER_HAS_BLOCKED_MESSAGE = 'We are sorry, your account is blocked. Please contact '+PROJECT_TITLE+' support for further assistance.';
QUIT_GAME_MSG = "Game is still in progress. Are you sure you want to exit from the game?";
GAME_ALREADY_STARTED_MSG = 'Game has already started! Come back for the next game.';
NO_QUIZ_GAME_CONF = 'No quiz game has been configured.';
QUIZ_GAME_NO_STARTTIME_MSG = 'The configured quiz game does not have a start time. \n Please contact '+ CLIENT_NAME +' support for further assistance.';
QUIZ_ALREADY_STARTED_MSG = "Dear Student, It seems you have attempted this test from some other device and not submitted it successfully. Please login the same device to resume your progress.";
QUIZ_NO_INTERNET_TIME_UP = 'Time Up! The ' + OBJECT_QUIZ + ' was not submitted. Please check your internet connection and try again.';
QUIZ_NO_INTERNET_NOT_SUBMITTED = 'The ' + OBJECT_QUIZ + ' was not submitted. Please check your internet connection and try again.';
INTERNET_TO_SUBMIT_QUIZ = 'Please switch on the internet to submit the ' + OBJECT_QUIZ;
QUIZ_NOT_SUBMITTED = QUIZ_NO_INTERNET_NOT_SUBMITTED;
SHORT_ANSWER_TYPE_RADIO_ERROR_MESSAGE = "Please select answer from both the rows.";
MIN_CHARACTERS = "Answer should contain minimum ";
MAX_CHARACTERS = "Answer can contain maximum of ";
RUN_OUT_OF_TIME_MESSAGE = 'This ' + OBJECT_QUIZ + ' is not available anymore. You have run out of time.';
NO_SDCARD_ERROR = "Please insert SD card and click on Refresh button to continue.";
if (APP_TYPE == "ios") {
    USER_SUBSCRIPTION_ERROR = "You do not have access to this part of the course";
    CATEGORY_SUBSCRIPTION_ERROR = USER_SUBSCRIPTION_ERROR;
    NO_SUBSCRIBED_CATEGORIES_MESSAGE = USER_SUBSCRIPTION_ERROR;
    QUIZ_SUBSCRIPTION_ERROR = USER_SUBSCRIPTION_ERROR;
    UNSUBSCRIBED_RESOURCE_MESSAGE = USER_SUBSCRIPTION_ERROR;
	SUBSCRIPTION_MESSAGE_ASK_A_DOUBT = USER_SUBSCRIPTION_ERROR;
} else {
	USER_SUBSCRIPTION_ERROR = "You are not subscribed to this part of the " + OBJECT_COURSE + ", please contact " + PROJECT_TITLE +" support for further assistance.";
	CATEGORY_SUBSCRIPTION_ERROR = "You are not subscribed to this part of the " + OBJECT_CATEGORY + ", please contact " + PROJECT_TITLE +" support for further assistance.";
	NO_SUBSCRIBED_CATEGORIES_MESSAGE = "You are not subscribed to any " + OBJECT_CATEGORIES + " please contact " + PROJECT_TITLE + " support for further assistance.";
	SDCARD_REGISTRATION_INTERNET_ERROR = "Please ensure that you are connected to internet or contact " + PROJECT_TITLE +" support for further assistance";
	QUIZ_SUBSCRIPTION_ERROR = "You are not subscribed to this " + OBJECT_QUIZ + ", please contact " + PROJECT_TITLE + " support for further assistance.";
    UNSUBSCRIBED_RESOURCE_MESSAGE = "You are not subscribed this part of the " + OBJECT_COURSE + "s, please contact " + PROJECT_TITLE +" support for further assistance.";
EXPIRY_ERROR = "Your package has expired. Please contact " + PROJECT_TITLE +" support for further assistance.";
	SUBSCRIPTION_MESSAGE_ASK_A_DOUBT = "You don't have access to this " +  OBJECT_CATEGORY  + ". To " + LOGS_TITLE + ", please contact " + PROJECT_TITLE +" support for further assistance.";
}
SDCARD_REGISTRATION_INTERNET_ERROR = "Please ensure that you are connected to internet or contact " + PROJECT_TITLE +" support for further assistance";
LEADERBOARD_NO_USERS = "No reports to display";
NO_WEBINARS_MESSAGE = "No " + WEBINAR_TITLE + " to display";
QUIZ_END_DATE_ERROR_MESSAGE = OBJECT_QUIZ + ' cannot be attempted. please contact site administrator.';
CONTENT_NOT_SUPPORTED = OBJECT_VIDEO + " not supported";
EMAIL_VERIFIED_SUCCESSFULLY='Your registration information has been sent to '+ PROJECT_TITLE+' successfully. In order to complete your registration, please verify the email ID provided, by clicking on the confirmation link in the email that we have sent to you.';
EMAIL_VERIFY_ERROR_MSG ='Email is not verified.Verify your email to login.';
WEBINAR_VIDEO_TRANSCODING = "Note : The recorded video of the session is usually available 12 hours after the completion of the " + WEBINAR_TITLE + ". If 12 hours have elapsed and you are still not able to view the video, please contact the administrator.";
INVALID_GROUP_SELECTION_ERROR = 'Please contact '+PROJECT_TITLE+' support for further assistance.';
SURVEY_QUIZ_MESSAGE = 'You have successfully completed the';
EMAIL_VERIFY_ERROR_MSG = 'Email is not verified.Verify your email to login.';
LOGOUT_FAILURE = ERROR_MESSAGE;
SUBMIT_EVAL_ATTEMPT_MSG = "You have already completed this test. Click on the 'Ok' button to submit.";
WEBINAR_ACCOUNT_PROBLEM = "Not able to start the " + WEBINAR_TITLE + ", due to a problem in the " + WEBINAR_TITLE + " Account.";
WEBINAR_RESTART_MSG = WEBINAR_TITLE + " has already been completed. Do you want to start another session of this " + WEBINAR_TITLE + "?";
QUIZ_TIMER_MISSMATCH = "We noticed a time difference of more than a minute. Please start the quiz again to continue.";
OTP_DESCRIPTION = "Note : An OTP has been sent to your registered mobile number:";
RESEND_OTP_DESCRIPTION = "An OTP has been re-sent to your registered email id: ";
OTP_SENT_MESSAGE = "A new OTP has been sent to your mobile number.";
MOBILE_NO_NOT_VERIFIED_MESSAGE = 'Your mobile no is not verified';
USER_HAS_MORE_THEN_ONE_EMAIL_ID_MESSAGE = 'It seems the Email ID linked with your account is not correct or linked with some other account. Please contact '+PROJECT_TITLE+' Support team for more details.';
USER_HAS_BLOCKED_MESSAGE = 'We are sorry, your account is blocked. Please contact '+PROJECT_TITLE+' support for further assistance.';
EMAIL_ID_DOES_NOT_EXIST_MESSAGE = 'Login details that you have entered does not exist, please enter correct details or Signup.';
INVALID_MOBILE_NUMBER_MESSAGE = 'Your registered mobile number is invalid Please update your mobile number.';
MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE = 'It seems the mobile number linked with your account is linked with some other account as well, please contact '+PROJECT_TITLE+' support team for more details.';
MOBILE_NO_ALREADY_EXIST = 'This mobile number already exists, Please try with another number';
OTP_EXPIRED_MESSAGE = 'OTP has been expired';
OTP_DOES_NOT_MATCH_MESSAGE = 'The OTP you have entered is not correct, please re enter or click on Resend OTP.';
MOBILE_NO_ALREADY_EXIST = 'This mobile number already exists, Please try with another number';
INCORRECT_OTP = "Please enter correct OTP.";
OTP_VERIFIED = "OTP verified successfully!";
ASK_A_DOUBT_QUESTIONS_LIMIT = "You have exceeded the limit of questions per day";
WRONG_CURRENT_PASSWORD = "The entered current password is incorrect.";
CHANGE_PASSWORD_SUCCESS = "Password changed successfully";
CONFIRM_PASSWORD_MISMATCH = "The new password and confirm password do not match."
RESOURCE_NOT_ALLOWED = "Learning material can only be accessed on mobile app. Kindly download from Google Play Store and Apple App Store (Coming Soon).";
WEBINAR_COMPLETED_STOPPED = WEBINAR_TITLE + " has completed or been stopped. \n";
YOU_HAVE_NO_AVAILABLE_BADGES = "There are no badges available to be earned.";
FILE_NOT_SUPPORTED = "File not Supported";
WELCOME_TEXT = "Welcome to "+PROJECT_TITLE;
WEBINAR_SCHEDULED_FOR = WEBINAR_TITLE + ' is scheduled for ';
WELCOME_TEXT = "Welcome to "+PROJECT_TITLE;
DO_YOU_WANT_REDOWNLOAD_FILE = "Are you sure, you want to re-download this file?";
NO_COURSE_INFO = "No " + OBJECT_CATEGORY  + " info available";
WEBINAR_SCHEDULED_FOR = WEBINAR_TITLE + " is scheduled at ";
ACCESS_TO_ACTIVITY_DENIED = 'Access to '+ OBJECT_ASSIGNMENT +' is denied. '+ OBJECT_ASSIGNMENT.capitalizeFirstLetter() +' end date was ';
ENTER_ALL_QUESTIONS = "Please fill the option before submitting";
ENTER_CORRECT_ANSWER_PROMPT = "Please enter the correct answer";
NO_ITEMS_IN_LINKED_OBJECT = "No items available for you.";
NO_SCHOLARSHIP_TEST_MESSAGE = "No Scholarship "+OBJECT_QUIZ+" is available. Contact " + PROJECT_TITLE + " support for further assistance.";
OTP_DESCRIPTION = "Please enter the OTP that you have received on your registered email id ";
INCORRECT_OTP = "Please enter correct OTP.";
OTP_INVALID_MESSAGE = "Invalid OTP entered.";
ENTER_PHONE_NUMBER = "Enter New Mobile Number: ";
PNUMBER_INVALID_MESSAGE = "Invalid Mobile Number";
MOBILE_NO_NOT_VERIFIED_MESSAGE = 'Your mobile no is not verified';
USER_HAS_MORE_THEN_ONE_EMAIL_ID_MESSAGE = 'It seems the Email ID linked with your account is not correct or linked with some other account. Please contact '+PROJECT_TITLE+' Support team for more details.';
USER_HAS_BLOCKED_MESSAGE = 'We are sorry, your account is blocked. Please contact '+PROJECT_TITLE+' support for further assistance.';
EMAIL_ID_DOES_NOT_EXIST_MESSAGE = 'Login details that you have entered does not exist, please enter correct details or Signup.';
INVALID_MOBILE_NUMBER_MESSAGE = 'Your registered mobile number is invalid Please update your mobile number.';
MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE = 'It seems the mobile number linked with your account is linked with some other account as well, please contact '+PROJECT_TITLE+' support team for more details.';
MOBILE_NO_ALREADY_EXIST = 'This mobile number already exists, Please try with another number';
OTP_EXPIRED_MESSAGE = 'OTP has been expired';
OTP_DOES_NOT_MATCH_MESSAGE = 'The OTP you have entered is not correct, Please re enter or click on Resend OTP.';
MOBILE_NO_ALREADY_EXIST = 'This mobile number already exists, Please try with another number';
MOBILE_NO_ASSOCIATED_WITH_MULTIPLE_ACC_MESSAGE = 'It seems the mobile number linked with your account is linked with some other account as well, please contact '+PROJECT_TITLE+' support team for more details.';
OTP_VERIFIED = "OTP verified successfully!";
OTP_SENT_MESSAGE = "OTP has been resent successfully.";
EMAIL_INVALID_MESSAGE = "Invalid Email id";
ENTER_EMAIL_ID = "Update Email ID:";
EMAIL_INVALID_MESSAGE = "Please enter valid Email ID";
USER_EXISTS = "Email ID already exist!!";
OTP_INVALID_MESSAGE = "Incorrect OTP entered. Please try again.";
USER_DOES_NOT_EXIST = "User does not exist";
NO_REPORTS_AVAILABLE = "Reports not enabled for this quiz.";
MULTIPLE_CATEGORIES_SEQUENTIAL_MESSAGE = "There are multiple "+OBJECT_CATEGORIES+" which should be completed before starting this "+OBJECT_CATEGORY;
QUIZ_RESULT_ID_UNAVAILABLE = "Loading test failed [Error code : 0001]. Please click Ok to retry";
FILE_NOT_SUPPORTED = "Uploaded file type is not supported";
QUIZ_RESULT_ID_UNAVAILABLE = "Loading test failed [Error code : 0001]. Please click Ok to retry";

QUIZ_END_MESSAGE = "You have successfully completed the Test";
QUIZ_ALREADY_ATTEMPTED_MESSAGE = "You have already taken this test. You may not take it again.";
AUTOMATIC_SUBMIT_MSG = "Your result is being submitted. You will be redirected to the results page.";
QUIZ_END_BUTTON_TEXT = "Back";
SCH_TEST_ALREADY_TAKEN = "You have already taken the Scholarship test";
THANK_YOU_FOR_TAKING_SCH_TEST = "Thank You for taking the Scholarship test";
QUIZ_LOG_SUBMIT_FAILURE = "We were not able to submit your attempt log. Please check your internet connection and try again";
NO_CONTENT_ALERT = "There is no " +OBJECT_COURSE+  " inside this " +OBJECT_CATEGORY;
QUIZ_EVAL_INPROGRESS = "Quiz evaluation in progress. <br/> Results will be announced after evaluation.",
ADAPTIVE_QUIZ_DELETE_SUCCESS = "Test deleted successfully";
ADAPTIVE_QUIZ_DELETE_UNSUCCESS = "Test deletion failed";
QUIZ_EVAL_INPROGRESS = "Quiz evaluation in progress. <br/> Results will be announced after evaluation."
FLASHCARDS_SUBSCRIPTION_ERROR = "You do not have subscription to view " + FLASHCARDS_TITLE;
CONFIRM_QUIZ_ATTEMPT_DELETE = "You took the quiz on a different device earlier. Now you're retaking it on another device. Would you like to proceed with this attempt and erase the previous one, starting the quiz from the beginning?";
CONFIRM_QUIZ_ATTEMPT_DELETE_WITH_TIME = "You took the quiz on a different device earlier. Now you're taking it on another device. Would you like to proceed with this attempt and erase the previous one, starting the quiz from the beginning?  <br> Since this quiz does not allow the user to resume, deleting this attempt will leave you with only the remaining time for you to start and finish the quiz.";
INTERNET_REQUIRED_FOR_REPORTS = "Internet is required to view the reports.";
UPLOAD_LIMIT_EXCEEDED = "The selected file exceeds the maximum allowed size. Please choose a file smaller than 20 MB." 
FORUM_GENERAL_PLACEHOLDER = "Type your question here...";
FORUM_ACTIVITY_PLACEHOLDER = "Post your submission...";
FORUM_ACTIVITY_TITLE = "Activity Forum";
FORUM_ACTIVITY_QUESTION_TITLE = "Post your submission";
MYACTIVITIES_END_DATE_MESSAGE='Access to '+OBJECT_TASK+' is denied.'+ OBJECT_TASK +' end date was';
EMPTY_NOTES = "Please enter some notes";
CHATBOT_COURSE_NOT_SUBSCRIBED_ERROR = "You are not subscribed to this " + OBJECT_CATEGORY + ", please contact " + PROJECT_TITLE + " support for further assistance.";
MULTIPLE_ANSWERS_SELECTED_TEXT = "Multiple Answers Selected";
CHOOSE_ANSWER_TEXT = "Choose the Answer";
PENDING_RESULT_SYNC = "No pending results to sync";
MOBILE_EXISTS = "Mobile Number already exists";
RESEND_MOBILENUMBER_OTP_DESCRIPTION = "An OTP has been re-sent to your registered mobile number: ";
ENTER_OTP_MESSAGE = "Please enter the OTP";
TIME_UP = "Time up";
OTP_DESCRIPTION = "Please enter the OTP that you have received on your registered mobile number ";
Flashcard_Download_InProgress = OBJECT_FLASHCARD + " added for downloading";
MANDATORY_RESOURCES_COMPLETION = "The completion of the learning resources is necessary to proceed with this segment of the course.";
GOOGLE_LOGIN_UNSUCCESS = "Login unsuccessful using Google account."
EMAIL_OTP_DESCRIPTION = 'Please enter the OTP that you have received on your registered email id '
MOBILE_OTP_DESCRIPTION = "Please enter the OTP that you have received on your registered mobile number "
/* Messages Specific to Client */

CLIENT_OBJECT_CATEGORY_MESSAGE = "There is no content available in the " + CLIENT_OBJECT_CATEGORY+"";
CLIENT_OBJECT_COURSE_MESSAGE  = "There is no content available in the " + CLIENT_OBJECT_COURSE+"";
CLIENT_OBJECT_CHAPTER_MESSAGE  = "There is no content available in the "+CLIENT_OBJECT_CHAPTER+"";
CLIENT_OBJECT_RESOURCE_MESSAGE  = "";
CLIENT_OBJECT_RESOURCE_MESSAGE  = "";
RESOURCEPATH_ONLINE = 'online';
RESOURCEPATH_FILE_DOES_NOT_EXIST_ERROR = 'File does not exist on the sd card';
REOURCEPATH_NO_CONNECTIVITY_ERROR = 'No connectivity. File download is in progress';
RESOURCEPATH_OFFLINE = 'offline';
DOWNLOAD_NOTIFICATION_MESSAGE = "";
ENTER_USERNAME = "Please enter username.";
VALID_USERNAME = "Please provide a valid username.";
ENTER_ADDRESS = "Please enter address.";
ENTER_MOBILE_NO = "Please enter mobileno.";
ENTER_VALID_MOBILE_NO = "Please provide a valid mobileno.";
ENTER_ALL_FIELDS = "Please enter all the fields";
EMAIL_EMPTY = "Email field cannot be left empty";
MOB_NUM_EMPTY = "Mobile Number field cannot be left empty.";
VALID_MOB_NUM ="Please provide a valid mobile number.";
PASSWORD_EMPTY = "Password field cannot be left empty";
PASSWORD_LENGTH_ERROR = 'Password should be of minimum 6 characters';
VALID_EMAIL = 'Invalid Email Address';
USER_CREATION_FAILURE = "There is some problem in creating user.Please try later."
USER_LOGIN_FAILURE = "There is some problem while logging in. Please try later."
USER_LOGGED_IN_DIFF_DEVICE ="This device is not registered with you. You have already logged-in in another device. Please log-in from the same device to continue.";
USER_ALREADY_REGISTERED = "The following user already exists. Please signin to continue."
EDIT_USER_FAILURE = "There is some problem in editing the user.Please try later.";
NO_SIM_CARD_AVAILABLE_ERROR = "No sim card present in the phone";
USER_NOT_REGISTERED = 'User is not registered';
LOGIN_FAILURE = "The email and password do not match";
NO_USER_PRODUCTS = "The user has not brought any products";

FORGOT_PASSWORD_ERROR = "There is some problem while changing password. Please try again later";

IMAGE_UPLOAD_FAILED = "There is no network or some issue in upload.Please try later."
IMAGE_ROTATE_FAILED = "There is no network or some issue in rotate.Please try later."

NO_RESOURCE_ERROR = "The Object you are looking for is not present";

QUESTION_POSTED = "Your question has been posted";

GET_USER_GROUPS_FAILURE = "Error while getting user groups";

GET_USER_INFO_FAILURE = "Error while getting user info";

INTERNET_ERROR = "Internet is required.";

EXPIRY_ERROR = "Your package has expired. Please contact " + PROJECT_TITLE +" support for further assistance.";

INVALID_SYSTEM_DATE_ERROR = "Your tablet/phone date seems to be incorrect. Please adjust your date to use the application or contact " + PROJECT_TITLE +" support for further assistance.";

IMPROPER_SDCARD_STATUS="Please insert valid SdCard";

NO_INTERNET_AND_FILE_NOT_AVAILABLE = "File has not been downloaded"; 

FILE_NOT_AVAILABLE_AND_DOWNLOAD = "File has not been downloaded. Click on the button below to start downloading.";

FILE_DOWNLOAD_IN_PROGRESS = "File download is in progress."; 

FILE_NOT_AVAILABLE = "File has not been downloaded"; 

Download_InProgress = "File added for downloading";

ADD_TO_DOWNLOAD_MESSAGE = "added for downloading";

VIDEO_DOWNLOAD_INPROGRESS ="Video added for downloading";

Quiz_Download_InProgress = OBJECT_QUIZ + " added for downloading";



USER_EXIST_CHECK_ERROR = "There is some problem while registering the user. Please try again later.";

USER_LOGOUT_ERROR = "Please Signin to continue";

RESOURCE_ERROR = "There is some problem while performing this operation. \n\
        Please check your internet connection or contact " + PROJECT_TITLE +" support for further assistance.";

UPGRADE_USER_GROUPS_FAILURE = "There is some problem while upgrading the user. \n\
        Please try again later or contact " + PROJECT_TITLE +" support for further assistance.";

ERROR_MESSAGE = "Something went wrong. Please check your internet connection and try again.";
LIMIT_OF_QUESTIONS = "Only two questions can be asked per day.";
SEQUENTIAL_LEARNING_ERROR = "It is not yet time for you to view this section. <br/>OR<br/>You have earlier steps to be completed in sequence before you can view this. <br/>Please standby until it is time to view this section if all earlier steps in sequence have been completed by you.";
USER_SUBSCRIPTION_ERROR = "You are not subscribed to any " + OBJECT_COURSE + "s, please contact " + PROJECT_TITLE +" support for further assistance.";
POST_ACCESS_ERROR = "You don't have access to Ask a Guide. To get regular access, please contact " + PROJECT_TITLE +" support for further assistance.";
QUIZ_SKIP_ERROR = "All questions need to be answered to submit the " + OBJECT_QUIZ + ".";
SKIP_ERROR = "Please answer to continue";
UNAUTHORIZED_USER = "Unauthorized user, please contact " + PROJECT_TITLE +" support for further assistance.";
QUIZ_ERROR_MESSAGE = "There is some problem while starting the " + OBJECT_QUIZ + ".";
FILE_NOT_AVALABLE_FOR_DOWNLOAD = "This " + OBJECT_VIDEO + " is not available for download.";
MIN_CHARACTERS ="Answer should contain minimum ";
MAX_CHARACTERS ="Answer can contain maximum of ";
NO_ACTIVITIES_MESSAGE='No activities available';
MYACTIVITIES_END_DATE_MESSAGE='Access to '+OBJECT_TASK+' is denied.'+ OBJECT_TASK +' end date was';
QUIZ_END_DATE_ERROR_MESSAGE= OBJECT_QUIZ+' cannot be attempted. please contact site administrator.';
Quiz_Download_InProgress = OBJECT_QUIZ + " added for downloading.";
YOU_HAVE_NO_BADGES_EARNED="You have no Badges earned";
WEBINAR_SCHEDULED_FOR = WEBINAR_TITLE + " is scheduled at ";
USER_SUBSCRIPTION_ERROR = "You do not have access to this part of the course";
function changeTribyteTheme() {
    var theme = Platform.getPreference("ENABLE_NEW_UI");
    if (theme != "false") {
        Platform.setPreference("ENABLE_NEW_UI", "false");
    } else {
        Platform.setPreference("ENABLE_NEW_UI", "true");
    }
    window.location.reload();
}

function loadTheme() {
    var theme = Platform.getPreference("ENABLE_NEW_UI");
    if (theme != "false") {
        ENABLE_NEW_UI = true;
        var styles = "platform/css/switch_theme.min.css";
        if (APP_TYPE == "web") {
            styles = "/platform/css/switch_theme.min.css";
        }
        var styleTag = document.createElement("link");
        styleTag.setAttribute("rel", "stylesheet");
        styleTag.setAttribute("href", styles);
        document.head.appendChild(styleTag);
    } else {
        ENABLE_NEW_UI = false;
    }
}
var activeLang = Platform.getPreference("lang");
if(typeof activeLang == "undefined" || activeLang == ""){
    Platform.setPreference("lang",DEFAULT_LANG);
}
LANGUAGE = {
"en":{
    "welcome_text": WELCOME_TEXT,
    "Loading":"Loading...",
    "About us" : "About us",
    "English" : "English"
    },
"bn":{
    "Bengali" : "বাংলা"
    },
"ta":{
    "Tamil" : "தமிழ்"
    },
"ka":{
    "Kannada" : "ಕನ್ನಡ"
    },   
"hi":{
    "Hindi" : "हिंदी"
    },
"te":{
    "Telugu" : "తెలుగు"
    },
"ma":{
    "Malayalam" : "മലയാളം"
    }
}

platformView.prototype = new View();
platformView.prototype.constructor = platformView.constructor;
function platformView() {
}
;

platformView.prototype.render = function (params) {
    $("#search_text").blur();
    LANG = LANGUAGE_CODE[Platform.getPreference("lang")];
    this.setDefaultStyles();
    this.setbodystyle();
    this.renderHeader(params);
    if (typeof QUIZ_IN_PROGRESS != "undefined" && !QUIZ_IN_PROGRESS) {
        if (ENABLE_NEW_UI && params != "signinredirect") this.renderFooter();
    }
    $(".unread_notification").addClass("hidden");
    var settings = CONTROLLER.getService("Settings");
    var currentuser = settings.getLastLoggedinUser();
    var prodType = CONTROLLER.getVariable('actualUserProductType');
    if (typeof prodType != 'undefined' && prodType != "") {
        USER_PRODUCT_TYPE = prodType;
        CONTROLLER.setVariable('actualUserProductType', "");
    }
    if (window.location.href.indexOf("index.html") >= 0 && (((typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") || USER_PRODUCT_TYPE == "offline") && (typeof currentuser != 'undefined' && currentuser != ''))) {
        var sdcardcreator = checkSDcardCreator();
        if (sdcardcreator == false) {
            if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
                var sdCardContent = Platform.getDataFromExternalFileSystem("content.toc");
            }
            else {
                sdCardContent = Platform.getDataFromFileSystem("content.toc");
            }
            if (sdCardContent == "") {
                if (CONTROLLER.activeViewName != 'Error') {
                    CONTROLLER.loadView("Error", "nosdcard");
                    return "";
                }
            }
            if (OBJECT_USER_ID != "" && sdCardContent != "" && CONTROLLER.activeViewName != 'Class'
                && CONTROLLER.activeViewName != 'Streams') {
                sdCardContent = $.parseJSON(sdCardContent);
                var sdcardRegistered = this.sdCardReg(sdCardContent.name);
                if (sdcardRegistered === false || sdcardRegistered == "") {
                    if (CONTROLLER.activeViewName != 'SdCardRegister') {
                        CONTROLLER.loadView("SdCardRegister");
                        return "";
                    }
                }
            }
        }
    }

    //this.checkNotifications();

    this.renderContent(params);

    this.checkWindowSize();

    this.setCustomStyles(params);

    this.handleDeviceResize(params);
};

platformView.prototype.setDefaultStyles = function () {
    $(window).off("resize");
    $('body, #wrapper, #header_wrapper, #header, #content_wrapper, #footer_wrapper, #footer_wrapper .footer').css({
        "width": "100vw"
    });
    $('body, #wrapper').css({
        "height": "100%"
    });
};

platformView.prototype.checkWindowSize = function () {
    if (EXEC_MODE == "online") {
        var width = "100%";
        if (LANDSCAPE_VIEWS.indexOf(CONTROLLER.targetViewname) == -1) {
            if ($(window).width() > ($(window).height() + 100) || $(window).width() > 799) {
                if (IS_TEAMS_APP) {
                    width = "85%";
                } else {
                    width = "100%";
                }
            }
        }
        if ($("#windows_wrapper").length == 0) {
            $("#content_wrapper").wrapInner("<div id='windows_wrapper' style='margin: 0 auto;position:relative;'></div>");
        }
        $("#windows_wrapper").css({ "width": width });
    }
}

platformView.prototype.handleDeviceResize = function (params) {
    if (EXEC_MODE == "online") {
        var THIS = this;
        if (LANDSCAPE_VIEWS.indexOf(CONTROLLER.targetViewname) == -1) {
            this.setDefaultStyles();
            $(window).off("resize");
        }
        $(window).on("resize", function () {
            var width = "100%";
            if (LANDSCAPE_VIEWS.indexOf(CONTROLLER.targetViewname) == -1) {
                if ($(window).width() > ($(window).height() + 100) || $(window).width() > 799) {
                    if (IS_TEAMS_APP) {
                        width = "85%";
                    }
                }
            }
            $(".title_text").css("width", $(window).width() - 170);
            $("#windows_wrapper").css({ "width": width });
            if (EXEC_MODE == "online" && APP_TYPE != "ios" && ($(window).width() < $(window).height())) {
                if ($(".break_dialog").length > 0) {
                    closeDialog();
                }
                if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
                    BreakManager.closeBreak();
                    BREAK_MODE == "false";
                }
            }
            THIS.setCustomStyles(params);
        });
    }
};

platformView.prototype.renderHeader = function (params) {
    var user = CONTROLLER.getService("User");
    var platformView = this;
    var menuHideArray = "";
    var menuShowArray = "", headerData = [];
    if (typeof ENABLE_LOCALIZATION != 'undefined' && ENABLE_LOCALIZATION == "true") {
        headerData['lang'] = LANG_META_OBJECTS;
    }
    this.renderTemplate("header.tl", headerData, "#header_wrapper", true);
    var title = this.getTitle(params);
    if (typeof REPORT_ISSUE_ENABLED != 'undefined' && REPORT_ISSUE_ENABLED == "true") {
        $('#right-menu-reportissue').removeClass('hidden');
    }
    if ($('.title_text').hasClass('hidden')) {
        $('.title_text').removeClass('hidden');
        $('.title_image_wrapper').addClass('hidden');
    }
    if (typeof SHOW_GROUP_LOGO != 'undefined' && SHOW_GROUP_LOGO == "true") {
        if (title == COURSE_PAGE_TITLE) {
            this.getGroupImage(title);
        }
    }
    $(".title_text").css("width", $(window).width() - 190);
    $("#search_wrapper").css("margin-right", "16px");
    $("#notif_img,#notifications").hide();
    if (title == CATEGORY_PAGE_TITLE) {
        $("#search_icon").css("right", "50px");
        $("#search_wrapper").css("margin-right", "0px");
        $("#notif_img,#notifications").hide();
        $("#right-menu-notes").css("display", "none");
        $(".title_text").css("margin-left", "10px");
        menuHideArray = ["#back"];
        menuShowArray = ["#menu"];
    }
    else if (title == NOTES_TITLE) {
        menuHideArray = ["#right-menu-icon", "#menu", "#search_wrapper"];
        menuShowArray = ["#back"];
    }
    else {
        menuHideArray = ["#menu"];
        menuShowArray = ["#back"];
        $("#search_wrapper").css("margin-right", "0px");
        if (title != "Search") {
            $("#search_icon").css("right", "50px");
        }
    }
    hideMenuIcons(menuHideArray);
    showMenuIcons(menuShowArray);
    $(".title_text").html(title);
    $('#header_wrapper').css('width', $(window).width());
    $("#right-menu-icon").unbind('click').bind("click", function (e) {
        platformView.onRightMenuClick(e);
    });
    $('body').click(function (e) {
        platformView.onBodyClick(e);
    });
    $("#right-menu-notes").unbind('click').bind("click", function () {
        CONTROLLER.loadView("NotesList");
    });
    $("#right-menu-info").unbind('click').bind("click", function () {
        CONTROLLER.loadView("InfoList");
    });
    $("#right-menu-reset-password").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == true) {
            platformView.onResetPasswordClick();
        }
        else {
            alert(t("INTERNET_ERROR", true));
        }
    });
    $("#right-menu-upgrade-user").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == true) {
            platformView.onUserUpgradeClick();
        }
        else {
            alert(t("INTERNET_ERROR", true));
        }
    });
    $("#right-menu-notifications,#notifications").unbind('click').bind("click", function () {
        CONTROLLER.loadView("Notifications");
    });
    $("#search_icon").click(function () {
        platformView.onSearchClick();
    });


    $("#right-menu-choose-class").click(function () {
        CONTROLLER.loadView("ChooseClass");
    });

    $("#right-menu-blog").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == true) {
            CONTROLLER.loadView("MyBlogs");
        }
        else {
            alert(t("INTERNET_ERROR", true));
        }
    });
    $(document).unbind().on("keypress", "#search_text", function (e) {
        if (e.which == 13) {
            platformView.onTextSearchClick();
        }
    });
    $("#search_text").focusin(function () {
        $("#search_text").addClass('inFocus');
    });
    $("#content_wrapper").css('height', $(window).height() - 56);
    $('#right-menu-dashboard').click(function () {
        CONTROLLER.loadView('Dashboard');
        return false;
    });
    $('#menu img').click(function () {
        if ($('.dashboard_menu').hasClass('hidden')) {
            $('.dashboard_menu').removeClass('hidden');
            $('.dashboard_menu').addClass('activeDasboardMenu');

        } else {
            $('.dashboard_menu').addClass('hidden');
            $('.dashboard_menu').removeClass('activeDasboardMenu');
        }

        $('.activeDasboardMenu').click(function () {
            CONTROLLER.loadView('Dashboard');
            return false;
        });
    });

    $("#right-menu-peersync").unbind('click').bind("click", function () {
        platformView.onPeerSyncClick();
    });

    $("#right-menu-delete-db").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return false;
        }

        var logoutMessage = t("Clearing the data will delete all the downloaded Contents from this App. Click OK to continue.");
        if (EXEC_MODE == "online") {
            logoutMessage = t("Do you want to Logout?");
        }
        var check = confirm(logoutMessage, function () {
            try {
                Client.showProgressBar(t("Loading") + "");
                setTimeout(function () {
                    var user = CONTROLLER.getService("User")
                    var logoutdata = user.logout();
                    if (EXEC_MODE == 'online') {
                        if (logoutdata == LOGOUT_FAILURE) {
                            alert(LOGOUT_FAILURE);
                            Client.closeProgressBar();
                        }
                        return;
                    }
                    if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
                        var socialLogin = Platform.getPreference('socialLogin');
                        var loginSource = Platform.getPreference("loginsource");
                        if(typeof socialLogin != 'undefined' && socialLogin == 'true' && typeof loginSource != 'undefined' && loginSource == 'google'){
                            Client.googleSignOut();
                        }
                    }
                    var dbDeleted = Platform.deleteDatabase();
                    if (dbDeleted == true || dbDeleted == false) {
                        var prevView = CONTROLLER.getVariable("previousView");
                        if (typeof prevView != 'undefined' && prevView == 'Error' && EXEC_MODE == "mobile") {
                            window.location.href = "../html/index.html";
                            Client.closeProgressBar();
                        } else {
                            DATABASE_DOWNLOADED = false;
                            $('#wrapper').remove();
                            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
                                <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                            if (typeof SIGNINLANDINGPAGE_ENABLED != 'undefined' && SIGNINLANDINGPAGE_ENABLED == 'true') {
                                CONTROLLER.loadView("SigninLanding");
                            } else {
                                CONTROLLER.loadView("Signin");
                            }
                            Client.closeProgressBar();
                        }
                    }
                }, 200);
            } catch (e) {
                Client.closeProgressBar();
            }
        });

    });

    $("#right-menu-logout").unbind('click').bind("click", function () {
        platformView.onLogoutClick();
    });

    $("#right-menu-refreshBatch").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == true) {
            Client.showProgressBar(t("Loading") + "");
            setTimeout(function () {
                user.downloadUserGroups("false");
                user.downloadUserProducts("true");
                Platform.updateSubscriptionFromServer();
                Platform.startService();
                Platform.startBackGroundJOB(true);
                CONTROLLER.loadView("AllSubjects");
                Client.closeProgressBar();
            }, 200);
        } else {
            alert(t("INTERNET_ERROR", true));
            return false;
        }
    });

    $('#sync_wrapper,#right-menu-refresh').unbind('click').bind('click', function (e) {
        Platform.startService();
        Platform.startBackGroundJOB(true);
        $('#sync_icon').addClass('sync_rotate');
        $('.syncing').removeClass('hidden');
    });

    $("#right-menu-webinar").unbind('click').bind("click", function () {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return false;
        }
        CONTROLLER.loadView("Webinar");
    });
    if (typeof ABOUT_US_TITLE != 'undefined') {
        $("#right-menu-info").find("#right-menu-info-text").html(ABOUT_US_TITLE);
    }
};

platformView.prototype.renderFooter = function () {
    FOOTER_RENDERED = [];
    FOOTER_RENDERED = FOOTER_DATA;
    this.renderTemplate("footer_menu.tl", { 'footer_data': FOOTER_DATA }, "#footer_wrapper", true);
    $(".footer").css({ "text-align": "center", 'width': $(window).width() });
    $('#footer_wrapper').css('width', $(window).width());
    $('.footer_learn img').attr('src', '/client/images/learn_active.png');
    $('.footer_learn p').css('color', '#26a9e0');
    $('#footer_wrapper').removeClass('hidden');
    var categories_listing_mode = CONTROLLER.getVariable('categories_listing_mode')
    if(typeof categories_listing_mode != "undefined" && categories_listing_mode != "" && categories_listing_mode == 'all_courses'){
        $('.footer_all_courses_image').addClass('active_tab');
        $('.footer_all_courses_view  p').addClass('active_color');
    }
};

platformView.prototype.onLogoutClick = function (forcelogout=false) {
    var THIS = this;
    if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
        trackScreenEventDetails({ 'context': 'logout', 'event_name': 'TB_TAP_LOGOUT' });
    }
    if(forcelogout){
        THIS.triggerLogout();
    }
    else{
        confirm(t("Do you want to Logout?"), function () {
            THIS.triggerLogout();
        });
    }
}

platformView.prototype.triggerLogout = function () {
    var userService = CONTROLLER.getService("User");
    var ssoLogin = Platform.getPreference("ssoLogin");
        Client.showProgressBar(t("Loading") + "...");
        if (typeof trackWebEngageLogOut == "function") {
            trackWebEngageLogOut();
        }
        setTimeout(function () {
            $('body').html('');
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
            Platform.saveObject("currentuser", 1, "content", "");
            if (Platform.getNetworkState() == true) {
            userService.logout();
        }
        if (typeof ssoLogin != 'undefined' && ssoLogin == "true") {
            Client.closeProgressBar();
            window.location.href = CLIENT_LOGOUT_URL;
        } else {
            CONTROLLER.loadView("Signin");
        }
        if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
            var socialLogin = Platform.getPreference('socialLogin');
            var loginSource = Platform.getPreference("loginsource");
            if(typeof socialLogin != 'undefined' && socialLogin == 'true' && typeof loginSource != 'undefined' && loginSource == 'google'){
                Client.googleSignOut();
            }
        }
        if (APP_TYPE == "android" && EXEC_MODE == "mobile") {
            Platform.clearCache();
        }
        return false;
    }, 100);
}

platformView.prototype.onTextSearchClick = function () {
    if ($("#search_text").val().trim() != "") {
        $("#search_text").blur();
        $("#search_text").removeClass("inFocus");
        Client.showProgressBar(t("Loading") + "...");
        setTimeout(function () {
            var param = {
                'searchText': $("#search_text").val().trim()
            };
            if (CONTROLLER.getVariable("recentdownloadsview") == true) {
                CONTROLLER.setVariable("previousView", "RecentDownloads");
                CONTROLLER.loadView("RecentDownloads", param);
            }else if(CONTROLLER.getVariable("tasksearchenabled") == true){
                CONTROLLER.setVariable("tasksearch", param.searchText);
                CONTROLLER.setVariable("previousView", "ListTask");
                CONTROLLER.loadView("ListTask");
            }
            else {
                CONTROLLER.loadView("Search", param);
            }
            Client.closeProgressBar();
        }, 800)
    }
};

platformView.prototype.getSyncStatus = function () {
    if (EXEC_MODE == "online")
        return;
    $('#sync_wrapper').removeClass('hidden');
    this.startSync();
}

platformView.prototype.startSync = function (params) {
    if (EXEC_MODE == "online")
        return;
    var status = Platform.isDataSyncHappening();
    if (status == '1') {
        $('#sync_icon').addClass('sync_rotate');
        $('.syncing').removeClass('hidden');
    } else {
        if ($('#sync_icon').hasClass('sync_rotate')) {
            $('#sync_icon').removeClass('sync_rotate');
            $('.syncing').addClass('hidden');
        }
    }
}

function GetObjectKeyIndex(obj, keyToFind) {
    var i = 0, key;
    for (key in obj) {
        if (key == keyToFind) {
            return i;
        }
        i++;
    }
    return null;
}

platformView.prototype.isVideoViewable = function (groupid, courseid, chapterid, resourceid, catid) {
    try {
        var courseService = CONTROLLER.getService("Course");
        var chapterService = CONTROLLER.getService("Chapter");
        var course = $.parseJSON(Platform.getObjectProperty(OBJECT_COURSE, courseid, "content"));
        var videosViewedLength = "";
        var viewedResource = "";
        var videosLength = 0;
        var videoviewedgroup = "";
        if (typeof RECOMMENDED_COURSES != "undefined" && RECOMMENDED_COURSES == true) {
            var course_index = COURSES_MAP.indexOf(courseid);
            if (course_index > 0 && typeof course.enforce != "undefined" && course.enforce == 1) {
                for (i in course.recommended_courses) {
                    this.getAllResourcesWithChapter(course.recommended_courses[i], groupid);
                    videosViewedLength = courseService.getCompletedVideosOfCourseCount(groupid, course.recommended_courses[i]);
                    videosLength = courseService.getResourcesCount(catid, course.recommended_courses[i], groupid);
                    if (videosViewedLength < videosLength) {
                        var rec_course_index = COURSES_MAP.indexOf(course.recommended_courses[i]);
                        alert(t("RECOMMENDED_COURSE_ERROR", true));
                        COURSE_INDEX = rec_course_index + 1;
                        return false;
                    }
                }
            }
        }
        if (typeof SEQUENTIAL_LEARNING_ENABLED != "undefined" && SEQUENTIAL_LEARNING_ENABLED == true) {
            var chapter = $.parseJSON(Platform.getObjectProperty(OBJECT_CHAPTER, chapterid, "content"));
            this.getAllResourcesWithChapter(courseid, groupid);
            this.checkSeekEnabled(course, chapter);
            if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
                if (VIDEOVIEW_HISTORY_VERSION != undefined && VIDEOVIEW_HISTORY_VERSION == "1.0") {
                    var videosviewedinchapter = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, videoviewedgroup, chapterid, "chapter", OBJECT_VIDEO);
                }
                else {
                    var videosviewedinchapter = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, videoviewedgroup, chapterid, "chapter");
                }
            } else {
                videosviewedinchapter = Platform.getVideoViewHistory(OBJECT_USER_ID, videoviewedgroup, chapterid, "chapter");
            }
            videosviewedinchapter = $.parseJSON(videosviewedinchapter);
            var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
            if (typeof chapter.learning_flow != "undefined") {
                if (typeof category.learning_flow != "undefined" && category.learning_flow == 1) {
                    /*Category level check*/
                    if (typeof ALL_COURSES[catid] == "undefined" || ALL_COURSES[catid] == "") {
                        this.getAllCoursesToRender(catid);
                    }
                    var currentidno = ALL_COURSES[catid].indexOf(courseid);
                    var currentchapteridno = ALL_CHAPTERS[courseid].indexOf(chapterid);
                    if ((currentidno - 1) >= 0) {
                        var previouscourse = ALL_COURSES[catid][currentidno - 1];
                        this.getAllResourcesWithChapter(previouscourse, groupid);
                        var previouschapter = ALL_CHAPTERS[previouscourse];
                        for (var k = 0; k < previouschapter.length; k++) {
                            videosViewedLength = chapterService.getCompletedVideosOfChapterCount(groupid, previouschapter[k]);
                            if (videosViewedLength < ALL_VIDEOS[previouscourse][previouschapter[k]].length) {
                                alert(t("SEQUENTIAL_LEARNING_ERROR", true));
                                return false;
                            }
                        }
                    }
                    if (course.learning_flow == 1 || course.learning_flow == -1) {
                        /*Course level check if category level check is enabled*/
                        this.getAllResourcesWithChapter(courseid, groupid);
                        var status = this.checkCourseLevelSequentialLearning(courseid, chapterid, groupid, videosViewedLength);
                        if (status == false) {
                            return false;
                        }

                    }
                    if (chapter.learning_flow == 1 || chapter.learning_flow == -1) {
                        /*Chapter level check if category level check is enabled*/
                        this.getAllResourcesWithChapter(courseid, groupid);
                        var status = this.checkChapterLevelSequentialLearning(courseid, chapterid, resourceid, viewedResource, videosviewedinchapter);
                        return status;
                    }

                }
                else if ((category.learning_flow == 0 || typeof category.learning_flow == "undefined") && course.learning_flow == 1) {
                    /*Course level check*/
                    this.getAllResourcesWithChapter(courseid, groupid);
                    var status = this.checkCourseLevelSequentialLearning(courseid, chapterid, groupid, videosViewedLength);
                    if (status == false) {
                        return false;
                    }

                    if (chapter.learning_flow == 1 || chapter.learning_flow == -1) {
                        var status = this.checkChapterLevelSequentialLearning(courseid, chapterid, resourceid, viewedResource, videosviewedinchapter);
                        return status;
                    }

                } else if (course.learning_flow == 0 && chapter.learning_flow == 1) {
                    if (chapter.learning_flow == 1 || chapter.learning_flow == -1) {
                        var status = this.checkChapterLevelSequentialLearning(courseid, chapterid, resourceid, viewedResource, videosviewedinchapter);
                        return status;
                    }
                } if (chapter.learning_flow == 1) {
                    var status = this.checkChapterLevelSequentialLearning(courseid, chapterid, resourceid, viewedResource, videosviewedinchapter);
                    return status;
                }
                else {
                    return true;
                }
            }
        }
        else {
            return true;
        }
    }
    catch (e) {
        console.log("function :- isVideoViewable " + e.message);
    }
}

platformView.prototype.checkCategoryLevelSequentialLearning = function (catid, groupid) {
    try {
        var catService = CONTROLLER.getService("Category");
        var catObj = catService.getCategoryFromid(catid);

        // Check if category has tag "enable_course_sequence"
        if (catObj.tags_details && (JSON.stringify(catObj.tags_details).indexOf('enable_course_sequence') > -1)) {

            // Get current category's completion percentage
            var catCompletion = this.getCompletionBySubject(catObj, groupid);

            // If category has tag "enable_course_sequence" and is completed, no need to check for previous ranked course completion
            if (catCompletion["watched"] >= 100) {
                return true;
            }
            else {
                var categories = this.getAllCategoriesToRender(groupid);
                categories = categories[OBJECT_CATEGORY];
                categories = sortByRank(categories);
                var indexArray = categories.map(function (o) {
                    return o.nid;
                });

                //If it is first category, no need to check for sequential learning
                var currentidno = indexArray.indexOf(catid);
                if (currentidno == 0) {
                    return true;
                }

                // Get previous ranked category
                var previousIncompleteCategoryWithRank = "", previousIncompleteCategoryWithRankCount = 0;
                for (var i = currentidno - 1; i >= 0; i--) {
                    if (categories[i].rank != null) {
                        if (previousIncompleteCategoryWithRank == "" || previousIncompleteCategoryWithRank.rank == categories[i].rank) {
                            // Get previous ranked category's completon percentage
                            var catCompletion = this.getCompletionBySubject(categories[i], groupid);

                            // If previous ranked category's completon percentage is 100%, content can be viewed 
                            if (catCompletion["watched"] < 100) {
                                previousIncompleteCategoryWithRankCount++;
                                previousIncompleteCategoryWithRank = categories[i];
                            }
                        }
                    }
                }
                if (previousIncompleteCategoryWithRankCount > 0) {

                    /* If previous ranked category's completon percentage is less than 100%, content cannot be viewed
                    and if there are more than 1 category, then alert multiple categories should be viewed message else 
                    alert the respective category which must be viewed */
                    if (previousIncompleteCategoryWithRankCount > 1) {
                        alert(MULTIPLE_CATEGORIES_SEQUENTIAL_MESSAGE);
                    }
                    else {
                        alert('You need to complete ' + previousIncompleteCategoryWithRank.title + ' ' + OBJECT_CATEGORY + ' to access this ' + OBJECT_CATEGORY + '.');
                    }
                    return false;
                }
                else {
                    return true;
                }
            }
        }
        return true;
    }
    catch (e) {
        console.log("Function : checkCategoryLevelSequentialLearning , message => " + e.message);
    }
}

platformView.prototype.checkChapterLevelSequentialLearning = function (courseid, chapterid, resourceid, viewedResource, videosviewedinchapter) {
    var indexArray = ALL_VIDEOS[courseid][chapterid].map(function (o) {
        return o.nid;
    });
    var currentidno = indexArray.indexOf(resourceid);
    var previousresource = indexArray[currentidno - 1];
    if (previousresource >= 0) {
        if (hasResourceId(videosviewedinchapter["videoviewed"], previousresource) == true) {
            viewedResource = getObjectById(videosviewedinchapter["videoviewed"], previousresource, "resourceid");
            if (viewedResource.status == "completed") {
                return true;
            }
            else {
                alert(t("SEQUENTIAL_LEARNING_ERROR", true));
                return false;
            }
        } else {
            alert(t("SEQUENTIAL_LEARNING_ERROR", true));
            return false;
        }
    }
}
platformView.prototype.checkCourseLevelSequentialLearning = function (courseid, chapterid, groupid, videosViewedLength) {
    var chapterService = CONTROLLER.getService("Chapter");
    var currentidno = ALL_CHAPTERS[courseid].indexOf(chapterid);
    if ((currentidno - 1) >= 0) {
        var keysbyindex = Object.keys(ALL_CHAPTERS[courseid]);
        var previouschapter = ALL_CHAPTERS[courseid][currentidno - 1];
        videosViewedLength = chapterService.getCompletedVideosOfChapterCount(groupid, previouschapter);
        if (videosViewedLength < ALL_VIDEOS[courseid][previouschapter].length) {
            alert(t("SEQUENTIAL_LEARNING_ERROR", true));
            return false;
        }
    }
}
platformView.prototype.categoryLevelDownload = function (type, param1) {
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var courseService = CONTROLLER.getService("Course");
    var categoryService = CONTROLLER.getService("Category");
    if (type == OBJECT_CATEGORY) {
        Platform.addOrUpdateCategoryDownloadStatus(CONTROLLER.getVariable("activegroupid"), param1, UPDATE_STATUS);
        var courses = categoryService.getCoursesForCategory(param1, CONTROLLER.getVariable("activegroupid"));
        for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
            courseService.downloadAllResourcesOfCourse(courses[OBJECT_COURSE][i].nid);
            courseService.downloadAllBooksOfCourse(courses[OBJECT_COURSE][i].nid);
            contentUtils.showDownloadProgressForCategory();
        }
        CONTROLLER.setVariable("catid_addedfor_download", "");
    }
}
platformView.prototype.downloadAllResourcesDialog = function (text, type, param1, param2) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var scrollposition = $(window).scrollTop();

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var courseService = CONTROLLER.getService("Course");
    var categoryService = CONTROLLER.getService("Category");

    if ($(window).width() < 380) {
        width = 310;
        height = 170;
    }
    else if ($(window).width() > 380 && $(window).width() < 400) {
        width = 330;
        height = 180;
    }
    else {
        width = 438;
        height = 200;
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Download All": {
                text: t('Download All'),
                click: function () {
                    closeDialog();
                    Client.showProgressBar(t("Adding files for download. This might take a few minutes. Please Wait..."));
                    setTimeout(function () {
                        if (type == OBJECT_CATEGORY) {
                            if(typeof HIDE_GROUPS_FILTER != "undefined" && HIDE_GROUPS_FILTER == 'true'){
                                var category = JSON.parse(Platform.getObjectProperty(OBJECT_CATEGORY,param1,"content"))
                                var groupid = category.group[0]
                            }else{ 
                                 var groupid = CONTROLLER.getVariable("activegroupid")
                            }
                            Platform.addOrUpdateCategoryDownloadStatus(groupid, param1, UPDATE_STATUS);
                            Platform.addCategoryForDownload(groupid,param1,"0","");
                            contentUtils.showDownloadProgressForCategory();
                            CONTROLLER.setVariable("catid_addedfor_download", "");
                        }
                        else {
                            Platform.addOrUpdateCourseDownloadStatus(param1, UPDATE_STATUS);
                            courseService.downloadAllResourcesOfCourse(param1);
                            courseService.downloadAllBooksOfCourse(param1);
                            contentUtils.showDownloadProgressForCourse();
                        }
                        Client.closeProgressBar();
                    }, 500);
                    return false;

                }
            },

            "Pause All": {
                text: t("Pause All"),
                click: function () {
                    closeDialog();
                    Client.showProgressBar("Pausing all downloads. This might take a few minutes. Please Wait...");
                    setTimeout(function () {
                        if (type == OBJECT_CATEGORY) {
                            Platform.pauseAllDownloads()
                        }
                        else{
                            courseService.pauseAllDownloadsOfCourse(param1);
                        }
                        Client.closeProgressBar();
                    }, 500);
                    return false;

                }
            },
            "Resume All": {
                text: t("Resume All"),
                click: function () {
                    closeDialog();
                    Client.showProgressBar("Resuming all downloads. This might take a few minutes. Please Wait...");
                    setTimeout(function () {
                        if(type == OBJECT_CATEGORY){
                            Platform.resumeAllDownloads()
                        }
                        else{
                            courseService.resumeAllDownloadsOfCourse(param1);
                        }
                        Client.closeProgressBar();
                    }, 500);
                    return false;

                }
            },
            "Re-Download All": {
                text: t("Re-Download All"),
                click: function () {
                    closeDialog();
                    Client.showProgressBar("Adding files for download. This might take a few minutes. Please Wait...");
                    setTimeout(function () {
                        if (type == OBJECT_CATEGORY) {
                            if(typeof HIDE_GROUPS_FILTER != "undefined" && HIDE_GROUPS_FILTER == 'true'){
                                var category = JSON.parse(Platform.getObjectProperty(OBJECT_CATEGORY,param1,"content"))
                                var groupid = category.group[0]
                            }else{ 
                                 var groupid = CONTROLLER.getVariable("activegroupid")
                            }
                            Platform.addOrUpdateCategoryDownloadStatus(groupid, param1, UPDATE_STATUS);
                            Platform.addCategoryForDownload(groupid,param1,UPDATE_STATUS,"");
                            contentUtils.showDownloadProgressForCategory();
                            CONTROLLER.setVariable("catid_addedfor_download", "");
                        }
                        else {
                            Platform.addOrUpdateCourseDownloadStatus(param1, UPDATE_STATUS);
                            courseService.deleteAllDownloadsOfCourse(param1);
                            courseService.downloadAllResourcesOfCourse(param1);
                            courseService.downloadAllBooksOfCourse(param1);
                            contentUtils.showDownloadProgressForCourse();
                        }

                        Client.closeProgressBar();
                    }, 500);
                    return false;

                }
            },
            "Delete All": function() {
                closeDialog();
                Client.showProgressBar("Deleting downloaded files. This might take a few minutes. Please Wait...");
                setTimeout(function(){
                    if(type == OBJECT_CATEGORY){
                        if(typeof HIDE_GROUPS_FILTER != "undefined" && HIDE_GROUPS_FILTER == 'true'){
                            var category = JSON.parse(Platform.getObjectProperty(OBJECT_CATEGORY,param1,"content"))
                            var groupid = category.group[0]
                        }else{ 
                             var groupid = CONTROLLER.getVariable("activegroupid")
                        }
                        Platform.addOrUpdateCategoryDownloadStatus(groupid, param1, "2");
                        Platform.addCategoryForDownload(groupid,param1,"2","");
                        contentUtils.showDownloadProgressForCategory();
                        Client.closeProgressBar();
                        CONTROLLER.setVariable("catid_addedfor_download","");
                        $('#content_status_' + "_"+ param1).html("");
                        if($('#dl_category_'+ param1).hasClass("delete")){
                            $('#dl_category_'+ param1).removeClass("delete")
                        }
                        else if($('#dl_category_'+ param1).hasClass("inprogress")){
                            $('#dl_category_'+ param1).removeClass("inprogress")
                        }
                        $("#content_status_" + param1).text("");
                    }
                },500);
                return false;
            },
            Cancel: {
                text: t('Cancel'),
                click: function () {
                    closeDialog();
                    return false;
                }
            },
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog_redownload"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-dialog-buttonset .ui-button').css({
        "width": "32.8%",
        "padding": "0",
        "font-size": "85%"
    });
    $('html body .ui-dialog').css({
        'z-index': '99999999',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none'
    });
    $('.ui-button-text:contains("Resume All")').parent().css(
        "border", "1px solid #eee!important"
    );
    $('.ui-dialog-buttonset .ui-button').css({
        "width": "32.8%"
    });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

platformView.prototype.getAllResourcesWithChapter = function (courseid, groupid) {
    var chapterSevice = CONTROLLER.getService("Chapter");
    var courseService = CONTROLLER.getService("Course");
    if (HIDE_GROUPS_FILTER == "true") {
        var chapter = courseService.getChaptersForCourse(courseid, "");
    } else {
        var chapter = courseService.getChaptersForCourse(courseid, groupid);
    }
    var videos_length = 0;
    var videos_viewed_length = 0;
    var indexToBeRemoved = [];
    ALL_VIDEOS = [];
    ALL_CHAPTERS = [];
    ALL_VIDEOS[courseid] = [];
    ALL_CHAPTERS[courseid] = new Array();
    var chaptersarray = [];
    for (var i = 0; i < chapter[OBJECT_CHAPTER].length; i++) {
        chaptersarray.push(chapter[OBJECT_CHAPTER][i].nid);
        if (typeof CHECK_CONTENT_BELONGS_TO_USERGROUPS != "undefined" && CHECK_CONTENT_BELONGS_TO_USERGROUPS == "true") {
            var video = this.getResourcesForChapterByGroups(chapter[OBJECT_CHAPTER][i].nid, courseid);
        } else {
            var video = chapterSevice.getResourcesForChapter(chapter[OBJECT_CHAPTER][i].nid, courseid);
        }
        videos_length = videos_length + video[OBJECT_VIDEO].length;
        ALL_VIDEOS[courseid][chapter[OBJECT_CHAPTER][i].nid] = [];
        for (var j = 0; j < video[OBJECT_VIDEO].length; j++) {
            ALL_VIDEOS[courseid][chapter[OBJECT_CHAPTER][i].nid].push(video[OBJECT_VIDEO][j]);
        }

        var total_video = video[OBJECT_VIDEO].length;
        if (total_video == 0) {
            indexToBeRemoved.push(chapter[OBJECT_CHAPTER].indexOf(chapter[OBJECT_CHAPTER][i]));
        }
        else {
            chapter[OBJECT_CHAPTER][i][OBJECT_VIDEO] = video[OBJECT_VIDEO];
        }
    }
    ALL_CHAPTERS[courseid] = chaptersarray;
    if (indexToBeRemoved.length > 0) {
        for (var i = indexToBeRemoved.length - 1; i >= 0; i--) {
            chapter[OBJECT_CHAPTER].splice(indexToBeRemoved[i], 1);
        }
    }
    VIDEOS_LENGTH[courseid] = videos_length;
    return chapter;
}
platformView.prototype.getAllCourses = function (catid, courses) {
    ALL_COURSES = [];
    ALL_COURSES[catid] = [];
    var coursesarray = [];
    for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
        coursesarray.push(courses[OBJECT_COURSE][i].nid);
    }
    ALL_COURSES[catid] = coursesarray;
}
platformView.prototype.checkSeekEnabled = function (course, chapter) {
    SEEK_ENABLED = true;
    if (typeof chapter.seek != "undefined") {
        if (chapter.seek == 0 || (chapter.seek == -1 && course.seek == 0)) {
            SEEK_ENABLED = false;
        }
    }
}

platformView.prototype.onUserUpgradeClick = function () {
    var user = CONTROLLER.getService("User");
    var settings = CONTROLLER.getService("Settings");
    var currentuser = settings.getLastLoggedinUser("user");
    var THIS = this;
    var newUserProductType;
    setTimeout(function () {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return;
        }
        Client.showProgressBar(t("Loading") + "");
        var currentUserProduct = settings.getUserProducts(OBJECT_USER_ID);
        DATABASE_DOWNLOADED = false;
        var newUserProducts = user.downloadUserProducts();
        if (newUserProducts == "error" || typeof newUserProducts === "undefined" || newUserProducts == "") {
            Client.closeProgressBar();
            alert(t("ERROR_MESSAGE", true));
            return;
        } else if (newUserProducts == 'access forbidden') {
            Client.closeProgressBar();
            OBJECT_USER_DATA.loginRequiredOnline = true;
            Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
            Platform.showMessage(t("USER_LOGOUT_ERROR", true));
            $('body').html('');
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
            CONTROLLER.loadView("Signin");
            return;
        }
        DATABASE_DOWNLOADED = true;
        Client.closeProgressBar();
        if (isArray(newUserProducts)) {
            newUserProducts = newUserProducts[0];
        }
        if (isArray(currentUserProduct)) {
            currentUserProduct = currentUserProduct[0];
        }
        if (newUserProducts.type == "internal") {
            newUserProductType = "hybrid";
        } else if (newUserProducts != "" && newUserProducts.type == "learning-course") {
            newUserProductType = "offline";
        }
        else if (newUserProducts.model.toLowerCase().indexOf("online") >= 0) {
            newUserProductType = "hybrid";
        }
        else if (newUserProducts.model.toLowerCase().indexOf("memcard") >= 0 || currentuser.email.indexOf("aesl") >= 0) {
            newUserProductType = "offline";
        }
        else {
            newUserProductType = "hybrid";
        }
        if ((currentUserProduct.title == newUserProducts.title) && (currentUserProduct.nid == newUserProducts.nid)) {
            alert("No new upgrades");
        }
        else {
            if (newUserProductType == "offline") {
                confirm("You are upgrading to an offline product. \n\
                    It is necessary that you should have the required SD card with you \n\
                    before upgrading. Are you sure you want to upgrade?",
                    function () {
                        THIS.upgradeUser();
                    });
            }
            else {
                confirm("New upgrade available. Are you sure you want to upgrade?",
                    function () {
                        THIS.upgradeUser();
                    });
            }
        }
    }, 400);
}

platformView.prototype.upgradeUser = function () {
    Client.showDialog();
    Client.showMessage("Upgrading user");
    var dbDeleted = Platform.deleteDatabase();
    DATABASE_DOWNLOADED = false;
    if (dbDeleted == true || dbDeleted == false) {
        $('body').html('');
        $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
        <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
        CONTROLLER.loadView("Signin");
        Client.removeDialog();
        Client.closeProgressBar();
    }
}

platformView.prototype.reDownloadAndDeleteDialog = function (alert_text, resourcedata, preference, download_complete_url, downloadmessage, courseid, type, contentdata) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var scrollposition = $(window).scrollTop();
    var nid = "";

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var catid = CONTROLLER.getVariable("activecatid");

    if ($(window).width() < 380) {
        width = 310;
        height = 170;
    }
    else {
        width = 438;
        height = 200;
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        create: function (event, ui) {
            $('body').bind('touchmove', function (e) { e.preventDefault() });
        },
        beforeClose: function (event, ui) {
            $('body').unbind('touchmove');
        },
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Re-Download": {
                text: t('Re-Download'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    if (resourcedata.type == RESOURCE_TYPE_ASSESSMENT || resourcedata.type == RESOURCE_TYPE_FLASHCARD) {
                        nid = resourcedata.url;
                    }
                    else if(resourcedata.type == RESOURCE_TYPE_ROLEPLAY){
                        nid = resourcedata.roleplay_nid;
                    }
                    else {
                        nid = resourcedata.nid;
                    }
                    if ((resourcedata.type == "Video" || resourcedata.type == RESOURCE_TYPE_WEBINAR) && typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true) {
                        var prompt_resolution = Platform.getPreference("prompt_resolution");
                        if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                            if (typeof resourcedata.webinar_record_id !== "undefined" && resourcedata.webinar_record_id != "") {
                                var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR, resourcedata.webinar_record_id, "content"));
                                videoFormat = webinarRecordObject["format-type"];
                            }
                        }
                        if (typeof DOWNLOAD_RESOURCE_FORMAT == "undefined" || (typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT == "")) {
                            select_video_resolution_for_download(false, "", "", "");
                            Platform.deleteFileFromInternalMemory(nid);
                        } else if (prompt_resolution == "true") {
                            if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                                select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                            } else {
                                select_video_resolution_for_download(false, resourcedata, courseid, CONTROLLER.getVariable("activecatid"));
                            }
                            Platform.deleteFileFromInternalMemory(nid);
                        }
                        else if (resourcedata.type == RESOURCE_TYPE_WEBINAR && webinarRecordObject['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) == -1) {
                            select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                            Platform.deleteFileFromInternalMemory(nid);
                        } else if (resourcedata.type == "Video" && resourcedata['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) == -1) {
                            select_video_resolution_for_download(false, resourcedata, courseid, CONTROLLER.getVariable("activecatid"));
                            Platform.deleteFileFromInternalMemory(nid);
                        } else {
                            Platform.deleteFileFromInternalMemory(nid);
                            contentUtils.fileDownload(resourcedata, preference, download_complete_url, downloadmessage, courseid, type);
                            if (typeof contentdata != 'undefined') {
                                contentUtils.showProgressBar(contentdata.nid);
                                contentUtils.showInProgressMessage(contentdata);
                            }
                        }
                    } else {
                        Platform.deleteFileFromInternalMemory(nid);
                        if(typeof contentdata != "undefined"){
                            var containerId = contentdata.nid;
                        }
                        else{
                            containerId = "";
                        }
                        contentUtils.fileDownload(resourcedata, preference, download_complete_url, downloadmessage, courseid, type, catid, containerId);
                        if (typeof contentdata != 'undefined') {
                            contentUtils.showProgressBar(contentdata.nid);
                            contentUtils.showInProgressMessage(contentdata);
                        }
                    }
                    return false;
                },
            },
            "Delete": {
                text: t('Delete'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    if (resourcedata.type == RESOURCE_TYPE_ASSESSMENT) {
                        nid = resourcedata.url;
                    }
                    else if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                        nid = resourcedata.webinar_record_id;
                    }
                    else if (resourcedata.type == RESOURCE_TYPE_ROLEPLAY){
                        nid = resourcedata.roleplay_nid;
                    }
                    else {
                        nid = resourcedata.nid;
                    }
                    Platform.deleteFileFromInternalMemory(nid);
                    if (typeof contentdata != 'undefined') {
                        contentUtils.showDownloadMessage(contentdata.nid);
                    } else if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                        contentUtils.showDownloadMessage(resourcedata.nid);
                    } else if(resourcedata.type == RESOURCE_TYPE_ROLEPLAY){
                        contentUtils.showDownloadMessage(resourcedata.nid);
                    }else if (resourcedata.type == RESOURCE_TYPE_FLASHCARD) {
                        contentUtils.showDownloadMessage(resourcedata.nid);
                    } else {
                        contentUtils.showDownloadMessage(nid);
                    }
                    if (CONTROLLER.getVariable("recentdownloadsview") == true) {
                        CONTROLLER.loadView("RecentDownloads");
                    }
                    return false;
                },
            },
            "Cancel": {
                text: t('Cancel'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    return false;
                },
            },
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog_redownload"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(alert_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);
    $(".ui-dialog-titlebar").hide();

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('.ui-button-text:contains("Re-Download"),.ui-button-text:contains("Delete")')
        .parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.ui-dialog-buttonset .ui-button').css({
        "width": "32.8%"
    });
    $('html body .ui-dialog').css({
        'z-index': '99999999',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'width': $(window).width() - 40 + 'px', 'margin-left': '-45%'
    });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

platformView.prototype.downloadFailed = function (alert_text, resourcedata, preference, download_complete_url, downloadmessage, courseid, type, contentdata) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var scrollposition = $(window).scrollTop();
    var nid = "";

    var width = 0;
    var height = 0;
    var contentUtils = CONTROLLER.getService("ContentUtils");

    if ($(window).width() < 380) {
        width = 310;
        height = 170;
    }
    else {
        width = 438;
        height = 200;
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        create: function (event, ui) {
            $('body').bind('touchmove', function (e) { e.preventDefault() });
        },
        beforeClose: function (event, ui) {
            $('body').unbind('touchmove');
        },
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Retry": {
                text: t('Retry'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    if (resourcedata.type == RESOURCE_TYPE_ASSESSMENT) {
                        nid = resourcedata.url;
                    }
                    else {
                        nid = resourcedata.nid;
                    }
                    if ((resourcedata.type == "Video" || resourcedata.type == RESOURCE_TYPE_WEBINAR) && typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true) {
                        var prompt_resolution = Platform.getPreference("prompt_resolution");
                        if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                            if (typeof resourcedata.webinar_record_id !== "undefined" && resourcedata.webinar_record_id != "") {
                                var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR, resourcedata.webinar_record_id, "content"));
                                videoFormat = webinarRecordObject["format-type"];
                            }
                        }
                        if (typeof DOWNLOAD_RESOURCE_FORMAT == "undefined" || (typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT == "")) {
                            select_video_resolution_for_download(false, "", "", "");
                            Platform.deleteFileFromInternalMemory(nid);
                        } else if (prompt_resolution == "true") {
                            if (resourcedata.type == RESOURCE_TYPE_WEBINAR) {
                                select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                            } else {
                                select_video_resolution_for_download(false, resourcedata, courseid, CONTROLLER.getVariable("activecatid"));
                            }
                            Platform.deleteFileFromInternalMemory(nid);
                        }
                        else if (resourcedata.type == RESOURCE_TYPE_WEBINAR && webinarRecordObject['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) == -1) {
                            select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                            Platform.deleteFileFromInternalMemory(nid);
                        } else if (resourcedata.type == "Video" && resourcedata['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) == -1) {
                            select_video_resolution_for_download(false, resourcedata, courseid, CONTROLLER.getVariable("activecatid"));
                            Platform.deleteFileFromInternalMemory(nid);
                        } else {
                            Platform.deleteFileFromInternalMemory(nid);
                            contentUtils.fileDownload(resourcedata, preference, download_complete_url, downloadmessage, courseid, type);
                            if (typeof contentdata != 'undefined') {
                                contentUtils.showProgressBar(contentdata.nid);
                                contentUtils.showInProgressMessage(contentdata);
                            }
                        }
                    } else {
                        Platform.deleteFileFromInternalMemory(nid);
                        contentUtils.fileDownload(resourcedata, preference, download_complete_url, downloadmessage, courseid, type);
                        if (typeof contentdata != 'undefined') {
                            contentUtils.showProgressBar(contentdata.nid);
                            contentUtils.showInProgressMessage(contentdata);
                        }
                    }
                    return false;
                },
            },
            "Done": {
                text: t('Done'),
                click: function () {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    return false;
                },
            },
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog_redownload"><span class="ui-icon ui-icon-alert"></span><p class="ui-title">Download Failed</p>\n\
                       <div class="alert_dialog_title">' + t(alert_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);
    $(".ui-dialog").css("border-radius", "25px");
    $('.alert_dialog_title').css("text-align", "initial");
    $(".ui-dialog-titlebar").hide();
    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({
        'z-index': '99999999',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'width': $(window).width() - 40 + 'px', 'margin-left': '-45%'
    });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}



platformView.prototype.onResetPasswordClick = function () {
    var user = CONTROLLER.getService("User");
    var data = "{";
    var temp = "";
    temp = temp + ' "email" : "' + OBJECT_USER_DATA.email + '", ';
    temp = temp + ' "username" : "' + OBJECT_USER_DATA.username + '" ';
    data = data + temp + "}";
    confirm("Are you sure, you want to reset your password?", function () {
        Client.showProgressBar(t("Loading") + "");
        setTimeout(function () {
            var status = user.resetPassword(data);
            Client.closeProgressBar();
            if (status == "true") {
                $('body').html('');
                $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                Platform.showMessage("Your password has been reset.");
                CONTROLLER.loadView("Signin");
            } else {
                alert(status);
            }
        }, 200);
    });
}

platformView.prototype.onBodyClick = function (e) {
    if (!$('#right-menu-wrapper').hasClass('hidden') && e.target.className != 'vjs-tech' && e.target.id != 'video-menu-icon') {
        $('#right-menu-wrapper').addClass('hidden');
        $("#right-menu-icon").removeClass("right-menu-selected");
    }
}

platformView.prototype.onSearchClick = function (focus) {
    var searchObj = CONTROLLER.getVariable("activesearchtext");
    if ($("#search_text").hasClass("hidden") || !$("#search_text").hasClass("inFocus")) {
        if (CONTROLLER.activeViewName !== "Search") {
            $("#search_text").addClass("inFocus");
        }
        $("#notifications,#sync_wrapper,#right-menu-icon,.title_text,.lang_selector").addClass("hidden");
        $("#search_icon").css("right", "12px");
        if (CONTROLLER.activeViewName == "HomePage") {
            $("#menu, #home_page_title_wrapper").addClass("hidden");
            $("#back").removeClass("hidden");
            $("#back").css("visibility", "visible");
        }
        $("#search_text").removeClass("hidden");
        if (searchObj && searchObj != "" && typeof searchObj.searchText != 'undefined' && searchObj.searchText != "") {
            $("#search_text").val(searchObj.searchText);
        } else {
            $("#search_text").val('');
        }
        if (typeof focus != 'undefined' && focus == false) {
            $("#search_text").removeClass("inFocus");
        } else {
            $("#search_text").focus();
        }
        if (!(typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true")) {
            var menuShowArray = ["#back"];
            showMenuIcons(menuShowArray);
        }
    } else if ($("#search_text").val().trim() == '') {
        $("#search_text").focus();
    } else if (CONTROLLER.activeViewName == "Search") {
        if (searchObj && searchObj != "" && typeof searchObj.searchText != 'undefined' && searchObj.searchText != "") {
            if (typeof focus != 'undefined' && focus == false) {
                $("#search_text").removeClass("inFocus");
            } else {
                $("#search_text").focus();
            }
            if (searchObj.searchText != $("#search_text").val().trim()) {
                this.onTextSearchClick();
            } else {
                $("#search_text").val(searchObj.searchText);
            }
        }
    } else if ($("#search_text").hasClass("inFocus")) {
        this.onTextSearchClick();
    }
};

platformView.prototype.onRightMenuClick = function (e) {
    try {
        $('body').swipe('enable');
    } catch (e) { }
    if ($("#right-menu-wrapper").hasClass("hidden")) {
        $("#right-menu-wrapper").removeClass("hidden");
        $("#right-menu-icon").addClass("right-menu-selected");
    }
    else {
        $("#right-menu-wrapper").addClass("hidden");
        $("#right-menu-icon").removeClass("right-menu-selected");
    }
    if (!$("#search_text").hasClass("hidden")) {
        $("#notifications,#sync_wrapper").removeClass("hidden");
        $("#search_text").addClass("hidden");
        $("#search_icon").removeClass("hidden");
        $("#search_text").val("");
        $(".title_text").css("visibility", "visible");
    }
    if (!$('#question_list_wrapper').hasClass('hidden')) {
        $('#question_list_wrapper').addClass('hidden');
        $('#question_filter_overlay').remove();
    }
    // this.checkNotifications();
    e.stopPropagation();
}

platformView.prototype.checkNotifications = function () {
    var setNotificationStatus = function (unreadNotifications) {
        if (unreadNotifications == 0) {
            if (!$(".unread_notification").hasClass("hidden")) {
                $(".unread_notification").addClass("hidden");
            }
        }
        else {
            if (EXEC_MODE == "online" || APP_TYPE == "ios") {
                $(".unread_notification").hasClass("hidden");
                $(".unread_notification").removeClass("hidden");
                $(".unread_notification").html(unreadNotifications);
                var notifications = Platform.getUnreadNotification();
                if (typeof notifications !== 'object') {
                    notifications['notification'] = $.parseJSON(notifications);
                } else {
                    notifications['notification'] = notifications;
                }
            } else {
                var notifications = Platform.getUserNotification(OBJECT_USER_ID);
                notifications = $.parseJSON(notifications);
            }
            for (var i = 0; i < notifications["notification"].length; i++) {
                var notification_type = notifications["notification"][i].c_type;
                if (EXEC_MODE == "online" || APP_TYPE == "ios") {
                    if (notification_type === "popup") {
                        notifications["notification"][i].isread = 0;
                        var notification_isread = Platform.getPreference("notification_seen_" + notifications["notification"][i].nid);
                        if (notification_isread == 1) {
                            notifications["notification"][i].isread = notification_isread;
                        }
                    }
                }
                if (notification_type === "popup" && notifications["notification"][i].isread == 0) {
                    tribyte_dialog_popup_notification(notifications["notification"][i]);
                    return false;
                }
            }
            if ($(".unread_notification").hasClass("hidden")) {
                $(".unread_notification").removeClass("hidden");
            }
            $(".unread_notification").html(unreadNotifications);
        }
    }
    if (EXEC_MODE == "online" || APP_TYPE == "ios") {
        Platform.getUnreadNotification(setNotificationStatus);
    } else {
        var unreadNotifications = Platform.getNotificationUnReadCount(OBJECT_USER_ID);
        setNotificationStatus(unreadNotifications);
    }
}

platformView.prototype.renderBody = function () {
    alert("This method needs to be overridden with your own implementation");
};

platformView.prototype.getTitle = function () {
    return "";
}

platformView.prototype.setbodystyle = function () {
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });

    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $("#footer_wrapper").css({
        width: $(window).width() + "px"
    });
    $('#content_wrapper').removeClass('content_wrapper_height');
}


platformView.prototype.setCustomStyles = function () {

};

platformView.prototype.sdCardReg = function (sdCardName) {

    var sdcardCid = Platform.getSdCardCID(sdCardName);
    var sdcardRegistered = false;
    if (sdcardCid == "") {
        sdcardRegistered = "";
    }

    var sdcardJson = Platform.getObjectsOfType(OBJECT_SDCARD);
    if (sdcardJson != "") {
        sdcardJson = $.parseJSON(sdcardJson);
        for (i = 0; i < sdcardJson.sdcard.length; i++) {
            if (sdcardCid == sdcardJson.sdcard[i].cid) {
                sdcardRegistered = true;
            }
        }
    }

    return sdcardRegistered;
};

platformView.prototype.showDownloadActionDialog = function (resourcedata, resnid) {
    //dialog to show buttons for cancel and delete of dialog
    $(function () {
        try {
            var scrollposition = $(window).scrollTop();
            var platformview = new platformView();
            var width;
            var height;
            var THIS = CONTROLLER.getService("ContentUtils");
            $('#content_wrapper').append('<div id="choose-download-action" ></div>');
            platformview.renderTemplate('download_action.tl', resourcedata, '#choose-download-action', true);
            var pause_state = THIS.getFilePauseState(resourcedata.nid);
            if (pause_state == false) {
                if (!$('#download-resume').hasClass('hidden')) {
                    $('#download-resume').addClass('hidden');
                    $('#download-pause').after('<div class="dialog_button_seperator"></div>');
                }
            } else {
                if (!$('#download-pause').hasClass('hidden')) {
                    $('#download-pause').addClass('hidden');
                    $('#download-resume').after('<div class="dialog_button_seperator"></div>');
                }
            }
            $('#download-cancel').after('<div class="dialog_button_seperator"></div>');
            platformview.bindDownloadActionClick();
            if ($(window).width() < 380) {
                width = 250;
                height = 170;
            }
            else {
                width = 300;
                height = 200;
            }
            $('#download-action').dialog({
                resizable: false,
                modal: true,
                height: height,
                width: width,
                create: function (event, ui) {
                    $('body').bind('touchmove', function (e) { e.preventDefault() });
                },
                beforeClose: function (event, ui) {
                    $('body').unbind('touchmove');
                },
                open: function () {
                    //                window.scrollTo(0,scrollposition);
                    $('.ui-widget-overlay').addClass('custom-overlay');
                },
                dialogClass: 'dlgfixed',
                position: "center",
            });
            calculateDialogHeight(105);
            $('.download-action-wrapper').css('width', '100%');
            $("#dialog_transprant_wrapper").toggle();
            setdialogstyle();
            $('body').bind('touchmove', function (e) { e.preventDefault() });
            $('#download-action-close').click(function (event) {
                closeDialog();
            });
            $('.download-action-buttons').bind('click', function (e) {
                var action_selected = $(e.target).attr("id");
                if (action_selected == 'download-resume') {
                    Platform.resumeDownload(resourcedata.nid);
                    Platform.showMessage(resourcedata.title + t(" download resumed"));
                    if (ENABLE_NEW_UI) {
                        $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_button').css({ 'background-image': 'url(./platform/images/thumb-dl-duel_new.png)' });
                    } else {
                        $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_button').css({ 'background-image': 'url(./platform/images/thumb-dl-duel.png)' });
                    }
                }
                else if (action_selected == 'download-cancel') {
                    Platform.cancelDownloads(resourcedata.nid);
                    if ($('.new_version_text[nid=' + resourcedata.nid + ']').hasClass("new_version_inprogress")) {
                        $('.new_version_text[nid=' + resourcedata.nid + ']').removeClass("hidden");
                        $('.new_version_text[nid=' + resourcedata.nid + ']').removeClass("new_version_inprogress");
                    }
                    Platform.showMessage(resourcedata.title + t(" download deleted"));
                    $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_status').html("");
                    var index = findIndexByKeyValue(DOWNLOAD_FILES_IN_PROGRESS, 'nid', resourcedata.nid);
                    if (index != null) {
                        DOWNLOAD_FILES_IN_PROGRESS.splice(index, 1);
                    }
                    if ($('.download_button[nid=' + resourcedata.nid + ']').hasClass("inprogress")) {
                        $('.download_button[nid=' + resourcedata.nid + ']').removeClass("inprogress");
                    }
                    $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_button').css({ 'background-image': '' });
                    Platform.removeObject("resource", resourcedata.nid);
                    Platform.deleteFileFromInternalMemory(resourcedata.nid);
                    if (resnid) {
                        $('.download_wrapper[nid=' + resnid + ']').find('.download_status').html("");
                        if ($('.download_button[nid=' + resnid + ']').hasClass("inprogress")) {
                            $('.download_button[nid=' + resnid + ']').removeClass("inprogress");
                        }
                        $('.download_wrapper[nid=' + resnid + ']').find('.download_button').css({ 'background-image': '' });
                        THIS.showDownloadMessage(resnid);
                    } else {
                        THIS.showDownloadMessage(resourcedata.nid);
                    }
                }
                else if (action_selected == 'download-pause') {
                    Platform.pauseDownload(resourcedata.nid);
                    Platform.showMessage(resourcedata.title + t(" download paused"));
                    if (ENABLE_NEW_UI) {
                        $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_button').css({ 'background-image': 'url(./platform/images/thumb-dl-blue_new.png)' });
                    } else {
                        $('.download_wrapper[nid=' + resourcedata.nid + ']').find('.download_button').css({ 'background-image': 'url(./platform/images/thumb-dl-blue.png)' });
                    }
                }
                else if (action_selected == 'cancel') {
                    $('.ui-widget-overlay').removeClass('custom-overlay');
                }
                setTimeout(function () {
                    closeDialog();
                }, 300);
            });

            $(".dlgfixed").center(false);
            $(".ui-dialog-titlebar").hide();

            $('#download-action').parent().addClass('alert_wrapper_dialog');
            $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
            $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
            $('html body .ui-dialog').css({
                'z-index': '100000',
                'background': '#ffffff', 'position': 'fixed', 'outline': 'none'
            });
            $("#transparent_wrapper").css({
                "display": "block",
                height: $(window).height() + "px",
                width: $(window).width() + "px"
            });
        }
        catch (e) {
            Client.closeProgressBar();
            console.log("function : showDownloadActionDialog"
                + "\n message:" + e.message);
        }
    });
}

platformView.prototype.showDownloadDialog = function (download_text, resourcedata,
    preference, download_complete_url, downloadmessage, courseid, type) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var scrollposition = $(window).scrollTop();

    if (typeof title == "undefined") {
        title = CLIENT_NAME;
    }
    var width;
    var height;

    if ($(window).width() < 380) {
        width = 250;
        height = 120;
    }
    else {
        width = 300;
        height = 120;
    }
    $('#alert_dialog').dialog({
        resizable: false,
        title: title,
        modal: true,
        height: height,
        width: width,
        create: function (event, ui) {
            $('body').bind('touchmove', function (e) { e.preventDefault() });
        },
        beforeClose: function (event, ui) {
            $('body').unbind('touchmove');
        },
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Download": function () {
                contentUtils.fileDownload(resourcedata, preference, download_complete_url, downloadmessage, courseid, type);
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;

            },
            "Cancel": function () {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            }
        },
        close: function () {
            $(this).dialog("close");
            $('#alert_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="alert_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + t(download_text) + '</div>\
                      </div>';

    $('#alert_dialog').html(text);

    $(".dlgfixed").center(false);

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({
        'z-index': '100000',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none'
    });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text:contains("Download")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');

    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

platformView.prototype.showUserSubscriptionError = function (overrideMessage) {
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var scrollposition = $(window).scrollTop();
    var THIS = this;

    var width;
    var height;
    if ($(window).width() < 380) {
        width = 250;
        height = 170;
    }
    else {
        width = 300;
        height = 200;
    }
    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        create: function (event, ui) {
            $('body').bind('touchmove', function (e) { e.preventDefault() });
        },
        beforeClose: function (event, ui) {
            $('body').unbind('touchmove');
        },
        open: function () {
            //            window.scrollTo(0,scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Buy Now": function () {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });

                if(typeof BUY_NOW_INTERNAL_LINK != 'undefined'){
                    THIS.handleInternalBuyNowClick();
                } else if (typeof BUY_NOW_PRODUCT_INFO != "undefined") {
                    THIS.handleProdInfoClick();
                } else {
                    THIS.handleBuyNowClick();
                }

            },
            "Cancel": function () {
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var message = overrideMessage ? overrideMessage : t("USER_SUBSCRIPTION_ERROR",true);
    var text = '<div class="alert_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
                       <div class="alert_dialog_title">' + message + '</div>\
                      </div>';

    $('#alert_dialog').html(text);

    $(".dlgfixed").center(false);
    $(".ui-dialog-titlebar").hide();

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.ui-button-text:contains("Buy Now")').parents('.ui-button').after('<div class="dialog_button_seperator"></div>');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text:contains("Buy Now")').css("font-family", "Roboto Bold");
    $('html body .ui-dialog').css({
        'z-index': '100000',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none'
    });
    $('#bla').after('<div class="dialog_button-seperator"></div>');
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
}

platformView.prototype.handleBuyNowClick = function (overrideURL) {
    try {
        Platform.setStatusAndNavigationBar(true);
        Platform.setDeviceOrientation("portrait");
        var redirectPath, url;
        var groupIdForProducts = CONTROLLER.getVariable("activegroupid");
        if (Platform.getNetworkState() == true) {
            if (USE_PORTAL_SERVER_DOMAIN && USE_PORTAL_SERVER_DOMAIN == true) {
                SERVER_DOMAIN = PORTAL_SERVER_DOMAIN;
            }
            else {
                SERVER_DOMAIN = OBJECT_SERVER_DOMAIN;
            }
            if (typeof SHOW_UNSUBSCRIBED_ERROR_MESSAGE != "undefined" && SHOW_UNSUBSCRIBED_ERROR_MESSAGE == "true") {
                alert(UNSUBSCRIBED_ERROR_MESSAGE);
                return;
            }
            else if (typeof EXTERNAL_BUY_NOW_LINK != "undefined" && EXTERNAL_BUY_NOW_LINK == true) {
                if (typeof BUY_NOW != "undefined" && BUY_NOW != "") {
                    url = BUY_NOW;
                    window.location.href = url
                    return;
                }
            }
            else if (typeof BUY_NOW_URL != "undefined") {
                // add selected language to buy now url
                if (typeof ENABLE_LOCALIZATION != 'undefined' && ENABLE_LOCALIZATION == 'true') {
                    BUY_NOW_URL = BUY_NOW_DOMAIN + '/IN/'+LANGUAGE_CODE[Platform.getPreference("lang")]+'/' + "user/product/list?";
                }else{
                    BUY_NOW_URL = BUY_NOW_DOMAIN + '/user/product/list?' + LOCAL_APP_PATH;
                }

                BUY_NOW_URL = BUY_NOW_URL + "?category=" + "&active_group=" + groupIdForProducts + "&app=true";
                
                if (BUY_NOW_URL.toLowerCase().indexOf('http') > -1) {
                    if (typeof OPEN_BUYNOW_IN_BROWSER != "undefined" && OPEN_BUYNOW_IN_BROWSER == true) {
                        Platform.openBrowser(BUY_NOW_URL);
                        Client.closeProgressBar();
                        return;
                    }
                    window.history.pushState({}, document.title, location.href.split("?")[0] + "?nosplash=true");
                    if (overrideURL) {
                        redirectPath = overrideURL;
                    } else {
                        redirectPath = BUY_NOW_URL;
                    }
                } else {
                    redirectPath = SERVER_DOMAIN + BUY_NOW_URL;
                }
            }
            else {
                if (typeof REDIRECTTOPRODUCTLIST != 'undefined' && REDIRECTTOPRODUCTLIST == "true") {
                    redirectPath = SERVER_DOMAIN + '/user/product/list?app=true&group=' + groupIdForProducts + '&category=' + CONTROLLER.getVariable("activegroupid");
                } else {
                    redirectPath = SERVER_DOMAIN + '/buynow/course?app=true&category=' + CONTROLLER.getVariable("activegroupid");
                }
            }

            // for android and ios open in webview and for mobile browser replace the current page
            if (APP_TYPE !== "web" && typeof OPEN_BUYNOW_IN_WEBVIEW != 'undefined' && OPEN_BUYNOW_IN_WEBVIEW == 'true') {
                loadContentInWebview(redirectPath, false, 'portrait', false, t(BUY_NOW_PAGE_NAME));
            } else {
                var url = SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&auth=" + AUTH_ENABLED + "&useractive=true&path=" + encodeURIComponent(redirectPath);
                Client.closeProgressBar();
                Platform.navigate(url);
            }
        }
        else {
            alert(INTERNET_ERROR);
            return;
        }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function :- handleBuyNowClick" + e.message);
        alert("Something went wrong. Please check your internet connection and try again");
    }
};

platformView.prototype.bindDownloadActionClick = function (e) {
    try {
        $('.download-action-buttons #download-resume, .download-action-buttons #download-pause, .download-action-buttons #download-cancel').bind('click', function (e) {
            $('.download-action-buttons #download-resume ,.download-action-buttons #download-pause,.download-action-buttons #download-cancel').removeClass('selected');
        });
    }
    catch (e) {
        console.log("function : bindDownloadActionClick"
            + "\n message:" + e.message);
    }
}

platformView.prototype.isResourcePlayable = function (resourceid, courseid, type, catid, playvideo, params, download, contenttype_ebook, isSupportDocs = false) {
    try {
        var cat_tid = '';
        var settingsService = CONTROLLER.getService("Settings");
        var resourceService = CONTROLLER.getService("Resource");
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        var categoryService = CONTROLLER.getService("Category");
        var groupId = CONTROLLER.getVariable("activegroupid");
        var resource = "";
        if(params.type == OBJECT_SUPPORT_DOCS){
            resource = $.parseJSON(Platform.getObjectProperty(OBJECT_SUPPORT_DOCS, resourceid, "content"));
        } else if (typeof contenttype_ebook != 'undefined' && contenttype_ebook == OBJECT_BOOK) {
            resource = $.parseJSON(Platform.getObjectProperty("content", resourceid, "content"));
        } else {
            resource = $.parseJSON(Platform.getObjectProperty(type, resourceid, "content"));
        }
        if(isSupportDocs && resource == null){
            var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_SUPPORT_DOCS, resourceid, "content"));
        }
        if (resource == null) {
            if (Platform.getNetworkState() == true) {
                resource = resourceService.getResourceInfoById(resourceid, true, OBJECT_VIDEO_WEBINAR);
            } else {
                alert(INTERNET_ERROR);
                Client.closeProgressBar();
                return false;
            }
        }
        if(resource.type == RESOURCE_TYPE_WEBINAR){
            var webinar_service = CONTROLLER.getService("Webinar");
            var webinar_data = webinar_service.getWebinarById(resource.url);
            CONTROLLER.setVariable('webinar_toc_details', params);
            if(typeof webinar_data != "undeifned" && webinar_data == INTERNET_ERROR && 
            typeof resource.webinar_record_id != "undefined" && resource.webinar_record_id != "" && resource.webinar_record_id != null){
                return;
            }
            if(typeof webinar_data != 'undefined' && webinar_data != "" && ( webinar_data.session_status == "started"  || webinar_data.session_status == null)){
                this.webinarInTOC(webinar_data);
                Client.closeProgressBar();
                return;
            }else if(webinar_data.session_status == "completed" && isTrainer()){
                this.webinarInTOC(webinar_data);
                Client.closeProgressBar();
                return;
            }else if(typeof resource.webinar_record_id == 'undefined'
                    || (typeof resource.webinar_record_id != 'undefined'
                    && (resource.webinar_record_id == "" || resource.webinar_record_id == null ))){
                alert(WEBINAR_VIDEO_TRANSCODING);
                this.updateVVHforTocWebinar(webinar_data.startdate);
                Client.closeProgressBar();
                return false;
            }
            var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR, resource.webinar_record_id, "content"));
            if(webinarRecordObject == null){
                if(Platform.getNetworkState() == true){
                    webinarRecordObject = resourceService.getResourceInfoById(resource.webinar_record_id, true, OBJECT_VIDEO_WEBINAR);
                }else{
                    alert(INTERNET_ERROR);
                    return false;
                }
            }
        }
        if (typeof catid == 'undefined' || catid == "") {
            catid = resource[OBJECT_CATEGORY][0];
        }
        var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));
        cat_tid = category.tid;

        if (typeof resource.tags != 'undefined' && resource.tags.includes("post_assessment")) {

            var categoryId;
            if (EXEC_MODE == 'online') {
                categoryId = cat_tid;
            } else {
                categoryId = catid;
            }
            var watchedContent = categoryService.getCompletedVideosOfSubjectCount(groupId, categoryId);
            var totalContent = categoryService.getResourcesCount(categoryId, "", groupId);
            if (!(totalContent - 1 <= watchedContent)) {
                alert(MANDATORY_RESOURCES_COMPLETION);
                Client.closeProgressBar();
                return false;
            }
        }
        //check category level sequential learning
        var isCategoryViewable = this.checkCategoryLevelSequentialLearning(catid, CONTROLLER.getVariable("activegroupid"));
        if (!isCategoryViewable) {
            if ($(".topic_title_wrapper_inner").length > 1) {
                $(".topic_title_wrapper_inner").css("opacity", "1");
            }
            return false;
        }
        var status = "";
        if (USER_PRODUCT_TYPE == "offline" && resource.price.toLowerCase() == "paid") {
            if (settingsService.getUserSubscriptions(OBJECT_USER_ID, catid, courseid, CONTROLLER.getVariable("activegroupid")) == ""
                && settingsService.getUserSubscriptions(OBJECT_USER_ID, "", courseid, CONTROLLER.getVariable("activegroupid")) == ""
                && settingsService.getUserSubscriptions(OBJECT_USER_ID, "", courseid, "") == "") {
                if (typeof SHOW_UNSUBSCRIBED_RES_MESSAGE != "undefined" && SHOW_UNSUBSCRIBED_RES_MESSAGE == "true") {
                    alert(t("UNSUBSCRIBED_RESOURCE_MESSAGE", true));
                    Client.closeProgressBar();
                } else {
                    this.showUserSubscriptionError();
                }
                return false;
            }
        }
        if (USER_PRODUCT_TYPE != "online") {
            if ((resource.type == RESOURCE_TYPE_HTML || (typeof RESOURCE_TYPE_ASSIGNMENT != "undefined" && resource.type == RESOURCE_TYPE_ASSIGNMENT) || (typeof OBJECT_SCORM != "undefined" && resource.type == OBJECT_SCORM) || resource.type.toLowerCase() == "html" || resource.type.toLowerCase().indexOf('scorm') >= 0)) {
                if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
                    var content_access_type = CHECK_SDCARD_CONTENT;
                } else {
                    var content_access_type = USER_PRODUCT_TYPE;
                }
                var contentAvailable = Platform.isContentAvailable(resource.nid, resource.youtube_id, RESOURCE_FORMAT, content_access_type, ISAVAILABLE_HTML_TYPE);
                contentAvailable = $.parseJSON(contentAvailable);
                if (resource.type.toLowerCase() == "html" && Platform.getNetworkState() == false) {
                    return true;
                }
                if (contentAvailable.filestatus == "Available" || contentAvailable.filestatus == "In Progress") {
                    return true;
                }
                else {
                    status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                    return status;
                }
            }
            else if(resource.type == RESOURCE_TYPE_ROLEPLAY){
                var isFileAvailable = contentUtilsService.isFileAvailable(resource.roleplay_nid, resource.youtube_id, CONTROLLER.getVariable("activecourseid"), "roleplay");
                if (isFileAvailable.filestatus == "Available") {
                    return true;
                } else if (isFileAvailable.filestatus == "Not Available" && USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == false) {
                    alert(t("INTERNET_ERROR", true));
                    Client.closeProgressBar();
                    return false;

                } else if (isFileAvailable.filestatus == "In Progress" && isFileAvailable.version != "old" && Platform.getNetworkState() == false) {
                    alert(t("FILE_DOWNLOAD_IN_PROGRESS", true));
                    Client.closeProgressBar();
                    return false;
                } else if (USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == true) {
                    status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                    return status;
                }
            }
            else if ((type == OBJECT_VIDEO || resource.type == "Video") && resource.type != RESOURCE_TYPE_ASSESSMENT && resource.type != RESOURCE_TYPE_FLASHCARD) {
                if(typeof params.type != "undefined" && params.type == "supportdocs"){
                    type = OBJECT_SUPPORT_DOCS  
                }
                if (type == OBJECT_SUPPORT_DOCS || type == OBJECT_VIDEO_WEBINAR) {
                    RESOURCEPATH = resourceService.getResourcePath(resourceid, courseid, catid, type);
                } else {
                    RESOURCEPATH = resourceService.getResourcePath(resourceid, courseid, catid);
                }
                if (USER_PRODUCT_TYPE == "hybrid" && RESOURCEPATH[0] == "online") {
                    status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                    return status;
                }
                if (RESOURCEPATH[0] == "error") {
                    if (USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == false) {
                        alert(t("INTERNET_ERROR", true));
                        Client.closeProgressBar();
                        return false;
                    }
                    var fileAvailable = contentUtilsService.isFileAvailable(resource.nid, resource.youtube_id, courseid, "video", catid);
                    if (fileAvailable.filestatus == "Not Available") {
                        if (USER_PRODUCT_TYPE == "offline") {
                            if (typeof download != "undefined" && download == "true") {
                                return true;
                            }
                            else {
                                var sdcardcompatibility = checkSdcardCompatibility(courseid, catid);
                                if (sdcardcompatibility == true) {
                                    this.showDownloadDialog(t("FILE_NOT_AVAILABLE_AND_DOWNLOAD", true), resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, type);
                                }
                                else {
                                    return false;
                                }
                            }
                        }
                        else if (USER_PRODUCT_TYPE == "hybrid") {
                            this.showDownloadDialog(t("FILE_NOT_AVAILABLE_AND_DOWNLOAD", true), resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, type);
                        }
                    }
                    else if (fileAvailable.filestatus == "In Progress" && fileAvailable.version != "old" && Platform.getNetworkState() == false) {
                        alert(t("FILE_DOWNLOAD_IN_PROGRESS", true));
                        Client.closeProgressBar();
                    }
                    return false;
                }
                else {
                    return true;
                }
            }
            else if (type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT || resource.type == RESOURCE_TYPE_FLASHCARD) {
                var quizObj;
                if ((resource.type == RESOURCE_TYPE_ASSESSMENT || resource.type == RESOURCE_TYPE_FLASHCARD) && resource.url) {
                    var quizService = CONTROLLER.getService("Quiz");
                    quizObj = quizService.getQuiz(resource.url);
                } else {
                    quizObj = resource;
                }

                var isFileAvailable = contentUtilsService.isFileAvailable(quizObj.nid, quizObj.changed, "", "quiz");
                
                if (isFileAvailable.filestatus == "Available") {
                    return true;
                }
                else if (isFileAvailable.filestatus == "Not Available" && USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == false) {
                    var quiz_meta = Platform.getPreference(resource.url);
                    if (quiz_meta != "") {
                        quiz_meta = $.parseJSON(quiz_meta);
                        if (quiz_meta.nid == resource.url) {
                            return true;
                        }
                    } else {
                        alert(t("INTERNET_ERROR", true));
                        Client.closeProgressBar();
                        return false;
                    }
                }
                else if (isFileAvailable.filestatus == "In Progress" && isFileAvailable.version != "old" && Platform.getNetworkState() == false) {
                    alert(t("FILE_DOWNLOAD_IN_PROGRESS", true));
                    Client.closeProgressBar();
                    return false;
                }
                else if (USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == true) {
                    status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                    return status;
                }
                else {
                    return true;
                }
            }
            else if (type == OBJECT_BOOK || resource.type == "Document") {
                if (resource.filetype == "pdf") {
                    var isFileAvailable = contentUtilsService.isFileAvailable(resource.nid, resource.youtube_id, CONTROLLER.getVariable("activecourseid"), "ebook");
                    if (isFileAvailable.filestatus == "Available") {
                        return true;
                    } else if (isFileAvailable.filestatus == "Not Available" && USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == false) {
                        alert(t("INTERNET_ERROR", true));
                        Client.closeProgressBar();
                        return false;

                    }
                    else if (isFileAvailable.filestatus == "In Progress" && isFileAvailable.version != "old" && Platform.getNetworkState() == false) {
                        alert(t("FILE_DOWNLOAD_IN_PROGRESS", true));
                        Client.closeProgressBar();
                        return false;
                    }
                    else if (USER_PRODUCT_TYPE == "hybrid" && Platform.getNetworkState() == true) {
                        status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                        return status;
                    }
                } else {
                    status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
                    return status;
                }
            }
        }
        else {
            status = this.checkResourceStatus(resource, courseid, cat_tid, playvideo, params, download);
            return status;
        }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function : isResourcePlayable " + e.message);
    }
}

platformView.prototype.beginQuiz = function (e, id, type, embed) {
    if (typeof embed == "undefined") {
        embed = false;
    }
    var params = { "nid": id, "embed": embed };
    $('#chapters_list_wrapper').remove();
    $('#content_wrapper').attr('id', 'quiz_wrapper');
    loadMathJax(function () {
        if (type == 'practice') {
            CONTROLLER.loadView('Practicequiz', params);
        } else {
            if (type == 'game' || type == 'practice_game') {
                params.gamemode = true;
            }
            CONTROLLER.loadView('Certificatequiz', params);
        }
    });
};

platformView.prototype.checkResourceStatus = function (resource, courseid, cat_tid, playvideo, params, download) {
    try {
        var contentUtilsService = CONTROLLER.getService("ContentUtils");
        var userService = CONTROLLER.getService("User");
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            Client.closeProgressBar();
            return false;
        }
        var resourceStatus = contentUtilsService.getResourceStatus(resource.nid, resource.type, courseid, download, cat_tid, resource, params);
        if (resource.type != "Video") {
            playvideo = false;
        } else if (resource.type == "Video" && resource.source == "Link") {
            playvideo = false;
        }

        if (resourceStatus == "403") {
            if (typeof ASYNC_LOGIN_REQUIRED != 'undefined' && ASYNC_LOGIN_REQUIRED == "true") {
                var loginStatus = userService.loginRedirect();
                if (loginStatus == true) {
                    resourceStatus = "200";
                }
            }
            else {
                var socialLogin = Platform.getPreference('socialLogin');
                OBJECT_USER_DATA.loginRequiredOnline = true;
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
                Platform.showMessage(t("USER_LOGOUT_ERROR", true));
                $('#wrapper').remove();
                $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
                 <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
                if (window.innerHeight > window.innerWidth) {
                    if (typeof socialLogin != "undefined" && socialLogin == "true") {
                        CONTROLLER.loadView("SigninLanding");
                    } else {
                        CONTROLLER.loadView("Signin");
                    }
                }
                else {
                    setTimeout(function () {
                        Platform.setDeviceOrientation("portrait");
                        $(window).on("resize", function () {
                            Platform.setStatusAndNavigationBar(true);
                            if (typeof socialLogin != "undefined" && socialLogin == "true") {
                                CONTROLLER.loadView("SigninLanding");
                            }
                            else {
                                CONTROLLER.loadView("Signin");
                            }
                            $(window).off("resize");
                        });
                    }, 50);
                }
                return false;
            }
        }
        if (resourceStatus == "404") {
            if (typeof download != "undefined" && download == "true") {
                alert(t("FILE_NOT_AVALABLE_FOR_DOWNLOAD", true));
                return false;
            } else {
                return true;
            }
        } else if (resourceStatus == "403") {
            if (typeof SAML_LOGIN != "undefined" && SAML_LOGIN == "true") {
                this.user = CONTROLLER.getService("User");
                var samlLogin = Platform.getPreference('samlLogin');
                var emailid = Platform.getPreference('emailid');
                if (typeof samlLogin != "undefined" && samlLogin == "true") {
                    var status = this.user.loginRedirect(emailid);
                    return true;
                }
                else {
                    this.callAccessDenied();
                    return false;
                }
            }
            else {
                this.callAccessDenied();
                return false;
            }
        }
        else if (resourceStatus == "401") {
            if (typeof SHOW_UNSUBSCRIBED_RES_MESSAGE != "undefined" && SHOW_UNSUBSCRIBED_RES_MESSAGE == "true") {
                tribyte_check_plans_show(t("UNSUBSCRIBED_RESOURCE_MESSAGE", true));
                if(typeof CONTROLLER.activeViewName == "undefined" || CONTROLLER.activeViewName != "undefined" && CONTROLLER.activeViewName == null){
                    CONTROLLER.loadView("HomePage");
                }
            } else if ((typeof playvideo != "undefined" && playvideo == true) && (typeof HIDE_UNSUBSCRIBED_RESOURCE == 'undefined')) {
                var res_status = contentUtilsService.getUnsubscribedResourceurl(resource.nid, resource.type, courseid, download, cat_tid, resource);
                if (res_status == "200") {
                    CONTROLLER.setVariable("hide_bookmarks", "true");
                    return true;
                } else if (res_status == 403) {
                    onAccessDenied();
                } else {
                    return false;
                }
            } else {
                this.showUserSubscriptionError();
            }
            return false;
        }
        else if (resourceStatus == "408") {
            alert(RESOURCE_NOT_ALLOWED);
            return false;
        }
        else if (resourceStatus == "200") {
            return true;
        }
        else {
            alert(t("RESOURCE_ERROR", true));
            return false;
        }
    }
    catch (e) {
        console.log("function : checkResourceStatus " + e.message);
        Client.closeProgressBar();
    }
};

platformView.prototype.registerUser = function () {
    if (Platform.getNetworkState() == false) {
        alert(t("INTERNET_ERROR", true));
    }
    var userjsonstring = CONTROLLER.getVariable("userJson");
    var usergroups = CONTROLLER.getVariable("usergroups");
    var userstream = CONTROLLER.getVariable("userstream");
    var userService = CONTROLLER.getService("User");

    var userjson = $.parseJSON(userjsonstring);
    userjson.groups = usergroups;
    userjson.stream = userstream;
    userjson.utm_source = "tribyteapp";
    userjson.role = "Student";
    OBJECT_USER_PASSWORD = userjson.password;

    var result = userService.createUser(JSON.stringify(userjson));

    if (result == "true" || result == USER_ALREADY_REGISTERED) {
        var status1 = userService.loginUser(JSON.stringify(userjson));
        if (status1 !== "true") {
            alert(status1);
            return;
        }

        var user_groups = this.getUserGroups();
        if (user_groups == "") {
            alert(t("ERROR_MESSAGE", true));
            return;
        }
        OBJECT_USER_DATA.groups = user_groups;

        var userProducts = userService.downloadUserProducts();
        if (userProducts != "" && typeof userProducts === "undefined" ||
            (userProducts == 'access forbidden') || (userProducts == "error")) {
            this.onUserProductsError(OBJECT_USER_DATA, userProducts);
            return "";
        }

        var bootstrapUser = this.bootstrapUser();
        if (bootstrapUser == false) {
            return "";
        }
        DATABASE_DOWNLOADED = true;
        this.saveUserData(OBJECT_USER_DATA, user_groups);
        userService.downloadUserProducts();
        this.registerCron();
        this.initializeUser();
    }
    else
        alert(result);
}

platformView.prototype.registerCron = function () {
    var group = "";
    var timestamp = "1428364800";
    var cronurl = "";
    var cronjobs = "";
    var crontimestamp = "";
    for (var k = 0; k < OBJECT_USER_DATA.groups.length; k++) {
        for (var l = 0; l < APP_USER_GROUPS.length; l++) {
            if (OBJECT_USER_DATA.groups[k] == APP_USER_GROUPS[l].nid) {
                group = APP_USER_GROUPS[l];
                cronjobs = Platform.getAllCronJobs();
                cronjobs = $.parseJSON(cronjobs);
                if (!$.isEmptyObject(cronjobs)) {
                    for (j = 0; j < cronjobs.length; j++) {
                        if (cronjobs[j].id == "objectsmodified-" + encodeURIComponent(group.title)) {
                            cronurl = cronjobs[j].url;
                            cronurl = cronurl.split("=");
                            crontimestamp = cronurl[1].split("&");
                            timestamp = crontimestamp[0];
                        }
                    }
                }
                Platform.unregisterCronJob("objectsmodified-" + encodeURIComponent(group.title));
                Platform.unregisterCronJob("objectsmodified-" + encodeURIComponent(group.title) + "%20");
                Platform.registerCronJob(OBJECT_SERVER_DOMAIN + '/api/getobjectsmodified?timestamp=' + timestamp + '&uid=' + OBJECT_USER_ID + "&version=" + API_VERSION + "&groups=" + encodeURIComponent(group.title), 1, "objectsmodified-" + encodeURIComponent(group.title));
            }
        }
    }
}

platformView.prototype.bootstrapUser = function () {
    if(typeof DOWNLOADING_CONTENT_MESSAGE !== "undefined" && DOWNLOADING_CONTENT_MESSAGE === "true"){
        Client.showMessage(t("Downloading Course Content"));
    }else{
        Client.showMessage("");
    }
    var userjson = OBJECT_USER_DATA;
    userjson.trialuser = true;
    var bootstrap = Platform.bootstrapUser(JSON.stringify(userjson));
    bootstrap = $.parseJSON(bootstrap);
    if (bootstrap && bootstrap.bootstrap_status != "success") {
        Client.removeDialog();
        Client.closeProgressBar();
        this.display_login_error(bootstrap.bootstrap_message + '. Contact ' + PROJECT_TITLE + ' support with your user details for further assistance');
        Platform.logMessage("Downloading database failed.");
        return false;
    }
    else {
        return true;
    }
}

platformView.prototype.saveUserData = function (currentuser, user_groups) {
    if (user_groups) {
        currentuser.groups = user_groups;
    }
    var userService = CONTROLLER.getService("User");
    Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(currentuser)));
    Platform.saveObject("user", currentuser.uid, "content", $.trim(JSON.stringify(currentuser)));
}

platformView.prototype.initializeUser = function () {
    var initializeuser = Platform.initializeUser(JSON.stringify(OBJECT_USER_DATA));
    if (initializeuser == true || initializeuser == false) {
        if(EXEC_MODE == "mobile"){
            window.location.href = Platform.getAppLocalURL()
        }else{
            window.location.href = "../html/index.html?ftl=true";
        }
    }
}

platformView.prototype.getUserGroups = function () {
    var user_groups = [];
    var user = this.user.getGroupsForUser(OBJECT_USER_ID);

    if (user == "" || user == GET_USER_GROUPS_FAILURE || user == INTERNET_ERROR)
        return "";

    var k = 0;
    for (i = 0; i < user.length; i++) {
        for (j = 0; j < APP_USER_GROUPS.length; j++) {
            if (user[i].title.indexOf(APP_USER_GROUPS[j].title) > -1) {
                user_groups[k] = user[i].nid;
                k++;
            }
        }
        user_groups[k] = user[i].nid;
        k++;
    }
    return user_groups;
}

platformView.prototype.getGroupsWithCategories = function (groupid) {
    try {
        var categories = [], groups = [];
        var categoryService = CONTROLLER.getService("Category");
        if (HIDE_GROUPS_FILTER == "true") {
            groups = OBJECT_USER_GROUPS;
        }
        else {
            groups.push(CONTROLLER.getVariable("activegroupid"));
        }

        if (!groupid) {
            groupid = "";
        }
        if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
            categories = categoryService.getCategoriesForGroup(groupid);
        }
        //sending groupid as empty to get all the user subscribed categories
        else {
            categories[OBJECT_CATEGORY] = categoryService.getUserSubscribedCategories();
        }
        var groups = this.getUserGroupsToBeShown(groups);
        categories.groups = groups;
        return categories;
    } catch (e) {
        console.log("function - getGroupsWithCategories : " + e.message)
    }
}

platformView.prototype.getUserProductCategories = function (groupid) {
    var userproduct = CONTROLLER.getService("Settings").getUserProducts(OBJECT_USER_ID);
    var categories = CONTROLLER.getService("Category").getCategoriesForGroup(groupid);

    if (userproduct != "") {
        var productcategories = [];
        var userproductcategories = [];
        var category = "";
        var i = 0;
        var newcategoriesjson = [];
        var temp = "";
        if (isArray(userproduct)) {
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (l = 0; l < userproduct.length; l++) {
                    for (j = 0; j < userproduct[l][OBJECT_CATEGORY].length; j++) {
                        if (userproduct[l][OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                            var found = false;
                            for (var m = 0; m < newcategoriesjson.length; m++) {
                                if (newcategoriesjson[m].nid == categories[OBJECT_CATEGORY][k].nid) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found != true) {
                                newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                                i++;
                            }
                        }
                    }
                }

            }
        }
        else {
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (j = 0; j < userproduct[OBJECT_CATEGORY].length; j++) {
                    if (userproduct[OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                        newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                        i++;
                    }
                }
            }
        }
        userproductcategories[OBJECT_CATEGORY] = newcategoriesjson;
        return userproductcategories;
    }
    else {
        if (typeof PRODUCT_SUBSCRIPTION != "undefined" && PRODUCT_SUBSCRIPTION == "true") {
            userproductcategories = [];
            userproductcategories[OBJECT_CATEGORY] = [];
            return userproductcategories;
        } else {
            return categories;
        }
    }
}

function resultcallback(imagepath) {

    if (imagepath == '') {
        $('#attach_content_wrapper').addClass('hidden');
        $('#attach_image_overlay').remove();
        alert(FILE_NOT_SUPPORTED);
        return;
    }
    if (imagepath != "") {
        if (IMAGEPATH == "") {
            GALLERYPATH = imagepath; //image path from gallery
        }
        if (CONTROLLER.activeViewName == 'BlogComments') {
            var blogcommentsview = new BlogCommentsView();
            blogcommentsview.blogsAttachImage(imagepath); // call to commentsview of blogs
        }
        else if (CONTROLLER.activeViewName == 'CreateBlog') {
            var blogcreate = new CreateBlogView();
            blogcreate.blogsAttachImage(imagepath); // call create blog view
        }
        else if (CONTROLLER.activeViewName == 'Comments') {
            var commentsView = new CommentsView();
            commentsView.attachImage(imagepath); // call to commentsview of forum
        }
        else if (CONTROLLER.activeViewName == 'Certificatequiz' || CONTROLLER.activeViewName == 'Practicequiz') {
            if(imagepath.indexOf('.mp3') > -1 || imagepath.indexOf('.mp4') > -1 || imagepath.indexOf('.mkv') > -1 || imagepath.indexOf('.wav') > -1){
                var adjustedFilePath = "file:" + imagepath;
                var folderName = "QuestionAudioVideoUpload_"+ OBJECT_USER_ID;
                var url =  OBJECT_SERVER_DOMAIN + "/api/user/fileupload?uid="+OBJECT_USER_ID+'&folder='+folderName;
                var upload_data = {"message" : "Uploading...", "data" : adjustedFilePath, "url" : url, "method" : "post", "showdialog" : "true", "callback" : "getUploadedVideoPath"};
                upload_data = JSON.stringify(upload_data);
                Platform.makeAsyncTaskCall(upload_data);
            }else{
                var quizView = new QuizView();
                quizView.attachImage(imagepath); // call to certificateQuizview
            }
        }
        else {
            var questionView = new PostQuestionView();
            questionView.attachImage(imagepath); // call to postquestionview of forum
        }
    }
}
// this function called by android api, returns uploaded file path
function getserverImagePath(imgdata) {
    if (imgdata) {
        imgdata = JSON.stringify(imgdata);
        imgdata = $.parseJSON(imgdata);
        var comment_json = {};
        if (EXEC_MODE == "online") {
            var filepath = imgdata[0]['filepath'];
        } else {
            var commentdata = imgdata['data'];
            commentdata = $.parseJSON(commentdata);
            comment_json[QUESTIONS_WITH_COMMENTS] = commentdata;
            var filepath = comment_json[QUESTIONS_WITH_COMMENTS][0]['filepath'];
        }
        if (CONTROLLER.activeViewName == 'BlogComments') {
            var blogcomment = new BlogCommentsView();
            blogcomment.addBlogsAttachedFile(filepath);
        }
        else if (CONTROLLER.activeViewName == 'CreateBlog') {
            var blogcreate = new CreateBlogView();
            blogcreate.getquestionData(filepath);
        }
        else if (CONTROLLER.activeViewName == 'Comments') {
            var commentsView = new CommentsView();
            commentsView.addAttachedFile(filepath); // call to commentsview of forum
        }
        else if (CONTROLLER.activeViewName == 'Certificatequiz' || CONTROLLER.activeViewName == 'Practicequiz') {
            // if file is of video or audio show the file directly
			if(filepath.indexOf('.mp3') > -1 || filepath.indexOf('.mp4') > -1 || filepath.indexOf('.mkv') > -1 || filepath.indexOf('.wav') > -1){
                var filepath = encodeURI(filepath);
                var data = {};
                var file = {'filepath' : filepath};
                data['status'] = 200;
                data['data'] = [];
                data['data'].push(file);
                getUploadedVideoPath(data);
            }else{
                var quizView = new QuizView();
                quizView.saveQuizImageFile(filepath); // call to certificateQuizview
            }
        }
        else {
            var questionView = new PostQuestionView();
            questionView.postAttachedFile(filepath);// call to postquestionview of forum
        }

    }
}

platformView.prototype.onPeerSyncClick = function () {
    CONTROLLER.loadView("Sync");
}

platformView.prototype.renderNotesList = function () {
    try {
        Client.showProgressBar(t("Loading") + "");
        var courseid = CONTROLLER.getVariable("activecourseid");
        var courseService = CONTROLLER.getService("Course");
        var categoryService = CONTROLLER.getService("Category");
        if (typeof courseid != "undefined" && courseid != "") {
            var course_notes = courseService.getNotesForCourse(CONTROLLER.getVariable("activecourseid"));
            Client.closeProgressBar();
            var notes = [];
            var notes_res_id = CONTROLLER.getVariable('activeresourceid');
            if(typeof SHOW_PORTRAIT_VIDEO != 'undefined' && SHOW_PORTRAIT_VIDEO){
                // filtering notes for respective video
                if(typeof course_notes['chapterToRender'] != 'undefined'){
                    for(var i=0; i< course_notes['chapterToRender'].length; i++){
                        var content = course_notes['chapterToRender'][i]['content'];
                        for(var j=0; j < content.length; j++){
                             var eachContent = content[j]['notes'];
                             for(var k=0; k< eachContent.length; k++){
                                 if(notes_res_id == eachContent[k].resourceid){
                                     notes.push(eachContent[k]);
                                     notes.subtopic_name =course_notes['chapterToRender'][i].title
                                 }
                             }
                        }
                     }
                }   
                course_notes['notes'] = notes;
                course_notes['subtopic_name'] = notes.subtopic_name;
                this.renderTemplate("video_notes.tl", course_notes, ".v_tab_video_notes_view", true);
                $("#video_notes_index_wrapper").height($("#video_tab_content_wrapper").height() - 130 + "px");
                if(typeof course_notes.notes != "undefined" && course_notes.notes.length == 0){
                    $("#subtopic_name").addClass("hidden")
                }
            }else{
                this.renderTemplate("noteslist.tl", course_notes, "#content_wrapper", true);
            }
        }
        else {
            var category_notes = categoryService.getNotesForCategory(CONTROLLER.getVariable("activecatid"),
                CONTROLLER.getVariable("activegroupid"));
            Client.closeProgressBar();
            this.renderTemplate("categories_noteslist.tl", category_notes, "#content_wrapper", true);
        }
    }
    catch (e) {
        console.log("function :- renderNotesList " + e.message);
    }
};

platformView.prototype.callAccessDenied = function () {
    try {
        Platform.setPreference('saml_access_denied', 'true');
        OBJECT_USER_DATA.loginRequiredOnline = true;
        Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
        Platform.saveObject("user", OBJECT_USER_DATA.uid, "content", $.trim(JSON.stringify(OBJECT_USER_DATA)));
        Platform.showMessage(t("USER_LOGOUT_ERROR", true));
        $('body').html('');
        $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
        $('body').css("overflow", "auto");
        if (window.innerHeight > window.innerWidth) {
            CONTROLLER.loadView("Signin");
        }
        else {
            setTimeout(function () {
                Platform.setDeviceOrientation("portrait");
                $(window).on("resize", function () {
                    clearTimeout(titleTimer);
                    Platform.setStatusAndNavigationBar(true);
                    CONTROLLER.loadView("Signin");
                    $(window).off("resize");
                });
            }, 50);
        }
    }
    catch (e) {
        console.log("function :- callAccessDenied " + e.message);
    }
}
platformView.prototype.isQuizViewable = function (resource, type, onlineContent) {
    var THIS = this;
    this.quiz = CONTROLLER.getService("Quiz");
    this.allQuestions = this.quiz.getQuiz(resource.nid);
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var isFileAvailable = contentUtils.isFileAvailable(resource.nid, resource.changed, "", "quiz");

    var mode = getQueryParameter('mode');
    if ((Platform.getNetworkState() == true) && (typeof CHECK_QUIZ_ATTEMPTS != 'undefined' && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttempts(resource.nid);
        if (quiz_attempts == ERROR_MESSAGE) {
            alert(quiz_attempts);
            Client.closeProgressBar();
            return "";
        }
        var attempts = quiz_attempts['attempts']['total_attempts'];
        var quiz_attempt_data = quiz_attempts['attempts'].quiz_attempt_data;
        if (typeof quiz_attempt_data !== 'undefined' && quiz_attempt_data.length > 0) {
            quiz_attempt_data = quiz_attempt_data[quiz_attempt_data.length - 1];
            if(quiz_attempt_data.is_evaluated != 1 && quiz_attempt_data.time_end == 0){
                attempts = attempts - 1;
            }
        }
    }
    else if ((Platform.getNetworkState() == false) && (typeof CHECK_QUIZ_ATTEMPTS != 'undefined' && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttemptsinOffline(resource.nid);
        var attempts = quiz_attempts;
    }
    var quiz_attempts_offline = this.quiz.getQuizAttemptsinOffline(resource.nid);
    if (quiz_attempts_offline > attempts) {
        attempts = quiz_attempts_offline;
    }
    var quiz_close = parseInt(this.allQuestions.quiz_close);
    var quiz_open = parseInt(this.allQuestions.quiz_open);
    var attempt_expired_quiz = this.allQuestions.attempt_expired_quiz ? this.allQuestions.attempt_expired_quiz : '0';
    var current_date = Math.round((getSyncedServerTime() / 1000));

    if (this.allQuestions.takes != 0 && attempts >= this.allQuestions.takes && this.allQuestions.pass_rate == 0
        && typeof quiz_attempt_data !== 'undefined' && quiz_attempt_data.is_evaluated == "1") {
        //if passrate is 0 for certificate quiz and attempts are crossed then show survey quiz message
        if (typeof mode != 'undefined' && mode != "null" && mode == "scormToolKit") {
            alert(SURVEY_QUIZ_MESSAGE + ' ' + this.allQuestions.title + '.', function () {
                CONTROLLER.loadView("HomePage");
                return false;
            });
        } else {
            Client.closeProgressBar();
            alert(SURVEY_QUIZ_MESSAGE + ' ' + this.allQuestions.title + '.');
        }
        return false;
    }
    if (this.allQuestions.takes != 0 && attempts >= this.allQuestions.takes) {
        if (CONTROLLER.activeViewName == "Course" || CONTROLLER.activeViewName == "Resource" || CONTROLLER.activeViewName == "HomePage" || (typeof mode != 'undefined' && mode != "null" && mode == "scormToolKit")) {
            if (Platform.getNetworkState() == true) {
                SINGLE_QUESTION = false;
                LOAD_QUIZ_ONLINE = false;
                SYNCED_QUESTION_DATA = false;
            }
            var status = this.showQuizResult(this.allQuestions.nid, "", attempts, t("QUIZ_ATTEMPTS_EXCEEDED", true), quiz_attempt_data, this.allQuestions.type);
            if (status == true) {
                return false;
            }
        } else {
            Client.closeProgressBar();
            alert(t("QUIZ_ATTEMPTS_EXCEEDED", true));
            return false;
        }
    }
    if (this.allQuestions.quiz_always != '1' && quiz_open != 0 && quiz_open > current_date) {
        var openDate = new Date((parseInt(quiz_open)) * 1000).toString();
        var openDateIndex = openDate.lastIndexOf(':') + 3;
        var closeDate = new Date((parseInt(quiz_close)) * 1000).toString();
        var closeDateIndex = closeDate.lastIndexOf(':') + 3;
        alert(t("QUIZ_FUTURE_DATE_ERROR_MESSAGE", true) + openDate.substring(0, openDateIndex) + t(' till ') + closeDate.substring(0, closeDateIndex));
        Client.closeProgressBar();
        return false;
    }
    if (this.allQuestions.quiz_always != '1' && ((quiz_close != 0 && attempt_expired_quiz != '1' && quiz_close < current_date) || (quiz_open != 0 && quiz_open > current_date))) {
        var closeDate = new Date((parseInt(quiz_close)) * 1000).toString();
        var closeDateIndex = closeDate.lastIndexOf(':') + 3;
        alert(t("QUIZ_EXPIRED_DATE_ERROR_MESSAGE", true) + closeDate.substring(0, closeDateIndex));
        Client.closeProgressBar();
        return false;
    }

    var contentUtils = CONTROLLER.getService("ContentUtils");
    var isFileAvailable = contentUtils.isFileAvailable(resource.nid, resource.changed, "", "quiz");

    var quizView = new QuizView();
    if(Platform.getNetworkState() == true){
        CONTROLLER.setVariable("quiz_attempt_"+resource.nid, quiz_attempts);
    }
    var quiz_attempts_apm = CONTROLLER.getVariable("quiz_attempt_"+resource.nid);
    if (onlineContent) {
        //checking and prompting the user to switch to airplane mode
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
            var prevView = CONTROLLER.getVariable("previousView");
            var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
            var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
            if (!device_in_airplane_mode) {
                var message = '<p style="text-align: left;">1. Switching to a different application is restricted while taking quiz. If you switch to a different application more than 3 times, quiz will be submitted automatically.</p><p style="text-align: left;">2. Device needs to be switched to Airplane Mode. You can go to settings and enable Airplane mode or Click on OK to take you to settings for enabling Airplane mode.</p>';
                if (isFileAvailable.filestatus == "Not Available") {
                    // contentUtils.fileDownload(this.allQuestions, "", "", this.allQuestions.title + t(" downloaded successfully"), "", OBJECT_QUIZ, "");
                    Platform.setPreference(this.allQuestions.nid, JSON.stringify(this.allQuestions));
                    var questions = this.quiz.getQuestionsForQuiz(this.allQuestions.nid, this.allQuestions.changed);
                    if (questions != "") {
                        var questions_for_quiz = "questions_for_quiz_" + this.allQuestions.nid;
                        Platform.setPreference(questions_for_quiz, JSON.stringify(questions));
                        var quizView = new QuizView();
                        if (quiz_result == '' || quiz_result === null) {
                            quiz_result = { 'quiz_result': '' };
                        }
                    }
                    message = '<p style="text-align: left;">1. Switching to a different application is restricted while taking quiz. If you switch to a different application more than 3 times, quiz will be submitted automatically.</p><p style="text-align: left;">2. Device needs to be switched to Airplane Mode. You can go to settings and enable Airplane mode or Click on OK to take you to settings for enabling Airplane mode.</p>';
                }
                Client.closeProgressBar();
                confirm(t(message), function () {
                    if (quiz_attempts_apm['attempts']['total_attempts'] > 0 && typeof quiz_attempts_apm['attempts']['quiz_attempt_data'] != 'undefined') {
                        for (let i = 0; i < quiz_attempts_apm['attempts']['quiz_attempt_data'].length; i++) {
                            if (quiz_attempts_apm['attempts']['quiz_attempt_data'][i]['is_evaluated'] == 0) {
                                var inProgress = i;
                                break;
                            }
                        }
                        if(typeof inProgress != 'undefined'){
                            var resultid = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['result_id'];
                            if (quiz_result !== '' || quiz_result !== null) {
                                THIS.getResults(quiz_result);
                            }
                            THIS.allQuestions['quiz_result']['gen_result_id'] = resultid;
                        }else{
                            THIS.allQuestions['quiz_result'] = '';
                            THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                            var time_start = getSyncedServerTime();
                            THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                            var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], 'false'); //resumeQuiz = false
                        } 
                    } else if (quiz_attempts_apm['attempts']['total_attempts'] == 0) {
                        THIS.allQuestions['quiz_result'] = '';
                        THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                        var time_start = getSyncedServerTime();
                        THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                        var resumeQuiz = 'false';
                        var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], resumeQuiz)
                    }
                    var status = THIS.updateResultId(resultid, THIS.allQuestions);
                    if (status == false) {
                        return false;
                    }
                    CONTROLLER.setVariable("airplane_mode_settings", "true");
                    Platform.enableAirplaneMode();
                });
                var quiz_id = "quiz_" + resource.nid;
                var question_for_quiz = "ques_for_quiz_" + resource.nid;
                CLICKED_QUIZ = Platform.getPreference(quiz_id);
                return false;
            } else {
                if (quiz_attempts_apm['attempts']['total_attempts'] > 0 && typeof quiz_attempts_apm['attempts']['quiz_attempt_data'] != 'undefined') {
                    for (let i = 0; i < quiz_attempts_apm['attempts']['quiz_attempt_data'].length; i++) {
                        if (quiz_attempts_apm['attempts']['quiz_attempt_data'][i]['is_evaluated'] == 0) {
                            var inProgress = i;
                            break;
                        }
                    }
                    if(typeof inProgress != 'undefined'){
                        var resultid = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['result_id'];
                        if (quiz_result !== '' || quiz_result !== null) {
                            THIS.getResults(quiz_result);
                        }
                        THIS.allQuestions['quiz_result']['gen_result_id'] = resultid;
                    }else{
                        THIS.allQuestions['quiz_result'] = '';
                        THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                        var time_start = getSyncedServerTime();
                        THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                        var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], 'false'); //resumeQuiz = false
                    } 
                } else if (quiz_attempts['attempts']['total_attempts'] == 0) {
                    THIS.allQuestions['quiz_result'] = '';
                    THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                    var time_start = getSyncedServerTime();
                    THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                    var resumeQuiz = 'false';
                    var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], resumeQuiz)
                }
                var status = THIS.updateResultId(resultid, THIS.allQuestions);
                return true;
            }
        } else {
            return true;
        }
    }

    //checking and prompting the user to switch to airplane mode
    if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
        var prevView = CONTROLLER.getVariable("previousView");

        var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
        var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
        if (!device_in_airplane_mode) {
            var message = '<p style="text-align: left;">1. Switching to a different application is restricted while taking quiz. If you switch to a different application more than 3 times, quiz will be submitted automatically.</p><p style="text-align: left;">2. Device needs to be switched to Airplane Mode. You can go to settings and enable Airplane mode or Click on OK to take you to settings for enabling Airplane mode.</p>';
            if (isFileAvailable.filestatus == "Not Available" && typeof prevView != 'undefined' && prevView != 'TestSeries') {
                // contentUtils.fileDownload(this.allQuestions, "", "", this.allQuestions.title + t(" downloaded successfully"), "", OBJECT_QUIZ, "");
                Platform.setPreference(this.allQuestions.nid, JSON.stringify(this.allQuestions));
                var questions = this.quiz.getQuestionsForQuiz(this.allQuestions.nid, this.allQuestions.changed);
                if (questions != "") {
                    var questions_for_quiz = "questions_for_quiz_" + this.allQuestions.nid;
                    Platform.setPreference(questions_for_quiz, JSON.stringify(questions));

                    if (quiz_result == '' || quiz_result === null) {
                        quiz_result = { 'quiz_result': '' };
                    }
                }
                message = '<p style="text-align: left;">1. Switching to a different application is restricted while taking quiz. If you switch to a different application more than 3 times, quiz will be submitted automatically.</p><p style="text-align: left;">2. Device needs to be switched to Airplane Mode. You can go to settings and enable Airplane mode or Click on OK to take you to settings for enabling Airplane mode.</p>';
            }
            Client.closeProgressBar();
            confirm(t(message), function () {
                if (quiz_attempts_apm['attempts']['total_attempts'] > 0 && typeof quiz_attempts_apm['attempts']['quiz_attempt_data'] != 'undefined') {
                    for (let i = 0; i < quiz_attempts_apm['attempts']['quiz_attempt_data'].length; i++) {
                        if (quiz_attempts_apm['attempts']['quiz_attempt_data'][i]['is_evaluated'] == 0) {
                            var inProgress = i;
                            break;
                        }
                    }
                    if(typeof inProgress != 'undefined'){
                        var resultid = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['result_id'];
                        if (quiz_result !== '' || quiz_result !== null) {
                            THIS.getResults(quiz_result);
                        }
                        THIS.allQuestions['quiz_result']['gen_result_id'] = resultid;
                    }else{
                        THIS.allQuestions['quiz_result'] = '';
                        THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                        var time_start = getSyncedServerTime();
                        THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                        var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], 'false'); //resumeQuiz = false
                    } 
                } else if (quiz_attempts_apm['attempts']['total_attempts'] == 0){
                    THIS.allQuestions['quiz_result'] = '';
                    THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                    var time_start = getSyncedServerTime();
                    THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                    var resumeQuiz = 'false';
                    var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], resumeQuiz)
                }
                var status = THIS.updateResultId(resultid, THIS.allQuestions);
                if (status == false) {
                    return false;
                }
                CONTROLLER.setVariable("airplane_mode_settings", "true");
                Platform.enableAirplaneMode();
            });
            Client.closeProgressBar();
            return false;
        } else {
            if (quiz_attempts_apm['attempts']['total_attempts'] > 0 && typeof quiz_attempts_apm['attempts']['quiz_attempt_data'] != 'undefined') {
                for (let i = 0; i < quiz_attempts_apm['attempts']['quiz_attempt_data'].length; i++) {
                    if (quiz_attempts_apm['attempts']['quiz_attempt_data'][i]['is_evaluated'] == 0) {
                        var inProgress = i;
                        break;
                    }
                }
                if(typeof inProgress != 'undefined'){
                    var resultid = quiz_attempts_apm['attempts']['quiz_attempt_data'][inProgress]['result_id'];
                    if (quiz_result !== '' || quiz_result !== null) {
                        THIS.getResults(quiz_result);
                    }
                    THIS.allQuestions['quiz_result']['gen_result_id'] = resultid;
                }else{
                    THIS.allQuestions['quiz_result'] = '';
                    THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                    var time_start = getSyncedServerTime();
                    THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                    var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], 'false'); //resumeQuiz = false
                } 
            } else if (quiz_attempts_apm['attempts']['total_attempts'] == 0){
                THIS.allQuestions['quiz_result'] = '';
                THIS.allQuestions['quiz_result'] = quizView.generate_results(THIS.allQuestions);
                var time_start = getSyncedServerTime();
                THIS.allQuestions['quiz_result']['gen_result_id'] = 'temp_quiz_' + THIS.allQuestions['nid'] + '_' + time_start;
                var resumeQuiz = 'false';
                var resultid = THIS.quiz.createResultId(THIS.allQuestions['nid'], THIS.allQuestions['quiz_result']['result_id'], resumeQuiz)
            }
            var status = THIS.updateResultId(resultid, THIS.allQuestions);
            if (status == false) {
                return false;
            }
            return true;
        }
    }
    if (USER_PRODUCT_TYPE == "offline") {
        if (isFileAvailable.filestatus == "Available") {
            return true;
        } else if (isFileAvailable.filestatus == "In Progress") {
            Client.closeProgressBar();
            alert("Quiz download in progress ");
            return false;
        } else if (isFileAvailable.filestatus == "Not Available") {
            Client.closeProgressBar();
            alert("Quiz not downloaded");
            return false;
        }
    } else if (USER_PRODUCT_TYPE == "hybrid" || USER_PRODUCT_TYPE == "online") {
        if (isFileAvailable.filestatus == "Available") {
            return true;
        } else if (isFileAvailable.filestatus == "In Progress") {
            if (Platform.getNetworkState() == true) {
                return true;
            } else {
                Client.closeProgressBar();
                alert("Quiz download in progress Please check your internet connection")
                return false;
            }

        } else if (isFileAvailable.filestatus == "Not Available") {
            if (Platform.getNetworkState() == true) {
                return true;
            } else {
                var quiz_meta = Platform.getPreference(resource.nid);
                quiz_meta = $.parseJSON(quiz_meta);
                if (quiz_meta != null && quiz_meta != "" && typeof quiz_meta != "undefined") {
                    if (resource.nid == quiz_meta.nid) {
                        return true;
                    }
                } else {
                    Client.closeProgressBar();
                    alert("Quiz not downloaded Please check your internet connection")
                    return false;
                }
            }
        }
    }
}
platformView.prototype.updateResultId = function (resultid, quiz, attemptLog) {
    var quizView = new QuizView();
    if (quizView.checkOnlineQuizResponse(resultid)) {
        if (attemptLog) {
            if (!quiz['quiz_result']['attempt_log']) {
                quiz['quiz_result']['attempt_log'] = "";
            }
            quiz['quiz_result']['attempt_log'] += attemptLog;
        }

        if (resultid.indexOf("temp_") > -1) {
            quiz['quiz_result'].result_id = resultid;
            return true;
        }
        if (this.gameMode != true && (typeof quiz['quiz_template'] == 'undefined' || quiz['quiz_template'] == '2')) {
            /**
             * 1. For a dated quiz with allow_offline_attempt or quiz_template=2, attempt is registered online
             * before starting the quiz. And from here on the attempt can be continued in offline.
             * 2. gen_result_id stores the value of the generated result id.
             * 3. gen_result_id needs to be replaced with result_id before submitting the quiz.
             */
            quiz['quiz_result']['gen_result_id'] = resultid;
            var temp_resultid = 'temp_quiz_' + quiz['nid'] + '_' + quiz['quiz_result']['time_start'];
            quiz['quiz_result']['result_id'] = temp_resultid;
            if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '') {
                quiz['quiz_result'].time_left = this.MAX_TIME;
            }
            if (quiz['quiz_result']) {
                this.quiz.quizResultSave(quiz['quiz_result'].result_id, quiz.nid, JSON.stringify(quiz['quiz_result']));
            }
        } else {
            quiz['quiz_result']['gen_result_id'] = resultid;
            quiz['quiz_result']['result_id'] = resultid;
            quizView.saveOnlineQuizResult();
        }
        return true;
    }
    return false;
};
platformView.prototype.getResults = function(quiz_result){
    if (quiz_result['quiz_result'] != '') {
        for (var i in quiz_result['quiz_result']) {
            if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                    this.allQuestions['quiz_result'] = quiz_result['quiz_result'][i];
                    this.allQuestions['quiz_result'].attempt_log = quiz_result['quiz_result'][i]['attempt_log'] ? quiz_result['quiz_result'][i]['attempt_log'] : "";
                    this.allQuestions['quiz_result'].result_id = quiz_result['quiz_result'][i].result_id;
                    this.allQuestions['quiz_result'].time_start = parseInt(quiz_result['quiz_result'][i].time_start);
            }
        }
    }
}

platformView.prototype.getGroupImage = function (title) {
    try {
        var userGroups = {};
        var groups = [];
        groups['groups'] = [];
        this.settings = CONTROLLER.getService("Settings");
        this.currentuser = this.settings.getLastLoggedinUser();
        userGroups['groups'] = OBJECT_USER_GROUPS;
        if (userGroups['groups'].length != 0) {
            for (var i = 0; i < userGroups['groups'].length; i++) {
                var group = Platform.getObjectProperty(OBJECT_GROUP, userGroups['groups'][i], "content");
                group = $.parseJSON(group);
                if (group != "" && group != null) {
                    var image = PROJECT_TITLE + '/images/' + group['title'].toLowerCase() + '.jpg';
                    if (Platform.isFileAvailableInAssets(image)) {
                        $('.title_text').addClass('hidden');
                        $('.title_image_wrapper').removeClass('hidden');
                        var imgpath = 'url(../images/' + group['title'].toLowerCase() + '.jpg)';
                        $('#title_image').css({ 'background-image': imgpath });
                        $(".title_image_wrapper").css("width", $(window).width() - 132);
                        break;
                    }
                }
            }
        }
    }
    catch (e) {
        console.log("function :- getGroupImage " + e.message);
    }
}


platformView.prototype.EndBreak = function () {
    try {
        $('.break-content').addClass('hidden');
        Client.showProgressBar(t("Loading") + "");
        $('#quiz_breaks_outer_wrapper').dialog("close");
        $('#quiz_breaks_outer_wrapper').remove();
        if ($('.player').length > 0) {
            if (!PlayerManager.isPlaying()) {
                PlayerManager.playVideo();
            }
        }
        if (typeof QUIZ_TIMER != 'undefined') {
            window.clearInterval(QUIZ_TIMER);
        }
        BREAK_MODE = 'false';
        if ($("#solution_embed_wrapper").length != 1) {
            $('.title-overlay-wrapper').removeClass('hidden');
        }
        BreakManager.closeBreak();
        Client.closeProgressBar();
    }
    catch (e) {
        console.log("function :- EndBreak " + e.message);
    }
}

platformView.prototype.renderTestCollection = function (params) {
    try {
        var THIS = this;
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, params.resourceid, "content"));
        var tcId = resource['url'];
        if (params['isLinkedObject']) {
            tcId = params.linkedobject_item;
        }
        var quizService = CONTROLLER.getService("Quiz");
        var resourceService = CONTROLLER.getService("Resource");
        var attempts = parseInt(resource.series_attempts);
        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
            if (Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                Client.closeProgressBar();
                return;
            }
        }
        Platform.saveObject(OBJECT_TEST_COLLECTION, tcId, "content", JSON.stringify(tcId));
        CONTROLLER.setVariable("activeresourceid", resource.nid);
        var testCollObj = quizService.getTestCollectionFromId(tcId);
        if (isNaN(attempts)) {
            attempts = testCollObj[0]["test_coll_max_attempts"];
        }
        var result = quizService.getTestCollectionQuizResult(tcId);
        var videosviewedincourse = "";
        if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
            if (VIDEOVIEW_HISTORY_VERSION != undefined && VIDEOVIEW_HISTORY_VERSION == "1.0") {
                videosviewedincourse = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, CONTROLLER.getVariable("activegroupid"), params.courseid, "course", OBJECT_VIDEO);
            }
            else {
                videosviewedincourse = Platform.getVideoViewHistoryByType(OBJECT_USER_ID, CONTROLLER.getVariable("activegroupid"), params.courseid, "course");
            }
        }
        else {
            videosviewedincourse = Platform.getVideoViewHistory(OBJECT_USER_ID, CONTROLLER.getVariable("activegroupid"), params.courseid, "course");
        }
        videosviewedincourse = $.parseJSON(videosviewedincourse);
        for (var k = 0; k < videosviewedincourse["videoviewed"].length; k++) {
            if (videosviewedincourse["videoviewed"][k].resourceid == params.resourceid) {
                if (videosviewedincourse["videoviewed"][k].status != "null") {
                    if (videosviewedincourse["videoviewed"][k].status == "completed") {
                        params.seen = "completed";
                    }
                    else {
                        params.seen = "inprogress";
                    }
                }
            }
        }

        resource.courseid = params.courseid;
        resource.catid = params.catid;
        resource.groupid = CONTROLLER.getVariable("activegroupid")
        resource.chapterid = params.chapterid;
        QUIZ_RESOURCE_PARAMS = resource;
        var test_Collection = resourceService.getResourceUrlFromResourceData(resource.nid, RESOURCE_TYPE_TEST_COLLECTION, resource.chapterid, resource.courseid, resource.catid, params['linkedobject_item']);
        var navigation = CONTROLLER.getVariable('linked_object_test_collection');
        CONTROLLER.setVariable('linked_object_test_collection', false);
        QUIZ_RESOURCE_PARAMS['test_collection'] = test_Collection;
        test_Collection['title'] = params.title;
        TEST_COLLECTION_MAX_ATTEMPTS = attempts;
        TEST_COLLECTION_USER_ATTEMPTS = test_Collection.attempts;
        if (test_Collection && test_Collection.attempts < attempts && test_Collection.quiznid != "" && result['quiz_result'].length != attempts) {
            CONTROLLER.setVariable("content_type", OBJECT_TEST_COLLECTION);
            var quiz = quizService.getQuiz(test_Collection.quiznid);
            var isQuizViewable = THIS.isQuizViewable(quiz, quiz.type);
            if (isQuizViewable) {
                Client.showProgressBar(t("Loading") + "...");
                var questions = quizService.getQuestionsForQuiz(quiz.nid, quiz.changed);
                if (questions != "" && questions['question'].length != 0) {
                    QUIZ_RESOURCE_PARAMS.quizid = test_Collection.quiznid;
                    resource.resourceid = resource.nid;
                    CONTROLLER.loadView("Resource", resource);
                } else {
                    alert(t("TEST_COLLECTION_ERROR", true));
                }
                Client.closeProgressBar();
            }
        } else if (test_Collection.quiznid == "" && test_Collection.attempts == 0) {
            if (navigation) {
                CONTROLLER.onBack();
                setTimeout(function () {
                    $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                    alert(t("TEST_COLLECTION_EMPTY", true));
                }, 1000);
            } else {
                $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                alert(t("TEST_COLLECTION_EMPTY", true));
            }
        } else if (test_Collection.quiznid == "" && test_Collection.attempts <= attempts && params.seen == 'completed') {
            if (typeof test_Collection.result_ids != 'undefined' && test_Collection.result_ids != '') {
                if (navigation) {
                    CONTROLLER.onBack();
                    setTimeout(function () {
                        test_Collection['message'] = TEST_COLLECTION_CERTIFIED;
                        CONTROLLER.loadView("QuizAttemptDetails", test_Collection);
                    }, 1000);
                } else {
                    test_Collection['message'] = TEST_COLLECTION_CERTIFIED;
                    CONTROLLER.loadView("QuizAttemptDetails", test_Collection);
                }
            } else {
                if (navigation) {
                    CONTROLLER.onBack();
                    setTimeout(function () {
                        alert(t("TEST_COLLECTION_CERTIFIED", true));
                        $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                    }, 1000);
                } else {
                    alert(t("TEST_COLLECTION_CERTIFIED", true));
                    $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                }
            }
        } else if (test_Collection.quiznid == "" && test_Collection.attempts > attempts) {
            if (navigation) {
                CONTROLLER.onBack();
                setTimeout(function () {
                    $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                    alert(t("TEST_COLLECTION_ATTEMPTS_EXCEEDED", true));
                }, 1000);
            } else {
                $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                alert(t("TEST_COLLECTION_ATTEMPTS_EXCEEDED", true));
            }
        } else if (test_Collection == 'error') {
            if (navigation) {
                CONTROLLER.onBack();
                setTimeout(function () {
                    alert(t("TEST_COLLECTION_ERROR", true));
                    $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
                }, 1000);
            } else {
                alert(t("TEST_COLLECTION_ERROR", true));
                $("#topic_title_wrapper_" + resource.nid).css("opacity", "1");
            }
        } else if (typeof test_Collection.result_ids != 'undefined' && test_Collection.result_ids != '') {
            if (navigation) {
                CONTROLLER.onBack();
                setTimeout(function () {
                    CONTROLLER.loadView("QuizAttemptDetails", test_Collection);
                }, 1000);
            } else {
                CONTROLLER.loadView("QuizAttemptDetails", test_Collection);
            }
        } else {
            if (navigation) {
                CONTROLLER.onBack();
                setTimeout(function () {
                    alert(t("TEST_COLLECTION_ATTEMPTS_CROSSED", true));
                }, 1000);
            } else {
                alert(t("TEST_COLLECTION_ATTEMPTS_CROSSED", true));
            }
        }
        Client.closeProgressBar();
    }
    catch (e) {
        Client.closeProgressBar(e);
    }
};

platformView.prototype.getAllCategoriesforUserGroups = function () {
    var courseslength = "";
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    var categoryService = CONTROLLER.getService("Category");
    var settingsService = CONTROLLER.getService("Settings");
    var allcourses = [];
    allcourses[OBJECT_COURSE] = [];
    var allcourses;
    var courses = [];
    courses[OBJECT_COURSE] = [];
    var allcategories = {};
    allcategories[OBJECT_CATEGORY] = [];
    var allcourses = {};
    allcourses[OBJECT_COURSE] = [];
    if (EXEC_MODE == "online") {
        var categories = this.getGroupsWithCategories();
        if (categories[OBJECT_CATEGORY]) {
            for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
                courseslength = categories[OBJECT_CATEGORY][i][OBJECT_COURSE + '_count'];
                if (courseslength <= 1) {
                    categories[OBJECT_CATEGORY][i].course_title = " " + t(OBJECT_COURSE_TITLE.toLowerCase());
                }
                else {
                    categories[OBJECT_CATEGORY][i].course_title = " " + t(OBJECT_COURSE_TITLE.toLowerCase() + "s");
                }
                if (typeof categories[OBJECT_CATEGORY][i].courseslength != "undefined") {
                    categories[OBJECT_CATEGORY][i].courseslength = categories[OBJECT_CATEGORY][i].courseslength + courseslength;
                }
                else {
                    categories[OBJECT_CATEGORY][i].courseslength = courseslength;
                }
            }
        }
        allcategories[OBJECT_CATEGORY] = categories[OBJECT_CATEGORY];
    } else {
        if(Object.keys(ALL_CATEGORIES_JSON).length == 0){
            var categories = this.getGroupsWithCategories();
            for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
                categories[OBJECT_CATEGORY][i].posterpath = contentUtilsService.getPosterPath(categories[OBJECT_CATEGORY][i].nid, categories[OBJECT_CATEGORY][i].thumbnail_filename, OBJECT_PRESET);
                if (!hasId(allcategories[OBJECT_CATEGORY], categories[OBJECT_CATEGORY][i].nid)) {
                    allcategories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][i]);
                }
                else {
                    for (var l = 0; l < allcategories[OBJECT_CATEGORY].length; l++) {
                        if (allcategories[OBJECT_CATEGORY][l].nid == categories[OBJECT_CATEGORY][i].nid) {
                            allcategories[OBJECT_CATEGORY][l].courseslength = allcategories[OBJECT_CATEGORY][l].courseslength + courseslength;
                        }
                    }
                }
            }
            ALL_CATEGORIES_JSON = allcategories;
            ALL_CATEGORIES_JSON = $.parseJSON(JSON.stringify(ALL_CATEGORIES_JSON));
        }else{
            allcategories = ALL_CATEGORIES_JSON;
            allcategories = $.parseJSON(JSON.stringify(allcategories));
            return allcategories;
        }
    }
    if(typeof POSTER_PATHS != 'undefined' && POSTER_PATHS.length > 0){
        Platform.downloadMultipleFile(JSON.stringify(POSTER_PATHS));
        POSTER_PATHS = [];
    }
    return allcategories;
};

platformView.prototype.getAllCategoryInfo = function (categories,from_buynow = false) {
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    var categoryService = CONTROLLER.getService("Category");
    var userService = CONTROLLER.getService("User");
    try {
        var allsubjectsview = this;
        var courses = "";
        var all_categories = {},
            groupid = "";
        all_categories[OBJECT_CATEGORY] = [];
        for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
            categories[OBJECT_CATEGORY][i].posterpath = contentUtilsService.getPosterPath(categories[OBJECT_CATEGORY][i].nid, categories[OBJECT_CATEGORY][i].thumbnail_filename, OBJECT_PRESET);
            if (HIDE_GROUPS_FILTER == "true") {
                groupid = OBJECT_USER_GROUPS.join();
            } else {
                groupid = CONTROLLER.getVariable("activegroupid");
            }
            if (EXEC_MODE == 'online' && typeof categories[OBJECT_CATEGORY][i][OBJECT_COURSE + '_count'] != "undefined") {
                var courseslength = categories[OBJECT_CATEGORY][i][OBJECT_COURSE + '_count'];
                categories[OBJECT_CATEGORY][i]['courseslength'] = courseslength;
            } else {
                if(EXEC_MODE == 'online'){
                    var courses = "";
                    courses = categoryService.getCoursesForCategory(categories[OBJECT_CATEGORY][i].nid, groupid);
                    var courseslength = courses[OBJECT_COURSE].length;
                }else{
                    var courseslength = categoryService.getCourseLengthForCategory(groupid, categories[OBJECT_CATEGORY][i].nid);
                }
            }
            categories[OBJECT_CATEGORY][i].courseslength = courseslength;
            if (courseslength <= 1) {
                categories[OBJECT_CATEGORY][i].course_title = " " + t(OBJECT_COURSE_TITLE.toLowerCase());
            } else {
                categories[OBJECT_CATEGORY][i].course_title = " " + t(OBJECT_COURSE_TITLE.toLowerCase() + "s");
            }
            if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true" && (typeof HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES == "undefined" || (typeof HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES != "undefined" && HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES == "false"))
            ||(typeof HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES == "undefined" || (typeof HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES != "undefined" && HIDE_LOCK_ICON_UNSUBSCRIBED_CATEGORIES == "false") && 
            typeof from_buynow != "undefined" && from_buynow != "" &&  from_buynow == true)) {
                if (typeof categories[OBJECT_CATEGORY][i]['subscribed'] == "undefined" || categories[OBJECT_CATEGORY][i]['subscribed'] == "false") {
                    if (typeof HIDE_CATEGORY_LOCK != 'undefined' && !HIDE_CATEGORY_LOCK) {
                        categories[OBJECT_CATEGORY][i].lock_category_img = "/platform/images/lock-icon.ce7d58e3.png";
                    } else {
                        categories[OBJECT_CATEGORY][i].lock_category_img = "";
                    }
                    categories[OBJECT_CATEGORY][i].subscribed = "false";
                } else {
                    all_categories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][i]);
                }
            }
        }
    
        if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
            for (var j = 0; j < categories[OBJECT_CATEGORY].length; j++) {
                if (jsonArrayHasId(all_categories[OBJECT_CATEGORY], categories[OBJECT_CATEGORY][j].nid) == false) {
                    all_categories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][j]);
                }
            }
            return all_categories;
        } else {
            return categories;
        }
    } catch (e) {
        console.log("function : getAllCategoryInfo - " + e.message);
    }
};

platformView.prototype.checkMultipleParentGroups = function (getdata) {
    try {
        var count = 0; var maingroup = [];
        for (var j = 0; j < MAIN_GROUPS.length; j++) {
            if (jQuery.inArray(MAIN_GROUPS[j]['nid'], OBJECT_USER_DATA.groups) != -1) {
                maingroup.push(MAIN_GROUPS[j]['nid']);
                count++;
            }
        }
        if (count == 2) {
            $('#right-menu-choose-class').removeClass('hidden');
        }
        if (getdata && getdata == "groupinfo") {
            return maingroup;
        } else {
            return count;
        }
    }
    catch (e) {
        console.log("function :- checkMultipleParentGroups " + e.message);
    }
}

platformView.prototype.setActiveGroupidFromMainGroups = function () {
    try {
        var active_group = CONTROLLER.getVariable("activegroupid");
        var main_groups = this.checkMultipleParentGroups();
        var main_group_info = this.checkMultipleParentGroups("groupinfo");
        if (main_groups == 1) {
            if (main_group_info) {
                CONTROLLER.setVariable("activegroupid", main_group_info[0]);
            }
        } else if (main_groups > 1) {
            if (!active_group) {
                CONTROLLER.setVariable("activegroupid", main_group_info[0]);
            }
        } else if (main_group_info.length != 0) {
            CONTROLLER.setVariable("activegroupid", main_group_info[0]);
        } else {
            CONTROLLER.setVariable("activegroupid", OBJECT_USER_GROUPS[0]);
        }
    }
    catch (e) {
        console.log("function :- checkMultipleParentGroups " + e.message);
    }
}



platformView.prototype.orderUserGroups = function (groups) {
    for (var i = 0; i < groups.length; i++) {
        if (i == 0 && OBJECT_USER_GROUPS.length > 1) {
            var group = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, groups[i], "content"));
            if (group != null && typeof group.title != 'undefined') {
                if (group.title.search("Class XII") > -1 || group.title.search("Class 12") > -1) {
                    var temp = groups[1];
                    OBJECT_USER_GROUPS[1] = groups[0];
                    OBJECT_USER_GROUPS[0] = temp;
                }
            }
        }
    }
    return OBJECT_USER_GROUPS;
}

//get product subscribed categories (Enable if product subscription is required)
platformView.prototype.getSubscribedCourseCategories = function () {
    var subscribedCourseCategories = [];
    var product_categories = [];
    subscribedCourseCategories[OBJECT_CATEGORY] = [];
    var settingsService = CONTROLLER.getService("Settings");
    var userproduct = settingsService.getUserProducts(OBJECT_USER_ID);

    if (userproduct) {
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
        var courses = Platform.getObjectsOfType(OBJECT_COURSE);
        courses = $.parseJSON(courses);
        for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
            var user_sub = settingsService.getUserSubscriptions(OBJECT_USER_ID, "", courses[OBJECT_COURSE][i].nid, "");
            if (user_sub != "" && user_sub != null) {
                var courseCategories = courses[OBJECT_COURSE][i][OBJECT_CATEGORY];
                for (var k = 0; k < courseCategories.length; k++) {
                    if ($.inArray(courseCategories[k], product_categories) === -1) {
                        product_categories.push(courseCategories[k]);
                    }
                }
            }
        }
        for (var j = 0; j < product_categories.length; j++) {
            $.each(categories[OBJECT_CATEGORY], function (key, value) {
                if (value['nid'] == product_categories[j]) {
                    categories[OBJECT_CATEGORY][key]['subscription_type'] = 'course';
                    categories[OBJECT_CATEGORY][key]['subscribed'] = "true";
                    subscribedCourseCategories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][key]);
                }
            });
        }

    }
    return subscribedCourseCategories;
};

platformView.prototype.getSubscribedGroupsWithCategories = function (group) {
    var THIS = platformView.prototype;
    var categoryService = CONTROLLER.getService("Category");
    var courseService = CONTROLLER.getService("Course");
    var coursescount = "";
    var subscribedCategories = THIS.getSubscribedCategories(group); //get user subscribed categories.
    if (typeof PRODUCT_SUBSCRIPTION != "undefined" && PRODUCT_SUBSCRIPTION != "false") {
        var subscribedCourseCategories = THIS.getSubscribedCourseCategories(); //get product subscribed categories
    } else {
        var subscribedCourseCategories = []; subscribedCourseCategories[OBJECT_CATEGORY] = [];
    }
    var subscribedGroupWithCategories = []; var allSubscribedCategories = {}; var orderedallsubscribedcategories = {};
    subscribedGroupWithCategories[OBJECT_CATEGORY] = []; allSubscribedCategories[OBJECT_CATEGORY] = []; orderedallsubscribedcategories[OBJECT_CATEGORY] = [];
    if (group) {
        var usergroups = [];
        usergroups.push(group);
    } else {
        var usergroups = OBJECT_USER_GROUPS;
    }

    //get All groups categories
    if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
        var groupWithCategories = categoryService.getCategoriesForGroup(usergroups.join());
        for (var j = 0; j < groupWithCategories[OBJECT_CATEGORY].length; j++) {
            subscribedGroupWithCategories[OBJECT_CATEGORY].push(groupWithCategories[OBJECT_CATEGORY][j]);
        }
    }else{
        for (var i = 0; i < usergroups.length; i++) {
            //get group subscribed categories [Group Level Subscription Check].
            var user_sub = THIS.settings.getUserSubscriptions(OBJECT_USER_ID, "", "", usergroups[i]);
            if (user_sub != "" && user_sub != null && user_sub != "{}") {
                var groupWithCategories = CONTROLLER.getService("Category").getCategoriesForGroup(usergroups[i]);
                for (var j = 0; j < groupWithCategories[OBJECT_CATEGORY].length; j++) {
                    subscribedGroupWithCategories[OBJECT_CATEGORY].push(groupWithCategories[OBJECT_CATEGORY][j]);
                }
            }
        }
    }
    
    if (subscribedCategories[OBJECT_CATEGORY].length > 0) {
        for (var j = 0; j < subscribedCategories[OBJECT_CATEGORY].length; j++) {
            if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedCategories[OBJECT_CATEGORY][j].nid) == false) {
                allSubscribedCategories[OBJECT_CATEGORY].push(subscribedCategories[OBJECT_CATEGORY][j]);
            }
        }
    }
    if (subscribedCourseCategories[OBJECT_CATEGORY].length > 0) {
        for (var j = 0; j < subscribedCourseCategories[OBJECT_CATEGORY].length; j++) {
            if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedCourseCategories[OBJECT_CATEGORY][j].nid) == false) {
                allSubscribedCategories[OBJECT_CATEGORY].push(subscribedCourseCategories[OBJECT_CATEGORY][j]);
            }
        }
    }
    if (subscribedGroupWithCategories[OBJECT_CATEGORY].length > 0) {
        for (var j = 0; j < subscribedGroupWithCategories[OBJECT_CATEGORY].length; j++) {
            if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedGroupWithCategories[OBJECT_CATEGORY][j].nid) == false) {
                allSubscribedCategories[OBJECT_CATEGORY].push(subscribedGroupWithCategories[OBJECT_CATEGORY][j]);
            }
        }
    }
    allSubscribedCategories[OBJECT_CATEGORY] = sortByRank(allSubscribedCategories[OBJECT_CATEGORY]);
    for (var m = 0; m < allSubscribedCategories[OBJECT_CATEGORY].length; m++) {
        if (typeof allSubscribedCategories[OBJECT_CATEGORY][m]['subscribed'] != 'undefined' && allSubscribedCategories[OBJECT_CATEGORY][m]['subscribed'] == 'true') {
            orderedallsubscribedcategories[OBJECT_CATEGORY].push(allSubscribedCategories[OBJECT_CATEGORY][m]);
        }
    }
    for (var k = 0; k < allSubscribedCategories[OBJECT_CATEGORY].length; k++) {
        if (allSubscribedCategories[OBJECT_CATEGORY][k]['subscribed'] != 'true') {
            orderedallsubscribedcategories[OBJECT_CATEGORY].push(allSubscribedCategories[OBJECT_CATEGORY][k]);
        }
    }
    for (var l = 0; l < orderedallsubscribedcategories[OBJECT_CATEGORY].length; l++) {
        coursescount = courseService.getCoursesCount(orderedallsubscribedcategories[OBJECT_CATEGORY][l].nid, usergroups.join(","));
        orderedallsubscribedcategories[OBJECT_CATEGORY][l].courseslength = coursescount;
    }
    return orderedallsubscribedcategories;
};

//get user subscribed categories.[Category Level Subscription Check]
platformView.prototype.getSubscribedCategories = function (group) {
    try {
        var categoryService = CONTROLLER.getService("Category");
        var categories = {};
        if (HIDE_GROUPS_FILTER == "true") {
            group = "";
        }
        categories[OBJECT_CATEGORY] = categoryService.getUserSubscribedCategories(group);
        for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
            categories[OBJECT_CATEGORY][i]['subscribed'] = "true";
        }
        return categories;
    } catch (e) {
        console.log("function: getSubscribedCategories" + e.message);
    }
};

platformView.prototype.getResourcesForChapterByGroups = function (chapterid, courseid) {
    try {
        var chapter_resources = [], chapter_resources_nid = [];
        this.chapter = CONTROLLER.getService("Chapter");
        var userGroups = [];
        if (HIDE_GROUPS_FILTER == "true") {
            userGroups = OBJECT_USER_DATA.groups;
        }
        else {
            userGroups.push(CONTROLLER.getVariable("activegroupid"))
        }

        for (var i = 0; i < userGroups.length; i++) {
            var resources = this.chapter.getResourcesForChapter(chapterid,courseid, userGroups[i]);
            if (resources) {
                for (var j = 0; j < resources[OBJECT_VIDEO].length; j++) {
                    if ($.inArray(resources[OBJECT_VIDEO][j].nid, chapter_resources_nid) == -1) {
                        chapter_resources_nid.push(resources[OBJECT_VIDEO][j].nid)
                        chapter_resources.push(resources[OBJECT_VIDEO][j]);
                    }
                }
            }
        }
        resources[OBJECT_VIDEO] = chapter_resources;
        return resources;
    } catch (e) {
        Client.closeProgressBar();
        console.log("function :- getResourcesForChapterByGroups, message :- " + e.message);
    }
}

platformView.prototype.getContentGroupsForVideViedwedHistory = function () {
    var contentGroups = CONTROLLER.getVariable("contentGroups");
    var user_content_groups = [];
    for (var i = 0; i < contentGroups.length; i++) {
        user_content_groups.push(contentGroups[i].nid)
    }
    return user_content_groups;
}

platformView.prototype.getAllGroupsForVideoViewHistory = function () {
    var contentGroups = OBJECT_USER_DATA.groups;
    var user_content_groups = [];
    for (var i = 0; i < contentGroups.length; i++) {
        user_content_groups.push(contentGroups[i])
    }
    return user_content_groups;
}

platformView.prototype.getUserGroupsToBeShown = function (user_groups) {
    try {
        var group = ""; var groups = [], user_sub = "";
        var settings = CONTROLLER.getService("Settings");
        for (var i = 0; i < user_groups.length; i++) {
            group = Platform.getObjectProperty(OBJECT_GROUP, user_groups[i], "content");
            var usergroups = $.parseJSON(group);
            if (!usergroups || (typeof usergroups.status != "undefined" && usergroups.status == "0")) {
                continue;
            }
            if (typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION == "true") {
                user_sub = settings.getUserSubscriptions(OBJECT_USER_ID, "", "", user_groups[i]);
            }
            if (typeof GROUP_SUBSCRIPTION == "undefined" ||
                (typeof GROUP_SUBSCRIPTION != "undefined" && GROUP_SUBSCRIPTION == "true" && user_sub != "" && user_sub != null && user_sub != "{}")) {

                if (typeof SHOW_ONLY_CONTENT_GROUPS != "undefined" && SHOW_ONLY_CONTENT_GROUPS == "true") {
                    if (group) {

                        if ((typeof usergroups.type != "undefined" && usergroups.type == "user") && (typeof MAIN_GROUPS == "undefined") && typeof SHOW_GROUPS_OF_TYPE_USER == 'undefined') {
                            group = "";
                        }
                    }
                }
                if (group != "") {
                    group = $.parseJSON(group);
                    if (typeof SHOW_GROUP_DISPLAY_NAME != "undefined" && SHOW_GROUP_DISPLAY_NAME == "true") {
                        if (PROJECT_TITLE.toLowerCase() == "takshashila") {
                            if (group.title.toLowerCase().indexOf("gcpp") >= 0) {
                                group.display_name = "GCPP";
                            } else if (group.title.toLowerCase().indexOf("pgp") >= 0) {
                                group.display_name = "PGP";
                            } else if (group.title.toLowerCase().indexOf("gcss") >= 0) {
                                group.display_name = "GCSS";
                            } else if (group.title.toLowerCase().indexOf("gctp") >= 0) {
                                group.display_name = "GCTP";
                            }
                        } else {
                            group.title = group.display_name;
                        }
                    } else {
                        group.title = group.title.split(/\s+/).slice(0, 2).join(" ");
                    }
                    groups.push(group);
                }
            }
        }
        return groups;
    }
    catch (e) {
        console.log(e.message + " function = getUserGroupsToBeShown");
    }

}

platformView.prototype.getAllCategoriesToRender = function (groupid) {
    var categories = [];
    var categoryService = CONTROLLER.getService("Category");
    if (typeof groupid == 'undefined' || groupid == '') {
        groupid = OBJECT_USER_GROUPS[0];
    }
    var allbatch_categories = {};
    allbatch_categories[OBJECT_CATEGORY] = [];
    if (HIDE_GROUPS_FILTER == "true") {
        if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
            categories = this.getSubscribedGroupsWithCategories();
            categories = this.getAllCategoryInfo(categories);
        } else {
            if (typeof SHOW_ALLSUBSCRIBED_CATEGORIES != "undefined" && SHOW_ALLSUBSCRIBED_CATEGORIES == "true") {
                categories[OBJECT_CATEGORY] = categoryService.getUserSubscribedCategories();
                categories = this.getAllCategoryInfo(categories);
            } else {
                categories = this.getAllCategoriesforUserGroups();
                categories = this.getAllCategoryInfo(categories);
            }

        }

    } else if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
        categories = this.getSubscribedGroupsWithCategories(groupid);
        categories = this.getAllCategoryInfo(categories);
    } else if (typeof SHOW_USERGROUP_CATEGORIES != "undefined" && SHOW_USERGROUP_CATEGORIES == "true") {
        var batches = CONTROLLER.getVariable("activebatches");
        if (!batches) {
            this.user.getUserGroupsData();
            var batches = CONTROLLER.getVariable("activebatches");
        }
        if (batches) {
            for (var i = 0; i < batches['batches'].length; i++) {
                categories = this.getSubscribedGroupsWithCategories(batches['batches'][i].nid);
                for (var j = 0; j < categories[OBJECT_CATEGORY].length; j++) {
                    categories[OBJECT_CATEGORY][j].batchid = batches['batches'][i].nid;
                    allbatch_categories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][j]);
                }
            }
            categories = allbatch_categories;
            categories = this.getAllCategoryInfo(categories);
        }
    } else {
        categories = this.getGroupsWithCategories(groupid);
        categories = this.getAllCategoryInfo(categories);
    }
    var categoriesWithCourses = [];
    for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
        if (categories[OBJECT_CATEGORY][i].courseslength > 0) {
            categoriesWithCourses.push(categories[OBJECT_CATEGORY][i]);
        }
    }
    categories[OBJECT_CATEGORY] = categoriesWithCourses;
    return categories;
}

platformView.prototype.showChapterDescription = function (desc, title, desc_title) {
    $('#chap_description_dialog').remove();
    $('body').append('<div id="chap_description_dialog" ></div>');
    var scrollposition = $(window).scrollTop();

    $('#chap_description_dialog').dialog({
        resizable: false,
        modal: true,
        height: ($(window).height()),
        width: ($(window).width()),
        open: function () {
            //window.scrollTo(0, scrollposition);
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": {
                text: t('Ok'),
                click: function () {
                    $(this).dialog("close");
                    $('#chap_description_dialog').remove();
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    return false;
                }
            }
        },
        close: function () {
            $(this).dialog("close");
            $('#chap_description_dialog').remove();
            $("#transparent_wrapper").css({
                "display": "none"
            });
            $('.ui-widget-overlay').removeClass('custom-overlay');
            return false;

        },
        dialogClass: 'dlgfixed',
        position: "center",
    });
    if (ENABLE_NEW_UI) {
        if (typeof title != "undefined" && title != "") {
            var text = '<div class="chap_description_text_wrapper"><div class="chap_title_text">'+ title + '</div><div class="close_desc"><img src="/platform/images/close_blue.657f8ddf.png" /></div>\n\
	                    </div>\n\
	                    <div class="chap_subtitle_text">' + desc_title + " " + t("Description") + '</div>\n\
	                    <div class="chap_description_text">\n\ ' + desc + '</div>';
        } else {
            var text = '<div class="chap_description_text"><div class="chap_description_text">\n\ ' + desc + '</div>';
        }
    } else {
        var text = '<div class="chap_description_text">\n\ ' + desc + '</div>';
    }

    $('#chap_description_dialog').html(text);

    $(".dlgfixed").center(false);

    $('#chap_description_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    if (ENABLE_NEW_UI) {
        $('.alert_wrapper_dialog').css("height", $(window).height());
        $("#chap_description_dialog").css("height", "100%");
    }
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({
        'z-index': '99999999',
        'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'top': '50%'
    });
    $(".ui-dialog-titlebar").hide();
    if (ENABLE_NEW_UI) {
        $(".ui-dialog").css({ "border-radius": "0px" });
        $(".ui-dialog-buttonpane").addClass("hidden");
        $('.close_desc').bind('click', function () {
            $('#chap_description_dialog').dialog('close');
        });
    } else {
        if ($(window).width() < 380) {
            $('html body .ui-dialog').css({ 'top': '53%' });
        }
        $(".ui-dialog-buttonset .ui-button").css("width", "100%");
    }
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    $(".close_desc").css("float", "right");
    $('.close_desc').bind('click', function () {
        $('#chap_description_dialog').dialog('close');
    });
}

platformView.prototype.checkUserSubscription = function (message) {
    var data = "{";
    var temp = "";
    temp = ' "uid" : "' + OBJECT_USER_ID + '"';
    data = data + temp + "}";
    var subscription = Platform.getUserSubscription(data);
    if (typeof DENY_HOMEPAGEDATA_ACCESS !== 'undefined' && DENY_HOMEPAGEDATA_ACCESS == 'true') {
        if (!subscription || subscription == "[]" || subscription == "{}") {
            $('#my_progress .panel,#forum .panel, #blogs .panel, #my_activities .panel, #announcements .panel').addClass('access_disabled');
        }
    }
    return subscription;
}

platformView.prototype.checkScheduleOfChaptersInCourse = function (batchSchedule, chapterid, showMessage) {
    var status = batchSchedule[OBJECT_COURSE].status;
    var bacthScheduleOfChapters = batchSchedule[OBJECT_COURSE].topic;
    var currentDate = Math.round((getSyncedServerTime() / 1000));
    if (typeof batchSchedule[OBJECT_COURSE].restrict_content_access != "undefined" && batchSchedule[OBJECT_COURSE].restrict_content_access == true) {
        for (var i in bacthScheduleOfChapters) {
            if (bacthScheduleOfChapters[i].nid === chapterid) {
                var chapterStartDate = bacthScheduleOfChapters[i].start_date;
                var chapterEndDate = bacthScheduleOfChapters[i].end_date;
                if (chapterEndDate != null)
                    chapterEndDate = parseInt(chapterEndDate) + 86399;
                var msg = 'The content is scheduled to be available';
                if ((chapterStartDate != null && chapterEndDate != null) && (chapterStartDate > currentDate || currentDate > chapterEndDate)) {
                    if ((currentDate > chapterStartDate && currentDate > chapterEndDate) && status != true && typeof showMessage == 'undefined') {
                        return true;
                    }
                    msg = msg + ' from ' + getFormattedDate(chapterStartDate);
                    msg = msg + ' to ' + getFormattedDate(chapterEndDate);
                } else if ((chapterStartDate != null && chapterEndDate == null) && (chapterStartDate > currentDate)) {
                    msg = msg + ' from ' + getFormattedDate(chapterStartDate);
                } else if ((chapterStartDate == null && chapterEndDate != null) && (chapterEndDate < currentDate)) {
                    /** if chapterStartDate is null don't consider end date - Changes done to match portal work flow */
                    //                    return true;//commented this and changed the implementation back where access is allowed only till the end date
                    if (currentDate > chapterEndDate && status != true && typeof showMessage == 'undefined') {
                        return true;
                    }
                    msg = msg + ' till ' + getFormattedDate(chapterEndDate);
                } else {
                    return true;
                }
                msg = msg + '.';
                if (typeof showMessage != 'undefined' && showMessage == true) {
                    alert(msg);
                }
                return false;
            }
        }
    } else if (typeof batchSchedule[OBJECT_COURSE].restrict_content_access != "undefined" && batchSchedule[OBJECT_COURSE].restrict_content_access != true && status != true) {
        var restrict_content_access = batchSchedule[OBJECT_COURSE].restrict_content_access;
        for (var i in bacthScheduleOfChapters) {
            if (bacthScheduleOfChapters[i].nid === chapterid) {
                var chapterStartDate = bacthScheduleOfChapters[i].start_date;
                var chapterEndDate = bacthScheduleOfChapters[i].end_date;
                if (chapterEndDate != null && restrict_content_access == true)
                    chapterEndDate = parseInt(chapterEndDate) + 86399;
                var msg = 'The content is scheduled to be available';
                if ((chapterStartDate != null && chapterEndDate != null) && (chapterStartDate > currentDate || currentDate > chapterEndDate)) {
                    if ((currentDate > chapterStartDate && currentDate > chapterEndDate) && status != true) {
                        return true;
                    }
                    msg = msg + ' from ' + getFormattedDate(chapterStartDate);
                    msg = msg + ' to ' + getFormattedDate(chapterEndDate);
                } else if ((chapterStartDate != null && chapterEndDate == null) && (chapterStartDate > currentDate)) {
                    msg = msg + ' from ' + getFormattedDate(chapterStartDate);
                } else if ((chapterStartDate == null && chapterEndDate != null) && (chapterEndDate < currentDate)) {
                    /** if chapterStartDate is null don't consider end date - Changes done to match portal work flow */
                    return true;
                    //                     if(currentDate > chapterEndDate && restrict_content_access != true && typeof showMessage == 'undefined'){
                    //                         return true;
                    //                     }
                    //                     msg = msg + ' till ' + getFormattedDate(chapterEndDate);
                } else {
                    return true;
                }
                msg = msg + '.';
                if (typeof showMessage != 'undefined' && showMessage == true) {
                    alert(msg);
                }
                return false;
            }
        }
    } else
        return true;
};

platformView.prototype.getGroupsByGlobalContentVisibility = function () {
    if (typeof GLOBAL_CONTENT_VISIBILITY == "undefined") {
        return false;
    }
    var contentGroups = [];
    var userGroups = [];
    var allGroups = OBJECT_USER_GROUPS_JSON;
    for (var i = 0; i < allGroups.length; i++) {
        if (allGroups[i]) {
            if (allGroups[i]['type'] == "content") {
                contentGroups.push(allGroups[i]['nid']);
            } else if (allGroups[i]['type'] == "user") {
                userGroups.push(allGroups[i]['nid']);
            }
        }
    }
    if (typeof GLOBAL_CONTENT_VISIBILITY != "undefined" && GLOBAL_CONTENT_VISIBILITY == "user") {
        return userGroups.length > 0 && userGroups;
    } else if (typeof GLOBAL_CONTENT_VISIBILITY != "undefined" && GLOBAL_CONTENT_VISIBILITY == "content") {
        return contentGroups.length > 0 && contentGroups;
    } else {
        return false;
    }
};


platformView.prototype.footerMethodClick = function () {

    var THIS = this;
    if(PROJECT_TITLE.includes("FinShiksha")){
        $(".footer_dashboard").unbind().bind('click', function () {
            Client.showProgressBar("Loading");
            CONTROLLER.loadView("HomePage");
        });
    
        $(".footer_home").unbind().bind('click', function () {
            Platform.navigate(BUY_NOW_DOMAIN);
        });
    
        $(".footer_about_us").unbind().bind('click', function () {
            CONTROLLER.loadView('InfoList');
        });
    
        $(".footer_course").unbind().bind('click', function () {
            Platform.navigate(BUY_NOW_DOMAIN + "/courses/");
        });
        $(".footer_mycourse").unbind().bind('click', function () {
            CONTROLLER.loadView('AllSubjects');
        });
    
        $('.footer_forum').unbind().bind('click', function () {
            CONTROLLER.setVariable("activecourseid", "");
            RENDER_FORUM_HEADERFILE = "true";
            if (typeof DISPLAY_CATEGORY_SPECIFIC_COURSES != 'undefined' && DISPLAY_CATEGORY_SPECIFIC_COURSES != 'true') {
                CONTROLLER.setVariable("globalforum", "true");
            }
            if (Platform.getNetworkState() == false) {
                if (typeof ENABLE_OFFLINE_FORUM == 'undefined' || ENABLE_OFFLINE_FORUM != 'false') {
                    var files = ["askanexpert/templates/compiled/askanexpert_header",
                        "askanexpert/templates/compiled/askanexpert_questionlist"];
                    require(files, function () {
                        CONTROLLER.setVariable("offlineForumMode", "true");
                        CONTROLLER.loadView('PostQuestion');
                    });
                } else {
                    alert(INTERNET_ERROR);
                    return;
                }
            } else if ($('#forum .panel').hasClass('access_disabled')) {
                alert(USER_SUBSCRIPTION_ERROR);
                return;
            }
            else {
                var activeview = CONTROLLER.activeViewName;
                if (activeview != 'ForumTopics') {
                    if (HISTORY.length != 0) {
                        if (HISTORY.indexOf('ForumTopics') > -1) {
                            for (var i = 0; i < HISTORY.length; i++) {
                                if (HISTORY[i] === 'ForumTopics') {
                                    HISTORY.splice(i, 1);
                                }
                            }
                            HISTORY.push('ForumTopics');
                        }
                        else {
                            HISTORY.push('ForumTopics');
                        }
                    }
                    loadMathJax(function () {
                        CONTROLLER.loadView("ForumTopics")
                    });
                }
            }
        });
    
        $(".footer_schedule").unbind().bind('click', function () {
            Platform.navigate(BUY_NOW_DOMAIN + "/webinars/");
        });
    
        $(".footer_myschedule").unbind().bind('click', function () {
            HomePageView.prototype.renderMyWebinars();
        });
    
        $(".footer_more").unbind().bind('click', function () {
            var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
            if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
            && load_subscibed_categories == true){
                SHOW_UNSUBSCRIBED_CATEGORIES = "true";
                CONTROLLER.setVariable('categories_listing_mode','')
            }
            var activeview = CONTROLLER.activeViewName;
            if (activeview != 'Menu') {
                if (HISTORY.length != 0) {
                    if (HISTORY.indexOf('Menu') > -1) {
                        for (var i = 0; i < HISTORY.length; i++) {
                            if (HISTORY[i] === 'Menu') {
                                HISTORY.splice(i, 1);
                            }
                        }
                        HISTORY.push('Menu');
                    }
                    else {
                        HISTORY.push('Menu');
                    }
                }
                CONTROLLER.loadView("Menu");
            }
        });
    }else{

    $(".footer_home").unbind().bind('click', function () {
        var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
        if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
        && load_subscibed_categories == true){
            SHOW_UNSUBSCRIBED_CATEGORIES = "true";
            CONTROLLER.setVariable('categories_listing_mode','')
        }
        CONTROLLER.setVariable('courses_for_flashcard', 'false')
        CONTROLLER.setVariable('categories_listing_mode', 'AllSubjects');
        if (!IS_APP_SHELL) {
            window.history.pushState({}, document.title, window.location.pathname);
        }
        Client.showProgressBar(t("Loading") + "...");
        THIS.unsetTaskQuiz();
        THIS.unsetTagDetails();
        CONTROLLER.loadView("HomePage");
    });
    
    $(".footer_course").unbind().bind('click', function () {
        var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
        if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
        && load_subscibed_categories == true){
            SHOW_UNSUBSCRIBED_CATEGORIES = "true";
            CONTROLLER.setVariable('categories_listing_mode','')
        }
        if (!IS_APP_SHELL) {
            window.history.pushState({}, document.title, window.location.pathname);
        }
        var mycourses = $(this).hasClass("footer_study") ? "mycourses" : "practice";
        if($(this).hasClass("footer_flashcard")){
            mycourses = "flash_card";
            if(Platform.getNetworkState() === true){
                CONTROLLER.setVariable('categories_listing_mode', 'flash_card');
            }else{
                alert(INTERNET_ERROR);
                return false;
            }
        }
        var activeMyCourseView = CONTROLLER.getVariable("mycourses");
        var activeview = CONTROLLER.activeViewName;
        if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
            if (mycourses == 'mycourses') {
                trackScreenEventDetails({ 'context': 'study', 'event_name': 'TB_TAP_STUDY' });
            } else {
                trackScreenEventDetails({ 'context': 'practice', 'event_name': 'TB_TAP_PRACTICE' });
            }
        }
        if (activeview != 'AllSubjects' || (activeview == 'AllSubjects' && (activeMyCourseView != activeview))) {
            CONTROLLER.setVariable("mycourses", mycourses);
            if (HISTORY.length != 0) {
                THIS.unsetTaskQuiz();
                if (HISTORY.indexOf('AllSubjects') > -1) {
                    for (var i = 0; i < HISTORY.length; i++) {
                        if (HISTORY[i] === 'AllSubjects') {
                            HISTORY.splice(i, 1);
                        }
                    }
                    HISTORY.push('AllSubjects');
                }
                else {
                    HISTORY.push('AllSubjects');
                }
            }
            setTimeout(function () {
                if(typeof LOAD_CHATBOT_CATEGORIES_LANDINGPAGE !== "undefined" && LOAD_CHATBOT_CATEGORIES_LANDINGPAGE === "true"){
                    // load all subjects to show all the categories
                    CONTROLLER.setVariable('loaded_chatbot', 'true');
                    CONTROLLER.setVariable('categories_listing_mode', 'chat_bot');
                    CONTROLLER.loadView('AllSubjects');
                    return;
                } else if(mycourses == "flash_card"){
                    CONTROLLER.setVariable('categories_listing_mode', 'flash_card');
                    CONTROLLER.loadView('AllSubjects');
                } else if (mycourses == "mycourses") {
                    if(APP_TYPE == "web"){
                        Client.showProgressBar(t("Loading") + "...");
                    }
                    CONTROLLER.setVariable('categories_listing_mode', 'AllSubjects');
                    CONTROLLER.loadView('AllSubjects');
                } else {
                    CONTROLLER.setVariable('categories_listing_mode', 'Practice');
                    CONTROLLER.loadView('AllSubjects');
                }
            });
        }
    });

    $('.footer_forum').unbind().bind('click', function () {
        THIS.handleForumClick();
    });

    $(".footer_schedule").unbind().bind('click', function () {
        var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
        if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
        && load_subscibed_categories == true){
            SHOW_UNSUBSCRIBED_CATEGORIES = "true";
            CONTROLLER.setVariable('categories_listing_mode','')
        }
        if (!IS_APP_SHELL) {
            window.history.pushState({}, document.title, window.location.pathname);
        }
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return '';
        } else {
            var activeview = CONTROLLER.activeViewName;
            if (activeview != 'Calender') {
                THIS.unsetTagDetails();
                if (HISTORY.length != 0) {
                    if (HISTORY.indexOf('Calender') > -1) {
                        for (var i = 0; i < HISTORY.length; i++) {
                            if (HISTORY[i] === 'Calender') {
                                HISTORY.splice(i, 1);
                            }
                        }
                        HISTORY.push('Calender');
                    }
                    else {
                        HISTORY.push('Calender');
                    }
                }
                CONTROLLER.loadView("Calender");
            }
        }
    });

    $(".footer_more").unbind().bind('click', function () {
        var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
        if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
        && load_subscibed_categories == true){
            SHOW_UNSUBSCRIBED_CATEGORIES = "true";
            CONTROLLER.setVariable('categories_listing_mode','')
        }
        if (!IS_APP_SHELL) {
            window.history.pushState({}, document.title, window.location.pathname);
        }
        var activeview = CONTROLLER.activeViewName;
        if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
            trackScreenEventDetails({ 'context': 'home more', 'event_name': 'TB_TAP_HOME_MORE' });
        }
        if (activeview != 'Menu') {
            THIS.unsetTagDetails();
            THIS.unsetTaskQuiz();
            if (HISTORY.length != 0) {
                if (HISTORY.indexOf('Menu') > -1) {
                    for (var i = 0; i < HISTORY.length; i++) {
                        if (HISTORY[i] === 'Menu') {
                            HISTORY.splice(i, 1);
                        }
                    }
                    HISTORY.push('Menu');
                }
                else {
                    HISTORY.push('Menu');
                }
            }
            CONTROLLER.loadView("Menu");
        }
    });

    $(".footer_all_course").unbind().bind('click', function () {
        if (Platform.getNetworkState() == true) {
            THIS.unsetTaskQuiz();
            THIS.unsetTagDetails();
            // $('.footer_home_image, .footer_course_image, .footer_forum_image, .footer_schedule_image, .footer_more_image').removeClass('active_tab');
            // $('.footer_all_course_image').addClass('active_tab');
            THIS.handleBuyNowClick();
        } else {
            alert(INTERNET_ERROR);
        }
    });

    $(".footer_all_courses_view").unbind().bind('click', function () {
        CONTROLLER.setVariable('tag_course_id', '');
        if (Platform.getNetworkState() == true) {
            $('.footer_course_image, .footer_forum_image, .footer_schedule_image, .footer_more_image,.footer_home_image').removeClass('active_tab');
            $('.footer_icons > p').css('color','#FFFFFF');
            THIS.handleBuyNowClick();
        }else{
            alert(INTERNET_ERROR);
        }
    });
    
    $(".footer_testseries").unbind().bind('click', function () {
        if (Platform.getNetworkState() == true) {
            THIS.unsetTaskQuiz();
            $('.footer_home_image, .footer_course_image, .footer_forum_image, .footer_schedule_image, .footer_more_image').removeClass('active_tab');
            $('.footer_all_course_image').addClass('active_tab');
            HomePageView.prototype.renderTestCollection();
        } else {
            alert(INTERNET_ERROR);
        }
    });

    $(".footer_my_favourites").unbind().bind('click', function () {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return '';
        } else {
            CONTROLLER.animateViewLoad = false;
            Client.showProgressBar(t("Loading") + "");
            CONTROLLER.loadView("Bookmarks");
        }
    });
}
}

platformView.prototype.setCourseFooterStyles = function () {
    if(PROJECT_TITLE.includes("FinShiksha")){
        $('.footer_dashboard_image, .footer_home_image, .footer_forum_image, .footer_schedule_image, .footer_more_image').removeClass('active_tab');
        $('.footer_dashboard_image').addClass('active_tab');
    }else{
        if(CONTROLLER.getVariable('categories_listing_mode') == 'flash_card'){
            $('.footer_home_image, .footer_forum_image, .footer_schedule_image, .footer_more_image, .footer_course_image, .footer_flash_image').removeClass('active_tab');
            $('.footer_flash_image').addClass('active_tab');
        } else if (CONTROLLER.getVariable('categories_listing_mode') == 'Practice') {
            $('.footer_home_image, .footer_forum_image, .footer_schedule_image, .footer_more_image, .footer_course_image, .footer_flash_image').removeClass('active_tab');
            $('.footer_practice_image').addClass('active_tab');
        } else if (CONTROLLER.getVariable('categories_listing_mode') == 'all_courses') {
            $('.footer_home_image, .footer_forum_image, .footer_schedule_image, .footer_more_image, .footer_course_image, .footer_flash_image').removeClass('active_tab');
            $('.footer_all_courses_image').addClass('active_tab');
            $('.footer_all_courses_view  p').addClass('active_color');
        }
        else {
            $('.footer_home_image, .footer_forum_image, .footer_schedule_image, .footer_more_image, .footer_flash_image').removeClass('active_tab');
            $('.footer_course_image').addClass('active_tab');
            $('.footer_course p').addClass('active_color');
        }
    }
};

platformView.prototype.getCompletionBySubject = function (category, groupid, callback) {
    try {
        var categoryService = CONTROLLER.getService("Category");
        var settingsService = CONTROLLER.getService("Settings");
        if (EXEC_MODE == "online") {
            var catid = category.nid;
            if (HIDE_GROUPS_FILTER == "true") {
                groupid = OBJECT_USER_GROUPS.join(",");
            } else {
                groupid = CONTROLLER.getVariable("activegroupid");
            }
            if (typeof callback == "undefined") {
                var resourcescount = categoryService.getResourcesCount(category.tid, "", groupid, filetype, type);
                var videoviewcount = categoryService.getCompletedVideosOfSubjectCount(groupid, category.tid, filetype, type);

                var comple_per = 0;
                if (resourcescount > 0) {
                    comple_per = Math.ceil(((videoviewcount / resourcescount) * 100));
                }
                if (comple_per > 100) {
                    comple_per = 100;
                }
                return { 'nid': catid, 'watched': comple_per, 'watchedTotal': videoviewcount, 'total': resourcescount };
            }
            categoryService.getResourcesCount(category.tid, "", groupid, filetype, type, function (resourcescount) {
                categoryService.getCompletedVideosOfSubjectCount(groupid, category.tid, filetype, type, function (videoviewcount) {
                    var comple_per = 0;
                    if (resourcescount > 0) {
                        comple_per = Math.ceil(((videoviewcount / resourcescount) * 100));
                    }
                    if (comple_per > 100) {
                        comple_per = 100;
                    }
                    callback({ 'nid': catid, 'watched': comple_per, 'watchedTotal': videoviewcount, 'total': resourcescount });
                });
            });
        } else {
            var catid = category.nid;
            var batchid = "";
            if (typeof category.batchid != 'undefined') {
                batchid = category.batchid;
            }
            var comple_per = 0;
            if (typeof IGNORE_PARAMS_IN_SHOWING_PROGRESS != "undefined" && IGNORE_PARAMS_IN_SHOWING_PROGRESS == "true") {
                var filetype = "pdf";
                var type = "Document";
            } else {
                var ignore_params = "";
            }
            if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true') {
                var subscribedcourses = [];
                for (var k = 0; k < OBJECT_USER_GROUPS.length; k++) {
                    var courses = categoryService.getCoursesForCategory(catid, OBJECT_USER_GROUPS[k]);
                    for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
                        if (!subscribedcourses.includes(courses[OBJECT_COURSE][i].nid)) {
                            subscribedcourses.push(courses[OBJECT_COURSE][i].nid);
                        }
                    }
                }
            }
            if (typeof SHOW_ALL_USERCONTENT_GROUPS_COUNT != "undefined" && SHOW_ALL_USERCONTENT_GROUPS_COUNT == "true") {
                var content_groups_array = this.getContentGroupsForVideViedwedHistory();
                var content_groups = content_groups_array.length ? ('"' + content_groups_array.join('","') + '"') : "";
                var resourcescount = categoryService.getResourcesCount(catid, "", content_groups, filetype, type);
            } else if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true') {
                var allCourses = subscribedcourses.length ? subscribedcourses.join(",") : "";
                var resourcescount = this.course.getResourcesCountByType(allCourses, groupid, filetype, type);
            } else {
                var filter = '[{"object_type":"' + OBJECT_COURSE + '"},{"object_type":"' + OBJECT_CHAPTER + '"},{"object_type":"' + OBJECT_VIDEO + '"}]';
                var resourcescount = Platform.getRelationObjectCount(groupid, OBJECT_CATEGORY, catid, filter, ignore_params,true);
            }
            if (typeof GET_COMPLETED_VIDEOS != "undefined" && GET_COMPLETED_VIDEOS == true) {
                if (typeof USERGROUP_SPECIFIC_VVH != "undefined" && USERGROUP_SPECIFIC_VVH == "true") {
                    var videoviewcount = categoryService.getCompletedVideosOfSubjectCount(batchid, catid, filetype, type);
                } else {
                    if (HIDE_GROUPS_FILTER == "true" || (typeof SHOW_UNIQUE_GROUP_DATA != 'undefined' && SHOW_UNIQUE_GROUP_DATA == "true")) {
                        groupid = "";
                    }
                    if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true') {
                        var allCourses = subscribedcourses.length ? subscribedcourses.join(",") : "";
                        var videoviewcount = this.course.getCompletedVideosOfCourseCount(groupid, allCourses, filetype, type);
                    } else {
                        var videoviewcount = categoryService.getCompletedVideosOfSubjectCount(groupid, catid, filetype, type);
                    }
                }
            } else {
                groupid = "";
                if (typeof PLATFORM_VERSION != "undefined" && PLATFORM_VERSION == 4.0) {
                    var videoviewcount = Platform.getVideoViewHistoryCountByType(OBJECT_USER_ID, groupid, catid, "subject");
                }
                else {
                    videoviewcount = Platform.getVideoViewHistoryCount(OBJECT_USER_ID, groupid, catid, "subject");
                }
            }

            if (resourcescount > 0) {
                var comple_per = Math.ceil(((videoviewcount / resourcescount) * 100));
            }

            if (comple_per > 100) {
                comple_per = 100;
            }
            return { 'nid': catid, 'watched': comple_per, 'watchedTotal': videoviewcount, 'total': resourcescount };
        }
    }
    catch (e) {
        console.log(e.message + " function = getCompletionBySubject");
    }
};

platformView.prototype.registrationMessage = function (msg) {
    window.removeEventListener("message", this.registrationMessage);
    if (msg.data.message == 'verification_success' || msg.data.message == 'registration_success') {
        loginUser(msg.data);
    }
}

platformView.prototype.handleProdInfoClick = function (allProductsInfo, catId) {
    try {
        if (typeof BUY_NOW_URL_TYPE != "undefined" && BUY_NOW_URL_TYPE == "redirect") {
            if (!catId && CONTROLLER.getVariable("activecatid")) {
                var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, CONTROLLER.getVariable("activecatid"), "content"));
                catId = category["tid"];
                var prodBuyNowUrl = BUY_NOW_PRODUCT_INFO + catId + "&" + LOCAL_APP_PATH;
                this.handleBuyNowClick(prodBuyNowUrl);
            } else {
                this.handleBuyNowClick(BUY_NOW_URL);
            }
            return;
        }
        if (!allProductsInfo) {
            allProductsInfo = CONTROLLER.getVariable("allProductsInfo");
            Client.showProgressBar(t("Loading") + "...");
            if (!allProductsInfo) {
                var THIS = this;
                var catService = CONTROLLER.getService("Category");
                catService.getAllProducts(function (data) {
                    if (data != RESOURCE_ERROR) {
                        CONTROLLER.setVariable("allProductsInfo", data);
                        THIS.handleProdInfoClick(data, catId);
                    } else {
                        alert("Product Info not available.");
                        Client.closeProgressBar();
                    }
                });
                return;
            }
        }
        if (!catId) {
            var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, CONTROLLER.getVariable("activecatid"), "content"));
            catId = category["tid"];
        }
        var highPrice = 0, prodTitle = "";
        for (var j = 0; j < allProductsInfo.length; j++) {
            if (allProductsInfo[j]["subjects"]) {
                var isPresent = findIndexByKeyValue(allProductsInfo[j]["subjects"], "nid", catId)
                if (isPresent != null) {
                    var prodSubs = allProductsInfo[j]["online"];
                    for (var i in prodSubs) {
                        if (highPrice < parseInt(prodSubs[i]["price"])) {
                            highPrice = parseInt(prodSubs[i]["price"]);
                            prodTitle = allProductsInfo[j]["title"];
                        }
                    }
                }
            }
        }
        prodTitle = prodTitle.replace(/[^a-z0-9]+/gi, "-");
        if (prodTitle) {
            var prodBuyNowUrl = BUY_NOW_PRODUCT_INFO + prodTitle + "/?" + LOCAL_APP_PATH;
            this.handleBuyNowClick(prodBuyNowUrl);
        } else {
            alert("Product Info not available.");
            Client.closeProgressBar();
        }
    } catch (e) {
        console.log("function : handleInfoClick - " + e.message);
    }
};
platformView.prototype.getVideoFeedback = function (callback) {
    try {
        if (typeof ENABLE_FEEDBACK != "undefined" && ENABLE_FEEDBACK == true) {
            var videoFeedbackData = CONTROLLER.getVariable("videoFeedbackData");
            if (videoFeedbackData) {
                callback(videoFeedbackData);
            } else {
                var resourceService = CONTROLLER.getService("Resource");
                resourceService.getVideoFeedback(function (feedback) {
                    if (feedback != "error") {
                        CONTROLLER.setVariable("videoFeedbackData", feedback);
                        callback(feedback);
                    } else {
                        callback(false);
                    }
                });
            }
        } else {
            callback(false);
        }
    } catch (e) {
        console.log("platformView - getVideoFeedback :" + e);
    }
};
platformView.prototype.saveVideoFeedback = function (nid, status, feedback_value) {
    var resourceService = CONTROLLER.getService("Resource");
    var fid = 'temp_videofeedback_' + nid;
    if (feedback_value == '') {
        feedback_value = ''
        feedback_value = { 'comments': [] };
        feedback_value['vid'] = nid;
        feedback_value['uid'] = OBJECT_USER_ID;
        feedback_value['status'] = status;
        feedback_value['feedback_id'] = fid;
        feedback_value['time'] = Math.floor(Date.now() / 1000);
    }
    resourceService.videoFeedbackSave(JSON.stringify(feedback_value), function (response) {
        if (response != "error") {
            var feedbackIndex = null;
            var oldFeedback = CONTROLLER.getVariable("videoFeedbackData");
            if (isArray(oldFeedback)) {
                feedbackIndex = findIndexByKeyValue(oldFeedback, "vid", nid);
                feedback_value['feedback_id'] = response[0];
            } else {
                oldFeedback = [];
                feedbackIndex = 0;
                feedback_value['feedback_id'] = response[0];
            }
            if (feedbackIndex != null) {
                oldFeedback[feedbackIndex] = feedback_value;
            } else {
                oldFeedback.push(feedback_value);
            }
            CONTROLLER.setVariable("videoFeedbackData", oldFeedback);
        } else {
            alert(t(ERROR_MESSAGE));
            $('#thankyou_overlay,#videofeedback_thankyou_wrapper').remove();
        }
    });

};
platformView.prototype.onVideoFeedbackClick = function () {
    var THIS = this;
    $(".feedback_img").unbind('click').click(function (e) {
        var imgid = $(this).attr("id");
        var status = $(this).attr("status");
        var nid = $(this).attr("nid");
        if (imgid == 'vd_likeimg') {
            if ($(this).attr('src').indexOf('active') > 0) return;
            $('#vd_likeimg').attr('src', '/platform/images/likeactive.9ad65436.png');
            $('#vd_dislikeimg').attr('src', '/platform/images/dislike.6efb2f1a.png');
        } else {
            if ($(this).attr('src').indexOf('active') > 0) return;
            $('#vd_dislikeimg').attr('src', '/platform/images/dislikeactive.bea72979.png');
            $('#vd_likeimg').attr('src', '/platform/images/like.2e903c35.png');
        }
        $(this).css('border-color', '#e5e5e5');
        THIS.renderVideoFeedbackOptions(status, nid);
        e.stopPropagation();
        return false;
    });
};
platformView.prototype.showThankyouforfeedback = function () {
    $("body").append("<div id='videofeedback_thankyou_wrapper'></div>");
    this.renderTemplate('feedback_thankyou.tl', '', "#videofeedback_thankyou_wrapper", false);
    if ($('#thankyou_overlay').length == 0) {
        $('body').append("<div id='thankyou_overlay'></div>");
    }
    if ($(window).width() > $(window).height()) {
        $('.feedback_thank_outer_wrp').css({ 'width': '60%', 'top': '50%' });
    }
    $(".thankyou_close").unbind('click').click(function (e) {
        $('#thankyou_overlay,#videofeedback_thankyou_wrapper').remove();
        e.stopPropagation();
        return false;
    });
};
platformView.prototype.renderVideoFeedbackOptions = function (status, nid, type) {
    var THIS = this;
    if (CONTROLLER.activeViewName == 'Resource') {
        var closedialog = closeDialog();
        CONTROLLER.setVariable('closed_resourceview_dialog', 'true');
    }
    // var feedbackdata =$.parseJSON(Platform.getObjectProperty('videofeedbackoption', 'videofeedback-123', "content"));
    var feedbackdata = FEEDBACK_OPTIONS;
    var feeedback_key = "videofeedbackoptions";
    if (type == OBJECT_BOOK) {
        feeedback_key = "ebookfeedbackoptions";
    }
    if (type == OBJECT_QUESTION) {
        feeedback_key = "questionfeedbackoptions";
    }
    var data = '';
    var templateData = {};
    if (typeof feedbackdata == "undefined" || feedbackdata == null) {
        THIS.saveVideoFeedback(nid, status, "", function () { });
        $('#rate_video_wrapper,#rate_video_overlay').remove();
        THIS.showThankyouforfeedback();
        return;
    }
    if (status == '1') {
        if (typeof feedbackdata[feeedback_key][1] == "undefined") {
            THIS.saveVideoFeedback(nid, status, "", function () { });
            $('#rate_video_wrapper,#rate_video_overlay').remove();
            THIS.showThankyouforfeedback();
            return;
        }
        data = feedbackdata[feeedback_key][1].feedback;
        templateData['title'] = feedbackdata[feeedback_key][1]['title'];
    } else {
        if (typeof feedbackdata[feeedback_key][0] == "undefined") {
            THIS.saveVideoFeedback(nid, status, "", function () { });
            $('#rate_video_wrapper,#rate_video_overlay').remove();
            THIS.showThankyouforfeedback();
            return;
        }
        data = feedbackdata[feeedback_key][0].feedback;
        templateData['title'] = feedbackdata[feeedback_key][0]['title'];
    }
    templateData.keyvalue = function (chk, ctx, bodies) {
        var obj = ctx.current();
        for (var k in obj) {
            chk = chk.render(bodies.block, ctx.push({ key: k, value: obj[k] }));
        }
        return chk;
    }
    templateData['status'] = status;
    templateData['nid'] = nid;
    templateData['data'] = data;
    if ($('#rate_video_wrapper').length > 0) {
        $('#rate_video_wrapper,#rate_video_overlay').remove();
    }
    $("body").append("<div id='videofeedback_options_wrapper'></div>");
    this.renderTemplate('videofeedback_options.tl', templateData, "#videofeedback_options_wrapper", false);
    if ($('#feedback_overlay').length == 0) {
        $('body').append("<div id='feedback_overlay'></div>");
    }
    if (status == '1') {
        $('#upvote_image').attr('src', '/platform/images/likeactive.9ad65436.png');
        $('#downvote_image').attr('src', '/platform/images/dislike.6efb2f1a.png');
    } else {
        $('#upvote_image').attr('src', '/platform/images/like.2e903c35.png');
        $('#downvote_image').attr('src', '/platform/images/dislikeactive.bea72979.png');
    }
    $(".feedback_close").unbind('click').click(function (e) {
        $(".ui-dialog").css("display","block")
        $('#feedback_overlay,#videofeedback_options_wrapper').remove();
        CONTROLLER.setVariable('closed_resourceview_dialog', '');
        THIS.saveVideoFeedback(nid, status, "", function () { });
        e.stopPropagation();
        return false;
    });
    if ($(window).width() > $(window).height()) {
        $('.feedback_outer_wrapper').css({ 'width': '80%', 'top': '50%' });
    }
    if (type != OBJECT_BOOK) {
        $(".ui-dialog").css("display","contents")
        $("#videofeedback_text").unbind('focus').focus(function () {
            if ($(window).width() > $(window).height()) {
                $('.feedback_outer_wrapper').css('top', '0%');
                setTimeout(function () {
                    $(".feedback_outer_wrapper").scrollTop(0);
                }, 1000);
            }
        });
        $("#videofeedback_text").unbind('blur').blur(function () {
            if ($(window).width() > $(window).height()) {
                $('.feedback_outer_wrapper').css('top', '50%');
            }
        });
    }
    $(".feedback_info_opt").unbind('click').click(function (e) {
        if (!$(this).hasClass('feedback_option_active')) {
            $(this).addClass('feedback_option_active');
            $(this).removeClass('feedback_option_inactive');
        } else {
            $(this).addClass('feedback_option_inactive');
            $(this).removeClass('feedback_option_active');
        }
        e.stopPropagation();
        return false;
    });
    $(".btn_feedbacksumit").unbind('click').click(function (e) {
        var nid = $(this).attr('nid');
        var status = $(this).attr('status');
        var fid = 'temp_videofeedback_' + nid;
        var feedback_value = ''
        feedback_value = { 'comments': [] };
        feedback_value['vid'] = nid;
        feedback_value['uid'] = OBJECT_USER_ID;
        feedback_value['status'] = status;
        feedback_value['time'] = Math.floor(Date.now() / 1000);
        feedback_value['feedback_id'] = fid;
        var videofeedback_text = $('#videofeedback_text').val().trim();
        $(".ui-dialog").css("display","block")
        $(".feedback_option_active").each(function (index) {
            var temp_data = {};
            temp_data['comment_id'] = $(this).attr('id');
            temp_data['comment_status'] = $(this).text();
            feedback_value['comments'].push(temp_data);
        });
        if (feedback_value['comments'].length == 0 && (videofeedback_text.length == 0 || videofeedback_text == 'Enter your feedback (Optional Max Characters 150)')) {
            $('.select_opts_vf').removeClass('hidden');
        } else {
            if (videofeedback_text.length > 0 && videofeedback_text != 'Enter your feedback (Optional Max Characters 150)') {
                //  0th element is for user written feedback
                feedback_value['comments'].unshift({
                    "comment_id": "0",
                    "comment_status": videofeedback_text
                });
            } else if (typeof feedback_value['comments'][1] != 'undefined') {
                feedback_value['comments'].unshift({ "comment_id": "0", "comment_status": "" });
            }
            THIS.saveVideoFeedback(nid, status, feedback_value, function () { });
            $('#feedback_overlay,#videofeedback_options_wrapper').remove();
            THIS.showThankyouforfeedback();
        }
        CONTROLLER.setVariable('closed_resourceview_dialog', '');
        e.stopPropagation();
        return false;
    });
}

platformView.prototype.renderForum = function () {
    if (!IS_APP_SHELL && !IS_TEAMS_APP) {
        window.history.pushState({}, document.title, window.location.pathname);
    }
    var THIS = this;
    CONTROLLER.setVariable("activecourseid", "");
    CONTROLLER.setVariable('toc_forum', false);
    RENDER_FORUM_HEADERFILE = "true";
    if (typeof DISPLAY_CATEGORY_SPECIFIC_COURSES != 'undefined' && DISPLAY_CATEGORY_SPECIFIC_COURSES != 'true') {
        CONTROLLER.setVariable("globalforum", "true");
    }
    if (Platform.getNetworkState() == false) {
        if (EXEC_MODE == "mobile" && (typeof ENABLE_OFFLINE_FORUM == 'undefined' || ENABLE_OFFLINE_FORUM != 'false')) {
            CONTROLLER.setVariable("offlineForumMode", "true");
            CONTROLLER.loadView('PostQuestion');
        } else {
            alert(INTERNET_ERROR);
            return;
        }
    } else if ($('#forum .panel').hasClass('access_disabled')) {
        alert(t("USER_SUBSCRIPTION_ERROR",true));
        return;
    }
    else {
        $('.footer_course_image, .footer_home_image, .footer_schedule_image, .footer_more_image').removeClass('active_tab');
        $('.footer_forum_image').addClass('active_tab');
        $('.footer_all_courses_image').removeClass('active_tab');
        $('.footer_icons > p').removeClass('active_color');
        $('.footer_forum p').addClass('active_color');;
        var show_logs = CONTROLLER.getVariable("show_logs");
        if (typeof show_logs != 'undefined' && show_logs) {
            CONTROLLER.loadView("ForumTopics");
        } else {
            THIS.renderFooterForumCode();
        }
    }
}

platformView.prototype.renderFooterForumCode = function () {
    var THIS = this;
    var activeview = CONTROLLER.activeViewName;
    if (activeview != 'ForumTopics') {
        THIS.unsetTaskQuiz();
        if (HISTORY.length != 0) {
            if (HISTORY.indexOf('ForumTopics') > -1) {
                for (var i = 0; i < HISTORY.length; i++) {
                    if (HISTORY[i] === 'ForumTopics') {
                        HISTORY.splice(i, 1);
                    }
                }
                HISTORY.push('ForumTopics');
            }
            else {
                HISTORY.push('ForumTopics');
            }
        }
        loadMathJax(function () {
            CONTROLLER.loadView("ForumTopics");
        });
    }
}

platformView.prototype.bookmark_page = function (page, query_string, bookmarkIcon) {
    if (Platform.getNetworkState() == true) {
        // already bookmarked
        var page_bookmarked_error = '';
        var page_bookmarked_error_params = {};
        var previousView = this.getQueryParameterPdf('prev_view', query_string);
        var preView = CONTROLLER.getVariable("previousView");
        if (previousView == 'bookmark') {
            var params_pdf = {};
            params_pdf['uid'] = this.getQueryParameterPdf('uid', query_string);
            var data = BookmarksService.prototype.getAllBookmarks(params_pdf);
            var pdf_duration = page;
            params_pdf['page'] = this.getQueryParameterPdf('page', query_string);
            for (var i = 0; i < data.length; i++) {
                if (data[i].duration == pdf_duration) {
                    page_bookmarked_error = true;
                    page_bookmarked_error_params['nid'] = data[i].nid;
                    page_bookmarked_error_params['bookmark_id'] = data[i].bookmark_id;
                }
            }
        }
        else {
            var params_pdf = {};
            params_pdf['uid'] = this.getQueryParameterPdf('uid', query_string);
            var pdf_course_id = this.getQueryParameterPdf("course_id", query_string);
            var pdf_cat_id = this.getQueryParameterPdf("cat_id", query_string);
            var pdf_chapter_id = this.getQueryParameterPdf("chapter_id", query_string);
            var pdf_nid = this.getQueryParameterPdf('nid', query_string);
            var pdf_duration = page;
            var data = BookmarksService.prototype.getAllBookmarks(params_pdf);

            // checking page is bookmarked already
            for (var i = 0; i < data.length; i++) {
                if (data[i].nid == pdf_nid && data[i].course_id == pdf_course_id && data[i].category_id == pdf_cat_id && data[i].chapter_id == pdf_chapter_id && data[i].user_id == params_pdf['uid'] && data[i].duration == pdf_duration) {
                    page_bookmarked_error = true;
                    page_bookmarked_error_params['nid'] = data[i].nid;
                    page_bookmarked_error_params['bookmark_id'] = data[i].bookmark_id;
                }
            }
        }

        var params = {};
        var nid = this.getQueryParameterPdf("nid", query_string);
        if (preView == "SupportDocsList") {
            var content = Platform.getObjectProperty(OBJECT_SUPPORT_DOCS, nid, "content");
        } else {
            var content = Platform.getObjectProperty(OBJECT_VIDEO, nid, "content");
        }
        var page_number = page;
        params['course_id'] = this.getQueryParameterPdf("course_id", query_string);
        params['category_id'] = this.getQueryParameterPdf("cat_id", query_string);
        params['chapter_id'] = this.getQueryParameterPdf("chapter_id", query_string);
        params['nid'] = nid;
        params['duration'] = page_number;
        params['page_number'] = page_number;

        if (typeof content != 'undefined' && content != '') {
            content = JSON.parse(content);
        }
        params['content'] = content;
        params['title'] = content.title;


        if (params['page_number'] == 0) {
            alert('page is empty');
        }
        else {
            if (page_bookmarked_error) {
                this.delete_bookmark_pdf(page_bookmarked_error_params, bookmarkIcon);
            }
            else {
                if (previousView == 'bookmark') {
                    var params_pdf_add = {};
                    var nid = this.getQueryParameterPdf('nid', query_string);
                    var content = Platform.getObjectProperty(OBJECT_VIDEO, nid, "content");
                    var page_number = page;
                    params_pdf_add['nid'] = this.getQueryParameterPdf('nid', query_string);
                    params_pdf_add['uid'] = this.getQueryParameterPdf('uid', query_string);
                    params_pdf_add['bookmark_id'] = this.getQueryParameterPdf('bookmark_id', query_string);
                    params_pdf_add['course_id'] = this.getQueryParameterPdf('course_id', query_string);
                    params_pdf_add['category_id'] = this.getQueryParameterPdf('cat_id', query_string);
                    params_pdf_add['chapter_id'] = this.getQueryParameterPdf('chapter_id', query_string);
                    params_pdf_add['title'] = this.getQueryParameterPdf('title', query_string);
                    params_pdf_add['duration'] = page_number;
                    params_pdf_add['page_number'] = page_number;
                    if (typeof content != 'undefined' && content != '') {
                        content = JSON.parse(content);
                    }
                    params_pdf_add['content'] = content;
                    params_pdf_add['title'] = content.title;
                    this.save_pdf_bookmark(params_pdf_add, bookmarkIcon);
                }
                else {
                    this.save_pdf_bookmark(params, bookmarkIcon);
                }
            }
        }
    }
    else {
        alert(INTERNET_ERROR);
        setTimeout(function () {
            if (bookmarkIcon) {
                $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
            }
        }, 200);
        return "";
    }
}

platformView.prototype.delete_bookmark_pdf = function (params, bookmarkIcon) {
    var scaleDialog = false;
    if (!bookmarkIcon) {
        bookmarkIcon = $('.pdf_bookmark');
    } else {
        scaleDialog = true;
    }
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var width;
    var height;
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Ok": function () {
                $("html,body").css("overflow", "");
                params['action'] = 'delete';
                var api_result = BookmarksService.prototype.updateBookmark(params);
                if (api_result == 'success') {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $(bookmarkIcon).removeClass('bookmark_active');
                    $(bookmarkIcon).addClass('bookmark_inactive');
                    alert('Bookmark has been deleted');
                    setTimeout(function () {
                        if (scaleDialog) {
                            $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
                        }
                    }, 200);
                }
                else if (api_result == 'error') {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Something went wrong please try again later');
                    setTimeout(function () {
                        if (scaleDialog) {
                            $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
                        }
                    }, 200);
                }
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            },
            "Cancel": function () {
                $("html,body").css("overflow", "");
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="bookmark_dialog">' + '<div class="bookmark_event_text delete_bookmark_page">' + 'Delete Favourite ' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
            <div class="delete_dialog_title">'+ 'Are you sure you want to delete this favourite ?' + '</div>\n\
            </div>' ;

    $('#alert_dialog').html(text);

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $(".dlgfixed").center(false);
    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '5%', 'margin-top': '16px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.ui-dialog').css({ 'padding': '0px', 'top': '55%', 'border-radius': '3px !important', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    if (scaleDialog) {
        $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
    }
}

platformView.prototype.save_pdf_bookmark = function (params, bookmarkIcon) {
    var scaleDialog = false;
    if (!bookmarkIcon) {
        bookmarkIcon = $('.pdf_bookmark');
    } else {
        scaleDialog = true;
    }
    $('#alert_dialog').remove();
    $('body').append('<div id="alert_dialog" ></div>');
    var global_pin = 0;
    var width;
    var height;
    if ($(window).width() < 480) {
        width = 300;
        height = 'auto';
    }
    else {
        width = 340;
        height = 'auto';
    }

    $('#alert_dialog').dialog({
        resizable: false,
        modal: true,
        height: height,
        width: width,
        open: function () {
            $('.ui-widget-overlay').addClass('custom-overlay');
        },
        buttons: {
            "Save": function () {
                $("html,body").css("overflow", "");
                params['global_pin'] = global_pin;
                params['title'] = $('input[id="bookmark_title"]').val();
                params['action'] = 'add';
                if (params['title'].trim() == "") {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Title is empty. Please enter the title.');
                    setTimeout(function () {
                        if (scaleDialog) {
                            $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
                        }
                    }, 200);
                }
                else {
                    var api_result = BookmarksService.prototype.updateBookmark(params);
                }
                if (api_result == 'success') {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    $(bookmarkIcon).removeClass('bookmark_inactive');
                    $(bookmarkIcon).addClass('bookmark_active');
                    alert('PDF favorite has been added');
                    setTimeout(function () {
                        if (scaleDialog) {
                            $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
                        }
                    }, 200);
                }
                else if (api_result == 'error') {
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    alert('Something went wrong please try again later');
                    setTimeout(function () {
                        if (scaleDialog) {
                            $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
                        }
                    }, 200);
                }

                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            },
            "Cancel": function () {
                $("html,body").css("overflow", "");
                $(this).dialog("close");
                $('#alert_dialog').remove();
                $("#transparent_wrapper").css({
                    "display": "none"
                });
                return false;
            }
        },
        dialogClass: 'dlgfixed',
        position: "center",
    });

    var text = '<div class="bookmark_dialog">' + '<div class="add_pdf_bookmark">' + 'Add to Favorites' + '</div> ' + '</div>' +
        '<div class="dialog">' + '<div class="event_dialog"><span class="ui-icon ui-icon-alert"></span>\n\
            <div class="add_doc_title">' + '<input type="text" value="" id="bookmark_title" placeholder="Enter the title"/>' + '</div>\n\
            <div class="add_pdf_global hidden">' + '<input type="checkbox" name="bookmark" value="bookmark"/> Share with others' + '</div>\n\
            </div>' ;


    $('#alert_dialog').html(text);

    $('input[type="checkbox"]').click(function () {
        if ($(this).prop("checked") == true) {
            global_pin = 1;
        }
        else if ($(this).prop("checked") == false) {
            global_pin = 0;
        }
    });

    $('input[id="bookmark_title"]').val(params.title);

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    // $(".dlgfixed").center(true);

    if (typeof ENABLE_BOOKMARK_SHARE != 'undefined' && ENABLE_BOOKMARK_SHARE) {
        $('.add_pdf_global').removeClass('hidden');
    }

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('save_button');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#f4f5f9', 'position': 'absolute', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '5%', 'margin-top': '4px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $('.bookmark_dialog .ui-dialog').css({ 'padding': '0px', 'top': '30%', 'border-radius': '3px !important', 'border-color': '#0080C6' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });
    if (scaleDialog) {
        $('.alert_wrapper_dialog').addClass('scaleDialogInPdf');
    }
}

// Bookmark feature code

platformView.prototype.getQueryParameterPdf = function (parameterName, query_string) {
    var queryString = query_string;
    var parameterName = parameterName + "=";
    if (queryString.length > 0) {
        var begin = queryString.indexOf(parameterName);
        if (begin != -1) {
            begin += parameterName.length;
            var end = queryString.indexOf("&", begin);
            if (end == -1) {
                end = queryString.length
            }
            return unescape(queryString.substring(begin, end));
        }
    }
    return "null";
}


// bookmarked already

platformView.prototype.gettingBookmarkedStatus = function (page, query_string, bookmarkIcon) {
    var previousView = this.getQueryParameterPdf('prev_view', query_string);
    if (!bookmarkIcon) {
        bookmarkIcon = $('.pdf_bookmark');
    }
    if (previousView == 'bookmark') {
        var params_pdf = {};
        params_pdf['uid'] = this.getQueryParameterPdf('uid', query_string);
        var data = BookmarksService.prototype.getAllBookmarks(params_pdf);
        var pdf_duration = page;
        for (var i = 0; i < data.length; i++) {
            if (data[i].duration == pdf_duration) {
                $(bookmarkIcon).removeClass('bookmark_inactive');
                $(bookmarkIcon).addClass('bookmark_active');
                break;
            }
            else {
                $(bookmarkIcon).removeClass('bookmark_active');
                $(bookmarkIcon).addClass('bookmark_inactive');
            }
        }
    } else {
        var params_pdf_status = {};
        params_pdf_status['uid'] = this.getQueryParameterPdf('uid', query_string);
        var pdf_course_id = this.getQueryParameterPdf("course_id", query_string);
        var pdf_cat_id = this.getQueryParameterPdf("cat_id", query_string);
        var pdf_chapter_id = this.getQueryParameterPdf("chapter_id", query_string);
        var pdf_nid = this.getQueryParameterPdf('nid', query_string);
        var pdf_duration = page;
        if (typeof data == 'undefined') {
            var data = BookmarksService.prototype.getAllBookmarks(params_pdf_status);
            // checking page is bookmarked already
            for (var i = 0; i < data.length; i++) {
                if (data[i].nid == pdf_nid && data[i].course_id == pdf_course_id && data[i].category_id == pdf_cat_id &&
                    data[i].chapter_id == pdf_chapter_id && data[i].user_id == params_pdf_status['uid']) {
                    if (data[i].duration == pdf_duration) {
                        $(bookmarkIcon).removeClass('bookmark_inactive');
                        $(bookmarkIcon).addClass('bookmark_active');
                    } else {
                        $(bookmarkIcon).removeClass('bookmark_active');
                        $(bookmarkIcon).addClass('bookmark_inactive');
                    }
                }
            }
        }
    }

}

platformView.prototype.startWebinar = function (data, startdate_text) {
    var THIS = this, nid = "";
    var params = {};
    var url = "";
    var webinarStatus = "", meetingId = "";
    var webinarService = CONTROLLER.getService("Webinar");
    if (Platform.getNetworkState() == false) {
        alert(INTERNET_ERROR);
        return false;
    }
    nid = data;
    Client.showProgressBar(t("Loading") + "...");
    setTimeout(function () {
        var webinar_json = WebinarService.prototype.getWebinarById(nid);
        var provider = webinar_json['provider'];
        if (webinar_json["url"] == "#") {
            var meeting_id = webinar_json["meeting_ids"][0];
            webinar_json["url"] = GOOGLE_MEET_URL + meeting_id;
            url = webinar_json['url'];
        } else {
            url = webinar_json['url'];
        }
        THIS.updateVVHforTocWebinar(webinar_json.startdate);
        if (provider != "gmeet") {
            webinarStatus = WebinarService.prototype.getWebinarStatus(nid);
            if (webinarStatus == 403) {
                onAccessDenied();
                return false;
            }
            if (webinarStatus.status == "started") {
                meetingId = webinarStatus.meeting_id;
                if (!IS_APP_SHELL || APP_TYPE == "ios") {
                    if (!IS_APP_SHELL) meetingId = nid; // if webinar is launched in mobile browser sending webinar nid
                    if (typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")) {
                        Client.launchWebinar(meetingId, webinar_json.password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID);
                    } else if (WEBINAR_USER_DETAIL_TYPE == "firstname") {
                        var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                        Client.launchWebinar(meetingId, webinar_json.password, userProfile[0].first_name + "-" + OBJECT_USER_ID);
                    } else if (WEBINAR_USER_DETAIL_TYPE == "firstname_and_username") {
                        var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                        Client.launchWebinar(meetingId, webinar_json.password, userProfile[0].first_name + "-" + OBJECT_USER_NAME +  "-" + OBJECT_USER_ID);
                    } else {
                        Client.launchWebinar(nid);
                    }
                } else {
                    if (typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")) {
                        webinarService.joinWebinar(meetingId, webinar_json.password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_json.webinar_account);
                    }
                    else if (WEBINAR_USER_DETAIL_TYPE == "firstname") {
                        var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                        webinarService.joinWebinar(meetingId, webinar_json.password, userProfile[0].first_name + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_json.webinar_account);
                    }
                    else if (WEBINAR_USER_DETAIL_TYPE == "firstname_and_username") {
                        var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                        webinarService.joinWebinar(meetingId, webinar_json.password, userProfile[0].first_name + "-" + OBJECT_USER_NAME +  "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_json.webinar_account);
                    }
                }
                WebinarService.prototype.updateWebinarAttendance(nid)
            } else {
                params.status = webinarStatus.status;
                params.nid = nid;
                params.start_date_text = startdate_text;
                params.end_date = webinar_json.enddate;
                params.password = webinar_json.password;
                params.webinar_account = webinar_json.webinar_account;
                CONTROLLER.setVariable('webinar_toc', true);
                if (CONTROLLER.getVariable("previousView") == "AllSubjects" && CONTROLLER.activeViewName != "Course") {
                    CONTROLLER.onBack();
                    setTimeout(function () {
                        CONTROLLER.loadView("WebinarInactive", params);
                    }, 1000);
                } else {
                    CONTROLLER.loadView("WebinarInactive", params);
                }
            }
        } else {
            var start_date_time = webinar_json['startdate'];
            var end_date_time = webinar_json['enddate'];
            var current_date_time = $.now();
            current_date_time = Math.floor(current_date_time / 1000);

            if (start_date_time <= current_date_time && current_date_time <= end_date_time) {
                webinar_json.status = "active";
            } else if (start_date_time > current_date_time) {
                webinar_json.status = "inactive";
            } else {
                webinar_json.status = "completed";
            }
            params.status = webinar_json.status;

            webinarStatus = WebinarService.prototype.getWebinarStatus(nid);

            if (webinarStatus.status == "started") {
                Platform.openBrowser(url);
                WebinarService.prototype.updateWebinarAttendance(nid);
            } else {
                params.nid = nid;
                params.start_date_text = startdate_text;
                params.password = webinar_json.password;
                params.webinar_account = webinar_json.webinar_account;
                params.end_date = webinar_json.enddate;
                params.provider = webinar_json.provider;
                CONTROLLER.setVariable('webinar_toc', true);
                CONTROLLER.loadView("WebinarInactive", params);
            }
        }
        Client.closeProgressBar();
    }, 300);
}

platformView.prototype.renderWebinarEvent = function (params) {
    var width;
    var height;
    if (typeof params.description != 'undefined' && params.description != '') {
        var description = replaceURLWithHTMLLinks(params.description);
    }
    params['description'] = description;
    if ($(window).width() < 480) {
        width = $(window).width() + 'px';
        height = 'auto';
    }
    else {
        width = $(window).width() + 'px';
        height = 'auto';
    }
    $('body').append('<div id="alert_dialog" ></div>');
    if (params.event == 'not_active') {
        var THIS = this;
        var webinar_record_id = params.webinar_record_id;
        if (webinar_record_id == "" || webinar_record_id == null) {
            if (params['event_type'] == 'event-past') {
                description = description + "\n" + WEBINAR_VIDEO_TRANSCODING;
                params['description'] = description;
            } else {
                params['description'] = description;
            }

            $('#alert_dialog').dialog({
                resizable: false,
                modal: true,
                height: height,
                width: width,
                open: function () {
                    $('.ui-widget-overlay').addClass('custom-overlay');
                },
                buttons: {
                },
                dialogClass: 'dlgfixed',
                position: "center",
            });
        } else {
            params['description'] = description;
            $('#alert_dialog').dialog({
                resizable: false,
                modal: true,
                height: height,
                width: width,
                open: function () {
                    $('.ui-widget-overlay').addClass('custom-overlay');
                },
                buttons: {
                    "Play": function () {
                        var params = { "resourceid": webinar_record_id, "type": "webinar_record" };
                        SCROLL_POSITION = $("#wrapper").scrollTop();
                        var objtype = Platform.getObjectType(webinar_record_id);
                        var resource = $.parseJSON(Platform.getObjectProperty(objtype, webinar_record_id, "content"));
                        if (resource == null) {
                            if (Platform.getNetworkState() == false) {
                                alert(INTERNET_ERROR);
                                return;
                            }
                            resource = THIS.resource.getResourceInfoById(webinar_record_id, "true");
                            if (resource == "error") {
                                return;
                            }
                        }
                        if (resource != null) {
                            var isResourcePlayable = THIS.isResourcePlayable(params.resourceid, "", "webinar_record", "", true, params);
                            Client.closeProgressBar();
                            if (isResourcePlayable == false) {
                                return false;
                            } else {
                                $(this).dialog("close");
                                $('#alert_dialog').remove();
                                CONTROLLER.setVariable("previousView", "Calender");
                                CONTROLLER.loadView("Resource", params);
                            }
                        }
                    }
                },
                dialogClass: 'dlgfixed',
                position: "center",
            });
        }
    }
    else if (params.event == 'active') {
        $('#alert_dialog').dialog({
            resizable: false,
            modal: true,
            height: height,
            width: width,
            open: function () {
                $('.ui-widget-overlay').addClass('custom-overlay');
            },
            buttons: {
                "Start": function () {
                    $("html,body").css("overflow", "");
                    $(this).dialog("close");
                    $('#alert_dialog').remove();
                    if (params.type == OBJECT_TASK.capitalizeFirstLetter()) {
                        CONTROLLER.loadView("ListTask", params);
                    }
                    else if (params.type == OBJECT_ASSIGNMENT.capitalizeFirstLetter()) {
                        CONTROLLER.loadView("Assignment", params);
                    }
                    $("#transparent_wrapper").css({
                        "display": "none"
                    });
                    return false;
                },
            },
            dialogClass: 'dlgfixed',
            position: "center",
        });
    } else if (params.event == "webinar_trainer") {
        var THIS = this;
        params['description'] = description;
        var nid = params['id'];
        if (params.webinar_status == "started") {
            $('#alert_dialog').dialog({
                resizable: false,
                modal: true,
                height: height,
                width: width,
                open: function () {
                    $('.ui-widget-overlay').addClass('custom-overlay');
                },
                buttons: {
                    "Stop": function () {
                        WebinarService.prototype.updateWebinar(nid, WEBINAR_COMPLETED);
                        Client.closeProgressBar();
                        handleBackClick();
                    },
                    "Join": function () {
                        THIS.onStartWebinarClick(params);
                    }
                },
                dialogClass: 'dlgfixed',
                position: "center",
            });
        } else {
            $('#alert_dialog').dialog({
                resizable: false,
                modal: true,
                height: height,
                width: width,
                open: function () {
                    $('.ui-widget-overlay').addClass('custom-overlay');
                },
                buttons: {
                    "Start": function (e) {
                        THIS.onStartWebinarClick(params);
                    }
                },
                dialogClass: 'dlgfixed',
                position: "center",
            });
        }

    }

    this.renderTemplate("webinar_events.tl", params, "#alert_dialog", true);

    $('.event_dialog_description a').click(function (e) {
        var url = this.href;
        if (url != undefined && url != "") {
            var pattern = /^((http|https|ftp):\/\/)/;
            if (!pattern.test(url)) { //if domain name not starts with "http://
                url = "http://" + url;
            }
            Platform.openBrowser(url);
        }
        e.stopPropagation();
        return false;
    });

    if (typeof params.due == 'undefined' || params.due == '') {
        $('.event_dialog_due').css({ 'display': 'none' });
    }

    if (typeof params.end == 'undefined' || params.end == '') {
        $('.event_dialog_end').css({ 'display': 'none' });
    }

    if ($(window).width() > $(window).height()) {
        $('.alert_dialog').css({
            "max-height": height + 20,
            "overflow-y": "scroll"
        });
    }

    $('#alert_dialog').parent().addClass('alert_wrapper_dialog');
    $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
    $('.ui-button-text').parent().addClass('event_start_dialog');
    $('.ui-dialog-titlebar').addClass('alert_wrapper_dialog_title');
    $('html body .ui-dialog').css({ 'border': '1px solid #bbb', 'z-index': '100000', 'background': '#F4F5F9', 'position': 'fixed', 'outline': 'none', 'border-radius': '0px' });
    $('.ui-dialog-buttonset .ui-button, .download-action-button').css({ 'width': '35%' });
    $('.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset').css({ 'margin-bottom': '10px', 'margin-top': '10px' });
    $('.event_dialog_description a').css({ 'color': '#00aff0', 'text-decoration': 'underline', 'font-weight': '500' });
    $('.event_dialog_description').css({ 'color': '#5d5a5a' });
    $('.alert_wrapper_dialog').css({ 'height': $(window).height() + "px" });
    $('.ui-dialog').css({ 'padding': '0px', 'border-radius': '3px', 'border-color': '#0080C6', 'margin-top': '0px !important', 'margin-left': '0px !important', 'top': '0%' });
    $(".ui-dialog-titlebar").hide();
    $('.ui-button-text').css({ 'font-weight': '600' });
    $("#transparent_wrapper").css({
        "display": "block",
        height: $(window).height() + "px",
        width: $(window).width() + "px"
    });

    if (params.event == "webinar_trainer" && params.webinar_status == "started") {
        $('.ui-button-text').parent().addClass('webinar_btn');
    }
}

platformView.prototype.webinarInTOC = function (webinar_data) {
    if (isTrainer()) {
        Client.showProgressBar(t("Loading") + "...");
        var params = {};
        params['id'] = webinar_data.nid;
        params['title'] = webinar_data.title;
        params['status'] = 'webinar_toc';
        params['webinar_record_id'] = webinar_data.webinar_record_id;
        params['type'] = WEBINAR_TITLE;
        params['description'] = "Do you want to start this Live Class?";
        params['start'] = getFormattedDateWithTimeForEvents(webinar_data.startdate);
        if (webinar_data.enddate != '' && webinar_data.enddate != null) {
            params['end'] = getFormattedDateWithTimeForEvents(webinar_data.enddate);
        }
        params['event'] = 'webinar_trainer';
        params['event_type'] = "";
        params['startdate_text'] = webinar_data.startdate_text;
        var webinar_json = webinar_data;
        var trainer_role = false;
        if (webinar_json.created_by == ADMIN) {
            if (typeof webinar_json.owners != 'undefined'
                && webinar_json.owners.length == 0) {
                trainer_role = true;
            } else if (typeof webinar_json.owners != 'undefined'
                && webinar_json.owners.length > 0) {
                if (webinar_json.owners.indexOf(OBJECT_USER_ID) > -1) {
                    trainer_role = true;
                }
            }
        }
        if (trainer_role) {
            params['webinar_json'] = webinar_json;
            params['webinar_status'] = webinar_json.session_status;
            this.renderWebinarEvent(params);
        } else {
            this.startWebinar(webinar_data.nid, webinar_data.startdate_text);
        }

    } else {
        var webinar_start = getFormattedDateWithTime(webinar_data.startdate);
        CONTROLLER.setVariable('webinar_toc', true);
        this.startWebinar(webinar_data.nid, webinar_start);
    }
}

platformView.prototype.onStartWebinarClick = function (params) {
    var THIS = this;
    var webinarService = CONTROLLER.getService("Webinar");
    if (Platform.getNetworkState() == false) {
        alert(INTERNET_ERROR);
        Client.closeProgressBar();
        return false;
    }
    nid = params['id'];
    password = params['webinar_json'].password;
    startdate_text = THIS.getStartDateForWebinar(params['webinar_json']);
    meeting_id = params['webinar_json'].meeting_ids[params['webinar_json'].meeting_ids.length - 1];
    webinar_account = params['webinar_json'].webinar_account;
    webinar_account = webinar_account.toLowerCase();
    provider = params['webinar_json'].provider;
    url = GOOGLE_MEET_URL + meeting_id;
    if (provider != "gmeet") {
        Client.showProgressBar(t("Loading") + "");
        checkWebinarAccountStatus = function (status) {
            status = $.parseJSON(status);
            if (status['status'] == "success" || status['satus'] == "success") {
                webinarStatus = WebinarService.prototype.getWebinarStatus(nid);
                if (webinarStatus == 403) {
                    onAccessDenied();
                    Client.closeProgressBar();
                    return false;
                }
                if (webinarStatus.status == "completed") {
                    Client.closeProgressBar();
                    confirm(t("WEBINAR_RESTART_MSG", true), function () {
                        THIS.startNewWebinar(nid, password, webinar_account);
                    }, "START", "BACK");
                } else {
                    meetingId = meeting_id;
                    if (EXEC_MODE == 'online') {
                        Client.startWebinar(nid);
                    } else {
                        meetingId = meetingId.toString();
                        if (typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")) {
                            webinarService.startWebinar(meetingId, password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_account);
                        }
                        else if (WEBINAR_USER_DETAIL_TYPE == "firstname") {
                            var userProfile = THIS.settings.getUserProfile(OBJECT_USER_DATA.uid);
                            webinarService.startWebinar(meetingId, password, userProfile[0].first_name + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_account);
                        }
                        WebinarService.prototype.updateWebinar(nid, WEBINAR_STARTED);
                    }
                    Client.closeProgressBar();
                }
            }
            else {
                alert(WEBINAR_ACCOUNT_PROBLEM);
                Client.closeProgressBar();
            }
        }
    } else {
        var start_date_time = params['webinar_json']['startdate'];
        var end_date_time = params['webinar_json']['enddate'];
        var current_date_time = $.now();
        current_date_time = Math.floor(current_date_time / 1000);
        var webinar_json = params['webinar_json'];
        if (start_date_time <= current_date_time && current_date_time <= end_date_time) {
            webinar_json.status = "active";
        } else {
            webinar_json.status = "completed";
        }
        if (start_date_time <= current_date_time && current_date_time <= end_date_time) {
            Platform.openBrowser(url);
        } else {
            webinar_json.nid = nid;
            webinar_json.start_date_text = startdate_text;
            webinar_json.password = params['webinar_json'].password;
            webinar_json.webinar_account = params['webinar_json'].webinar_account;
            webinar_json.end_date = params['webinar_json'].enddate;
            $('#alert_dialog').remove();
            CONTROLLER.setVariable('webinar_toc', true);
            CONTROLLER.loadView("WebinarInactive", webinar_json);
        }
    }
    if (EXEC_MODE == "online" && provider != "gmeet") {
        checkWebinarAccountStatus(JSON.stringify({ "status": "success" }));
    } else {
        webinarService.getWebinarUserInfo(webinar_account, nid);
    }
}

platformView.prototype.startNewWebinar = function (nid, password, webinar_account) {
    var THIS = this;
    var webinarService = CONTROLLER.getService("Webinar");
    if (EXEC_MODE == 'online') {
        $("#webinar_" + nid).html("Stop");
        Client.startWebinar(nid);
        return;
    }
    Client.showProgressBar(t("Loading") + "");
    var newMeetingID = WebinarService.prototype.createNewMeeting(nid);
    if (newMeetingID == 405) {
        alert("Please end the active meeting");
    } else if (newMeetingID == 403) {
        onAccessDenied();
    } else if (newMeetingID == 401 || newMeetingID == ERROR_MESSAGE) {
        alert(ERROR_MESSAGE);
    } else {
        newMeetingID = newMeetingID.toString();
        if (typeof WEBINAR_USER_DETAIL_TYPE == "undefined" || (typeof WEBINAR_USER_DETAIL_TYPE != "undefined" && WEBINAR_USER_DETAIL_TYPE == "username")) {
            webinarService.startWebinar(newMeetingID, password, OBJECT_USER_NAME + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_account);
        }
        else if (WEBINAR_USER_DETAIL_TYPE == "firstname") {
            var userProfile = this.settings.getUserProfile(OBJECT_USER_DATA.uid);
            webinarService.startWebinar(newMeetingID, password, userProfile[0].first_name + "-" + OBJECT_USER_ID, OBJECT_USER_DATA.email, webinar_account);
        }
        WebinarService.prototype.updateWebinar(nid, WEBINAR_STARTED);
    }
    Client.closeProgressBar();
};

platformView.prototype.getStartDateForWebinar = function (webinar_json) {
    var startdate = new Date(parseInt(webinar_json.startdate) * 1000);
    var startDatetext = startdate.getWeekDay() + ", " +
        startdate.getMonthName(startdate.getMonth() + 1) + " " +
        startdate.getDate() + nth(startdate.getDate()) + " " +
        startdate.getFullYear() + ", " +
        format_time(startdate);

    return startDatetext;
}

platformView.prototype.checkWebinarStatus = function (resource, params, isResourcePlayableStatus) {
    var isResourcePlayable = "";
    Client.showProgressBar(t("Loading") + "...");
    var webinar_service = CONTROLLER.getService("Webinar");
    var webinarContentId = resource['url'];
    if (params['isLinkedObject']) {
        webinarContentId = params.linkedobject_item;
    }
    if (typeof isResourcePlayableStatus == "undefined") {
        isResourcePlayable = this.isResourcePlayable(resource.nid, params.courseid, OBJECT_VIDEO, params.catid, true, params);
    } else {
        isResourcePlayable = isResourcePlayableStatus;
    }
    if (isResourcePlayable == false) {
        Client.closeProgressBar();
        return true;
    }
    var webinar_data = webinar_service.getWebinarById(webinarContentId);
    CONTROLLER.setVariable('webinar_toc_details', params);
    if (Platform.getNetworkState() == true) {
        if (typeof webinar_data != 'undefined' && webinar_data != "" && (webinar_data.session_status == "started" || webinar_data.session_status == null)) {
            this.webinarInTOC(webinar_data);
            Client.closeProgressBar();
            return true;
        } else if (typeof webinar_data != 'undefined' && webinar_data != "" && (webinar_data.session_status == "completed" || webinar_data.session_status == null) && params['isLinkedObject']) {
            this.playWebinarVideo(webinar_data.video_nid);
            Client.closeProgressBar();
            return true;
        } else if (typeof resource.webinar_record_id == 'undefined'
            || (typeof resource.webinar_record_id != 'undefined'
                && (resource.webinar_record_id == "" || resource.webinar_record_id == null))) {
            if (webinar_data.session_status == "completed" && isTrainer()) {
                this.webinarInTOC(webinar_data);
                Client.closeProgressBar();
                return true;
            } else {
                alert(t("WEBINAR_VIDEO_TRANSCODING", true));
                this.updateVVHforTocWebinar(webinar_data.startdate);
                Client.closeProgressBar();
                return true;
            }
        }
    } else {
        return false;
    }
    Client.closeProgressBar();
    return false;
}

platformView.prototype.renderTestContent = function () {
    if (Platform.getNetworkState() == false) {
        alert(INTERNET_ERROR);
        return '';
    } else {
        CONTROLLER.setVariable('categories_listing_mode', 'TestSeries');
        CONTROLLER.loadView('AllSubjects');
    }
};


platformView.prototype.showQuizResult = function (nid, quiz_res_obj, attempts, message, quiz_attempt_data, quiz_type) {
    try {
        if (typeof attempts != 'undefined' && attempts > 1 && Platform.getNetworkState() == false && quiz_type != "practice") {
            Client.closeProgressBar();
            alert(INTERNET_ERROR);
            return true;
        }
        CONTROLLER.setVariable("ScoreCardReport", "");
        this.quiz = CONTROLLER.getService("Quiz");
        Client.showProgressBar(t("Loading") + "");
        var quiz_obj;
        var params = {};
        var questions;
        var THIS = this;
        var count = 0;
        quiz_obj = THIS.quiz.getQuiz(nid);
        //first attempt
        if (typeof quiz_type != 'undefined' && quiz_type == 'practice' && Platform.getNetworkState() == false) {
            questions = THIS.quiz.getQuestionsForQuiz(nid, quiz_obj['changed'], '', true);
        } else {
            questions = THIS.quiz.getQuestionsForQuiz(nid, quiz_obj['changed'], '', false);
        }
        if (quiz_res_obj == "") {
            quiz_res_obj = THIS.quiz.getQuizResult(nid, true);
            if (quiz_res_obj != null && quiz_res_obj != '' && quiz_res_obj['quiz_result'].length != 0) {
                quiz_res_obj = quiz_res_obj['quiz_result']
                quiz_res_obj = quiz_res_obj[quiz_res_obj.length - 1];
                if (quiz_res_obj.is_evaluated == "") {
                    return false;
                }
            }
        }
        if (typeof quiz_attempt_data != 'undefined' && quiz_attempt_data.is_evaluated != 1 && quiz_attempt_data.time_end == 0
            && typeof quiz_attempt_data.macaddress != 'undefined') {
            if (typeof quiz_obj['quiz_template'] == 'undefined'
                || quiz_obj['quiz_template'] == '2') {
                if (quiz_res_obj && quiz_res_obj.gen_result_id == quiz_attempt_data.result_id) {
                    return false;
                }
            } else {
                return false;
            }
        }
        for (var i in questions["question"]) {
            if (!isNaN(i)) {
                for (var j in quiz_res_obj) {
                    if (!isNaN(j)) {
                        if (questions["question"][i].nid == quiz_res_obj[j]['question_result'].nid) {
                            questions["question"][i]['question_result'] = quiz_res_obj[j]['question_result'];
                        }
                    }
                }
            }
        }
        THIS.allQuestions = quiz_obj;
        quiz_obj['quiz_result'] = quiz_res_obj;
        quiz_obj['questions'] = questions["question"];
        params.report = quiz_obj;
        params.mode = "all";
        params.replace_item = "#quiz_wrapper";
        params.replace = true;
        params.message = message;
        params.THIS = THIS;
        if (typeof attempts != "undefined") {
            quiz_obj['attempts'] = attempts;
            params.attempts = attempts;
        }
        if (typeof QUIZ_TIMER != 'undefined') {
            window.clearInterval(QUIZ_TIMER);
        }
        QUIZ_TIMER = null;
        THIS.MAX_TIME = null;
        THIS.flagCount = [];
        $('.quiz_menu_button ').addClass('hidden');
        CONTROLLER.setVariable("quizreport", params);
            $("#content_wrapper").empty();
            $("#content_wrapper").append("<div id='quiz_wrapper'></div>");
            if (CONTROLLER.activeViewName == "Course") {
                $("#quiz_wrapper").addClass('test_series_toc_quiz');
            }
            $("#search_wrapper,#sync_wrapper,#right-menu-icon").addClass("hidden");
        var mode = getQueryParameter('mode',LOCATION_URL);
        if(typeof mode != 'undefined' && mode == "adaptivelearning"){
            Client.showProgressBar(t("Loading") + "...");
            setTimeout(function () {
                CONTROLLER.setVariable("quizreport", params);
                CONTROLLER.loadView("TestSummary");
                Client.closeProgressBar();
            }, 200);
            $("#wrapper").css("overflow", "");
            $("#quiz_wrapper").css({ "overflow": "", "margin-top": "0px" });
        }
        else if (quiz_obj.type != 'undefined' && quiz_obj.type == 'practice') {
            CONTROLLER.loadChildView('Report', function (view) {
                CONTROLLER.setVariable("resultType", "practice");
                view.render(params);
            });
        } else {
            if (typeof quiz_obj != "undefined" && typeof quiz_obj.show_results != "undefined" && (parseInt(quiz_obj.show_results)) > Math.round((getSyncedServerTime() / 1000))) {
                if (quiz_obj.show_basic_user_stats != "undefined" && quiz_obj.show_basic_user_stats == "1") {
                    Client.showProgressBar(t("Loading") + "...");
                    setTimeout(function () {
                        params.show_results = quiz_obj.show_results;
                        CONTROLLER.setVariable("quizreport", params);
                        CONTROLLER.loadView("UserStatistics");
                        Client.closeProgressBar();
                        $('#footer_wrapper,#search_wrapper').addClass('hidden');
                    }, 200);
                } else {
                    var date = (new Date((parseInt(quiz_obj.show_results)) * 1000));
                    var period = date.getHours() >= 12 ? 'PM' : 'AM';
                    $('#quiz_wrapper').empty();
                    $('#quiz_wrapper').append('<div id="end_dtate_message">Thank you for taking quiz. Results will be announced after' + " " + (date.toString().replace(/GMT.*/g, period)) + '</div>');
                    $('#footer_wrapper,#search_wrapper').addClass('hidden');
                }
            }
            else if ((typeof quiz_obj.quiz_result != 'undefined' && typeof quiz_obj.quiz_result.eval_completed != 'undefined' && quiz_obj.quiz_result.eval_completed == false
                && typeof quiz_obj.quiz_result.is_evaluated != 'undefined' && quiz_obj.quiz_result.is_evaluated == '0')) {

                $('#quiz_wrapper').empty();
                $('#footer_wrapper,#search_wrapper').addClass('hidden');
                if (typeof quiz_obj.inprogress_result_template != 'undefined' && quiz_obj.inprogress_result_template != '') {
                    quiz_obj.inprogress_result_template = replaceParamsinTemplate(quiz_obj.inprogress_result_template,"",params);
                    $('.title_text').text(quiz_obj.title);
                    $('#quiz_wrapper').append('<div id="quiz_eval_inprogress">' + quiz_obj.inprogress_result_template + '</div>');
                    //removed as it is overlaping with the current topic header in toc
                    $('#quiz_eval_inprogress').css({ 'height': '90vh' });
                } else {
                    $('#quiz_wrapper').append('<div id="quiz_eval_inprogress" class="eval_inprogress_msg">' + QUIZ_EVAL_INPROGRESS + '</div>');
                }
            }
            else {
                if(Platform.getNetworkState() == false){
                    Client.closeProgressBar();
                    alert(INTERNET_REQUIRED_FOR_REPORTS, function() {
                        if (CONTROLLER.activeViewName == "Course") {
                            CONTROLLER.loadView('Course', CONTROLLER.getVariable("activecourseid"))
                        } else {
                            CONTROLLER.onBack();
                        }
                    });
                    CONTROLLER.setVariable("quizreport","");
                    return true;
                }else if (typeof ENABLE_TESTSUMMARY_FROM_COURSE != "undefined" && ENABLE_TESTSUMMARY_FROM_COURSE == "true" && quiz_obj.display_feedback == 1 && quiz_obj.result_tabs.length > 0) {
                    Client.showProgressBar(t("Loading") + "...");
                    setTimeout(function () {
                        CONTROLLER.setVariable("quizreport", params);
                        CONTROLLER.loadView("TestSummary");
                        Client.closeProgressBar();
                    }, 200);
                    $("#wrapper").css("overflow", "");
                    $("#quiz_wrapper").css({ "overflow": "", "margin-top": "0px" });
                } else {
                    CONTROLLER.loadChildView('Report', function (view) {
                        $('#wrapper').css({ 'overflow': 'scroll' });
                        THIS.currentQuestionView = view;
                        setTimeout(function () {
                            view.renderCertificateReport(params);
                            $('#footer_wrapper,#search_wrapper').addClass('hidden');
                        }, 200);
                    });
                }
            }
        }
        Client.closeProgressBar();
        return true;
    } catch (e) {
        Client.closeProgressBar();
        console.log("showQuizResult - " + e.message);
        return false;
    }
};

platformView.prototype.loadContent = function (params) {
    try {
        var THIS = this;
        var resource = params['resource'];
        var resourceid = params['resourceid'];
        var chapterid = params['chapterid'];
        var courseid = params['courseid'];
        var catid = params['catid'];
        var groupid = params['groupid'];
        var batchSchedule = params['batchSchedule'];
        if(typeof params["title"] == "undefined"){
            params["title"] = resource["title"];
        }
        if (resource.type == null) {
            alert(t("CONTENT_NOT_SUPPORTED", true));
            Client.closeProgressBar();
            return;
        }
        if (typeof params['mode'] == "undefined" || params['mode'] != "task") {
            if (typeof SEQUENTIAL_LEARNING_ENABLED != "undefined" && SEQUENTIAL_LEARNING_ENABLED == true && this.isVideoViewable(groupid, courseid, chapterid, resourceid, catid) == false) {
                return false;
            }
            if (CONTROLLER.getVariable("recentdownloadsview") == true) {
                CONTROLLER.setVariable("previousView", "RecentDownloads");
            }
            if (typeof batchSchedule == "undefined") {
                var courseService = CONTROLLER.getService("Course");
                batchSchedule = courseService.getBatchScheduleOfCourse(groupid, courseid);
            }
            if (!$.isEmptyObject(batchSchedule[OBJECT_COURSE]) && this.checkScheduleOfChaptersInCourse(batchSchedule, chapterid, true) == false) {
                return false;
            }
        }
        if (USER_PRODUCT_TYPE == "online" && Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR", true));
            return false;
        }
        if (USER_PRODUCT_TYPE == "offline") {
            var sdcardcompatibility = checkSdcardCompatibility(courseid, catid);
            if (sdcardcompatibility != true) {
                return false;
            }
        }
        Client.showProgressBar(t("Loading") + "...");
        if (typeof RESOURCE_TYPE_LINKED_OBJECT != "undefined" && resource['type'] == RESOURCE_TYPE_LINKED_OBJECT) {
            params = this.getLinkedObjectParams(resource, params);
            resource.type = resource['linkedobject_type'];
            // checking if linked object available for the user group
            if (THIS.isLinkedObjectAvailable(params) == false) {
                var no_content_msg = 'No ' + OBJECT_VIDEO + ' available';
                if (typeof resource.type != 'undefined') {
                    no_content_msg = 'No ' + resource.type + ' available';
                    if (resource.type == RESOURCE_TYPE_ASSIGNMENT) {
                        no_content_msg = 'No ' + OBJECT_ASSIGNMENT.capitalizeFirstLetter() + ' available';
                    }
                };
                Client.closeProgressBar();
                alert(no_content_msg);
                return false;
            }
        }

        if (typeof CATEGORY_DETAILS != 'undefined' && CATEGORY_DETAILS.length > 0 && APP_TYPE == 'android') {
            for(key in CATEGORY_DETAILS){
                if (CATEGORY_DETAILS[key].nid == catid) {
                    if (typeof CATEGORY_DETAILS[key].mark_content != 'undefined' && CATEGORY_DETAILS[key].mark_content == '1') {
                        SHOW_MARK_CONTENT = true;
                    }else{
                        SHOW_MARK_CONTENT = false;
                    }
                    break;
                }
            }
        }
        if (typeof CATEGORY_DETAILS == 'undefined' || CATEGORY_DETAILS == null || CATEGORY_DETAILS.length == 0 && APP_TYPE == 'android') {
            var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY,catid,"content"));
            if(typeof category != 'undefined' && category != null && typeof category.mark_content != 'undefined' && category.mark_content == 1){
                SHOW_MARK_CONTENT = true;
            }else{
                SHOW_MARK_CONTENT = false;
            }
        }
        setTimeout(function () {
            if(typeof params.type != "undefined" && params.type == OBJECT_SUPPORT_DOCS){
                var isSupportDocs = true;
            }
            if (resource.type === "Document") {
                resource.courseid = courseid;
                resource.chapterid = chapterid;
                var contenttype_ebook = OBJECT_BOOK;
                var isResourcePlayable = THIS.isResourcePlayable(resource.nid, courseid, OBJECT_BOOK, catid, true, params, ' ', contenttype_ebook, isSupportDocs);
                if (isResourcePlayable == false) {
                    Client.closeProgressBar();
                    return false;
                }
            } else {
                var isResourcePlayable = THIS.isResourcePlayable(resourceid, courseid, OBJECT_VIDEO, catid, true, params, '', '', isSupportDocs);
                if (isResourcePlayable == false) {
                    Client.closeProgressBar();
                    return false;
                }
            }
            if (resource.type == RESOURCE_TYPE_WEBINAR && THIS.checkWebinarStatus(resource, params, isResourcePlayable)) {
                return false;
            }
            // checking for the video and loading videoplayer view 
            if(resource.type == "Video"){
                var isResourcePlayable = THIS.isResourcePlayable(resourceid, courseid, OBJECT_VIDEO, catid, true, params);
                Client.closeProgressBar();
                if (isResourcePlayable == false) {
                    $("#topic_title_wrapper_" + resourceid).css("opacity", "1");
                    return false;
                }
                else{
                    if(typeof LOAD_VIDEO_PLAYER_VIEW != 'undefined' && LOAD_VIDEO_PLAYER_VIEW) {
                        var resourceService =  CONTROLLER.getService("Resource");
                        var resource_data = $.parseJSON(resourceService.getObjectContentProperties(OBJECT_VIDEO, params.resourceid));
                        var checkForPreviousView = CONTROLLER.getVariable("previousView");
                        if(typeof checkForPreviousView != 'undefined' && checkForPreviousView != "AllSubjects" && CONTROLLER.activeViewName != "Course"){
                            CONTROLLER.loadView("Resource", params);
                        }else{
                            if(resource_data.source == RESOURCE_TYPE_LINK && resource_data.type != RESOURCE_TYPE_WEBINAR) {
                                CONTROLLER.loadView("Resource", params);
                            } else {
                                SHOW_PORTRAIT_VIDEO = true;
                                params['isResourcePlayable'] = isResourcePlayable;
                                CONTROLLER.loadView("VideoPlayer", params);
                            }
                        }
                    }else {
                        CONTROLLER.loadView("Resource", params);
                    }
                    Client.closeProgressBar();
                }
            } else if(resource.type == RESOURCE_TYPE_QUIZ){
                if(USER_PRODUCT_TYPE == "online"){
                    if(Platform.getNetworkState() == false){
                        alert("Internet is required.");
                        $("#topic_title_wrapper_" + resourceid).css("opacity", "1");
                        Client.closeProgressBar();
                        return;
                    }
                }
                params.type = RESOURCE_TYPE_QUIZ;
                CONTROLLER.loadView("Resource",params);
            } else if (resource.type == RESOURCE_TYPE_ASSESSMENT) {
                SINGLE_QUESTION = false;
                LOAD_QUIZ_ONLINE = false;
                SYNCED_QUESTION_DATA = false;
                if(typeof params.type != "undefined" && params.type != "" && params.type == OBJECT_SUPPORT_DOCS){
                    var content = Platform.getObjectProperty(OBJECT_SUPPORT_DOCS, resourceid, "content");
                }else{
                    var content = Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content");
                }
                var content = $.parseJSON(content);
                var ctitle = content.title;
                CONTROLLER.setVariable("resourcetitle", ctitle);
                var quizService = CONTROLLER.getService("Quiz");
                var quizId = content['url'];
                if (params['isLinkedObject']) {
                    quizId = params.linkedobject_item;
                }
                var quiz = quizService.getQuiz(quizId);
                CONTROLLER.setVariable('track_practice_details', { "practice_test_name": quiz.clipping_nid, "practice_test_id": quiz.clipping });
                if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
                    if (quiz.type == 'practice') {
                        trackScreenEventDetails({ 'context': 'practice test', 'event_name': 'TB_TAP_PRACTICE_TEST_NAME' });
                    }
                }
                var quiz_id = 'quiz_' + quiz.nid;
                Platform.setPreference(quiz_id, JSON.stringify(quiz));
                THIS.setDownloadQuizType(quiz);
                if (quiz == INTERNET_ERROR) {
                    alert(t("INTERNET_ERROR", true));
                    Client.closeProgressBar();
                    return false;
                }
                if (typeof quiz == "undefined" || quiz == "") {
                    alert(t("ERROR_MESSAGE", true));
                    Client.closeProgressBar();
                    return false;
                }
                if (typeof quiz != 'undefined' && quiz != "" && quiz != 'error') {
                    var isQuizViewable = THIS.isQuizViewable(quiz, quiz.type);
                    if (isQuizViewable) {
                        Client.showProgressBar(t("Loading") + "...");
                        if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION == true) {
                            if (Platform.getNetworkState() == true) {
                                var questions = THIS.downloadQuestionsDataAsync(quiz, false);
                            } else if (Platform.getNetworkState() == false) {
                                var questions = THIS.downloadQuestionsDataAsync(quiz, true);
                            }
                            var last_question = Platform.getPreference('lastViewedQuestionIndex_' + quiz.nid);
                            if (typeof questions == 'undefined' || questions == "") {
                                if (typeof last_question != 'undefined' && last_question != "" && last_question != "1") {
                                    var questions = THIS.quiz.getQuestionsForQuiz(quiz.nid, "", Number(last_question), false);
                                } else {
                                    var questions = THIS.quiz.getQuestionsForQuiz(quiz.nid, "", "1", false);
                                }
                            }
                        } else {
                            var questions = quizService.getQuestionsForQuiz(quiz.nid, quiz.changed);
                        }
                        var question_for_quiz = "ques_for_quiz_" + quiz.nid;
                        Platform.setPreference(question_for_quiz, JSON.stringify(questions));
                        if ((typeof questions == 'undefined' || questions == '') ||
                            (typeof questions['question'] !== 'undefined' && questions['question'].length == 0)) {
                            alert(t("NO_QUESTIONS", true));
                            Client.closeProgressBar();
                            return false;
                        } else if (questions != "") {
                            CONTROLLER.loadView("Resource", params);
                        } else {
                            alert(t("QUIZ_ERROR_MESSAGE", true));
                            Client.closeProgressBar();
                            return false;
                        }
                    }
                } else {
                    alert(t("QUIZ_ERROR_MESSAGE", true));
                    Client.closeProgressBar();
                    return false;
                }
            } else if (typeof RESOURCE_TYPE_TEST_COLLECTION != 'undefined' && resource.type == RESOURCE_TYPE_TEST_COLLECTION) {
                THIS.renderTestCollection(params);
            } else if (resource.type.toLowerCase() == "html") {
                if (resource.description) {
                    CONTROLLER.loadView("Resource", params);
                } else {
                    alert(t("RESOURCE_ERROR", true));
                    Client.closeProgressBar();
                    return false;
                }
            } else if(typeof RESOURCE_TYPE_FLASHCARD != 'undefined' && resource.type == RESOURCE_TYPE_FLASHCARD){
                THIS.renderFlashCard(params);
            } else {
                CONTROLLER.setVariable('resource_params', params);
                CONTROLLER.loadView("Resource", params);
                Client.closeProgressBar();
            }
            return true;
        });
    } catch (e) {
        console.log("function : loadContent : " + e.message);
    }
};

platformView.prototype.getLinkedObjectParams = function (resource, params) {
    try {
        var newParams = $.extend(true, {}, params);
        if (resource['linkedobject_items'].length > 0) {
            for (var i = 0; i < resource['linkedobject_items'].length; i++) {
                if (OBJECT_USER_GROUPS.indexOf(resource['linkedobject_items'][i]['group_nid']) > -1) {
                    newParams.isLinkedObject = true;
                    newParams.linkedobject_nid = resource['linkedobject_nid'];
                    newParams.linkedobject_type = resource['linkedobject_type'];
                    newParams.linkedobject_item = resource['linkedobject_items'][i]['item'];
                    break;
                }
            }
        }
        return newParams;
    } catch (e) {
        console.log("funcion - getLinkedObjectParams : " + e.message);
        return params;
    }
}

platformView.prototype.unsetLinkedObjectParams = function (params) {
    try {
        var newParams = $.extend(true, {}, params);
        delete newParams['isLinkedObject']
        delete newParams['linkedobject_nid'];
        delete newParams['linkedobject_type'];
        delete newParams['linkedobject_item'];
        return newParams;
    } catch (e) {
        console.log("funcion - unsetLinkedObjectParams : " + e.message);
        return params;
    }
}

platformView.prototype.loadSigninPage = function () {
    var userService = CONTROLLER.getService("User");
    $('body').html('');
    $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
             <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
    Platform.saveObject("currentuser", 1, "content", "");
    if (Platform.getNetworkState() == true) {
        userService.logout();
    }
    CONTROLLER.loadView("Signin");
    return false;
};

platformView.prototype.unsetTaskQuiz = function () {
    SHOW_PORTRAIT_VIDEO = false;
    CONTROLLER.setVariable('task_course_id', "");
    CONTROLLER.setVariable('task_cat_id', "");
    // unset chatbot variable
    CONTROLLER.setVariable('loaded_chatbot', '');
}

platformView.prototype.playWebinarVideo = function (webinar_record_id) {
    try {
        var THIS = this;
        var params = { "resourceid": webinar_record_id, "type": OBJECT_VIDEO_WEBINAR };
        var objtype = Platform.getObjectType(webinar_record_id);
        var resource = $.parseJSON(Platform.getObjectProperty(objtype, webinar_record_id, "content"));
        if (resource == null) {
            if (Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                return;
            }
            resource = THIS.resource.getResourceInfoById(webinar_record_id, "true");
            if (resource == "error") {
                return;
            }
        }
        if (resource != null) {
            var isResourcePlayable = THIS.isResourcePlayable(params.resourceid, "", OBJECT_VIDEO_WEBINAR, "", true, params);
            Client.closeProgressBar();
            if (isResourcePlayable == false) {
                return false;
            } else {
                CONTROLLER.loadView("Resource", params);
            }
        }
    } catch (e) {
        console.log('playwebinarvideo' + e);
    }
}

platformView.prototype.setTaskCourseId = function (params) {
    try {
        var THIS = this;
        var task_data = THIS.tasks.getTaskDataFromId(params.id);
        if (params.resource_type == OBJECT_QUIZ.capitalizeFirstLetter()) {
            var quiz_data = THIS.contentUtils.getQuizFromId(params.nid);
            if (quiz_data != ERROR_MESSAGE) {
                quiz_data = $.parseJSON(quiz_data);
                params.catid = quiz_data[0]['courses'][0];
            }
        } else {
            var content_data = THIS.contentUtils.getContentFromId(params.nid);
            if (content_data != ERROR_MESSAGE) {
                content_data = $.parseJSON(content_data);
                params.catid = content_data[0][OBJECT_CATEGORY][0];
            }
        }
        if (task_data != ERROR_MESSAGE) {
            task_data = $.parseJSON(task_data);
            params.courseid = task_data['course_id'];
        }
        params.courseid = getCategoryNidFromTid(params.courseid);
        CONTROLLER.setVariable('task_course_id', params.catid);
        CONTROLLER.setVariable('task_cat_id', params.courseid);
    } catch (e) {
        console.log('setTaskCourseId' + e);
    }
}

platformView.prototype.isLinkedObjectAvailable = function (params) {
    try {
        var linkedObjects = params.resource.linkedobject_items;
        for (var i = 0; i < OBJECT_USER_GROUPS.length; i++) {
            if (JSON.stringify(linkedObjects).includes(OBJECT_USER_GROUPS[i])) {
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log('isLinkedObjectAvailable' + e);
    }
}



platformView.prototype.redownloadOrDeleteDownloadedContent = function (id, params, supportdocs = false) {
    var THIS = this;
    var nid = id;
    var type = "", contentdata;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var resource;
    var activeViewName = CONTROLLER.activeViewName;
    if (supportdocs || (typeof activeViewName != 'undefined' && activeViewName == 'SupportDocsList') ) {
        resource = $.parseJSON(Platform.getObjectProperty(OBJECT_SUPPORT_DOCS, nid, "content"));
    } else {
        resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, nid, "content"));
    }
    var courseid = CONTROLLER.getVariable("activecourseid");
    if (resource.type == OBJECT_QUIZ || resource.type == RESOURCE_TYPE_ASSESSMENT) {
        nid = resource.url;
        type = resource.type;
        contentdata = resource;
        resource = quizService.getQuiz(nid);
    }
    else {
        nid = resource.nid;
        type = OBJECT_VIDEO;
    }
    if (supportdocs || (typeof activeViewName != 'undefined' && activeViewName == 'SupportDocsList') ) {
        type = OBJECT_SUPPORT_DOCS;
    }
    if (CONTROLLER.getVariable("recentdownloadsview") == true) {
        courseid = params.courseid;
        chapterid = params.chapterid;
        CONTROLLER.setVariable("activecatid", params.catid);
    }
    var status = THIS.contentUtils.checkResourceDownloadStatus(nid, type, courseid);
    if (status == 1 || status == 3) {
        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
            if (typeof ENABLE_SMARTBOX == "undefined" || ENABLE_SMARTBOX == "false" || (ENABLE_SMARTBOX == "true" && TRIAL_USER == "false")) {
                if (USER_PRODUCT_TYPE == "online" && Platform.getNetworkState() == false) {
                    alert(t("INTERNET_ERROR", true));
                    return;
                }
                Client.showProgressBar(t("Loading") + "...");
                var isResourcePlayable = THIS.isResourcePlayable(id, courseid, OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
                Client.closeProgressBar();
                if (isResourcePlayable == false) {
                    return false;
                }
            }
        }
        if ((status == 1 || status == 3)) {
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                resource.contentid = nid;
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this " + OBJECT_QUIZ + "?"),
                    resource, "", "", resource.title + t(" downloaded successfully"), courseid, OBJECT_QUIZ, contentdata);
            } else {
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this file?"),
                    resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO);
            }
        }
        else {
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                resource.contentid = nid;
            }
            confirm(t("Are you sure, you want to re-download this file?"), function (e) {
                contentUtils.fileDownload(resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO);
                if (typeof contentdata != 'undefined') {
                    contentUtils.showProgressBar(contentdata.nid);
                    contentUtils.showInProgressMessage(contentdata);
                }
                e.stopPropagation();
                return false;
            });
        }
    }
}


platformView.prototype.bindDownloadResourceClick = function () {
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var groupid = CONTROLLER.getVariable("activegroupid");
    var THIS = this;
    $('.download_button').unbind('click').bind('click', function (e) {
        var resourceid = $(this).attr("nid");
        var chapterid = $(this).parents('.chapter').attr("nid");
        var courseid = CONTROLLER.getVariable("activecourseid");
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content"));
        if (CONTROLLER.getVariable("recentdownloadsview") == true) {
            params = {};
            var chapterid = resource[OBJECT_CHAPTER][0];
            var courseid = $.parseJSON(Platform.getObjectFromRelations(OBJECT_CHAPTER, resource[OBJECT_CHAPTER][0], OBJECT_COURSE))[OBJECT_COURSE][0]["objectid"];
            params = { "chapterid": chapterid, "courseid": courseid, "catid": resource[OBJECT_CATEGORY][0] };
        }
        if (USER_PRODUCT_TYPE == "offline") {
            var sdcardcompatibility = checkSdcardCompatibility(courseid, THIS.catid);
            if (sdcardcompatibility != true) {
                return "";
            }
        }
        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
            var batchSchedule = THIS.course.getBatchScheduleOfCourse(groupid, courseid);
            if (!$.isEmptyObject(batchSchedule[OBJECT_COURSE]) && THIS.checkScheduleOfChaptersInCourse(batchSchedule, chapterid, true) == false) {
                $("#topic_title_wrapper_" + resourceid).css("opacity", "1");
                return false;
            }
            if (USER_PRODUCT_TYPE == "online" && Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                return;
            }
            Client.showProgressBar(t("Loading") + "...");
            var isResourcePlayable = THIS.isResourcePlayable(resourceid, courseid, OBJECT_VIDEO, THIS.catid, "", "", "true");
            Client.closeProgressBar();
            if (isResourcePlayable == false) {
                return false;
            }
        }
        if (resource.type == "Document") {

            if ($(this).hasClass("download")) {
                contentUtils.fileDownload(resource, DOCUMENT_FORMAT, "", resource.title + t(" downloaded successfully"), CONTROLLER.getVariable("activecourseid"), OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
                e.stopPropagation();
                return false;
            } else if ($(this).hasClass("inprogress")) {
                THIS.showDownloadActionDialog(resource);
                e.stopPropagation();
                return false;
            }
            else if ($(this).hasClass("delete")) {
                var status = THIS.contentUtils.checkResourceDownloadStatus(resourceid, OBJECT_VIDEO, courseid);
                if ((status == 1 || status == 3)) {
                    THIS.redownloadOrDeleteDownloadedContent(resourceid, params);
                }
                else {
                    confirm(t("Are you sure, you want to re-download this file?"), function () {
                        contentUtils.fileDownload(resource, DOCUMENT_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, THIS.catid);
                        e.stopPropagation();
                        return false;
                    });
                }
                e.stopPropagation();
                return false;
            }
        } else if (resource.type == RESOURCE_TYPE_ASSESSMENT) {
            var content = Platform.getObjectProperty(OBJECT_VIDEO, resourceid, "content");
            content = $.parseJSON(content);
            var quiz = quizService.getQuiz(content.url);
            quiz['contentid'] = resourceid;
            if (typeof quiz == "undefined" || quiz == "") {
                alert(t("ERROR_MESSAGE", true));
                $("#topic_title_wrapper_" + resourceid).css("opacity", "1");
                Client.closeProgressBar();
            }
            if ($(this).hasClass("download")) {
                contentUtils.fileDownload(quiz, "", "", resource.title + t(" downloaded successfully"), courseid, OBJECT_QUIZ, CONTROLLER.getVariable("activecatid"));
                contentUtils.showProgressBar(resource.nid);
                contentUtils.showInProgressMessage(resource);
                e.stopPropagation();
                return false;
            } else if ($(this).hasClass("inprogress")) {
                THIS.showDownloadActionDialog(quiz, resourceid);
                e.stopPropagation();
                return false;
            }
            else if ($(this).hasClass("delete") && USER_PRODUCT_TYPE != "offline") {
                THIS.bindRedownloadResourceClick(resourceid);
                e.stopPropagation();
                return false;
            }

        }
        else if ($(this).hasClass("download")) {
            var isResourcePlayable = THIS.isResourcePlayable(resourceid, courseid, OBJECT_VIDEO, THIS.catid, "", "", "true");
            if (isResourcePlayable == false) {
                Client.closeProgressBar();
                return false;
            }
            if (typeof ENABLE_DOWNLOAD_RESOLUTION_SWITCH != 'undefined' && ENABLE_DOWNLOAD_RESOLUTION_SWITCH == true
                && (resource.type == RESOURCE_TYPE_WEBINAR ||
                    (resource.source == "Upload" && resource.type == "Video"))) {
                var prompt_resolution = Platform.getPreference("prompt_resolution");
                if (resource.type == RESOURCE_TYPE_WEBINAR) {
                    if (typeof resource.webinar_record_id !== "undefined" && resource.webinar_record_id != "") {
                        var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR, resource.webinar_record_id, "content"));
                        videoFormat = webinarRecordObject["format-type"];
                    }
                }
                if (typeof DOWNLOAD_RESOURCE_FORMAT == "undefined" || (typeof DOWNLOAD_RESOURCE_FORMAT != "undefined" && DOWNLOAD_RESOURCE_FORMAT == "")) {
                    if (resource.type == RESOURCE_TYPE_WEBINAR) {
                        select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                    } else {
                        select_video_resolution_for_download(false, resource, courseid, CONTROLLER.getVariable("activecatid"));
                    }
                } else {
                    if (resource.type == "Video" && typeof resource['format-type'] != "undefined" && resource['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) > -1 && prompt_resolution != "true") {
                        contentUtils.fileDownload(resource, DOWNLOAD_RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
                    }
                    else if (resource.type == RESOURCE_TYPE_WEBINAR && typeof webinarRecordObject['format-type'] != "undefined" && webinarRecordObject['format-type'].indexOf(DOWNLOAD_RESOURCE_FORMAT) > -1 && prompt_resolution != "true") {
                        contentUtils.fileDownload(resource, DOWNLOAD_RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
                    }
                    else {
                        if (resource.type == RESOURCE_TYPE_WEBINAR) {
                            select_video_resolution_for_download(false, webinarRecordObject, courseid, CONTROLLER.getVariable("activecatid"));
                        } else {
                            select_video_resolution_for_download(false, resource, courseid, CONTROLLER.getVariable("activecatid"));
                        }
                    }

                }
            } else {
                contentUtils.fileDownload(resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, CONTROLLER.getVariable("activecatid"));
            }
            e.stopPropagation();
            return false;
        } else if ($(this).hasClass("inprogress")) {
            THIS.showDownloadActionDialog(resource);
            e.stopPropagation();
            return false;
        } else if ($(this).hasClass("delete")) {
            var status = THIS.contentUtils.checkResourceDownloadStatus(resourceid, OBJECT_VIDEO, courseid);
            if ((status == 1 || status == 3)) {
                THIS.redownloadOrDeleteDownloadedContent(resourceid, params);
            }
            else {
                confirm(t("Are you sure, you want to re-download this file?"), function () {
                    contentUtils.fileDownload(resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO, THIS.catid);
                    e.stopPropagation();
                    return false;
                });
            }
            e.stopPropagation();
            return false;
        }
        e.stopPropagation();
    });
}


platformView.prototype.bindDownloadQuizClick = function (e) {
    var quizService = CONTROLLER.getService("Quiz");
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var THIS = this;
    $('#download_quiz,#download_quiz_certificate').unbind('click').bind('click', function (e) {
        var resourceid = $(this).attr("nid");
        var courseid = CONTROLLER.getVariable("activecourseid");
        var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_QUIZ, resourceid, "content"));
        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
            if ((USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") && Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                return;
            }
            Client.showProgressBar(t("Loading") + "...");
            var isResourcePlayable = THIS.isResourcePlayable(resourceid, courseid, OBJECT_QUIZ, THIS.catid, "", "", "true");
            Client.closeProgressBar();
            if (isResourcePlayable == false) {
                return false;
            }

            if ($(this).hasClass("download")) {
                contentUtils.fileDownload(resource, "", "", resource.title + t(" downloaded successfully"), courseid, OBJECT_QUIZ, CONTROLLER.getVariable("activecatid"));
                e.stopPropagation();
                return false;
            } else if ($(this).hasClass("inprogress")) {
                THIS.showDownloadActionDialog(resource);
                e.stopPropagation();
                return false;
            }
            else if ($(this).hasClass("delete") && USER_PRODUCT_TYPE != "offline") {
                THIS.bindReDownloadQuizClick(resourceid);
                e.stopPropagation();
                return false;
            }
        }
    });
}


platformView.prototype.bindRedownloadResourceClick = function (id) {
    var THIS = this;
    var nid = id;
    var resnid = id;
    var type = "", contentdata;
    var contentUtils = CONTROLLER.getService("ContentUtils");
    var quizService = CONTROLLER.getService("Quiz");
    var resource = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO, nid, "content"));
    var courseid = CONTROLLER.getVariable("activecourseid");
    if (resource.type == RESOURCE_TYPE_ASSESSMENT) {
        nid = resource.url;
        type = resource.type;
        contentdata = resource;
        resource = quizService.getQuiz(nid);
        var youtubeid = resource.changed;
    }
    else {
        nid = resource.nid;
        type = OBJECT_VIDEO;
        var youtubeid = resource.changed;
    }
    var status = THIS.contentUtils.checkResourceDownloadStatus(nid, type, courseid, youtubeid);
    if (status == 1 || status == 3) {
        if (USER_PRODUCT_TYPE == "online" || USER_PRODUCT_TYPE == "hybrid") {
            if (USER_PRODUCT_TYPE == "online" && Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR", true));
                return;
            }
            Client.showProgressBar(t("Loading") + "...");
            var isResourcePlayable = THIS.isResourcePlayable(id, courseid, OBJECT_VIDEO, THIS.catid);
            Client.closeProgressBar();
            if (isResourcePlayable == false) {
                return false;
            }
        }
        if ((status == 1 || status == 3)) {
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                resource.contentid = nid;
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this " + OBJECT_QUIZ + "?"),
                    resource, "", "", resource.title + t(" downloaded successfully"), courseid, OBJECT_QUIZ, contentdata);
            } else {
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this file?"),
                    resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO);
            }
        }
        if ((status == 1 || status == 3)) {
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                resource.contentid = nid;
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this " + OBJECT_QUIZ + "?"),
                    resource, "", "", resource.title + t(" downloaded successfully"), courseid, OBJECT_QUIZ, contentdata);
            } else {
                THIS.reDownloadAndDeleteDialog(t("Are you sure, you want to re-download or delete this file?"),
                    resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO);
            }
        }

        else {
            if (resource.type == PRACTICE || resource.type == CERTIFICATE || resource.type == RESOURCE_TYPE_ASSESSMENT) {
                resource.contentid = nid;
            }
            confirm(t("Are you sure, you want to re-download this file?"), function (e) {
                contentUtils.fileDownload(resource, RESOURCE_FORMAT, "", resource.title + t(" downloaded successfully"), courseid, OBJECT_VIDEO);
                if (typeof contentdata != 'undefined') {
                    contentUtils.showProgressBar(contentdata.nid);
                    contentUtils.showInProgressMessage(contentdata);
                }
                e.stopPropagation();
                return false;
            });
        }
    }

}

platformView.prototype.getAllCoursesToRender = function (catid) {
    var user_groups = OBJECT_USER_GROUPS;
    var groupid = CONTROLLER.getVariable("activegroupid");
    if (HIDE_GROUPS_FILTER == "true") {
        var allcourses = [];
        allcourses[OBJECT_COURSE] = [];
        var allcourses;
        var courses = [];
        courses[OBJECT_COURSE] = [];

        if (typeof MAIN_GROUPS != 'undefined') {
            var main_groups = this.checkMultipleParentGroups();
            if (main_groups > 1) {
                var allcourses = this.category.getCoursesForCategory(catid, CONTROLLER.getVariable("activegroupid"));
                for (var l = 0; l < allcourses[OBJECT_COURSE].length; l++) {
                    if (jsonArrayHasId(courses[OBJECT_COURSE], allcourses[OBJECT_COURSE][l].nid) == false) {
                        courses[OBJECT_COURSE].push(allcourses[OBJECT_COURSE][l]);
                    }
                }
            } else {
                courses = this.getCoursesOfAllGroups(catid, user_groups, courses);
            }
        } else {
            courses = this.getCoursesOfAllGroups(catid, user_groups, courses);
        }
    } else {
        var courses = this.category.getCoursesForCategory(catid, groupid);
    }

    var category = $.parseJSON(Platform.getObjectProperty(OBJECT_CATEGORY, catid, "content"));

    if (category.hide_label) {
        this.hide_label = category.hide_label;
    } else {
        this.hide_label = "0";
    }

    CONTROLLER.setVariable("activecatobj", category);
    if (USER_PRODUCT_TYPE == "offline") {
        var chaptersofsdcard = this.toc.getChaptersOfSdCard();
        if (chaptersofsdcard != "") {
            if (typeof chaptersofsdcard[category.title] == "undefined" || chaptersofsdcard[category.title] == "") {
                chaptersofsdcard = "";
            }
            else {
                chaptersofsdcard = chaptersofsdcard[category.title];
            }
        }
    }
    else {
        chaptersofsdcard = "";
    }

    subscribedcourses = this.getSubscribedCourses(courses, chaptersofsdcard, this.hide_label);

    if (subscribedcourses[OBJECT_COURSE].length == 0) {
        if (typeof MAIN_GROUPS != 'undefined') {
            //check user has any other active groups product content
            if (main_groups > 1) {
                for (var i = 1; i < MAIN_GROUPS.length; i++) {
                    if (!groupid) {
                        groupid = MAIN_GROUPS[i]['nid'];
                    }
                    var allcourses = this.category.getCoursesForCategory(catid, groupid);
                    for (var l = 0; l < allcourses[OBJECT_COURSE].length; l++) {
                        if (jsonArrayHasId(courses[OBJECT_COURSE], allcourses[OBJECT_COURSE][l].nid) == false) {
                            courses[OBJECT_COURSE].push(allcourses[OBJECT_COURSE][l]);
                        }
                    }
                    subscribedcourses = this.getSubscribedCourses(courses, chaptersofsdcard);
                    if (subscribedcourses[OBJECT_COURSE].length != 0) {
                        break;
                    }
                }
                if (subscribedcourses[OBJECT_COURSE].length == 0 && groupid) {
                    HIDE_MENUS = "true"
                }
            } else {
                HIDE_MENUS = "true";
            }
        } else {
            HIDE_MENUS = "true";
        }
    }
    this.getAllCourses(catid, subscribedcourses);
    subscribedcourses['courses'] = subscribedcourses[OBJECT_COURSE];
    subscribedcourses[OBJECT_CATEGORIES] = subscribedcourses[OBJECT_COURSE];
    return { 'subscribedcourses': subscribedcourses, 'groupid': groupid }
}


platformView.prototype.getCoursesOfAllGroups = function (catid, user_groups, courses) {
    if (GET_FROM_COURSE_DETAILS_API && $.isFunction(Platform.getCourseDetails)) {
        if (COURSE_TOPIC_DETAILS.hasOwnProperty(OBJECT_COURSE + "_" + catid)) {
            courses[OBJECT_COURSE] = COURSE_TOPIC_DETAILS[OBJECT_COURSE + "_" + catid];
        } else {
            var stringformatgroups = user_groups.toString();
            courses[OBJECT_COURSE] = JSON.parse(Platform.getCourseDetails(stringformatgroups, catid, false))[0][OBJECT_COURSE];
            if (courses[OBJECT_COURSE].length > 0) COURSE_TOPIC_DETAILS[OBJECT_COURSE + "_" + catid] = courses[OBJECT_COURSE];
        }
    } else {
        for (var k = 0; k < user_groups.length; k++) {
            var allcourses = this.category.getCoursesForCategory(catid, user_groups[k]);
            for (var l = 0; l < allcourses[OBJECT_COURSE].length; l++) {
                if (jsonArrayHasId(courses[OBJECT_COURSE], allcourses[OBJECT_COURSE][l].nid) == false) {
                    courses[OBJECT_COURSE].push(allcourses[OBJECT_COURSE][l]);
                }
            }
        }
    }

    return courses;
}


platformView.prototype.getSubscribedCourses = function (courses, chaptersofsdcard, hide_label) {
    COURSES_MAP = [];
    var j = 0;
    var subscribedcourses = [];
    COURSE_INDEX_MAP = {};
    subscribedcourses[OBJECT_COURSE] = [];
    for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
        courses[OBJECT_COURSE][i].hide_label = hide_label;
        if (typeof CHAPTER_TITLE != 'undefined') {
            courses[OBJECT_COURSE][i]['course_chapter_title'] = t(CHAPTER_TITLE + "[s]");
        } else {
            courses[OBJECT_COURSE][i]['course_chapter_title'] = t(OBJECT_CHAPTER + "[s]").capitalizeFirstLetter();
        }
        COURSES_MAP.push(courses[OBJECT_COURSE][i].nid);
        COURSE_INDEX_MAP[courses[OBJECT_COURSE][i].nid] = { "offset": i + 1, "title": courses[OBJECT_COURSE][i].title };
        chaptersubject = false;
        if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true') {
            var user_sub = this.settings.getUserSubscriptions(OBJECT_USER_ID, CONTROLLER.getVariable("activecatid"), courses[OBJECT_COURSE][i].nid);
            if (user_sub != "" && user_sub != null) {
                var subscribedcourses = this.getCourses(i, j, courses, subscribedcourses, chaptersofsdcard);
                j++;
            }
        }
        else {
            var subscribedcourses = this.getCourses(i, j, courses, subscribedcourses, chaptersofsdcard);
            j++;
        }
    }
    return subscribedcourses;
}

platformView.prototype.getCourses = function (i, j, courses, subscribedcourses, chaptersofsdcard) {
    try {
        var tocService = this.toc;
        var chaptersubject = "";
        var contentutils = CONTROLLER.getService("ContentUtils");
        subscribedcourses[OBJECT_COURSE].push(courses[OBJECT_COURSE][i]);
        var topicsCount = courses[OBJECT_COURSE][i][OBJECT_CHAPTER].length;
        subscribedcourses[OBJECT_COURSE][j].title = courses[OBJECT_COURSE][i].title;
        if (typeof subscribedcourses[OBJECT_COURSE][j]['topic'] != 'undefined' && subscribedcourses[OBJECT_COURSE][j]['topic'][0] == null) {
            subscribedcourses[OBJECT_COURSE][j].topicscount = 0;
        } else {
            subscribedcourses[OBJECT_COURSE][j].topicscount = topicsCount;
        }
        if (typeof ENABLE_NEW_UI != 'undefined' && !ENABLE_NEW_UI) {
            subscribedcourses[OBJECT_COURSE][j].posterpath = contentutils.getPosterPath(courses[OBJECT_COURSE][i].nid, courses[OBJECT_COURSE][i].thumbnail_filename, CHAPTER_IMAGE_PRESET);
        }
        subscribedcourses[OBJECT_COURSE][j].rank = parseInt(courses[OBJECT_COURSE][i].rank);
        if (CONTENT_IN_MULTIPLE_SDCARDS == true && USER_PRODUCT_TYPE == "offline") {
            if (chaptersofsdcard != "") {
                for (j = 0; j < chaptersofsdcard.length; j++) {
                    if (chaptersofsdcard[j].nid == courses[OBJECT_COURSE][i].nid) {
                        chaptersubject = true;
                    }
                }
                if (chaptersubject == true) {
                    subscribedcourses[OBJECT_COURSE][i].class = "chapter_enabled";
                }
                else {
                    subscribedcourses[OBJECT_COURSE][i].class = "chapter_disabled";
                }
            }
            else {
                subscribedcourses[OBJECT_COURSE][i].class = "chapter_disabled";
            }
            var sdcard = tocService.getTocSdCardInfo(courses[OBJECT_COURSE][i].nid);
            if (sdcard === false) {
                subscribedcourses[OBJECT_COURSE][i].class = "chapter_enabled";
            }
        }
        return subscribedcourses;
    }
    catch (e) {
        console.log("getCourses :=" + e.message);
    }
}

platformView.prototype.setDownloadQuizType = function (quiz) {
    try {
        if (typeof quiz != 'undefined' && quiz != "" && quiz != null) {
            if (typeof quiz.airplane_mode != 'undefined' && quiz.airplane_mode == "0"
                && typeof quiz.type != 'undefined' && (quiz.type == 'certificate' || quiz.type == 'practice')
                && typeof quiz.quiz_template != 'undefined' && quiz.quiz_template == "1"
                && typeof quiz.show_navigation != "undefined" && quiz.show_navigation == 0
                && typeof quiz.sections != 'undefined' && quiz.sections.length == 0) {
                SINGLE_QUESTION = true;
                LOAD_QUIZ_ONLINE = true;
            }
        }
    } catch (e) {
        console.log('platformView : setDownloadQuizType' + e);
    }
}

platformView.prototype.downloadQuestionsDataAsync = function (quiz, ques_navigation) {
    try {
        var nid = quiz.nid;
        var filepath = "/video/" + nid + "/" + nid + '_' + quiz.changed + ".quiz";
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var isFileAvailable = contentUtils.isFileAvailable(nid, quiz.changed, "", "quiz");
        console.log('isFileAvailable', isFileAvailable);
        if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new")) {
            if (typeof ques_navigation != 'undefined' && ques_navigation) {
                var questions = Platform.getDataFromFileSystem(filepath);
                var questions_data = {};
                if (typeof questions != 'undefined' && questions != '' && typeof ques_navigation != 'undefined' && ques_navigation) {
                    var IsString = isJSONString(questions);
                    if (IsString) {
                        questions = JSON.parse(questions);
                    }else{
                        questions = JSON.parse(JSON.parse(questions));
                    }
                    questions = questions.questions ? questions.questions : questions;
                    questions = JSON.stringify(questions);
                    var filepath = Platform.getLocalServerPath() +"/video/"+nid+"/" ;
                    questions = replaceAll(STATIC_SERVER+"/", filepath , questions);
                    var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
                    var localFilePath = filepath.substr(filepath.indexOf('//'));
                    questions = replaceAll(staticPath+"/", localFilePath, questions);
                    questions = replaceAll(OBJECT_SERVER_DOMAIN.substr(OBJECT_SERVER_DOMAIN.indexOf('//')) +  '/' , filepath, questions )
                    questions = replaceAll("//" + OBJECT_SERVER_DOMAIN  + "/modules/mtp_quiz/image/play_video.png","/platform/images/play_video.90d2066a.png",questions);
                    questions = replaceAll("//" + OBJECT_SERVER_DOMAIN +"/modules/quiz/images/no_audio_banner.jpg","/platform/images/no_audio_banner.233fa6fd.png",questions);
                    if(typeof OLD_STATIC_SERVER != "undefined"){
                        if (OLD_STATIC_SERVER instanceof Array) {
                            for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                                var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                                questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                            }
                        }
                        else{
                            var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                            questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                        }
                    }
                    questions_data['question'] = JSON.parse(questions);
                    return questions_data;
                }
            } else {
                return "";
            }
        } else if ((isFileAvailable.filestatus == "Not Available" && isFileAvailable.version == "")
            || (isFileAvailable.filestatus == "Available" && isFileAvailable.version == "old")) {
            console.log('Quiz ' + nid + ' added for download');
            var downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + nid + "&auth=" + AUTH_ENABLED + "&additionaldetails=true";
            var filepath = "video/" + nid + "/" + nid + '_' + quiz.changed + ".zip";
            var paramsJson = {
                'objectid': nid, 'objecttype': 'resource',
                'serverurl': downloadurl, 'localurl': filepath, 'message': '',
                'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
                'onComplete': "", 'youtubeid': quiz.changed, 'format': "", 'type': 'content'
            };
            Platform.setPreference("backgroundQuizDownload_" + nid, "true");
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
        return "";
    } catch (e) {
        console.log('platformView : downloadQuestionsDataAsync' + e);
    }
}

platformView.prototype.checkForQuestionsAvailabiltyAndDownload = function (quiz, show_loader) {
    try {
        if (typeof SYNCED_QUESTION_DATA != 'undefined' && SYNCED_QUESTION_DATA) {
            console.log('questions already synced');
            return quiz;
        } else {
            if (typeof show_loader != 'undefined' && show_loader) {
                Client.showProgressBar(t("Loading") + "...");
            }
            console.log('syncing questions');
            var quiz_data = quiz; var questions = "";
            var filepath = "/video/" + quiz_data.nid + "/" + quiz_data.nid + '_' + quiz_data.changed + ".quiz";
            var contentUtils = CONTROLLER.getService("ContentUtils");
            var isFileAvailable = contentUtils.isFileAvailable(quiz_data.nid, quiz_data.changed, "", "quiz");
            // checking if quiz file available offline and returning data
            // else making online call to get questions
            if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new")) {
                questions = Platform.getDataFromFileSystem(filepath);
                if (typeof questions != 'undefined' && questions != '') {
                    questions = JSON.parse(questions);
                    if(typeof questions != 'undefined' && typeof questions == 'string'){
                        questions = JSON.parse(questions);
                    }
                    if(typeof questions != 'undefined' && typeof questions.questions != 'undefined'){
                        questions = questions.questions;
                    }
                } else {
                    if (typeof quiz_data != 'undefined' && quiz_data.nid != 'undefined') {
                        questions = this.quiz.getAllQuizQuestions(quiz_data.nid);
                        questions = JSON.parse(questions);
                    }
                }
            } else {
                if (typeof quiz_data != 'undefined' && quiz_data.nid != 'undefined') {
                    questions = this.quiz.getAllQuizQuestions(quiz_data.nid);
                    questions = JSON.parse(questions);
                }
            }
            if (questions != 'undefined' && questions.length > 0 && questions.length == Number(quiz_data.question_count)) {
                for (var i = 0; i < questions.length; i++) {
                    if (typeof quiz_data.quiz_result[i] != 'undefined' && quiz_data.quiz_result[i]['question_result'] != 'undefined') {
                        if (typeof questions[i]['question_result'] == 'undefined') {
                            questions[i]['question_result'] = quiz_data.quiz_result[i]['question_result'];
                        }
                    }
                }
                quiz_data['questions'] = questions;
            }
            SYNCED_QUESTION_DATA = true;
            return quiz_data;
        }
    } catch (e) {
        console.log('platformView : checkForQuestionsAvailabiltyAndDownload' + e);
    }
}

//set pervious duration for the video
platformView.prototype.setPreviousTimeForVideo = function(resourceMeta, params, resourceContent){
    try{
        var THIS = this;
        var resourceView = this;
        var initialLoad = true;
        if (resourceMeta.source != RESOURCE_TYPE_LINK || resourceMeta.type == RESOURCE_TYPE_WEBINAR) {
            $("#video-right-menu-notes").removeClass("menu-disabled");
                var hplayer = hPlayer;
                var videoViewHistory = resourceView.getVideoViewHistory(params);
                if (YOUTUBE_PLAYER == false) {
                    if (typeof params.notes != "undefined") {
                        var a = params.duration.split(':');
                        var seconds = (+a[0]) * 3600 + (+a[1]) * 60 + (+a[2].substring(0, a[2].length - 1));
                        setTimeout(function () {
                            hplayer.currentTime(seconds);
                        }, 1200);
                    }
                    else if (typeof params.bookmark != "undefined") {
                        var a = params.duration;
                        setTimeout(function () {
                            hplayer.currentTime(a);
                        },50);
                    }
                    else if (typeof params.solution_embed != "undefined" && params.solution_embed == true) {
                        var duration = Math.round(params.solutionDuration);
                        setTimeout(function () {
                            hplayer.currentTime(duration);
                        }, 50);
                    } else {
                        if (!$.isEmptyObject(videoViewHistory)) {
                            var lastseentime = Math.round(videoViewHistory.lastseentime || videoViewHistory.lastseenduration) ;
                            if(typeof resourceContent.type != "undefined" && resourceContent.type == RESOURCE_TYPE_WEBINAR){
                                var webinarRecordObject = $.parseJSON(Platform.getObjectProperty(OBJECT_VIDEO_WEBINAR,resourceContent.webinar_record_id,"content"));
                                resourceContent.duration = webinarRecordObject.duration;
                            }
                            if (lastseentime && lastseentime > 5 && ((resourceContent.duration - lastseentime) > 5)) {
                                setTimeout(function () {
                                    hplayer.currentTime(lastseentime - 5);
                                    PlayerManager.addMarkers([lastseentime - 5],hPlayer.duration(),"lastSeenDuration");
                                    PlayerManager.tracker["startTime"].push(hplayer.currentTime());
                                    PlayerManager.tracker["endTime"].push('0');
                                    PlayerManager.tracker.trackingIndex = PlayerManager.tracker.trackingIndex + 1;
                                }, 20);
                                setTimeout(function(){
                                    if(CONTROLLER.activeViewName != "Resource" && $(window).width() < $(window).height()){
                                        $('.vjs-marker.lastSeenDuration').css({'top': '10px','height': '10px'});
                                    }else{
                                        $('.vjs-marker.lastSeenDuration').css({'top': '15px','height': '15px'});
                                    }
                                }, 50);
                            }
                        }
                    }
                }
                if (typeof params.solution_embed == "undefined" || (typeof params.solution_embed != "undefined"
                && params.solution_embed != true)) {
                    var resourceService = CONTROLLER.getService("Resource");
                    var indexjson = resourceService.getIndexOfresource(params.resourceid);
                    if (indexjson[OBJECT_VIDEO_INDEX].length > 0 && indexjson[OBJECT_VIDEO_INDEX][0]['items'] && indexjson[OBJECT_VIDEO_INDEX][0]['items'].length > 0) {
                        $("#right-menu-index").removeClass("hidden");
                        $("#index-menu-icon").removeClass("hidden");
                        if($(window).width() < $(window).height()){
                            if (typeof SHOW_MARK_CONTENT != "undefined" && SHOW_MARK_CONTENT) {
                                $(".res-prev-icon").css({"right":"70px"});
                                $(".res-next-icon").css({"right":"40px"});
                            }else{
                                $(".res-prev-icon").css({"right":"60px"});
                                $(".res-next-icon").css({"right":"20px"});
                            }
                            
                        }else{
                            $(".res-prev-icon").css({"right":"185px"});
                            $(".res-next-icon").css({"right":"125px"});
                        }
                       
                    }
                    if ($("#video-right-menu-notes").hasClass("menu-disabled")) {
                        $("#video-right-menu-notes").removeClass("menu-disabled");
                    }
                }
                if (Platform.getNetworkState() == true && YOUTUBE_PLAYER == false && (typeof ENABLE_FEEDBACK != 'undefined' && ENABLE_FEEDBACK)) {
                    resourceView.getVideoFeedback(function (video_state) {
                        $('#learningvideoplayer').showRateVideo(resourceMeta.nid, video_state);
                        if($(window).width() > $(window).height()){
                            $('.video-js .vjs-control').css({'right': '2em'});
                            $('#playback_rate_btns').css({'right': '2em'});
                        }
                    });
                };
                if($(window).width() > $(window).height()){
                    $('.video-js .vjs-control').css({'right': '2em'});
                    $('#playback_rate_btns').css({'right': '2em'});
                }
                if(initialLoad == true){
                    if (SPINNERTIMER != "undefined") {
                        clearInterval(SPINNERTIMER);
                    }
                    SPINNERTIMER = setInterval(function () {
                        if (PlayerManager.getTime() > 0 && PlayerManager.isPlaying() == true) {
                            var currentTime = Math.floor(new Date().getTime() / 1000);

                            var videoViewHistory = THIS.getVideoViewHistory(params);
                            var endtime = "";
                            if (!$.isEmptyObject(videoViewHistory)) {
                                var endtime = Math.round(videoViewHistory.endtime);
                                if (endtime && endtime > 10 && ((resourceContent.duration - endtime) > 10)) {
                                    var starttime = endtime - 10;
                                }
                            } else {
                                starttime = 0;
                            }
                            if (typeof starttime == "undefined") {
                                starttime = 0;
                            }
                            var groupid = THIS.getVVHGroupid(params);
                            if (typeof params.solution_embed != "undefined" && params.solution_embed == true) {
                                var activeresourceid = CONTROLLER.getVariable("activesolutionid");
                            }else{
                                var activeresourceid = CONTROLLER.getVariable("activeresourceid");
                            }
                            if (endtime == '') {
                                var videodetails = '{"totalduration":"' + resourceMeta.duration + '","duration":0,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + activeresourceid + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + params.courseid + '","groupid":"' + groupid + '","categoryid":"' + params.catid + '","chapterid":"' + params.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '"}';
                            } else
                            {
                                videodetails = '{"totalduration":"' + resourceMeta.duration + '","duration":0,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + activeresourceid + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + params.courseid + '","groupid":"' + groupid + '","categoryid":"' + params.catid + '","chapterid":"' + params.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '"}';
                            }
                            var disablevvhonresolutionchange = CONTROLLER.getVariable("disablevvhonresolutionchange")
                            if ((typeof params.updateVideoViewHistory == "undefined"  &&  disablevvhonresolutionchange != 'true') || (params.updateVideoViewHistory != "false" && params.updateVideoViewHistory != false  &&  disablevvhonresolutionchange != 'true')) {
                                OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
                                OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
                                MobileResourceService.prototype.addOrUpdateVideoViewHistory(videodetails,"",resourceMeta.type);
                                if(EXEC_MODE == "online"){
                                    setTimeout(function() {
                                        ResourceView.prototype.forceVideosync(true)
                                    },300);
                                }
                            }
                            CONTROLLER.setVariable("disablevvhonresolutionchange","false")
                            clearInterval(SPINNERTIMER);
                            initialLoad = false;
                        }
                    }, 500);
                }
        } else {
            if (YOUTUBE_PLAYER == true && (typeof params.updateVideoViewHistory == "undefined" || (params.updateVideoViewHistory != "false" && params.updateVideoViewHistory != false))) {
                var groupid = THIS.getVVHGroupid(params);
                $('#video-menu-icon').addClass('hidden');
                var currentTime = Math.floor(new Date().getTime() / 1000);
                var videodetails = '{"totalduration":1,"duration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + CONTROLLER.getVariable("activeresourceid") + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + params.courseid + '","groupid":"' + groupid + '","categoryid":"' + params.catid + '","chapterid":"' + params.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '"}';
                OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
                OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
                MobileResourceService.prototype.addOrUpdateVideoViewHistory(videodetails);
            }
        }
    }catch(e){
        console.log("setPreviousTimeForVideo " + e.message );
    }
}

platformView.prototype.getVVHGroupid = function (params) {
    var activebatchid = CONTROLLER.getVariable("activebatchid");
    if (typeof USERGROUP_SPECIFIC_VVH != "undefined" && USERGROUP_SPECIFIC_VVH == "true") {
        var groupid = activebatchid;
    } else {
        var groupid = params.groupid;
    }
    return groupid;
}

platformView.prototype.setFullScreenForVideo = function(){
    try{
        var THIS = this;
        if(VIDEO_DEFAULT_FULLSCREEN && VIDEO_DEFAULT_FULLSCREEN == true){
            $(".video-js").removeClass("vjs-waiting");
            if(!SHOW_PORTRAIT_VIDEO){
                setTimeout(function(){
                    FULLSCREENTIMER = setInterval(function(){
                        THIS.setVideoFullscreen();
                    },10);
                    $(window).on("resize", function() {
                        var timer2 = setInterval(function(){
                            THIS.setVideoFullscreen();
                        },10);
                        if(typeof FULLSCREENTIMER2 == "undefined" || FULLSCREENTIMER2 == ""){
                            FULLSCREENTIMER2 = timer2;
                        }
                        else if(typeof FULLSCREENTIMER2 == "undefined" && timer2 != FULLSCREENTIMER2){
                            clearInterval(FULLSCREENTIMER2);
                            FULLSCREENTIMER2 = timer2;
                        }
                    });
                },1000);   
            }
        }
    }catch(e){
        console.log("setFullScreenForVideo " + e.message);
    }
}

platformView.prototype.getVideoViewHistory = function(params){
    try{
        params.uid = OBJECT_USER_ID;
        params.categoryid = params.catid;
        var groupid = params.groupid;
        if(typeof USERGROUP_SPECIFIC_VVH != "undefined" && USERGROUP_SPECIFIC_VVH == "true"){
            var gid = this.getVVHGroupid(params);
            params.groupid = gid;
        } else {
            params.groupid = "";
        }
        if(typeof params['courseid'] == "undefined" || params['courseid'] == ""){
            var objectCourseId = $.parseJSON(Platform.getObjectFromRelations(OBJECT_CHAPTER, params.chapterid, OBJECT_COURSE))[OBJECT_COURSE][0]["objectid"];
            params['courseid'] = objectCourseId;
        }
        var videodetails = JSON.stringify(params);
        if(EXEC_MODE == 'online'){
            var videoViewHistory = ResourceView.prototype.getVideoViewHistory(params);
        }else{
            var videoViewHistory = Platform.getVideoViewHistory(videodetails);
        }
        params.groupid = groupid;
        if(typeof videoViewHistory == "object"){
            return videoViewHistory;
        }else{
            return $.parseJSON(videoViewHistory);
        }
    }catch(e){
        console.log("getVideoViewHistory " + e);
    }
};

platformView.prototype.setVideoFullscreen = function() {
    try{
        if( (typeof DISABLE_VIDEO_STRETCH == "undefined"  || (typeof DISABLE_VIDEO_STRETCH != "undefined" && DISABLE_VIDEO_STRETCH != "true" ) ) 
        && ($('video').prop('videoHeight') > 0 || YOUTUBE_PLAYER == true) && $("#notes_text_wrapper").hasClass("hidden") && $("#solution_embed_wrapper").length != 1){
            $('body').width($(window).width());
            if(YOUTUBE_PLAYER == false){
                $('body .video-js .vjs-tech').css({
                "height": "auto"
            })
            $("#wrapper").css({
                "width":$(window).width(),
                "height":$(window).height()
            });
            var iOriginalVideoHeight = $('video').prop('videoHeight');
            var iCurrentVideoHeight = $('video').height();
            var iVideoContainerHeight = $('.player').height();
            var iCurrentScale = iOriginalVideoHeight / iCurrentVideoHeight;
            var iScaleY = (iVideoContainerHeight / iOriginalVideoHeight) * iCurrentScale;

            //Important to note: Set the origin to the top left corner (0% 0%), or else the position of the video goes astray
            $('video').css({
                "transform-origin": "0% 0%",
                "transform": "scaleY(" + iScaleY + ")",
                "-ms-transform-origin": "0% 0% ", /* IE 9 */
                "-ms-transform": "scaleY(" + iScaleY + ")", /* IE 9 */
                "-moz-transform-origin": "0% 0%", /* Firefox */
                "-moz-transform": "scaleY(" + iScaleY + ")", /* Firefox */
                "-webkit-transform-origin": "0% 0%", /* Safari and Chrome */
                "-webkit-transform": "scaleY(" + iScaleY + ")", /* Safari and Chrome */
                "-o-transform-origin": "0% 0%", /* Opera */
                "-o-transform": "scaleY(" + iScaleY + ")" /* Opera */
            });
            }
            else{
                $('iframe').css({"height":$(window).height(),"width":$(window).width()});
            }

            window.scrollTo(0, 0);
            clearInterval(FULLSCREENTIMER);
            if(typeof FULLSCREENTIMER2 != "undefined"){
                clearInterval(FULLSCREENTIMER2);
            }
        }
    }catch(e){
        console.log('VideoPlayerView setVideoFullscreen ' + e);
    }
};

platformView.prototype.videoResolutionStyle = function(videoParams){
    try{
        if($(".vjs-menu-content").height() > 0){
            $('.vjs-resolution-button').click();
        }
        $('.vjs-menu').removeClass("vjs-lock-showing");
        $('.vjs-menu-button-popup').blur();
        $('.video-js').removeClass("vjs-workinghover");
        if(CONTROLLER.activeViewName != "Resource"){
            $('.vjs-menu .vjs-menu-content').css({'width':'60px'});
            $('.vjs-menu li').css({'height':'30px', 'line-height':'30px'});
            if($(window).width() > $(window).height()){
                $('.vjs-menu .vjs-menu-content').css({'width':'100px'});
                $('.vjs-menu li').css({'height':'45px', 'line-height':'45px'});
            }
            // adding style after playback rates are shown on video 
            setTimeout(() => {
                if($('#subtitles_button').length > 0){
                    $('#playback_rate_btns').css({'margin-left':'-4px','font-size': '8px', 'right': '40px'});
                    $('.video-js .vjs-control').css({'right': '2em'});
                }else{
                    $('#playback_rate_btns').css({'margin-left':'-4px','font-size': '8px', 'right': '15px'});
                    $('.video-js .vjs-control').css({'right': '2em'});
                }
                if(Platform.getNetworkState() == false){
                    if($('#subtitles_button').length > 0){
                        $('#subtitles_button').css("margin-left","auto")
                    }
                    else{
                        $("#bookmarks_icon").css("margin-left","auto")
                    }
                }
                if($(window).width() > $(window).height()){
                    $('#playback_rate_btns').css('font-size','17px')
                }
                if($("#solution_embed_wrapper").length > 0){
                    $('.video-js .vjs-time-control').css({'width': '50px'}); 
                    $('.video-js .vjs-time-divider').css({'width': 'auto'});
                    $('.video-js .vjs-control').css({'right': '2em'});
                    $('.vjs-menu li').css({'height':'45px', 'line-height':'45px'});
                    $('#playback_rate_btns').css({'margin-left':'-20px','font-size': '17px'});
                    $('.vjs-menu .vjs-menu-content').css({'width':'100px'});
                    if($(window).width() > $(window).height()){
                        $('.video-js .vjs-control').css({'right': '2em'});
                    }
                    $('.vjs-menu .vjs-menu-content').css({'width':'100px'});
                }
                
            },600);
            if($(window).height() > $(window).width()){
                $('.video-js .vjs-time-control').css({'width': '50px'});
            }else{
                $('.video-js .vjs-time-control').css({'width': '75px'});
            }
            $('.video-js .vjs-time-divider').css({'width': 'auto'});
        }else{
            $('.vjs-menu .vjs-menu-content').css({'width':'100px'});
            $('.vjs-menu li').css({'height':'45px', 'line-height':'45px'});
            $('#playback_rate_btns').css({'margin-left':'-20px','font-size': '17px'});
        }
        if(typeof videoParams != 'undefined' && typeof videoParams.showreschange != 'undefined' && !videoParams.showreschange){
            $('.vjs-duration').css({'margin-right':'60%'});
        }

    }catch(e){
        console.log("videoResolutionStyle " + e.message);
    }
} 

platformView.prototype.renderFlashCard = function(params){
    try{
        var contentutils = CONTROLLER.getService("ContentUtils");
        if (APP_TYPE == "web") {
            if(Platform.getNetworkState() == true){
                this.loadFlashCardOnline(params);
            }else{
                alert(INTERNET_ERROR);
                return;
            }
        }
        if (APP_TYPE == "android") {

        var THIS = this;
            // get quiz details
        var flashCardDetails = Platform.getObjectProperty("assessment", params.resource.flashcard_nid , "content");
        var quiz_details = "";

        if(flashCardDetails !== ""){
            quiz_details = JSON.parse(flashCardDetails);
        }

        if (typeof CHECK_SDCARD_CONTENT != "undefined" && CHECK_SDCARD_CONTENT == "offline") {
            var content_access_type = CHECK_SDCARD_CONTENT;
        } else {
            var content_access_type = USER_PRODUCT_TYPE;
        }
        // check if flashcard quiz is available offline, if available load from local files 
        var isFileAvailable = contentutils.isFileAvailable(quiz_details.nid, quiz_details.changed, "", "quiz");
        
        // isFileAvailable = JSON.parse(isFileAvailable)

        if (typeof quiz_details != 'undefined' && quiz_details != "" && typeof quiz_details.nid != 'undefined' && quiz_details.nid != "" && 
            (isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new" || isFileAvailable.version == "old")){
            
            var flashCardQuestions =  MobileQuizService.prototype.getQuestionsForQuiz(quiz_details.nid, quiz_details.changed);

            // if questions are available try to open in offline mode 
            if(typeof flashCardQuestions !== 'undefined' && flashCardQuestions !== ""){
                // client config taken from local 
                var clientConfig = {
                    object_assignment : OBJECT_ASSIGNMENT, object_categories : OBJECT_CATEGORIES, object_category : OBJECT_CATEGORY, 
                    object_chapter : OBJECT_CHAPTER, object_content : OBJECT_VIDEO, object_course : OBJECT_COURSE, object_quiz : OBJECT_QUIZ,
                    object_webinar : OBJECT_WEBINAR, test_coll : OBJECT_TEST_COLLECTION,enableshare : true
                }
        
                flashCardQuestions = flashCardQuestions.question;
                var flashcard_id = params.resource.nid;

                var course_id = CONTROLLER.getVariable("activecourseid");
                var chapter_id = CONTROLLER.getVariable("activechapterid");

                var app_path =  "/apps/flashcard/flashcardlist?nid=" + params.resource.url + "&category_nid=" +CONTROLLER.getVariable("activecatid")  + "&active_group=" +  CONTROLLER.getVariable("activegroupid") + "&img_zoom=true" + "&courseid=" + course_id + "&topicid=" + chapter_id;

                var local_flashcard_path =  Platform.getLocalServerPath() + app_path;

                var flashcard_title = params.resource.title

                local_flashcard_path = local_flashcard_path + "&mode=offline";

                // get quiz attempts
                var flashCardAttempts = Platform.getRelatedObjects(OBJECT_QUIZ, flashcard_id , "quiz_result");

                if(typeof flashCardAttempts != 'undefined' && flashCardAttempts != ""){
                    flashCardAttempts = JSON.parse(flashCardAttempts);
                    flashCardAttempts = flashCardAttempts.quiz_result;
                }

                // set all the preferences to be used in flashcard page
                Platform.setPreference("client_config_details", JSON.stringify(clientConfig));
                Platform.setPreference("flashcard_details", flashCardDetails);
                Platform.setPreference("flashcard_questions", JSON.stringify(flashCardQuestions));
                Platform.setPreference("flashcard_attempt", JSON.stringify(flashCardAttempts));

                loadContentInWebview(local_flashcard_path, true, 'portrait', true, flashcard_title);
                
            }else if(Platform.getNetworkState("true") == true){
                this.loadFlashCardOnline(params);
            }else{
                alert(INTERNET_ERROR);
                return;
            }
        }else if(Platform.getNetworkState("true") == true){
            this.loadFlashCardOnline(params);
        }else{
            alert(INTERNET_ERROR);
            return;
        }
            
        }

        var currentTime = Math.floor(new Date().getTime() / 1000);

        Platform.setPreference("closeActivity","flashcard_vvh")
        
        if(Platform.getNetworkState() == true){ 
            CONTROLLER.setVariable("resourcevvhmode","1")   
            var viewmode = "1"
        }else{
            CONTROLLER.setVariable("resourcevvhmode","0")  
            var viewmode = "0"
        }

        var videodetails = '{"duration":1,"totalduration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + params.resource.nid + '","starttime":"' + currentTime + '","viewmode":"' + viewmode + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + params.courseid + '","groupid":"' + params.groupid + '","categoryid":"' + params.catid + '","chapterid":"' + params.chapterid +'","session":"' + OBJECT_USER_ID + '-' + currentTime + '"}';
         
        OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
        OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");

        MobileResourceService.prototype.addOrUpdateVideoViewHistory(videodetails);

        $(".topic_title_wrapper_inner").css("opacity","1");
        var notificationtype = CONTROLLER.getVariable('notificationType') || "";
        CONTROLLER.setVariable("scrolltocontent",params.resourceid);
        if(CONTROLLER.activeViewName == "Course"){
            //added page position to scroll to once view is rendered
            CONTROLLER.setVariable("pageposition",$("#content_wrapper").scrollTop());
            CONTROLLER.loadView("Course",params.courseid);
        } else if( (CONTROLLER.activeViewName == "VideoPlayer" || CONTROLLER.activeViewName == "Resource")
        && ($(".notifications_container").length == 0) )
        {
            CONTROLLER.onBack();
        }
        if(CONTROLLER.activeViewName == "SupportDocsList"){
            $('.chapter_dropdown').toggleClass('show');
            $('.supporting_docs_dropdown_wrapper').trigger("click");
        }
        if(notificationtype == "action" && $(".vs_progress_wrapper").length > 0){
            CONTROLLER.loadView("HomePage");
        }
        Client.closeProgressBar();
    }catch(e){
        console.log("renderFlashCard " + e.message);
    }
}

platformView.prototype.handleForumEvents = function(msg){
    Platform.setDeviceOrientation("portrait");
    $(window).on("resize", function () {
        RENDER_FORUM_HEADERFILE = "true";
        var forumcourseid = CONTROLLER.getVariable("forumcourseid");
        if (typeof forumcourseid == "undefined" || forumcourseid == "") {
            CONTROLLER.setVariable("forumcourseid", CONTROLLER.getVariable("activecourseid"));
        }
        CONTROLLER.setVariable("activecourseid", "");
        CONTROLLER.setVariable('forum_activity', 'true');
        if (CONTROLLER.getVariable('forum_event_view') != '' && CONTROLLER.getVariable('forum_event_view') == "resource") {
            CONTROLLER.setVariable('forum_event_view', '');
            CONTROLLER.onBack();
        }
        if ($('#content_wrapper').length == 0) {
            $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
            <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
        }
        if (typeof msg.data.hide_discussion != 'undefined' && msg.data.hide_discussion == "1") {
            CONTROLLER.setVariable('hide_discussion', true);
        }
        if (msg.data.message == 'discuss_assg_forum') {
            CONTROLLER.setVariable('assg_id', msg.data.assg_id);
            if (msg.data) CONTROLLER.loadView("ForumTopics");
        } else if (msg.data.message == 'edit_assg_forum') {
            console.log('edit button clicked');
        } else if (msg.data.message == 'view_assg_forum') {
            CONTROLLER.setVariable('view_forum_question', 'true');
            CONTROLLER.setVariable('assg_id', msg.data.assg_id);
            CONTROLLER.setVariable('ftid', msg.data.ftid);
            CONTROLLER.loadView("ForumTopics");
        }
        $(window).off("resize");
    });
};

platformView.prototype.renderChatBot = function (params, customClient="false") {
    try{
        if(Platform.getNetworkState() == true){
            var courseSubscription = CONTROLLER.getVariable("chatbot_course_subscription");
            
            // if subscription is not there throw subscription error
            if(typeof courseSubscription != "undefined" && courseSubscription == "false"){
                alert(CHATBOT_COURSE_NOT_SUBSCRIBED_ERROR);
            }else{
                // load chatbot directly from categories page
                CONTROLLER.setVariable("categories_listing_mode", "chat_bot");
                CONTROLLER.setVariable("chatbot_enabled", "true");

                // if chatbot page is defined open chatbot url in client dilog
                if(typeof CHATBOT_PAGE != 'undefined' && CHATBOT_PAGE){
        
                    var activeGroup = getQueryParameter('active_group');
                    
                    if(activeGroup == "null"){
                        activeGroup = CONTROLLER.getVariable('activegroupid');
                    }
            
                    Platform.enableZoom(false);
                
                    // send current course nid along with course name to launch chat bot for that course
                    if(typeof customClient !== "undefined" && customClient == "true"){
                        var resourceUrl = CHATBOT_PAGE + '?cn=' + params.title + '&nid=' + params.nid + '&client='+ CHATBOT_CLIENT_NAME_MAPPING[params.nid] +'&uname=' + OBJECT_USER_DATA.username + "&uid=" + OBJECT_USER_ID + '&botname=' + CHATBOT_NAME;
                    }else{
                        var resourceUrl = CHATBOT_PAGE + '?cn=' + params.title + '&nid=' + params.nid + '&client='+ CHATBOT_CLIENT_NAME +'&uname=' + OBJECT_USER_DATA.user_details[0].first_name + "&uid=" + OBJECT_USER_ID + '&botname=' + CHATBOT_NAME;
                    }
                   
                    // check if it's android or ios if not open in new window for mobile browser
                    if(IS_APP_SHELL === true){
                        if(APP_TYPE === "android"){
                            var params = {
                                "url": resourceUrl,
                                "enablezoom": true,
                                "orientation": 'portrait',
                                "showbackbutton": true,
                                "title": CHATBOT_NAME,
                                "togglescreencapture": true,
                                "showprogressbar": true,
                                "showheader": true,
                                "removeorientation": false
                            };
                            params = JSON.stringify(params);
                            Client.openDash(params);
                        }else{
                            loadContentInWebview(resourceUrl, CHATBOT_NAME, "true");
                        }
                    }else{
                        window.open(resourceUrl, "_blank");
                    }
                   
                    Client.closeProgressBar();
                }
            }
        }else{
            alert(INTERNET_ERROR);
        }
    }catch(e){
        console.log('showChatBot ' + e.message);
    }
}

platformView.prototype.renderMySubscriptionsView = function () {
    if (Platform.getNetworkState() == false) {
        alert(INTERNET_ERROR);
        return '';
    } else {
        if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
            trackScreenEventDetails({'context' : 'my subscriptons', 'event_name' : 'TB_TAP_MY_SUBSCRIPTIONS'});
        }
        CONTROLLER.loadView("MySubscriptions");
    }
};

platformView.prototype.reportIssue = function () {
    if(typeof TRACK_SCREEN_EVENTS !='undefined' && TRACK_SCREEN_EVENTS == true){
        trackScreenEventDetails({'context' : 'Report Issue', 'event_name' : 'TB_TAP_REPORT_ISSUE'});
    }
    Platform.reportIssue();
};

platformView.prototype.clearCache = function(){
    try{
        Platform.clearCache(true);
    }catch(e){
        console.log("HomePageView.prototype.clearCache " + e.message);
    }
}

platformView.prototype.unsetTagDetails = function(){
    CONTROLLER.setVariable('tag_name', '');
    CONTROLLER.setVariable('tag_course_id', '');
    CONTROLLER.setVariable('tag_categories', '');
    CONTROLLER.setVariable('categories_listing_mode', '');
}


platformView.prototype.showMarkForComplete = function (content_type) {
    try {
    $("body").append("<div id='videofeedback_thankyou_wrapper'></div>");
    var video_details = OBJECT_VIDEO_DETAILS;
    var videoParams = {'catid':video_details.categoryid, 'categoryid':video_details.categoryid, 'chapterid':video_details.chapterid, 'courseid':video_details.courseid, 'resourceid':video_details.resourceid, 'uid':OBJECT_USER_ID};
    var videoViewHistory = Platform.getVideoViewHistory(JSON.stringify(videoParams));
    videoViewHistory = JSON.parse(videoViewHistory);
    var params = {};
    var screenwidth = $(window).width();
    var screenheight = $(window).height();
    var currentTime = Math.floor(new Date().getTime() / 1000);
    var durationWatched = '';
    if (typeof DEFAULT_MAX_VIDEO_COMPLETION == 'undefined') {
        var video_settings = JSON.parse(Platform.getObjectProperty('video_settings','video_settings','content'));
        if (typeof video_settings.server_data != 'undefined') {
            DEFAULT_MAX_VIDEO_COMPLETION = video_settings.server_data.video_completion_percent;
            if (!checkIsEmpty(video_settings.server_data.pdf_completion_percent)) {
                DEFAULT_MAX_PDF_COMPLETION = video_settings.server_data.pdf_completion_percent;
            }else{
                DEFAULT_MAX_PDF_COMPLETION = '90';
            }
            if (!checkIsEmpty(video_settings.server_data.video_completion_percent)) {
                DEFAULT_MAX_VIDEO_COMPLETION = video_settings.server_data.video_completion_percent;
            }else{
                DEFAULT_MAX_VIDEO_COMPLETION = '90';
            }
        }
    }
    if (typeof content_type != "undefined" && content_type == "video") {
        params.isVideo = "true";
        var currentVideoTime = hPlayer.currentTime();
        PlayerManager.tracker.endOfVideo(Math.floor(currentVideoTime));
        durationWatched = PlayerManager.tracker.getDuration();
        PlayerManager.pauseVideo();
        if (PlayerManager.tracker.endTime.length > 0) {
            PlayerManager.tracker.endTime.pop();
        }
        max_content_completion = DEFAULT_MAX_VIDEO_COMPLETION;
    }else{
        durationWatched = Platform.getPreference("pdfPageNumber");
        params.isVideo = "false";
        max_content_completion = DEFAULT_MAX_PDF_COMPLETION;
    }
    if (typeof videoViewHistory != "undefined" && (typeof videoViewHistory.status != "undefined" && videoViewHistory.status == "completed")) {
        params.isComplete = "false";
    }else{
        params.isComplete = "true";
    }
    var data = {"uid" : OBJECT_USER_ID , "resourceid" : video_details.resourceid};
    var contentDurationComplete = Platform.getTotalDurationOfVideoWatched(JSON.stringify(data));
    if (typeof OBJECT_VIDEO_DETAILS.totalduration != 'undefined') {
        contentDurationComplete = (contentDurationComplete/OBJECT_VIDEO_DETAILS.totalduration)*100;
    }
    if (contentDurationComplete >= max_content_completion) {
        params.contentDurationComplete = "true";
    } else {
        params.contentDurationComplete = "false";
    }
    this.renderTemplate('markascomplete.tl', params, "#videofeedback_thankyou_wrapper", false);
    if ($('#thankyou_overlay').length == 0) {
        $('body').append("<div id='thankyou_overlay'></div>");
    }
    if (params.isComplete == "false") {
        $('.thankyou_close').addClass("hidden");
    }
    if(screenwidth < screenheight){
        if (screenwidth > 500) {
            $(".markCompleteOuterWrapper").css({"width" : '75%' , "min-height" : 'auto'})
        } else {
            $(".markCompleteOuterWrapper").css({"width" : '80%' , "min-height" : 'auto'})
        }
    }
    $(".thankyou_close, .cancelMarkButton").unbind('click').click(function (e) {
        if (typeof content_type != "undefined" && content_type == "video") {
            PlayerManager.playVideo();
        }
        removeMarkCompleteWrapper();
    });
    var video_details = JSON.parse(JSON.stringify(OBJECT_VIDEO_DETAILS));
    video_details["action"] = "user_marked";
    video_details["status"] = "completed";
    video_details["endtime"] = currentTime.toString();
    video_details["duration"] = durationWatched;
    $(".okayMarkButton").unbind('click').click(function (e) {
        video_details["status"] = "inprogress";
        OBJECT_VIDEO_DETAILS["status"] = "inprogress";
        Platform.addOrUpdateVideoViewHistory(JSON.stringify(video_details));
        $("#right-menu-complete-text").text('Mark as complete');
        if (OBJECT_VIDEO_DETAILS.type == 'Document') {
            var iframe = document.getElementById('ebookview-frame');
            function sendMessageToIframe(message) {
                iframe.contentWindow.postMessage(message, "*");
            }
            sendMessageToIframe('inprogress');
        } else {
            $(".res-mark-complete").css({"content" : 'url(platform/images/complete.085acc78.png)' , "min-height" : '25%'})
        }
        if (typeof content_type != "undefined" && content_type == "video") {
            PlayerManager.playVideo();
        }
        removeMarkCompleteWrapper();
    });

    $(".markAsCompleteSubmit").unbind('click').click(function (e) {
        OBJECT_VIDEO_DETAILS["status"] = "completed";
        Platform.addOrUpdateVideoViewHistory(JSON.stringify(video_details));
        $("#right-menu-complete-text").text('Mark as incomplete');
        if (OBJECT_VIDEO_DETAILS.type == 'Document') {
            var iframe = document.getElementById('ebookview-frame');
            function sendMessageToIframe(message) {
                iframe.contentWindow.postMessage(message, "*");
            }
            sendMessageToIframe('completed');
        } else {
            $(".res-mark-complete").css({"content" : 'url(platform/images/completed.82d16540.png)' , "min-height" : '25%'})
        }
        if (typeof content_type != "undefined" && content_type == "video") {
            PlayerManager.playVideo();
        }
        removeMarkCompleteWrapper();
    });
    } catch (e) {
        console.log("platformView.prototype.showMarkForComplete " + e.message);
    }
};

platformView.prototype.loadFlashCardOnline = function (params) {
    try {
        var flashcard_id = params.resource.flashcard_nid;

        var flashcard_title = params.title || params.resource.title;

        var category_id = params.catid;

        var activeGroup = CONTROLLER.getVariable("activegroupid");

        var course_id = params.courseid ? params.courseid : CONTROLLER.getVariable("activecourseid");

        var chapter_id = params.chapterid ? params.chapterid : CONTROLLER.getVariable("activechapterid");       

        var redirectPath = OBJECT_SERVER_DOMAIN + FLASHCARDS_PAGE + "?nid=" + flashcard_id + "&category_nid=" + category_id + "&active_group=" + activeGroup + "&img_zoom=true" + "&courseid=" + course_id + "&topicid=" + chapter_id;
    
        if(APP_TYPE == "web"){
            CONTROLLER.loadView("Flashcard",params);
        }else{
            loadContentInWebview(redirectPath, true, 'portrait', true, flashcard_title);
        }
    } catch (e) {
        console.log("platformView.prototype.loadFlashCardOnline " + e.message);
    }
};

platformView.prototype.updateVVHforTocWebinar = function (webinar_start_date) {
    try {
        var currentTime = Math.floor(new Date().getTime() / 1000);
        if (typeof CONTROLLER.getVariable('webinar_toc_details') != 'undefined' && CONTROLLER.getVariable('webinar_toc_details') != "") {
            var webinar_toc_details = CONTROLLER.getVariable('webinar_toc_details');
            var webinar_vvh_toc_details = '{"duration":2,"totalduration":2,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + 
            '","resourceid":"' + webinar_toc_details.resourceid + '","starttime":"' + currentTime + '","endtime":"' + (currentTime + 1) + '","agent":"mobile"' + ',"courseid":"' + webinar_toc_details.courseid + 
            '","groupid":"' + webinar_toc_details.groupid + '","categoryid":"' + webinar_toc_details.catid + '","chapterid":"' + webinar_toc_details.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","lastseenduration":""}';
            webinar_vvh_toc_details = $.parseJSON(webinar_vvh_toc_details);
            webinar_vvh_toc_details['viewmode'] = 1;
        }
        
        if (currentTime > webinar_start_date) {
            webinar_vvh_toc_details['status'] = "completed";
        } else {
            webinar_vvh_toc_details['status'] = "inprogress";
        };
        Platform.addOrUpdateVideoViewHistory(JSON.stringify(webinar_vvh_toc_details));
        CONTROLLER.setVariable('webinar_toc_details', "");
    } catch (e) {
        console.log("platformView.prototype.updateVVHforTocWebinar " + e.message);
        CONTROLLER.setVariable('webinar_toc_details', "");
    }
}

platformView.prototype.checkSingleContentInSubtopic = function (resources) {
    try {
        resources = resources['content'];
        var reslength = resources.length;
        if(typeof reslength != "undefined" && reslength == 1){
            CONTROLLER.setVariable("singlecontent",true)
        }
    } catch (e) {
        console.log("platformView.prototype.updateVVHforTocWebinar " + e.message);
        CONTROLLER.setVariable('webinar_toc_details', "");
    }
}
platformView.prototype.handleForumClick = function () {
    var load_subscibed_categories = $(".footer_course").hasClass("load_subscibed_categories")
    if(typeof load_subscibed_categories != "undefined"  && load_subscibed_categories != "" 
    && load_subscibed_categories == true){
        SHOW_UNSUBSCRIBED_CATEGORIES = "true";
        CONTROLLER.setVariable('categories_listing_mode','')
    }
    this.unsetTagDetails();
    if (!IS_APP_SHELL) {
        window.history.pushState({}, document.title, window.location.pathname);
    }
    this.renderForum();
}
QuizView.prototype = new View();
QuizView.prototype.constructor = QuizView.constructor;

function QuizView() {
    this.quiz = CONTROLLER.getService("Quiz");
    this.currentQuestionIndex = 0;
    this.allQuestions = '';
    this.currentQuestionView = null;
    this.modeCount = 0;
    this.activeQuizLanguage = '';
    this.attemptLog = [];
    this.lastSentLogIndex = 0;
    this.sectionNavigated = false;
    this.subjectNavigated = false;
}

QuizView.prototype.render = function (params) {
    this.setDefaultStyles(params);
    if (BREAK_MODE == '' || BREAK_MODE == false || BREAK_MODE == 'false') {
        this.renderHeader(params);
    }
    this.renderContent(params);
    //SKIP_QUIZ_FOOTER from testseries while attempting the quiz on back we are rendering homepage but quiz footer is rendering
    if (typeof SKIP_QUIZ_FOOTER == 'undefined' || SKIP_QUIZ_FOOTER == false) {
        this.renderFooter(params);
        this.setbodystyle(params);
        this.handleDeviceResize(params);
    }
    SKIP_QUIZ_FOOTER = false;
    
};

QuizView.prototype.setDefaultStyles = function () {
    if(typeof BREAK_MODE != "undefined" && BREAK_MODE != "true" ){
        $(window).off("resize");
    }
    $('body, #wrapper, #header_wrapper, #header, #content_wrapper, #quiz_wrapper, #footer_wrapper, #footer_wrapper .footer').css({
        "width": "100vw"
    });
    $('body, #wrapper').css({
        "height": "100vh"
    });
    $('#content_wrapper, #quiz_wrapper').css({
        "overflow-y": "auto"
    });
};

QuizView.prototype.handleDeviceResize = function (params) {
    this.setDefaultStyles();
    var THIS = this;
    if(typeof BREAK_MODE != "undefined" && BREAK_MODE != "true" ){
        $(window).off("resize");
    }
    $(window).on("resize", function () {
        $(".title_text").css("width", $(window).width() - 170);
        THIS.setbodystyle(params);
    });
};

QuizView.prototype.setbodystyle = function () {
    var ParentView = CONTROLLER.getVariable("activeParentView");
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    var preView = CONTROLLER.getVariable("previousView");
    var mode = getQueryParameter('mode');

    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#quiz_wrapper').css({
        width: screenwidth,
        height: screenheight - 100
    });

    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });

    $("#footer_wrapper").css({
        width: $(window).width() + "px"
    });

    $('#header_wrapper').css('width', $(window).width());
    $("body,#wrapper,#quiz_wrapper").css('background', '#E5E5E5');

    var catid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else {
        catid = CONTROLLER.getVariable("activecatid");
    }

    $(".title_text").css("width", $(window).width() - 170);


    $('#wrapper').css({ 'overflow': 'auto' });
    $("body").css("overflow", "auto");

    if ($('#header_wrapper').css('position') == 'fixed') {
        if (ParentView != "DashBoardQuiz") {
            if (($(window).width() < 380)) {
                $('#quiz_wrapper').css({ 'margin-top': '50px' })
            }
            else {
                $('#quiz_wrapper').css({ 'margin-top': '55px' });
            }
        }
        else {
            if ($(window).width() < 380) {
                $('#quiz_wrapper').css({ 'margin-top': '12px' });
            }
            else {
                $('#quiz_wrapper').css({ 'margin-top': '12px' });
            }
        }
    }
    if (typeof preView !== 'undefined' && preView == "PreAssessment") {
        $("#back,#search").css({ display: "none" });
    }

    if (ENABLE_NEW_UI) {
        $("body,#wrapper,#quiz_wrapper").css('background', '#F4F5F9');
        $('#header_wrapper').css({ 'display': 'block' });
    }
    if ($(".title_text").hasClass("scholarship_test")) {
        $(".title_text").addClass('pre_assessment_title');
    }
    $("#footer_wrapper").css({ "height": "auto" });

    // on mobile browser when launched url with mode=embedQuiz hide the back button
    if(APP_TYPE == "web" && typeof mode !== "undefined" && mode === "embedQuiz"){
        $("#back").hide();
    }
};

QuizView.prototype.onBack = function () {
    var THIS = this;
    if ($("div[data-id]").length > 0 && $("div[data-id]").attr("data-id") == "b_layer") {
        $("img[data-b-img]").remove();
        $("div[data-id]").css('opacity', '0');
        $("div[data-id]").remove();
    }
    
    CONTROLLER.setVariable("preView", "");
    setTimeout(function () {
        THIS.QuizOnBack();
    }, 300);
};

QuizView.prototype.QuizOnBack = function () {
    var THIS = this;
    CONTROLLER.setVariable('pages', '');
    CONTROLLER.setVariable('reportQuestions', '');
    CONTROLLER.setVariable('operand', 1);
    REPORT_PAGE_NO = 1;
    REPORT_PAGE_VIEW_NO = 1;
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    var mode = getQueryParameter('mode');
    var mode = getQueryParameter('mode', LOCATION_URL);
    CONTROLLER.setVariable('webinarFeedback', "false");
    QUIZ_IN_PROGRESS = false;
    if ($("#solution_embed_wrapper").length == 1) {
        THIS.removeSolutionWrapper();
        return "";
    }
    var courseid = "";
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
    }
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        if (typeof OBJECT_TEST_COLLECTION != "undefined" && QUIZ_RESOURCE_PARAMS.type == OBJECT_TEST_COLLECTION) {
            var content_type = OBJECT_TEST_COLLECTION;
        } else {
            var content_type = OBJECT_QUIZ;
        }
        var currentTime = Math.floor(getSyncedServerTime() / 1000);
        var videodetails = '{"totalduration":2,"duration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + QUIZ_RESOURCE_PARAMS.resourceid + '","starttime":"' + OBJECT_VIDEO_DETAILS.starttime + '","endtime":"'+currentTime+'","agent":"mobile"' + ',"courseid":"' + QUIZ_RESOURCE_PARAMS.courseid + '","groupid":"' + QUIZ_RESOURCE_PARAMS.groupid + '","categoryid":"' + QUIZ_RESOURCE_PARAMS.catid + '","chapterid":"' + QUIZ_RESOURCE_PARAMS.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","type":"' + content_type + '"}';
        OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
        OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
        Platform.addOrUpdateVideoViewHistory(videodetails);
        if(EXEC_MODE == 'online'){
            if(typeof QUIZ_RESOURCE_PARAMS.courseid != "undefined" && QUIZ_RESOURCE_PARAMS.courseid != ""){
                delete VIDEOS_WATCHED_COURSE[QUIZ_RESOURCE_PARAMS.courseid];
            }
        }
    }
    $('#wrapper').remove();
    $('body').append('<div id="wrapper"><div id="header_wrapper"></div>\n\
    <div id="content_wrapper"></div><div id="footer_wrapper"></div></div>');
    Platform.toggleScreenCapture('false');
    var searchtext = CONTROLLER.getVariable("activesearchtext");
    if (typeof mode != 'undefined' && mode == "bookmark") {
        window.history.back();
    } else if (typeof mode != 'undefined' && mode != "null" && mode == "scormToolKit") {
        CONTROLLER.loadView("HomePage");
    } else if (typeof mode != 'undefined' && mode != "null" && mode == "adaptivequiz") {
        Client.showProgressBar(t("Loading") + "...");
        var app_url = Platform.getAppLocalURL();
        app_url = app_url + '&nosplash=true&mode=adaptivequiz';
        window.location.replace(app_url);
    } else if (CONTROLLER.getVariable("previousView") == 'DownloadStatus') {
        if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
            CONTROLLER.setVariable("previousView", "HomePage");
        } else {
            CONTROLLER.setVariable("previousView", "AllSubjects");
        }
        CONTROLLER.loadView("DownloadStatus");
    }
    else if (notificationtype != "" && notificationtype == "action" && notificationUrlType != "course") {
        if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
            CONTROLLER.loadView("HomePage");
        } else {
            CONTROLLER.loadView("AllSubjects");
        }
        CONTROLLER.setVariable('notificationType', "");
    }
    else if (CONTROLLER.getVariable("previousView") == 'calender') {
        CONTROLLER.setVariable("previousView", "");
        CONTROLLER.loadView("Calender");
    }
    else if (CONTROLLER.getVariable("previousView") == 'ListTask') {
        if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
            CONTROLLER.setVariable("previousView", "HomePage");
        } else {
            CONTROLLER.setVariable("previousView", "AllSubjects");
        }
        CONTROLLER.loadView("ListTask");
    }
    else if (typeof QUIZ_EMBED != "undefined" && QUIZ_EMBED == true) {
        if (QUIZ_EMBED == true && $(window).width() > $(window).height() && IS_APP_SHELL) {
            Platform.setDeviceOrientation("portrait");
            $(window).on("resize", function () {
                CONTROLLER.loadView("Course", courseid);
                $(window).off("resize");
            });
        } else {
            THIS.gobackToPreviousView(searchtext, courseid);
        }
    }
    else {
        THIS.gobackToPreviousView(searchtext, courseid);
    }
    QUIZ_RESOURCE_PARAMS = "";
    Platform.toggleScreenCapture('false');
};

QuizView.prototype.renderHeader = function (params) {
    if (typeof params.gamemode != 'undefined') {
        var title = 'Quiz Game';
    } else {
        var title = this.getTitle(params);
    }
    var pre_assessment_ttle = $(".title_text").hasClass("scholarship_test");
    this.renderTemplate("quiz_header.tl", { 'title': title }, "#header_wrapper", true);
    if (typeof pre_assessment_ttle != 'undefined' && pre_assessment_ttle) {
        $(".title_text").addClass("scholarship_test");
    }
};

QuizView.prototype.getTitle = function (params) {
    var courseid = ""; var catid = "";
    var preView = CONTROLLER.getVariable("previousView");
    var myactivity = CONTROLLER.getVariable("myactivity");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var listallquizzesview = CONTROLLER.getVariable("listallquizzes");
    var webinarFeedback = CONTROLLER.getVariable('webinarFeedback');
    var webinarFeedbackTitle = CONTROLLER.getVariable('webinarFeebackTitle');
    if (typeof webinarFeedback != 'undefined' && webinarFeedback == 'true') {
        return webinarFeedbackTitle;
    }
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        courseid = QUIZ_RESOURCE_PARAMS.courseid;
        catid = QUIZ_RESOURCE_PARAMS.catid;
    }
    else {
        courseid = CONTROLLER.getVariable("activecourseid");
        catid = CONTROLLER.getVariable("activecatid");
    }
    if ((typeof preView !== 'undefined' && preView === "HomePage") || (notificationtype != "" && notificationtype == 'action')) {
        if (typeof listallquizzesview !== 'undefined' && listallquizzesview === "true") {
            return QUIZZES_TITLE;
        }
        else if (typeof preView !== 'undefined' && preView === "TestSeries") {
            if (typeof TEST_COLL_ID != 'undefined' && typeof TEST_COLL_ID['title'] != 'undefined' && TEST_COLL_ID['title'] != "") {
                return TEST_COLL_ID['title'];
            }
        }
        else if (typeof params.nid != 'undefined') {
            var nid = params.nid;
            this.allQuestions = this.quiz.getQuiz(nid);
            if ((typeof this.allQuestions.courses != "undefined" && this.allQuestions.courses.length != 0) || (notificationtype != "" && notificationtype == 'action')) {
                if (notificationtype != "" && notificationtype == 'action') {
                    courseid = CONTROLLER.getVariable("activecourseid");
                    return this.allQuestions.title;
                } else {
                    var courseid = this.allQuestions.courses[0];
                }
                var course = Platform.getObjectProperty(OBJECT_COURSE, courseid, "content");
                course = $.parseJSON(course);
                if (course != "" && course != null) {
                    return course.title
                }
                else if (QUESTION_OF_THE_DAY == true) {
                    return "Question Of The Day"
                }
                else {
                    return "Quiz Of the Day";
                }
            }
            else if ((typeof myactivity != 'undefined' && myactivity == 'true') || (typeof CONTROLLER.activeViewName != 'undefined' && CONTROLLER.activeViewName == "ListTask") || (typeof params != 'undefined' && typeof params.gamemode != 'undefined' && params.gamemode == true)) {
                return this.allQuestions.title;
            }
            else if (QUESTION_OF_THE_DAY == true) {
                return "Question Of The Day"
            }
            else {
                return "Quiz Of the Day";
            }
        }
        else if (QUESTION_OF_THE_DAY == true) {
            return "Question Of The Day"
        }
        else {
            return "Quiz Of the Day";
        }
    }
    else if (typeof preView !== 'undefined' && preView === "TestSeries") {
        if (typeof DISPLAY_QUIZ_NAME_IN_TESTSERIES_QUIZ != "undefined" && DISPLAY_QUIZ_NAME_IN_TESTSERIES_QUIZ == true) {
            var nid = params.nid;
            this.allQuestions = this.quiz.getQuiz(nid);
            if (typeof this.allQuestions.display_name != 'undefined' && this.allQuestions.display_name != null && this.allQuestions.display_name != "") {
                return this.allQuestions.display_name;
            } else {
                return this.allQuestions.title;
            }
        }
        else if (typeof TEST_COLL_ID != 'undefined' && typeof TEST_COLL_ID['title'] != 'undefined' && TEST_COLL_ID['title'] != "") {
            return TEST_COLL_ID['title'];
        }
    }
    else if (typeof preView !== 'undefined' && preView == "PreAssessment") {
        return this.getQuizTitle(params.nid);
    }
    else if (typeof courseid !== 'undefined' && courseid !== '') {
        var courseService = CONTROLLER.getService("Course");
        if (CLIENT_NAME == "Aplet") {
            var quiz_title = this.getQuizTitle(params.nid);
            return quiz_title;
        }
        return courseService.getCourseTitle(courseid);
    }
    else {
        this.allQuestions = this.quiz.getQuiz(params.nid);
        CONTROLLER.setVariable('quiz_title', this.allQuestions.title)
        return this.allQuestions.title;

    }

};

QuizView.prototype.renderContent = function (params) {
    try {
        Platform.toggleScreenCapture('true');
        this.quiz_nid = params['nid'];
        this.beginQuiz(params['nid']);
        Client.closeProgressBar();
    } catch (e) {
        console.log('QuizView - renderContent :' + e.message);
        Client.closeProgressBar();
    }
};
QuizView.prototype.getQuizTitle = function (nid) {
    var quiz = this.quiz.getQuiz(nid);
    return quiz.title;
};
QuizView.prototype.renderQuestion = function (type) {
    var THIS = this;
    var questiontype = "";
    // single question to load for online quiz
    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
        && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
        this.checkForCurrentQuestionAndSwapQuestionsData();
    }
    if (typeof this.allQuestions.questions[this.currentQuestionIndex].questiontype == "undefined") {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].type;
        this.allQuestions.questions[this.currentQuestionIndex].questiontype = questiontype;
    }
    else {
        questiontype = this.allQuestions.questions[this.currentQuestionIndex].questiontype;
    }
    if (typeof this.allQuestions.repeat_untill_correct != "undefined") {
        var repeat_untill_correct = this.allQuestions.repeat_untill_correct;
    }
    var params = {
        "quiz": this.allQuestions.questions[this.currentQuestionIndex]
        , "index": this.currentQuestionIndex
        , "total_questions": this.allQuestions.questions.length
        , "type": this.allQuestions.type
        , "replace_item": '#quiz_wrapper'
        , "quizAttrs": this.allQuestions
        , "replace": true
        , "mode": 'all'
        , "questiontype": questiontype
        , "start_question": getSyncedServerTime()
    };
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = params.quizAttrs.nid;
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
    }
    if (questiontype == "truefalse") {
        CONTROLLER.loadChildView('Truefalse', function (view) {
            THIS.renderView(type, view, params);
        });
    } else if (questiontype == "matching") {
        CONTROLLER.loadChildView('Match', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (questiontype == "short_answer") {
        CONTROLLER.loadChildView('ShortAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else if (questiontype == "long_answer") {
        CONTROLLER.loadChildView('LongAnswer', function (view) {
            THIS.renderView(type, view, params);
        });
    }
    else {
        CONTROLLER.loadChildView('Mcq', function (view) {
            THIS.renderView(type, view, params);
        });
    }

    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
        && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
        Client.closeProgressBar();
    }
};

QuizView.prototype.renderView = function (type, view, params) {
    var THIS = this;
    view.renderContent(params);
    THIS.currentQuestionView = view;
    $('#certificate_placeholder').show();
    $('#quiz_bookmark_wrapper').show();
    $('#countDown, #revise_back').hide();
    if (type == true || (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION)) {
        THIS.currentQuestionView.setPreviousData(THIS.allQuestions.questions[THIS.currentQuestionIndex], "", THIS.allQuestions);
    }

    if (typeof params.quizAttrs.repeat_until_correct != 'undefined' && typeof params.quizAttrs.type != 'undefined' && params.quizAttrs.type == 'practice' && params.quizAttrs.repeat_until_correct == 0) {
        var current_question = THIS.allQuestions.questions[THIS.currentQuestionIndex];
        if (typeof current_question.question_result != 'undefined' && typeof current_question.question_result.user_answer != 'undefined' && current_question.question_result.user_answer.length != 0) {
            $('.unselected').css('pointer-events', 'none');
            $('.ckeditor-html5-audio, .ckeditor-html5-video').css('pointer-events', 'auto');
            if (current_question.questiontype == "truefalse") {
                view.showCorrectOptions(current_question, 'review');
            } else {
                view.showCorrectOptions(current_question.alternatives, 'review');
            }
        }
    }
    THIS.quizStyleBack();

    //Previous and next call back on swipe
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe('enable');
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + ' #swipe_wrapper').swipe({
            swipeLeft: function (e) {
                if (!$('.quiz_next').data('clicked')) {
                    $('.quiz_next').data('clicked', true);
                    THIS.quizNext();
                }
            },
            swipeRight: function (e) {
                if (!$('.quiz_back').hasClass('flipQuestionback_light')) {
                    if (!$('.quiz_back').data('clicked')) {
                        $('.quiz_back').data('clicked', true);
                        THIS.quizBack();
                    }
                }
            }
        });
    }
    $("#swipe_wrapper").swipe('destroy');
    $("#swipe_wrapper").swipe({
        swipeLeft: function () {
            if (!$('.quiz_next').data('clicked')) {
                $('.quiz_next').data('clicked', true);
                if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
                    && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
                    Client.showProgressBar(t("Loading") + "...");
                }
                if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
                    return "";
                }
                setTimeout(function () {
                    THIS.quizNext();
                }, 100);
            }
        },
        swipeRight: function () {
            if (!$('.quiz_back').hasClass('flipQuestionback_light')) {
                if (!$('.quiz_back').data('clicked')) {
                    $('.quiz_back').data('clicked', true);
                    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
                        && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
                        Client.showProgressBar(t("Loading") + "...");
                    }
                    if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
                        return "";
                    }
                    setTimeout(function () {
                        THIS.quizBack();
                    }, 100);
                }
            }
        }
    });
    $('.quiz_next, .quiz_back').removeData('clicked');
    var currQuestion = this.allQuestions.questions[this.currentQuestionIndex];
    var currQuestionResult = currQuestion['question_result'];
    var log = 'Question ' + (this.currentQuestionIndex + 1) + ' Started';
    var logExtras = this.getLogExtrasForQuestion(currQuestion, currQuestionResult);
    this.logUserAction(log, logExtras);
    if (typeof MathJax != "undefined") {
        MathJax.Hub.Queue(["Typeset", MathJax.Hub, 'quiz_wrapper']);
    }
    
    if ((typeof ENABLE_FEEDBACK != 'undefined' && ENABLE_FEEDBACK && typeof QUESTION_LEVEL_FEEDBACK != 'undefined' && QUESTION_LEVEL_FEEDBACK == true)) {
        this.showQuestionFeedback(currQuestion.nid);
    }

    if(typeof ENABLE_FEEDBACK != 'undefined' && typeof QUESTION_LEVEL_FEEDBACK != 'undefined' && QUESTION_LEVEL_FEEDBACK == true && typeof QUESTION_OF_THE_DAY != 'undefined' && !QUESTION_OF_THE_DAY){
        this.showQuestionFeedback(currQuestion.nid);
    }

    var preView = CONTROLLER.getVariable("previousView");
    var mode = getQueryParameter('mode', LOCATION_URL);
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    //if quiz isn't tagged to any course bookmark is hidden
    var hasCourseId = checkQuizHasCourseId();
    if (Platform.getNetworkState() == true && !this.gameMode && !(typeof preView !== 'undefined' && preView === "TestSeries") && !(typeof notificationtype != 'undefined' && notificationtype == "action" && typeof notificationUrlType != 'undefined' && notificationUrlType == "quiz")
        && !(typeof preView !== 'undefined' && preView === "PreAssessment") && !(typeof preView !== 'undefined' && preView === "calender")
        && typeof ENABLE_BOOKMARKS != 'undefined' && ENABLE_BOOKMARKS && !(typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") && !(typeof mode != 'undefined' && mode == 'bookmark') && (typeof params["quizAttrs"].enable_favorites != 'undefined' && params["quizAttrs"].enable_favorites == '1')
        && (typeof QUESTION_OF_THE_DAY != 'undefined' && !QUESTION_OF_THE_DAY)
        && (hasCourseId)) {
        this.showQuizBookmark(currQuestion.nid, params); // calling favorites quiz
    }

    if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
        $(".quiz_menu_button").addClass("hidden");
    }

    if (typeof this.allQuestions.allow_jumping != 'undefined' && this.allQuestions.allow_jumping == '0') {
        if ($('#quiz_breaks_outer_wrapper').length == 0) {
            $('.resource_quiz_menu_button').addClass('hidden');
            $('.res-prev-icon').css({ 'right': '50px' });
            $('.res-next-icon').css({ 'right': '0px' });
            $('.title-overlay-inner').css({ 'width': '50vw' });
            $('.resource_quiz_menu_button').addClass('hidden');
        }
    }
    if (typeof preView != 'undefined' && preView == "calender") {
        if ($('#header_wrapper').length > 0) {
            if (typeof $('#header_wrapper')[0].style != 'undefined' && typeof $('#header_wrapper')[0].style.display != 'undefined' && $('#header_wrapper')[0].style.display == 'none') {
                $('#header_wrapper').css({ 'display': 'block' });
            }
        }
    }

    var quiz_mode = getQueryParameter('mode', LOCATION_URL);
    if (typeof quiz_mode != 'undefined' && quiz_mode != null && quiz_mode != 'null' && quiz_mode != '' && quiz_mode == 'adaptivequiz') {
        $('#quiz_bookmark_wrapper').addClass('hidden');
    }

    var windowWidth = window.innerWidth;
    $('video:not(.resource_player video)').css({'max-width': (windowWidth - 60) + 'px'});
    $('.ckeditor-html5-video .option_video').css({'max-width': (windowWidth - 100) + 'px'});
    $('.match_question_container .ckeditor-html5-video .option_video').css({'max-width': '100%'});
    $('.multimaching_matchanswers .ckeditor-html5-video .option_video').css({'max-width': '100%'});
    if (EXEC_MODE == "online" && APP_TYPE != "ios" && !document.fullscreenElement && $('#quiz_breaks_outer_wrapper .course_quiz_wrapper').length > 0) {
        $('#quiz_breaks_outer_wrapper .course_quiz_wrapper').css({'height': 'calc(100vh - 105px)'});
    } else if ($('#quiz_breaks_outer_wrapper .course_quiz_wrapper').length > 0) {
        $('#quiz_breaks_outer_wrapper .course_quiz_wrapper').css({'height': 'calc(100vh - 50px)'});
    }

    //Custom styles only for Leadschool v2.
    var client = getQueryParameter("client", window.location.href);
    if(typeof client !== 'undefined' && client === 'leadschoolv2'){
        THIS.applyLeadschoolV2Styles();
    }
    if(APP_TYPE != "web"){
        $("#questionTitle a,.question_title_expand a,#question_wrapper a,.solution_content_wrapper a,#section_text_wrapper a").not(".match_option_choose dt a,.match_option_choose dd ul li a, a:contains('Read More'), a:contains('Read Less')").unbind('click').bind("click", function (e) {
            var url = $(this).attr("href");
            if(typeof url != "undefined" && url != "" && !url.includes("javascript:void(0)") && url != "#"){
                if($.isFunction(Client.openUrlInWebview)){
                    loadContentInWebview(url, false, 'portrait', false, "");
                }else{
                    Platform.openBrowser(url);
                }
            }
        });
    }

    if(typeof this.allQuestions.all_questions_mandatory != 'undefined' && this.allQuestions.all_questions_mandatory == "1"){
        if($('.flipQuestion_endquiz').length > 0){
            $('.flipQuestion_endquiz').addClass('hidden');
        }
    }

    //Passing the question context to chatbot only if the question is of type mcq
    if(typeof BREAK_MODE == "undefined" || BREAK_MODE != "true"){
        var question_type = this.allQuestions.questions[this.currentQuestionIndex].questiontype;
        var chatbot = window.dash;
        var isChatBotInitialized = CONTROLLER.getVariable("chatbotInitialized");
        if(Platform.getNetworkState() == true && typeof isChatBotInitialized !== "undefined" 
        && isChatBotInitialized == "true" && typeof chatbot !== 'undefined'){
            if($('#botIcon').length == 0){
                chatbot.appendBotIconToBody();
            }
            if(chatbot.isDashBotShown()){
                chatbot.hideDashBot();
            }
            if(question_type == 'multichoice'){
                chatbot.showDashBot();
                this.passQuestionContextToChatbot();
            }
        }
    }
};

QuizView.prototype.reportRender = function (action) {
    if (action == true) {
        var answer_result = this.currentQuestionView.checkUserScore('', false);
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);
    }
    //save quiz results at end
    this.quizResultSave();
    if (typeof clearcheck != 'undefined') {
        clearInterval(clearcheck);
    }
    var params = {
        "report": this.allQuestions
        , "replace_item": '#quiz_wrapper'
        , "replace": true
    };
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
        var id = CONTROLLER.getVariable('breakquizid');
        params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
        $('#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id + '#footer_wrapper').addClass('hidden');
    }
    CONTROLLER.loadChildView('Report', function (view) {
        view.render(params);
    });
};

QuizView.prototype.quizNext = function (button) {
    var THIS = this;
    //load the next question
    if ((this.allQuestions.questions.length > this.currentQuestionIndex + 1)) {
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
            var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
            if (!device_in_airplane_mode) {
                confirm(t("To take the quiz, device needs to be in Airplane mode. Click on OK to take you to settings for enabling Airplane mode"), function () {
                    CONTROLLER.setVariable("airplane_mode_settings", "true");
                    Platform.enableAirplaneMode();
                    //get current question result
                    if (THIS.allQuestions.questions[this.currentQuestionIndex]["type"] != "truefalse") {
                        var answer_result = THIS.currentQuestionView.checkUserScore(button, false, THIS.allQuestions);
                        if (answer_result['user_result_answer'].is_correct == 1) {
                            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                            THIS.questionResultSave(answer_result['user_result_answer']);
                            THIS.currentQuestionIndex = THIS.currentQuestionIndex + 1;
                            THIS.renderQuestion(true);
                        } else {
                            //submitting quiz without filling option
                            if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_correct == 2) {
                                alert(t('ENTER_ALL_QUESTIONS', true));
                            } else {
                                alert(t('ENTER_CORRECT_ANSWER_PROMPT', true));
                            }
                            $('.quiz_next, .quiz_back').removeData('clicked');
                        }
                    }
                }, "OK", "Cancel");
            } else {
                //get current question result
                if (THIS.allQuestions.questions[this.currentQuestionIndex]["type"] != "truefalse") {
                    var answer_result = THIS.currentQuestionView.checkUserScore(button, false, THIS.allQuestions);
                    if (answer_result['user_result_answer'].is_correct == 1) {
                        THIS.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                        THIS.questionResultSave(answer_result['user_result_answer']);
                        THIS.currentQuestionIndex = this.currentQuestionIndex + 1;
                        THIS.renderQuestion(true);
                    } else {
                        //submitting quiz without filling option
                        if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_correct == 2) {
                            alert(t('ENTER_ALL_QUESTIONS', true));
                        } else {
                            alert(t('ENTER_CORRECT_ANSWER_PROMPT', true));
                        }
                        $('.quiz_next, .quiz_back').removeData('clicked');
                    }
                }
            }
        } else {
            //get current question result
            if (this.allQuestions.questions[this.currentQuestionIndex]["type"] != "truefalse") {
                var answer_result = this.currentQuestionView.checkUserScore(button, false, this.allQuestions);
                if (answer_result['user_result_answer'].is_correct == 1) {
                    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                    this.questionResultSave(answer_result['user_result_answer']);
                    this.currentQuestionIndex = this.currentQuestionIndex + 1;
                    this.renderQuestion(true);
                } else {
                    //submitting quiz without filling option
                    if (answer_result['user_result_answer'].is_correct == 0 && answer_result['user_result_answer'].is_correct == 2) {
                        alert(t('ENTER_ALL_QUESTIONS', true));
                    } else {
                        alert(t('ENTER_CORRECT_ANSWER_PROMPT', true));
                    }
                    $('.quiz_next, .quiz_back').removeData('clicked');
                }
            }
        }
    } else {
        //printing report form and updating quiz results
        this.reportRender(true);
    }
};

QuizView.prototype.quizBack = function (button) {
    var THIS = this;
    var log = 'Clicked previous button';
    this.logUserAction(log);
    var seconds = THIS.timeTaken();
    var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
    if (this.currentQuestionIndex >= 1) {
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
            var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
            if (!device_in_airplane_mode) {
                confirm(t("To take the quiz, device needs to be in Airplane mode. Click on OK to take you to settings for enabling Airplane mode"), function () {
                    CONTROLLER.setVariable("airplane_mode_settings", "true");
                    Platform.enableAirplaneMode();
                    var answer_result = THIS.currentQuestionView.checkUserScore(button, false, THIS.allQuestions);
                    // adding time taken for each question
                    if (typeof quiz_result_obj != "undefined") {
                        answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds)
                    } else {
                        answer_result['user_result_answer']['time_taken'] = seconds;
                    }
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                    THIS.questionResultSave(answer_result['user_result_answer']);
                    THIS.currentQuestionIndex = THIS.currentQuestionIndex - 1;
                    THIS.renderQuestion(true);
                }, "OK", "Cancel");
            } else {
                var answer_result = THIS.currentQuestionView.checkUserScore(button, false, THIS.allQuestions);
                // adding time taken for each question
                if (typeof quiz_result_obj != "undefined") {
                    answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds)
                } else {
                    answer_result['user_result_answer']['time_taken'] = seconds;
                }
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                THIS.questionResultSave(answer_result['user_result_answer']);
                THIS.currentQuestionIndex = THIS.currentQuestionIndex - 1;
                THIS.renderQuestion(true);
            }
        } else {
            var answer_result = this.currentQuestionView.checkUserScore(button, false, this.allQuestions);
            // adding time taken for each question
            if (typeof quiz_result_obj != "undefined") {
                answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds)
            } else {
                answer_result['user_result_answer']['time_taken'] = seconds;
            }
            this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
            this.questionResultSave(answer_result['user_result_answer']);
            this.currentQuestionIndex = this.currentQuestionIndex - 1;
            this.renderQuestion(true);
        }
    }

};

QuizView.prototype.quizStyleBack = function (button) {
    $('#flipQuestionback').removeClass('flipQuestionback_light');
    if (this.currentQuestionIndex == 0) {
        $('#flipQuestionback').addClass('flipQuestionback_light');
    }
};


QuizView.prototype.renderFooter = function (params) {
    var THIS = this;
    if ($("#footer_wrapper").hasClass("hidden")) {
        $("#footer_wrapper").removeClass("hidden");
    }
    
    if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true" && CONTROLLER.activeViewName != "TestSummary") {
        this.renderTemplate("quiz_footer.tl", "", "#quiz_breaks_outer_wrapper #footer_wrapper", true);
        $('.flipQuestion_endquiz').css('display', 'inline-block');
    }else{
        this.renderTemplate("quiz_footer.tl", "", "#footer_wrapper", true);
    }

    if (CONTROLLER.targetViewname == "Certificatequiz"
        && (typeof ENABLE_QUIZ_CLEAR_RESPONSE != "undefined" && ENABLE_QUIZ_CLEAR_RESPONSE == "true")
        && (typeof params.gamemode == 'undefined' || params.gamemode == false)
        && (typeof BREAK_MODE != 'undefined' && (BREAK_MODE == 'false' || BREAK_MODE == false))) {
        $('#flipQuestion_clearresponse').css('display', 'block');
    }

    if(typeof this.allQuestions != 'undefined' && typeof this.allQuestions.all_questions_mandatory != 'undefined' 
        && this.allQuestions.all_questions_mandatory == "1"){
        if($('.flipQuestion_endquiz').length > 0){
            $('.flipQuestion_endquiz').addClass('hidden');
        }
    }
  

    //Prebview and next call back
    $('.quiz_back').click(function (e) {
        var element = $(this);
        if (element.data('clicked')) {
            // Previously clicked, stop actions
            e.preventDefault();
            e.stopPropagation();
        } else {
            element.data('clicked', true);
            if (!$(this).hasClass('flipQuestionback_light')) {
                if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
                    && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
                    Client.showProgressBar(t("Loading") + "...");
                }
                setTimeout(function () {
                    THIS.quizBack();
                }, 100);
            }
        }
    });

    $('.quiz_next').click(function (e) {
        var element = $(this);
        if (element.data('clicked')) {
            // Previously clicked, stop actions
            e.preventDefault();
            e.stopPropagation();
        } else {
            element.data('clicked', true);
            if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
                && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
                Client.showProgressBar(t("Loading") + "...");
            }
            setTimeout(function () {
                THIS.quizNext();
            }, 100);
        }
    });

    // for showing the correct options
    $('.correctOption').click(function (e) {
        var id = $(this).attr('nid');
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].questiontype == "multichoice") {
            THIS.currentQuestionView.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex].alternatives);
        } else {
            THIS.currentQuestionView.showCorrectOptions(THIS.allQuestions.questions[THIS.currentQuestionIndex]);
        }
        if (typeof SHOW_SOLUTION_WITH_SHOW_ANSWER != "undefined" && SHOW_SOLUTION_WITH_SHOW_ANSWER == true) {
            THIS.showSolutionForQuestion();
        }
    });
    //show legend bar in menu
    $('.quiz_menu_button').click(function (e) {
        $('#question_nav_show').remove();
        if (THIS.show_legend(this) != true) {
            $(this).addClass('active_legend');
            e.stopImmediatePropagation();
        }
        return false;
    });

    $('#gameinfo_wrapper').click(function (e) {
        THIS.showGameInstructions();
        e.stopPropagation();
        return false;
    });

    //clearing the options selected by users
    $('#flipQuestion_clearresponse').click(function () {
        THIS.clearOptionsSelected();
    });

    // submit question of the day question 
    if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
        $('#flipQuestion_quizOfTheDaySubmit').click(function () {
            if (Platform.getNetworkState() == true) {
                THIS.quizNext();
            } else {
                alert(INTERNET_ERROR);
            }
        });
    }

    if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
        $('.quiz_next, .quiz_back').addClass("hidden");
        $('#showanswer_main_wrapper').css({ "width": "100%" });
    }

};

QuizView.prototype.beginQuiz = function (nid) {
    var THIS = this;
    this.allQuestions['questions'] = [];
    this.allQuestions['quiz_result'] = [];
    this.attemptLog = [];
    //get quiz attributes
    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
        this.allQuestions = JSON.parse(Platform.getPreference('quiz_' + nid));
    } else {
        this.allQuestions = this.quiz.getQuiz(nid);
    }

    if (isArray(this.allQuestions)) {
        this.allQuestions = this.allQuestions[0];
    }
    if ((Platform.getNetworkState() == true) && (CHECK_QUIZ_ATTEMPTS != undefined && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttempts(nid);
        THIS.checkOnAttempts(quiz_attempts, nid);
    }
    else if ((Platform.getNetworkState() == false) && (CHECK_QUIZ_ATTEMPTS != undefined && CHECK_QUIZ_ATTEMPTS == "true")) {
        var quiz_attempts = this.quiz.getQuizAttemptsinOffline(nid);
        THIS.checkOnAttempts(quiz_attempts, nid);
    }
    else {
        THIS.renderQuiz(nid);
    }
};

QuizView.prototype.QuizResume = function (quiz_result_id, callback) {
    try {
        var isResume = false;
        var lastQuestionLoadedFromOnline = false;
        var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
        if (quiz_result == '' || quiz_result === null) {
            quiz_result = { 'quiz_result': '' };
        }
        if (Platform.getNetworkState() == true && (typeof quiz_result_id != 'undefined' && quiz_result_id != "")) {
            var matchFound = false;
            if (quiz_result['quiz_result'] != '') {
                for (var i in quiz_result['quiz_result']) {
                    if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                        || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                        if (quiz_result_id === quiz_result['quiz_result'][i].result_id) {
                            matchFound = true;
                            break;
                        } else if (quiz_result_id === quiz_result['quiz_result'][i].gen_result_id) {
                            matchFound = true;
                            break;
                        }
                    }
                }
            }
            if (matchFound == false) {
                var quiz_result_from_result_id = this.quiz.getQuizResultFromResultId(quiz_result_id);
                if (!this.checkOnlineQuizResponse(quiz_result_from_result_id)) {
                    if (typeof callback == "function") {
                        callback("error");
                    } else {
                        return "error";
                    }
                }
                quiz_result_from_result_id = this.fixIsSkippedState(quiz_result_from_result_id);
                quiz_result = { 'quiz_result': [] };
                quiz_result['quiz_result'].push(quiz_result_from_result_id);
            }
        }

        this.currentQuestionIndex = 0;
        this.allQuestions['quiz_result'] = '';
        this.allQuestions['quiz_result'] = this.generate_results();

        if (((typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) || (typeof QUESTION_OF_THE_DAY != "undefined" && QUESTION_OF_THE_DAY))
            && quiz_result['quiz_result'] != '' && (!Platform.getPreference('lastViewedQuestionIndex_' + this.allQuestions.nid) ||
                Platform.getPreference('lastViewedQuestionIndex_' + this.allQuestions.nid) == "1")) {
            lastQuestionLoadedFromOnline = true;
            Platform.setPreference('lastViewedQuestionIndex_' + this.allQuestions.nid, "2");
        }

        if (typeof QUESTION_OF_THE_DAY != "undefined" && QUESTION_OF_THE_DAY && Platform.getPreference('lastViewedQuestionIndex_' + this.allQuestions.nid)) {
            lastQuestionLoadedFromOnline = true;
        }

        var last_question_index = Platform.getPreference('lastViewedQuestionIndex_' + this.allQuestions.nid);
        // get resumed Quiz data if result evaluatation is null
        if ((typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION || typeof QUESTION_OF_THE_DAY != "undefined" && QUESTION_OF_THE_DAY) &&
            typeof last_question_index != 'undefined' && last_question_index != ''
            && last_question_index != null && last_question_index != "1") {
            if (quiz_result['quiz_result'] != '') {
                for (var i in quiz_result['quiz_result']) {
                    if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                        || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                        if (quiz_result['quiz_result'][i].result_id.indexOf("temp_") > -1 || quiz_result_id === quiz_result['quiz_result'][i].result_id) {
                            var timeTaken = 0;
                            this.addIdleTimeToQuizResult(quiz_result['quiz_result'][i]);
                            last_question_index = Number(last_question_index) - 1;
                            if (typeof lastQuestionLoadedFromOnline != 'undefined' && lastQuestionLoadedFromOnline) {
                                var index = last_question_index - 1;
                            } else {
                                var index = last_question_index;
                            }
                            if (!isNaN(index)) {
                                if (typeof quiz_result['quiz_result'][i][index] !== 'undefined'
                                    && typeof quiz_result['quiz_result'][i][index]['question_result'] !== 'undefined') {
                                    isResume = true;
                                    var curr_question_result = quiz_result['quiz_result'][i][index]['question_result'];
                                    curr_question_result['time_taken'] = parseInt(curr_question_result['time_taken']);
                                    timeTaken = timeTaken + parseInt(curr_question_result['time_taken']);
                                    if (typeof index != "undefined" && index != null && typeof this.allQuestions.questions[index] != 'undefined') {
                                        this.allQuestions.questions[index]['question_result'] = curr_question_result;
                                        this.allQuestions['quiz_result'][index] = { 'question_result': curr_question_result };
                                        this.currentQuestionIndex = index;
                                    }
                                }
                            }
                            this.allQuestions['quiz_result'] = quiz_result['quiz_result'][i];
                            this.allQuestions['quiz_result'].attempt_log = quiz_result['quiz_result'][i]['attempt_log'] ? quiz_result['quiz_result'][i]['attempt_log'] : "";
                            this.allQuestions['quiz_result'].result_id = quiz_result['quiz_result'][i].result_id;
                            this.allQuestions['quiz_result'].time_start = parseInt(quiz_result['quiz_result'][i].time_start);
                            if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '' && (this.sectionNavigated == false && this.subjectNavigated == false)) {
                                if (!quiz_result['quiz_result'][i]['time_left'] || quiz_result['quiz_result'][i]['time_left'] == '0') {
                                    quiz_result['quiz_result'][i]['time_left'] = parseInt(this.allQuestions.time_limit) - timeTaken;
                                }
                                var resume_remaining_time_airplane = CONTROLLER.getVariable("quiz_resume_time_"+this.allQuestions.nid);
                                // Checking for Large exam certificate quiz with allow resume disabled  
                                // to show remaining time which comes from server as background time tracked
                                if (Platform.getNetworkState() == true && (this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2")
                                    && (this.allQuestions.allow_resume == "0")) {
                                    // checking whether quiz is in active or missed state 
                                    // so that if quiz is in missed state show entire duration instead of showing only remaining duration
                                    var test_series_current_tab = CONTROLLER.getVariable("test_series_tab");
                                    if (typeof test_series_current_tab != 'undefined' && test_series_current_tab == "active"
                                        || (this.allQuestions.attempt_expired_quiz == "1")) {
                                        // remaining duration coming from server
                                        var remaining_time = this.quiz.getTestRemainingDuration(quiz_result_id);
                                        if (typeof remaining_time != "undefined" && remaining_time != "") {
                                            this.MAX_TIME = remaining_time;
                                        } else {
                                            this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                        }
                                    } else {
                                        this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                    }
                                }else if(Platform.getNetworkState() == false && this.allQuestions.allow_resume == "0" && this.allQuestions.airplane_mode == "1" && typeof resume_remaining_time_airplane != 'undefined'){
                                    this.MAX_TIME = resume_remaining_time_airplane;
                                }else{
                                    this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                }
                            }
                        }
                    }
                }
            }
        } else {
            if (quiz_result['quiz_result'] != '') {
                for (var i in quiz_result['quiz_result']) {
                    if (quiz_result['quiz_result'][i]['is_evaluated'] == ''
                        || quiz_result['quiz_result'][i]['is_evaluated'] == 0) {
                        if (quiz_result['quiz_result'][i].result_id.indexOf("temp_") > -1 || quiz_result_id === quiz_result['quiz_result'][i].result_id) {
                            var timeTaken = 0;
                            this.addIdleTimeToQuizResult(quiz_result['quiz_result'][i]);
                            for (var j in quiz_result['quiz_result'][i]) {
                                if (!isNaN(j)) {
                                    if (typeof quiz_result['quiz_result'][i][j]['question_result'] !== 'undefined') {
                                        isResume = true;
                                        var curr_question_result = quiz_result['quiz_result'][i][j]['question_result'];
                                        var index = findIndexByKeyValue(this.allQuestions.questions, 'nid', curr_question_result.nid);
                                        curr_question_result['time_taken'] = parseInt(curr_question_result['time_taken']);
                                        timeTaken = timeTaken + parseInt(curr_question_result['time_taken']);
                                        if (typeof index != "undefined" && index != null && typeof this.allQuestions.questions[index] != 'undefined') {
                                            this.allQuestions.questions[index]['question_result'] = curr_question_result;
                                            this.allQuestions['quiz_result'][index] = { 'question_result': curr_question_result };
                                            this.currentQuestionIndex = index + 1;
                                        }
                                    }
                                }
                            }
                            this.allQuestions['quiz_result'] = quiz_result['quiz_result'][i];
                            this.allQuestions['quiz_result'].attempt_log = quiz_result['quiz_result'][i]['attempt_log'] ? quiz_result['quiz_result'][i]['attempt_log'] : "";
                            this.allQuestions['quiz_result'].result_id = quiz_result['quiz_result'][i].result_id;
                            this.allQuestions['quiz_result'].time_start = parseInt(quiz_result['quiz_result'][i].time_start);
                            if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '' && (this.sectionNavigated == false && this.subjectNavigated == false)) {
                                if (!quiz_result['quiz_result'][i]['time_left'] || quiz_result['quiz_result'][i]['time_left'] == '0') {
                                    quiz_result['quiz_result'][i]['time_left'] = parseInt(this.allQuestions.time_limit) - timeTaken;
                                }
                                var resume_remaining_time_airplane = CONTROLLER.getVariable("quiz_resume_time_"+this.allQuestions.nid);
                                // Checking for Large exam certificate quiz with allow resume disabled  
                                // to show remaining time which comes from server as background time tracked
                                if (Platform.getNetworkState() == true && (this.allQuestions.type == "certificate" && this.allQuestions.quiz_template == "2")
                                    && (this.allQuestions.allow_resume == "0")) {
                                    // checking whether quiz is in active or missed state 
                                    // so that if quiz is in missed state show entire duration instead of showing only remaining duration
                                    var test_series_current_tab = CONTROLLER.getVariable("test_series_tab");
                                    if (typeof test_series_current_tab != 'undefined' && test_series_current_tab == "active"
                                        || (this.allQuestions.attempt_expired_quiz == "1")) {
                                        // remaining duration coming from server
                                        
                                        if(typeof quiz_result_id == "undefined"){
                                            var deleted_inprogress_resultid = Platform.getPreference("deleted_inprogress_resultid_"+this.allQuestions.nid);
                                            if(typeof deleted_inprogress_resultid != "undefined" && deleted_inprogress_resultid != ""){
                                                quiz_result_id = deleted_inprogress_resultid;
                                            }
                                        }
                                        var remaining_time = this.quiz.getTestRemainingDuration(quiz_result_id);
                                        if (typeof remaining_time != "undefined" && remaining_time != "") {
                                            this.MAX_TIME = remaining_time;
                                        } else {
                                            this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                        }
                                    } else {
                                        this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                    }
                                }else if(Platform.getNetworkState() == false && this.allQuestions.allow_resume == "0" && this.allQuestions.airplane_mode == "1" && typeof resume_remaining_time_airplane != 'undefined'){
                                    this.MAX_TIME = resume_remaining_time_airplane;
                                }else {
                                    this.MAX_TIME = parseInt(quiz_result['quiz_result'][i]['time_left']);
                                }
                            }
                        }
                    }
                }
            }
        }
        //load first question of the section/subject whenever there is navigation between the subjects/sections.
        if (this.sectionNavigated == true || this.subjectNavigated == true) {
            this.currentQuestionIndex = 0;
        }
        if ((this.sectionNavigated == false && this.subjectNavigated == false) && (this.gameMode == true || (typeof QUIZ_MODE_ONLINE != 'undefined' && QUIZ_MODE_ONLINE == 'true'))) {
            var resumeQuiz = this.gameMode ? 'false' : 'true';
            var resultid = this.quiz.createResultId(this.allQuestions['nid'], this.allQuestions['quiz_result']['result_id'], resumeQuiz)
            var status = this.updateResultId(resultid);
            if (status == false) {
                return false;
            }
        }
        if (this.sectionNavigated == false && this.subjectNavigated == false) {
            return this.registerAttempt(isResume, callback, quiz_result_id);
        }
        else {
            if (typeof callback == "function") {
                callback(isResume);
            }
            else {
                return isResume;
            }
        }
    } catch (e) {
        console.log("Function : QuizResume, error => " + e.message);
    }
};

QuizView.prototype.registerAttempt = function (isResume, callback, quiz_result_id) {
    /**
     * Attempt needs to be registered online
     * before starting/resuming the quiz.
     */
    var forceRegisterAttempt = false;
    var preView = CONTROLLER.getVariable("previousView");
    var webinarFeedback = CONTROLLER.getVariable('webinarFeedback');
    var mode = getQueryParameter('mode', window.location.href);
    //added check for AllSubjects along with (typeof preView !== 'undefined' && preView === "TestSeries")
    if ((typeof preView !== 'undefined' && (preView === "TestSeries" ||
        preView === "AllSubjects" || preView === "HomePage" || preView === 'ListTask' || preView === "calender")) || (typeof webinarFeedback != 'undefined' && webinarFeedback == "true")
        || (typeof mode != 'undefined' && mode != "null" && (mode == "scormToolKit" || mode == 'adaptivelearning' || mode == 'iframemode'))) {
        forceRegisterAttempt = true;
    }
    if (this.gameMode || forceRegisterAttempt) {
        var resumeQuiz = 'true';
        if (this.gameMode) {
            if (this.allQuestions.type == CHALLENGE_MODES["flexi"] && this.resumedGame) {
                resumeQuiz = 'true';
            } else {
                resumeQuiz = 'false';
            }
        }
        //removed check if type not practice to create a result id even if test is of type practice
            var THIS = this;
            var attemptLogs = this.getAttemptLog();
            attemptLogJson = JSON.stringify({ "attempt_log": attemptLogs });

            if(typeof quiz_result_id == 'undefined' || quiz_result_id == ""){
                this.quiz.createResultId(this.allQuestions['nid'], this.allQuestions['quiz_result'].result_id, resumeQuiz, attemptLogJson, function (resultid) {
                    if (THIS.updateResultId(resultid, attemptLogs)) {
                        if (typeof callback == "function") {
                            callback(isResume);
                        } else {
                            return isResume;
                        }
                    }
                });
            }else{
                if(THIS.updateResultId(quiz_result_id, attemptLogs)) {
                    if (typeof callback == "function") {
                        callback(isResume);
                    }
                }
            }
            if (typeof callback != "function") {
                return isResume;
            }
        } else {
            if (typeof callback == "function") {
                callback(isResume);
            } else {
                return isResume;
            }
        }
};

QuizView.prototype.fixIsSkippedState = function (quiz_result) {
    //Changing is_skipped state as per app design for Online Quiz
    if (quiz_result['is_evaluated'] == ''
        || quiz_result['is_evaluated'] == 0) {
        var timeTaken = 0;
        for (var j in quiz_result) {
            if (typeof quiz_result[j]['question_result'] !== 'undefined') {
                if (typeof quiz_result[j]['question_result'] != 'undefined' && quiz_result[j]['question_result'].is_skipped == 2 && (quiz_result[j]['question_result'].user_answer == null
                    || quiz_result[j]['question_result'].user_answer.length == 0 || Object.keys(quiz_result[j]['question_result'].user_answer).length == 0)) {
                    quiz_result[j]['question_result'].is_skipped = 1; /* No user answer then it is considered as skipped */
                }
                else if (typeof quiz_result[j]['question_result'] != 'undefined' && quiz_result[j]['question_result'].is_skipped == 3 && (quiz_result[j]['question_result'].user_answer == null
                    || quiz_result[j]['question_result'].user_answer.length == 0 || Object.keys(quiz_result[j]['question_result'].user_answer).length == 0)) {
                    quiz_result[j]['question_result'].is_skipped = 2; /* marked for review and no user answer */
                }
                else if (typeof quiz_result[j]['question_result'] != 'undefined' && quiz_result[j]['question_result'].is_skipped == 1 && (quiz_result[j]['question_result'].user_answer != null && (quiz_result[j]['question_result'].user_answer.length > 0 || Object.keys(quiz_result[j]['question_result'].user_answer).length > 0))) {
                    quiz_result[j]['question_result'].is_skipped = 2; /* marked for review and user has given answer  */
                }
            }
        }
    }
    return quiz_result;
}

QuizView.prototype.QuizGameResume = function () {
    var THIS = this;
    this.createResultIdCalled = true;
    if (typeof this.allQuestions['quiz_result'] != "undefined" && Object.keys(this.allQuestions['quiz_result']).length != 0) {
        var temp_resultid = 'temp_quiz_' + this.allQuestions['nid'] + '_' + this.allQuestions['quiz_result']['time_start'];
        this.quiz.createResultId(this.allQuestions['nid'], temp_resultid, this.resumedGame, function (resultid) {
            THIS.updateResultId(resultid);
        });
    } else {
        this.allQuestions['quiz_result'] = this.generate_results();
        this.registerAttempt(false, false);
    }
};

QuizView.prototype.checkForEvalResult = function () {
    try {
        var results = this.quiz.getQuizResult(this.allQuestions.nid);
        if (results != '' && results != null && results['quiz_result'].length != 0) {
            var quiz_result = results['quiz_result'][results['quiz_result'].length - 1];
            if (quiz_result.is_evaluated == '1') {
                for (var j in quiz_result) {
                    if (typeof quiz_result[j]['question_result'] !== 'undefined') {
                        var curr_question_result = quiz_result[j]['question_result'];
                        var index = findIndexByKeyValue(this.allQuestions.questions, 'nid', curr_question_result.nid);
                        if (typeof index != "undefined" && index != null) {
                            this.allQuestions.questions[index]['question_result'] = curr_question_result;
                        }
                    }
                }
                this.allQuestions['quiz_result'] = quiz_result;
                var THIS = this;
                $('#footer_wrapper').remove();

                var log = 'Quiz Submit Retry';
                this.logUserAction(log);

                alert(SUBMIT_EVAL_ATTEMPT_MSG, function () {
                    THIS.retryQuizSubmit();
                    return;
                });
                return true;
            }
        }
        return false;
    } catch (e) {
        console.log("QuizView : checkForEvalResult - " + e);
    }
};

QuizView.prototype.questionResultSave = function (question_result, callback) {
    try {
        var index = 0;
        if (this.enableSectionalNavigation == true) {
            index = findIndexByKeyValue(this.allQuestions.sections[0].questionsdata, 'nid', question_result.nid);
            var currQuestion = this.allQuestions.sections[0].questionsdata[index];
        }
        else if (this.enableSubjectsNavigation == true) {
            index = findIndexByKeyValue(this.allQuestions.subjectsmeta[0].questionsdata, 'nid', question_result.nid);
            var currQuestion = this.allQuestions.subjectsmeta[0].questionsdata[index];
        } else {
            index = this.currentQuestionIndex;
            var currQuestion = this.allQuestions.questions[index];
        }
        this.allQuestions['quiz_result'][index] = { 'question_result': question_result };
        if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '')
            this.allQuestions['quiz_result']['time_left'] = this.MAX_TIME;

        var currQuestionResult = this.allQuestions['quiz_result'][index]['question_result'];
        var log = 'Question Saved';
        var logExtras = this.getLogExtrasForQuestion(currQuestion, currQuestionResult);
        this.logUserAction(log, logExtras);

        var attemptLogs = this.getAttemptLog();

        if(EXEC_MODE == 'online'){
            if (!this.allQuestions['quiz_result']['attempt_log']) {
                this.allQuestions['quiz_result']['attempt_log'] = "";
            }
            this.allQuestions['quiz_result']['attempt_log'] += attemptLogs;
        }else{
            this.saveAttemptLogsToDatabase(this.allQuestions, attemptLogs);
        }
        if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
            Platform.setPreference('lastViewedQuestionIndex_' + this.allQuestions.nid, this.currentQuestionIndex + 1);
        }
        if ((this.allQuestions['quiz_result'].result_id.indexOf("temp_") < 0 && this.allQuestions.quiz_template == '1') || this.gameMode) {
            this.saveOnlineQuizResult()
            //Changing is_skipped state as per portal design for Online Quiz
            var curr_question_result = $.extend(true, {}, this.allQuestions['quiz_result'][index]['question_result']);
            if(this.gameMode){
                curr_question_result['attempt_log'] = attemptLogs;
            }
            if (typeof curr_question_result != 'undefined' && curr_question_result.is_skipped == 1
                && (curr_question_result.user_answer == null || curr_question_result.user_answer.length == 0 || Object.keys(curr_question_result.user_answer).length == 0)) {
                curr_question_result.is_skipped = 2; /* No user answer then it is considered as skipped */
            }
            else if (typeof curr_question_result != 'undefined' && curr_question_result.is_skipped == 2
                && (curr_question_result.user_answer == null || curr_question_result.user_answer.length == 0 || Object.keys(curr_question_result.user_answer).length == 0)) {
                curr_question_result.is_skipped = 3; /* marked for review and no user answer */
            }
            else if (typeof curr_question_result != 'undefined' && curr_question_result.is_skipped == 2
                && (curr_question_result.user_answer != null && (curr_question_result.user_answer.length > 0 || Object.keys(curr_question_result.user_answer).length > 0))) {
                curr_question_result.is_skipped = 1; /* marked for review and user has given answer  */
            }
            this.quiz.saveQuestionResult(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(curr_question_result), callback);
        } else {
            this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
        }
        if (typeof this.questionIdleTime != "undefined") {
            this.resetQuestionIdleTime();
        }
    } catch (e) {
    }
};


QuizView.prototype.generate_results = function (quiz) {
    //Generate the Result data.
    if (typeof quiz == 'undefined') {
        quiz = this.allQuestions;
    }
    var generate_result = {};
    var time_start = getSyncedServerTime();
    var temp_resultid = 'temp_quiz_' + quiz['nid'] + '_' + time_start;
    generate_result['result_id'] = temp_resultid;
    generate_result['nid'] = quiz['nid'];
    generate_result['vid'] = quiz['vid'];
    generate_result['uid'] = OBJECT_USER_ID;
    generate_result['time_start'] = time_start;
    generate_result['time_end'] = "";
    generate_result['score'] = "";
    generate_result['is_invalid'] = "";
    generate_result['is_evaluated'] = "";
    generate_result['time_left'] = "";
    return generate_result;
};

QuizView.prototype.updateResultId = function (resultid, attemptLog) {
    if (this.checkOnlineQuizResponse(resultid)) {
        if (attemptLog) {
            if(EXEC_MODE == online){
                if (!this.allQuestions['quiz_result']['attempt_log']) {
                    this.allQuestions['quiz_result']['attempt_log'] = "";
                }
                this.allQuestions['quiz_result']['attempt_log'] += attemptLog;
            }else{
                this.saveAttemptLogsToDatabase(this.allQuestions, attemptLog, resultid);
            }
        }

        // in offline when temp result id is created send that as result id in quiz result
        if (resultid.indexOf("temp_") > -1) {
            this.allQuestions['quiz_result'].result_id = resultid;
            return true;
        }

        if (this.gameMode != true && (typeof this.allQuestions['quiz_template'] == 'undefined' || this.allQuestions['quiz_template'] == '2')) {
            /**
             * 1. For a dated quiz with allow_offline_attempt or quiz_template=2, attempt is registered online
             * before starting the quiz. And from here on the attempt can be continued in offline.
             * 2. gen_result_id stores the value of the generated result id.
             * 3. send gen_result_id as result_id for the sync to happen instead of temp_result_id
             */
            this.allQuestions['quiz_result']['gen_result_id'] = resultid;

            // if user attempts quiz in online and result id is created send result id as it is instead of temp_result_id
            this.allQuestions['quiz_result']['result_id'] = resultid;   
            
            if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '') {
                this.allQuestions['quiz_result'].time_left = this.MAX_TIME;
            }
            
            if (this.allQuestions['quiz_result']) {
                this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
            }
        } else {
            this.allQuestions['quiz_result']['gen_result_id'] = resultid;
            this.allQuestions['quiz_result']['result_id'] = resultid;
            this.saveOnlineQuizResult();
        }
        return true;
    }
    return false;
};

QuizView.prototype.checkOnlineQuizResponse = function (response) {
    if (!this.gameMode && (typeof response == 'undefined' || response == "" || response == null)) {
        this.exitFromQuiz(QUIZ_RESULT_ID_UNAVAILABLE);
        $('#footer_wrapper').empty();
        return false;
    }
    if (typeof response != 'undefined' && (response == USER_LOGOUT_ERROR || response == ERROR_MESSAGE)) {
        if (this.gameMode) {
            this.clearGameTimers();
        } else {
            window.clearInterval(QUIZ_TIMER);
            QUIZ_TIMER = null;
        }
        if (response == USER_LOGOUT_ERROR) {
            onAccessDenied();
        } else {
            this.exitFromQuiz(response);
        }
        $('#footer_wrapper').empty();
        return false;
    }
    return true;
};

QuizView.prototype.quizResultSave = function (callback) {
    try {
        $.each(this.allQuestions['quiz_result'], function (index, value) {
            if (typeof value != 'undefined' && typeof value['question_result'] != 'undefined' && value['question_result'].is_skipped == 2 && (value['question_result'].user_answer == null
                || value['question_result'].user_answer.length == 0 || Object.keys(value['question_result'].user_answer).length == 0)) {
                value['question_result'].is_skipped = 1; /* marked for review and no user answer then it is considered as skipped */
            }
            else if (typeof value != 'undefined' && typeof value['question_result'] != 'undefined' && value['question_result'].is_skipped == 2 && (value['question_result'].user_answer != null
                && (value['question_result'].user_answer.length > 0 || Object.keys(value['question_result'].user_answer).length > 0))) {
                value['question_result'].is_skipped = 0; /* marked for review and user has given answer then it is considered as attempted */
            }
        });
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            if (typeof QUIZ_RESOURCE_PARAMS.type != 'undefined' && (typeof RESOURCE_TYPE_TEST_COLLECTION != 'undefined' && QUIZ_RESOURCE_PARAMS.type == RESOURCE_TYPE_TEST_COLLECTION)) {
                var testCollectionId = QUIZ_RESOURCE_PARAMS.url;
                this.allQuestions['quiz_result']['testCollectionId'] = testCollectionId;
                this.allQuestions['quiz_result']['courseid'] = QUIZ_RESOURCE_PARAMS.courseid;
                this.allQuestions['quiz_result']['resourceid'] = QUIZ_RESOURCE_PARAMS.resourceid;
            }
        }
        this.allQuestions['quiz_result']['score'] = this.getTotalScore();
        this.allQuestions['quiz_result']['is_evaluated'] = 1;
        if (typeof this.MAX_TIME != 'undefined' && this.MAX_TIME != null && this.MAX_TIME != '')
            this.allQuestions['quiz_result']['time_left'] = this.MAX_TIME;
        else
            this.allQuestions['quiz_result']['time_left'] = 0;
        this.allQuestions['quiz_result']['time_end'] = Math.floor(getSyncedServerTime() / 1000);

        var log = 'Quiz Submit';
        this.logUserAction(log);

        var attemptLogs = this.getAttemptLog();

        if(EXEC_MODE == 'online'){
            if (!this.allQuestions['quiz_result']['attempt_log']) {
                this.allQuestions['quiz_result']['attempt_log'] = "";
            }
            this.allQuestions['quiz_result']['attempt_log'] += attemptLogs;
        }else{
            this.saveAttemptLogsToDatabase(this.allQuestions, attemptLogs);
        }

        if(Platform.getPreference("backgroundQuizDownload_" + this.allQuestions.nid) != 'undefined' && Platform.getPreference("backgroundQuizDownload_" + this.allQuestions.nid) == "true"){
            Platform.deleteFileFromInternalMemory(this.allQuestions.nid);
            Platform.cancelDownloads(this.allQuestions.nid);
            Platform.removeObject("resource", this.allQuestions.nid);
            Platform.setPreference("backgroundQuizDownload_" + this.allQuestions.nid, "false");
        }

        if (this.allQuestions['quiz_result'].result_id.indexOf("temp_") > -1) {
            this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']), testCollectionId);
        }
        this.saveResultstoAdmin(this.allQuestions['quiz_result'].result_id, attemptLogs, callback);
        if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
            // setting last viewed question indedx to empty
            Platform.setPreference('lastViewedQuestionIndex_' + this.allQuestions.nid, '');
            // checking questions availablity for single question quiz and getting questions if not available
            this.allQuestions = this.checkForQuestionsAvailabiltyAndDownload(this.allQuestions, true);
        }
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
            var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
            if (device_in_airplane_mode) {
                confirm(t("To submit the test, kindly switch off the Airplane mode and turn on the internet."), function () {
                    CONTROLLER.setVariable("airplane_mode_settings", "true");
                    Platform.enableAirplaneMode();
                    Platform.setPreference("app_minimized", "");
                }, "OK", "Cancel");
                var app_minimized = Platform.getPreference("app_minimized");
                if (typeof app_minimized !== 'undefined' || app_minimized !== '') {
                    Platform.setPreference("app_minimized", "");
                }
            }
        }
    } catch (e) {
        console.log(e.message);
    }
};

QuizView.prototype.saveResultstoAdmin = function (result_id, attemptLogs, callback) {
    try {
        var THIS = this;
        Client.showProgressBar(t("Loading") + "...");
        if (result_id && result_id.indexOf("temp_") < 0) {
            if(EXEC_MODE == "online"){
                Platform.removeObject("quiz_result", result_id);
                Platform.removeRelation(OBJECT_QUIZ, this.quiz_nid, "quiz_result", result_id);
            }
            if (this.gameMode) {
                var res = this.quiz.finishQuiz(this.quiz_nid, result_id, attemptLogs);
                if (res == false) {
                    this.saveOnlineQuizResult();
                    if (typeof callback == 'function') {
                        callback(false);
                    }
                } else {
                    var temp_result_id = this.allQuestions['quiz_result']['temp_result_id'];
                    if(EXEC_MODE == "online"){
                        Platform.removeObject("quiz_result", temp_result_id);
                        Platform.removeRelation(OBJECT_QUIZ, this.quiz_nid, "quiz_result", temp_result_id);
                    }
                    if (typeof callback == 'function') {
                        callback(true);
                    }
                }
                Client.closeProgressBar();
                return;
            }
            this.saveOnlineQuizResult();
        } else {
            this.replaceResultId(result_id);
        }
        if (EXEC_MODE == "online") {
            if (Platform.getNetworkState() == true) {
                var uploadStatus = THIS.uploadQuizLog();
                if (uploadStatus == false) {
                    CONTROLLER.setVariable('quizSubmitErrorMessage', QUIZ_LOG_SUBMIT_FAILURE);
                    if (typeof callback == 'function') {
                        callback(false);
                    }
                    Client.closeProgressBar();
                    return false;
                }

                //delete logs from quiz object before submitting
                delete this.allQuestions['quiz_result']["attempt_log"];
                Platform.uploadQuiz(this.allQuestions['quiz_result'], function (uploadStatus) {
                    if (uploadStatus == QUIZ_NOT_SUBMITTED || uploadStatus == USER_LOGOUT_ERROR) {
                        CONTROLLER.setVariable('quizSubmitErrorMessage', uploadStatus);
                        status = false;
                    } else {
                        Platform.removeRelation(OBJECT_QUIZ, THIS.quiz_nid, 'quiz_result');
                        if (result_id && result_id.indexOf("temp_") < 0) {
                            var temp_result_id = THIS.allQuestions['quiz_result']['temp_result_id'];
                            Platform.removeObject("quiz_result", temp_result_id);
                        } else {
                            Platform.removeObject('quiz_result', result_id);
                        }
                        status = true;
                    }
                    Client.closeProgressBar();
                    if (typeof callback == 'function')
                        return callback(status);
                });
                return;
            } else {
                // CONTROLLER.setVariable('quizSubmitErrorMessage', QUIZ_NO_INTERNET_NOT_SUBMITTED);
                CONTROLLER.setVariable('quizSubmitErrorMessage', QUIZ_NOT_SUBMITTED);
                if (typeof callback == 'function') {
                    callback(false);
                }
                Client.closeProgressBar();
            }
        } else {
            this.quiz.quizResultSave(result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
            var upload = Platform.uploadQuiz();
            if (upload == true || upload == false) {
                Client.closeProgressBar();
                if (Platform.getNetworkState() == true) {
                    Client.showProgressBar(t("Submitted successfully!"));
                }
                CONTROLLER.setVariable("scored_percentage", this.allQuestions.quiz_result.score);
                setTimeout(function () {
                    var scored_percentage = CONTROLLER.getVariable("scored_percentage")
                    if (typeof ENABLE_APP_REVIEW != "undefined" && ENABLE_APP_REVIEW == "true" && typeof scored_percentage != "undefined" && scored_percentage != "" && scored_percentage >= 80
                        && typeof Platform.rateThisApp != "undefined" && typeof Platform.rateThisApp == "function") {
                        Client.closeProgressBar();
                        Platform.rateThisApp();
                    }
                    CONTROLLER.setVariable("scored_percentage", "");
                }, 500);
            }
            setTimeout(function () {
                if (typeof callback == 'function') {
                    if (typeof THIS.allQuestions.type != 'undefined' && THIS.allQuestions.type == 'certificate') {
                        callback(upload);
                    } else {
                        callback(true);
                    }
                }
                Client.closeProgressBar();
            }, 250);
        }
    } catch (e) {
        Client.closeProgressBar();
        console.log(e.message);
        if (typeof callback == 'function') {
            callback(false);
        }
    }
};

QuizView.prototype.handleSubmitFailure = function () {
    this.allQuestions['quiz_result']['is_evaluated'] = '1';
    if (this.allQuestions['quiz_result']['result_id'].indexOf("temp_") == -1) {
        if (this.allQuestions['quiz_result']['temp_result_id'] && this.allQuestions['quiz_result']['temp_result_id'].indexOf("temp_") > -1) {
            this.allQuestions['quiz_result']['result_id'] = this.allQuestions['quiz_result']['temp_result_id'];
        } else {
            var temp_result_id = 'temp_quiz_' + this.allQuestions['nid'] + '_' + this.allQuestions['quiz_result']['time_start'];
            this.allQuestions['quiz_result']['result_id'] = temp_result_id;
        }
    }
    this.quiz.quizResultSave(this.allQuestions['quiz_result'].result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
};

/**
 * 1. gen_result_id needs to be replaced with result_id before submitting the quiz.
 * This is done only for quizzes with attempt being registered on start/resume of the quiz.
 * 2. temp_result_id is used by App Platform team to update the result obtained from server after submit api call. temp_result_id stores the result in "temp_quiz_<quizId>_<timestamp>" format.
 */

QuizView.prototype.saveOnlineQuizResult = function () {
    /**
     *  This method is used for saving online quiz results in local db after updating question results to the server.
     */
    var temp_result_id = "";
    if (this.allQuestions['quiz_result'] && typeof this.allQuestions['quiz_result']['temp_result_id'] != "undefined" && this.allQuestions['quiz_result']['temp_result_id'].indexOf("temp_") > -1) {
        temp_result_id = this.allQuestions['quiz_result']['temp_result_id'];
    } else {
        temp_result_id = 'temp_quiz_' + this.allQuestions['nid'] + '_' + this.allQuestions['quiz_result']['time_start'];
        this.allQuestions['quiz_result']['temp_result_id'] = temp_result_id;
    }
    this.quiz.quizResultSave(temp_result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
};

QuizView.prototype.replaceResultId = function (result_id) {
    /**
     * 1. gen_result_id needs to be replaced with result_id before submitting the quiz.
     * This is done only for quizzes with attempt being registered on start/resume of the quiz.
     * 2. temp_result_id is used by App Platform team to update the result obtained from server after submit api call. temp_result_id stores the result in "temp_quiz_<quizId>_<timestamp>" format.
     */
    if (typeof this.allQuestions['quiz_result']['gen_result_id'] != 'undefined') {
        this.allQuestions['quiz_result']['temp_result_id'] = this.allQuestions['quiz_result']['result_id'];
        this.allQuestions['quiz_result']['result_id'] = this.allQuestions['quiz_result']['gen_result_id'];
        this.quiz.quizResultSave(result_id, this.allQuestions.nid, JSON.stringify(this.allQuestions['quiz_result']));
    }
};

$('.match_options_select').each(function () {
    select = this;
    if ($(select).val()) {
        $.each(THIS.question.match, function (key, value) {
            var id = $(select).find(":selected").attr("title");
            if (id == value["match_id"]) {
                correct = 1;
            } else {
                wrong_answer = true;
            }

            selected = selected++;
        });
    }
    else {
        wrong_answer = true;
    }
});

QuizView.prototype.getTotalMarks = function (questions) {
    var tot_marks = 0;
    for (var i in questions) {
        tot_marks += parseInt(questions[i].max_score);
    }
    return tot_marks;
};
QuizView.prototype.getTotalScore = function () {
    var correct = 0;
    if (this.enableSectionalNavigation == true) {
        this.allQuestions.questions = this.allQuestions.sections[0].questionsdata;
    } else if (this.enableSubjectsNavigation == true) {
        this.allQuestions.questions = this.allQuestions.subjectsmeta[0].questionsdata;
    } else {
        this.allQuestions.questions = this.allQuestions.questions;
    }
    var totalLength = this.getTotalMarks(this.allQuestions.questions);
    var answer = "";
    for (var i in this.allQuestions.questions) {
        if (typeof this.allQuestions.questions[i]['question_result'] !== 'undefined') {

            correct += parseFloat(this.allQuestions.questions[i]['question_result']['points_awarded']);
        }
    }
    var user_total_percentage = parseFloat((correct * 100 / totalLength), 2);
    if (user_total_percentage < 0) {
        user_total_percentage = 0;
    }
    this.allQuestions['quiz_result']['total_score'] = user_total_percentage;
    return user_total_percentage;
}

QuizView.prototype.show_legend = function (button, number) {
    var THIS = this;
    var questions = [];
    if (typeof this.allQuestions.questions !== 'undefined') {
        questions['questions'] = this.allQuestions.questions;
        questions['quiztype'] = this.allQuestions.type;
        if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
            && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
            if (typeof this.allQuestions.question_numbers == 'undefined' ||
                this.allQuestions.question_numbers == "" || this.allQuestions.question_numbers == null) {
                var question_numbers = THIS.addLegendQuestionNumbers();
                questions['question_numbers'] = question_numbers;
                this.allQuestions['question_numbers'] = question_numbers;
            }
            questions['question_numbers'] = this.allQuestions.question_numbers;
            this.renderTemplate('nav_list_new.tl', questions, '#quiz_wrapper', false);
        } else {
            this.renderTemplate('nav_list.tl', questions, '#quiz_wrapper', false);
        }
        var catid = "";
        if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
            catid = QUIZ_RESOURCE_PARAMS.catid;
        }
        else {
            catid = CONTROLLER.getVariable("activecatid");
        }
        $('.question_nav_title_no').click(function (e) {
            // load single question
            if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION
                && typeof SYNCED_QUESTION_DATA != 'undefined' && !SYNCED_QUESTION_DATA) {
                Client.showProgressBar(t("Loading") + "...");
                var question_number_index = Number($(this).attr('index'));
                THIS.addSelectedQuestionsFromLegenedToQuiz(THIS.allQuestions.nid, question_number_index)
            }
            var page_no = $(this).attr('page');
            THIS.modeCount = parseInt(page_no) + 1;
            if (EXEC_MODE == "mobile" && typeof THIS.allQuestions.airplane_mode != 'undefined' && THIS.allQuestions.airplane_mode == "1") {
                var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
                if (!device_in_airplane_mode) {
                    confirm(t("To take the quiz, device needs to be in Airplane mode. Click on OK to take you to settings for enabling Airplane mode"), function () {
                        CONTROLLER.setVariable("airplane_mode_settings", "true");
                        Platform.enableAirplaneMode();
                        THIS.goQuestion(this, page_no);
                    }, "OK", "Cancel");
                } else {
                    THIS.goQuestion(this, page_no);
                }
            } else {
                THIS.goQuestion(this, page_no);
            }
        });

        $('.quiz_cancel').click(function (e) {
            if (!$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('question_nav_show_tilde') && !$(e.target).hasClass('question_nav_title_no') && !$(e.target).hasClass('active_legend') && $(e.target).attr('id') != 'search_icon') {
                $('#question_nav_show').remove();
                $(e.target).removeClass('active_legend');
            }
        });

        $('.quiz_submit').click(function () {
            THIS.quizEnd(this);
            if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
                trackScreenEventDetails({ 'context': 'submit', 'event_name': 'TB_TAP_SUBMIT' });
            }
        });
    }

};

QuizView.prototype.goQuestion = function (button, number) {
    var answer_result = this.currentQuestionView.checkUserScore(button, true);
    var seconds = this.timeTaken();
    var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
    // adding time taken for each question
    if (typeof quiz_result_obj != "undefined") {
        answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds);
    } else {
        answer_result['user_result_answer']['time_taken'] = seconds;
    }
    this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
    this.questionResultSave(answer_result['user_result_answer']);
    this.currentQuestionIndex = parseInt(number);
    this.renderQuestion(true);
};

QuizView.prototype.quizEnd = function () {
    if (typeof this.isResume != "undefined" && this.isResume == true) {
        this.reportRender(false);
        this.isResume = false;
    } else {
        var attempt_all = this.allQuestions.all_questions_mandatory;
        var answer_result = this.currentQuestionView.checkUserScore('', true);
        var seconds = this.timeTaken();
        var quiz_result_obj = this.allQuestions['quiz_result'][this.currentQuestionIndex];
        // adding time taken for each question
        if (typeof quiz_result_obj != "undefined") {
            answer_result['user_result_answer']['time_taken'] = (quiz_result_obj['question_result']['time_taken'] + seconds);
        } else {
            answer_result['user_result_answer']['time_taken'] = seconds;
        }
        this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
        this.questionResultSave(answer_result['user_result_answer']);
        var isAppResume = CONTROLLER.getVariable("onAppResume");
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1"
            && isAppResume === "true") {
            this.reportRender(false);
            clearInterval(clearcheck);
        } else {
            if (typeof attempt_all != "undefined" && attempt_all == 1) {
                var count = this.getResultsLength();
                if (this.allQuestions.questions.length != count) {

                    if (this.allQuestions.questions.length - 1 == count) {
                        var answer_result = this.currentQuestionView.checkUserScore('', this.revise);
                        if (answer_result['user_result_answer'].questiontype != 'long_answer' && answer_result['user_result_answer'].answer.length == 0) {
                            Client.closeProgressBar();
                            alert(t('ENTER_CORRECT_ANSWER_PROMPT', true));
                            return "";
                        } else if (answer_result['user_result_answer'].is_skipped == 1) {
                            Client.closeProgressBar();
                            alert(t("SKIP_ERROR", true));
                            return "";
                        } else if (answer_result['user_result_answer'].is_skipped == 2) {
                            Client.closeProgressBar();
                            alert(t("QUIZ_SKIP_ERROR", true));
                            return;
                        } else {
                            this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                            this.questionResultSave(answer_result['user_result_answer']);
                            var skip = this.containsSkip();
                            if (skip != undefined && skip == true) {
                                Client.closeProgressBar();
                                alert(t("QUIZ_SKIP_ERROR", true));
                                return;
                            }
                            this.reportRender(false);
                        }
                    } else {
                        Client.closeProgressBar();
                        alert(t("QUIZ_SKIP_ERROR", true));
                        return;
                    }
                } else {
                    var skip = this.containsSkip();
                    if (skip != undefined && skip == true) {
                        Client.closeProgressBar();
                        alert(t("QUIZ_SKIP_ERROR", true));
                        return;
                    }
                    this.reportRender(false);
                }
            } else {
                this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = answer_result['user_result_answer'];
                this.questionResultSave(answer_result['user_result_answer']);
                this.reportRender(false);
            }
        }
    }
};
QuizView.prototype.getResultsLength = function () {
    count = 0;
    var results = this.allQuestions.quiz_result;
    $.each(results, function (index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            count++;
        }
    });
    return count;
};
QuizView.prototype.containsSkip = function () {
    var results = this.allQuestions.quiz_result;
    var skipped = false;
    $.each(results, function (index, value) {
        if (value && typeof value['question_result'] != "undefined") {
            if (value['question_result'].is_skipped == 1 || (value['question_result'].is_skipped == 2 && value['question_result'].user_answer.length == 0)) {
                skipped = true;
            }
        }
    });
    return skipped;
};
QuizView.prototype.getQuizAttempts = function () {
    var quiz_result = this.quiz.getQuizResult(this.allQuestions.nid);
    return quiz_result['quiz_result'].length;
};

//get quiz instructions in dialogue
QuizView.prototype.getQuizInstructions = function (lang) {
    var quiz_instructions = this.getActiveLangInstructions(lang);
    if (typeof quiz_instructions.instructions != 'undefined' && quiz_instructions.instructions != "") {
        quiz_instructions.instructions = this.replaceInstructionPlaceholders(quiz_instructions.instructions);
        if ($('.instructions_outer_wrapper').length == 0) {
            $('body').append('<div class="instructions_outer_wrapper"></div>');
        }
        $('.instructions_outer_wrapper').dialog({
            resizable: false,
            modal: true,
            height: 500,
            width: 361,
            dialogClass: 'dlgfixed',
            position: "center",
            closeOnEscape: false
        });
        this.renderTemplate("quiz_start_instruction.tl", quiz_instructions, ".instructions_outer_wrapper", true);
        this.bindQuizLanguageChange();
        if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1) {
            $('#selectQuizLang_wrapper').removeClass('hidden');
            var select_height = $('#selectQuizLang_wrapper').height() + 10;
            $('.instructions_inner_wrapper').css({ 'padding-bottom': select_height + 'px' });
            this.bindQuizChooseLanguage();
            this.bindContinueClick(true);
        } else {
            this.bindContinueClick(false);
        }
        $(".dlgfixed").center(false);
        $('.instructions_outer_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.instructions_outer_wrapper #selectedQuizLang, instructions_outer_wrapper #chooseLang').css({ 'height': 'auto' });
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('.ui-dialog-buttonpane').css({
            "text-align": "center",
            margin: "15px"
        });
        $('.ui-button').css({ 'display': 'none' });
        $('.inst_title').css({ "font-size": "20px", 'font-family': 'Roboto Bold', 'display': 'inline-block', 'width': '70%', 'font-weight': '600' });
        $('.instructions_outer_wrapper').parent().addClass('alert_wrapper_dialog');
        $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
        $('html body .ui-dialog').css({
            'z-index': '99999999',
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'padding': 0
        });
        $(".ui-dialog-titlebar").hide();
        $('html body .ui-dialog').css({
            "height": $(window).height() + "px", "width": $(window).width() + "px", "display": "block", 'z-index': '99999999', "overflow-x": " auto",
            'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'overflow-y': 'scroll', "left": 0, "top": 0, "margin-left": "0", "margin-top": "0"
        });

        $('.instructions_inner_wrapper,.instructions_outer_wrapper').css('height', $(window).height() - 71 + "px");
        $('.instructions_outer_wrapper').css({ "margin-top": "20px", "padding": 0 });
        if ($(window).width() < 380) {
            $('.continue, .instructions_inner_wrapper, .inst_title').css({ 'font-size': '16px' });
            $('.continue').click(function () {
                if (typeof TRACK_SCREEN_EVENTS != 'undefined' && TRACK_SCREEN_EVENTS == true) {
                    trackScreenEventDetails({ 'context': 'continue', 'event_name': 'TB_TAP_CONTINUE' });
                }
            })
        }
        $('html body .ui-dialog').css({ "height": $(window).height() - 3 + "px" });
        $('.instructions_inner_wrapper img').css({ 'max-width': '100%', 'height': 'auto' });

        if (typeof MathJax != "undefined") {
            MathJax.Hub.Queue(["Typeset", MathJax.Hub, 'instructions_outer_wrapper']);
        }
        if(APP_TYPE != "web"){
            $(".instructions_outer_wrapper a").unbind('click').bind("click", function (e) {
                var url = $(this).attr("href");
                if(typeof url != "undefined" && url != "" ){
                    if($.isFunction(Client.openUrlInWebview)){
                        loadContentInWebview(url, false, 'portrait', false, "");
                    }else{
                        Platform.openBrowser(url);
                    }
                }
            });
        }
    } else {
        $('#gameinfo_wrapper').addClass('hidden');
        this.startQuiz();
    }
};

QuizView.prototype.bindContinueClick = function (checkLanguage) {
    var THIS = this;
    $('.cont_wrapper').unbind("click").bind("click", function () {
        if (checkLanguage == true) {
            var value = $('#selectedQuizLang').find('option:selected').attr('value');
            if (value != '') {
                THIS.startQuiz();
                $('.instructions_outer_wrapper').dialog("close");
                $('#quiz_inst_overlay, .instructions_outer_wrapper').remove();
            } else {
                $('.selectQuizLang_text').css('color', 'red');
            }
        } else {
            THIS.startQuiz();
            $('.instructions_outer_wrapper').dialog("close");
            $('#quiz_inst_overlay, .instructions_outer_wrapper').remove();
        }
    });
}

QuizView.prototype.bindQuizLanguageChange = function () {
    var THIS = this;
    $('#selectedInsLang').on('change', function (e) {
        $('#selectedInsLang').off();
        $('.instructions_outer_wrapper').dialog("close");
        $('#quiz_inst_overlay, .instructions_outer_wrapper').remove();
        THIS.getQuizInstructions(this.value);
    });
};

QuizView.prototype.bindQuizChooseLanguage = function () {
    var THIS = this;
    $('#selectedQuizLang').on('change', function (e) {
        if (this.value != '') {
            $('.selectQuizLang_text').css('color', '#000');
            THIS.activeQuizLanguage = this.value;
            CONTROLLER.setVariable('activeQuizLanguage', this.value);
        }
    });
};

QuizView.prototype.getActiveLangInstructions = function (lang) {
    if (typeof lang == 'undefined') {
        lang = '';
    }
    var instructions_json = {
        'title': this.allQuestions.title,
        'instructions': this.allQuestions.quiz_instruction,
    }
    if (typeof this.allQuestions.language != 'undefined' && this.allQuestions.language.length > 1) {
        instructions_json.defaultLang = this.quiz.getQuestionLocaleInfo(this.allQuestions.language, this.activeQuizLanguage);

        if (typeof this.allQuestions.updatedLocale != 'undefined' && typeof this.allQuestions.updatedLocale.quiz_instruction != 'undefined') {
            var updatedLocale = this.allQuestions.updatedLocale.quiz_instruction;
            if (Object.keys(updatedLocale).length > 1) {
                instructions_json.localeInfo = this.quiz.getQuestionLocaleInfo(Object.keys(updatedLocale), lang);
                for (var i in updatedLocale) {
                    if (i == lang) {
                        instructions_json.instructions = updatedLocale[i];
                        break;
                    }
                }
            }
        }
    }
    return instructions_json;
}

QuizView.prototype.replaceInstructionPlaceholders = function (instruction) {
    var quiz = this.allQuestions;
    var max_attempts = "";
    var user_attempts = '';
    max_attempts = quiz.takes;
    user_attempts = quiz.attempts;
    var activeContent_type = CONTROLLER.getVariable("content_type");
    if (typeof activeContent_type != 'undefined' && typeof OBJECT_TEST_COLLECTION != "undefined" && activeContent_type == OBJECT_TEST_COLLECTION) {
        max_attempts = TEST_COLLECTION_MAX_ATTEMPTS;
        user_attempts = TEST_COLLECTION_USER_ATTEMPTS;
    }
    instruction = instruction.replace(/@user_attempt/g, user_attempts + 1);
    instruction = instruction.replace(/@max_attempt/g, max_attempts);
    instruction = instruction.replace(/@test_time/g, Math.round(quiz.time_limit / 60));
    instruction = instruction.replace(/@pass_marks/g, quiz.pass_rate);
    instruction = instruction.replace(/@total_question/g, quiz.question_count);
    return instruction;
}

QuizView.prototype.gobackToPreviousView = function (searchtext, courseid) {
    var preView = CONTROLLER.getVariable("previousView");
    var listallquizzesview = CONTROLLER.getVariable("listallquizzes");
    var myactivity = CONTROLLER.getVariable("myactivity");
    var notificationtype = CONTROLLER.getVariable('notificationType');
    var notificationUrlType = CONTROLLER.getVariable('notificationUrlType');
    var subjectsToResourseView = CONTROLLER.getVariable("subjectsToResourseView");
    var mode = getQueryParameter("mode", window.location.href);
    if ($("#solution_embed_wrapper").length == 1) {
        backFromSolution();
        return;
    }
    if(typeof mode !== 'undefined' && mode !== null && mode !== 'null' && mode === 'iframemode'){
        window.parent.postMessage('navigateBack', '*');
    } else if (notificationtype != "" && notificationtype == "action" && notificationUrlType != "course") {
        CONTROLLER.loadView("HomePage");
        CONTROLLER.setVariable('notificationType', "");
    } else if (CONTROLLER.getVariable("previousView") == 'calender') {
        CONTROLLER.setVariable("previousView", "");
        CONTROLLER.loadView("Calender");
    } else if (typeof listallquizzesview !== 'undefined' && listallquizzesview === "true") {
        CONTROLLER.loadView("ListAllQuizzes");
        CONTROLLER.setVariable("listallquizzes", "");
    } else if (typeof preView !== 'undefined' && preView === "HomePage") {
        if (typeof myactivity !== 'undefined' && myactivity === "true") {
            CONTROLLER.loadView("MyActivities");
            CONTROLLER.setVariable("myactivity", "");
            $('#header_wrapper').css({ 'position': '' });
        } else {
            CONTROLLER.loadView("HomePage");
            CONTROLLER.setVariable("previousView", "HomePage");
        }
    } else if (typeof preView !== 'undefined' && preView === "ChallengeTab") {
        Client.showProgressBar(t("Loading") + "...");
        CONTROLLER.setVariable("previousView", "");
        CONTROLLER.loadView("ChallengeTab");
        $('#header_wrapper').removeClass('game_header');
    } else if (typeof preView !== undefined && preView === "TestSeries") {
        CONTROLLER.loadView("TestSeries");
    } else if (typeof searchtext != "undefined" && searchtext != "") {
        CONTROLLER.setVariable("activesearchtext", "");
        CONTROLLER.loadView("Search", searchtext);
    } else if (typeof preView !== undefined && preView === "Category") {
        var catid = CONTROLLER.getVariable("activecatid");
        CONTROLLER.loadView("Category", catid);
        CONTROLLER.setVariable("previousView", "");
    } else if (typeof preView !== 'undefined' && preView == "PreAssessment") {
        Platform.closeApplication();
    } else if (typeof preView !== undefined && preView === "notificationView") {
        CONTROLLER.loadView('Notifications', 'announcements');
        CONTROLLER.setVariable("previousView", "HomePage");
    }else if(typeof subjectsToResourseView != "undefined" && subjectsToResourseView == 'true'){
        CONTROLLER.setVariable("subjectsToResourseView", '');
        CONTROLLER.loadView("AllSubjects");
    } else {
        if(CONTROLLER.getVariable("previousView") == "VideoPlayer"){
            if (FORCE_SKIP_LIBRARY_VIEW == true && SHOW_LIBRARY_COURSES == true) {
                CONTROLLER.setVariable("previousView", "Downloads");
            } else {
                CONTROLLER.setVariable("previousView", "AllSubjects");
            }
        }
        CONTROLLER.loadView("Course", courseid);
    }
};

QuizView.prototype.checkOnAttempts = function (quiz_attempts, nid) {
    var quiz_res = ""; var attempts = 0; var questions = '';
    var question_result = ""; var THIS = this,offlinequizresults = false;
    if (typeof quiz_attempts != "undefined") {
        if (Platform.getNetworkState() == false) {
            attempts = quiz_attempts;
            offlinequizresults = CONTROLLER.getVariable("offlinequizresults");
        }
        else {
            attempts = quiz_attempts['attempts'].total_attempts;
        }
    }
    
    if( (typeof quiz_attempts != 'undefined' && typeof quiz_attempts['attempts'] != 'undefined') || offlinequizresults){
        var quiz_attempt_data = offlinequizresults.quiz_result || quiz_attempts['attempts'].quiz_attempt_data;
        if (typeof quiz_attempt_data !== 'undefined' && quiz_attempt_data.length > 0) {
            quiz_attempt_data = quiz_attempt_data[quiz_attempt_data.length - 1];
            if (quiz_attempt_data.is_evaluated != 1 && quiz_attempt_data.time_end == 0) {
                attempts = attempts - 1;
                var quiz_result_id = quiz_attempt_data.result_id;
            }
        }
        
    }
    if(offlinequizresults){
        CONTROLLER.setVariable("offlinequizresults",false);
    }
    this.allQuestions.attempts = attempts;
    if (this.allQuestions.takes != 0 && attempts >= this.allQuestions.takes) {
        questions = this.quiz.getQuestionsForQuiz(nid, this.allQuestions.changed);
        this.allQuestions['questions'] = questions['question'];
        var results = this.quiz.getQuizResult(nid);
        if (results != '' && results != null && results['quiz_result'].length != 0) {
            quiz_res = results['quiz_result'][results['quiz_result'].length - 1];
            THIS.allQuestions.quiz_result = quiz_res;
            if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
                var last_question_index = Platform.getPreference('lastViewedQuestionIndex_' + THIS.allQuestions.nid);
                if (typeof last_question_index != 'undefined' && last_question_index != "" && last_question_index != null) {
                    last_question_index = Number(last_question_index);
                    THIS.allQuestions['questions'][last_question_index - 1].question_result = quiz_res[last_question_index - 1].question_result;
                } else {
                    THIS.allQuestions = THIS.checkForQuestionsAvailabiltyAndDownload(THIS.allQuestions, false);
                }
            } else {
                $.each(quiz_res, function (key, value) {
                    if (!isNaN(key)) {
                        THIS.allQuestions['questions'][key].question_result = quiz_res[key].question_result;
                    }
                });
            }
        }
        var params = {
            "report": THIS.allQuestions
            , "replace_item": '#quiz_wrapper'
            , "replace": true
        };
        if (typeof BREAK_MODE != "undefined" && BREAK_MODE == "true") {
            var id = CONTROLLER.getVariable('breakquizid');
            params.replace_item = '#quiz_breaks_outer_wrapper #quiz_wrapper #break_quiz_wrapper_' + id;
            $('#footer_wrapper').remove();
        }
        CONTROLLER.loadChildView('Report', function (view) {
            view.render(params);
        });
    }
    else {
        if(typeof quiz_result_id == "undefined"){
            var quiz_result_id = "";
        }
        this.renderQuiz(nid, quiz_result_id);
    }
};

QuizView.prototype.startQuiz = function () {
    if (this.currentQuestionIndex >= this.allQuestions.questions.length) {
        this.currentQuestionIndex = this.allQuestions.questions.length - 1;
        this.renderQuestion(true);
    } else {
        this.renderQuestion(false);
    }
}

QuizView.prototype.renderQuiz = function (nid, quiz_result_id) {
    var questions = '';
    try {
        if (typeof GA_ENABLED != "undefined" && GA_ENABLED == "true") {
            Platform.trackScreen(OBJECT_QUIZ + "-" + this.allQuestions.title);
        }
        else {
            Client.trackScreen(OBJECT_QUIZ + "-" + this.allQuestions.title);
        }
    }
    catch (e) {
    }

    //load all questions
    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
        questions = JSON.parse(Platform.getPreference("ques_for_quiz_" + nid));
    } else if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
        questions = JSON.parse(Platform.getPreference("question_of_the_day_" + nid));
    } else {
        questions = this.quiz.getQuestionsForQuiz(nid, this.allQuestions.changed);
    }

    if (typeof SINGLE_QUESTION != 'undefined' && SINGLE_QUESTION) {
        var last_question = Platform.getPreference('lastViewedQuestionIndex_' + this.allQuestions.nid);
        if (typeof last_question == 'undefined') {
            last_question = "";
        }
        if (typeof last_question != 'undefined' && (last_question == "" || last_question == "1" || last_question == "null")) {
            this.allQuestions['questions'] = questions['question'];
        } else {
            this.allQuestions['questions'] = [];
            this.allQuestions['questions'][Number(last_question) - 1] = questions['question'][0];
        }
    } else if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY) {
        this.allQuestions['questions'] = [];
        this.allQuestions['questions'][0] = questions;
    } else {
        this.allQuestions['questions'] = questions['question'];
    }
    //resume quiz from start
    var resume = this.QuizResume(quiz_result_id);
    if (resume == true) {
        var log = 'Quiz Resumed';
        this.logUserAction(log);
        if (EXEC_MODE == "mobile" && typeof this.allQuestions.airplane_mode != 'undefined' && this.allQuestions.airplane_mode == "1") {
            this.isResume = true;
            var THIS = this;

        } else {
            this.startQuiz();
        }
    } else if (resume == false) {
        var log = 'Quiz Started';
        this.logUserAction(log);
        this.getQuizInstructions();
    }
    if (typeof QUIZ_RESOURCE_PARAMS != "undefined" && QUIZ_RESOURCE_PARAMS != "") {
        if (typeof OBJECT_TEST_COLLECTION != "undefined" && QUIZ_RESOURCE_PARAMS.type == OBJECT_TEST_COLLECTION) {
            var content_type = OBJECT_TEST_COLLECTION;
        } else {
            var content_type = OBJECT_QUIZ;
        }
        var currentTime = Math.floor(getSyncedServerTime() / 1000);
        var videodetails = '{"totalduration":2,"duration":1,"count":"1","timestamp":"","updatedtime":"","createdtime":"","uid":"' + OBJECT_USER_ID + '","resourceid":"' + CONTROLLER.getVariable("activeresourceid") + '","starttime":"' + currentTime + '","endtime":"0","agent":"mobile"' + ',"courseid":"' + QUIZ_RESOURCE_PARAMS.courseid + '","groupid":"' + QUIZ_RESOURCE_PARAMS.groupid + '","categoryid":"' + QUIZ_RESOURCE_PARAMS.catid + '","chapterid":"' + QUIZ_RESOURCE_PARAMS.chapterid + '","session":"' + OBJECT_USER_ID + '-' + currentTime + '","type":"' + content_type + '"}';
        OBJECT_VIDEO_DETAILS = $.parseJSON(videodetails);
        OBJECT_VIDEO_DETAILS.viewmode = CONTROLLER.getVariable("resourcevvhmode");
        Platform.addOrUpdateVideoViewHistory(videodetails);
    }
};


QuizView.prototype.clearOptionsSelected = function (nid) {
    var THIS = this;
    var qsn_type = THIS.allQuestions.questions[THIS.currentQuestionIndex].questiontype;
    if (qsn_type == "multichoice") {
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].choice_multi == 0) {
            $('.form-radio').attr('checked', false);
        } else {
            $('.form-checkbox').attr('checked', false);
        }
        $('.option_selected').removeClass('correctAnswer wrongAnswer');
    } else if (qsn_type == "matching") {
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "matrix") {
            $('.form-checkbox').attr('checked', false);
        } else if (THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "sequence") {
            $.each(THIS.allQuestions.questions[THIS.currentQuestionIndex].matchanswers, function (key, value) {
                var temp = $('#' + value["match_id"]);
                $("#sortable").append(temp);
            });
        }
        else if(THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == "multimatching") {
            $(".match_option_choose dt span,.match_option_choose dt span p").html(t(CHOOSE_ANSWER_TEXT));
            $('.match_options :checkbox').prop('checked', false);
        }else if(THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type == 'inlinefeedback'){
            var inlinefeedback_answers = THIS.allQuestions.questions[THIS.currentQuestionIndex].matchanswers;
            $.each(inlinefeedback_answers, function(key, value) {
                $('.inlinefeedback_field[name="tries['+ value.match_id +']"]').attr('value', "");
            });
        }else{
            $(".match_option_choose dt span,.match_option_choose dt span p").html(t(CHOOSE_ANSWER_TEXT));
            $(".match_option_choose dt a .user_option").attr("id", "");
        }
    } else if (qsn_type == "truefalse") {
        $('.form-radio').attr('checked', false);
        $('.option_selected').removeClass('correctAnswer wrongAnswer');
    } else if (qsn_type == "short_answer") {
        var ansr_eval = THIS.allQuestions.questions[THIS.currentQuestionIndex].correct_answer_evaluation;
        if (ansr_eval == 4 || ansr_eval == 5) {
            $('#edit-tries option:nth(0)').prop("selected", "selected");
        } else if (ansr_eval == 6) {
            $('.short-radio-input').attr('checked', false);
        } else {
            $("#edit-tries").val('');
        }
    } else if (qsn_type == "long_answer") {
        var long_answer_type = THIS.allQuestions.questions[THIS.currentQuestionIndex].long_answer_type;
        if(long_answer_type == "video_long_answer" || long_answer_type == 'audio_long_answer'){
            if($('.uploaded_audio_video').length > 0){
                $('.uploaded_audio_video').attr('src', "");
                $('.uploaded_audio_video').addClass('hidden');
            }
        }else{
            $(".textarea_resizable").html('');
            QUIZ_FILE_UPLOAD_PATH = "";
        }
    }

    if (THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']) {
        THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_correct = 0;
        THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].points_awarded = 0;
        if (THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped == 0) {
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result'].is_skipped = 1;
        }
        if (qsn_type == "multichoice") {
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['answer'] = [];
            THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = [];
        } else if (qsn_type == "short_answer" || qsn_type == "long_answer") {
            if(qsn_type == "long_answer"){
                var long_answer_type = THIS.allQuestions.questions[THIS.currentQuestionIndex]['long_answer_type'];
                if(long_answer_type == 'video_long_answer' || long_answer_type == 'audio_long_answer'){
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['file_path'] = "";
                }else{
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = "";
                }
            }else{
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = "";
            }
        } else if (qsn_type == "matching") {
            var qsn_match_type = THIS.allQuestions.questions[THIS.currentQuestionIndex].match_type;
            if (qsn_match_type == "matching" || qsn_match_type == 'sequence') {
                $.each(THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'], function (key, value) {
                    THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'][key] = "0";
                });
            } else if (qsn_match_type == "matrix" || qsn_match_type == "multimatching" || qsn_match_type == "inlinefeedback") {
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['answer'] = [];
                THIS.allQuestions.questions[THIS.currentQuestionIndex]['question_result']['user_answer'] = [];
            }
        }
    }
    this.logUserAction("Clear Response");
};


QuizView.prototype.removeSolutionWrapper = function () {
    try {
        hPlayer.dispose();
    } catch (ex) {
    }
    $('.title-overlay-wrapper').removeClass("hidden");
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");
    closeDialog();
    var title = CONTROLLER.getVariable("resourcetitle");
    if (typeof QUIZ_RESOURCE_PARAMS.resourceid != 'undefined' && QUIZ_RESOURCE_PARAMS.resourceid != '') {
        CONTROLLER.setVariable('activeresourceid', QUIZ_RESOURCE_PARAMS.resourceid);
    }
    if (SOLUTION_EMBED == true) {
        if (SOLUTION_LANDSCAPE == false) {
            Platform.setDeviceOrientation("portrait");
        } else {
            Platform.setDeviceOrientation("landscape");
        }
        SOLUTION_EMBED = false;
    } else {
        Platform.setDeviceOrientation("portrait");
    }
    clearTimeout(titleTimer);
    clearInterval(FULLSCREENTIMER);
    clearInterval(FULLSCREENRESIZETIMER);
    $("#viewport").attr("content", "width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no");

    if ($(window).width() > $(window).height() && IS_APP_SHELL) {
        if (SOLUTION_LANDSCAPE == true) {
            Platform.setDeviceOrientation("landscape");
        } else {
            Platform.setDeviceOrientation("portrait");
        }
        $(window).on("resize", function () {
            setBodyStyle();
        });
    }
    else {
        setBodyStyle();
    }
    $('.flipQuestion_endquiz').css('display', 'none');
    var height = $(window).height() - 125;
    $("#quizContainer_wrap").css({
        height: height + "px"
    })
    if (typeof title != 'undefined' && title != '') {
        $('.title-overlay-inner').text(title);
    }
    setTimeout(function () {
        if (typeof QUESTION_OF_THE_DAY != 'undefined' && QUESTION_OF_THE_DAY != null && QUESTION_OF_THE_DAY != '') {
            var body_height = $('body').height();
            $('#quizContainer_wrap').css('height', (body_height - 125));
            $("#quiz_wrapper").css("height", "auto");
        }
    }, 100);

    // removing solution embed wrapper on back
    $("#solution_embed_wrapper").remove();
}

QuizView.prototype.showGameInstructions = function () {
    if ($('#gameinstructions_dialog').length == 0) {
        $('body').append('<div id="gameinstructions_dialog"></div>');
    }
    $('#gameinstructions_dialog').dialog({
        resizable: false,
        modal: true,
        height: $(window).height(),
        width: $(window).width(),
        dialogClass: 'dlgfixed',
        position: "center"
    });
    var instructions = {
        'challengeMode': this.allQuestions.quiz_template
    };
    this.renderTemplate("gameinstructionsdialog.tl", instructions, "#gameinstructions_dialog", true);
    $(".dlgfixed").center(false);
    $('.ui-button').css({ 'display': 'none' });
    $(".ui-dialog-titlebar").hide();
    $('html body .ui-dialog').css({
        "height": $(window).height() + "px",
        "width": $(window).width() + "px",
        "display": "block",
        'z-index': '99999999',
        "overflow-x": " auto",
        'background': '#ffffff',
        'position': 'fixed',
        'outline': 'none',
        'overflow-y': 'auto',
        "left": 0,
        "top": 0,
        "margin": 0,
        'padding': 0,
        "border-radius": "0px",
        "border": "none"
    });
    $("#game_dialog_close").unbind("click").bind("click", function (e) {
        closeDialog();
    });
};

QuizView.prototype.allowAttempt = function (msg) {
    var preView = CONTROLLER.getVariable("previousView");
    if (Platform.getNetworkState() == false && (this.gameMode || (typeof preView !== 'undefined' && preView === "TestSeries"))) {
        if (typeof preView !== 'undefined' && preView === "TestSeries" && (typeof this.allQuestions['quiz_template'] == 'undefined' || this.allQuestions['quiz_template'] == '2')) {
            return true;
        }
        if (typeof msg != 'undefined') {
            this.exitFromQuiz(msg)
            return false;
        } else {
            alert(t("INTERNET_ERROR", true));
            Client.closeProgressBar();
            return false;
        }
    }
    return true;
}

QuizView.prototype.exitFromQuiz = function (msg, wrapper) {
    var THIS = this;
    if (typeof QUIZ_TIMER != 'undefined') {
        window.clearInterval(QUIZ_TIMER);
        QUIZ_TIMER = null;
    }
    if (typeof QUIZ_MODULE != 'undefined' && QUIZ_MODULE == 'true') {
        $('.instructions_dialog').css('display', 'none');
        $('#footer_wrapper').remove();
        if (typeof QUIZ_END_BUTTON_TEXT != 'undefined') {
            var end_button = t("QUIZ_END_BUTTON_TEXT");
            var end_button = t("Try Again");
        } else {
            var end_button = t("Logout");
        }
        if (CONTROLLER.getVariable('quizSubmitErrorMessage') == t("QUIZ_ALREADY_STARTED_MSG", true)) {
            var end_button = QUIZ_END_BUTTON_TEXT;
        }
        if (typeof msg != 'undefined' && msg == INTERNET_TO_SUBMIT_QUIZ) {
            var end_button = t("Submit");
        }
        var data = { 'message': msg, 'end_button': end_button };
        if (typeof wrapper == 'undefined') {
            wrapper = "#quiz_wrapper";
        }
        THIS.renderTemplate("quiz_end_message.tl", data, wrapper, true);
        $('.quizinfo_wrapper, #search_wrapper').addClass('hidden');
        $('.quiz_end_logout_button').unbind("click").bind("click", function (e) {
            THIS.onSaveQuizResultComplete(false);
        });
        $('.no_internet_quiz_submit_btn').unbind("click").bind("click", function (e) {
            if (Platform.getNetworkState() == true) {
                var quiz_attempts = THIS.quiz.getQuizAttempts(THIS.allQuestions.nid);
                attempts = quiz_attempts['attempts'].total_attempts;
                quiz_attempt_data = quiz_attempts['attempts'].quiz_attempt_data;
                
                quiz_attempt_data = quiz_attempt_data[quiz_attempt_data.length - 1];
                if (quiz_attempt_data.is_evaluated != 1 && quiz_attempt_data.time_end == 0
                    && typeof quiz_attempt_data.macaddress != 'undefined') {
                    quiz_result_id = quiz_attempt_data.result_id;
                    attemptedOnSameDevice = THIS.isQuizAttemptOnSameDevice(quiz_attempt_data);
                    if (attempts >= 1 && attemptedOnSameDevice == false) {
                        var quiz_result = THIS.quiz.getQuizResultFromResultId(quiz_attempt_data.result_id);
                        if (quiz_result.time_end == 0 && quiz_result.is_evaluated != 1) {
                            alert("Your attempt on this device has been deleted because you initiated an attempt on a different device.");
                            THIS.quiz.deleteQuizAttempt(quiz_attempt_data.result_id);
                        }
                    }
                }
                
                Platform.startService();
                Platform.startBackGroundJOB(true);
                Client.showProgressBar(t("Submitted successfully!"));
                var errormessage = { 'message': QUIZ_END_MESSAGE };
                THIS.renderTemplate("quiz_end_message.tl", errormessage, "#quiz_wrapper", true);
                $('.quiz_end_logout_button').html("Back to results page");
                if(SHOW_USER_ANSWERS != 'undefined' && SHOW_USER_ANSWERS == true){
                    THIS.renderUserAnswers();
                }
                $('.quiz_end_logout_button').unbind("click").bind("click", function (e) {
                    CONTROLLER.onBack();
                });
                setTimeout(() => {
                    Client.closeProgressBar();
                }, 1000);
            } else {
                alert(INTERNET_ERROR);
            }
        });
    } else {
        replaceBody();
        setTimeout(function () {
            Client.closeProgressBar();
            $('#footer_wrapper').empty();
            var check = alert(msg, function () {
                var preView = CONTROLLER.getVariable("previousView");
                if (Platform.getNetworkState() == true && (typeof preView !== 'undefined' && preView === "TestSeries")) {
                    CONTROLLER.loadView("TestSeries");
                } else if (Platform.getNetworkState() == true && (typeof preView !== 'undefined' && preView === "PreAssessment")) {
                    var platformview = new platformView();
                    platformview.loadSigninPage();
                }
                else {
                    if (this.gameMode) {
                        CONTROLLER.onBack();
                    } else {
                        CONTROLLER.loadView("HomePage");
                    }
                }
            });
        }, 200);
        return;
    }
};

/**
 * To extract the extra information that is needed while logging action performed on a question.
 */
QuizView.prototype.getLogExtrasForQuestion = function (currQuestion, currQuestionResult) {
    try {
        var index = this.currentQuestionIndex;
        var is_skipped_state = "";
        if (currQuestionResult) {
            var user_answer = (currQuestionResult['user_answer']) ? currQuestionResult['user_answer'] : "";
            var is_skipped = currQuestionResult.is_skipped;
            if (is_skipped == "2") {
                if (user_answer != "") {
                    is_skipped_state = PORTAL_IS_SKIPPED_STATES['1'];
                } else {
                    is_skipped_state = PORTAL_IS_SKIPPED_STATES['3'];
                }
            } else if (is_skipped == "1" && user_answer == "") {
                is_skipped_state = PORTAL_IS_SKIPPED_STATES['2'];
            } else {
                is_skipped_state = PORTAL_IS_SKIPPED_STATES[is_skipped];
            }
        } else {
            var user_answer = "";
            var is_skipped = "";
            var is_skipped_state = PORTAL_IS_SKIPPED_STATES['4'];
        }
        var logExtras = {
            'index': index + 1,
            'nid': currQuestion.nid,
            'vid': currQuestion.vid ? currQuestion.vid : "",
            'user_answer': user_answer,
            'state': is_skipped_state,
        };
        return logExtras;
    } catch (e) {
        console.log('Error in function - getLogExtrasForQuestion : \n' + e.message);
    }
};

/**
 * To log user actions while attempting a quiz.
 */
QuizView.prototype.logUserAction = function (logMessage, logExtras) {
    try {
        if (typeof TRACK_USER_ACTIONS != 'undefined' && TRACK_USER_ACTIONS) {
            var questionInfo = [];
            var message = logMessage;
            if (!logExtras) {
                questionInfo.push("questionnid-");
                questionInfo.push("questionvid-");
                questionInfo.push("options-");
            } else {
                questionInfo.push("questionnid-" + logExtras.nid);
                questionInfo.push("questionvid-" + logExtras.vid);
                var userAnswer = logExtras.user_answer;
                if (typeof userAnswer == "object") {
                    if (isArray(userAnswer)) {
                        userAnswer = userAnswer.join(" ");
                    } else {
                        var temp = Object.keys(userAnswer).map(function (key) {
                            return userAnswer[key];
                        });
                        userAnswer = temp.join(" ");
                    }
                }
                questionInfo.push("options-" + userAnswer);

                if (logMessage == "Question Saved")
                    message = "Question " + (this.currentQuestionIndex + 1) + " " + logExtras.state;
            }

            var resultId = "no-resultid";
            var remainingTime = "";
            if (typeof this.allQuestions['quiz_result'] != "undefined") {
                if (this.allQuestions['quiz_result']['gen_result_id']) {
                    resultId = this.allQuestions['quiz_result']['gen_result_id'];
                } else if (this.allQuestions['quiz_result']['result_id'] && this.allQuestions['quiz_result']['result_id'].indexOf("temp_") < 0) {
                    resultId = this.allQuestions['quiz_result']['result_id'];
                }
                remainingTime = this.allQuestions['quiz_result'].time_left ? this.allQuestions['quiz_result'].time_left : "";
            }

            var currentTime = new Date(getSyncedServerTime()).toString().replace(/.GMT.*/g, '');

            var log = [];
            log.push("ip-address");
            log.push("source-" + this.getUserAgent());
            log.push("uid-" + OBJECT_USER_ID);
            log.push("resultid-" + resultId);
            log.push("quiznid-" + this.allQuestions.nid);
            log.push("quizvid-" + this.allQuestions.vid);
            log = log.concat(questionInfo);
            log.push("remainingtime-" + remainingTime);
            log.push("dateandtime-" + currentTime);
            log.push("message-" + message);

            this.attemptLog.push(log.join());
        }
    } catch (e) {
        console.log('Error in function - logUserAction \n' + log + '\n' + e.message);
    }
};

QuizView.prototype.getUserAgent = function () {
    try {
        var ua = navigator.userAgent.toLowerCase();
        if (typeof DESKTOP_BUILD != 'undefined' && DESKTOP_BUILD == true) {
            return "windows";
        } else if (ua.indexOf("android") > -1 && typeof EXEC_MODE != 'undefined' && EXEC_MODE == "mobile") {
            return "android";
        } else if (ua.indexOf("android") > -1 && typeof EXEC_MODE != 'undefined' && EXEC_MODE == "online") {
            return "mobile web";
        } else {
            return "offline web";
        }
    } catch (e) {
        console.log("function : QuizView - getUserAgent - " + e.message);
    }
};

QuizView.prototype.getLogsFromIndex = function (index) {
    try {
        var logs = [];
        if (this.attemptLog.length > 0) {
            logs = this.attemptLog.slice(index, this.attemptLog.length);
        }
        var logsString = "";
        for (var i = 0; i < logs.length; i++) {
            logsString += logs[i] + "\r\n";
        }
        return logsString;
    } catch (e) {
        console.log('Error in function - getLogsFromIndex :' + e.message);
    }
};

QuizView.prototype.getAttemptLog = function () {
    if (this.attemptLog.length > 0) {
        var attemptLog = this.getLogsFromIndex(this.lastSentLogIndex);
        this.lastSentLogIndex = this.attemptLog.length;
        return attemptLog;
    }
};

QuizView.prototype.showSolutionForQuestion = function (localeQuestion, isCorrect) {
    try {
        if (typeof BREAK_MODE == "undefined" || BREAK_MODE == "false" || BREAK_MODE == "") {
            if (this.allQuestions) {
                var questionContent = this.allQuestions.questions[this.currentQuestionIndex];
                var updatedLocale = questionContent.updatedLocale;
                if (typeof updatedLocale != 'undefined' && Object.keys(updatedLocale).length > 1) {
                    if (typeof this.activeQuizLanguage != 'undefined' && this.activeQuizLanguage != '') {
                        questionContent = updatedLocale[this.activeQuizLanguage];
                    }
                }
            } else {
                var questionContent = localeQuestion;
            }
            if ($(".solution_content_wrapper").length == 0) {
                this.renderTemplate("show_solution.tl", questionContent, "#questionPanel", false);
            }
            $('.solution_content_wrapper').removeClass('hidden');
            $(".solution_text_wrapper").removeClass("hidden");
            $('.solution_text_wrapper').unbind("click").bind("click", function (e) {
                QUIZ_IN_PROGRESS = true;
                var id = questionContent['solution']['clipping_nid'];
                var type = Platform.getObjectType(id);
                var courseid = ""; var catid = "";
                var prevView = CONTROLLER.getVariable('previousView');
                if (typeof prevView != 'undefined' && prevView == "AllSubjects") {
                    var activecatid = CONTROLLER.getVariable('activecatid');
                    var activecourseid = CONTROLLER.getVariable('activecourseid');
                    if (typeof activecatid != 'undefined' && activecatid != null && activecatid != '') {
                        catid = activecatid;
                    }
                    if (typeof activecourseid != 'undefined' && activecourseid != null && activecourseid != '') {
                        courseid = activecourseid;
                    }
                }
                if (type == 'Document') {
                    type = OBJECT_BOOK;
                } else if (type == 'Video') {
                    type = OBJECT_VIDEO;
                } else if (type == 'HTML5') {
                    showSolutionofTypeHtml(questionContent['solution']['content']);
                    return "";
                }
                var params = {
                    "resourceid": id, "solution_embed": true, "type": type,
                    "activeViewName": CONTROLLER.activeViewName, "activeView": CONTROLLER.activeView, "courseid": courseid,
                    "catid": catid, "groupid": CONTROLLER.getVariable("activegroupid"),
                    "solutionDuration": questionContent['solution']['duration'], 'solution_type': type
                };
                CONTROLLER.setVariable("hide_bookmarks", "true");
                CONTROLLER.loadView("Resource", params);
                e.stopPropagation();
                return false;
            });
            if (questionContent['solution'] && questionContent['solution']['content'] == "") {
                $('.solution_content_wrapper').css({ 'border': 0, 'margin': '12px 0' });
                $('.solution_header').hide();
            }
        }
    } catch (e) {
        console.log("function : showSolutionForQuestion " + e.message);
    }
};

QuizView.prototype.showQuestionFeedback = function (nid) {
    try {
        if (this.gameMode == true) {
            return;
        }
        if (!this.allQuestionFeedback && Platform.getNetworkState() == true) {
            var THIS = this;
            var pView = CONTROLLER.getView("platform");
            if (typeof pView.getVideoFeedback == "undefined") {
                return;
            }
            pView.getVideoFeedback(function (feedback) {
                THIS.allQuestionFeedback = feedback;
                THIS.showQuestionFeedbackIcons(nid, feedback);
            });
        } else {
            this.showQuestionFeedbackIcons(nid, this.allQuestionFeedback);
        }
    } catch (e) {
        console.log("QuizView - showQuestionFeedback: " + e);
    }
};

QuizView.prototype.showQuestionFeedbackIcons = function (nid, feedback) {
    try {
        if (Platform.getNetworkState() == true) {
            if ($("#question_feedback").length == 0) {
                $("#certificate_placeholder").append('<div id="question_feedback"><button class="rate_v_imag" \n\
                id="rate_upvote_image" type="button" status="1" aria-live="polite"></button> \n\
                <button class="rate_v_imag" \n\
                id="rate_downvote_image" type="button" status="0" aria-live="polite"></button></div>');
            }
            var feedbackIndex = findIndexByKeyValue(feedback, "vid", nid);
            var initialStatus = feedbackIndex != null ? feedback[feedbackIndex].status : null;
            if (initialStatus == '1') {
                $('#question_feedback #rate_upvote_image').addClass("rate_active");
            } else if (initialStatus == '0') {
                $('#question_feedback #rate_downvote_image').addClass("rate_active");
            } else {
                $("#question_feedback .rate_v_imag").removeClass("rate_active");
            }
            $("#question_feedback .rate_v_imag").unbind("click").bind("click", function (e) {
                var status = $(this).attr('status')
                if (status == initialStatus) {
                    e.stopPropagation();
                    return false;
                } else {
                    initialStatus = status;
                }
                var pView = CONTROLLER.getView("platform");
                pView.renderVideoFeedbackOptions(status, nid, OBJECT_QUESTION);
                $("#question_feedback .rate_v_imag").removeClass("rate_active");
                if (status == '1') {
                    $(this).addClass("rate_active");
                } else {
                    $(this).addClass("rate_active");
                }
                e.stopPropagation();
                return false;
            });
        }
    } catch (e) {
        console.log("QuizView - showQuestionFeedbackIcons : " + e);
    }
};

QuizView.prototype.addIdleTimeToQuizResult = function (local_result) {
    try {
        var local_result_id = this.getLocalResultId(local_result);
        var questionIdleTimeId = local_result_id + '-' + OBJECT_USER_ID + '-time';
        var questionIdleTime = $.parseJSON(Platform.getObjectProperty("questionIdleTime", questionIdleTimeId, "content"));
        if (questionIdleTime !== null) {
            if (local_result.hasOwnProperty(questionIdleTime.qIndex) == false) {
                local_result[questionIdleTime.qIndex] = {};
                local_result[questionIdleTime.qIndex]['question_result'] = questionIdleTime;
            }
            for (var i in local_result) {
                if (i == questionIdleTime.qIndex) {
                    var currQuestion = local_result[i]['question_result'];
                    if (typeof currQuestion['nid'] != "undefined" && currQuestion['nid'] == questionIdleTime.nid) {
                        currQuestion['answer_timestamp'] = questionIdleTime.answer_timestamp;
                        currQuestion['time_taken'] = parseInt(currQuestion['time_taken']) + parseInt(questionIdleTime.idle_time);
                        local_result['time_left'] = parseInt(local_result['time_left']) - parseInt(questionIdleTime.idle_time);
                        delete currQuestion['idle_time'];
                        delete currQuestion['qIndex'];
                        this.quiz.quizResultSave(local_result_id, local_result.nid, JSON.stringify(local_result));
                        Platform.removeObject("questionIdleTime", questionIdleTimeId);
                    }
                    break;
                }
            }
        }
    } catch (e) {
        console.log("function - addIdleTimeToQuizResult : " + e.message);
    }
};

QuizView.prototype.getLocalResultId = function (local_result) {
    try {
        var temp_result_id = local_result.result_id;
        if (temp_result_id.indexOf("temp_") == -1) {
            if (local_result && typeof local_result['temp_result_id'] != "undefined" && local_result['temp_result_id'].indexOf("temp_") > -1) {
                temp_result_id = local_result['temp_result_id'];
            } else {
                temp_result_id = 'temp_quiz_' + local_result.nid + '_' + local_result['time_start'];
                local_result['temp_result_id'] = temp_result_id;
            }
        }
        return temp_result_id
    } catch (e) {
        console.log("function - getLocalResultId : " + e.message);
    }
}

// quiz favorites
QuizView.prototype.showQuizBookmark = function (nid, params) {
    try {
        if (Platform.getNetworkState() == true) {
            var THIS = this;
            var getQuizBookmarks = "";
            var task_course_id = CONTROLLER.getVariable('task_course_id');
            var task_cat_id = CONTROLLER.getVariable('task_cat_id');
            var quiz_params = {};
            if (typeof params['quizAttrs'] == 'undefined') {
                quiz_params['quiz_nid'] = params['quiz_nid'];
            } else {
                quiz_params['quiz_nid'] = params['quizAttrs'].nid;
            }
            if ((typeof task_course_id != "undefined" && task_course_id != "") &&
                (typeof task_cat_id != "undefined" && task_cat_id != "")) {
                quiz_params['type'] = 'task_quiz';
                quiz_params['catid'] = CONTROLLER.getVariable('task_cat_id');
                quiz_params['courseid'] = CONTROLLER.getVariable('task_course_id');
                getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
            } else if (QUIZ_RESOURCE_PARAMS == "") {
                quiz_params['catid'] = CONTROLLER.getVariable('activecatid');
                quiz_params['courseid'] = CONTROLLER.getVariable('activecourseid');
                getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
            } else {
                getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(QUIZ_RESOURCE_PARAMS);
            }

            if (nid == "") {
                console.log(params);
            }

            if (getQuizBookmarks != []) {
                getQuizBookmarks = checkIsBookmarked(getQuizBookmarks,nid);
                if (getQuizBookmarks) {
                    if ($("#remove_quiz_favorite").length == 0) {
                        $("#quiz_bookmark_wrapper").append('<div id="remove_quiz_favorite" nid="' + nid + '">Added to Favorites</div>');
                    }
                } else {
                    if ($("#add_quiz_favorite").length == 0) {
                        $("#quiz_bookmark_wrapper").append('<div id="add_quiz_favorite" nid="' + nid + '">Add to Favorites</div>');
                    }
                }
            }

            // add quiz favorite
            THIS.addQuizFavorite(params, false);

            //remove quiz favorite
            THIS.removeQuizFavorite(nid, params, false);
        }
    } catch (e) {
        console.log("QuizView - showQuizBookmark : " + e);
    }
};

QuizView.prototype.addQuizFavorite = function (params, removed) {
    try {
        // add quiz_favorite
        $("#add_quiz_favorite").unbind("click").bind("click", function (e) {
            if (Platform.getNetworkState() == true) {
                var params_new = {};
                var getQuizBookmarks = "";
                var task_course_id = CONTROLLER.getVariable('task_course_id');
                var task_cat_id = CONTROLLER.getVariable('task_cat_id'), quiz_title = "";
                var quiz_params = {};
                if (typeof params['quizAttrs'] == 'undefined') {
                    quiz_params['quiz_nid'] = params['quiz_nid'];
                } else {
                    quiz_params['quiz_nid'] = params['quizAttrs'].nid;
                }
                if ((typeof task_course_id != "undefined" && task_course_id != "") &&
                    (typeof task_cat_id != "undefined" && task_cat_id != "")) {
                    quiz_params['type'] = 'task_quiz';
                    quiz_params['catid'] = CONTROLLER.getVariable('task_cat_id');
                    quiz_params['courseid'] = CONTROLLER.getVariable('task_course_id');
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
                } else if (QUIZ_RESOURCE_PARAMS == "") {
                    quiz_params['catid'] = CONTROLLER.getVariable('activecatid');
                    quiz_params['courseid'] = CONTROLLER.getVariable('activecourseid');
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
                } else {
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(QUIZ_RESOURCE_PARAMS);
                }

                if (removed) {
                    var quiz_nid = params.quiz_nid;
                    var question_nid = params.question_nid;
                    var quiz_external_id = params.quiz_external_id;
                    if (QUIZ_RESOURCE_PARAMS != "" && typeof QUIZ_RESOURCE_PARAMS.title != "undefined") {
                        quiz_title = QUIZ_RESOURCE_PARAMS.title;
                    }
                    else {
                        quiz_title = params.quiz_title;
                    }
                } else {
                    var quiz_nid = params.quizAttrs.nid;
                    var question_nid = params.quiz.nid;
                    var quiz_external_id = params.quiz.external_id;
                    if (QUIZ_RESOURCE_PARAMS != "" && typeof QUIZ_RESOURCE_PARAMS.title != "undefined") {
                        quiz_title = QUIZ_RESOURCE_PARAMS.title;
                    }
                    else {
                        quiz_title = params.quizAttrs.title;
                    }
                }

                var course_id = QUIZ_RESOURCE_PARAMS.courseid;
                var category_id = QUIZ_RESOURCE_PARAMS.catid;
                var chapter_id = QUIZ_RESOURCE_PARAMS.chapterid;

                if ((typeof task_course_id != "undefined" && task_course_id != "") &&
                    (typeof task_cat_id != "undefined" && task_cat_id != "")) {
                    category_id = CONTROLLER.getVariable('task_cat_id');
                    course_id = CONTROLLER.getVariable('task_course_id');
                    chapter_id = "";
                } else {
                    if (typeof course_id == "undefined") {
                        course_id = CONTROLLER.getVariable('activecourseid');
                    }
                    if (typeof category_id == "undefined") {
                        category_id = CONTROLLER.getVariable('activecatid');
                    }
                    if (typeof chapter_id == "undefined" && typeof CONTROLLER.getVariable('activechapterid') != 'undefined') {
                        chapter_id = CONTROLLER.getVariable('activechapterid');
                    } else {
                        chapter_id = "";
                    }
                }

                // params
                params_new['uid'] = OBJECT_USER_ID;
                params_new['quiz_nid'] = quiz_nid;
                params_new['question_nid'] = question_nid;
                params_new['quiz_title'] = quiz_title;
                params_new['course_id'] = course_id;
                params_new['category_id'] = category_id;
                params_new['chapter_id'] = chapter_id;
                params_new['quiz_external_id'] = quiz_external_id;
                if (quiz_external_id == null) {
                    params_new['quiz_external_id'] = "";
                }

                //calling save quiz favorite
                save_quiz_bookmark(params_new, function (data, quiz_data) {
                    if (data == 'success') {
                        $('#add_quiz_favorite').remove();
                        if ($("#remove_quiz_favorite").length == 0) {
                            $("#quiz_bookmark_wrapper").append('<div id="remove_quiz_favorite" nid="' + quiz_data.question_nid + '">Added to Favorites</div>');
                        }
                        QuizView.prototype.removeQuizFavorite(quiz_data.question_nid, quiz_data, true);
                    }
                });

            }
            else {
                alert(INTERNET_ERROR);
                return "";
            }
            e.stopPropagation();
            return false;
        });
    } catch (e) {
        console.log('quiz favorite adding error' + e);
    }
};

QuizView.prototype.removeQuizFavorite = function (nid, params, added) {
    try {
        // remove quiz favorite
        $("#remove_quiz_favorite").unbind("click").bind("click", function (e) {
            if (Platform.getNetworkState() == true) {
                var bookmarked_quiz_nid = "";
                var params_delete = {};
                var getQuizBookmarks = "";
                var task_course_id = CONTROLLER.getVariable('task_course_id');
                var task_cat_id = CONTROLLER.getVariable('task_cat_id');
                var quiz_params = {};
                if (typeof params['quizAttrs'] == 'undefined') {
                    quiz_params['quiz_nid'] = params['quiz_nid'];
                } else {
                    quiz_params['quiz_nid'] = params['quizAttrs'].nid;
                }
                if ((typeof task_course_id != "undefined" && task_course_id != "") &&
                    (typeof task_cat_id != "undefined" && task_cat_id != "")) {
                    quiz_params['type'] = 'task_quiz';
                    quiz_params['catid'] = CONTROLLER.getVariable('task_cat_id');
                    quiz_params['courseid'] = CONTROLLER.getVariable('task_course_id');
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
                } else if (QUIZ_RESOURCE_PARAMS == "") {
                    quiz_params['catid'] = CONTROLLER.getVariable('activecatid');
                    quiz_params['courseid'] = CONTROLLER.getVariable('activecourseid');
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(quiz_params);
                } else {
                    getQuizBookmarks = BookmarksService.prototype.getQuizFavorites(QUIZ_RESOURCE_PARAMS);
                }

                for (var i = 0; i < getQuizBookmarks.length; i++) {
                    if (getQuizBookmarks[i].question_nid == nid) {
                        bookmarked_quiz_nid = getQuizBookmarks[i].fav_id;
                        break;
                    }
                }

                if (added) {
                    var quiz_nid = params.quiz_nid;
                    var question_nid = params.question_nid;
                    var quiz_external_id = params.quiz_external_id;
                    var quiz_title = params.quiz_title;
                } else {
                    var quiz_nid = params.quizAttrs.nid;
                    var question_nid = params.quiz.nid;
                    var quiz_external_id = params.quiz.external_id;
                    var quiz_title = params.quizAttrs.title
                }

                var course_id = QUIZ_RESOURCE_PARAMS.courseid;
                var category_id = QUIZ_RESOURCE_PARAMS.catid;
                var chapter_id = QUIZ_RESOURCE_PARAMS.chapterid;

                if (typeof course_id == "undefined") {
                    course_id = CONTROLLER.getVariable('activecourseid');
                }
                if (typeof category_id == "undefined") {
                    category_id = CONTROLLER.getVariable('activecatid');
                }
                if (typeof chapter_id == "undefined" && typeof CONTROLLER.getVariable('activechapterid') != 'undefined') {
                    chapter_id = CONTROLLER.getVariable('activechapterid');
                } else {
                    chapter_id = "";
                }

                params_delete['uid'] = OBJECT_USER_ID;
                params_delete['nid'] = quiz_nid;
                params_delete['quiz_nid'] = quiz_nid;
                params_delete['question_nid'] = question_nid;
                params_delete['quiz_title'] = quiz_title;
                params_delete['course_id'] = course_id;
                params_delete['category_id'] = category_id;
                params_delete['chapter_id'] = chapter_id;
                params_delete['bookmarked_quiz_nid'] = bookmarked_quiz_nid;
                params_delete['quiz_external_id'] = quiz_external_id;

                //calling delete quiz favorite
                delete_quiz_favorite(params_delete, function (data, quiz_data) {
                    if (data == 'success') {
                        $('#remove_quiz_favorite').remove();
                        if ($("#add_quiz_favorite").length == 0) {
                            $("#quiz_bookmark_wrapper").append('<div id="add_quiz_favorite" nid="' + quiz_data.question_nid + '">Add to Favorites</div>');
                        }
                        QuizView.prototype.addQuizFavorite(quiz_data, true);
                    }
                });
            }
            else {
                alert(INTERNET_ERROR);
                return "";
            }
            e.stopPropagation();
            return false;
        });
    } catch (e) {
        console.log('quiz favorite delete error' + e);
    }
};
//this is a setInteval function to check if the user has minimized the app while taking a quiz,
//this method also checks if the user has switched on the internet while taking the quiz and blocks the user
QuizView.prototype.watchForAppResume = function (isAppResume) {
    var isAppResume = true;
    var THIS = this;
    if (typeof clearcheck != "undefined") {
        clearInterval(clearcheck);
    }
    clearcheck = setInterval(repeatcheck, 100, isAppResume);
    function repeatcheck(isAppResume) {
        var appResumed = CONTROLLER.getVariable("onAppResume");
        var device_in_airplane_mode = Platform.isAirplaneModeEnabled();
        var airplane_mode_settings = CONTROLLER.getVariable("airplane_mode_settings");
        if (appResumed === "true" && airplane_mode_settings != "true" && device_in_airplane_mode) {
            var app_minimized = Platform.getPreference("app_minimized");
            if (typeof app_minimized === 'undefined' || app_minimized === '') {
                app_minimized = 1;
                Platform.setPreference("app_minimized", app_minimized);
            } else {
                app_minimized = parseInt(app_minimized) + 1;
                Platform.setPreference("app_minimized", app_minimized);
            }

            if (app_minimized > ALLOW_MINIMIZE_NUM) {
                CONTROLLER.setVariable("MAX_MINIMIZE_REACHED", true);
                THIS.quizEnd();
                Platform.setPreference("app_minimized", "");
                clearInterval(clearcheck);
            } else {
                var allowed_minimize_num = parseInt(ALLOW_MINIMIZE_NUM) + 1;
                minimize_attempts_left = allowed_minimize_num - parseInt(app_minimized);
                alert("Quiz will be submitted, if you switch to another application " + minimize_attempts_left + " more time(s)");
            }
            CONTROLLER.setVariable("onAppResume", "false");

        }
        if (appResumed === "true" && airplane_mode_settings == "true") {
            CONTROLLER.setVariable("airplane_mode_settings", "false");
            CONTROLLER.setVariable("onAppResume", "false");
        }
        if (!device_in_airplane_mode && $('.ui-button-text:contains("Cancel")').parents('.ui-button').length == 0) {
            confirm(t("To take the quiz, device needs to be in Airplane mode. Click on OK to take you to settings for enabling Airplane mode"), function () {
                CONTROLLER.setVariable("airplane_mode_settings", "true");
                Platform.enableAirplaneMode();
            }, "OK", "Cancel");
        }
        else if (appResumed === "true" && device_in_airplane_mode && $('.ui-button-text:contains("Cancel")').parents('.ui-button').length > 0) {
            $('.ui-button-text:contains("Cancel")').parents('.ui-button').click();
        } else if (Platform.getNetworkState("true") == true && device_in_airplane_mode) {
            setTimeout(function () {
                if (Platform.getNetworkState("true") == true && device_in_airplane_mode) {
                    alert("It looks like the Internet has been switched on. Please switch off the internet to proceed further.");
                }
            }, 2000);
        }
    }
}

QuizView.prototype.uploadQuizLog = function () {
    try {
        var resultid = "";
        if (typeof this.allQuestions['quiz_result']['gen_result_id'] != "undefined") {
            resultid = this.allQuestions['quiz_result']['gen_result_id'];
        }
        else {
            resultid = this.allQuestions['quiz_result']['result_id'];
        }
        var attempt_log = this.allQuestions['quiz_result']["attempt_log"];
        var status = this.quiz.uploadQuizLog(this.quiz_nid, resultid, attempt_log, "attempt_log");
        if (status == ERROR_MESSAGE) {
            return false;
        }
        return true;
    } catch (e) {
        console.log("function : QuizView - uploadQuizLog - " + e.message);
    }
};

QuizView.prototype.getActiveLangSections = function () {
    try {
        var lang = CONTROLLER.getVariable('activeQuizLanguage');
        if (typeof lang == 'undefined') {
            lang = '';
        }
        var sections_json = {
            'sections': this.allQuestions.sections
        }
        /*if (typeof this.allQuestions.updatedLocale != 'undefined' && typeof this.allQuestions.updatedLocale.quiz_section != 'undefined') {
            var updatedLocale = this.allQuestions.updatedLocale.quiz_section;
            if (Object.keys(updatedLocale).length > 1) {
                sections_json.localeInfo = this.quiz.getQuestionLocaleInfo(Object.keys(updatedLocale), lang);
                for (var i in updatedLocale) {
                    if (i == lang) {
                        sections_json.sections = updatedLocale[i];
                        break;
                    }
                }
            }
        }*/
        /*sections_json.sections[0] = this.allQuestions.sections[0];
        if (typeof LOCALE_SECTIONS_TEXT_ALL_TITLES[lang] != "undefined") {
            sections_json.sections[0].title = LOCALE_SECTIONS_TEXT_ALL_TITLES[lang];
        }*/
        return sections_json.sections;
    } catch (e) {
        console.log("QuizView : getActiveLangSections - " + e);
    }
};

QuizView.prototype.getCurrentQuestionIndex = function (questionId) {
    try {
        var questionIndex = 0;
        if (this.enableSectionalNavigation == true) {
            questionIndex = findIndexByKeyValue(this.allQuestions.sections[0].questionsdata, 'nid', questionId);
        }
        else if (this.enableSubjectsNavigation == true) {
            questionIndex = findIndexByKeyValue(this.allQuestions.subjectsmeta[0].questionsdata, 'nid', questionId);
        } else {
            questionIndex = findIndexByKeyValue(this.allQuestions.questions, 'nid', questionId);
        }
        return questionIndex;
    } catch (e) {
        console.log("function : QuizView - getCurrentQuestionIndex - " + e);
    }
};

//attach image to the "question_text_img" Div
QuizView.prototype.attachImage = function (path) {
    try {
        if (IMAGEPATH) {
            $('.file-attached-wrapper').addClass('hidden');
        }
        this.uploadQuizImage();
    }
    catch (e) {
        console.log(e.message("function:attachImage"));
    }
}

QuizView.prototype.uploadQuizImage = function () {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var THIS = this;
        var path = "";
        var imagefileAvailable = "";

        if (IMAGEPATH != "") {
            path = IMAGEPATH;
        }
        else if (GALLERYPATH != "") {
            path = GALLERYPATH;
        }
        if (path == IMAGEPATH) {
            path = path.split(Platform.getLocalServerPath());
            imagefileAvailable = Platform.isFilePresentInDocRoot(path[1]);
        }
        else if (path == GALLERYPATH) {
            imagefileAvailable = "true";
        }

        if (this.checkSupportedFiles(path)) {
            if (Platform.getNetworkState() == true) {
                if (path != "" && (imagefileAvailable == "true" || imagefileAvailable == true)) {
                    this.uploadFileifImagePath(path);
                }
            } else {
                Client.closeProgressBar();
                alert(INTERNET_ERROR);
            }
        } else {
            Client.closeProgressBar();
            alert(FILE_NOT_SUPPORTED);
        }
    } catch (e) {
        console.log(e.message("function:uploadQuizImage"));
    }
}

//gets the uploaded server image path
QuizView.prototype.uploadFileifImagePath = function (imagepath) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var THIS = this;
        var path;
        var contentutils = CONTROLLER.getService("ContentUtils");
        if (IMAGEPATH) {
            path = Platform.getDocumentRoot() + "/sites/default/files/askexpertimages/" + IMAGENAME;
        }
        else {
            path = GALLERYPATH;
        }
        var data = THIS.getDatatoUploadFile(path);
        // calls the android api to get the uploaded server image path
        var serverimgpath = contentutils.uploadFiletoServer(data);
        Client.closeProgressBar();
    }
    catch (e) {
        Client.closeProgressBar();
        console.log(e.message("function:uploadFileifImagePath"));
    }
}

// upload file to the server
QuizView.prototype.getDatatoUploadFile = function (path) {
    try {
        if (Platform.getNetworkState() == false) {
            alert("Internet is required.");
            Client.closeProgressBar();
            return;
        }
        else {
            var imgpath = 'file:' + path;
            var url = OBJECT_SERVER_DOMAIN + '/api/forumupload?uid=' + OBJECT_USER_ID + '&type=forum';
            var qsn_data = "{";
            var temp = "";
            temp = temp + ' "message" : "Uploading file...",';
            temp = temp + ' "data" : "' + imgpath + '",';
            temp = temp + ' "url" : "' + url + '",';
            temp = temp + ' "method" : "post",';
            temp = temp + ' "showdialog" : "true",';
            temp = temp + ' "callback" : "getserverImagePath"';
            qsn_data = qsn_data + temp + "}";
            return qsn_data;
        }
    }
    catch (e) {
        console.log(e.message("function:getDatatoUploadFile"));
    }
}

QuizView.prototype.saveQuizImageFile = function (path) {
    try {
        QUIZ_FILE_UPLOAD_PATH = path;
        var link_path = QUIZ_FILE_UPLOAD_PATH.replace(/['"]+/g, '');
        if (path.includes('jpg') || path.includes('png') || path.includes('jpeg')) {
            $('#edit-tries').append('<img src="' + QUIZ_FILE_UPLOAD_PATH + '"/><br/><br/><br/>');
        } else {
            if ($('#edit-tries').html() != "") {
                $('#edit-tries').append('<br/><a class="quiz_file_upload_link" href="' + QUIZ_FILE_UPLOAD_PATH + '">' + link_path + '</a><br/>');
            } else {
                $('#edit-tries').append('<a class="quiz_file_upload_link" href="' + QUIZ_FILE_UPLOAD_PATH + '">' + link_path + '</a><br/>');
            }
        }

        $('.quiz_file_upload_link').unbind().click(function () {
            var uploaded_file_link = this.href;
            if (typeof uploaded_file_link != 'undefined' && uploaded_file_link) {
                Platform.openBrowser(uploaded_file_link);
            }
        });
    }
    catch (e) {
        console.log(e.message("function:saveQuizImageFile"));
    }
}

QuizView.prototype.checkSupportedFiles = function (path) {
    try {
        //audio_files - /\.(mp3|m4a|wav|wma|ogg|amr|m4b|m4p|aac)$/;
        //video_files - /\.(avi|flv|wmv|mp4|mov)$/;
        //office_files - /\.(ppt|pdf|txt|doc|xls|csv|zip|JSON)$/;
        //image_files - /\.(jpg|jpeg|png)$/;
        var file_path = path.toLowerCase();
        var supported_files = /\.(mp3|m4a|wav|wma|ogg|amr|m4b|m4p|aac|avi|flv|wmv|mp4|mov|ppt|pdf|txt|doc|xls|xlsx|docx|docm|pptx|pptm|xlsm|csv|zip|json|jpg|jpeg|png)$/;
        if (file_path.match(supported_files)) {
            var last_words = file_path.substring(file_path.length - 6); // checking .. in file extension
            if (last_words.includes('..')) {
                return false;
            }
            return true;
        }
        return false;

    } catch (e) {
        console.log(e.message("function:checkSupportedFiles"));
    }
}

/*
    Download questions data asynchronously and append data
    to the existing quiz data with already available quiz result
*/
QuizView.prototype.downloadQuestionsDataAsync = function (quiz, ques_navigation) {
    try {
        var nid = quiz.nid;
        var filepath = "/video/" + nid + "/" + nid + '_' + quiz.changed + ".quiz";
        var contentUtils = CONTROLLER.getService("ContentUtils");
        var isFileAvailable = contentUtils.isFileAvailable(nid, quiz.changed, "", "quiz");
        if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new")) {
            if (typeof ques_navigation != 'undefined' && ques_navigation) {
                var questions = Platform.getDataFromFileSystem(filepath);
                var questions_data = {};
                if (typeof questions != 'undefined' && questions != '' && typeof ques_navigation != 'undefined' && ques_navigation) {
                    var IsString = isJSONString(questions);
                    if (IsString) {
                        questions = JSON.parse(questions);
                    }else{
                        questions = JSON.parse(JSON.parse(questions));
                    }
                    questions = questions.questions ? questions.questions : questions;
                    questions = JSON.stringify(questions);
                    var filepath = Platform.getLocalServerPath() +"/video/"+nid+"/" ;
                    questions = replaceAll(STATIC_SERVER+"/", filepath , questions);
                    var staticPath = STATIC_SERVER.substr(STATIC_SERVER.indexOf('//'));
                    var localFilePath = filepath.substr(filepath.indexOf('//'));
                    questions = replaceAll(staticPath+"/", localFilePath, questions);
                    questions = replaceAll(OBJECT_SERVER_DOMAIN.substr(OBJECT_SERVER_DOMAIN.indexOf('//')) +  '/' , filepath, questions )
                    questions = replaceAll("//" + OBJECT_SERVER_DOMAIN  + "/modules/mtp_quiz/image/play_video.png","/platform/images/play_video.90d2066a.png",questions);
                    questions = replaceAll("//" + OBJECT_SERVER_DOMAIN +"/modules/quiz/images/no_audio_banner.jpg","/platform/images/no_audio_banner.233fa6fd.png",questions);
                    if(typeof OLD_STATIC_SERVER != "undefined"){
                        if (OLD_STATIC_SERVER instanceof Array) {
                            for (var i = 0; i < OLD_STATIC_SERVER.length; i++) {
                                var oldStaticPath = OLD_STATIC_SERVER[i].substr(OLD_STATIC_SERVER[i].indexOf('//'));
                                questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                            }
                        }
                        else{
                            var oldStaticPath = OLD_STATIC_SERVER.substr(OLD_STATIC_SERVER.indexOf('//'));
                            questions = replaceAll(oldStaticPath+"/", localFilePath, questions);
                        }
                    }
                    questions_data['question'] = JSON.parse(questions);
                    return questions_data;
                }
            } else {
                return "";
            }
        } else if ((isFileAvailable.filestatus == "Not Available" && isFileAvailable.version == "")
            || (isFileAvailable.filestatus == "Available" && isFileAvailable.version == "old")) {
            var downloadurl = OBJECT_SERVER_DOMAIN + '/api/download' + OBJECT_QUIZ + '?quizid=' + nid + "&auth=" + AUTH_ENABLED + "&additionaldetails=true";
            var filepath = "video/" + nid + "/" + nid + '_' + quiz.changed + ".zip";
            var paramsJson = {'objectid': nid, 'objecttype': 'resource',
                'serverurl': downloadurl, 'localurl': filepath, 'message': '',
                'sync': FILE_DOWNLOAD_ASYNC, 'expiryduration': "",
                'onComplete': "", 'youtubeid': quiz.changed, 'format': "", 'type': 'content'};
            Platform.downloadFile(JSON.stringify(paramsJson));
        }
        return "";
    } catch (e) {
        console.log('downloadQuestionsDataAsync' + e);
    }
}

/* 
    Checking if All Questions data available for generating reports
    if questions data is not available, downloaded synchronously and 
    generate the reports

*/
QuizView.prototype.checkForQuestionsAvailabiltyAndDownload = function (quiz, show_loader) {
    try {
        if (typeof SYNCED_QUESTION_DATA != 'undefined' && SYNCED_QUESTION_DATA) {
            return quiz;
        } else {
            if (typeof show_loader != 'undefined' && show_loader) {
                Client.showProgressBar(t("Loading") + "...");
            }
            var quiz_data = quiz; var questions = "";
            var filepath = "/video/" + quiz_data.nid + "/" + quiz_data.nid + '_' + quiz_data.changed + ".quiz";
            var contentUtils = CONTROLLER.getService("ContentUtils");
            var isFileAvailable = contentUtils.isFileAvailable(quiz_data.nid, quiz_data.changed, "", "quiz");
            // checking if quiz file available offline and returning data
            // else making online call to get questions
            if ((isFileAvailable.filestatus == "Available") && (isFileAvailable.version == "new")) {
                questions = Platform.getDataFromFileSystem(filepath);
                if (typeof questions != 'undefined' && questions != '') {
                    questions = JSON.parse(questions);
                    if(typeof questions != 'undefined' && typeof questions == 'string'){
                        questions = JSON.parse(questions);
                    }
                    if(typeof questions != 'undefined' && typeof questions.questions != 'undefined'){
                        questions = questions.questions;
                    }
                } else {
                    if (typeof quiz_data != 'undefined' && quiz_data.nid != 'undefined') {
                        questions = this.quiz.getAllQuizQuestions(quiz_data.nid);
                        questions = JSON.parse(questions);
                    }
                }
            } else {
                if (typeof quiz_data != 'undefined' && quiz_data.nid != 'undefined') {
                    questions = this.quiz.getAllQuizQuestions(quiz_data.nid);
                    questions = JSON.parse(questions);
                }
            }
            if (typeof IS_IOS != 'undefined' && IS_IOS) {
                questions = fixURLsWithoutProtocol(questions);
            }
            if (questions != 'undefined' && questions.length > 0 && questions.length == Number(quiz_data.question_count)) {
                for (var i = 0; i < questions.length; i++) {
                    if (typeof quiz_data.quiz_result[i] != 'undefined' && quiz_data.quiz_result[i]['question_result'] != 'undefined') {
                        if (typeof questions[i]['question_result'] == 'undefined') {
                            questions[i]['question_result'] = quiz_data.quiz_result[i]['question_result'];
                        }
                    }
                }
                quiz_data['questions'] = questions;
            }
            SYNCED_QUESTION_DATA = true;
            return quiz_data;
        }
    } catch (e) {
        console.log('checkForQuestionsAvailabiltyAndDownload' + e);
    }
}

/* Swap downloaded questions data along with available quiz result
   with exisiting questions data
*/
QuizView.prototype.swapDownloadedQuestionsData = function (downloadedQuestionsDataAsync) {
    try {
        var THIS = this;
        for (var i = 0; i < downloadedQuestionsDataAsync.question.length; i++) {
            if (typeof this.allQuestions.quiz_result[i] != 'undefined' && this.allQuestions.quiz_result[i]['question_result'] != 'undefined') {
                if (typeof downloadedQuestionsDataAsync['question'][i]['question_result'] == 'undefined') {
                    downloadedQuestionsDataAsync['question'][i]['question_result'] = this.allQuestions.quiz_result[i]['question_result'];
                }
            }
        }
        this.allQuestions['questions'] = downloadedQuestionsDataAsync['question'];
        Client.closeProgressBar();
        SYNCED_QUESTION_DATA = true;
    } catch (e) {
        console.log('swapDownloadedQuestionsData ' + e);
    }
}

/* Check for current question and 
   swap the questions data if available locally  
*/
QuizView.prototype.checkForCurrentQuestionAndSwapQuestionsData = function () {
    try {
        var THIS = this;
        var downloadedQuestionsDataAsync = THIS.downloadQuestionsDataAsync(this.allQuestions, true);
        if (typeof downloadedQuestionsDataAsync != 'undefined' && downloadedQuestionsDataAsync != ""
            && downloadedQuestionsDataAsync['question'] != 'undefined'
            && downloadedQuestionsDataAsync['question'].length > 0
            && downloadedQuestionsDataAsync['question'].length == Number(this.allQuestions.question_count)) {
            THIS.swapDownloadedQuestionsData(downloadedQuestionsDataAsync);
        } else {
            THIS.renderSingleQuestion();
        }
    } catch (e) {
        console.log('checkForCurrentQuestionAndSwapQuestionsData ' + e);
    }
}

/* Render single question from online
   and add result if already available for the question 
*/
QuizView.prototype.renderSingleQuestion = function () {
    try {
        var THIS = this;
        if (typeof this.allQuestions.questions[this.currentQuestionIndex] == 'undefined') {
            var question = THIS.getSingleQuestionOnline(this.allQuestions.nid, this.currentQuestionIndex + 1);
            this.allQuestions.questions[this.currentQuestionIndex] = question['question'][0];
            if (typeof this.allQuestions.quiz_result[this.currentQuestionIndex] != 'undefined' &&
                this.allQuestions.quiz_result[this.currentQuestionIndex] != '' &&
                this.allQuestions.quiz_result[this.currentQuestionIndex] != null) {
                this.allQuestions.questions[this.currentQuestionIndex]['question_result'] = this.allQuestions.quiz_result[this.currentQuestionIndex]['question_result'];
            }
        }
    } catch (e) {
        console.log('renderSingleQuestion ' + e);
    }
}

/* Adding question numbers for legend
   based on question count available at the quiz level
   before quiz is downloaded and available local 
*/
QuizView.prototype.addLegendQuestionNumbers = function () {
    try {
        var question_numbers = [];
        for (var i = 1; i <= Number(this.allQuestions.question_count); i++) {
            if (typeof this.allQuestions.quiz_result[i - 1] == 'undefined' || this.allQuestions.quiz_result[i - 1] == null
                || this.allQuestions.quiz_result[i - 1] == "") {
                question_numbers.push({ 'index': i, 'question_result': { 'is_correct': 0, 'is_skipped': 1 } });
            } else {
                question_numbers.push({ 'index': i, 'question_result': this.allQuestions.quiz_result[i - 1].question_result });
            }
        }
        return question_numbers;
    } catch (e) {
        console.log('addLegendQuestionNumbers ' + e);
    }
}

/* Selected question from the legened
   is added to quiz question object
*/
QuizView.prototype.addSelectedQuestionsFromLegenedToQuiz = function (nid, question_number_index) {
    try {
        var THIS = this;
        if (typeof THIS.allQuestions.questions[question_number_index - 1] == 'undefined'
            || THIS.allQuestions.questions[question_number_index - 1] == ""
            || THIS.allQuestions.questions[question_number_index - 1] == null) {
            // getting single question using question number 
            var question = THIS.getSingleQuestionOnline(nid, question_number_index);
            THIS.allQuestions.questions[question_number_index - 1] = question['question'][0];
            if (typeof THIS.allQuestions.quiz_result[question_number_index - 1] != 'undefined'
                && THIS.allQuestions.quiz_result[question_number_index - 1] != ''
                && THIS.allQuestions.quiz_result[question_number_index - 1] != null) {
                THIS.allQuestions.questions[question_number_index - 1]['question_result'] = THIS.allQuestions.quiz_result[question_number_index - 1]['question_result'];
            }
        }
    } catch (e) {
        console.log('addSelectedQuestionsFromLegenedToQuiz ' + e);
    }
}

/* Getting single question from online 
   based on selected question number
*/
QuizView.prototype.getSingleQuestionOnline = function (nid, question_number) {
    try {
        var THIS = this;
        var question = THIS.quiz.getQuizQuestionFromNumber(nid, question_number);
        question = $.parseJSON(question);
        var status = question['status'];
        if (status == 200) {
            var data = '{"question":' + question['data'].trim() + '}';
            question = THIS.quiz.updateQuestionLocale($.parseJSON(data));
        } else {
            question = "";
        }
        if (typeof IS_IOS != 'undefined' && IS_IOS) {
            question = fixURLsWithoutProtocol(question);
        }
        return question;
    } catch (e) {
        console.log('getSingleQuestionOnline ' + e);
    }
}


QuizView.prototype.getAttemptLogsFromDatabase = function (quiz_nid, logid, result_id) {
    try{
        var attempts_logs = Platform.getRelatedObjects('quiz_result', result_id, 'quiz_log');
        attempts_logs = JSON.parse(attempts_logs);
        var attempt_log_data = "";
        if(typeof attempts_logs != 'undefined' && typeof attempts_logs['quiz_log'] != 'undefined' && attempts_logs['quiz_log'].length > 0){
            var quiz_log_data = attempts_logs['quiz_log'][0];
            attempt_log_data = quiz_log_data['attempt_log'];
        }
        return attempt_log_data;
    }catch(e){
        console.log('getAttemptLogsFromDatabase ' + e);
    }
}

QuizView.prototype.saveAttemptLogsToDatabase = function (quiz_data, attempt_logs, resultid) {
    try{
        var THIS = this;
        var result_id = "";
        var logid = "";
        var quiz_nid = quiz_data.nid;
        if(typeof resultid != 'undefined'){
            result_id = resultid;
        }else if(typeof quiz_data['quiz_result']['gen_result_id'] != 'undefined'){
            result_id = quiz_data['quiz_result']['gen_result_id'];
        }else if(typeof quiz_data['quiz_result']['result_id'] != 'undefined'){
            result_id = quiz_data['quiz_result']['result_id'];
        }
        if(result_id.indexOf('temp_') > -1){
            logid = "temp_quiz_log_" + OBJECT_USER_ID + "_" + quiz_nid + "_" + result_id;
        }else{
            logid = result_id;
        }
        var attempt_log = THIS.getAttemptLogsFromDatabase(quiz_nid, logid, result_id);
        attempt_log += attempt_logs;
        var logData = {
            "logid": logid,
            "quizid": quiz_nid,
            "userid": OBJECT_USER_ID,
            "resultid": result_id,
            "attempt_log": attempt_log
        }
        logData = JSON.stringify(logData);
        Platform.saveObject("quiz_log", logid, "content", logData);
        Platform.addRelation("quiz_result", result_id, 'quiz_log', logid);
    }catch(e){
        console.log('saveAttemptLogsToDatabase ' + e);
    }
}

QuizView.prototype.applyLeadschoolV2Styles = function () {
    try{
        $('.res-prev-icon').addClass('hidden');
        $('.res-next-icon').addClass('hidden');
        $('.title-overlay').addClass('leadschoolv2_title_overlay');
        $('#quizContainer_wrap').css({'background-color':'#ecf0f4'});
        $('#flipQuestion_clearresponse').addClass('leadschoolv2_flipQuestion_clearresponse');
        $('#question_wrapper').addClass('leadschoolv2_question_wrapper');
        $('.res-back-icon').addClass('leadschoolv2_res_back_icon');
        $('#showanswer_main_wrapper').css({'width':'calc(100% - 90px)'});
        $('.title-overlay-text').css({'color':'#083360'});
        $('#flipQuestion').addClass('leadschoolv2_flipQuestion');
        $('#flipQuestionback').addClass('leadschoolv2_flipQuestionback');
        $('#sidemenu_icon').addClass('leadschoolv2_sidemenu_icon');
        $('#flipQuestionback').removeClass('leadschoolv2_flipQuestionback_light');
        if(this.currentQuestionIndex == 0){
            $('#flipQuestionback').addClass('leadschoolv2_flipQuestionback_light');
        }
        if (this.mode != 'none') {
            var disablebackbuttonindex = CONTROLLER.getVariable("disablebackbuttonindex")
            if (typeof disablebackbuttonindex != "undefined" && disablebackbuttonindex != "" && disablebackbuttonindex == this.modeCount) {
                $('#flipQuestionback').addClass('leadschoolv2_flipQuestionback_light');
            }
        }
        window.parent.postMessage("iframe_loaded", "*");
    }catch(e){
        console.log("function : QuizView - applyLeadschoolV2Styles : " + e.message);
    }
}

QuizView.prototype.passQuestionContextToChatbot = function(){
    try{
        var chatbot = window.dash;
        var contextData = {};
        var question_data = this.allQuestions.questions[this.currentQuestionIndex];
        var question_title = cleanUpHTMLTags(question_data.body);
        if(question_data.questiontype == "multichoice"){
            var alternatives_data = "";
            var correct_answer = "";
            for(var i = 0; i < question_data.alternatives.length; i++){
                alternatives_data += cleanUpHTMLTags(question_data.alternatives[i].answer) + "\n";
                if(question_data.alternatives[i].score_if_chosen == '1'){
                    correct_answer = cleanUpHTMLTags(question_data.alternatives[i].answer);
                }
            }
        }
        var contextData = {};
        contextData['context'] = { 
            "contextId" : question_data.nid,
            "contextType" : "answerQuestion",
            "contextData" : question_title + "\n" + alternatives_data,
            "contextEvent" : "onQuestionSeen",
            "contextSubType" : question_data.questiontype,
            "contextCorrectAnswer" : correct_answer,
        }
        chatbot.launchWithContext(contextData);
    }catch(e){
        console.log("QuizView >> openChatbotWithContext " + e.message);
    }
}
ChallengeView.prototype = new platformView();
ChallengeView.prototype.constructor = ChallengeView.constructor;

function ChallengeView() {
    this.challengeService = CONTROLLER.getService("Challenge");
    this.quiz = CONTROLLER.getService("Quiz");
}

ChallengeView.prototype.renderHeader = function () {
    var menuHideArray = "";
    var menuShowArray = "";
    this.renderTemplate("header.tl", "", "#header_wrapper", true);
    $("#right-menu-upgrade-user").hide();
    $("#search_wrapper").hide();
    $("#menu").hide();
    $(".title_text").css("width", '72%');
    $("#notif_img,#notifications").hide();
    $("#right-menu-icon").hide();
    menuHideArray = ["#menu"];
    menuShowArray = ["#back"];
    if(IS_TEAMS_APP){
        menuHideArray.push('#back');
        menuShowArray.pop('#back');
    }
    hideMenuIcons(menuHideArray);
    showMenuIcons(menuShowArray);
    $('#header_wrapper').css('width', $(window).width());
};

ChallengeView.prototype.setCustomStyles = function () {
    $('#header .title_text').html(this.getTitle());
    $('#footer_wrapper').empty();
    $('#footer_wrapper, #search_wrapper, #right-menu-icon, #sync_wrapper').addClass("hidden");
    var height = $(window).height();
    var contentwrapper_height = height - 56;
    $("#content_wrapper").css({
        'height': contentwrapper_height + 'px',
        "display": "block",
        'background-color': '#fff'
    });
    $("#wrapper,body").css({
        'width': '100%',
        "background": "#fff",
        "height": $(window).height() + "px",
        "overflow": "auto"
    });
    $("#header_wrapper").css({
        "-webkit-box-shadow": "0px 0px 8px 2px #888",
        "-moz-box-shadow": "0px 0px 8px 2px #888",
        "box-shadow": "0px 0px 8px 2px #888",
        "position": "relative"
    });
    $('body,#wrapper,#content_wrapper').animate({
        scrollTop: 0
    }, 1);
    this.setHeaderStyle();
    if (ENABLE_NEW_UI) {
        $("#header_wrapper").css({"display" : "block"});
        $('#footer_menu').addClass('hidden');
        $("#wrapper,body,#content_wrapper").css({ "background": "#F4F5F9" });
    }
};

ChallengeView.prototype.getTitle = function () {
    return "Challenge Details";
};

ChallengeView.prototype.setHeaderStyle = function () {
    $("#content_wrapper").css({
        'height': $(window).height() + 'px',
    });
    $('#header_wrapper').addClass('game_header');
};

ChallengeView.prototype.isGameActive = function (quiz_time) {
    if (moment(quiz_time).isSame(moment(getSyncedServerTime()), 'day')) {
        return true;
    }
    return false;
};

ChallengeView.prototype.isGameUpcoming = function (quiz_time) {
    var today = new Date(getSyncedServerTime());
    today.setHours(23, 59, 59, 999);
    return quiz_time.valueOf() > today.valueOf();
};

ChallengeView.prototype.isGameCompleted = function (quiz_time) {
    var today = new Date(getSyncedServerTime());
    return quiz_time.valueOf() < today.valueOf();
};

ChallengeView.prototype.isChallengeActive = function (challenge) {
    var today = Math.round(getSyncedServerTime() / 1000);
    var startTime = challenge.quiz_open && (parseInt(challenge.quiz_open) + GAME_START_BUFFER_TIME);
    var endTime = challenge.quiz_close && parseInt(challenge.quiz_close);
    if (challenge.type == CHALLENGE_MODES["flexi"]) {
        if (endTime > today) {
            return true;
        }
    } else {
        if (startTime > today) {
            return true;
        }
    }
    return false;
};

ChallengeView.prototype.scrollToDivAndHightLight = function (element, callback) {
    if ($(element).length) {
        var position = $(element).position().top - 50;
        $('#wrapper,#content_wrapper').animate({
            scrollTop: position
        }, 1);
        $(element).animate({ opacity: "0.7" }, 500);
        setTimeout(function () {
            $(element).animate({ opacity: "1" }, 0);
            $(element).animate({ opacity: "0.7" }, 500);
            setTimeout(function () {
                $(element).animate({ opacity: "1" }, 0);
                if (callback) callback();
            }, 500);
        }, 500);
    }
};

ChallengeView.prototype.challengeInvitation = function (quizId, challengeData) {
    var firstName = challengeData['first_name'] ? challengeData['first_name'] : "";
    var lastName = challengeData['last_name'] ? challengeData['last_name'] : "";
    var quizTitle = challengeData['title'];
    var userName = firstName + " " + lastName;
    var challengeLink = PORTAL_SERVER_DOMAIN + "/challenge_invite?id=" + OBJECT_USER_ID + "&challengeId=" + quizId;
    var message = userName + " has invited you for a challenge - " + quizTitle + ".\n\nClick the link below to accept the challenge \n" + challengeLink;
    Platform.share("Challenge Invitation", message);
};

ChallengeView.prototype.updateChallengeInvitation = function (challengeId, invitedUid, quizId, state, callback){
    if (Platform.getNetworkState() == true) {
        Client.showProgressBar(t("Loading") + "...");
        this.challengeService.updateUserChallenge(challengeId, invitedUid, quizId, state, function (response) {
            if (response == ERROR_MESSAGE) {
                alert("Unable to update status. Please try again later");
                if (callback) {
                    callback(false);
                }
            } else {
                Platform.showMessage("Challenge " + state + " successfully!");
                if (callback) {
                    callback(true);
                }
            }
            Client.closeProgressBar();
        });
    } else {
        alert(t("INTERNET_ERROR",true));
        return false;
    }
};

ChallengeView.prototype.bindChallengeTimer = function () {
    this.lastUpdatedTime = 0;
    this.clearChallengeTimer();
    CONTROLLER.setVariable("onAppResume", "false");
    var game = this.game;
    var params = this.param;
    this.remainingTime = 0;
    this.flexiGameStartTime = false;
    this.setRemainingTime();
    if (this.remainingTime < 0) {
        $("#game_start_timer").html('<div id="btn_view_winners" class="game_button">VIEW WINNERS</div>');
        $('#btn_view_winners').unbind('click').bind('click', function () {
            CONTROLLER.setVariable("previousView", "ChallengeDetails");
            CONTROLLER.loadView("ChallengeResult", params);
            return false;
        });
        Client.closeProgressBar();
        return;
    }
    if (this.challengeTimerAction(game, this.remainingTime)) {
        var startTime = parseInt(game.quiz_open) * 1000;
        var syncedServerTime = getSyncedServerTime();
        this.lastUpdatedTime = syncedServerTime;
        var today = new Date(syncedServerTime);
        today.setHours(23, 59, 59, 999);
        if(startTime > today.setHours(23, 59, 59, 999).valueOf()) {
            var timeToDisplay = moment(startTime).calendar(getSyncedServerTime(),{
                sameElse: 'ddd DD-MM-YYYY hh:mm A'
            });
            $("#game_start_timer").html('<span class="timer_date"><b>' + timeToDisplay + '</b></span>');
        } else {
            var THIS = this;
            CHALLENGE_TIMER = setInterval(function () {
                if (THIS.isServerTimeInSync() == false) {
                    return;
                }
                if (CONTROLLER.getVariable("onAppResume") == "true") {
                    THIS.setRemainingTime();
                    CONTROLLER.setVariable("onAppResume", "false");
                }
                THIS.remainingTime--;
                THIS.challengeTimerAction(game, THIS.remainingTime);
            }, 1000);
        }
    }

};

ChallengeView.prototype.setRemainingTime = function () {
    this.remainingTime = this.computeRemainingTime();
};

ChallengeView.prototype.computeRemainingTime = function () {
    var startTime = this.getGameStartTime();
    var syncedServerTime = getSyncedServerTime();
    var remainingTime = startTime - syncedServerTime;
    remainingTime = parseInt(Math.round(remainingTime / 1000)) + GAME_START_BUFFER_TIME;
    return remainingTime;
};

ChallengeView.prototype.challengeTimerAction = function(game, remainingTime) {
    if (remainingTime <= 60 && game.state == "invited") {
        this.clearChallengeTimer();
        closeDialog();
        alert("You have not accepted the challenge yet. Please accept the challenge to participate.");
        CONTROLLER.loadView('ChallengeTab');
        return false;
    }
    if (remainingTime <= 14) {
        this.clearChallengeTimer();
        closeDialog();
        Client.showProgressBar(t("Loading") + " Challenge...");
        this.loadGameView(game);
        return false;
    } else {
        var timeRemain = this.pad(Math.floor((remainingTime / 3600))) + " : " + this.pad(Math.floor((remainingTime % 3600) / 60)) + " : " + this.pad(Math.floor((remainingTime % 3600) % 60));
        $('#quiz_game_start_timer').html('<span class="timer_span"><b>' + timeRemain + '</b></span>');
        return true;
    }
};

ChallengeView.prototype.clearChallengeTimer = function () {
    if (typeof CHALLENGE_TIMER != 'undefined' && CHALLENGE_TIMER) {
        clearInterval(CHALLENGE_TIMER);
        CHALLENGE_TIMER = null;
    } else {
        CHALLENGE_TIMER = null;
    }
};

ChallengeView.prototype.loadGameView = function (game) {
    var gameId = game.nid;
    var gameType = game.type;
    $("#content_wrapper").empty();
    this.beginQuiz('', gameId, gameType);
};

ChallengeView.prototype.getGameStartTime = function(game) {
    var quiz_open = parseInt(this.game.quiz_open) * 1000;
    if (this.game.type == CHALLENGE_MODES["flexi"]) {
        if (!this.flexiGameStartTime) {
            this.flexiGameStartTime = getSyncedServerTime();
        }
        if (this.flexiGameStartTime > quiz_open) {
            return this.flexiGameStartTime;
        }
    }
    return quiz_open;
};

ChallengeView.prototype.getChallengeEndTime = function (game, startTime) {
    COOLDOWN_TIME = undefined;
    if (game.type == CHALLENGE_MODES["flexi"]) {
        return parseInt(game.quiz_close);
    }
    if (!startTime) {
        startTime = game.quiz_open;
    }
    var challengeStartTime = parseInt(startTime) * 1000;
    var challengeDuration = 0;
    if (typeof game.time_limit != 'undefined' && game.time_limit != 0) {
        if (game.time_limit < 30) {
            COOLDOWN_TIME = parseInt(game.time_limit);
        }
    }
    if(typeof COOLDOWN_TIME == 'undefined'){
        COOLDOWN_TIME = 10;
    }
    if(typeof DEFAULT_QUESTION_TIME == 'undefined'){
        DEFAULT_QUESTION_TIME = 30;
    }
    if (!game.questions) {
        var questions = this.quiz.getQuestionsForQuiz(game.nid);
        if (questions != INTERNET_ERROR && questions && questions["question"]) {
            game.questions = questions["question"];
        }
    }
    for (var i in game.questions) {
        var quest_time_limit = parseInt(game.questions[i].time_limit);
        if(!quest_time_limit){
            quest_time_limit = DEFAULT_QUESTION_TIME;
        }
        quest_time_limit = quest_time_limit * 1000;
        var cooldown_time = COOLDOWN_TIME * 1000;
        challengeDuration = (challengeDuration + quest_time_limit + cooldown_time);
    }
    challengeDuration = challengeDuration + 20000;
    var challengeEndTime = Math.round((challengeStartTime + challengeDuration) / 1000);
    return challengeEndTime;
};

ChallengeView.prototype.pad = function(n) {
    return n < 10 ? '0' + n : n;
};

ChallengeView.prototype.getGroup = function () {
    if (typeof GROUP_SPECIFIC_DATA != "undefined" && GROUP_SPECIFIC_DATA == "true") {
        return CONTROLLER.getVariable("activegroupid");
    } else if(typeof BATCH_SPECIFIC_DATA != "undefined" && BATCH_SPECIFIC_DATA == "true" ){
        var batchids = getallbatchids();
        return batchids;
    }
    else {
        return OBJECT_USER_GROUPS.join();
    }
};

ChallengeView.prototype.getGroupName = function(){
    if(typeof USE_GAME_SEARCH_TEXT != 'undefined' && USE_GAME_SEARCH_TEXT == true){
        for(var i=0; i < OBJECT_USER_GROUPS.length; i++){
            var groupsJson = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, OBJECT_USER_GROUPS[i], "content"));
            if (groupsJson.type == "user" && groupsJson.title.match(/^AGE/i)
                    && groupsJson.title.match(/\d\-\d/) ) {
                return groupsJson.title;
            }
        }
    }else{
        return "";
    }

}

ChallengeView.prototype.isServerTimeInSync = function() {
    var currentTime = getSyncedServerTime();
    if (this.lastUpdatedTime > currentTime) {
        alert('We noticed a change in device date and time!');
        this.onBack();
        return false;
    } else {
        this.lastUpdatedTime = currentTime;
        return true;
    }
};

BlogsView.prototype = new View();
BlogsView.prototype.constructor = BlogsView.constructor;
function BlogsView() {
    this.settings = CONTROLLER.getService("Settings");
    this.currentuser = this.settings.getLastLoggedinUser();
};


BlogsView.prototype.render = function(params) {
    this.renderHeader();
    this.renderContent(params);
    this.setCustomStyles(params);
};


BlogsView.prototype.onBack = function()
{
    var prevView = CONTROLLER.getVariable("previousView");
    if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
        CONTROLLER.loadView("HomePage");
    } else {
        CONTROLLER.loadView("AllSubjects");
    }
};


BlogsView.prototype.renderHeader = function(params) {
    var title = this.getTitle();
    this.renderTemplate("blogs_header.tl", {'title': title}, "#header_wrapper", true);
};

BlogsView.prototype.getTitle = function() {
    return "Blogs";
};

BlogsView.prototype.setCustomStyles = function()
{
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#wrapper').css({
        width: screenwidth,
        height: screenheight - 100
    });

    $("#content_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });
    $("#wrapper,body,#blogs_wrapper").css('background', '#e5e5e5');
    $('#blogs_wrapper').css({'height': $(window).height() - 50, "overflow-y": "auto"});
    $('#sync_wrapper').addClass('hidden');

    if(ENABLE_NEW_UI){
        $("#wrapper,body,#blogs_wrapper").css('background', '#F4F5F9');
    }
};

//on selecting any of the button in attach view
BlogsView.prototype.onAttachClick = function(id) {
    try {
        // attach image from gallery
        if (id == 'blogs_attach_image_wrapper') {
            IMAGEPATH = "";
            Platform.openFileExplorer();
        }
        //attach image through camera
        else if (id == 'blogs_take_picture_wrapper') {
            var milliseconds = new Date().getTime();
            IMAGENAME = OBJECT_USER_ID + "_" + OBJECT_USER_NAME + "_" + milliseconds + ".png";
            var imagepath = Platform.getLocalServerPath() + "/sites/default/files/askexpertimages/" + IMAGENAME;
            var appfolder = Platform.getApplicationFolder();
            if (appfolder.indexOf("http") > -1) {
                var urlpath = appfolder;
            }
            else
            {
                urlpath = Platform.getLocalServerPath() + appfolder;
            }
            var url = urlpath + "/html/index.html?imagepath=" + imagepath + "&crop=true";
            Platform.setLastUrl(url);
            IMAGEPATH = "";
            Platform.takePhoto(imagepath);
        }
    }
    catch (e) {
        console.log("function:onAttachClick " + e.message);
    }
}

//gets the uploaded server image path
BlogsView.prototype.uploadFileifImagePath = function(imagepath) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var THIS = this;
		setTimeout(function () {
	        var path;
	        var contentutils = CONTROLLER.getService("ContentUtils");
	        if (IMAGEPATH) {
				if(EXEC_MODE == "online") {
	            	path = Platform.getDocumentRoot() + "/sites/default/files/askexpertimages/" + IMAGENAME;
				} else {
					path = imagepath;
				}
	        }
	        else {
	            path = GALLERYPATH;
	        }
	        var data = THIS.getDatatoUploadFile(path);
	        // calls the android api to get the uploaded server image path
	        var serverimgpath = contentutils.uploadFiletoServer(data);
            Client.closeProgressBar();
        }, 150);
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function:uploadFileifImagePath " + e.message);
    }
}

// upload file to the server
BlogsView.prototype.getDatatoUploadFile = function(path) {
    try {
        if (Platform.getNetworkState() == false) {
            alert("Internet is required.");
            Client.closeProgressBar();
            return;
        }
        else {
            var imgpath = 'file:' + path;
            var url = OBJECT_SERVER_DOMAIN + '/api/forumupload?uid=' + OBJECT_USER_ID + '&type=blog';
            var qsn_data = "{";
            var temp = "";
            temp = temp + ' "message" : "Uploading image...",';
            temp = temp + ' "data" : "' + imgpath + '",';
            temp = temp + ' "url" : "' + url + '",';
            temp = temp + ' "method" : "post",';
            temp = temp + ' "showdialog" : "true",';
            temp = temp + ' "callback" : "getserverImagePath"';
            qsn_data = qsn_data + temp + "}";
            return qsn_data;
        }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function:getDatatoUploadFile " + e.message);
    }
}

BlogsView.prototype.renderBlogsAttachFile = function(path) {
    try {
        var blog = new BlogsView();
        if ($('#blogs_attach_content_wrapper').length == 0) {
            $('#attach_content_wrapper').remove();
            $("body").append("<div id='blogs_attach_content_wrapper' class='hidden'></div>");
            this.renderTemplate('blogs_attach_file.tl', "", "#blogs_attach_content_wrapper", false);
        }

        $('.blogs_attach_text_outer_wrapper,.blogs_attach_div').addClass('hidden');
        $('.attach_file_windows_div,.attach_div_header').removeClass('hidden');
        $('.blogs_attach_cancel_text').click(function() {
            $('#blogs_attach_content_wrapper').addClass('hidden');
            $('#blogs_attach_image_overlay').remove();
        });

        $('INPUT[type="file"]').change(function() {
            var ext = this.value.match(/\.(.+)$/)[1];
            ext = ext.toLowerCase();
            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    $('#windows_file_submit').attr('disabled', false);
                    break;
                default:
                    alert('This is not an allowed file type.');
                    this.value = '';
            }
        });
        $('#windows_file_submit').unbind('click').bind("click", function() {
            var imagepath = $("#upload_file")[0].files[0];
            if (imagepath) {
                UPLOADPATH = imagepath;
                imagepath = imagepath['name'];
                GALLERYPATH = imagepath;
                var commentsView = new BlogCommentsView();
                commentsView.blogsAttachImage(imagepath);
            }
        });
        $('.blogs_attach_text_inner_wrapper ').unbind('click').bind("click", function(e) {
            var id = $(this).attr('id');
                setTimeout(function() {
                   $("#" + id + ' .blogs_attach_title_label input:radio[name=blogs_attach]').attr('checked', true);
                 }, 100);
            blog.onAttachClick(id);
            e.stopPropagation();
            return false;
        });

    }
    catch (e) {
        console.log("function:renderAttachFile " + e.message);
    }
}

BlogsView.prototype.getCommentsofblog = function(blogs, nid, page, type) {
    try {
    var j;  var blogs_with_comments = {};
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR",true));
            return;
        }
        else {
            if (blogs != undefined)
            {
                for (j = 0; j < blogs['blogs'].length; j++) {
                    if (nid == blogs['blogs'][j].nid) {
                        blogs_with_comments[BLOGS_WITH_COMMENTS] = blogs['blogs'][j];
                        blogs_with_comments[BLOGS_WITH_COMMENTS].page = page;
                        blogs_with_comments[BLOGS_WITH_COMMENTS].type = type;
                    }
                }
            }
            CONTROLLER.loadView('BlogComments', blogs_with_comments);
        }
    }
    catch (e) {
        console.log(e.message + " function:callCommentsView");
};
}

BlogsView.prototype.getBlogsCreatedDate = function(blogs) {
    try {
    var j;  var blogs_with_comments = {};  var commentscount = []; var count;
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR",true));
            return;
        }
        else {
            if(blogs != undefined){
            for(var i = 0; i < blogs['blogs'].length; i++){
                var created_date = blogs['blogs'][i].created_date;
                created_date = created_date*1000;
                var d = new Date(created_date);
                var new_description = blogs['blogs'][i]['body'];
                var month = d.getMonth();
                var monthNames = [ "January", "February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December"];
                var date = monthNames[month] + " " + d.getDate() + ", " + d.getFullYear();
                blogs['blogs'][i].blogcreated = date;
                count = 0;
                if (EXEC_MODE == 'online') {
                    if (new_description.indexOf('src=') >= 0) {
                        var myRegex = /<img[^>]+src="(.*\/\/[^">]+)"/g;
                        match = myRegex.exec(new_description);
                        var path = encodeURI(match[1]);
                        if (path.indexOf('http') == -1) {
                            if(typeof IS_HTTPS_BUILD != 'undefined' && IS_HTTPS_BUILD == 'true'){
                                var protocol = 'https:';
                             } else {
                                 var protocol = 'http:';
                             }
                             if(match != null && new_description.indexOf(match[1]) > -1)
                             var position = new_description.indexOf(match[1]);

                         var temp = [new_description.slice(0, position), protocol, new_description.slice(position)].join('');
                         blogs['blogs'][i].body = temp;
                        }
                    }
                }
            if(blogs['blogs'][i]['comments'] != undefined){
                for(var j=0; j<blogs['blogs'][i]['comments'].length; j++)
                {
                    if (blogs['blogs'][i]['comments'][j].pid == 0)
                    {
                         count++;
                    }
                    if (EXEC_MODE == 'online') {
                        var new_comment = blogs['blogs'][i]['comments'][j]['body'];
                        if (new_comment.indexOf('src=') >= 0) {
                            var myRegex = /<img[^>]+src="(.*\/\/[^">]+)"/g;
                            match = myRegex.exec(new_comment);
                            var path = encodeURI(match[1]);
                            if (path.indexOf('http') == -1) {
                                if(typeof IS_HTTPS_BUILD != 'undefined' && IS_HTTPS_BUILD == 'true'){
                                    var protocol = 'https:';
                                 } else {
                                     var protocol = 'http:';
                                 }
                                 if(match != null && new_comment.indexOf(match[1]) > -1)
                                 var position = new_comment.indexOf(match[1]);

                             var temp = [new_comment.slice(0, position), protocol, new_comment.slice(position)].join('');
                             blogs['blogs'][i]['comments'][j]['body'] = temp;
                            }
                        }
                    }
                }
                commentscount.push(count);
            }
            else{
                commentscount.push(0);
            }
              blogs['blogs'][i].commentscount = commentscount[i];
            }
        }
            return blogs;
        }
    }
    catch (e) {
        console.log(e.message + " function:getBlogsDate");
};
};

BlogsView.prototype.initPagination = function(totblogs, page)
{
    var THIS = this;  var edges; var displayedPages;
    if($(window).width() > 360){
       edges = 2;
       displayedPages = 2;
    }
    else{
        edges = 1;
        displayedPages = 1;
    }

        $(function() {
            $("#blogselector").pagination({
                items: totblogs,
                currentPage : page+1,
                itemsOnPage: 10,
                edges:edges,
                displayedPages:displayedPages,
                cssStyle: 'light-theme',
                onPageClick:THIS.onPageClick
            });

        });
         if ($("#blogselector li").hasClass('disabled')) {
        $("#blogselector .disabled .current").css('display', 'none');
       }
};

BlogsView.prototype.onPageClick = function(pageNumber) {
    try {
        if (Platform.getNetworkState() == false) {
            $("#selector").pagination('prevPage');
            alert(t("INTERNET_ERROR",true));
        }
        else {
            Client.showProgressBar(t("Loading") + "...");
            pageNumber = pageNumber - 1;
            if(CONTROLLER.activeViewName == 'MyBlogs'){
                var myblogs = new MyBlogsView();
                myblogs.renderMyBlogs(pageNumber);
            }
            else{
                var allblogs = new AllBlogsView();
                allblogs.renderAllBlogs(pageNumber);
            }
            this.setCustomStyles();
            Client.closeProgressBar();
        }
    }
    catch (e) {
        console.log(e.message + " function:onPageClick");
    }
};

BlogsView.prototype.getUserActiveGroups = function(groupid) {
    try {
        var group = "";  var groups = []; var activeGroups = [];
         var groupid = CONTROLLER.getVariable("activegroupid");
         var groupinfo = Platform.getObjectProperty(OBJECT_GROUP, groupid, "content");
         groupinfo = $.parseJSON(groupinfo);
         var user_groups = OBJECT_USER_GROUPS;
        for (var i = 0; i < user_groups.length; i++) {
            group = Platform.getObjectProperty(OBJECT_GROUP, user_groups[i], "content");
            if (group != "") {
                group = $.parseJSON(group);
                if (typeof MAIN_GROUPS != "undefined") {
                    if (groupinfo['title'].toLowerCase().indexOf("gcpp") >= 0) {
                        if (group['title'].toLowerCase().indexOf("gcpp") >= 0 && group['status'] == 1) {
                            activeGroups.push(group['nid']);
                        }
                    } else if (groupinfo['title'].toLowerCase().indexOf("pgp") >= 0) {
                        if (group['title'].toLowerCase().indexOf("pgp") >= 0 && group['status'] == 1) {
                            activeGroups.push(group['nid']);
                        }
                    }
                }else if (group['status'] == 1) {
                        activeGroups.push(group['nid']);
                    }
            }
        }
        return activeGroups;
    }
    catch (e) {
        console.log("function:getDatatoUploadFile " + e.message);
    }
}

BlogsView.prototype.removeSpace = function(text) {
    try {
        text = text.replace(/&nbsp;/g, '');
        text = text.replace(/^[(\p{Z}\s)(\b&nbsp;\b)(\b<div>&nbsp; <br\/><\/div>\b)]+$/gi, '')
        text = text.trim();
        return text;
    }
    catch (e) {
        console.log("function :- removeSpace" + e.message);
    }
};

BlogsView.prototype.showSelectedItem = function(item) {
    if (item == "my_blogs") {
        this.setTabStyles("#my_blogs", "#all_blogs", "#create_blog");
    }
    else if (item == "all_blogs") {
        this.setTabStyles("#all_blogs", "#create_blog","#my_blogs");
    }
    else if (item == "create_blog")
    {
        this.setTabStyles("#create_blog","#my_blogs","#all_blogs");
    }
}

BlogsView.prototype.setTabStyles = function(id1, id2, id3) {
    $(id1).removeClass("blog_items_inactive");
    $(id1).addClass("blog_items_active");
    $(id2).removeClass("blog_items_active");
    $(id2).addClass("blog_items_inactive");
    $(id3).removeClass("blog_items_active");
    $(id3).addClass("blog_items_inactive");
}

TaskView.prototype = new View();
TaskView.prototype.constructor = TaskView.constructor;

function TaskView() {

};


TaskView.prototype.render = function(params) {
    this.renderHeader();
    this.renderContent(params);
    this.setCustomStyles(params);
};


TaskView.prototype.onBack = function()
{
    if(CONTROLLER.getVariable("previousView") == 'calender'){
        CONTROLLER.setVariable("previousView", "");
        CONTROLLER.loadView("Calender");
    }
    else{
        CONTROLLER.loadView("Category", CONTROLLER.getVariable("activecatid"));
    }
};


TaskView.prototype.renderHeader = function(params) {
    var title = this.getTitle();
    var preView = CONTROLLER.getVariable("previousView");
    if (typeof preView !== 'undefined' && (preView === "HomePage" || preView === "AllSubjects") || (preView == "")) {
        $("#content_wrapper").empty();
        $("#content_wrapper").append("<div id='chapter_content_wrapper'></div>");
         this.renderTemplate("task_header.tl", {'title': title}, "#header_wrapper", true);
    }
    $("#search_icon").unbind('click').bind("click",(function () {
        platformview = new platformView();
        platformView.prototype.onSearchClick();
    }));

};

TaskView.prototype.getTitle = function() {
        return "Tasks";
};

TaskView.prototype.setCustomStyles = function()
{
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#wrapper').css({
        width: screenwidth,
        height: screenheight - 100
    });

    $("#content_wrapper,#askanexpert_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });
    $("#wrapper,body,#askanexpert_wrapper,#content_wrapper").css('background', '#e5e5e5');
    $('#askanexpert_wrapper').css({'height': $(window).height() - 50, "overflow-y": "auto"});
    if(ENABLE_NEW_UI){
	$("#wrapper,body,#askanexpert_wrapper,#content_wrapper").css('background', '#F4F5F9');
    }
};

TaskView.prototype.getUserProductCategories = function(groupid) {
    var userproduct = this.settings.getUserProducts(OBJECT_USER_ID);
    var categories = this.category.getCategoriesForGroup(groupid);

    if (userproduct != "") {
        var productcategories = [];
        var userproductcategories = [];
        var category = "";
        var i = 0;
        var newcategoriesjson = [];
        var temp = "";
        if (userproduct.length > 1 && userproduct[0].type.indexOf("course") > -1) {
            temp = userproduct[0]
            userproduct = [];
            userproduct = temp;
        }
        if (isArray(userproduct)) {
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (l = 0; l < userproduct.length; l++) {
                    for (j = 0; j < userproduct[l][OBJECT_CATEGORY].length; j++) {
                        if (userproduct[l][OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                            var found = false;
                            for (var m = 0; m < newcategoriesjson.length; m++) {
                                if (newcategoriesjson[m].nid == categories[OBJECT_CATEGORY][k].nid) {
                                    found = true;
                                    break;
                                }
                            }
                            if (found != true) {
                                newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                                i++;
                            }
                        }
                    }
                }
            }
        }
        else {
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (j = 0; j < userproduct[OBJECT_CATEGORY].length; j++) {
                    if (userproduct[OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                        newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                        i++;
                    }
                }
            }
        }
        userproductcategories[OBJECT_CATEGORY] = newcategoriesjson;
        return userproductcategories;
    }
    else {
        if (typeof PRODUCT_SUBSCRIPTION != "undefined" && PRODUCT_SUBSCRIPTION == "true") {
			userproductcategories = [];
			userproductcategories[OBJECT_CATEGORY] = [];
			return userproductcategories;
        } else {
            return categories;
        }
    }
}

TaskView.prototype.isResourcePlayable = function (resourceid, courseid, type, catid, playvideo, params) {
    try {
        var platformViewObj = CONTROLLER.getView('platform');
        return platformViewObj.isResourcePlayable(resourceid, courseid, type, catid, playvideo, params);
    }
    catch (e) {
        console.log("function : isResourcePlayable " + e.message);
        Client.closeProgressBar();
    }
}

TaskView.prototype.beginQuiz = function(e, id, type, taskid, status, embed)
{
    if(typeof embed == "undefined"){
        embed = false;
    }
    var quizService = CONTROLLER.getService("Quiz");
    var quiz = quizService.getQuiz(id);
    var platform = new platformView();
    var isQuizViewable = platform.isQuizViewable(quiz, quiz.type, true);
    if (isQuizViewable) {
        TASK_MODE = "true";
        var params = { "nid": id, "embed": embed, "task_id": taskid, "status": status };
        $('#chapters_list_wrapper').remove();
        $('#content_wrapper').attr('id', 'quiz_wrapper');
        loadMathJax(function () {
            if (type == 'practice') {
                CONTROLLER.loadView('Practicequiz', params);
            } else {
                CONTROLLER.loadView('Certificatequiz', params);
            }
        });
    }
};

TaskView.prototype.hideRightMenu = function() {
   try {
        $("#right-menu-wrapper").addClass("hidden");
        $("#right-menu-icon").removeClass("right-menu-selected");
    }
    catch (e) {
        console.log("function :- hideRightMenu " + e.message);
    }
};

TaskView.prototype.hideTaskFilter = function() {
   try {
        $('#task_list_wrapper').addClass('hidden');
        $('#task_filter_overlay').addClass('hidden');
        $('.tasklist_list_header').css('z-index', '1');
    }
    catch (e) {
        console.log("function :- hideTaskFilter " + e.message);
    }
};

AskanExpertView.prototype = new View();
AskanExpertView.prototype.constructor = AskanExpertView.constructor;

function AskanExpertView() {
 this.courseid = CONTROLLER.getVariable("activecourseid")
};


AskanExpertView.prototype.render = function(params) {
    if(typeof ZOPIM_CHAT_ENABLE != 'undefined' && ZOPIM_CHAT_ENABLE == 'true'){
        if(typeof hideChat != "undefined"){
            hideChat();
        }
    }
    this.renderHeader();
    this.renderContent(params);
    this.setCustomStyles(params);
    try{
        Client.trackScreen("ask an expert");
    }
    catch(e){
    }
};


AskanExpertView.prototype.onBack = function()
{
    CONTROLLER.loadView("AllSubjects");
};


AskanExpertView.prototype.renderHeader = function(params) {
    var title = this.getTitle();
    if (typeof RENDER_FORUM_HEADERFILE != "undefined" && RENDER_FORUM_HEADERFILE == "true") {
        this.renderTemplate("askanexpert_header.tl", {'title': title}, "#header_wrapper", true);
    }
    else{
         if(($('#task_list_wrapper').length != 0) && (typeof TASK_ENABLED != "undefined" && TASK_ENABLED == "true")){
              $('#task_list_wrapper').remove();
         }
    }
};

AskanExpertView.prototype.getTitle = function() {
    var title = "";
    var show_logs = CONTROLLER.getVariable("show_logs");
    if (typeof show_logs != 'undefined' && show_logs) {
        title = LOGS_TITLE;
    }else{
        if (CONTROLLER.getVariable("forum_activity") == "true") {
            title = FORUM_ACTIVITY_TITLE;
        } else {
            title = ASKANEXPERT_TITLE;
        }
    }
    return t(title);
};

AskanExpertView.prototype.setCustomStyles = function()
{
    var screenwidth = $(window).width() + "px";
    var screenheight = $(window).height() + "px";
    $("body,#wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    $('#wrapper').css({
        width: screenwidth,
        height: screenheight - 100
    });

    $("#content_wrapper,#askanexpert_wrapper").css({
        width: screenwidth,
        height: (screenheight - 63)
    });

    $("#header_wrapper").css({
        width: screenwidth + "px"
    });
    $("#wrapper,body,#askanexpert_wrapper").css('background', '#e5e5e5');
    $('#askanexpert_wrapper').css({'height': $(window).height() - 50, "overflow-y": "auto"});
    $('#sync_wrapper').addClass('hidden');
};

AskanExpertView.prototype.onAttachClick = function(id) {
    try {
        // attach image from gallery
        var THIS= this;
        if (id == 'attach_image_wrapper') {
            IMAGEPATH = "";
            Platform.openFileExplorer();
        }
        //attach image through camera
        else if (id == 'take_picture_wrapper') {
            var milliseconds = new Date().getTime();
            IMAGENAME = OBJECT_USER_ID + "_" + OBJECT_USER_NAME + "_" + milliseconds + ".png";
            var imagepath = Platform.getLocalServerPath() + "/sites/default/files/askexpertimages/" + IMAGENAME;
            var appfolder = Platform.getApplicationFolder();
            if (appfolder.indexOf("http") > -1) {
                var urlpath = appfolder;
            }
            else
            {
                urlpath = Platform.getLocalServerPath() + appfolder;
            }
            var url = urlpath + "/html/index.html?imagepath=" + imagepath + "&crop=true";
            Platform.setLastUrl(url);
			if(EXEC_MODE == "online") {
            	IMAGEPATH = imagepath;
			} else {
				IMAGEPATH = "";
			}
            Platform.takePhoto(imagepath);
        }
        else if (id == 'audio_video_wrapper') {
            if (Platform.getNetworkState() == false) {
                alert(t("INTERNET_ERROR",true));
                return;
            } else {
                window.removeEventListener("message",receiveMessage);
                window.addEventListener('message', receiveMessage);
                THIS.showFileUploadDialog();
                $('#attach_content_wrapper').addClass('hidden');  
            }  
        }
    }
    catch (e) {
        console.log("function:onAttachClick " + e.message);
    }
};
function receiveMessage(event) {
    console.log(event);
    $('#attach_image_overlay,.ad_vd_iframe_wrp').remove();
    if (event.data.message == "upload_success") {
        var path = event.data.filepath;
        path=path.trim();
        AUDIOPATH=path;
        if(CONTROLLER.activeViewName == 'Comments') {
            var commentsView = new CommentsView();
            commentsView.attachImage(path); // call to commentsview of forum
        }
        else {
            var postview = new PostQuestionView();
            postview.attachImage(path);
            // call to postquestionview of forum
        }
    }
}
//gets the uploaded server image path
AskanExpertView.prototype.uploadFileifImagePath = function(imagepath) {
    try {
        Client.showProgressBar(t("Loading") + "...");
        var THIS = this;
		setTimeout(function () {
        var path;
        var contentutils = CONTROLLER.getService("ContentUtils");
        if (IMAGEPATH) {
				if(EXEC_MODE == "online") {
	            	path = Platform.getDocumentRoot() + "/sites/default/files/askexpertimages/" + IMAGENAME;
				} else {
             path = imagepath;
        }
	        }
        else {
            path = GALLERYPATH;
        }
        var data = THIS.getDatatoUploadFile(path);
        // calls the android api to get the uploaded server image path
        var serverimgpath = contentutils.uploadFiletoServer(data);
        Client.closeProgressBar();
        }, 300);
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function:uploadFileifImagePath " + e.message);
    }
}

// upload file to the server
AskanExpertView.prototype.getDatatoUploadFile = function(path) {
    try {
        if (Platform.getNetworkState() == false) {
            alert(t("INTERNET_ERROR",true));
			Client.closeProgressBar();
            return;
        }
        else {
            var imgpath = 'file:' + path;
            var url = OBJECT_SERVER_DOMAIN + '/api/forumupload?uid=' + OBJECT_USER_ID + '&type=forum';
            var qsn_data = "{";
            var message = t("Uploading image...");
            var temp = "";
            temp = temp + ' "message" : "'+message+'",';
            temp = temp + ' "data" : "' + imgpath + '",';
            temp = temp + ' "url" : "' + url + '",';
            temp = temp + ' "method" : "post",';
            temp = temp + ' "showdialog" : "true",';
            temp = temp + ' "callback" : "getserverImagePath"';
            qsn_data = qsn_data + temp + "}";
            return qsn_data;
        }
    }
    catch (e) {
        Client.closeProgressBar();
        console.log("function:getDatatoUploadFile " + e.message);
    }
}

// renders the attach image view
AskanExpertView.prototype.renderAttachFile = function(path) {
    try {
        var expert = new AskanExpertView();
        var data = {};
        data['exec_mode'] = EXEC_MODE;
        if ($('#attach_content_wrapper').length == 0) {
            $('#blogs_attach_content_wrapper').remove();
            $("body").append("<div id='attach_content_wrapper' class='hidden'></div>");
            this.renderTemplate('askanexpert_attach_file.tl', data, "#attach_content_wrapper", false);
        }
        $('.attach_text_outer_wrapper,.attach_div').addClass('hidden');
        $('.attach_file_windows_div,.attach_div_header').removeClass('hidden');
        $('.attach_cancel_text').click(function() {
            $('#attach_content_wrapper').addClass('hidden');
            $('#attach_image_overlay').remove();
        });

        if (EXEC_MODE == "mobile") {
            $('.attach_text_outer_wrapper,.attach_div').removeClass('hidden');
        }

        $('INPUT[type="file"]').change(function() {
            var ext = this.value.match(/\.(.+)$/)[1];
            ext = ext.toLowerCase();
            switch (ext) {
                case 'jpg':
                case 'jpeg':
                case 'png':
                    $('#windows_file_submit').attr('disabled', false);
                    break;
                default:
                    alert('This is not an allowed file type.');
                    this.value = '';
            }
        });
        $('#windows_file_submit').unbind('click').bind("click", function() {
            var imagepath = $("#upload_file")[0].files[0];
            if (imagepath) {
                UPLOADPATH = imagepath;
                imagepath = imagepath['name'];
                GALLERYPATH = imagepath;
                if (TYPE == "post_comment") {
                    var commentsView = new CommentsView();
                    commentsView.attachImage(imagepath); // call to commentsview
                }
                else {
                    var questionView = new PostQuestionView();
                    questionView.attachImage(imagepath); // call to postquestionview
                }
            }
        });

        $('.attach_text_inner_wrapper ').unbind('click').bind("click", function(e) {
            var id = $(this).attr('id');
                setTimeout(function() {
                   $("#" + id + ' .attach_title_label input:radio[name=attach]').attr('checked', true);
                 }, 100);
            expert.onAttachClick(id);
            e.stopPropagation();
            return false;
        });

    }
    catch (e) {
        console.log("function:renderAttachFile " + e.message);
    }
}

AskanExpertView.prototype.showFileUploadDialog = function(event) {
        if($("#iframe-container").length > 0) {
            $("#iframe-container").remove();
        }
        var redirectPath = OBJECT_SERVER_DOMAIN + '/reviewer/uploadfile?uid='+OBJECT_USER_ID+'&type=forum&filetype=audio&webview=true&dialog=true';
        var resourcepath = OBJECT_SERVER_DOMAIN + '/api/login/redirect?uid=' + OBJECT_USER_ID + "&auth="+AUTH_ENABLED+"&useractive=true&path=" + encodeURIComponent(redirectPath);
        resourcepath = Platform.getAuthenticatedURL(resourcepath, 'GET', 'cloud');   

        $("body").append(" <div class='ad_vd_iframe_wrp'><div class='uploadfile_outer_wrapper'><div class='uploadfile_close'></div><div class='attach_cancel_text'>Upload a file</div></div><div id='ad_vd_iframe_container'><iframe id='ad_vd_iframe' frameborder=0 style= height=" + ($(window).height()) + " width=" + $(window).width() + " src=" + resourcepath + "></iframe></div></div>");
        Client.showProgressBar(t("Loading") + "...");
        setTimeout(function () {
            Client.closeProgressBar();
        },2000);
        $('.uploadfile_close').click(function() {
            AUDIOPATH = '';
            $('#attach_image_overlay,.ad_vd_iframe_wrp').remove();
        });
}

AskanExpertView.prototype.getUserProductCategories = function(groupid) {
    var userproduct = this.settings.getUserProducts(OBJECT_USER_ID);
    var categories = this.category.getCategoriesForGroup(groupid);
    var categories_having_course = {}; categories_having_course[OBJECT_CATEGORY] = [];
    var trial_product_flag=false;
    CATEGORIES_OBJECT = categories;
    var remove_sub_arr = [];
    var prod_title = "";
    if (userproduct != "") {
        var productcategories = [];
        var userproductcategories = [];
        var category = "";
        var i = 0;
        var newcategoriesjson = [];
        var temp = "";
        if (isArray(userproduct)) {
            prod_title = userproduct[0]['title'];
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (l = 0; l < userproduct.length; l++) {
                    if (userproduct[l]['title'].indexOf('Trial') > -1) {
                        if (userproduct[l]['groups'].indexOf(groupid) > -1) {
                            trial_product_flag = true;
                        }
                    }
                        var gup = userproduct[l].groups;
                        for (var n = 0; n < gup.length; n++) {
                            if (gup[n] == groupid) {
                                for (j = 0; j < userproduct[l][OBJECT_CATEGORY].length; j++) {
                                    if (userproduct[l][OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                                        var found = false;
                                        for (var m = 0; m < newcategoriesjson.length; m++) {
                                            if (newcategoriesjson[m].nid == categories[OBJECT_CATEGORY][k].nid) {
                                                found = true;
                                                break;
                                            }
                                        }
                                        if (found != true) {
                                            newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                                            i++;
                                        }
                                    }
                                }
                            }
                        }
                }
            }
            if (trial_product_flag == false) {
                for (var t = 0; t < userproduct.length; t++) {
                    if (userproduct[t]['title'].indexOf('Trial') > -1) {
                        for (var s = 0; s < categories[OBJECT_CATEGORY].length; s++)
                        {
                            for (j = 0; j < userproduct[t][OBJECT_CATEGORY].length; j++) {
                                if (userproduct[t][OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][s].nid) {
                                    var found = false;
                                    for (var m = 0; m < newcategoriesjson.length; m++) {
                                        if (newcategoriesjson[m].nid == categories[OBJECT_CATEGORY][s].nid) {
                                            found = true;
                                            break;
                                        }
                                    }
                                    if (found != true) {
                                        newcategoriesjson[i] = categories[OBJECT_CATEGORY][s];
                                        i++;
                                    }
                                }
                            }

                        }
                    }
                }
            }
        }
        else {
            prod_title = userproduct['title'];
            for (k = 0; k < categories[OBJECT_CATEGORY].length; k++) {
                for (j = 0; j < userproduct[OBJECT_CATEGORY].length; j++) {
                    if (userproduct[OBJECT_CATEGORY][j] == categories[OBJECT_CATEGORY][k].nid) {
                        newcategoriesjson[i] = categories[OBJECT_CATEGORY][k];
                        i++;
                    }
                }
            }
        }
        userproductcategories[OBJECT_CATEGORY] = newcategoriesjson;

        if (isArray(userproduct) && userproduct[0].allowed_no_of_questions != null && typeof userproduct[0].allowed_no_of_questions != undefined) {
            ALLOWED_NO_OF_QUESTIONS = userproduct[0].allowed_no_of_questions;
        }else if(!isArray(userproduct) && userproduct.allowed_no_of_questions != null && typeof userproduct.allowed_no_of_questions != undefined ){
             ALLOWED_NO_OF_QUESTIONS = userproduct.allowed_no_of_questions;
        }
        if (prod_title && prod_title.toLowerCase().indexOf('aakash itutor demo product') >= 0) {
            if (groupid == MAIN_PREMIUM_GROUPS[0]['nid'] || groupid == MAIN_PREMIUM_GROUPS[1]['nid']) {
                remove_sub_arr[0] = 'botany';
                remove_sub_arr[1] = 'zoology';
            } else if (groupid == MAIN_PREMIUM_GROUPS[2]['nid'] || groupid == MAIN_PREMIUM_GROUPS[3]['nid']) {
                remove_sub_arr[0] = 'mathematics';
            }
            for (var m = 0; m < remove_sub_arr.length; m++) {
                for (var n = 0; n < userproductcategories[OBJECT_CATEGORY].length; n++) {
                    if (userproductcategories[OBJECT_CATEGORY][n]['title'].toLowerCase().indexOf(remove_sub_arr[m]) >= 0) {
                        userproductcategories[OBJECT_CATEGORY].splice(n, 1);
                        break;
                    }
                }
            }
        }
        if(EXEC_MODE != 'online'){
        for (var i = 0; i < userproductcategories[OBJECT_CATEGORY].length; i++) {
            var courses = this.category.getCoursesForCategory(userproductcategories[OBJECT_CATEGORY][i].nid, CONTROLLER.getVariable("activegroupid"));
            if (!$.isEmptyObject(courses) && courses[OBJECT_COURSE].length != 0) {
                categories_having_course[OBJECT_CATEGORY].push(userproductcategories[OBJECT_CATEGORY][i]);
            }
        }
        userproductcategories[OBJECT_CATEGORY] = categories_having_course[OBJECT_CATEGORY];
        }
        return userproductcategories;
    }
    else {
        if (typeof PRODUCT_SUBSCRIPTION != "undefined" && PRODUCT_SUBSCRIPTION == "true") {
			userproductcategories = [];
			userproductcategories[OBJECT_CATEGORY] = [];
			return userproductcategories;
        } else {
            return categories;
        }
    }
}

AskanExpertView.prototype.orderUserGroups = function(groups) {
for (i = 0; i < groups.length; i++) {

        if (i == 0 && OBJECT_USER_GROUPS.length > 1) {
            group = $.parseJSON(Platform.getObjectProperty(OBJECT_GROUP, groups[i], "content"));
            if (group && (group.title.search("Class XII") > -1 || group.title.search("Class 12") > -1)) {
                temp = groups[1];
                OBJECT_USER_GROUPS[1] = groups[0];
                OBJECT_USER_GROUPS[0] = temp;
            }
        }
    }
    return OBJECT_USER_GROUPS;
   }

AskanExpertView.prototype.hideRightMenu = function() {
    try {
        $("#right-menu-wrapper").addClass("hidden");
        $("#right-menu-icon").removeClass("right-menu-selected");
    }
    catch (e) {
        console.log("function :- hideRightMenu " + e.message);
    }
};

AskanExpertView.prototype.removeSpace = function(text) {
    try {
        text = text.replace(/&nbsp;/g, '');
        text = text.replace(/^[(\p{Z}\s)(\b&nbsp;\b)(\b<div>&nbsp; <br\/><\/div>\b)]+$/gi, '')
        text = text.trim();
        return text;
    }
    catch (e) {
        console.log("function :- removeSpace" + e.message);
    }
};

AskanExpertView.prototype.checkMultipleParentGroups = function() {
    try {
        var count = 0;
        for (var j = 0; j < MAIN_GROUPS.length; j++) {
            if (jQuery.inArray(MAIN_GROUPS[j]['nid'], OBJECT_USER_DATA.groups) != -1) {
                count++;
            }
        }
        return count;
        }
    catch (e) {
        console.log("function :- checkMultipleParentGroups " + e.message);
    }
}

AskanExpertView.prototype.getGroupsWithCategories = function(groupid) {
    try {
        var platform = new platformView();
        return platform.getGroupsWithCategories(groupid);
    } catch (e) {
        console.log("function - getGroupsWithCategories : " + e.message)
            }
        }

AskanExpertView.prototype.getAllCategoryInfo = function(categories) {
    var allsubjectsview = this; var courses = "";
    for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
        categories[OBJECT_CATEGORY][i].posterpath = CONTROLLER.getService("ContentUtils").getPosterPath(categories[OBJECT_CATEGORY][i].nid, categories[OBJECT_CATEGORY][i].thumbnail_filename, OBJECT_PRESET);
        courses = allsubjectsview.category.getCoursesForCategory(categories[OBJECT_CATEGORY][i].nid, "");
        if (courses) {
            var courseslength = courses[OBJECT_COURSE].length;
            categories[OBJECT_CATEGORY][i].courseslength = courseslength;
        }
    }
    return categories;
}

AskanExpertView.prototype.getAllCategoriesforUserGroups = function() {
    var courseslength = "";
    var contentUtilsService = CONTROLLER.getService("ContentUtils");
    var allcourses = [];
    allcourses[OBJECT_COURSE] = [];
    var allcourses;
    var courses = [];
    courses[OBJECT_COURSE] = [];
    var allcategories = {};
    allcategories[OBJECT_CATEGORY] = [];
    var allcourses = {};
    allcourses[OBJECT_COURSE] = [];
    for (var k = 0; k < OBJECT_USER_GROUPS.length; k++) {
        var categories = this.getGroupsWithCategories(OBJECT_USER_GROUPS[k]);
        for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
            categories[OBJECT_CATEGORY][i].posterpath = contentUtilsService.getPosterPath(categories[OBJECT_CATEGORY][i].nid, categories[OBJECT_CATEGORY][i].thumbnail_filename, OBJECT_PRESET);
            courses = this.category.getCoursesForCategory(categories[OBJECT_CATEGORY][i].nid, OBJECT_USER_GROUPS[k]);
            categories[OBJECT_CATEGORY][i].course_title = " " + t(OBJECT_COURSE_TITLE + "[s]");
            courseslength = 0;
            for (var h = 0; h < courses[OBJECT_COURSE].length; h++) {
                if (typeof CHECK_SUBSCRIBED_COURSES != "undefined" && CHECK_SUBSCRIBED_COURSES == 'true') {
                    var user_sub = this.settings.getUserSubscriptions(OBJECT_USER_ID, categories[OBJECT_CATEGORY][i].nid, courses[OBJECT_COURSE][h].nid);
                    if ((user_sub != "" && user_sub != null) && jsonArrayHasId(allcourses[OBJECT_COURSE], courses[OBJECT_COURSE][h].nid) == false) {
                        allcourses[OBJECT_COURSE].push(courses[OBJECT_COURSE][h]);
                        courseslength++;
                    }
                } else {
                if (jsonArrayHasId(allcourses[OBJECT_COURSE], courses[OBJECT_COURSE][h].nid) == false) {
                    allcourses[OBJECT_COURSE].push(courses[OBJECT_COURSE][h]);
                    courseslength++;
                }
            }
            }

            if (typeof categories[OBJECT_CATEGORY][i].courseslength != "undefined") {
                categories[OBJECT_CATEGORY][i].courseslength = categories[OBJECT_CATEGORY][i].courseslength + courseslength;
            }
            else {
                categories[OBJECT_CATEGORY][i].courseslength = courseslength;
            }

            if (!hasId(allcategories[OBJECT_CATEGORY], categories[OBJECT_CATEGORY][i].nid)) {
                allcategories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][i]);
            }
            else {
                for (var l = 0; l < allcategories[OBJECT_CATEGORY].length; l++) {
                    if (allcategories[OBJECT_CATEGORY][l].nid == categories[OBJECT_CATEGORY][i].nid) {
                        allcategories[OBJECT_CATEGORY][l].courseslength = allcategories[OBJECT_CATEGORY][l].courseslength + courseslength;
                    }
                }
            }
        }
    }
    return allcategories;
};

//get product subscribed categories (Enable if product subscription is required)
AskanExpertView.prototype.getSubscribedCourseCategories = function() {
    var subscribedCourseCategories = [];
    var product_categories = [];
    subscribedCourseCategories[OBJECT_CATEGORY] = [];

    var userproduct = this.settings.getUserProducts(OBJECT_USER_ID);

    if (userproduct) {
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
        var courses = Platform.getObjectsOfType(OBJECT_COURSE);
        courses = $.parseJSON(courses);
        for (var i = 0; i < courses[OBJECT_COURSE].length; i++) {
            var user_sub = this.settings.getUserSubscriptions(OBJECT_USER_ID, "", courses[OBJECT_COURSE][i].nid, "");
            if (user_sub != "" && user_sub != null) {
                var courseCategories = courses[OBJECT_COURSE][i][OBJECT_CATEGORY];
                for (var k = 0; k < courseCategories.length; k++) {
                    if ($.inArray(courseCategories[k], product_categories) === -1) {
                        product_categories.push(courseCategories[k]);
                    }
                }
            }
        }
         for (var j = 0; j < product_categories.length; j++) {
            $.each(categories[OBJECT_CATEGORY], function(key, value) {
                if (value['nid'] == product_categories[j]) {
                    categories[OBJECT_CATEGORY][key]['subscription_type'] = 'course';
                     categories[OBJECT_CATEGORY][key]['subscribed'] = "true";
                    subscribedCourseCategories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][key]);
                }
            });
        }

    }
    return subscribedCourseCategories;
};

AskanExpertView.prototype.getSubscribedGroupsWithCategories = function(group) {
    var subscribedCategories = this.getSubscribedCategories(group); //get user subscribed categories.
    if (typeof PRODUCT_SUBSCRIPTION != "undefined" && PRODUCT_SUBSCRIPTION != "false") {
        var subscribedCourseCategories = this.getSubscribedCourseCategories(); //get product subscribed categories
    }else{
         var subscribedCourseCategories = []; subscribedCourseCategories[OBJECT_CATEGORY] = [];
    }
     var subscribedGroupWithCategories = []; var allSubscribedCategories ={};
     subscribedGroupWithCategories[OBJECT_CATEGORY] = []; allSubscribedCategories[OBJECT_CATEGORY] = [];
     if (group) {
        var usergroups = [];
        usergroups.push(group);
    } else {
        var usergroups = OBJECT_USER_GROUPS;
    }
     //get All groups categories
    for (var i = 0; i < usergroups.length; i++) {
        if (typeof SHOW_UNSUBSCRIBED_CATEGORIES != "undefined" && SHOW_UNSUBSCRIBED_CATEGORIES == "true") {
            var groupWithCategories = this.category.getCategoriesForGroup(usergroups[i]);
            for (var j = 0; j < groupWithCategories[OBJECT_CATEGORY].length; j++) {
                subscribedGroupWithCategories[OBJECT_CATEGORY].push(groupWithCategories[OBJECT_CATEGORY][j]);
            }
        } else {
             //get group subscribed categories [Group Level Subscription Check].
            var user_sub = this.settings.getUserSubscriptions(OBJECT_USER_ID, "", "", usergroups[i]);
            if (user_sub != "" && user_sub != null && user_sub != "{}") {
                var groupWithCategories = this.category.getCategoriesForGroup(usergroups[i]);
                for (var j = 0; j < groupWithCategories[OBJECT_CATEGORY].length; j++) {
                    subscribedGroupWithCategories[OBJECT_CATEGORY].push(groupWithCategories[OBJECT_CATEGORY][j]);
                }
            }
        }
    }
     if(subscribedCategories[OBJECT_CATEGORY].length > 0){
          for (var j = 0; j < subscribedCategories[OBJECT_CATEGORY].length; j++) {
              if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedCategories[OBJECT_CATEGORY][j].nid) == false) {
                  allSubscribedCategories[OBJECT_CATEGORY].push(subscribedCategories[OBJECT_CATEGORY][j]);
              }
          }
     }
     if(subscribedCourseCategories[OBJECT_CATEGORY].length > 0){
          for (var j = 0; j < subscribedCourseCategories[OBJECT_CATEGORY].length; j++) {
              if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedCourseCategories[OBJECT_CATEGORY][j].nid) == false) {
                  allSubscribedCategories[OBJECT_CATEGORY].push(subscribedCourseCategories[OBJECT_CATEGORY][j]);
              }
          }
     }
     if(subscribedGroupWithCategories[OBJECT_CATEGORY].length > 0){
          for (var j = 0; j < subscribedGroupWithCategories[OBJECT_CATEGORY].length; j++) {
              if (jsonArrayHasId(allSubscribedCategories[OBJECT_CATEGORY], subscribedGroupWithCategories[OBJECT_CATEGORY][j].nid) == false) {
                  allSubscribedCategories[OBJECT_CATEGORY].push(subscribedGroupWithCategories[OBJECT_CATEGORY][j]);
              }
          }
     }
     return allSubscribedCategories;
};

//get user subscribed categories.[Category Level Subscription Check]
AskanExpertView.prototype.getSubscribedCategories = function(group) {
    var subscribedcategories = [];
    subscribedcategories[OBJECT_CATEGORY] = [];
    if (!group) {
        var categories = Platform.getObjectsOfType(OBJECT_CATEGORY);
        categories = $.parseJSON(categories);
    } else {
        var categories = this.category.getCategoriesForGroup(group);
    }
    for (var i = 0; i < categories[OBJECT_CATEGORY].length; i++) {
        categories[OBJECT_CATEGORY][i]["subscribed"] = "true";
        var user_sub = this.settings.getUserSubscriptions(OBJECT_USER_ID, categories[OBJECT_CATEGORY][i].tid, "", "");
        if (user_sub != "" && user_sub != null && user_sub != "{}") {
            subscribedcategories[OBJECT_CATEGORY].push(categories[OBJECT_CATEGORY][i]);
        }
    }
    return subscribedcategories;
}

//get user subscribed categories.[Category Level Subscription Check]
AskanExpertView.prototype.getUserGroupsnidInArrayFormat = function(categories) {
    this.user = CONTROLLER.getService("User");
    var batches = CONTROLLER.getVariable("activebatches");
    if (!batches) {
        this.user.getUserGroupsData();
        var batches = CONTROLLER.getVariable("activebatches");
    }
     var user_batches= [];
     for (var i = 0; i < batches['batches'].length; i++) {
         user_batches.push(batches['batches'][i].nid);
     }
    return user_batches;
}

AskanExpertView.prototype.getUserGroupsToBeShown = function(user_groups) {
    var group = ""; var groups = [];
    for (var i = 0; i < user_groups.length; i++) {
        group = Platform.getObjectProperty(OBJECT_GROUP, user_groups[i], "content");
        if (typeof SHOW_ONLY_CONTENT_GROUPS != "undefined" && SHOW_ONLY_CONTENT_GROUPS == "true") {
            if (group) {
                var usergroups = $.parseJSON(group);
                if (typeof usergroups.type != "undefined" && usergroups.type == "user") {
                    group = "";
                }
            }
        }
        if (group != "") {
            group = $.parseJSON(group);
            if (typeof SHOW_GROUP_DISPLAY_NAME != "undefined" && SHOW_GROUP_DISPLAY_NAME == "true") {
                group.title = group.display_name;
            } else {
                group.title = group.title.split(/\s+/).slice(0, 2).join(" ");
            }
            groups.push(group);
        }
    }
    return groups;
}
AskanExpertView.prototype.removeCategory = function (category) {
     var subjects;
    for (i = 0; i < category[OBJECT_CATEGORY].length; i++) {
        subjects = $.grep(category[OBJECT_CATEGORY], function (e) {
            return e.nid != LEARN_MORE_CATID;
        });
    }
    return subjects;
};

AskanExpertView.prototype.checkAudioTagSupport = function (data) {
    var html = $('<div>').html(data);
    html.find('audio').replaceWith(function() {
        var src = this.src.toLowerCase();
        var type = src.split('.').pop();
        var  result = supportsAudioType(type);
        if(typeof result != 'undefined' && (result == 'true' || result == 'probably')){
            return this.outerHTML;
        }else{
            return '<a href="' + this.src + '" class="audio-file-download">'+this.src+'</a>'
        }
        
    });
    return html.html();
};
SigninView.prototype = new platformView();
SigninView.prototype.constructor = SigninView.constructor;
function SigninView() {
    this.user = CONTROLLER.getService("User");
    this.settings = CONTROLLER.getService("Settings");
}
;

SigninView.prototype.renderContent = function ()
{
    Client.removeDialog();
    Client.closeProgressBar();
    var skipsso = getQueryParameter('skipsso', LOCATION_URL);
    if(skipsso != "null" && skipsso == "true" ){
        ENABLE_SSO_LOGIN = "false";
    }
    CONTROLLER.animateViewLoad = false;
    var autoLogin =  Platform.getPreference('autoLogin');
    if (!IS_APP_SHELL && ENABLE_SSO_LOGIN == "true" && typeof EXTERNAL_LOGIN != "undefined" && EXTERNAL_LOGIN == true && typeof EXTERNAL_LOGIN_URL != "undefined") {
        window.location.href = EXTERNAL_LOGIN_URL;
        return;
    }
    var signinView = this;
    // var socialLogin = Platform.getPreference('socialLogin');
    // if (typeof socialLogin != 'undefined' && socialLogin == 'true') {
    //     Client.showProgressBar("loading");
    //     signinView.verifyOtploginOrSociallogin();
    //     return false;
    // }
    var id = "";
    if (typeof SAML_LOGIN != "undefined" && SAML_LOGIN == "true") {
        var samlLogin = Platform.getPreference('samlLogin');
    }
    if ((typeof samlLogin != "undefined") && samlLogin == "true" || (CONTROLLER.getVariable('register_successful') == 'true') || (typeof autoLogin != 'undefined' && autoLogin == 'true')) {
        CONTROLLER.setVariable('register_successful', 'false');
        Platform.setPreference('autoLogin','false')
        signinView.loginSuccess();  //SAML login workflow
    } else {
        var signin_placeholder = "Email / Username";
        if (typeof SIGNIN_PLACEHOLDER != 'undefined') {
            signin_placeholder = SIGNIN_PLACEHOLDER;
        } else {
            SIGNIN_PLACEHOLDER = signin_placeholder;
        }
        var lang_code = Platform.getPreference("lang");
        if(typeof ENABLE_LOCALIZATION != 'undefined' && ENABLE_LOCALIZATION == "true" && typeof lang_code != "undefined" && lang_code != ""){
            if(typeof CONTENT_LOCALIZATION_ONLY != 'undefined' && CONTENT_LOCALIZATION_ONLY == 'true'){
                var json = {
                    "clientname": PROJECT_TITLE.capitalizeFirstLetter(),
                    "placeholder": t(signin_placeholder),
                    "welcome_text": WELCOME_TEXT
                }
            }else{
                var json = {
                    "clientname": PROJECT_TITLE.capitalizeFirstLetter(),
                    "placeholder": t(signin_placeholder),
                    "welcome_text": LANGUAGE[LANGUAGE_CODE[Platform.getPreference("lang")]]['welcome_text']
                }
            }
        } else {
            var json = {
                "clientname": PROJECT_TITLE.capitalizeFirstLetter(),
                "placeholder": t(signin_placeholder),
                "welcome_text": WELCOME_TEXT
            }
        }
        if(typeof ENABLE_FORGOT_PASSWORD != 'undefined' && ENABLE_FORGOT_PASSWORD == 'true'){
            json.enable_fgp = 'true';
        }
        if (typeof ENABLE_REGISTER != 'undefined' && ENABLE_REGISTER == 'true'  && typeof VIEWCONFIG ["Register"]  != 'undefined') {
            json.enable_register = 'true';
        }

        if(typeof ENABLE_LOCALIZATION != 'undefined' && ENABLE_LOCALIZATION == "true"){
            json['lang'] = LANG_META_OBJECTS;
        }
        if(typeof loadSiginTemplateForSubClients == "function"){
            loadSiginTemplateForSubClients(json);                
        } else {
            this.renderTemplate("signin.tl", json, "#content_wrapper", true);
        }
        $("#selectedLang").off('change').on('change', function () {
            var previd = Platform.getPreference("lang");
            var curid = $(this).find('option:selected').attr('id');
            var selectedLangName = $(this).find('option:selected').attr('langname');

            if (curid !== previd || typeof previd == 'undefined') {
                Platform.setPreference("lang", curid);
                Platform.setPreference("langname", selectedLangName);
                LANG = LANGUAGE_CODE[curid];
                CONTROLLER.loadView("Signin");
            }

        });
        var preference = Platform.getPreference("langname");
        var option = $('option[value=' + preference + ']');
        option.attr('selected', true);

        if (typeof SAML_LOGIN != "undefined" && SAML_LOGIN == "true") {
            signinView.disablePassword();
        }
        if (typeof SIGNIN_LOGO_PRESENT != "undefined" && SIGNIN_LOGO_PRESENT == "true") {
            $('#sigin_i_logo_wrapper').removeClass('hidden');
        }

        if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
            $('.google_signin').removeClass('hidden');
        }

        var user = this.settings.getLastLoggedinUser();
        var remember_me_stored_data = Platform.getPreference("remember_me_details");
        if(typeof remember_me_stored_data == "string" && typeof remember_me_stored_data != 'undefined' && remember_me_stored_data != ''){
            var remember_me_signin_data =  JSON.parse(remember_me_stored_data);
            $("#signin_email").val(remember_me_signin_data['email']);
            $("#signin_password").val(remember_me_signin_data['password']);
        }
        if (user != "" && ((user.loginRequiredOffline && user.loginRequiredOffline == true) || (user.loginRequiredOnline && user.loginRequiredOnline == true)) && EXEC_MODE == "mobile") {
            if (CLIENT_NAME == 'amhi' || (typeof USE_USER_NAME != "undefined" && USE_USER_NAME == "true")) {
                $("#signin_email").val(user.username);
            }else if (typeof USERNAME_OR_EMAIL != "undefined" && USERNAME_OR_EMAIL == "true"){
                $("#signin_email").val(user.username);
            }else {
                $("#signin_email").val(user.email);
            }
            $("#signin_email").attr('readonly', true);
            $("#signin_email").addClass("input_readonly");

            $("#signin_submit").css({
                margin: "0 auto",
                float: "none"
            });
            signinView.enablePassword();
        }
        $('.signin_input').on('keyup', function (e) {
            id = $(this).attr("id");
            var mEvent = e || window.event;
            var mPressed = mEvent.keyCode || mEvent.which;
            if (mPressed == 13) {
                // On enter, go to next input field
                if (id == 'signin_email') {
                    document.getElementById('signin_password').focus();
                } else {
                    signinView.onSigninClick();
                }

            }
            return true;
        });
        $(document).on("keypress", "#signin_submit", function (e) {
            if (e.which == 13) {
                signinView.onSigninClick();
            }
        });
        $("#signin_terms").click(function () {
            setTimeout(function () {
                $('.terms_outer_wrapper').remove();
                $('body').append("<div class='terms_outer_wrapper'></div>");
                signinView.renderTemplate("termsandconditions.htm", "", ".terms_outer_wrapper", true);
                $('.terms_outer_wrapper').dialog({
                    resizable: false,
                    modal: true,
                    height: 500,
                    width: 361,
                    dialogClass: 'dlgfixed',
                    position: "center",
                });

                $('.ui-button-text, .ui-dialog-titlebar').css({"display": "none"})
                $(".dlgfixed").center(false);
                $('#terms_outer_wrapper').parent().addClass('alert_wrapper_dialog');
                $('.alert_wrapper_dialog').parent().addClass('alert_wrapper_dialog_outer');
                $('html body .ui-dialog').css({'border': '1px solid #bbb', 'z-index': '100000',
                    'background': '#ffffff', 'position': 'fixed', 'outline': 'none', 'overflow': 'auto',
                    'border-radius': '10px 10px 10px 10px', 'top': '40%'});

                $("#terms_and_conditions_text").scrollTop(10);
                $('.ui-dialog-buttonpane').css({
                    "text-align": "center",
                    margin: "15px"
                });
                $("#transparent_wrapper").css({
                    "display": "block",
                    height: $(window).height() + "px",
                    width: $(window).width() + "px"
                });
                $('.ok_wrapper').click(function () {
                    $('.terms_outer_wrapper').dialog("close");
                });
                var screenwidth = $(window).width();
                var screenheight = $(window).height();
                if (screenwidth > 750) {
                    $('html body .ui-dialog').css({'top': '42%'});
                }
                if (screenwidth < 380) {
                    $('html body .ui-dialog').css({'width': '306px', 'left': '57%', 'top': '47.5%'});
                    $('.terms_outer_wrapper').css({'height': '352px;'});
                    $('.ui-dialog ui-widget').css({'height': '413px'});
                    $('#terms_and_conditions_text').css({'height': '250px', 'width': '87%'});
                    $('.title_wrapper').css({'line-height': 'line-height: 15px;'});
                    $('#terms_title').css({'margin-left': '15px'});
                }
            }, 200);
        });


        $("#signin_submit").click(function () {
            var analyticsJson = {"context":"Sign in","action":"click","label":"Email Button Clicked", "user_id":"","type_of_user":""};
            trackScreen("Login_user",analyticsJson);
            signinView.onSigninClick();
            return false;
        });

        $('#register, #register_btn_submit, #registerlink').click(function () {
            $("#signin_email").blur();
            setTimeout(function () {
               if(typeof LOAD_PORTAL_REGISTRATION != "undefined" && LOAD_PORTAL_REGISTRATION == "true"){
                    if (Platform.getNetworkState() == true) {
                            url = PORTAL_REGISTRATION_URL;
                            window.location.href = url
                            Client.closeProgressBar();
                        }
                        else {
                        alert(t("INTERNET_ERROR", true));
                        return;
                    }
                }else{
                    CONTROLLER.loadView('Register');
                }            
            }, 300);
        });

        $("#signin_rememberme").click(function(){
            if($(this).is(":checked")) {
               CONTROLLER.setVariable("remember_me", "true");
              }
              else{
                CONTROLLER.setVariable("remember_me", "false");
              }
        });
        if (typeof ENABLE_REMEMBER_ME != 'undefined' && ENABLE_REMEMBER_ME == 'true') {
            $("#signin_rememberme_div").removeClass("hidden");
        }
        if (typeof ENABLE_REGISTER != 'undefined' && ENABLE_REGISTER == 'true'  && typeof VIEWCONFIG ["Register"]  != 'undefined') {
            $("#register_now").removeClass("hidden");
        }
        if (typeof SAML_LOGIN != "undefined" && SAML_LOGIN == "true") {
            $("#signin_email").keyup(function () {
                var email = $("#signin_email").val();
                var timestamp = new Date().getTime();
                if ((typeof VERIFY_EMAIL == "undefined" || (typeof VERIFY_EMAIL != 'undefined' && VERIFY_EMAIL != "false"))) {
                    var emailsuccess = signinView.validateEmail(email);
                } else {
                    emailsuccess = true;
                }
                if (typeof emailsuccess != "undefined" && emailsuccess == true) {
                    if (email.toLowerCase().indexOf("cognizant.com") >= 0) {
                        signinView.disablePassword();
                        signinView.samlLogin(email, timestamp);
                    } else {
                        signinView.enablePassword();
                    }
                }
            });
        }

        if(typeof ENABLE_GOOGLE_SIGNIN != 'undefined' && ENABLE_GOOGLE_SIGNIN == true){
            $('.google_signin').click(function () {
                if(Platform.getNetworkState() == true){
                    Client.googleSignIn();
                }else{
                    alert(t("INTERNET_ERROR",true));
                    Client.closeProgressBar();
                    return false;
                }
            })
        }
    }
};

SigninView.prototype.onForgotPasswordClick = function()
{
    var THIS = this;
    var userService = CONTROLLER.getService("User");
    var mobile_num = $('#signin_email').val().trim();
    if (mobile_num == "") {
        // THIS.display_login_error(EMAIL_EMPTY);
        CONTROLLER.loadView("ForgotPassword");
    } else {
        var mobilesuccess = THIS.validateMobNumber(mobile_num);
        if (mobilesuccess == true) {
            var usermobilejson = JSON.stringify({ "mobile": mobile_num });
            CONTROLLER.setVariable("save_user_id", "true");
            var userexists = userService.doesUserExist(usermobilejson);
            if (userexists == "true") { //if user exists
                $(".error-div").css('display', '');
                THIS.usermobilejson = { "mobile": mobile_num };
                $("#signin_email").blur();
                setTimeout(function () {
                    CONTROLLER.loadView("ForgotPassword", THIS.usermobilejson);
                }, 200);

            } else{
                $("#signin_email").focus();
                this.display_login_error(USER_DOES_NOT_EXIST);
                $("#signin_email").val("");
            }

        } else {
            $("#signin_email").focus();
            this.display_login_error(VALID_EMAIL);
            $("#signin_email").val("");
        }
    }
}

SigninView.prototype.display_login_error = function(message)
{
    Client.closeProgressBar();
    $(".error-div").css('display', 'block');
    $(".error-text").html(message);
    $("#signin_password").val('');
}


SigninView.prototype.signin_validate = function () {
    var email = $.trim($("#signin_email").val());
    var password = $("#signin_password").val();
    if (email == "" && password == "") {
        $("#signin_email").focus();
        this.display_login_error(t("ENTER_ALL_FIELDS",true));
    }
    else if (email == "") {
        $("#signin_email").focus();
        if (typeof SIGNIN_PLACEHOLDER != ' undefined') {
            var placeholder = SIGNIN_PLACEHOLDER;
            this.display_login_error(t(placeholder) + t("SIGNIN_PLACEHOLDER_EMPTY",true));
        } else {
            this.display_login_error(t("EMAIL_EMPTY",true));
        }
    } else if (typeof USERNAME_AS_MOBILENO != "undefined" && USERNAME_AS_MOBILENO == "true" && !this.validateMobNumber(email)) {
        $("#signin_email").focus();
        this.display_login_error(VALID_MOB_NUM);
        $("#signin_email").val("");
    } else if ((typeof USERNAME_OR_EMAIL != "undefined" && USERNAME_OR_EMAIL == "true")
          && (!this.validateUsername(email) && !this.validateEmail(email))) 
    {   
              $("#signin_email").focus();
              this.display_login_error(VALID_USERNAME);
              $("#signin_email").val("");
    } else if (typeof USERNAME_OR_EMAIL == "undefined" &&
      typeof USERNAME_AS_MOBILENO == "undefined" && !this.validateEmail(email) && !this.validateMobNumber(email) && (typeof VERIFY_EMAIL == "undefined" ||
          (typeof VERIFY_EMAIL != "undefined" && VERIFY_EMAIL != "false"))
      ) {
      $("#signin_email").focus();
      this.display_login_error(VALID_EMAIL);
      $("#signin_email").val("");
    }
    else if (password == "") {
    $("#signin_password").focus();
    this.display_login_error(t("PASSWORD_EMPTY",true));
    }
    else if(typeof ENABLE_MULTI_USER_LOGIN != "undefined" && ENABLE_MULTI_USER_LOGIN == "true"){
        var user_exists = false;
        var user_obj = "";
        if(Platform.getNetworkState("true") == false){
            var users = Platform.getObjectsOfType("user");
            if(users == ""){
                alert(t("INTERNET_ERROR",true));
                Client.closeProgressBar();
                return false;
            }
            users = $.parseJSON(users);
            for(var i=0;i<users["user"].length;i++){
                if(users["user"][i].email.toLowerCase() == email.toLowerCase()){
                    user_exists = true;
                    user_obj = users["user"][i];
                }
            }
            if(user_exists == false){
                this.display_login_error(t("INTERNET_ERROR",true));
                return;
            }
            this.loginUserOffline(password,user_obj);
        }
        else{
            this.loginUser(email, password);
        }
    }else{
        var user = this.settings.getLastLoggedinUser();
        var userService = CONTROLLER.getService("User");
        if (Platform.getNetworkState() == false && user == "") {
            this.display_login_error(t("INTERNET_ERROR",true));
        }else{
            //Commenting the getuserinfo call as the login user api will return 405 in case of email id not verified for api version >= 4
//            if (typeof EMAIL_VERIFICATION_REQUIRED != 'undefined' && EMAIL_VERIFICATION_REQUIRED == 'true') {
//                var userdata = userService.getUserInfo("", email);
//                if (userdata[0].email_verified == null) {
//                    this.display_login_error(t("EMAIL_VERIFY_ERROR_MSG", true));
//                    Client.closeProgressBar();
//                    return;
//                }
//            } else {
                this.loginUser(email, password);
//            }
        }
    }
    Client.closeProgressBar();
};


SigninView.prototype.validateMobNumber = function (mobNum) {
  if (!isNaN(mobNum)) {
    if (mobNum.length == 10) {
      var filter = /^\(?(\d{3})\)?[-\. ]?(\d{3})[-\. ]?(\d{4})$/;
      if (filter.test(mobNum)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  } else {
    return false;
  }
};
SigninView.prototype.loginUser = function (email, password) {
  var data = "{";
  var temp = "";
  temp = ' "username" : "' + email + '", ';
  temp = temp + ' "email" : "' + email + '", ';
    temp = temp + ' "password" :"' + password + '"';
    data = data + temp + "}";
    OBJECT_USER_PASSWORD = password;
    var user = this.settings.getLastLoggedinUser();
    if (user == "" || (user.loginRequiredOnline && user.loginRequiredOnline == true) || EXEC_MODE == "online") {
        var user_groups = OBJECT_USER_DATA.groups;
        var status = this.user.loginUser(data);
        if (status == "true") {
            if ((user.loginRequiredOnline && user.loginRequiredOnline == true) && EXEC_MODE == "mobile") {
                user.loginRequiredOnline = false;
                user.groups = user_groups;
                Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(user)));
                Platform.saveObject("user", user.uid, "content", $.trim(JSON.stringify(user)));
                OBJECT_USER_DATA = user;
                if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                    // if user get logged out because of session and if he tries to login again then it should be reloaded so that it shows the chatbot page 
                    if(typeof COURSE_SELECTION_BEFORE_SIGNINVIEW !== "undefined" && COURSE_SELECTION_BEFORE_SIGNINVIEW === "true"){
                        window.location.reload();
                    }
                    
                    CONTROLLER.loadView("HomePage");
                }else{
                    CONTROLLER.loadView("AllSubjects");
                }
                Client.closeProgressBar();
            }
            else {
                this.loginSuccess();
            }
        }
        else {
            this.display_login_error(status);
        }
    }
    else {
        this.loginUserOffline(password, user);
    }
}

SigninView.prototype.loginUserOffline = function(password, user) {
    var status = this.user.loginUserOffline(password, user);
    if (status == "true") {
        if(EXEC_MODE == "online"){
            CONTROLLER.loadView("HomePage");
        }
        else{
            window.location.href = "../html/index.html?ftl=true";
        }
    }
    else {
        this.display_login_error(status);
    }
}

SigninView.prototype.loginSuccess = function(callback) {
    try{
        var user_groups = this.getUserGroups();
        if (user_groups == "") {
            this.display_login_error("User group(s) not supported");
            console.log("downloading groups failed");
            return "";
        }

        var userProducts = this.user.downloadUserProducts();
        if (userProducts != "" && typeof userProducts === "undefined" ||
                        (userProducts == 'access forbidden')) {
            this.onUserProductsError(OBJECT_USER_DATA, userProducts);
            return "";
        }
        var bootstrapStatus = Platform.getBootstrapStatus();
        var isuserExistInDb = Platform.isUserExistInDB(JSON.stringify(OBJECT_USER_DATA));
        var currentuser = "";
        if(CHECK_USER_PRODUCTS_LENGTH && (userProducts == "" || userProducts == [])){
            Client.closeProgressBar();
            this.display_login_error(EXPIRY_ERROR);
            this.user.logout(false);
            return "";
        }
        if (NETWORK_BOOTSTRAP == true || bootstrapStatus != 'completed'
                || Platform.isUserExistInDB(JSON.stringify(OBJECT_USER_DATA))) {
            Client.closeProgressBar();
            Client.showDialog();
            if (NETWORK_BOOTSTRAP == true) {
                var status = Platform.initializeNetworkDataBase();
                currentuser = OBJECT_USER_DATA;
                DATABASE_DOWNLOADED = true;
                if(EXEC_MODE != "online"){
                	this.user.downloadUserProducts();
                }
                var userProducts = this.settings.getUserProducts(OBJECT_USER_ID);
                if ((userProducts != "" && typeof userProducts === "undefined") ||
                        (userProducts == 'access forbidden') || (userProducts == "error")) {
                    this.onUserProductsError(currentuser);
                    console.log("user products = " + userProducts);
                    return "";
                }
                this.saveUserData(currentuser, user_groups);
                CONTROLLER.loadView("Bootstrap", user_groups);
            }
            else {
                if(userProducts != "error")
                {
                    if (userProducts.length > 1 && (typeof APP_USER_GROUPS != 'undefined' && APP_USER_GROUPS.length != 0)) {
                        user_groups = [];
                        for (var i = 0; i < userProducts[0].groups.length; i++) {
                            for (var j = 0; j < APP_USER_GROUPS.length; j++) {
                                if (userProducts[0].groups[i] == APP_USER_GROUPS[j].nid) {
                                    user_groups.push(APP_USER_GROUPS[j].nid);
                                }
                            }
                        }
                }
                }

                var bootstrapUser = this.bootstrapUser(userProducts, user_groups);
                if (bootstrapUser == false) {
                    return "";
                }
            }
        }
        if (NETWORK_BOOTSTRAP != true) {
            currentuser = OBJECT_USER_DATA;
            if(EXEC_MODE != "online"){
                DATABASE_DOWNLOADED = true;
                this.user.downloadUserProducts();
            }
            var userProducts = this.settings.getUserProducts(OBJECT_USER_ID);
			if(CHECK_USER_PRODUCTS_LENGTH && (userProducts == "" || userProducts == [])){
                Client.closeProgressBar();
                this.display_login_error(EXPIRY_ERROR);
                return "";
            }
            var users = $.parseJSON(Platform.getObjectsOfType("user"));
            if(userProducts.length > 1 && userProducts[0].type.indexOf("course") > -1 && (typeof APP_USER_GROUPS != 'undefined' && APP_USER_GROUPS.length != 0)){
                user_groups = [];
                for (var i = 0; i < userProducts[0].groups.length; i++) {
                    for(var j=0;j<APP_USER_GROUPS.length;j++){
                        if(userProducts[0].groups[i] == APP_USER_GROUPS[j].nid){
                            user_groups.push(APP_USER_GROUPS[j].nid);
                        }
                    }
                }
            }
            if(user_groups == ""){
                this.display_login_error(t("INSTALLATION_ERROR",true));
                Client.removeDialog();
                Client.closeProgressBar();
                return;
            }
            if(EXEC_MODE == "mobile"){
				if( users!= null && typeof users['user']!= 'undefined' && users['user'].length > 0 && !Platform.isUserExistInDB(JSON.stringify(OBJECT_USER_DATA))) {
	                Platform.getNewUserSubscription(OBJECT_USER_ID);
	            }
            }
            this.saveUserData(currentuser, user_groups);
			if(EXEC_MODE == "mobile"){
	            Platform.startBackGroundJOB(true);
			}
            this.initializeUser(callback);
        }
        else {
            NETWORK_BOOTSTRAP = false;
        }
    }
    catch(e){
        console.log("function :- loginSuccess, message = " + e.message);
        Client.closeProgressBar();
        this.display_login_error(t("INSTALLATION_ERROR",true));
    }
};

SigninView.prototype.initializeUser = function(callback) {
    if (EXEC_MODE == "online") {
        CONTROLLER.setVariable('categories_listing_mode',"AllSubjects")
        if (typeof callback == "function") {
            callback.apply();
        } else {
            // check if the url has destination and redirect if it has one to the destination url
            var chatbotDestination = getQueryParameter("destination");
            var scholarship_group = Platform.getPreference('scholarship_group');
            if(typeof chatbotDestination !== "undefined" && chatbotDestination !== "null"  && chatbotDestination !== ""){
                var dashredirectUrl = chatbotDestination + "&nid=" + getQueryParameter("nid") + "&client=" + getQueryParameter("client");
                // replace the location with dash url
                window.location.replace(dashredirectUrl);
            }else if (typeof scholarship_group != 'undefined' && typeof scholarship_group === 'object') {
                CONTROLLER.loadView("PreAssessment");
            } 
            else if (typeof HOME_PAGE_ENABLED != "undefined" && HOME_PAGE_ENABLED == "true") {
                CONTROLLER.loadView("HomePage");
            }
            else {
                CONTROLLER.loadView("AllSubjects");
            }
        }
        return;
    } else {
        var initializeuser = Platform.initializeUser(JSON.stringify(OBJECT_USER_DATA));
	    this.user.getUserInfo("", OBJECT_USER_DATA.email);

        if(typeof trackWebEngageLogIn == "function"){
            trackWebEngageLogIn();
        }

	    var remember_me_option = CONTROLLER.getVariable("remember_me");
	    if(remember_me_option == 'true'){
	        this.remember_me();
	    }
        var scholarship_group = Platform.getPreference('scholarship_group');
        scholarship_group = $.parseJSON(scholarship_group);
        if (typeof scholarship_group != 'undefined' && typeof scholarship_group === 'object' && scholarship_group != null) {
            if (Platform.getNetworkState() == true && getQueryParameter("ftl") == "null") {
                this.user.loginRedirectAjax(OBJECT_USER_DATA.email);
            }
            CONTROLLER.loadView("PreAssessment");
            Client.removeDialog();
        } else {
            if (initializeuser == true || initializeuser == false) {
                window.location.reload();
            }
        }
    }
}

SigninView.prototype.saveUserData = function(currentuser, user_groups) {
    currentuser.groups = user_groups;
    Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(currentuser)));
    Platform.saveObject("user", currentuser.uid, "content", $.trim(JSON.stringify(currentuser)));
    OBJECT_USER_DATA = currentuser;
    this.user.saveUser(currentuser);
}

SigninView.prototype.onUserProductsError = function(currentuser, user_products) {
    if (user_products != "" || user_products.type == "module") {
        this.display_login_error(t("INSTALLATION_ERROR",true));
    }
    else {
        this.display_login_error(t("NO_USER_PRODUCTS",true));
    }
    Client.removeDialog();
    Client.closeProgressBar();
    Platform.removeObject("currentuser", 1);
    Platform.removeObject("user", currentuser.uid);
}

SigninView.prototype.bootstrapUser = function(user_products, user_groups) {
    if(typeof DOWNLOADING_CONTENT_MESSAGE !== "undefined" && DOWNLOADING_CONTENT_MESSAGE === "true"){
        Client.showMessage(t("Downloading Course Content"));
    }else{
        Client.showMessage("");
    }
    var userjson = OBJECT_USER_DATA;
    if(typeof user_groups != "undefined" && user_groups != "" && user_groups.length > 0){
        user_groups = user_groups[0];
    }
    userjson.groups = user_groups;
    if(isArray(user_products)){
        user_products = user_products[0];
    }
    if (user_products.type == "internal") {
        userjson.trialuser = true;
    }
    else {
        userjson.trialuser = false;
    }
    var bootstrap = Platform.bootstrapUser(JSON.stringify(userjson));
    bootstrap = $.parseJSON(bootstrap);
    if (bootstrap && bootstrap.bootstrap_status != "success") {
       Client.removeDialog();
       Client.closeProgressBar();
       this.display_login_error(bootstrap.bootstrap_message + '. Contact '+ PROJECT_TITLE +' support with your user details for further assistance');
       Platform.logMessage("Downloading database failed.");
       return false;
    }
    else {
        return true;
    }
}

SigninView.prototype.filterGroupsData = function(user_products) {
    if(isArray(user_products)){
        user_products = user_products[0];
    }
    if (user_products.title.indexOf("Classic") >= 0) {
        var quiz = Platform.getObjectsOfType(OBJECT_QUIZ);
        var ebooks = Platform.getObjectsOfType(OBJECT_BOOK);
        if (quiz != "") {
            quiz = $.parseJSON(quiz);
            for (i = 0; i < quiz[OBJECT_QUIZ].length; i++) {
                Platform.removeObject(OBJECT_QUIZ, quiz[OBJECT_QUIZ][i].nid);
            }
        }
        if (ebooks != "") {
            ebooks = $.parseJSON(ebooks);
            for (j = 0; j < ebooks[OBJECT_BOOK].length; j++) {
                Platform.removeObject(OBJECT_BOOK, ebooks[OBJECT_BOOK][j].nid);
            }
        }
    }
}

SigninView.prototype.getUserGroups = function() {
    var user_groups = [];
    var user = this.user.getGroupsForUser(OBJECT_USER_ID);
    if (user == "" || user == GET_USER_GROUPS_FAILURE || user == INTERNET_ERROR)
        return "";

    var k = 0;

        for (i = 0; i < user.length; i++) {
            if(user[i].force_redirect_group != null){
                var scholarship_group = {};
                scholarship_group.force_redirect_group = user[i].force_redirect_group;
                scholarship_group.group_redirect_value = user[i].group_redirect_value;
                scholarship_group.redirect_group_action_type = user[i].redirect_group_action_type; 
                if (EXEC_MODE == "online") {
                    Platform.setPreference('scholarship_group', scholarship_group);
                } else {
                    Platform.setPreference('scholarship_group', JSON.stringify(scholarship_group));
                }
            }
            if(typeof APP_USER_GROUPS != "undefined"){
                for (j = 0; j < APP_USER_GROUPS.length; j++) {
                    if (user[i].title.indexOf(APP_USER_GROUPS[j].title) > -1) {
                        user_groups[k] = user[i].nid;
                        k++;
                    }
                }
             user_groups[k] = user[i].nid;
             k++;
            }
            else{
                user_groups[i] = user[i].nid;
            }
        }

    return user_groups;
}

SigninView.prototype.validateEmail = function(sEmail) {
    var filter = /^([\w-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/;
    if (filter.test(sEmail)) {
        return true;
    }
    else {
        return false;
    }
}

SigninView.prototype.onSigninClick = function() {
    var signinView = this;
    $(".error-div").css('display', 'none');
    $('.error-text').html("");
    Client.showProgressBar(t("Loading") + "...");
    if($.isFunction(Platform.getLocalConfig)){
        var getLocalConfig = Platform.getLocalConfig();
        getLocalConfig = $.parseJSON(getLocalConfig);
        if (typeof getLocalConfig.bypass_password != "undefined" && getLocalConfig.bypass_password == "true") {
            signinView.bypass_password_login();
        }else {
            $("#signin_email").blur();
            setTimeout(function () {
                signinView.signin_validate();
            }, 200);
        }
    }else{
        $("#signin_email").blur();
        setTimeout(function () {
            signinView.signin_validate();
        }, 200);
    }
}
SigninView.prototype.bypass_password_login = function () {
    var email = $.trim($("#signin_email").val());
    if (email == "") {
        $("#signin_email").focus();
        if (typeof SIGNIN_PLACEHOLDER != ' undefined') {
            var placeholder = SIGNIN_PLACEHOLDER;
            this.display_login_error(t(placeholder) + t("SIGNIN_PLACEHOLDER_EMPTY", true));
        } else {
            this.display_login_error(t("EMAIL_EMPTY", true));
        }
    } else if (typeof USERNAME_AS_MOBILENO != "undefined" && USERNAME_AS_MOBILENO == "true" && !this.validateMobNumber(email)) {
        $("#signin_email").focus();
        this.display_login_error(VALID_MOB_NUM);
        $("#signin_email").val("");
    } else if ((typeof USERNAME_OR_EMAIL != "undefined" && USERNAME_OR_EMAIL == "true")
        && (!this.validateUsername(email) && !this.validateEmail(email))) {
        $("#signin_email").focus();
        this.display_login_error(VALID_USERNAME);
        $("#signin_email").val("");
    } else if (typeof USERNAME_OR_EMAIL == "undefined" &&
        typeof USERNAME_AS_MOBILENO == "undefined" && !this.validateEmail(email) && (typeof VERIFY_EMAIL == "undefined" ||
            (typeof VERIFY_EMAIL != "undefined" && VERIFY_EMAIL != "false"))
    ) {
        $("#signin_email").focus();
        this.display_login_error(VALID_EMAIL);
        $("#signin_email").val("");
    } else {
        if (Platform.getNetworkState() == true) {
            var data = "{";
            var temp = "";
            temp = ' "username" : "' + email + '"';
            data = data + temp + "}";
            var userexist = this.user.doesUserExist(data);
            if (userexist == 'true') {
                var status = this.user.loginRedirect(email);
                if (status == true) {
                    setUserData(email);
                    this.loginSuccess();
                } else {
                    this.display_login_error(t("ERROR_MESSAGE", true));
                }
            } else {
                this.display_login_error(t("USER_NOT_REGISTERED", true));
            }
        } else {
            this.display_login_error(t("INTERNET_ERROR", true));
        }
    }
}
SigninView.prototype.setCustomStyles = function () {
    var screenwidth = $(window).width();
    var screenheight = $(window).height();
    $("#wrapper").css(
    {
        "background-size": "100% 100%",
        "height": "auto",
        "min-height": $(window).height()
    });
    $("#client_signin_inner_wrapper,#signin_outer_wrapper,.terms_outer_wrapper").css({
        width: screenwidth,
        height: screenheight
    });
    if(typeof SIGNIN_BG_CLR != "undefined"){
        $("#wrapper").css("background",SIGNIN_BG_CLR);
    }
    if(typeof SIGNIN_BG_IMG != "undefined"){
        $("#wrapper").css(
            {
               "background": 'url(' + SIGNIN_BG_IMG + ')',
                "background-position": "center",
                "background-size": "cover"
            }
        );
    }
    $("#wrapper").css("overflow", "");
    $('#content_wrapper').css({'display':'table', 'background-color': 'inherit'});
    if (PROJECT_TITLE == "Crackverbal") {
        $("#wrapper").css(
        {
           "background-image": "none",
            "background":' #00a5e5'
        });
        var logowidth = $("#sigin_i_logo_wrapper").width();
        $("#sigin_i_logo").css("height", logowidth);
        $('#content_wrapper').css({'overflow': 'hidden', 'margin-top': '0px'});
    }
    var createusersuccess =  CONTROLLER.getVariable('createusersuccess');
    if(typeof createusersuccess != 'undefined' && createusersuccess == 'true'){
        CONTROLLER.setVariable('createusersuccess','');
    }

    if(ENABLE_NEW_UI)  $('#footer_menu').addClass('hidden');
    if($("#header").length > 0){ $("#header").addClass('hidden');}
    if($("#footer_wrapper").length > 0){
        $("#footer_wrapper").addClass('hidden');
    }
    if(typeof EXTERNAL_LOGIN != 'undefined' && !EXTERNAL_LOGIN){
        if(PROJECT_TITLE == "MyCompany FinShiksha"){
            $('#sigin_i_logo').css({'content':'url("/client/images/mycompany_signin_logo.png")'});
        }else if(PROJECT_TITLE == "FinShiksha"){
            $('#sigin_i_logo').css({'content':'url("/client/images/signin_logo.3d5453b4.png")'});
        }else if(PROJECT_TITLE == 'Scripbox FinShiksha'){
            $('#sigin_i_logo').css({'content':'url("/client/images/scripbox_signin_logo.png")'});
        }else{
            $('#sigin_i_logo').css({'content':'url("/client/images/signin_logo.3d5453b4.png")'});
        }
    }
}

SigninView.prototype.renderHeader = function() {
}

SigninView.prototype.onBack = function()
{
        if(typeof SIGNINLANDINGPAGE_ENABLED != 'undefined' && SIGNINLANDINGPAGE_ENABLED == 'true'){
            CONTROLLER.loadView("SigninLanding");
        }else{
            if(typeof COURSE_SELECTION_BEFORE_SIGNINVIEW !== "undefined" && COURSE_SELECTION_BEFORE_SIGNINVIEW === "true"){
                CONTROLLER.loadView("CourseSelection");
                return;
            }

            Platform.closeApplication();
        }
}

SigninView.prototype.samlLogin = function(email, timestamp)
{
  $("#signin_email").blur();
   Client.showProgressBar(t("Loading") + "...");
   if (Platform.getNetworkState() == true) {
        setTimeout(function() {
            Client.closeProgressBar();
            Platform.setPreference('time', timestamp.toString());
            var redirectPath =  Platform.getLocalServerPath() + '/app/'+CLIENT_NAME+'/html/index.html?timestamp='+timestamp + '&samlLogin=true';
            var url = OBJECT_SERVER_DOMAIN + '/login/cognizant?app=true&redirect='+encodeURIComponent(redirectPath);
            window.location.href = url;
        }, TIMEOUT);
    }
    else {
        this.display_login_error(t("INTERNET_ERROR",true));
    }
}

SigninView.prototype.disablePassword = function(){
    $("#signin_password").attr('readonly', true);
    $('#signin_input_password').css({'background-color': '#ccc'});
    $('#signin_password').val("");
}

SigninView.prototype.enablePassword = function(){
    $('#signin_input_password').css({'background-color': ''});
    $("#signin_password").attr('readonly', false);
}
SigninView.prototype.verifyOtploginOrSociallogin = function () {
    var user = '';
    user = this.settings.getLastLoggedinUser();
    if (user != "" || user.loginRequiredOnline == true) {
        var userGroups = Platform.getPreference('userGroups');
        var user_groups = $.parseJSON(userGroups);
        user.loginRequiredOnline = false;
        user.groups = user_groups;
        Platform.saveObject("currentuser", 1, "content", $.trim(JSON.stringify(user)));
        Platform.saveObject("user", user.uid, "content", $.trim(JSON.stringify(user)));
        OBJECT_USER_DATA = user;
        CONTROLLER.loadView("HomePage", "true");
        Client.closeProgressBar();
    }
    else {
        this.loginSuccess();
    }
}

SigninView.prototype.remember_me = function() {
    var email = $.trim($("#signin_email").val());
    var password = $("#signin_password").val();
    var remember_me_data = {"email" : email,"password" : password}
    remember_me_data = JSON.stringify(remember_me_data);
    Platform.setPreference("remember_me_details", remember_me_data);
}

SigninView.prototype.loginUserOffline = function(password, user) {
    var status = this.user.loginUserOffline(password, user);
    if (status == "true") {
        var remember_me_option = CONTROLLER.getVariable("remember_me");
        if(remember_me_option == 'true'){
            this.remember_me();
        }
        window.location.href = "../html/index.html?ftl=true";
    }
    else {
        this.display_login_error(status);
    }
}

SigninView.prototype.validateUsername = function(username) {
    var filter = /^[A-Za-z0-9]+$/;
    if (filter.test(username) ||  !VALIDATE_USERNAME) {
        return true;
    }
    else {
        return false;
    }
}

SigninView.prototype.onReedemClick = function()
{   
    if (Platform.getNetworkState() == true) {
        CONTROLLER.loadView("Redeem");
    } else{
        this.display_login_error(t("INTERNET_ERROR",true));
        return;
    }
}